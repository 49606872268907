import moment from 'moment';
import { store } from '../../store';
import { breadthFirstSearch } from '../utils/helpers';

export const parseEntityArguments = (e) => [
    e.entity_id,
    e.entity_name,
    e.hierarchy_level,
    e.parent_entity_id,
    e.timezone,
    e.weightage,
    e.entity_img_id,
    e.image_url,
    e.gateways,
    e.signedUrl,
    e.day_start,
    e.created_at
];

export class Entity {
    constructor(
        entity_id,
        entity_name,
        hierarchy_level,
        parent_entity_id,
        timezone = '+08:00',
        weightage,
        entity_img_id = -1,
        image_url = '',
        gateways,
        signedUrl,
        day_start,
        created_at
    ) {
        this.entity_id = entity_id;
        this.entity_name = entity_name;
        this.hierarchy_level = hierarchy_level;
        this.parent_entity_id = parent_entity_id;
        this.timezone = timezone;
        this.weightage = weightage;
        this.entity_img_id = entity_img_id;
        this.image_url = image_url;
        this.gateways = gateways;
        this.signedUrl = signedUrl;
        this.day_start = day_start;
        this.created_at = created_at;
    }

    get tzHours() {
        const sign = this.timezone[0] === '+' ? 1 : -1;
        const hour = +this.timezone.split(':')[0].replace(/[+/-]/, '');
        return hour * sign;
    }

    get tzStartDifference() {
        const { tzHours, day_start, timezone } = this;
        if (!day_start) return tzHours;

        const utcDayStartHour = moment.utc(day_start).get('hour');
        const utcMidnightHour = moment
            .utc(moment().utcOffset(timezone).startOf('day'))
            .get('hour');

        const diff = (utcDayStartHour + 24 - utcMidnightHour) % 24;
        return tzHours - diff;
    }

    get preference() {
        const { preference } = store.getState();

        return preference.entity;
    }

    get children() {
        const {
            entity: { entities, pc_directory },
        } = store.getState();
        const { entity_id } = this;

        return pc_directory[entity_id]
            ? pc_directory[entity_id]
                .map((child_id) => entities[child_id])
                .sort((a, b) => {
                    if (a.entity_name < b.entity_name) return -1;
                    if (a.entity_name > b.entity_name) return 1;
                    return 0;
                })
            : [];
    }

    get hasGateways() {
        return this.gateways.length;
    }

    get accessDenied() {
    // prevent navigation to parent/sibling entities
        const {
            auth: { user },
        } = store.getState();

        if (!user || !user.entity) return true;

        return !breadthFirstSearch([user.entity], (e) => {
            return e.entity_id === this.entity_id;
        });
    }

    get image() {
        return this.signedUrl;
    }

    clone() {
        return { ...this };
    }
}
