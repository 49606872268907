/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateAssetStdTime } from '../../../../store/old/Assets/Assets.action';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import CellStandardTime from './CellStandardTime';
import { Switch } from 'antd';

class OeeHeaderCell extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { handleOpenModalStandardTime, checkPolicy, asset, entity, updateAssetStandardTime } = this.props;
        const { entity_id } = entity;
        const { asset_name, autoUpdate, speed, stdTime, asset_id } = asset;

        return (
            <div className="header-oee-asset-table enabled header-asset-oee">
                <div className="header-asset-oee__title">
                    <p>{asset_name}</p>
                </div>
                <div className="header-asset-oee__item" 
                    onClick={(e) => {
                        setTimeout(() => {
                            if (handleOpenModalStandardTime && checkPolicy.isEditPermission) {
                                handleOpenModalStandardTime(asset);
                            }
                        });
                    }}
                >
                    <CellStandardTime autoUpdate={autoUpdate} stdTime={stdTime}/>
                </div>
                <div className="header-asset-oee__item">
                    <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={speed}
                        onChange={(value) => {
                            updateAssetStandardTime(
                                entity_id,
                                asset_id,
                                { speed: value }
                            )
                        }}
                    />
                </div>
            </div>
        );
    }
}

const checkPolicyStandardtime = (user) => {
    return {
        isEditPermission: user.check_resource_policy(
            'standard_time',
            false,
            true,
            false
        ),
    };
};

const mapStateToProps = (rootState) => {
    return {
        checkPolicy: checkPolicyStandardtime(rootState.auth.user),
        entity: currentEntitySelector(rootState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAssetStandardTime: (entity_id, asset_id, payload, callback) =>
            dispatch(updateAssetStdTime(entity_id, asset_id, payload, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OeeHeaderCell);

export const CellLegend = () => {
    return <div className="header-asset-oee header-asset-oee--absolute">
        <div className="header-asset-oee__title">Asset Name</div>
        <div className="header-asset-oee__item">Standard Time</div>
        <div className="header-asset-oee__item">Speed Loss</div>
    </div>
}
