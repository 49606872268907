import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT
import LoadingScreen from '../../components/Loading';
import { issuesState } from '../../../store/old/Issues/Issues.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { fetchIssues } from '../../../store/old/Issues/Issues.action';

export function withIssues(Component) {
    return function WrapperComponent(props) {
        const { entity_id } = useSelector(currentEntitySelector);
        const issuesStore = useSelector(issuesState);
        const { initialized, issues } = issuesStore;
        const dispatch = useDispatch();
        useEffect(() => {
            if (initialized) return;
            dispatch(fetchIssues(entity_id));
        }, []);

        return initialized ? (
            <Component {...props} issues={issues} />
        ) : (
            <LoadingScreen description={'Loading OEE issues...'} />
        );
    };
}
