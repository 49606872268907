import { DatePicker, type DateValidationError, LocalizationProvider, type PickerChangeHandlerContext } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { type Moment } from "moment"
import React, { useState } from "react"

const AukDatePicker = (props: Props) => {
    const [open, setOpen] = useState(false)

    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
            open={open}
            onClose={() => { setOpen(false) }}
            disableFuture={props.disableFuture}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            format={props.format ?? "YYYY/MM/DD"}
            slotProps={{
                textField: {
                    inputProps: { readOnly: props.readOnly ?? false },
                    onClick: () => { setOpen(true) }
                }
            }}
        />
    </LocalizationProvider>
}

interface Props {
    disableFuture?: boolean
    label: string
    value: Moment
    onChange: (value: Moment | null, context: PickerChangeHandlerContext<DateValidationError>) => void
    format?: string
    readOnly?: boolean
}

export default AukDatePicker
