import React from 'react';
import { SPAWrapper } from '../components/SPAWrapper';
import Markdown from 'react-markdown';

import { localizeTime } from '../utils/helpers';

import './SystemDowntime.scss';
import { useAppSelector } from '../../store/hooks';

const SystemDowntime = () => {
    const { content } = useAppSelector(
        (appState) => appState.app.systemDowntime || {}
    );

    return content ? (
        <SPAWrapper className="system-downtime">
            <Markdown className="markdown-body">{localizeTime(content)}</Markdown>
        </SPAWrapper>
    ) : null;
};

export default SystemDowntime;
