export class Policy {
    constructor(
        policy_name,
        policy_id,
        blocks,
        users,
        groups,
        resource_policies
    ) {
        this.policy_name = policy_name;
        this.policy_id = policy_id;
        this.blocks = blocks;
        this.users = users;
        this.groups = groups;
        this.resource_policies = resource_policies;
    }
}
