import { makeRequest } from "../Shared/Shared.action"
import { queryString } from "../../../legacy/utils/service"

export const api_updateAssetStandardTime = async (entity_id, asset_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/stdTime`,
                method: "PUT",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

// export const api_uploadAssetImage = async (
//     asset_id,
//     data
// ) => {
//     const { entity_id } = currentEntitySelector(store.getState())

//     return await new Promise(
//         (resolve, reject) => {
//             makeRequest({
//                 path: `v1/entity/${entity_id}/asset/${asset_id}/image`,
//                 method: "PUT",
//                 params: JSON.stringify(data),
//                 success: resolve,
//                 error: reject,
//             })
//         }
//     )
// }

export const api_createAsset = async (entity_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset`,
                method: "POST",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_updateAsset = async (entity_id, asset_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}`,
                method: "PUT",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_getAssetCharts = async (entity_id, asset_id) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/chart`,
                method: "GET",
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_updateAssetChart = async (entity_id, asset_id, chart_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/chart/${chart_id}`,
                method: "PUT",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

// export const api_deleteAssetsPure = async (data) => {
//     const { entity_id } = currentEntitySelector(store.getState())

//     return await new Promise(
//         (resolve, reject) => {
//             makeRequest({
//                 path: `v1/entity/${entity_id}/asset/`,
//                 method: "DELETE",
//                 params: JSON.stringify(data),
//                 success: resolve,
//                 error: reject,
//             })
//         }
//     )
// }

export const api_updateAssetChartOrder = async (entity_id, asset_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/chart_order`,
                method: "PUT",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_getAssetSkus = async (entity_id, asset_id) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/sku`,
            method: "GET",
            success: resolve,
            error: reject,
        })
    })
}

export const api_createAssetSku = async (entity_id, asset_id, sku_id) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/sku/${sku_id}`,
            method: "POST",
            params: JSON.stringify({}),
            success: resolve,
            error: reject,
        })
    })
}

export const api_deleteAssetSkus = async (entity_id, asset_id, data) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/sku`,
            method: "DELETE",
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        })
    })
}

export const api_getAssetOperatorPermittedLabels = async (entity_id, asset_id) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/basic/label`,
            method: "GET",
            success: resolve,
            error: reject,
        })
    })
}

export const api_updateAssetOperatorIssues = async (entity_id, asset_id, data) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/issue`,
            method: "PUT",
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        })
    })
}

export const api_updateAssetOperatorSkus = async (entity_id, asset_id, data) => {
    return await new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/sku`,
            method: "PUT",
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        })
    })
}

export const api_getAssets = async (entity_id, query) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset${queryString(query)}`,
                method: "GET",
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_getAssetsSkusStandardTimes = async (entity_id) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/stdTime`,
                method: "GET",
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_deleteAsset = async (entity_id, asset_id) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}`,
                method: "DELETE",
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            })
        }
    )
}
