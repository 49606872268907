import React from 'react';
import * as d3 from 'd3';
import { ColumnChart, LabelsChart, LineChart } from '../../../../Charts/v1';
import {
    ColumnChartTooltip,
    LabelsChartTooltip,
    TargetsChartTooltip,
} from '../../../../Charts/v1/tooltips/templates';
import { generateContinuousDomain } from '../../../../utils/charts';
import { getTargetsPathCoords } from '../../../../utils/targets';
import { labelDisplayText } from '../../../../utils/labels';
import CONSTANTS from '../../../../Constants';

const labelXAccessor = (d) => new Date(d.from);

export default class OperatorChart extends React.Component<any> {
    get chart() {
        const {
            meta: { mode, chart_type },
        } = this.props;

        const chartProps = this.getChartProps();

        if (mode === '2a') {
            return <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />;
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.COLUMN) {
            return <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />;
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.LINE) {
            return (
                <LineChart
                    {...chartProps}
                    focusEl="circle"
                    htmlTooltip={ColumnChartTooltip}
                />
            );
        }

        return null;
    }

    getChartProps() {
        const { meta, pointerEvents = true, asset } = this.props;
        const { color } = meta;

        const useBenchmark = asset.targets.length && asset._primaryChart?.dataSource === meta;

        const chartProps = {
            data: meta.data,
            xScale: d3.scaleTime(),
            yScale: d3.scaleLinear(),
            xDomain: this.daterange,
            yDomain: generateContinuousDomain(meta.data, (d) => d.val),
            xAccessor: (d) => d.time,
            yAccessor: (d) => d.val,
            colorAccessor: () => color || 'steelblue',
            useBrush: false,
            showYGrid: true,
            pointerEvents,
        };

        if (useBenchmark) {
            const benchmarkData =
        meta.mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT
            ? this._getBenchmarkPathData()
            : [];
            const benchmarkYDomain = generateContinuousDomain(
                benchmarkData,
                (d) => d[1]
            );
            chartProps.yDomain = [
                Math.min(chartProps.yDomain[0], benchmarkYDomain[0]),
                Math.max(chartProps.yDomain[1], benchmarkYDomain[1]),
            ];

            chartProps.useBenchmark = true;
            chartProps.benchmark = {
                xAccessor: (d) => d[0],
                yAccessor: (d) => d[1],
                data: benchmarkData,
            };
        }

        return chartProps;
    }

    getLabelProps() {
        return {
            xScale: d3.scaleTime(),
            xDomain: this.daterange,
            xAccessor: (d) => labelXAccessor(d),
            x2Accessor: (d) => new Date(d._to),
            colorAccessorLabel: (d) => d.color,
            colorAccessorText: () => '#fafafa',
            textAccessor: labelDisplayText,
            useTooltip: true,
            htmlTooltip: LabelsChartTooltip,
            keyAccessor: (d) => d.label_id,
        };
    }

    _getBenchmarkPathData() {
        const {
            asset: { targets },
            controls: { resolution },
        } = this.props;

        if (!targets.length) return [];

        return getTargetsPathCoords(targets, this.daterange, resolution);
    }

    get daterange() {
        const { lower, upper } = this.props.dateRange;

        return [new Date(lower), new Date(upper)];
    }

    get issueLabelsChart() {
        return (
            <div className="operator-chart__labels">
                <LabelsChart
                    {...this.getLabelProps()}
                    data={this.props.asset.issueLabels}
                />
            </div>
        );
    }

    get productionLabelsChart() {
        return (
            <div className="operator-chart__labels">
                <LabelsChart
                    {...this.getLabelProps()}
                    data={this.props.asset.productionLabels}
                />
            </div>
        );
    }

    get targetsChart() {
        return (
            <div className="operator-chart__labels">
                <LabelsChart
                    xScale={d3.scaleTime()}
                    xDomain={this.daterange}
                    xAccessor={(d) => labelXAccessor(d)}
                    x2Accessor={(d) => new Date(d.to)}
                    data={this.props.asset.targets}
                    colorAccessorLabel={() => '#2176FF'}
                    colorAccessorText={() => '#fafafa'}
                    textAccessor={(d) => (d.sku ? d.sku.codeName : 'Planned Production')}
                    useTooltip={true}
                    htmlTooltip={TargetsChartTooltip}
                    keyAccessor={(d) => d.target_id}
                    // borderRadius={3}
                />
            </div>
        );
    }

    render() {
        const { meta } = this.props;

        const { chart_title = '', units = '' } = meta || {};

        return (
            <div className="d-flex flex-column operator-chart">
                {this.targetsChart}
                {this.issueLabelsChart}
                {this.productionLabelsChart}
                <div className="operator-chart__header">
                    {chart_title} / {units || 'Unit per resolution'}
                </div>
                <div className="operator-chart__main">{this.chart}</div>
            </div>
        );
    }
}
