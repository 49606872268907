import React from 'react';

const ShiftCalendarCard = ({ hasElapsed, data }: any) => {
    return (
        <div className="auk-calendar-shift-card">
            {data.hasTargetsSet ? (
                <span className="auk-calendar-shift-card__label">
                    {data.targets[0].value} units
                </span>
            ) : (
                <span className="auk-calendar-shift-card__label">
                    {hasElapsed ? 'No production target' : '+ Set a target'}
                </span>
            )}
        </div>
    );
};

export default ShiftCalendarCard;
