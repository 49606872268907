import { ArrowDropDown, ArrowRight } from "@mui/icons-material"
import { Autocomplete, FormControl, FormHelperText, IconButton, MenuItem, SxProps, TextField } from "@mui/material"
import React, { type MouseEvent, useEffect, useState } from "react"
import useBlocks from "../../hooks/useBlocks"
import type BlockModel from "../../api/models/block.model"
import { breadthFirstSearch } from "../../legacy/utils/helpers"

const HIERARCHY_PADDING = 32
const DEFAULT_PADDING = 48

const ProcessTreeSelector = (props: Props) => {
    const { root: plant } = useBlocks()
    const [inputValue, setInputValue] = useState<string>("")
    const [searching, setSearching] = useState<boolean>(false)
    const [options, setOptions] = useState<ProcessNode[]>([])
    const [value, setValue] = useState<ProcessNode[] | ProcessNode | null>(props.multiple ? [] : null)

    useEffect(() => {
        const nodeProps = props.nodeProps ?? {}
        if (props.process) {
            const block: BlockModel = breadthFirstSearch([plant as BlockModel], (node: BlockModel) => node.blockId === props.process)
            setOptions(createNodes(block, [], nodeProps))
            return
        }
        setOptions(createNodes(plant as BlockModel, [], nodeProps))
    }, [plant, props.process, props.nodeProps])

    useEffect(() => {
        if (props.multiple) {
            const controlledValues = new Set(props.value as number[])
            const _values = options.filter(({ id }) => controlledValues.has(id))
            setValue(_values)
            return
        }

        const _value = options.find(({ id }) => props.value === id) ?? null
        setInputValue(_value?.label ?? "")
        setValue(_value)
    }, [props.value, JSON.stringify(options.map(({ id }) => id))])

    const handleListToggle = (node: ProcessNode, isExpanded: boolean) => {
        const index = options.findIndex((option) => option.id === node.id)
        if (index === -1) {
            return
        }

        const newOption: ProcessNode = { ...options[index], isExpanded }
        const newOptions = [...options]
        newOptions.splice(index, 1, newOption)
        let nextIndex = index + 1
        let nextExpanded = isExpanded
        let previousHierarchy = node.hierarchy
        const maxIndex = newOptions.length - 1
        while (nextIndex <= maxIndex && newOptions[nextIndex].hierarchy > node.hierarchy) {
            if (newOptions[nextIndex].hierarchy < previousHierarchy) {
                nextExpanded = isExpanded
            }

            newOptions[nextIndex].isVisible = nextExpanded
            if (isExpanded && newOptions[nextIndex].hasChildren) {
                nextExpanded = newOptions[nextIndex].isExpanded
            }

            previousHierarchy = newOptions[nextIndex].hierarchy
            nextIndex++
        }

        setOptions(newOptions)
    }

    const handleChange = (_: React.SyntheticEvent, value: ProcessNode[] | ProcessNode | null) => {
        if (props.multiple) {
            const val: number[] = (value as ProcessNode[]).map(({ id }) => id)
            props.onMultiChange && props.onMultiChange(val)
        } else {
            const val: number | null = value ? (value as ProcessNode).id : null
            props.onChange && props.onChange(val)
        }

        setSearching(false)
    }

    const handleInputChange = (_: React.SyntheticEvent, value: string) => {
        setInputValue(value)
        setSearching(true)
    }

    return <FormControl fullWidth>
        <Autocomplete
            ref={props.ref}
            options={options}
            onChange={handleChange}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, node) => renderOption(props, node, searching, handleListToggle)}
            renderInput={(params) => <TextField {...params} sx={{ marginTop: props.label ? "8px" : "0px" }} label={props.label}/>}
            inputValue={inputValue}
            value={value}
            size={props.size ?? "medium"}
            fullWidth
            disableClearable={props.disableClearable}
            multiple={props.multiple}
            disableCloseOnSelect={props.multiple}
            onBlur={props.onBlur}
        />
        {props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </FormControl>
}

const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    node: ProcessNode,
    isSearching: boolean,
    handleListToggle: (node: ProcessNode, isExpanded: boolean) => void,
) => {
    if (!node.isVisible) {
        return null
    }

    const isShowDropdown = !isSearching && node.hierarchy !== 1 && node.hasChildren
    const defaultPadding = !isShowDropdown ? DEFAULT_PADDING : 0
    const paddingLeft = isSearching ? DEFAULT_PADDING : (node.hierarchy - 1) * HIERARCHY_PADDING + defaultPadding
    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation()
        handleListToggle(node, !node.isExpanded)
    }

    return <MenuItem {...props} style={{ paddingLeft }} value={node.id} disabled={node.disabled ?? false}>
        {isShowDropdown && <IconButton style={{ marginRight: 8 }} onClick={handleClick}>
            {node.isExpanded ? <ArrowDropDown/> : <ArrowRight />}
        </IconButton>}
        <span style={{ paddingTop: 8, paddingBottom: 8 }}>{node.label}</span>
    </MenuItem>
}

const createNodes = (block: BlockModel, nodes: ProcessNode[] = [], options: NodeProps): ProcessNode[] => {
    nodes.push(createNode(block, options))
    if (!block.children || block.children.length === 0) {
        return nodes
    }

    for (const child of block.children) {
        createNodes(child, nodes, options)
    }

    return nodes
}

const createNode = (block: BlockModel, options: NodeProps = {}): ProcessNode => {
    const result: ProcessNode = {
        id: block.blockId,
        label: block.label,
        hierarchy: block.hierarchyLevel,
        isVisible: true,
        isExpanded: true,
        hasChildren: !!block.children?.length,
    }

    if (options.disabled) {
        result.disabled = options.disabled(block)
    }

    return result
}

export interface ProcessNode {
    id: number
    label: string
    hierarchy: number
    isVisible: boolean
    isExpanded: boolean
    hasChildren: boolean
    disabled?: boolean
}

interface Props {
    sx?: SxProps
    onChange?: (option: number | null) => void
    onMultiChange?: (options: number[]) => void
    value: number | number[] | null
    label?: string
    size?: "small" | "medium"
    nodeProps?: NodeProps
    ref?: React.Ref<any>
    error?: boolean
    helperText?: string
    disableClearable?: boolean
    onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined
    required?: boolean
    multiple?: boolean
    process?: number
}

interface NodeProps {
    disabled?: (node: any) => boolean
    required?: boolean
    multiple?: boolean
    size?: "small" | "medium"
    sx?: SxProps
    clearable?: boolean
}

export default ProcessTreeSelector
