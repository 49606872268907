import "./style.scss"
import { GridColDef } from "@mui/x-data-grid"
import { GridAlignment } from "@mui/x-data-grid/models/colDef/gridColDef"
import React, { CSSProperties } from "react"
import Grid from "../../../Grid/Grid"
import TreeTableCell from "../TreeTableCell/TreeTableCell"

const TreeTableHeader = (props: Props) => {
    return <Grid
        container
        item
        direction="row"
        className="tree-table-header"
        style={props.style}
    >
        {props.columns.map(column =>
            <TreeTableCell
                key={column.headerName || column.field}
                className={column.headerClassName as string}
                width={column.width}
                style={{ justifyContent: getCellAlignment(column.align) }}
            >
                {column.renderHeader ? column.renderHeader() : column.headerName}
            </TreeTableCell>
        )}
    </Grid>
}

const getCellAlignment = (align?: GridAlignment): string | undefined => {
    if (align === "left") {
        return "flex-start"
    }

    if (align === "right") {
        return "flex-end"
    }

    return align
}

interface Props {
    columns: HeaderColDef[]
    style?: CSSProperties
}

type HeaderColDef = Omit<GridColDef, "renderHeader"> & {
    renderHeader: () => React.ReactNode
}

export default TreeTableHeader
