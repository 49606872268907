import React from 'react';
import translate from '../../../utils/translate';
import IconGemini from '../../../../components/Icons/IconGemini';
import { round } from 'lodash';

const CellStandardTime = (props) => {
    return <div className='d-flex w-100 h-100 p-2'>
        {
            props.autoUpdate ? (
                <IconGemini 
                    title={'Machine Learning Cycle Time is switched on'} 
                    height={16} 
                    width={16} 
                    style={{position: 'absolute', top: '0.5em', left: '0.5em'}}
                />
            ) : 
            null
        }
        <div className='w-100 text-align-right' style={{lineHeight: '14px'}}>
            {props.stdTime === 0 ? translate('notset') : round(props.stdTime, 3)}
        </div>
    </div>
}

export default CellStandardTime