import { type SxProps, ToggleButton } from "@mui/material"
import ToggleButtons from "../../../../../components/ToggleButtons/ToggleButtons"
import "./UnitOfTimeSwitch.scss"

import React from "react"
import { GoalsUnitOfTimeEnum } from "../../../../../constants/goals.constants"

const UnitOfTimeSwitch = (props: Props) => {
    const handleChange = (_: React.MouseEvent<HTMLElement>, val: any) => {
        props.onChange(val)
    }

    return <ToggleButtons
        className="goals-toggle-buttons"
        value={props.value}
        onChange={handleChange}
        sx={props.sx}
    >
        <ToggleButton value={GoalsUnitOfTimeEnum.MONTHLY}>Monthly</ToggleButton>
        <ToggleButton value={GoalsUnitOfTimeEnum.WEEKLY}>Weekly</ToggleButton>
    </ToggleButtons>
}

interface Props {
    value: GoalsUnitOfTimeEnum
    onChange: (val: GoalsUnitOfTimeEnum) => void
    sx?: SxProps
}

export default UnitOfTimeSwitch
