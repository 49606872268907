import { put, takeLatest } from 'redux-saga/effects';

// REDUX STORE
import { store } from '../..';

// ACTION
import {
    // ENTITY
    createEntityPreferencesSuccess,
    createEntityPreferencesFailure,
    updateEntityPreferencesSuccess,
    updateEntityPreferencesFailure,

    // USER
    createUserPreferencesSuccess,
    createUserPreferencesFailure,
    updateUserPreferencesSuccess,
    updateUserPreferencesFailure,
} from './Preference.action';


// PREFERENCES SERVICES
import {
    api_createEntityPreference,
    api_updateEntityPreference,
    api_createUserPreference,
    api_updateUserPreference,
} from './Preference.services';

import { PreferenceConstants as K } from './Preference.constants';
import { errorFlash, flash } from '../../../legacy/components/Flash';

// CREATE ENTITY PREFERENCES
function* handleCreateEntityPreference(data) {
    try {
        const response = yield api_createEntityPreference(data.payload.entity);

        yield put(createEntityPreferencesSuccess(response));
        if (data.callback) {
            data.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(createEntityPreferencesFailure(error));
    }
}

export function* createEntityPreference() {
    yield takeLatest(K.ACTIONS.CREATE_ENTITY_PREFERENCES_REQUEST, handleCreateEntityPreference);
}

// UPDATE ENTITY PREFERENCES
function* handleUpdateEntityPreference(data) {
    try {
        const { preference_id } = store.getState().preference.entity;
        const response = yield api_updateEntityPreference(
            data.payload.entity,
            preference_id
        );

        yield put(updateEntityPreferencesSuccess(response));

        flash({
            message: 'Entity Preferences Updated',
            status: 'success',
        });

        if (data.callback) {
            data.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(updateEntityPreferencesFailure(error));
    }
}

export function* updateEntityPreference() {
    yield takeLatest(K.ACTIONS.UPDATE_ENTITY_PREFERENCES_REQUEST, handleUpdateEntityPreference);
}

// CREATE USER PREFERENCES
function* handleCreateUserPreference(data) {
    try {
        const response = yield api_createUserPreference(data.payload.user);

        yield put(createUserPreferencesSuccess(response));
        if (data.callback) {
            data.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(createUserPreferencesFailure(error));
    }
}

export function* createUserPreference() {
    yield takeLatest(K.ACTIONS.CREATE_USER_PREFERENCES_REQUEST, handleCreateUserPreference);
}

// UPDATE USER PREFERENCES
function* handleUpdateUserPreference(data) {
    try {
        const { preference_id } = store.getState().preference.user;
        const response = yield api_updateUserPreference(
            data.payload.user,
            preference_id
        );

        flash({ message: 'User Preferences Updated', status: 'success' });

        yield put(updateUserPreferencesSuccess(response));

        if (data.callback) {
            data.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(updateUserPreferencesFailure(error));
    }
}

export function* updateUserPreference() {
    yield takeLatest(K.ACTIONS.UPDATE_USER_PREFERENCES_REQUEST, handleUpdateUserPreference);
}
