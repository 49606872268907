export const BinarySearch = function (arr, x, start, end) {
    if (start > end) return false;

    let mid = Math.floor((start + end) / 2);

    if (arr[mid] <= x && x < arr[mid + 1]) return mid;

    if (arr[mid] > x) return BinarySearch(arr, x, start, mid - 1);
    else return BinarySearch(arr, x, mid + 1, end);
};

export const BinarySearchForIndex = function (arr, x, start, end) {
    if (start > end) {
        return false;
    }

    let mid = Math.floor((start + end) / 2);

    if (arr[mid + 1] >= x && arr[mid] <= x) {
        return {
            mid: mid,
            AfterMid: mid + 1,
        };
    }

    if (mid === 0 && arr[mid] > x) {
        return {
            mid: 0,
            left: 0,
        };
    }

    if (mid === end && arr[mid] < x) {
        return {
            mid: end,
        };
    }

    if (arr[mid] > x) {
        return BinarySearchForIndex(arr, x, start, mid - 1);
    } else return BinarySearchForIndex(arr, x, mid + 1, end);
};
