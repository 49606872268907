import _ from 'lodash';
import { store } from '../../store';

export const parseNotificationArguments = (n) => {
    return [
        n.notification_id,
        n.category,
        n.frequency,
        n.trigger,
        n.recipient_groups,
        n.recipient_users,
        n.asset_id,
        n.device_id,
        n.report_topic,
        n.alert_topic,
        n.entity_id,
        n.channel,
        n.message,
        n.mode,
        n.correlation_id,
    ];
};

export class Notification {
    constructor(
        notification_id,
        category,
        frequency = null,
        trigger = null, // set interface
        recipient_groups,
        recipient_users,
        asset_id,
        device_id,
        report_topic = '',
        alert_topic = '',
        entity_id,
        channel = null,
        message = null,
        mode,
        correlation_id = null
    ) {
        this.notification_id = notification_id;
        this.category = category;
        this.frequency = frequency;
        this.trigger = trigger;
        this.recipient_groups = recipient_groups || [];
        this.recipient_users = recipient_users || [];
        this.asset_id = asset_id;
        this.device_id = device_id;
        this.report_topic = report_topic;
        this.alert_topic = alert_topic;
        this.entity_id = entity_id;
        this.channel = channel;
        this.message = message;
        this.mode = mode;
        this.correlation_id = correlation_id;
    }

    get topic() {
        return this.report_topic || this.alert_topic || null;
    }

    get asset() {
        const {
            assets: { assets },
        } = store.getState();
        return assets[this.asset_id] || null;
    }

    get device() {
    // ignore if gateway notification
        if (this.category === 'gateway') return null;
        const {
            devices: { devices },
        } = store.getState();
        return devices[this.device_id] || null;
    }

    get metadatum() {
        return this.device
            ? this.device.metadata.find((m) => m.channel === this.channel)
            : null;
    }

    get correlation() {
        const {
            correlations: { correlations },
        } = store.getState();

        return correlations[this.correlation_id] || null;
    }

    get groups() {
        const {
            group: { list },
        } = store.getState();
        if (_.isEmpty(list)) return [];
        const groups = this.recipient_groups
            .map((group_id) => list[group_id])
            .filter((g) => g);
        return groups;
    }

    get users() {
        const {
            user: { list },
        } = store.getState();
        if (_.isEmpty(list)) return [];
        const users = this.recipient_users
            .map((user_id) => list[user_id])
            .filter((u) => u);
        return users;
    }

    get recipients() {
        return [...this.groups, ...this.users];
    }
}
