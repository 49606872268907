import classNames from "classnames"
import React from "react"

const ChartWrapper = (props: Props) => {
    const style = {
        height: props.height ? props.height : "100%",
        width: props.width ? props.width : "100%"
    }
    return <div
        id={props.id}
        className={classNames("chart-wrapper", props.className)} 
        style={style}
    >
        {props.children}
    </div>
}

interface Props {
    className?: string
    id?: string
    height?: number | string
    width?: number | string
    children: React.ReactNode
}

export default ChartWrapper