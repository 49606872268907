/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import { LabelConstants as K } from '../../../store/old/Labels/Labels.constants';
import PortalWrapper from '../../components/PortalWrapper';

const OFFSET_TOOLTIP = 5;

export default class DashboardLiveLabel extends React.Component {
    constructor(props) {
        super(props);

        this.state = { tooltip: false, position: { x: 0, y: 0 } };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseEnter(e) {
        this.setState({
            tooltip: true,
            position: {
                x: e.clientX + OFFSET_TOOLTIP,
                y: e.clientY + OFFSET_TOOLTIP,
            },
        });
    }

    handleMouseLeave() {
        this.setState({ tooltip: false });
    }

    handleMouseMove(e) {
        this.setState({
            position: {
                x: e.clientX + OFFSET_TOOLTIP,
                y: e.clientY + OFFSET_TOOLTIP,
            },
        });
    }

    get content() {
        const { from, label_values, output } = this.props.label;

        const sku = label_values.find(({ type }) => type === 'sku');
        const issue = label_values.find(({ type }) => type === 'issue');

        if (sku) {
            const {
                sku: { uom },
            } = sku;
            return `Output: ${output} ${uom}`;
        } else if (issue) {
            const now = moment();
            const duration = moment.duration(now.diff(moment(from))).as('minutes');
            return `Elapsed: ${duration.toFixed(0)} min`;
        } else {
            return `Output: ${output}`;
        }
    }

    get title() {
        const { attributes } = this.props.label;

        return Object.keys(attributes)
            .sort((a, b) => {
                if (a === K.TYPES.SKU) return -1;
                if (b === K.TYPES.SKU) return 1;
                if (a === K.TYPES.ISSUE) return -1;
                if (b === K.TYPES.ISSUE) return 1;
                if (a > b) return 1;
                if (b > a) return -1;
                return 0;
            })
            .map((k) => `${k.toUpperCase()}: ${attributes[k]}`);
    }

    get tooltipContent() {
        return (
            <div
                className="tag--tooltip d-flex flex-column"
                style={{
                    left: this.state.position.x,
                    top: this.state.position.y,
                }}
            >
                <div>{this.title.join(' | ')}</div>
                <div>{this.content}</div>
            </div>
        );
    }

    render() {
        const { issue } = this.props.label;

        return (
            <div className="indicator-tag">
                <span
                    className="tag--button text-uppercase px-2"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onMouseMove={this.handleMouseMove}
                    style={{
                        backgroundColor: issue ? issue.color : '#bbb',
                        color: issue ? '#fff' : '#222',
                    }}
                >
                    {this.state.tooltip && (
                        <PortalWrapper>{this.tooltipContent}</PortalWrapper>
                    )}
                    <span className="emphasise text-overflow-ellipsis">
                        {this.title.join(' | ')}
                    </span>
                    <span className="text-overflow-ellipsis">{this.content}</span>
                </span>
            </div>
        );
    }
}
