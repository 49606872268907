import CONSTANTS from '../../../../legacy/Constants';
import { getMapFromArr } from '../../../../legacy/utils/helpers';

export const CUSTOM_SPAN = 'Custom';
export const SHIFT = 'Shift';

export const LANGUAGE_OPTIONS = CONSTANTS.LANGUAGES.map((l) => {
    return {
        value: l.lang,
        label: l.nativeName,
    };
});

export const ControlsConstants = {
    ACTIONS: {
        TOGGLE_OEE2: 'TOGGLE_OEE2',
        TOGGLE_OEE_SKU: 'TOGGLE_OEE_SKU',
        INIT_CONTROL_PANEL: 'INIT_CONTROL_PANEL',
        SET_CONTROL_PANEL_STATE: 'SET_CONTROLS_STATE',
        CHANGE_DASHBOARD_MODE: 'CHANGE_DASHBOARD_MODE',
    },
    RESOLUTIONS: getMapFromArr(CONSTANTS.RESOLUTIONS, 'id'),
    PERIODS: {
        ...getMapFromArr(CONSTANTS.PERIODS, 'id'),
    },
};
