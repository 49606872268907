import { call, put, takeLatest } from 'redux-saga/effects';

import { getMapFromArr } from '../../../legacy/utils/helpers';

// MODEL
import { SkuChart, parseSkuChartArguments } from '../../../legacy/models';

// CONSTANT
import { SkuChartsConstants as K } from './SkuCharts.constant';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { api_bulkCreateUpdateSkuCharts, api_createSkuChart, api_getAssetSkuCharts, api_getSkuCharts, api_updateSkuChart } from './SkuCharts.services';
import { addSkuChartsResource, setSkuChartsResource } from './SkuCharts.action';

function* handleFetchSkuCharts(action) {
    try {
        const response = yield call(api_getSkuCharts, action.payload.entity_id, action.payload.query)

        const skuCharts = getMapFromArr(
            response.map(
                (s) => new SkuChart(...parseSkuChartArguments(s))
            ),
            'uuid'
        );

        yield put(setSkuChartsResource(skuCharts))

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchSkuChartsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_SKU_CHARTS,
        handleFetchSkuCharts
    );
}

function* handleFetchAssetSkuCharts(action) {
    try {
        const response = yield call(api_getAssetSkuCharts, action.payload.entity_id, action.payload.asset_id)

        const skuCharts = getMapFromArr(
            response.map(
                (s) => new SkuChart(...parseSkuChartArguments(s))
            ),
            'uuid'
        );

        yield put(addSkuChartsResource(skuCharts))

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchAssetSkuChartsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_SKU_CHARTS,
        handleFetchAssetSkuCharts
    );
}


function* handleCreateSkuChart(action) {
    try {
        const { payload } = action;

        const response = yield call(
            api_createSkuChart,
            payload.entity_id,
            payload.asset_id,
            payload.chart_id,
            payload.sku_id,
            payload.data
        );
        const created = new SkuChart(...parseSkuChartArguments(response));

        flash({
            message: `Create success`,
            status: 'success',
        });

        yield put(addSkuChartsResource({ [created.uuid]: created }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createSkuChartSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_SKU_CHART,
        handleCreateSkuChart
    );
}

function* handleUpdateSkuChart(action) {
    try {
        const { payload } = action;

        const response = yield api_updateSkuChart(
            payload.entity_id,
            payload.asset_id,
            payload.chart_id,
            payload.sku_id,
            payload.data
        );

        const updated = new SkuChart(...parseSkuChartArguments(response));

        flash({
            message: `Update success`,
            status: 'success',
        });

        yield put(addSkuChartsResource({ [updated.uuid]: updated }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateSkuChartSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_SKU_CHART,
        handleUpdateSkuChart
    );
}

function* handleBulkCreateSkuCharts(action) {
    try {
        const response = yield call(
            api_bulkCreateUpdateSkuCharts,
            action.payload.entity_id,
            action.payload.data
        );

        const skuCharts = getMapFromArr(
            response.map((item) => new SkuChart(...parseSkuChartArguments(item))),
            'uuid'
        );

        yield put(addSkuChartsResource(skuCharts));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* bulkCreateUpdateSkuChartsSaga() {
    yield takeLatest(
        K.ACTIONS.BULK_CREATE_UPDATE_SKU_CHARTS,
        handleBulkCreateSkuCharts
    );
}

// function* handleFetchSkus(action) {
//     try {
//         const { entity_id } = action.payload;
//         const response = yield call(api_getSkus, entity_id);

//         const skus = getMapFromArr(
//             response.map((s) => new Sku(...parseSkuArguments(s))),
//             'sku_id'
//         );

//         yield put(setSkusState(skus));

//         if (action.callback) {
//             yield action.callback(response);
//         }
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* fetchSkusSaga() {
//     yield takeLatest(
//         K.ACTIONS.GET_SKUS_REQUEST,
//         handleFetchSkus
//     );
// }

// function* handleCreateSku(action) {
//     try {
//         const response = yield api_createSku(action.payload.sku);
//         const sku = new Sku(...parseSkuArguments(response));

//         yield put(addSkusResource({ [sku.sku_id]: sku }));

//         if (action.callback) {
//             yield action.callback(response);
//         }
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* createSkuSaga() {
//     yield takeLatest(
//         K.ACTIONS.CREATE_SKU_REQUEST,
//         handleCreateSku
//     );
// }

// function* handleEditSku(action) {
//     try {
//         const response = yield api_updateSku(action.payload.sku);
//         const sku = new Sku(...parseSkuArguments(response));

//         yield put(addSkusResource({ [sku.sku_id]: sku }));

//         if (action.callback) {
//             yield action.callback(response);
//         }
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* editSkuSaga() {
//     yield takeLatest(
//         K.ACTIONS.UPDATE_SKU_REQUEST,
//         handleEditSku
//     );
// }

// function* handleDeleteSku(action) {
//     try {
//         const { sku_id } = action.payload.sku;
//         const response = yield api_deleteSku(sku_id);
//         yield put(removeSkusResource([sku_id]));

//         if (action.callback) {
//             yield action.callback(response);
//         }
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* deleteSkuSaga() {
//     yield takeLatest(
//         K.ACTIONS.DELETE_SKU_REQUEST,
//         handleDeleteSku
//     );
// }

// function* handleCreateBulkSku(action) {
//     try {
//         const appState = store.getState();
//         const { entity_id } = currentEntitySelector(appState);
//         const response = yield api_bulkCreateSku(action.payload.skus);

//         yield put(fetchSkus(entity_id));

//         if (action.callback) {
//             yield action.callback(response);
//         }
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* createBulkSkuSaga() {
//     yield takeLatest(
//         K.ACTIONS.BULK_CREATE_SKU_REQUEST,
//         handleCreateBulkSku
//     );
// }
