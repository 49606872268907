/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

// COMPONENTS
import { Logo } from '../Logo';

// SELECTORS
import { arrayGateways } from '../../../store/old/Gateway/Gateway.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

// ACTIONS
import { GetEntityGatewaysStatus } from '../../../store/old/Gateway/Gateway.action';

import shortLogoSrc from '../../../assets/images/auk-logo-short.png';
import AukTooltip from '../AukTooltip';

const { Option } = Select;


class GatewaySettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gw_idx: 0,
        };

        this.handleRefreshGatewayStatus();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.entity !== this.props.entity) {
            this.handleRefreshGatewayStatus();
        }
    };

    handleRefreshGatewayStatus = () => {
        const { entity, getEntityGatewayStatus } = this.props;

        if (!entity) return;

        getEntityGatewayStatus(entity.entity_id);
    };

    get gatewayOptions() {
        const { gateways } = this.props;

        const circle = (gw) => (
            <span
                className={`gateway-status-indicator ${gw.active ? 'active' : ''}`}
            />
        );

        return gateways.map((gw, idx) => {
            const label = (
                <span>
                    {circle(gw)}
                    {'  '}
                    {gw.serial_number}
                </span>
            );

            return { value: idx, label };
        });
    }

    get gatewayStatus() {
        const { gatewayOptions } = this;

        return (
            <div className="control-panel-item status">
                <div className="gateway" style={{ marginBottom: 2, flexGrow: 1 }}>
                    <AukTooltip.Help title="Refresh gateways" mouseEnterDelay={1}>
                        <div
                            className="mr-2 cursor-pointer"
                            onClick={this.handleRefreshGatewayStatus}
                        >
                            <i className="fas fa-sync-alt" />
                        </div>
                    </AukTooltip.Help>
                    {gatewayOptions.length ? (
                        <div style={{ flexGrow: 1 }}>
                            <Select options={gatewayOptions} defaultValue={this.state.gw_idx}>
                                {gatewayOptions.map((gw, idx) => (
                                    <Option key={idx} value={gw.value}>
                                        {gw.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.entity) return null;

        return (
            <div className="cp-settings">
                {this.gatewayStatus}
                <Logo className="short-logo" src={shortLogoSrc} />
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        gateways: arrayGateways(appState),
        entity: currentEntitySelector(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEntityGatewayStatus: (entity_id) => dispatch(GetEntityGatewaysStatus(entity_id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewaySettings);
