

// https://javascript.plainenglish.io/from-zero-to-dijkstra-part-2-a09e92a5ba8a

export default class PriorityQueue {
    values;

    constructor(values = []) {
        this.values = values;
    }

    enqueue(value, priority) {
        const item = new PriorityQueueNode(value, priority);
        this.values.push(item);
        this.bubbleUp();
    }

    bubbleUp() {
        let idx = this.values.length - 1;
        const item = this.values[idx]; // this is the newly enqueued node

        while (idx > 0) {
            let parentIdx = Math.floor((idx - 1) / 2);
            let parent = this.values[parentIdx];

            if (item.priority >= parent.priority) break;

            this.values[parentIdx] = item;
            this.values[idx] = parent;
            idx = parentIdx;
        }
    }

    dequeue() {
        let min = this.values[0];
        let end = this.values.pop();
        if (this.values.length > 0) {
            this.values[0] = end;
            this.sinkDown();
        }

        return min;
    }

    sinkDown() {
        let idx = 0;
        const len = this.values.length;
        const item = this.values[0]; // this is the new root

        while (true) {
            let leftChildIdx = 2 * idx + 1;
            let rightChildIdx = 2 * idx + 2;
            let leftChild, rightChild;
            let swap = null;

            if (leftChildIdx < len) {
                leftChild = this.values[leftChildIdx];

                if (leftChild.priority < item.priority) {
                    swap = leftChildIdx;
                }
            }

            if (rightChildIdx < len) {
                rightChild = this.values[rightChildIdx];

                if (
                    (!swap && rightChild.priority < item.priority) ||
          (leftChild && swap && rightChild.priority < leftChild.priority)
                ) {
                    swap = rightChildIdx;
                }
            }

            if (!swap) break;
            this.values[idx] = this.values[swap];
            this.values[swap] = item;
            idx = swap;
        }
    }
}

export class PriorityQueueNode {
    value;
    priority;

    constructor(value, priority) {
        this.value = value;
        this.priority = priority;
    }
}

// export const testPQ = [
//   new PriorityQueueNode('3', 3),
//   new PriorityQueueNode('9', 9),
//   new PriorityQueueNode('7', 7),
//   new PriorityQueueNode('6', 6),
//   new PriorityQueueNode('12', 12),
//   new PriorityQueueNode('10', 10),
//   new PriorityQueueNode('11', 11),
// ];
