/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { PanelBody, PanelFooter, PanelHeader } from './Panel';

import './FormModal.scss';
import AukButton from './AukButton';
import ModalPanel from './ModalPanel';

const FormModal = ({
    title,
    children,
    toggle,
    show,
    className,
    submit,
    render,
}) => {
    const [submitted, setSubmitState] = useState(false);

    className = `${className || ''} ${submitted ? 'submitted' : ''}`;

    const handleClickSave = () => {
        setSubmitState(true);
        submit();
    };

    if (!show) return null;

    return (
        <ModalPanel class="auk-form-modal">
            <PanelHeader>{title}</PanelHeader>
            <PanelBody className="fields">
                {render
                    ? render({ submitted })
                    : React.Children.map(children, (child) =>
                        React.cloneElement(child, { submitted })
                    )}
            </PanelBody>
            <PanelFooter className="p-3">
                <AukButton.Cancel onClick={toggle} />
                <AukButton.Save onClick={handleClickSave} />
            </PanelFooter>
        </ModalPanel>
    );
};

export default FormModal;
