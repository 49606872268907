import { take } from 'redux-saga/effects';

export function* watcherBuilder(
    type,
    cb
) {
    while (true) {
        const action = yield take(type);
        yield cb(action);
    }
}
