/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import { isNumber, keys, values } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import Circle from '../../components/Circle';
import ColorLegendSequential from '../../Charts/v1/ColorLegendSequential';
import VSpreadsheet from '../../components/VSpreadsheet';
import { ForceChart } from '../../Charts/v1';
import { HardwareManagementContext } from './HardwareManagementContext';

export const HardwareMesh = (props) => {
    const { data } = props;
    const { NODE_COLORS } = useContext(HardwareManagementContext);

    const chartData = useMemo(() => {
        const nodes = data.nodes.map((n) => ({ ...n, color: NODE_COLORS[n.type] }));
        const links = keys(data.links).reduce((acc, curr) => {
            const [source, target] = curr.split('-');
            return acc.concat({ source, target, value: data.links[curr].rssi });
        }, []);

        return { nodes, links };
    }, [data]);

    return (
        <>
            <MeshChartLegend />
            <ForceChart
                data={chartData}
                linkDomain={[100, 0]}
                linkRange={[0, 1]}
                nodeAccessor={(n) => n.node}
                getTooltipContent={(hoveredNode, linkedNodes) => {
                    const { node, asset_name } = hoveredNode;

                    const linked = linkedNodes
                        .map(
                            (l) =>
                                `<li>${l.target === node ? l.source : l.target}: ${
                                    l.value
                                }</li>`
                        )
                        .join('');

                    const title = asset_name ? `[${asset_name}] ${node}` : node;

                    return `<div class="mb-2">${title}</div>` + '<ul>' + linked + '</ul>';
                }}
            />
        </>
    );
};

const MeshChartLegend = () => {
    const { NODE_TYPES, NODE_COLORS } = useContext(HardwareManagementContext);
    return (
        <div className="mesh-legend">
            {values(NODE_TYPES).map((t) => {
                return (
                    <div key={t} className="d-flex align-items-center">
                        <Circle color={NODE_COLORS[t]} />
                        {t}
                    </div>
                );
            })}
        </div>
    );
};

const nodeAccessor = (n) => n.node;
const SCALE = () => d3.scaleSequential(d3.interpolateRdYlGn);
const DOMAIN = [-100, -40];

export const HardwareMeshTable = (props) => {
    const { nodes, cellValues } = props.data;

    const renderBodyCell = useCallback(
        ({ value }) => {
            const scale = SCALE().domain(DOMAIN);

            return (
                <div
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                    style={{
                        backgroundColor: isNumber(value) ? scale(value) : '#eee',
                        border: '1px solid #fff',
                        fontSize: 10,
                        opacity: 0.8,
                    }}
                >
                    {isNumber(value) ? value : ''}
                </div>
            );
        },
        []
    );

    const renderAxisCell = useCallback(
        ({ value }) => {
            const node = nodes.find((n) => n.node === value);
            const title = `[${
                node.asset_name ? node.asset_name : node.type.toUpperCase()
            }]: ${node.node}`;

            return (
                <div style={{ cursor: 'pointer', fontSize: 12 }} title={title}>
                    {value.substring(value.length - 4, value.length)}
                </div>
            );
        },
        [nodes]
    );

    return (
        <div className="d-flex flex-column w-100" style={{ position: 'relative' }}>
            <div style={{ flexGrow: 1 }}>
                <VSpreadsheet
                    cellWidth={50}
                    cellHeight={50}
                    headerHeight={50}
                    axisWidth={50}
                    data={cellValues}
                    columns={nodes}
                    rows={nodes}
                    columnAccessor={nodeAccessor}
                    rowAccessor={nodeAccessor}
                    renderBodyCell={renderBodyCell}
                    renderHeaderCell={renderAxisCell}
                    renderLeftSideCell={renderAxisCell}
                />
            </div>
            <div
                className="d-flex justify-content-center"
                style={{
                    flexShrink: 0,
                    marginTop: '2em',
                    marginBottom: '1em',
                    opacity: 0.8,
                }}
            >
                <ColorLegendSequential
                    scale={SCALE()}
                    domain={DOMAIN}
                    indicators={['-100', '-dBm', '0']}
                />
            </div>
        </div>
    );
};
