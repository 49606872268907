import moment from 'moment';
import { csvDatetimeFormat } from '../../utils/helpers';
import { oeeCSV } from '../../utils/oee';
import { ceil } from 'lodash';

const paretoCSV = (oeeWaterfallData, oeeParetoData, maintenanceData, block) => {
    let concurrentProductionLabels = [];

    const lossesSection = oeeCSV(oeeWaterfallData);

    const paretoHeaders = 'Issue, Total Effective Duration (hrs), Occurrence\n';
    const paretoSection =
    paretoHeaders +
    oeeParetoData
        .map((d) => {
            const issueName = d.issue && d.issue.name ? d.issue.name : 'DELETED';
            const totalEfDuration = (d.total_effective_duration / 3600).toFixed(2);
            return `${issueName}, ${totalEfDuration}, ${d.occurrence}`;
        })
        .join('\n');

    const labels = block.oee.effective_labels
        .map((d) => {
            const { from, to, issue, output } = d;
            return [
                issue ? issue : 'DELETED',
                moment(from).format(csvDatetimeFormat),
                d.isComplete ? moment(to).format(csvDatetimeFormat) : '-',
                d.isComplete ? d.getDuration('hours').toFixed(2) : '-',
                output,
                concurrentProductionLabels.join(','),
            ];
        })
        .join('\n');

    const labelSection =
    '(OEE) Cause,Start,End,Duration (hrs),Output,' + '\n' + labels;

    let maintenanceSection = '';

    if (maintenanceData) {
        const mtbfRow = `MTBF (hrs),${secondsToHours(maintenanceData.mtbf)}\nMTBF (min),${secondsToMinutes(maintenanceData.mtbf)}\n`
        const mttrRow = `MTTR (hrs),${secondsToHours(maintenanceData.mttr)}\nMTTR (min),${secondsToMinutes(maintenanceData.mttr)}\n`
        maintenanceSection = mtbfRow + mttrRow;
    }

    return [lossesSection, paretoSection, labelSection, maintenanceSection].join('\n\n');
};

const secondsToMinutes = seconds => seconds < 0 ? '--' : ceil(seconds / 60, 1);
const secondsToHours = seconds => seconds < 0 ? '--' : ceil(seconds / 3600, 1)

export default paretoCSV;
