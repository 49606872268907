import classNames from 'classnames';
import React from 'react';
import './UnauthLayout.scss'
import { Layout } from 'antd';

const UnauthLayout = (props) => {
    return (
        <Layout className={classNames(['unauth-layout', props.className])} style={props.style}>
            {props.children}
        </Layout>
    );
}

export default UnauthLayout