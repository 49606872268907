import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { keyBy, pickBy } from "lodash"
import { type UserPreferenceModel } from "../../api/models/preference.model"

interface PreferencesState {
    loading: boolean
    preferences: Record<string | number, any>
}

const initialState: PreferencesState = {
    loading: false,
    preferences: {},
}

export const preferencesSlice = createSlice({
    name: "preferences",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        clear: () => {
            return initialState
        },
        set: (state, action: PayloadAction<UserPreferenceModel[]>) => {
            state.preferences = keyBy(action.payload, "preferenceId")
        },
        add: (state, action: PayloadAction<UserPreferenceModel[]>) => {
            state.preferences = { ...state.preferences, ...keyBy(action.payload, "preferenceId") }
        },
        remove: (state, action: PayloadAction<number>) => {
            state.preferences = pickBy(state.preferences, preference => preference.preferenceId !== action.payload)
        },
    },
})

export const { clear, set, setLoading, add, remove } = preferencesSlice.actions

export default preferencesSlice.reducer
