/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';

import { defaultFilterOption } from '../utils/helpers';
import CONSTANTS from '../Constants';

const CODES = CONSTANTS.COUNTRYCODES.map(({ name, dial_code }) => ({
    label: `(${dial_code}) ${name}`,
    value: `${dial_code}`,
}));

const AukInputPhone = (props) => {
    const { value, onChange } = props;

    const [countryCode, setCountryCode] = useState(
        value ? value.country_code : undefined
    );
    const [mobile, setMobile] = useState(value ? value.mobile : null);

    useEffect(() => {
        onChange && onChange({ country_code: countryCode, mobile });
    }, [countryCode, mobile]);

    return (
        <Input.Group compact className="auk-input-phone">
            <div className="d-flex w-100">
                <Select
                    style={{ width: 180 }}
                    placeholder="Country"
                    options={CODES}
                    filterOption={defaultFilterOption}
                    showSearch
                    value={countryCode}
                    onChange={setCountryCode}
                    allowClear
                />
                <Input
                    placeholder="Phone"
                    style={{ flexGrow: 1 }}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value.replace(/\D/gm, ''))}
                    maxLength={20}
                />
            </div>
        </Input.Group>
    );
};

export default AukInputPhone;
