
import { store } from '../../store';
import { uiDatetimeFormat } from '../utils/helpers';
import moment from 'moment';

class Schedule {
    constructor(
        from,
        to,
        recurring,
        recur_start,
        recur_end,
        frequency,
        assets = [],
        updated_at,
        updated_by
    ) {
        this.from = from;
        this.to = to;
        this.recurring = recurring;
        this.recur_start = recur_start;
        this.recur_end = recur_end;
        this.frequency = frequency;
        this.assets = assets;
        this.updated_at = updated_at;
        this.updated_by = updated_by;
    }

    get linkedAssets() {
        const {
            assets: { assets: assetsState },
        } = store.getState();

        return this.assets
            .map((asset_id) => assetsState[asset_id])
            .filter((a) => a);
    }

    get userName() {
        const {
            user: { list },
        } = store.getState();

        const user = list[this.updated_by];
        return user ? user.nameFull : 'Deleted User';
    }

    get lastUpdated() {
        return moment(this.updated_at).format(uiDatetimeFormat);
    }

    get isCompleted() {
        const { to, recurring, recur_end } = this;

        const now = new moment();

        if (recurring) {
            return now.isSameOrAfter(moment(recur_end));
        }

        return now.isSameOrAfter(moment(to));
    }

    get isFuture() {
        const { from, recurring, recur_start } = this;

        const now = new moment();
        const startTime = moment(from);

        if (recurring) {
            const startHour = startTime.hour();
            const startMinute = startTime.minute();
            const start = moment(recur_start).set({
                hour: startHour,
                minute: startMinute,
            });
            return now.isBefore(start);
        }

        return now.isBefore(moment(startTime));
    }
}

export const parseShiftArguments = (s) => {
    return [
        s.shift_id,
        s.shift_name,
        s.from,
        s.to,
        s.recurring,
        s.recur_start,
        s.recur_end,
        s.frequency,
        s.assets.filter((a) => a).map((a) => (a.asset_id ? a.asset_id : a)),
        s.updated_at,
        s.updated_by,
    ];
};

export class Shift extends Schedule {
    constructor(
        shift_id,
        shift_name,
        from,
        to,
        recurring,
        recur_start,
        recur_end,
        frequency,
        assets = [],
        updated_at,
        updated_by
    ) {
        super(
            from,
            to,
            recurring,
            recur_start,
            recur_end,
            frequency,
            assets,
            updated_at,
            updated_by
        );

        this.shift_id = shift_id;
        this.shift_name = shift_name;
    }

    get id() {
        return this.shift_id;
    }
}

export const parseDowntimeArguments = (dt) => {
    return [
        dt.downtime_id,
        dt.downtime_name,
        dt.from,
        dt.to,
        dt.recurring,
        dt.recur_start,
        dt.recur_end,
        dt.frequency,
        dt.assets.filter((a) => a).map((a) => (a.asset_id ? a.asset_id : a)),
        dt.updated_at,
        dt.updated_by,
    ];
};

export class Downtime extends Schedule {
    constructor(
        downtime_id,
        downtime_name,
        from,
        to,
        recurring,
        recur_start,
        recur_end,
        frequency,
        assets = [],
        updated_at,
        updated_by
    ) {
        super(
            from,
            to,
            recurring,
            recur_start,
            recur_end,
            frequency,
            assets,
            updated_at,
            updated_by
        );

        this.downtime_id = downtime_id;
        this.downtime_name = downtime_name;
    }

    get id() {
        return this.downtime_id;
    }
}

export class AssetWorkSchedule {
    constructor(props) {
        this.shift_id = props.shift_id;
        this.parent_shift_id = props.parent_shift_id;
        this.shift_name = props.shift_name;
        this.from = moment(props.from);
        this.to = moment(props.to);
        this.is_live = props.is_live;
        this.targets = props.targets || [];
    }

    get hasTargetsSet() {
        return this.targets.length;
    }
}
