import { CloseOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Form, Input, Select } from 'antd';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import AukButton from '../../../components/AukButton';

import './OpcuaForm.scss'
import ShortUniqueId from 'short-unique-id';
import { DeviceConstants as K} from '../../../../store/old/Devices/Devices.constants';
import { CHART_TYPES } from '../../../Charts';
import BatchRecognitionInputs, { BatchRecognitionFormLabel, validateBatchRecognition } from './BatchRecognitionInputs';
import ColorPicker from '../../../components/ColorPicker';
import ControlLimitsInputs, { ControlLimitsFormLabel, validateControlLimits } from './ControlLimitsInputs';
import { getDefaultSpeedAttribute } from '../../../models';
import { isValidChartTitle } from '../../../utils/device';
import { getDefaultChartType } from '../../../utils/charts';
import { pickBy, values } from 'lodash';
import { useSelector } from 'react-redux';

const getFormItemPath = (fieldName, name) => ([fieldName, name])

const OPCUA_INPUT_VALIDATE = {
    CHART_TITLE: () => [
        {
            required: true,
            message: 'Chart title is required.',
        },
        {
            whitespace: true,
            message: 'Chart title is required.',
        },
        () => ({
            validator: (_, value = '') => {
                if (!isValidChartTitle(value))
                    return Promise.reject(
                        new Error('Chart title cannot contain #%&*?|<>{}\\.')
                    );
                return Promise.resolve();
            },
        })
    ],
    ITEM_ID: () => [
        {
            required: true,
            message: 'Item ID is required.',
        },
        {
            whitespace: true,
            message: 'Item ID is required.',
        },
        () => ({
            validator: (_, value) => {
                if (!isValidChartTitle(value = ''))
                    return Promise.reject(
                        new Error('Item ID cannot contain #%&*?|<>{}\\.')
                    );
                return Promise.resolve();
            },
        })
    ],
    GATEWAY: () => [
        {
            required: true,
            message: 'Gateway is required.',
        },
    ],
    MODE: () => [
        {
            required: true,
            message: 'Mode is required.',
        },
    ],
    SAMPLING_RATE: () => [
        {
            required: true,
            message: 'Sampling rate is required.',
        },
    ],
    BAT_REC: (fieldName) => [
        (form) => ({
            validator: (_, value) => {
                const mode = form.getFieldValue(['list', fieldName, 'mode']);
                return validateBatchRecognition(mode, value);
            },
        }),
    ],
    CONTROL_LIMITS: () => [
        () => ({
            validator: (_, value) => validateControlLimits(value),
        }),
    ],
};

const OpcuaForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();

    const getFormData = (data) => {
        return data.list.map(d => {
            const device = {
                device_id: d.deviceId,
                mac_address: d.macAddress,
                sampling_rate: d.samplingRate,
                gateway_id: d.gatewayId
            };

            const metadatum = {
                metadata_id: d.metadataId,
                channel: 0,
                type: 'opcua',
                chart_title: d.chartTitle,
                item_id: d.itemId,
                mode: d.mode,
                control_limits: d.controlLimits,
                invert: d.invert,
                units: d.units,
                color: d.color,
                batRec: d.batRec,
                speed: d.speed,
                chart_type: d.chartType || getDefaultChartType(d.mode)
            }

            if (d.mode !== '3a') {
                delete metadatum.batRec;
            }

            if (d.mode !== '2a') {
                delete metadatum.invert;
            }

            if (!d.metadataId) {
                delete metadatum.metadata_id;
                metadatum.speed = getDefaultSpeedAttribute(d.mode)
            } else {
                const stored = (props.data || []).find(record => record.metadataId === d.metadataId);
                metadatum.speed = stored.mode === d.mode ? d.speed : getDefaultSpeedAttribute(d.mode)
            }

            return {
                device: pickBy(device, (v) => v !== undefined),
                metadata: [pickBy(metadatum, (v) => v !== undefined)]
            }
        })
    }

    useImperativeHandle(ref, () => ({
        form,
        getFormData() {
            return getFormData(form.getFieldsValue(true))
        }
    }))

    return <Form
        ref={ref}
        name="opcuaForm"
        form={form}
        className="opcua-form"
        hidden={props.hidden}
        preserve
        onFinish={async () => {
            try {
                await form.validateFields()
                const errors = form.getFieldsError().filter(item => item.errors.length)
                if (!errors.length) {
                    props.submit(getFormData(form.getFieldsValue(true)))
                    return;
                }
            } catch(e) {
            }
        }}
    >
        <div>
            <OpcuaFormHeader/>
            <Form.List 
                name="list"
                initialValue={props.data || []}
            >
                {(fields, { add, remove }) => {
                    return (
                        <>
                                {fields.map((field, i) => {
                                    return (
                                        <Form.Item
                                            className='opcua-form-item'
                                            noStyle
                                            key={field.key}
                                            rules={[]}
                                            {...field}
                                        >
                                            <OpcuaFormRow
                                                index={i}
                                                field={field}
                                                remove={() => { 
                                                    remove(field.name)
                                                    // form.setFields([{ name: getFormItemPath(field.name, 'deviceId'), value: undefined }]) 
                                                    // form.setFields([{ name: getFormItemPath(field.name, 'metadataId'), value: undefined }]) 
                                                    // form.setFields([{ name: getFormItemPath(field.name, 'macAddress'), value: undefined }]) 
                                                }}
                                            />
                                        </Form.Item>
                                    );
                                })}

                                <Form.Item className='mb-0 mt-3'>
                                    <AukButton.Blue
                                        ghost
                                        onClick={() => { add(generateDefaultOpcua()) }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add OPCUA Input
                                    </AukButton.Blue>
                                </Form.Item>
                        </>
                    );
                }}
            </Form.List>
            {
                props.showSubmit ? <AukButton.Save htmlType="submit" className="mt-3 float-right"/> : null
            }
        </div>
    </Form>
})

export default OpcuaForm;

const OpcuaFormHeader = () => {
    return <div className="opcua-form-row opcua-form-row-header">
        <div className='opcua-form-row-item opcua-form-row-item--5'>No.</div>
        <div className='opcua-form-row-item opcua-form-row-item--15'>Name</div>
        <div className='opcua-form-row-item opcua-form-row-item--25'>Item ID</div>
        <div className='opcua-form-row-item opcua-form-row-item--15'>Gateway</div>
        <div className='opcua-form-row-item opcua-form-row-item--15'>Mode</div>
        <div className='opcua-form-row-item opcua-form-row-item--15'>Push Rate</div>
        <div className='opcua-form-row-item opcua-form-row-item--5'></div>
        <div className='opcua-form-row-item opcua-form-row-item--5'></div>
    </div>
}

const OpcuaFormRow = (props) => {
    const initialValues = useMemo(() => props.value, [])
    const [expanded, setExpanded] = useState('');

    const mode = useMemo(() => props.value.mode, [props.value])
    const gateways = useSelector(appState => appState.gateway.gateways)

    const opcuaGateways = useMemo(() => {
        return values(gateways)
            .filter(gw => gw.interface === 'opcua')
            .map(gw => ({
                label: gw.serial_number,
                value: gw.device_id
            }))
    }, [gateways])

    return <Collapse 
        activeKey={[expanded]}
    >
        <Collapse.Panel
            forceRender
            showArrow={false}
            key="1"
            header={
                <div className="opcua-form-row">
                    <Form.Item
                        hidden
                        initialValue={initialValues.deviceId}
                        name={getFormItemPath(props.field.name, 'deviceId')}
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        hidden
                        initialValue={initialValues.metadataId}
                        name={getFormItemPath(props.field.name, 'metadataId')}
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        hidden
                        initialValue={initialValues.macAddress}
                        name={getFormItemPath(props.field.name, 'macAddress')}
                    >
                        <Input disabled/>
                    </Form.Item>
                    <div className='opcua-form-row-item opcua-form-row-item--5'>
                        {props.index + 1}
                    </div>
                    <Form.Item
                        initialValue={initialValues.chartTitle}
                        name={getFormItemPath(props.field.name, 'chartTitle')}
                        className='opcua-form-row-item opcua-form-row-item--15' 
                        rules={OPCUA_INPUT_VALIDATE.CHART_TITLE()}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={initialValues.itemId}
                        name={getFormItemPath(props.field.name, 'itemId')}
                        className='opcua-form-row-item opcua-form-row-item--25'
                        rules={OPCUA_INPUT_VALIDATE.ITEM_ID()}
                    >
                        <Input />
                    </Form.Item>
                    
                    <Form.Item
                        name={getFormItemPath(props.field.name, 'gatewayId')}
                        initialValue={opcuaGateways[0]?.value}
                        className='opcua-form-row-item opcua-form-row-item--15'
                        rules={OPCUA_INPUT_VALIDATE.GATEWAY()}
                    >
                        <Select
                            options={opcuaGateways}
                            disabled={opcuaGateways.length === 1}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={initialValues.mode}
                        name={getFormItemPath(props.field.name, 'mode')}
                        className='opcua-form-row-item opcua-form-row-item--15'
                        rules={OPCUA_INPUT_VALIDATE.MODE()}
                    >
                        <Select className="w-100 h-100" options={K.MODES} />
                    </Form.Item>
                    <Form.Item
                        initialValue={initialValues.samplingRate}
                        name={getFormItemPath(props.field.name, 'samplingRate')}
                        className='opcua-form-row-item opcua-form-row-item--15'
                        rules={OPCUA_INPUT_VALIDATE.SAMPLING_RATE()}
                    >
                        <Select className="w-100 h-100" options={K.SAMPLING_RATES} />
                    </Form.Item>
                    <Form.Item className='opcua-form-row-item opcua-form-row-item--5'>
                        <span
                            className="d-flex justify-content-center align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <EllipsisOutlined
                                onClick={() => setExpanded(expanded ? '' : '1')}
                                style={{ transform: 'rotate(180)' }}
                            />
                        </span>
                    </Form.Item>
                    <Form.Item className='opcua-form-row-item opcua-form-row-item--5'>
                        <span
                            className="d-flex justify-content-center align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <CloseOutlined onClick={props.remove}/>
                        </span>
                    </Form.Item>
                </div>
            }
        >
            <div className="d-flex flex-column px-3">
                <Form.Item 
                    name={getFormItemPath(props.field.name, 'chartType')}
                    label="Chart Type"
                    initialValue={initialValues.chartType}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Select className="w-100 h-100" name="chartType" options={CHART_TYPES}/>
                </Form.Item>
                <Form.Item
                    name={getFormItemPath(props.field.name, 'color')}
                    label="Chart Color"
                    initialValue={initialValues.color}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <ColorPicker />
                </Form.Item>
                <Form.Item
                    name={getFormItemPath(props.field.name, 'units')}
                    label="Unit Measure"
                    initialValue={initialValues.units}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Input
                        className="w-100"
                        placeholder="e.g. Celcius"
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    hidden={mode !== '3a'}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    label={<BatchRecognitionFormLabel />}
                    name={getFormItemPath(props.field.name, 'batRec')}
                    initialValue={initialValues.batRec}
                    rules={OPCUA_INPUT_VALIDATE.BAT_REC(props.field.name)}
                >
                    <BatchRecognitionInputs />
                </Form.Item>
                <Form.Item
                    hidden={mode !== '3a'}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    label={<ControlLimitsFormLabel />}
                    name={getFormItemPath(props.field.name, 'controlLimits')}
                    initialValue={initialValues.controlLimits}
                    rules={OPCUA_INPUT_VALIDATE.CONTROL_LIMITS()}
                >
                    <ControlLimitsInputs />
                </Form.Item>
                <Form.Item
                    name={getFormItemPath(props.field.name, 'invert')}
                    label="Invert"
                    initialValue={initialValues.invert}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    hidden={mode !== '2a'}
                >
                    <Select className="w-100 h-100" options={K.INVERT} />
                </Form.Item>
            </div>
        </Collapse.Panel>
    </Collapse>
    
    

}

const generateDefaultOpcua = () => {
    const macAddress = new ShortUniqueId({ length: 16 }).rnd();
    return {
        macAddress: macAddress,
        batRec: { upp: 99999, low: 0.001, in: 1, out: 1 }
    }
}

export const deviceToOpcuaInputAdaptor = (device) => {
    const [metadatum] = device.metadata || [];

    return {
        deviceId: device.device_id,
        metadataId: metadatum?.metadata_id,
        macAddress: device.mac_address,
        gatewayId: device.gateway_id,
        samplingRate: device.sampling_rate,
        itemId: metadatum?.item_id,
        chartTitle: metadatum?.chart_title,
        chartType: metadatum?.chart_type,
        color: metadatum?.color,
        mode: metadatum?.mode,
        controlLimits: metadatum?.control_limits,
        invert: metadatum?.invert,
        units: metadatum?.units,
        batRec: metadatum?.batRec,
        speed: metadatum?.speed,
    }
}