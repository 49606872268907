/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import ProgressIndicator from "../../../ProgressIndicator/ProgressIndicator";

const TIME_INCREMENT_MS = 250;
const DismissTimerIndicator = ({ dismissMs }) => {
    const intervalRef = useRef();
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCurrentTime((oldCurrentTime) => oldCurrentTime+TIME_INCREMENT_MS);
        }, TIME_INCREMENT_MS);

        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (currentTime >= dismissMs) {
            clearInterval(intervalRef.current);
        }
    }, [currentTime]);

    return <ProgressIndicator className="actions-dismiss-timer"
        trailColor="#e6f4ff"
        percent={currentTime / dismissMs * 100}
        strokeWidth={16}
        width={16}/>
}

export default DismissTimerIndicator;
