/* eslint-disable react/prop-types */

import React from 'react';
import { Collapse, Popconfirm } from 'antd';
import AukButton from './AukButton';
import './DangerZone.scss';

export const DangerZone = (props) => {
    const { header, collapsible } = props;
    const items = <div className="danger-zone">{props.children}</div>;

    return (
        <div className="danger-zone-container">
            {collapsible ? (
                <Collapse ghost bordered={true}>
                    <Collapse.Panel header={header}>{items}</Collapse.Panel>
                </Collapse>
            ) : (
                <div className="d-flex flex-column w-100">
                    <div className="danger-zone__header w-100">{header}</div>
                    {items}
                </div>
            )}
        </div>
    );
};

export const DangerZoneItem = (props) => {
    return (
        <div className="danger-zone__item d-flex justify-content-between">
            <div className="danger-zone__item__body d-flex flex-column">
                <div className="danger-zone__item__body__title">{props.title}</div>
                <div className="danger-zone__item__body__description">
                    {props.description}
                </div>
            </div>
            <div className="d-flex align-items-center danger-zone__item__button">
                {props.button || (
                    <Popconfirm
                        title={props.confirmText}
                        onConfirm={props.handleClick}
                        okText="Yes"
                        cancelText="No"
                    >
                        <AukButton.Red size="small">{props.action}</AukButton.Red>
                    </Popconfirm>
                )}
            </div>
        </div>
    );
};
