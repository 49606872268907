/* eslint-disable react/prop-types */

import React from 'react';
import { StopOutlined } from '@ant-design/icons';
import { Empty } from 'antd';

import './None.scss';


const FlexCenteredContainer = ({ className, style, children }) => (
    <div
        className={`d-flex align-items-center justify-content-center ${
            className || ''
        }`}
        style={style}
    >
        {children}
    </div>
);

export const NoAccess = (props) => {
    const { className, style, description } = props;

    return (
        <FlexCenteredContainer className={className} style={style}>
            <Empty
                image={
                    <StopOutlined style={{ fontSize: 40, height: 40, opacity: 0.8 }} />
                }
                description={
                    <span style={{ fontSize: 12 }}>
                        {description || 'Access Restricted'}
                    </span>
                }
            />
        </FlexCenteredContainer>
    );
};

export const NoData = (props) => {
    const { className, style, description } = props;

    return (
        <FlexCenteredContainer className={className} style={style}>
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                    <span style={{ fontSize: 12 }}>{description || 'No Data'}</span>
                }
            />
        </FlexCenteredContainer>
    );
};
