/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { Badge, Dropdown, Menu, Modal } from 'antd';
import ReactMarkdown from 'react-markdown';

import { MailOutlined } from '@ant-design/icons';

import './Inbox.scss';

const Inbox = (props) => {
    const { items, titleAccessor, contentAccessor, showDot, placement } = props;
    const [selection, setSelection] = useState(null);

    const dot = useMemo(() => showDot(items), [items]);
    const modalTitle = useMemo(
        () => (selection ? titleAccessor(selection) : ''),
        [selection]
    );

    return (
        <div className="auk-inbox">
            <Dropdown
                trigger={['click']}
                placement={placement}
                overlay={
                    <Menu
                        style={{ width: 240 }}
                        onClick={({ domEvent }) => domEvent.stopPropagation()}
                    >
                        {items.map((d, i) => (
                            <Menu.Item
                                style={{ fontSize: 12 }}
                                key={i}
                                onClick={() => setSelection(d)}
                            >
                                {titleAccessor(d)}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <Badge dot={dot}>
                    <MailOutlined style={props.style} />
                </Badge>
            </Dropdown>
            <Modal
                className="auk-modal--small"
                style={{ width: '60vw' }}
                title={modalTitle}
                visible={!!selection}
                onOk={() => setSelection(null)}
                onCancel={() => setSelection(null)}
            >
                <ReactMarkdown className="markdown-body">
                    {selection && contentAccessor(selection)}
                </ReactMarkdown>
            </Modal>
        </div>
    );
};

Inbox.defaultProps = {
    showDot: () => false,
    placement: 'bottomRight',
};

export default Inbox;
