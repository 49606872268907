import React, { useCallback, useMemo, useRef, useState } from "react"
import { LabelRangeZoom, Labeller, LabellerBody, LabellerBodyItem, LabellerHeader, LabellerHeaderItem } from "./components"
import { checkFutureLabel, compileLabel, fetchAssetSkuLabels, getSpan } from "../../utils/labels"
import { Label } from "../../models"
import { isEmpty, pickBy } from "lodash"
import { LabelNotesInput } from "."
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import BlockModel from "../../../api/models/block.model"
import { SaveFilled } from "@ant-design/icons"
import { showLabelNotesSelector } from "../../../store/old/Labels/Labels.selector"
import AukButton from "../../components/AukButton"
import { flash } from "../../components/Flash"
import { AnyFunction } from "../../../types/any"
import { createBlockLabels } from "../../../store/old/UI/block/block.action"
import { getMapFromArr } from "../../utils/helpers"
import CONSTANTS from "../../Constants"
import { skusSelector } from "../../../store/old/Sku/Sku.selector"
import AssetsChecklist from "../../../routes/block/components/AssetsChecklist"
import ProductionLabelListItem from "./components/ProductionLabelListItem"
import ProductionFieldsInputs from "./components/ProductionFieldsInputs"
import PopoverBulkTagging from "./components/PopoverBulkTagging"

const BlockProductionLabeller = (props: Props) => {
    return (
        <Labeller className="issue-labeller" id="issueLabeller">
            {props.children}
        </Labeller>
    )
}

interface Props {
    children: React.ReactNode;
}

const BlockProductionLabellerHeader = (props: HeaderProps) => {
    const spanDuration = useMemo(() => getSpan(props.range), [props.range])

    return (
        <LabellerHeader className="flex-column">
            <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
            <LabellerHeaderItem label="Range">
                <LabelRangeZoom range={props.range} />
            </LabellerHeaderItem>
        </LabellerHeader>
    )
}

interface HeaderProps {
    range: Date[];
}

const BrushedOutputSummary = (props: SummaryProps) => {
    const value = props.brushedData.reduce((acc, curr) => acc += curr.val, 0)
    return <LabellerBodyItem header="Data">
        Total: {value}
    </LabellerBodyItem>
}

interface SummaryProps {
    brushedData: any[];
}

export const BlockProductionLabelView = (props: ViewProps) => {
    return (
        <BlockProductionLabeller>
            <BlockProductionLabellerHeader range={props.range} />
            <LabellerBody>
                <BrushedOutputSummary brushedData={props.brushedData} />
                <LabellerBodyItem header={"Label"}>
                    <ProductionLabelListItem data={props.selection} readOnly={true}/>
                </LabellerBodyItem>
                <LabellerBodyItem header="Notes">
                    <LabelNotesInput
                        value={props.selection.notes}
                        disabled={true}
                        showCount={false}
                    />
                </LabellerBodyItem>
            </LabellerBody>
        </BlockProductionLabeller>
    )
}

interface ViewProps extends SummaryProps, HeaderProps {
    selection: Label;
}

export const BlockProductionLabelCreate = (props: CreateProps) => {
    const dispatch = useAppDispatch()
    const checklistRef = useRef()
    const showLabelNotes = useAppSelector(showLabelNotesSelector)
    const skus = useAppSelector(skusSelector)

    const [checkedKeys, setCheckedKeys] = useState<(string | number)[]>([])
    
    const [tags, setTags] = useState<ProductionLabelValues | null>(null)
    const [notes, setNotes] = useState("")

    const handleSave = useCallback(() => {
        if (!props.block.children) return

        const values = {
            ...pickBy(tags, (v) => v && `${v}`.trim()),
        }

        if (isEmpty(values))
            return flash({
                message: "Inputs required to commence tagging",
                status: "warning",
            })

        if (values.sku && !skus[values.sku])
            return flash({
                message: "Inputs required to commence tagging",
                status: "warning",
            })

        if (showLabelNotes) values.notes = notes

        const payload = compileLabel({
            from: props.range[0],
            to: props.range[1],
            values,
        })

        if (checkFutureLabel(payload))
            return flash({
                message: "Cannot create label in future time.",
                status: "warning",
            })

        const blocksToAssets = getMapFromArr(props.block.children, "blockId")
        const checkedAssets = checkedKeys.map(key => blocksToAssets[key].assetId)

        if (!checkedAssets.length) {
            return flash({
                message: "No assets selected.",
                status: "warning",
            })
        }
        
        dispatch(createBlockLabels(checkedAssets, payload, () => {
            props.onSubmit && props.onSubmit()
        }))

    }, [tags, props.range, notes, showLabelNotes, checkedKeys])

    return (
        <BlockProductionLabeller>
            <BlockProductionLabellerHeader range={props.range} />
            <LabellerBody>
                <BrushedOutputSummary brushedData={props.brushedData} />
                <LabellerBodyItem header={"Create"}>
                    <ProductionFieldsInputs fields={CONSTANTS.LABELS} onChange={setTags} />
                </LabellerBodyItem>
                {showLabelNotes ? 
                    <LabellerBodyItem header="Notes">
                        <LabelNotesInput
                            value={notes}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setNotes(e.target.value)
                            }
                        />
                    </LabellerBodyItem>
                    : null}
                <LabellerBodyItem header={<span>Tag Assets <PopoverBulkTagging/></span>}>
                    <AssetsChecklist
                        block={props.block}
                        range={props.range}
                        fetchLabels={fetchAssetSkuLabels}
                        onChange={setCheckedKeys}
                    />
                </LabellerBodyItem>
                <AukButton.Blue
                    className="my-3"
                    icon={<SaveFilled />}
                    onClick={handleSave}
                    disabled={checklistRef?.current ? (checklistRef.current as any).isLoading : false}
                >
          Save
                </AukButton.Blue>
            </LabellerBody>
        </BlockProductionLabeller>
    )
}

interface CreateProps extends SummaryProps, HeaderProps {
    block: BlockModel;
    onSubmit: AnyFunction;
}

type ProductionLabelValues = Record<string, any>