import { updateStoreRedux } from '../../../legacy/utils/redux';

import { DeviceConstants as K } from './Devices.constants';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    devices: {},
});

export const devicesReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_DEVICES_RESOURCE) {
        return updateStoreRedux(state, {
            devices: action.payload.devices,
        });
    }

    if (action.type === K.ACTIONS.ADD_DEVICES_RESOURCE) {
        const { device } = action.payload;
        return updateStoreRedux(state, {
            devices: { ...state.devices, ...action.payload.devices },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_DEVICES_RESOURCE) {
        const deleteSet = new Set(action.payload.device_ids);
        const deletedState = pickBy(
            { ...state.devices },
            ({ device_id }) => !deleteSet.has(device_id)
        );

        return updateStoreRedux(state, {
            devices: deletedState,
        });
    }

    if (action.type === K.ACTIONS.RESET_DEVICES_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
