import React from "react"
import { Link } from "react-router-dom"
import BlockModel from "../../../api/models/block.model"
import CONSTANTS from "../../../legacy/Constants"
import BlockOperation from "../../../legacy/routes/Blocks/components/BlockOperation"

const BlockRowHeader = (props: Props) => {
    return <div className="d-flex flex-fill align-items-center">
        <span className="d-flex align-items-center justify-content-start">
            {props.block.assetId ? null : <BlockOperation className="mr-2" block={props.block}/> }
            {props.withNavigate ? <Link to={getLinkUrl(props.block)}>{props.block.label}</Link> : props.block.label}
            {props.addOnAfter ? props.addOnAfter : null}
        </span>
    </div>
}

interface Props {
    addOnAfter?: React.ReactNode;
    withNavigate?: boolean;
    block: BlockModel
}

export default BlockRowHeader

const getLinkUrl = (block: BlockModel) => {
    return block.assetId
        ? `${CONSTANTS.URLS.ASSET}/${block.blockId}`
        : `${CONSTANTS.URLS.BLOCK}/${block.blockId}`
}


