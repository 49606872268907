
export const cloneBlockTree = (node) => {
    const {
        block_id,
        parent_block_id,
        operation,
        weightage,
        order,
        hierarchy_level,
        asset_id,
        asset,
        flag,
        bn_mode,
        bn_block_id,
        block_name,
    } = node;
    const copy = Object.assign(
        {},
        {
            block_id,
            parent_block_id,
            operation,
            weightage,
            order,
            hierarchy_level,
            asset_id,
            flag,
            bn_mode,
            bn_block_id,
            block_name,
        },
        {
            type: 'block',
        },
        asset
            ? {
                asset: {
                    asset_id: asset.asset_id,
                    asset_name: asset.asset_name,
                },
            }
            : null
    );

    copy.children =
    node.children && node.children.length
        ? node.children
            .concat()
            .sort((a, b) => a.order - b.order)
            .map((child) => cloneBlockTree(child))
        : [];

    return copy;
};

export const getBlocksIssuesExclusionsState = (arr) => {
    return arr.reduce((acc, curr) => {
        const block_issue_exclusion = { [curr.issue_id]: curr.ib_id };
        acc[curr.block_id] = acc[curr.block_id]
            ? { ...acc[curr.block_id], ...block_issue_exclusion }
            : block_issue_exclusion;
        return acc;
    }, {});
};

export const getBlocksTreeData = (node, options = {}) => {
    const { labelAccessor, valueAccessor, disabledAccessor } = options;
    const label = labelAccessor ? labelAccessor(node) : node.label;
    const value = valueAccessor ? valueAccessor(node) : node.block_id;

    const disabled = disabledAccessor
        ? disabledAccessor(node)
        : node.isRestricted;

    if (node.children && node.children.length) {
        const children = node.children.map((c) => getBlocksTreeData(c, options));

        return { label, value, children, disabled };
    }

    return { label, value, disabled };
};
