export const TargetConstants = {
    ACTIONS: {
        SET_TARGETS_RESOURCE: 'SET_TARGETS_RESOURCE',
        ADD_TARGETS_RESOURCE: 'ADD_TARGETS_RESOURCE',
        REMOVE_TARGETS_RESOURCE: 'REMOVE_TARGETS_RESOURCE',
        FETCH_ENTITY_TARGETS_REQUEST: 'FETCH_ENTITY_TARGETS_REQUEST',
        FETCH_ASSET_TARGETS_REQUEST: 'FETCH_ASSET_TARGETS_REQUEST',
        CREATE_TARGET_REQUEST: 'CREATE_TARGET_REQUEST',
        BULK_CREATE_TARGETS_REQUEST: 'BULK_CREATE_TARGETS_REQUEST',
        UPDATE_TARGET_REQUEST: 'UPDATE_TARGET_REQUEST',
        DELETE_TARGET_REQUEST: 'DELETE_TARGET_REQUEST',
        FETCH_TARGETS_REQUEST_FAILURE: 'FETCH_TARGETS_REQUEST_FAILURE',
        CREATE_ASSET_SHIFT_TARGET_REQUEST: 'CREATE_ASSET_SHIFT_TARGET_REQUEST',
        UPDATE_ASSET_SHIFT_TARGET_REQUEST: 'UPDATE_ASSET_SHIFT_TARGET_REQUEST',
        DELETE_ASSET_SHIFT_TARGET_REQUEST: 'DELETE_ASSET_SHIFT_TARGET_REQUEST',
    },
};
