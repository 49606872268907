export const ar = {
    notes: 'ملاحظات',
    load: 'اذهب',
    export: 'تحميل',
    unutilised: 'برنامج الأمم المتحدة للاستخدام',
    breakdowns: 'أعطال',
    threshold: 'عتبة',
    oeepreview: 'معاينة OEE',
    heatmappreview: 'معاينة خريطة التمثيل اللوني',
    oeecolors: 'الألوان OEE',
    color: 'اللون',
    reset: 'إعادة تعيين',
    theme: 'موضوع',
    defaultview: 'افتراضي مشاهدة',
    defaulttimeselection: 'اختيار الوقت الافتراضية',
    zoomlevel: 'مستوى التكبير',
    entity: 'شخصية',
    preferences: 'تفضيلات',
    custom: 'مخصص',
    enteryourmailtoreset: 'أدخل بريدك لإعادة',
    goback: 'عد',
    resetpassword: 'إعادة تعيين كلمة المرور',
    logout: 'تسجيل خروج',
    ownersemail: 'أصحاب البريد الإلكتروني',
    optional: 'اختياري',
    industry: 'صناعة',
    timezone: 'وحدة زمنية',
    nocoderequesttojoin: 'No القانون طلب للانضمام',
    enterreferralcodeinstead: 'أدخل رمز الإحالة بدلا',
    setup: 'اقامة',
    company: 'شركة',
    referralcode: 'كود الإحالة',
    submit: 'إرسال',
    summary: 'ملخص',
    language: 'لغة',
    login: 'تسجيل الدخول',
    signin: 'تسجيل الدخول',
    signup: 'سجل',
    username: 'اسم المستخدم',
    password: 'كلمه السر',
    forgotpassword: 'هل نسيت كلمة المرور؟',
    rememberme: 'تذكرنى',
    register: 'تسجيل',
    registration: 'التسجيل',
    firstname: 'الاسم الاول',
    lastname: 'الكنية',
    email: 'البريد الإلكتروني',
    mobile: 'التليفون المحمول',
    country: 'بلد',
    contactnumber: 'رقم الاتصال',
    appointment: 'موعد',
    role: 'وظيفة',
    admin: 'مشرف',
    owner: 'صاحب',
    editor: 'محرر',
    viewer: 'مشاهد',
    confirmpassword: 'تأكيد كلمة المرور',
    iacceptthe: 'أقبل',
    useragreement: 'اتفاقية المستخدم',
    alreadyregisteredlogin: 'مسجل بالفعل؟ تسجيل الدخول',
    softwareasaservicetermsofuse: 'البرنامج كما هو وجود الخدمة شروط الاستخدام',
    dashboard: 'لوحة القيادة',
    streaming: 'تدفق',
    resolution: 'الدقة',
    seconds: 'ثواني',
    second: 'ثانيا',
    minutes: 'الدقائق',
    minute: 'دقيقة',
    hours: 'ساعات',
    hour: 'ساعة',
    weeks: 'أسابيع',
    week: 'أسبوع',
    months: 'الشهور',
    month: 'شهر',
    quarters: 'أرباع',
    quarter: 'ربع',
    years: 'سنوات',
    year: 'عام',
    from: 'من عند',
    to: 'إلى',
    start: 'بداية',
    end: 'النهاية',
    display: 'عرض',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'السلاسل الزمنية',
    gateway: 'بوابة',
    statusasof: 'الحالة اعتبارا من',
    active: 'نشيط',
    offline: 'غير متصل على الانترنت',
    mergeasonemachine: 'دمج كما آلة واحدة',
    drawarrowstoformprocessmap: 'رسم خريطة الأسهم لعملية النموذج',
    openblockeditor: 'فتح محرر كتلة',
    createnewtile: 'إنشاء بلاط جديد',
    summarytile: 'بلاط موجز',
    selectablockthennameyoursummarytile:
    'تحديد كتلة، ثم قم بتسمية البلاط ملخص الخاص بك',
    name: 'اسم',
    blockselector: 'كتلة محدد',
    asset: 'الأصول',
    therearenolabelstaggedinthisperiod: 'لا توجد علامات المعلمة في هذه الفترة',
    standardtimes: 'وقت قياسي (الصورة)',
    total: 'مجموع',
    last: 'الاخير',
    oeeinput: 'إدخال OEE',
    chartcolor: 'لون الرسم البياني',
    issueeditor: 'محرر قضية',
    issuemangement: 'مانجمنت القضية',
    cumulativeoutput: 'الانتاج التراكمي',
    tag: 'بطاقة شعار',
    label: 'ضع الكلمة المناسبة',
    valueval: 'قيمة (فال)',
    time: 'زمن',
    januaryjan: 'كانون الثاني (يناير)',
    februaryfeb: 'فبراير (فبراير)',
    marchmar: 'مسيرة (مارس)',
    aprilapr: 'أبريل (أبريل)',
    may: 'مايو',
    junejun: 'يونيو (يونيو)',
    julyjul: 'يوليو (يوليو)',
    augustaug: 'أغسطس (أغسطس)',
    septembersep: 'سبتمبر (سبتمبر)',
    octoberoct: 'تشرين الأول (أكتوبر)',
    novembernov: 'تشرين الثاني (نوفمبر)',
    decemberdec: 'كانون الأول (ديسمبر)',
    day: 'يوم',
    days: 'أيام',
    mondaymonm: 'الاثنين (الاثنين) M',
    tuesdaytuet: 'الثلاثاء (الثلاثاء) T',
    wednesdaywedw: 'الاربعاء (الأربعاء) W',
    thursdaythut: 'الخميس (الخميس) T',
    fridayfrif: 'الجمعة (الجمعة) F',
    saturdaysats: 'السبت (السبت) S',
    sundaysuns: 'الأحد (الأحد) S',
    edit: 'تعديل',
    add: 'إضافة',
    new: 'جديد',
    create: 'خلق',
    search: 'بحث',
    close: 'قريب',
    cancel: 'إلغاء',
    save: 'حفظ',
    saved: 'تم الحفظ',
    clear: 'واضح',
    enter: 'أدخل',
    navigator: 'ملاح',
    mandatoryfields: 'الحقول الإلزامية',
    select: 'تحديد',
    merge: 'دمج',
    delete: 'حذف',
    done: 'منجز',
    filter: 'منقي',
    table: 'الطاولة',
    columns: 'أعمدة',
    rows: 'الصفوف',
    cell: 'زنزانة',
    page: 'صفحة',
    previous: 'السابق',
    next: 'التالى',
    serialnumber: 'رقم سري',
    manuallyinputserialnumber: 'المدخلات يدويا الرقم التسلسلي',
    updateasset: 'تحديث الأصول',
    machine: 'آلة',
    brand: 'علامة تجارية',
    model: 'نموذج',
    nodes: 'العقد',
    fusions: 'اندماج',
    chartorder: 'الرسم البياني ترتيب',
    primary: 'ابتدائي',
    adddevicechannel: 'إضافة جهاز - قناة',
    pushratesec: 'دفع معدل (ثانية)',
    voltagev: 'الجهد (V)',
    sensor: 'المستشعر',
    mode: 'الوضع',
    digitalcount: 'عدد الرقمي',
    digitalstate: 'الدولة الرقمي',
    analogvoltage: 'التناظرية الجهد',
    charttitle: 'عنوان التخطيط',
    unitmeasure: 'وحدة قياس',
    attribute: 'ينسب',
    autocycletime: 'دورة الزمن السيارات',
    unittransform: 'وحدة تحويل',
    leaveblankifnotapplicable: 'اتركه فارغا إن لم يكن قابلا للتطبيق',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'الحقول الإلزامية (إجازة فارغا إذا قناة لا تكون قيد الاستعمال)',
    fusedchart: 'تنصهر الرسم البياني',
    fusionname: 'اسم الانصهار',
    charttype: 'نوع التخطيط',
    column: 'عمود',
    line: 'خط',
    state: 'حالة',
    expression: 'التعبير',
    combination: 'مزيج',
    currentcharts: 'المخططات الحالية',
    expressionconstructor: 'منشئ التعبير',
    validoperators: 'مشغلي صالحة',
    leaveblanktokeepexistingexpression: 'اتركه فارغا للحفاظ على التعبير القائمة',
    oee: 'OEE',
    overallequipmenteffectiveness: 'فعالية الجهاز الشاملة',
    legend: 'عنوان تفسيري',
    loading: 'جار التحميل',
    availability: 'توفر',
    performance: 'أداء',
    quality: 'جودة',
    unscheduled: 'غير المجدولة',
    planneddowntime: 'تنظيم الوقت الضائع',
    changeoversetup: 'التحول / الإعداد',
    breakdown: 'انفصال',
    unutilized: 'برنامج الأمم المتحدة للاستخدام',
    speedloss: 'سرعة فقدان',
    minorstops: 'توقف قاصر',
    rework: 'إعادة العمل',
    rejectscrap: 'رفض / خردة',
    nodata: 'لايوجد بيانات',
    blockeditor: 'محرر كتلة',
    sort: 'فرز',
    logicblocks: 'المنطق كتل',
    sequence: 'تسلسل',
    and: 'و',
    or: 'أو',
    utilizationheatmap: 'استخدام مخطط النشاط',
    startdate: 'تاريخ البدء',
    enddate: 'تاريخ الانتهاء',
    daily: 'اليومي',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
    quarterly: 'ربعي',
    yearly: 'سنوي',
    trendanalysis: 'تحليل الاتجاه',
    metricselection: 'متري اختيار',
    metric: 'قياس',
    data: 'البيانات',
    target: 'استهداف',
    periodselection: 'فترة الاختيار',
    span: 'امتداد',
    every: 'كل',
    repeat: 'كرر',
    repeats: 'يكرر',
    addsingle: 'إضافة واحدة',
    paretoanalysis: 'تحليل باريتو',
    calendartime: 'الوقت التقويم',
    scheduledtime: 'جدول زمني',
    operatingtime: 'وقت التشغيل',
    netruntime: 'صافي وقت التشغيل',
    effective: 'فعال',
    loadingloss: 'تحميل الخسارة',
    availabilityloss: 'فقدان التوفر',
    performanceloss: 'فقدان الأداء',
    qualityloss: 'فقدان الجودة',
    notagsinthiscategory: 'لا يوجد كلمات دليلية في هذه الفئة',
    overall: 'شاملة',
    tagged: 'معلم',
    occurrence: 'حادثة',
    skumanagement: 'إدارة SKU',
    category: 'الفئة',
    skuname: 'اسم SKU',
    skucode: 'كود SKU',
    unitofmeasurementuom: 'وحدة القياس (UOM)',
    description: 'وصف',
    csvupload: 'CSV تحميل',
    template: 'قالب',
    notifications: 'إشعارات',
    newnotification: 'الإعلام الجديد',
    monitoring: 'مراقبة',
    topic: 'موضوع',
    frequency: 'تكرر',
    recipients: 'المستفيدين',
    notifyby: 'إبلاغ عن طريق',
    sms: 'رسالة قصيرة',
    alert: 'إنذار',
    report: 'نقل',
    greaterthan: 'أكثر من',
    lessthan: 'أقل من',
    greaterthanorequal: 'أكبر من أو يساوي',
    lessthanorequal: 'اصغر من او يساوي',
    equal: 'مساو',
    range: 'نطاق',
    continuousduration: 'مدة مستمرة',
    usermanagement: 'إدارةالمستخدم',
    user: 'المستعمل',
    users: 'المستخدمين',
    invitenew: 'دعوة جديدة',
    status: 'الحالة',
    resend: 'إعادة إرسال',
    revoke: 'سحب او إبطال',
    invite: 'يدعو',
    invitees: 'المدعوين',
    addinviteesbypressingenterorclickingonthesign:
    "إضافة المدعوين عن طريق الضغط على مفتاح الإدخال أو النقر على علامة '+'",
    schedules: 'جداول',
    shift: 'تحول',
    profile: 'الملف الشخصي',
    changepassword: 'غير كلمة السر',
    deleteaccount: 'حذف الحساب',
    userguide: 'دليل المستخدم',
    overviewandbasiccontrol: 'نظرة عامة ومراقبة الأساسية',
    rangeandresolution: 'مجموعة والقرار',
    editingoptions: 'تحرير الخيارات',
    mergetiles: 'البلاط دمج',
    detailedassetview: 'عرض تفصيلي الأصول',
    assetoverview: 'نظرة عامة الأصول',
    summarystatistics: 'احصائيات ملخصة',
    dataanalysis: 'تحليل البيانات',
    oeeanoverview: 'OEE لمحة عامة',
    oeetoggle: 'OEE تبديل',
    addingamachine: 'إضافة آلة',
    nodeconfiguration: 'تكوين العقدة',
    deleteupdateassets: 'أصول التحديث الحذف',
    oeesummarytile: 'OEE ملخص البلاط',
    oeetags: 'OEE الكلمات',
    issuemanagement: 'إدارة القضايا',
    labelsandfiltering: 'تسميات والتصفية',
    useraccess: 'وصول المستخدم',
    group: 'مجموعة',
    groups: 'مجموعات',
    joincompany: 'تاريخ الشركة',
    notification: 'تنبيه',
    createnotification: 'إنشاء إعلام',
    node: 'العقدة',
    downtime: 'التوقف',
    downtimes: 'الأعطال',
    repeatstartdate: 'كرر تاريخ بدء',
    repeatenddate: 'كرر تاريخ الانتهاء',
    optional: 'اختياري',
    createplan: 'إنشاء خطة',
    today: 'اليوم',
    starttime: 'وقت البدء',
    endtime: 'وقت النهاية',
    chart: 'خريطة',
    reject: 'رفض',
    accept: 'قبول',
    filterbydaterange: 'تصفية حسب نطاق التاريخ',
    filterbymostrecent: 'تصفية حسب آخرها',
    result: 'نتيجة',
    type: 'نوع',
    duration: 'المدة الزمنية',
    by: 'بواسطة',
    title: 'عنوان',
    assigned: 'تعيين',
    action: 'عمل',
    track: 'تتبع',
    issue: 'القضية',
    priority: 'أفضلية',
    requestor: 'الطالب',
    opened: 'افتتح',
    assignee: 'المحال',
    root: 'جذر',
    cause: 'سبب',
    identified: 'محدد',
    defined: 'يعرف',
    date: 'تاريخ',
    resolve: 'حل',
    resolved: 'تم الحل',
    targetresolvedate: 'الهدف تاريخ العزم',
    procurement: 'تحصيل',
    choose: 'أختر',
    analyses: 'تحليلات',
    source: 'مصدر',
    selection: 'اختيار',
    historical: 'تاريخي',
    mostrecent: 'الأحدث',
    regression: 'تراجع',
    trendline: 'خط الاتجاه',
    upperbound: 'الحد الاعلى',
    lowerbound: 'الأدنى',
    preview: 'معاينة',
    period: 'فترة',
    series: 'سلسلة',
    axis: 'محور',
    youhaveno: 'لا يوجد لديك',
    code: 'الشفرة',
    all: 'الكل',
    upload: 'رفع',
    regressionanalysis: 'تحليل الانحدار',
    issuetracker: 'قضية تعقب',
    labelsearch: 'التسمية البحث',
    gatewaysetup: 'إعداد بوابة',
    setupgateway: 'بوابة الإعداد',
    radiomesh: 'شبكة الراديو',
    targetsetting: 'تحديد الأهداف',
    updatetrackedissue: 'تحديث مسألة تعقب',
    shownewtrackedissue: 'تظهر قضية مجنزرة جديدة',
    low: 'منخفض',
    mid: 'منتصف',
    high: 'عالي',
    linear: 'خطي',
    exponential: 'متسارع',
    logarithmic: 'وغاريتمي',
    quadratic: 'تربيعي',
    polynomial: 'متعدد الحدود',
    general: 'جنرال لواء',
    datataggingenrichment: 'بيانات توصيف التخصيب',
    hardware: 'المعدات',
    nodemanagement: 'إدارة عقدة',
    createeditnotification: 'إنشاء تحرير إخطار',
    conditions: 'الظروف',
    addsku: 'إضافة سكو',
    editsku: 'تحرير SKU',
    setupagateway: 'الإعداد بوابة',
    deviceserialnumber: 'الرقم التسلسلي للجهاز',
    repeatsweekly: 'يكرر أسبوعيا',
    repeatsdaily: 'يكرر يوميا',
    repeatsmonthly: 'يكرر شهريا',
    repeatsannually: 'يكرر سنويا',
    repeatsevery: 'يكرر كل',
    hourly: 'ساعيا',
    biweekly: 'نصف أسبوعيا',
};
