/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// SELECTOR
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';

import { flash } from '../Flash';
import { NoAccess } from '../None';

export const Permission = ({
    resource,
    forBlock,
    blockId,
    forResource,
    canDo,
    children,
}) => {
    const user = useSelector(userSelector);

    if (
        forResource &&
    resource &&
    !user.check_resource_policy(
        resource,
        canDo === 'view',
        canDo === 'edit',
        canDo === 'full'
    )
    ) {
        // return <NoAccess className="w-100 h-100"/ >;
        return null;
    }

    if (!forResource && resource && !user.check_user_policy_url(resource)) {
        flash({
            message: 'You do not have access on this resource',
            status: 'error',
        });
        return <Navigate to="/dashboard" replace={true}/>
    }

    if (forBlock && !user.check_user_policy_for_block(Number(blockId))) {
        flash({
            message: 'You do not have access on this asset',
            status: 'error',
        });
        return <Navigate to="/dashboard" replace={true}/>
    }

    return <>{children}</>;
};


const ROLE_ACCESS = {
    viewer: 1,
    editor: 2,
    owner: 3,
    admin: 4,
};

export const hasRolePermission = (role_name, accessLevel) => {
    const access = ROLE_ACCESS[accessLevel];
    const userRole = ROLE_ACCESS[role_name];

    return userRole >= access;
};

export const RolePermission = ({
    children,
    accessLevel,
}) => {
    const { role_name } = useSelector(userSelector);

    return hasRolePermission(role_name, accessLevel) ? <>{children}</> : null;
};
{/* 
export const hasResourcePermission = (user, resource, action) => {
    return user.check_resource_policy(
        resource,
        action === 'view',
        action === 'edit',
        action === 'full'
    );
}

export const ResourcePermission = ({ resource, action, children }) => {
    const user = useSelector(userSelector);
    const access = user.check_resource_policy(
        resource,
        action === 'view',
        action === 'edit',
        action === 'full'
    )
    return access ? <>{children}</> : null;
} */}
