/* eslint-disable react/prop-types */
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { SPAWrapper } from './SPAWrapper';

import './Loading.scss';

const LoadingScreen = (props) => {
    const icon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
    return (
        <SPAWrapper>
            <div className="loading">
                <div className="loading__spinner">
                    <Spin indicator={icon} />
                </div>
                {props.description ? (
                    <div className="loading__description">
                        <span>{props.description}</span>
                    </div>
                ) : null}
            </div>
        </SPAWrapper>
    );
};

export default LoadingScreen;
