import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { createAssetSku, deleteAssetSkus } from '../../../../../../store/old/Assets/Assets.action';
import { currentEntitySelector } from '../../../../../../store/old/Entity/Entity.selector';

const columns = [
    {
        title: 'Category',
        dataIndex: 'category',
    },
    {
        title: 'Code',
        dataIndex: 'code',
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
];

class SkusTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKeys: this.getSelectedKeys()
        }
    }

    get rowSelection() {
        return {
            checkStrictly: true,
            selectedRowKeys: this.state.selectedKeys,
            onSelect: (selected) => {
                const { entity_id } = this.props.entity;
                const { asset_id } = this.props.asset;
                const { sku_id } = selected;
                if (this.state.selectedKeys.includes(selected.sku_id)) {
                    this.setState({
                        selectedKeys: this.state.selectedKeys.filter(key => key !== sku_id)
                    })

                    this.props.deleteAssetSku(entity_id, asset_id, [sku_id]);
                    return;
                }

                this.setState({
                    selectedKeys: this.state.selectedKeys.concat(selected.sku_id)
                })

                this.props.createAssetSku(entity_id, asset_id, sku_id)
            },
        };
    }

    get dataSource() {
        const { skus } = this.props;
        return _.flatten(
            _.values(_.groupBy(_.sortBy(skus, 'category'), 'category')).map((items) =>
                _.sortBy(items, 'name')
            )
        );
    }

    getSelectedKeys() {
        return this.props.assetSkus[this.props.asset.asset_id] || []
    }

    render() {
        return (
            <div className="configuration-skus-tab">
                <Table
                    dataSource={this.dataSource}
                    columns={columns}
                    rowKey={'sku_id'}
                    rowSelection={this.rowSelection}
                />
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        assetSkus: rootState.assets.skus,
        skus: rootState.sku.skus,
        entity: currentEntitySelector(rootState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAssetSku: (entity_id, asset_id, sku_id, cb) => dispatch(createAssetSku(entity_id, asset_id, sku_id, cb)),
        deleteAssetSku: (entity_id, asset_id, sku_ids, cb) => dispatch(deleteAssetSkus(entity_id, asset_id, sku_ids, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkusTab);
