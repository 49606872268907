import React, { useRef } from "react"
import ChartWrapper from "../../../components/Chart/ChartWrapper"
import { LabelsChart } from "../../../legacy/Charts/v1"
import * as d3 from "d3"
import { LabelsChartTooltip } from "../../../legacy/Charts/v1/tooltips/templates"
import { labelDisplayText } from "../../../legacy/utils/labels"
import { Label } from "../../../legacy/models"
import { cancelBrushSelection, getLabelBrushRange, moveBrushSelection } from "../../../legacy/utils/charts"
import { generateId, getDataSubarrByTime } from "../../../legacy/utils/helpers"
import { AnyFunction } from "../../../types/any"
import OEEStackColumnChart from "../../../legacy/Charts/OEEStackColumnChart"

const OeeTimeSeriesChart = (props: Props) => {
    const chartId = useRef(`op-${generateId()}`)

    const oeeData = props.data ? props.data.oee : []
    const labelsData = props.data ? props.data.labels : []

    const getBrushedData = (brushedRange: Date[]) => {
        return getDataSubarrByTime(
            oeeData,
            brushedRange[0],
            brushedRange[1]
        )
    }

    const handleLabelClick = (d: any, x: any) => {
        cancelBrushSelection(d3.selectAll(".brush"))
        const chartBrush = d3.select(`#${chartId.current}`).select(".brush")
        const brushedRange = getLabelBrushRange(d, props.xDomain, { toAccessor: (l: Label) => l._to })
        moveBrushSelection(chartBrush, brushedRange.map(x))
        
        props.onLabelClick && props.onLabelClick(d, brushedRange, getBrushedData(brushedRange))
    }

    return <ChartWrapper height={props.height || "100%"} id={chartId.current}>
        <div style={{ height: 18, flexShrink: 0 }}>
            <LabelsChart
                xScale={d3.scaleTime()}
                xDomain={props.xDomain}
                xAccessor={(d: Label) => new Date(d.from)}
                x2Accessor={(d: Label) => new Date(d._to)}
                data={labelsData}
                colorAccessorLabel={(d: Label) => d.color}
                colorAccessorText={() => "#fafafa"}
                textAccessor={labelDisplayText}
                useTooltip={true}
                htmlTooltip={LabelsChartTooltip}
                keyAccessor={(d: Label) => d.label_id}
                className="w-100"
                onDoubleClick={(d: any, x: any) => { handleLabelClick(d, x) }}
            />
        </div>
        <OEEStackColumnChart
            data={oeeData}
            xDomain={props.xDomain}
            brush={{
                useBrush: true,
                onBrushEnd: (bounds: any, scaled: any, brushElement: any, data: any) => { 
                    props.onBrushEnd && props.onBrushEnd(bounds, scaled, brushElement, data)
                },
                onBrushCancel: props.onBrushCancel,
            }}
        />
    </ChartWrapper>
}

interface Props {
    data: ChartData;
    height: string | number
    xDomain: Date[]
    onLabelClick?: AnyFunction
    onBrushEnd?: AnyFunction
    onBrushCancel?: AnyFunction
}

interface ChartData {
    labels: Label[],
    oee?: any[]
}


export default OeeTimeSeriesChart
