export const BlockConstants = {
    VIEWS: {
        OUTPUT: 'Output',
        OEE_TIME_SERIES: 'OEE Time Series',
        OEE_AGGREGATED: 'OEE Aggregated'
    },
    ACTIONS: {
        BLOCK_FETCH_OEE_DATA: 'BLOCK_FETCH_OEE_DATA', // saga
        BLOCK_FETCH_OUTPUT_DATA: 'BLOCK_FETCH_OUTPUT_DATA', // saga
        BLOCK_SET_MODE: 'BLOCK_SET_MODE',
        BLOCK_SET_OEE_DATA: 'BLOCK_SET_OEE_DATA',
        BLOCK_SET_OUTPUT_DATA: 'BLOCK_SET_OUTPUT_DATA',
        BLOCK_SET_ISSUE_LABELS: 'BLOCK_SET_ISSUE_LABELS',
        BLOCK_ADD_ISSUE_LABELS: 'BLOCK_ADD_ISSUE_LABELS',
        BLOCK_SET_SKU_LABELS: 'BLOCK_SET_SKU_LABELS',
        BLOCK_ADD_SKU_LABELS: 'BLOCK_ADD_SKU_LABELS',
        BLOCK_RESET_STORE: 'BLOCK_RESET_STORE',
        BLOCK_CREATE_LABELS: 'BLOCK_CREATE_LABELS'
    }
}