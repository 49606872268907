import React from 'react';

import { onlyTEEP } from '../../../utils/oee';
import { uiDatetimeFormatWithSeconds } from '../../../utils/helpers';
import { labelDisplayText } from '../../../utils/labels';
import moment from 'moment';
import { store } from '../../../../store';

const oeeTooltipOrder = [
    'ef',
    'sl',
    'ms',
    'us',
    'pd',
    'uu',
    'na',
    'bd',
    'st',
    'rj',
    'rw',
];

const oeeListElement = (d, key, categories) => {
    const { oee2 } = store.getState().ui.controls;
    const abbr = categories[key].prefix.toUpperCase();
    const oeeValue = d.oee[key] ? d.oee[key].toFixed(2) : '0.00';
    const value = oee2 && onlyTEEP.has(key) ? '-' : oeeValue;
    return `<li>${abbr}: ${value}</li>`;
};

export const OEEChartTooltip = (d) => {
    const {
        oee: { categories },
    } = store.getState().ui;

    return (
        '<ul>' +
    '<li>Time: ' +
    moment(d.time).format(uiDatetimeFormatWithSeconds) +
    '</li>' +
    oeeTooltipOrder.map((cat) => oeeListElement(d, cat, categories)).join('') +
    '</ul>'
    );
};

export const LabelsChartTooltip = (d) => {
    let str = labelDisplayText(d);
    str = str.replace(/[<]/, '&lt;');
    str = str.replace(/[>]/, '&gt;');
    return str;
};

export const ColumnChartTooltip = (d) => {
    return (
        '<ul>' +
    '<li>Time: ' +
    moment(d.time).format(uiDatetimeFormatWithSeconds) +
    '</li>' +
    '<li>Val: ' +
    d.val +
    '</li>' +
    (d.dmac ? `<li>Mac:${d.dmac}</li>` : '') +
    '</ul>'
    );
};

export const AggregateChartTooltip = (d) => {
    const { key, data } = d;
    return (
        '<ul>' +
    '<li>' +
    '<span>' +
    '<span class="text-uppercase">' +
    key +
    '</span>' +
    ': ' +
    data[key].toFixed(1) +
    '% ' +
    '(' +
    (((data[key] / 100) * data.totalSeconds) / 3600).toFixed(2) +
    ' hours)' +
    '</span>' +
    '</li>' +
    '</ul>'
    );
};

export const TargetsChartTooltip = (d) => {
    const uom = d.sku ? d.sku.uom : 'units';

    return (
        '<ul class="tooltip--targets">' +
    '<li class="tooltip--targets__title">' +
    '<span>' +
    d.label +
    '</span>' +
    '</li>' +
    '<li class="tooltip--targets__row">' +
    '<span class="row-label">Target:</span>' +
    '<span>' +
    `${d.value} ${uom}` +
    '</span>' +
    '</li>' +
    '</ul>'
    );
};

export const HeatmapTooltip = (d) =>
    `${d.label}: ${d.value === '-' ? 'RESTRICTED' : d.value + '%'}`;

// react components
// export const ParetoTooltip = (props) => {
//   const { data } = props;
//   return (
//     <div className="tooltip--pareto">
//       <div className="label-links container">
//         <div
//           className="d-flex"
//           style={{ fontFamily: 'Trebuchet MS, sans-serif' }}
//         >
//           <div className="d-flex col-4 p-0">Tagged</div>
//           <div className="d-flex col-4 p-0">Hours (Eff. hours)</div>
//           <div className="d-flex col-4 p-0">Asset</div>
//         </div>
//         <div style={{ maxHeight: 400, overflow: 'auto' }}>
//           {data &&
//             data.map((d, i) => {
//               return (
//                 <div
//                   className="d-flex"
//                   key={i}
//                   style={{
//                     fontFamily: 'Helvetica, sans-serif',
//                     fontSize: 12,
//                     fontWeight: 400,
//                   }}
//                 >
//                   <div className="d-flex col-4 p-0">
//                     <a
//                       onClick={() => d.onClick()}
//                       style={{ textDecoration: 'underline', color: '#1890ff' }}
//                     >
//                       {d.from} to {d.to}
//                     </a>
//                   </div>
//                   <div className="d-flex col-4 p-0">
//                     {(d.duration / 3600).toFixed(2)} (
//                     {(d.effective_duration / 3600).toFixed(2)})
//                   </div>
//                   <div className="d-flex col-4 p-0">{d.asset_name}</div>
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   );
// };
