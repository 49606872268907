import { DataConstants as K } from './Data.constants';

/* SERVER REQUESTS */
export const fetchAssetChartsData = (entity_id, asset_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_CHARTS_DATA_REQUEST,
        payload: { entity_id, asset_id, query },
        callback,
    };
};

export const fetchAssetOeeData = (entity_id, asset_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_OEE_DATA_REQUEST,
        payload: { entity_id, asset_id, query },
        callback,
    };
};

export const fetchAssetOperatorData = (entity_id, asset_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_OPERATOR_DATA_REQUEST,
        payload: { entity_id, asset_id, query },
        callback,
    };
};

export const fetchBlockOeeData = (entity_id, block_id, query, options, callback) => {
    return {
        type: K.ACTIONS.FETCH_BLOCK_OEE_DATA_REQUEST,
        payload: { entity_id, block_id, query, options },
        callback,
    };
};

export const fetchSummaryTilesData = (entity_id, ids, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_SUMMARY_TILE_DATA_REQUEST,
        payload: { entity_id, ids, query },
        callback,
    };
};

export const fetchTilesData = (entity_id, ids, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_TILE_DATA_AND_LABELS_REQUEST,
        payload: { entity_id, ids, query },
        callback,
    };
};

/* UPDATE REDUX STORE */
export const addBlocksOeeDataResource = (blocks) => {
    return {
        type: K.ACTIONS.ADD_BLOCKS_OEE_DATA_RESOURCE,
        payload: { blocks },
    };
};

export const addSummaryTilesOeeDataResource = (summary_tiles) => {
    return {
        type: K.ACTIONS.ADD_SUMMARY_TILES_OEE_DATA_RESOURCE,
        payload: { summary_tiles },
    };
};

export const addTimesSeriesDataResource = (metadata = {}, fusions = {}) => {
    return {
        type: K.ACTIONS.ADD_TIME_SERIES_DATA_RESOURCE,
        payload: { metadata, fusions },
    };
};

export const clearDataResource = (resources) => {
    return {
        type: K.ACTIONS.CLEAR_DATA_RESOURCE,
        payload: { resources },
    };
};
