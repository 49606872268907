import "./style.scss"
import { IconButton, Typography } from "@mui/material"
import cx from "classnames"
import moment from "moment"
import React, { ReactEventHandler } from "react"
import Grid from "../../../Grid/Grid"

const DATE_FORMAT = "YYYY-MM-DD"
const today = moment().format(DATE_FORMAT)
const DayCalendarButton = (props: Props) => {
    const handleClick: ReactEventHandler = () => {
        props.onPress(props.value)
    }

    const valueFormat = props.value.format(DATE_FORMAT)
    const isStart = props.start?.format(DATE_FORMAT) === valueFormat
    const isEnd = props.end?.format(DATE_FORMAT) === valueFormat
    return <Grid item className={cx({
        "day-button": true,
        "first-child": props.firstChild,
        "last-child": props.lastChild,
    })}>
        <div className={cx({
            "day-button-container": true,
            "highlight": props.start && props.end && !props.value.isBefore(props.start) && !props.value.isAfter(props.end),
            "start": isStart,
            "end": isEnd,
        })}>
            <IconButton
                className={cx({
                    "day-button-btn": true,
                    "today": valueFormat === today,
                    "active": isStart || isEnd,
                    "disabled": props.disabled,
                })}
                onClick={handleClick}
                disabled={props.disabled}
            >
                <Typography variant="caption">{props.value.date()}</Typography>
            </IconButton>
        </div>
    </Grid>
}

interface Props {
    value: moment.Moment
    onPress: (value: moment.Moment) => void
    start?: Time.DateValue
    end?: Time.DateValue
    disabled?: boolean
    firstChild?: boolean
    lastChild?: boolean
}

export default DayCalendarButton
