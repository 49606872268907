import CONSTANTS from '../Constants';

export function getHslParams (str) {
    if (str.match('hsl')) {
        const [h,s,l] = str.match(/([0-9.]*)/g).filter(v => v)
        return hslToHex(h,s,l)
    }

    return str
}

export function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

function hslToColorString(hsl) {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
}

function moveTo(x, target, fraction) {
    return (1 - fraction) * x + fraction * target;
}

function c1(hsl) {
    return {
        h: hsl.h,
        s: hsl.s,
        l: moveTo(hsl.l, 100, 0.3),
    };
}

function c2(hsl) {
    return {
        h: hsl.h,
        s: hsl.s,
        l: moveTo(hsl.l, 100, 0.5),
    };
}

function c3(hsl) {
    return {
        h: hsl.h,
        s: hsl.s,
        l: moveTo(hsl.l, 100, 0.7),
    };
}

function c4(hsl) {
    return {
        h: hsl.h,
        s: hsl.s,
        l: moveTo(hsl.l, 100, 0.85),
    };
}

export function custom(hsl, op) {
    return {
        h: hsl.h,
        s: hsl.s,
        l: moveTo(hsl.l, 100, op),
    };
}

const hslRed = { h: 0, s: 60, l: 40 };
const hslYellow = { h: 42, s: 100, l: 50 };
const hslGreen = { h: 140, s: 50, l: 50 };
const hslBlue = { h: 207, s: 50, l: 50 };
const hslGray = { h: 0, s: 0, l: 50 };
const hslPurple = { h: 261, s: 50, l: 50 };

export const Red = hslToColorString(hslRed);
export const Red1 = hslToColorString(c1(hslRed));
export const Red2 = hslToColorString(c2(hslRed));
export const Red3 = hslToColorString(c3(hslRed));
export const Red4 = hslToColorString(custom(hslRed, 0.05));
export const Red5 = hslToColorString(custom(hslRed, 0.28));
export const Red6 = hslToColorString(custom(hslRed, 0.42));
export const Yellow = hslToColorString(hslYellow);
export const Yellow1 = hslToColorString(c1(hslYellow));
export const Yellow2 = hslToColorString(c2(hslYellow));
export const Yellow3 = hslToColorString(c3(hslYellow));
export const Green = hslToColorString(hslGreen);
export const Green1 = hslToColorString(c1(hslGreen));
export const Green2 = hslToColorString(c2(hslGreen));
export const Green3 = hslToColorString(c3(hslGreen));
export const Blue = hslToColorString(hslBlue);
export const Blue1 = hslToColorString(c1(hslBlue));
export const Blue2 = hslToColorString(c2(hslBlue));
export const Blue3 = hslToColorString(c3(hslBlue));
export const Gray = hslToColorString(hslGray);
export const Gray1 = hslToColorString(c1(hslGray));
export const Gray2 = hslToColorString(c2(hslGray));
export const Gray3 = hslToColorString(c3(hslGray));
export const Gray4 = hslToColorString(c4(hslGray));
export const Purple = hslToColorString(c1(hslPurple));
export const Purple1 = hslToColorString(c2(hslPurple));
export const Purple2 = hslToColorString(c3(hslPurple));
export const Purple3 = hslToColorString(c4(hslPurple));

export const Colors = {
    red: [Red, Red1, Red2, Red3, Red4, Red5, Red6],
    yellow: [Yellow, Yellow1, Yellow2, Yellow3],
    green: [Green, Green1, Green2, Green3],
    blue: [Blue, Blue1, Blue2, Blue3],
    gray: [Gray, Gray1, Gray2, Gray3, Gray4],
    purple: [Purple, Purple1, Purple2, Purple3],
    white: ['#ffffff'],
};

export const getStateColor = (d) => {
    let idx;
    if (d.state === CONSTANTS.CHARTS.STATE.ON) idx = 0;
    if (d.state === CONSTANTS.CHARTS.STATE.BLINK) idx = 1;
    if (d.state === CONSTANTS.CHARTS.STATE.OFF) idx = -1;

    if (idx === -1) return Colors.white[0];
    return Colors[d.color][idx];
};


export const chartDefaultColors = (chart_type) => {
    switch (chart_type) {
    case CONSTANTS.CHARTS.TYPES.COLUMN:
        return '#4682B4';
    case CONSTANTS.CHARTS.TYPES.LINE:
        return '#808080';
    case CONSTANTS.CHARTS.TYPES.STATE:
        return '#4682B4';
    default:
        return '#4682B4';
    }
};
