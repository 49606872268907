import { ImagesConstants as K } from './Images.constants';

export const updateEntityImageRequest = (base64Img) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_IMAGE_REQUEST,
        payload: { base64Img },
    };
};

export const updateEntityImageRequestSuccess = (data) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_IMAGE_REQUEST_SUCCESS,
        payload: { ...data },
    };
};

export const updateEntityImageRequestFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_IMAGE_REQUEST_FAILURE,
        payload: { error },
    };
};
