import React, { useEffect, useMemo, useState } from "react";

import "./AssetSkuCharts.scss";
import { Form, Input, Popconfirm, Tabs } from "antd";
import AukButton from "../../../components/AukButton";
import { MinusOutlined } from "@ant-design/icons";
import InputWithSubmit from "../../../components/AukInput/InputWithSubmit";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { bulkCreateUpdateSkuCharts, fetchAssetSkuCharts, updateSkuChart } from "../../../../store/old/SkuCharts/SkuCharts.action";
import LoadingScreen from "../../../components/Loading";
import { arraySkus, skusSelector } from "../../../../store/old/Sku/Sku.selector";
import { isFinite, toNumber, values } from "lodash";
import { defaultFilterOption } from "../../../utils/helpers";
import { updateAssetChart } from "../../../../store/old/Assets/Assets.action";
import { currentEntitySelector } from "../../../../store/old/Entity/Entity.selector";
import { flash } from "../../../components/Flash";

const { TabPane } = Tabs;

const VALIDATE = {
    MODIFIER: [
        (_form) => ({
            validator: (_, value) => {
                const val = toNumber(value)
                if (!isFinite(val)) {
                    return Promise.reject(new Error('Must be a number'));
                }

                return Promise.resolve();
            },
        }),
    ]
}

const transformSkuChart = (skuChart) => {
    return {
        sku_chart_id: skuChart.sku_chart_id,
        chart_id: skuChart.chart_id,
        sku_id: skuChart.sku_id,
        modifier: skuChart.modifier,
    }
}

const getSkuAssetChartKey = (sku_id, chart_id) => `${sku_id}-${chart_id}`

const getValidSkuCharts = (arraySkuCharts) => {
    return arraySkuCharts.filter(item => !!item.modifier)
}

const AssetSkuCharts = (props) => {
    const dispatch = useDispatch()
    const { entity_id } = useSelector(currentEntitySelector)
    const { asset_id } = props.asset;
    
    const [loading, setLoading] = useState(true);
    const skuChartsResource = useSelector(appState => appState.skuCharts.skuCharts)
    
    useEffect(() => {
        dispatch(fetchAssetSkuCharts(entity_id, asset_id, () => {
            setLoading(false) 
        }))
    }, [])

    const skuCharts = useMemo(() => {
        return values(skuChartsResource).reduce((acc, curr) => {
            const item = transformSkuChart(curr)
            return {
                ...acc,
                [item.chart_id]: acc[item.chart_id] ? acc[item.chart_id].concat(item) : [item]
            }
        }, {})
    }, [skuChartsResource])

    return (
        <Tabs
            tabPosition="left"
            type="card"
            tabBarStyle={{ width: 200 }}
            destroyInactiveTabPane
            className="asset-sku-charts"
        >
            {props.charts.map((c) => (
                <TabPane
                    tab={<span title={c.title}>{c.title}</span>}
                    key={c.chart_id}
                    closable={false}
                >
                        {
                            loading ?
                                <LoadingScreen description="Loading, please wait"/> : 
                                <AssetSkuChartForm asset={props.asset} chart={c} data={getValidSkuCharts(skuCharts[c.chart_id] || [])} raw={skuChartsResource}/>
                        }
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AssetSkuCharts;

const AssetSkuChartForm = (props) => {
    const dispatch = useDispatch()
    const { entity_id } = useSelector(currentEntitySelector)
    const [form] = Form.useForm()
    
    const skusResource = useSelector(skusSelector);
    const _skus = useMemo(() => arraySkus(skusResource), [skusResource])

    const [selection, setSelection] = useState(new Set(props.data.map(d => d.sku_id)));

    const skusOptions = useMemo(
        () => {
            return _skus.filter(sku => !selection.has(sku.sku_id)).map((s) => ({ value: s.sku_id, label: s.codeName }))
        },
        [_skus, selection]
    );

    useEffect(() => {
        form.setFieldsValue({ data: props.data })
        setSelection(new Set(props.data.map(d => d.sku_id)))
    }, [form, props.data])

    return <div className="asset-sku-charts-content">
        <FormHeader/>
        <Form
            name="skuChartForm"
            form={form}
            className="sku-chart-form"
            preserve
            onFinish={async () => {
                try {
                    await form.validateFields()
                    const errors = form.getFieldsError().filter(item => item.errors.length)
                    if (!errors.length) {
                        const { data, chart_modifier } = form.getFieldsValue(true)
                        const payload = data
                            .map(item => {
                                const modifier = item.modifier === '' ? null : +item.modifier;
                                if (item.sku_chart_id) {
                                    return {
                                        ...item, 
                                        modifier
                                    }
                                }
                                
                                const key = getSkuAssetChartKey(item.sku_id, item.chart_id);
                                return {
                                    ...item, 
                                    sku_chart_id: props.raw[key]?.sku_chart_id, 
                                    modifier
                                }

                            })
                            .filter(item => {
                                if (!item.sku_chart_id) {
                                    return true
                                }

                                const key = getSkuAssetChartKey(item.sku_id, item.chart_id);
                                return item.modifier !== props.raw[key].modifier
                            });

                        if (payload.length) {
                            dispatch(bulkCreateUpdateSkuCharts(entity_id, payload, () => { flash({ message: 'SKU multiplier(s) updated' }) }))
                        }

                        if (props.chart.modifier !== chart_modifier) {
                            dispatch(updateAssetChart(entity_id, props.asset.asset_id, props.chart.chart_id, { modifier: chart_modifier}))
                        }
                        return;
                    }
                } catch(e) {
                }
            }}
        >
            <Form.Item
                className='opcua-form-item'
                name="chart_modifier"
                rules={[]}
                initialValue={props.chart.modifier}
            >
                <ChartFormRowItem/>
            </Form.Item>
            <hr className="mt-0"/>
            <Form.List 
                name="data"
                initialValue={props.data || []}
            >
                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map((field, i) => {
                                return (
                                    <Form.Item
                                        className='opcua-form-item'
                                        noStyle
                                        key={field.key}
                                        rules={[]}
                                        {...field}
                                    >
                                        <SkuChartFormRowItem
                                            index={i}
                                            field={field}
                                            asset={props.asset}
                                            remove={(val) => {
                                                setSelection(new Set([...selection].filter(id => id !== val)))
                                                remove(field.name)
                                            }}
                                        />
                                    </Form.Item>
                                );
                            })}

                            <FormRow className='mb-0 mt-3'>
                                <AukButton.Select
                                    showSearch
                                    filterOption={defaultFilterOption}
                                    placeholder="+ Add SKU Multiplier"
                                    className="w-100"
                                    options={skusOptions}
                                    onChange={(val) => {
                                        setSelection(new Set([...selection, val]))
                                        add({ sku_id: val, chart_id: props.chart.chart_id, modifier: ''  })
                                    }}
                                />
                            </FormRow>
                        </>
                    );
                }}
            </Form.List>
            <AukButton.Save htmlType="submit" className="mt-3 float-right"/>
        </Form>
    </div>
};

const FormRow = (props) => {
    return <div className={classNames("sku-chart-form-row", props.className)}>{props.children}</div>
}

const FormHeader = () => {
    return (
        <FormRow className="sku-chart-form-header">
            <div className="sku-chart-form-row-item sku-chart-form-row-item--50">
                SKU
            </div>
            <div className="sku-chart-form-row-item sku-chart-form-row-item--50">
                Multiplier
            </div>
        </FormRow>
    )
}

const ChartFormRowItem = (props) => {
    return (
        <FormRow>
            <div className="sku-chart-form-row-item sku-chart-form-row-item--50">
                <Input style={{height: 32}} value="<Default>" disabled/>
            </div>
            <div className="sku-chart-form-row-item sku-chart-form-row-item--50">
                <Input
                    value={props.value || ''}
                    onChange={props.onChange}
                />
            </div>
        </FormRow>
    )
}

const getFormItemPath = (fieldName, name) => ([fieldName, name])

const SkuChartFormRowItem = (props) => {
    const dispatch = useDispatch()
    const { entity_id } = useSelector(currentEntitySelector)
    const { asset_id } = props.asset
    const skus = useSelector(skusSelector);
    const sku = useMemo(() => skus[props.value.sku_id], [skus, props.value])

    return (
        <FormRow>
            <Form.Item
                hidden
                initialValue={props.value.chart_id}
                name={getFormItemPath(props.field.name, 'chart_id')}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item 
                hidden
                initialValue={props.value.sku_id}
                name={getFormItemPath(props.field.name, 'sku_id')}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item 
                className="sku-chart-form-row-item sku-chart-form-row-item--50"
                initialValue={sku.codeName}
            >
                <Input value={sku.codeName} disabled/>
            </Form.Item>
            <Form.Item 
                className="sku-chart-form-row-item sku-chart-form-row-item--50"
                initialValue={props.value.modifier}
                name={getFormItemPath(props.field.name, 'modifier')}
                rules={VALIDATE.MODIFIER}
            >
                <Input 
                    className="w-100" 
                    addonAfter={props.value.sku_chart_id ? (
                        <Popconfirm
                            title={'Remove SKU modifier?'}
                            onConfirm={() => {
                                dispatch(updateSkuChart(
                                    entity_id, 
                                    asset_id, 
                                    props.value.chart_id, 
                                    props.value.sku_id, 
                                    { 
                                        sku_chart_id: props.value.sku_chart_id,
                                        modifier: null 
                                    }
                                ));
                                props.remove(props.value.sku_id)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <MinusOutlined />
                        </Popconfirm>
                    ) :
                        <MinusOutlined onClick={() => { props.remove(props.value.sku_id) }} />
                    } 
                />
            </Form.Item>
        </FormRow>
    )
}

