import { createSelector } from 'reselect';

export const blocksState = (appState) => {
    return appState.blocks;
};

export const blocksSelector = createSelector(blocksState, (v) => v.blocks);

export const arrayBlocks = createSelector([blocksState], (v) => {
    const blocks = v.blocks;
    const result = [];

    for (const item in blocks) {
        if (blocks.hasOwnProperty(item)) {
            const element = blocks[Number(item)];

            result.push(element);
        }
    }

    return result;
});

export const entityBlock = (appState) => {
    const { blocks, pc_directory } = blocksState(appState);
    return blocks[pc_directory['null'][0]];
};
