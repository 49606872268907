import { GroupConstants as K } from './Group.constant';
import { Group } from '../../../legacy/models';

export const getAllGroupsRequest = (callback) => {
    return {
        type: K.ACTIONS.FETCH_GROUPS_REQUEST,
        callback,
    };
};

export const setGroupsResource = (groups) => {
    return {
        type: K.ACTIONS.SET_GROUPS_RESOURCE,
        payload: { groups },
    };
};

export const addGroupsResource = (groups) => {
    return {
        type: K.ACTIONS.ADD_GROUPS_RESOURCE,
        payload: { groups },
    };
};

export const removeGroupsResource = (group_ids) => {
    return {
        type: K.ACTIONS.REMOVE_GROUPS_RESOURCE,
        payload: { group_ids },
    };
};

export const createGroupRequest = (group, callback) => {
    return {
        type: K.ACTIONS.CREATE_GROUP_REQUEST,
        payload: { group },
        callback,
    };
};

export const updateGroupRequest = (group, callback) => {
    return {
        type: K.ACTIONS.UPDATE_GROUP_REQUEST,
        payload: { group },
        callback,
    };
};

export const deleteGroupRequest = (group_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_GROUP_REQUEST,
        payload: { group_id },
        callback,
    };
};
