import { values } from 'lodash';
import { stopLoading } from '../../store/old/UI/Loaders/Loader.action';
import { store } from '../../store';

class HttpRequestMonitor {
    constructor() {
        this.requests = {};
    }

    addRequest(url, controller) {
        if (this.requests[url]) {
            this.abortAndRemoveRequest(url);
        }

        this.requests[url] = controller;
    }

    removeRequest(url) {
        delete this.requests[url];
    }

    abortAndRemoveRequest(url) {
        const controller = this.requests[url];
        controller.abort();
        store.dispatch(stopLoading());
        delete this.requests[url];
    }

    abortAndRemoveRequests() {
        values(this.requests).forEach((controller) => {
            controller.abort();
            store.dispatch(stopLoading());
        });

        this.requests = {};
    }
}

export default new HttpRequestMonitor();
