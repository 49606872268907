/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'reactstrap';
import AukButton from './AukButton';

const ConfirmationModal = (props) => (
    <Modal
        isOpen={props.showConfirmation}
        toggle={props.toggleConfirmation}
        backdrop="static"
    >
        <div className="panel confirmation-modal">
            <div className="panel-header">
                <p className="panel-title">
                    <i
                        className="fas fa-exclamation fa-2x"
                        style={{ color: 'rgb(204, 51, 51)' }}
                    />
                    <span style={{ marginLeft: 15 }}>{props.action}</span>
                </p>
            </div>
            <div className="panel-body">
                <div className="container" style={{ padding: 20 }}>
                    {props.message}
                </div>
            </div>
            <div className="panel-footer p-3">
                <AukButton.Outlined onClick={props.toggleConfirmation}>
          CANCEL
                </AukButton.Outlined>
                <AukButton.Red onClick={props.onConfirmation}>
          PROCEED
                </AukButton.Red>
            </div>
        </div>
    </Modal>
);

export default ConfirmationModal;
