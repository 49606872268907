import { createSelector } from 'reselect';
import { values } from 'lodash';

export const issuesState = (appState) =>
    appState.issues;

export const issuesSelector = createSelector(issuesState, (v) => v.issues);

export const arrayIssues = (appState) => {
    const { issues } = appState.issues;
    return values(issues);
};

export const rootIssues = (_issuesState) => {
    const { issues, pc_directory } = _issuesState;
    return pc_directory['null'].map((issue_id) => issues[issue_id]);
};
