import React, {
// useEffect
} from "react"
import {
    Outlet,
// useNavigate
} from "react-router-dom"
// import { ROUTES } from "../../routes/routes"
// import { useAppSelector } from "../../store/hooks"
import NavTemplate from "../Template/NavTemplate/NavTemplate"

// LEGACY
import { connectInitializedApp } from "../../legacy/Wrappers/HOCs/AppConnect"
import LoadingScreen from "../../legacy/components/Loading"
import { withEntity } from "../../legacy/Wrappers/HOCs/withEntity"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

const LayoutAuthenticated = () => {
    // const navigate = useNavigate()
    // const { isLoggedIn } = useAppSelector(({ auth }) => auth)
    // useEffect(() => {
    //     if (!isLoggedIn) {
    //         navigate(ROUTES.LOGIN)
    //     }
    // }, [isLoggedIn])

    return <NavTemplate>
        <DndProvider backend={HTML5Backend}>
            <Outlet />
        </DndProvider>
    </NavTemplate>
}

export default connectInitializedApp(withEntity(LayoutAuthenticated, LoadingScreen), () => <LoadingScreen description={"Loading core models..."} />)
