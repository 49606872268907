export const usersState = (appState) =>
    appState.user.list;

export const arrayUsers = (appState) => {
    return sortedUsers(usersState(appState)) 
};


export const sortedUsers = (userList) => {
    return Object.keys(userList)
        .map((user_id) => userList[+user_id])
        .sort((a, b) => {
            if (a.nameFull < b.nameFull) return -1;
            if (a.nameFull > b.nameFull) return 1;
            return 0;
        });
}
