export const es = {
    notes: 'Notas',
    load: 'Obtener',
    export: 'Exportar',
    unutilised: 'utilizado-Un',
    breakdowns: 'averías',
    threshold: 'Límite',
    oeepreview: 'Vista previa OEE',
    heatmappreview: 'Vista previa de mapa de calor',
    oeecolors: 'colores OEE',
    color: 'color',
    reset: 'Reiniciar',
    theme: 'Tema',
    defaultview: 'Vista predeterminada',
    defaulttimeselection: 'selección de tiempo predeterminado',
    zoomlevel: 'Nivel del zoom',
    entity: 'Entidad',
    preferences: 'preferencias',
    custom: 'Personalizado',
    enteryourmailtoreset: 'Ingrese su correo para restablecer',
    goback: 'Regresa',
    resetpassword: 'Restablecer la contraseña',
    logout: 'Cerrar sesión',
    ownersemail: 'Los propietarios de correo electrónico',
    optional: 'Opcional',
    industry: 'Industria',
    timezone: 'Zona horaria',
    nocoderequesttojoin: 'No Code solicitud para unirse',
    enterreferralcodeinstead: 'Introduce el código de referencia en vez',
    setup: 'Preparar',
    company: 'Empresa',
    referralcode: 'Código de referencia',
    submit: 'Enviar',
    summary: 'Resumen',
    language: 'Idioma',
    login: 'Iniciar sesión',
    signin: 'Registrarse',
    signup: 'Regístrate',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    forgotpassword: '¿Se te olvidó tu contraseña?',
    rememberme: 'Recuérdame',
    register: 'Registrarse',
    registration: 'Registro',
    firstname: 'Nombre de pila',
    lastname: 'Apellido',
    email: 'Email',
    mobile: 'Móvil',
    country: 'País',
    contactnumber: 'Número de contacto',
    appointment: 'Cita',
    role: 'Papel',
    admin: 'administración',
    owner: 'propietario',
    editor: 'editor',
    viewer: 'espectador',
    confirmpassword: 'Confirmar contraseña',
    iacceptthe: 'acepto el',
    useragreement: 'Acuerdo del Usuario',
    alreadyregisteredlogin: '¿Ya registrado? Iniciar sesión',
    softwareasaservicetermsofuse: 'Software-as-a-Service Condiciones de Uso',
    dashboard: 'Tablero',
    streaming: 'Transmisión',
    resolution: 'Resolución',
    seconds: 'segundos',
    second: 'segundo',
    minutes: 'minutos',
    minute: 'minuto',
    hours: 'horas',
    hour: 'Hora',
    weeks: 'semanas',
    week: 'Semana',
    months: 'meses',
    month: 'mes',
    quarters: 'cuarteles',
    quarter: 'trimestre',
    years: 'años',
    year: 'año',
    from: 'De',
    to: 'A',
    start: 'comienzo',
    end: 'Final',
    display: 'Monitor',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Series de tiempo',
    gateway: 'puerta',
    statusasof: 'Condición de',
    active: 'activo',
    offline: 'desconectado',
    mergeasonemachine: 'Combinar como una máquina',
    drawarrowstoformprocessmap: 'Dibujar flechas a mapa de procesos formulario',
    openblockeditor: 'editor de bloque abierto',
    createnewtile: 'Crear nuevo azulejo',
    summarytile: 'resumen de azulejos',
    selectablockthennameyoursummarytile:
    'Seleccionar un bloque, a continuación, el nombre de su baldosas Resumen',
    name: 'Nombre',
    blockselector: 'selector de bloques',
    asset: 'Activo',
    therearenolabelstaggedinthisperiod:
    'No hay etiquetas marcadas en este periodo',
    standardtimes: 'Standard Time (s)',
    total: 'Total',
    last: 'Último',
    oeeinput: 'de entrada OEE',
    chartcolor: 'color de la carta',
    issueeditor: 'Editor de tema',
    issuemangement: 'Mangement tema',
    cumulativeoutput: 'producción acumulada',
    tag: 'Etiqueta',
    label: 'Etiqueta',
    valueval: 'Valor (Val)',
    time: 'Hora',
    januaryjan: 'Enero (enero)',
    februaryfeb: 'Febrero (febrero)',
    marchmar: 'Marzo (mar)',
    aprilapr: 'Abril (abril)',
    may: 'Mayo',
    junejun: 'Junio ​​(Jun)',
    julyjul: 'Julio (julio)',
    augustaug: 'Agosto (agosto)',
    septembersep: 'Septiembre (septiembre)',
    octoberoct: 'Octubre (octubre)',
    novembernov: 'Noviembre (noviembre)',
    decemberdec: 'Diciembre (Dec)',
    day: 'Día',
    days: 'Dias',
    mondaymonm: 'Lunes (lunes) M',
    tuesdaytuet: 'Martes (mar) T',
    wednesdaywedw: 'Miércoles (miércoles) W',
    thursdaythut: 'Jueves (jueves) T',
    fridayfrif: 'Viernes (viernes) F',
    saturdaysats: 'Sábado (sábado) S',
    sundaysuns: 'Domingo (Sol) S',
    edit: 'Editar',
    add: 'Añadir',
    new: 'Nuevo',
    create: 'Crear',
    search: 'Buscar',
    close: 'Cerca',
    cancel: 'Cancelar',
    save: 'Salvar',
    saved: 'Salvado',
    clear: 'Claro',
    enter: 'Entrar',
    navigator: 'Navegador',
    mandatoryfields: 'Campos obligatorios',
    select: 'Seleccione',
    merge: 'Unir',
    delete: 'Eliminar',
    done: 'Hecho',
    filter: 'Filtrar',
    table: 'Mesa',
    columns: 'columnas',
    rows: 'filas',
    cell: 'célula',
    page: 'Página',
    previous: 'Anterior',
    next: 'próximo',
    serialnumber: 'Número de serie',
    manuallyinputserialnumber: 'Número de serie de entrada manualmente',
    updateasset: 'actualización de activos',
    machine: 'Máquina',
    brand: 'Marca',
    model: 'Modelo',
    nodes: 'Los nodos',
    fusions: 'fusiones',
    chartorder: 'Gráfico de la Orden',
    primary: 'Primario',
    adddevicechannel: 'Agregar dispositivo - Canal',
    pushratesec: 'Empuje Tasa (s)',
    voltagev: 'Voltaje (V)',
    sensor: 'Sensor',
    mode: 'Modo',
    digitalcount: 'Contador digital',
    digitalstate: 'Estado digital',
    analogvoltage: 'Tensión analógica',
    charttitle: 'Titulo del gráfico',
    unitmeasure: 'Unidad de medida',
    attribute: 'Atributo',
    autocycletime: 'Tiempo de ciclo automático',
    unittransform: 'unidad de Transformación',
    leaveblankifnotapplicable: 'Dejar en blanco si no es aplicable',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Los campos obligatorios (en blanco licencia si el canal no está en uso)',
    fusedchart: 'Gráfico fundida',
    fusionname: 'fusión Nombre',
    charttype: 'Tipo de gráfico',
    column: 'Columna',
    line: 'Línea',
    state: 'Estado',
    expression: 'Expresión',
    combination: 'Combinación',
    currentcharts: 'Listas actuales',
    expressionconstructor: 'Constructor expresión',
    validoperators: 'Los operadores válidos',
    leaveblanktokeepexistingexpression:
    'Dejar en blanco para mantener la expresión existente',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Overall Equipment Effectiveness',
    legend: 'Leyenda',
    loading: 'Cargando',
    availability: 'Disponibilidad',
    performance: 'Actuación',
    quality: 'Calidad',
    unscheduled: 'no programada',
    planneddowntime: 'Planificación de tiempo de inactividad',
    changeoversetup: 'Cambio / Configuración',
    breakdown: 'Descompostura',
    unutilized: 'No utilizado',
    speedloss: 'La pérdida de velocidad',
    minorstops: 'Detiene menores',
    rework: 'Rehacer',
    rejectscrap: 'Rechazar / Scrap',
    nodata: 'Sin datos',
    blockeditor: 'Editor de bloques',
    sort: 'Ordenar',
    logicblocks: 'Los bloques lógicos',
    sequence: 'Secuencia',
    and: 'Y',
    or: 'O',
    utilizationheatmap: 'Mapa de calor de utilización',
    startdate: 'Fecha de inicio',
    enddate: 'Fecha final',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    quarterly: 'Trimestral',
    yearly: 'Anual',
    trendanalysis: 'Análisis de tendencia',
    metricselection: 'Selección métrica',
    metric: 'Métrico',
    data: 'Datos',
    target: 'Objetivo',
    periodselection: 'Selección periodo',
    span: 'Lapso',
    every: 'Cada',
    repeat: 'Repetir',
    repeats: 'repeticiones',
    addsingle: 'Añadir individual',
    paretoanalysis: 'Análisis de Pareto',
    calendartime: 'tiempo de calendario',
    scheduledtime: 'Hora programada',
    operatingtime: 'Tiempo de funcionamiento',
    netruntime: 'tiempo neto de ejecución',
    effective: 'Eficaz',
    loadingloss: 'La pérdida de carga',
    availabilityloss: 'Pérdida disponibilidad',
    performanceloss: 'La pérdida de rendimiento',
    qualityloss: 'La pérdida de calidad',
    notagsinthiscategory: 'No hay etiquetas en esta categoría',
    overall: 'En general',
    tagged: 'Tagged',
    occurrence: 'Ocurrencia',
    skumanagement: 'Gestión SKU',
    category: 'Categoría',
    skuname: 'Nombre SKU',
    skucode: 'Código SKU',
    unitofmeasurementuom: 'Unidad de medida (UOM)',
    description: 'Descripción',
    csvupload: 'Subir CSV',
    template: 'Modelo',
    notifications: 'Notificaciones',
    newnotification: 'nueva notificación',
    monitoring: 'Supervisión',
    topic: 'Tema',
    frequency: 'Frecuencia',
    recipients: 'destinatarios',
    notifyby: 'notificar por',
    sms: 'SMS',
    alert: 'Alerta',
    report: 'Reporte',
    greaterthan: 'Mas grande que',
    lessthan: 'Menos que',
    greaterthanorequal: 'Mayor que o igual',
    lessthanorequal: 'Menor o igual',
    equal: 'Igual',
    range: 'Rango',
    continuousduration: 'Duración continua',
    usermanagement: 'Gestión de usuarios',
    user: 'Usuario',
    users: 'usuarios',
    invitenew: 'invitar a un nuevo',
    status: 'estado',
    resend: 'Enviar de nuevo',
    revoke: 'Revocar',
    invite: 'Invitación',
    invitees: 'invitados',
    addinviteesbypressingenterorclickingonthesign:
    "Agregar invitados pulsando Enter o hacer clic en el signo '+'",
    schedules: 'horarios',
    shift: 'Cambio',
    profile: 'Perfil',
    changepassword: 'Cambia la contraseña',
    deleteaccount: 'Borrar cuenta',
    userguide: 'Guía del usuario',
    overviewandbasiccontrol: 'Descripción y control básico',
    rangeandresolution: 'Rango y resolución',
    editingoptions: 'opciones de personalización',
    mergetiles: 'azulejos de combinación',
    detailedassetview: 'vista detallada de activos',
    assetoverview: 'visión general de activos',
    summarystatistics: 'Resumen estadístico',
    dataanalysis: 'Análisis de los datos',
    oeeanoverview: 'OEE una visión general',
    oeetoggle: 'de palanca OEE',
    addingamachine: 'Adición de una máquina',
    nodeconfiguration: 'configuración de nodo',
    deleteupdateassets: 'Actualizar Borrar activos',
    oeesummarytile: 'Resumen baldosas OEE',
    oeetags: 'OEE Etiquetas',
    issuemanagement: 'gestión de problemas',
    labelsandfiltering: 'Las etiquetas y filtrado',
    useraccess: 'Acceso de usuario',
    group: 'Grupo',
    groups: 'grupos',
    joincompany: 'unirse a la empresa',
    notification: 'Notificación',
    createnotification: 'crear notificación',
    node: 'Nodo',
    downtime: 'Falta del tiempo',
    downtimes: 'Los tiempos de parada',
    repeatstartdate: 'Repita Fecha de Inicio',
    repeatenddate: 'Fin de repetición Fecha',
    optional: 'Opcional',
    createplan: 'crear plan',
    today: 'Hoy',
    starttime: 'Hora de inicio',
    endtime: 'Hora de finalización',
    chart: 'Gráfico',
    reject: 'Rechazar',
    accept: 'Aceptar',
    filterbydaterange: 'Filtrar por intervalo de fechas',
    filterbymostrecent: 'Filtrar por más reciente',
    result: 'Resultado',
    type: 'Tipo',
    duration: 'Duración',
    by: 'por',
    title: 'Título',
    assigned: 'asignado',
    action: 'Acción',
    track: 'pista',
    issue: 'Problema',
    priority: 'Prioridad',
    requestor: 'solicitante',
    opened: 'abrió',
    assignee: 'Cesionario',
    root: 'raíz',
    cause: 'Porque',
    identified: 'Identificado',
    defined: 'definido',
    date: 'fecha',
    resolve: 'resolver',
    resolved: 'resuelto',
    targetresolvedate: 'fecha de determinación de destino',
    procurement: 'Obtención',
    choose: 'Escoger',
    analyses: 'análisis',
    source: 'Fuente',
    selection: 'Selección',
    historical: 'Histórico',
    mostrecent: 'Más reciente',
    regression: 'Regresión',
    trendline: 'La línea de tendencia',
    upperbound: 'límite superior',
    lowerbound: 'límite inferior',
    preview: 'avance',
    period: 'período',
    series: 'serie',
    axis: 'eje',
    youhaveno: 'no tienes',
    code: 'Código',
    all: 'Todas',
    upload: 'subir',
    regressionanalysis: 'Análisis de regresión',
    issuetracker: 'seguimiento de incidencias',
    labelsearch: 'etiqueta de búsqueda',
    gatewaysetup: 'Configuración de puerta de enlace',
    setupgateway: 'configuración de puerta de enlace',
    radiomesh: 'malla de radio',
    targetsetting: 'la fijacion de objetivos',
    updatetrackedissue: 'Problema de actualización de seguimiento',
    shownewtrackedissue: 'mostrar nueva emisión de orugas',
    low: 'bajo',
    mid: 'medio',
    high: 'alto',
    linear: 'lineal',
    exponential: 'exponencial',
    logarithmic: 'logarítmica',
    quadratic: 'cuadrático',
    polynomial: 'polinomio',
    general: 'General',
    datataggingenrichment: 'Los datos de marcado Enriquecimiento',
    hardware: 'Hardware',
    nodemanagement: 'gestión de nodos',
    createeditnotification: 'Crear aviso de edición',
    conditions: 'condiciones',
    addsku: 'añadir SKU',
    editsku: 'editar SKU',
    setupagateway: 'configurar una puerta de enlace',
    deviceserialnumber: 'número de serie del dispositivo',
    repeatsweekly: 'repite todas las semanas',
    repeatsdaily: 'repite todos los días',
    repeatsmonthly: 'repite todos los meses',
    repeatsannually: 'repite cada año',
    repeatsevery: 'Se repite cada',
    hourly: 'cada hora',
    biweekly: 'quincenal',
};
