import { makeRequest } from '../Shared/Shared.action';

// tile dashboard metainfo
export const api_getDashboardMetainfo = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/dashboard`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};
