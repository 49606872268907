/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, {
    forwardRef,
    useEffect,
    useState,
} from 'react';
import { useDrag } from 'react-dnd';
import { ResizableBox } from 'react-resizable';
import { getEmptyImage } from 'react-dnd-html5-backend';

// UTILS
import CONSTANTS from '../Constants';
import { DashboardConstants as K } from '../../store/old/UI/Dashboard/Dashboard.constants';


import './index.scss';


export const getWidgetDims = (d, gridCell, accessors = { height: (d) => d.height, width: (d) => d.width }) => {
    return {
        width: (accessors.width(d) || 1) * gridCell.width,
        height: (accessors.height(d) || 1) * gridCell.height,
    };
};

const getSteps = (value, stepSize) => {
    return Math.ceil(value / stepSize);
};

export const WidgetEditable = (props) => {
    const {
        data,
        children,
        editing = true,
        handleDragEnd,
        gridCell,
        handleDelete,
        handleEdit,
        showEdit,
        showDelete,
    } = props;

    const dims = getWidgetDims(data, gridCell);
    const [width, setWidth] = useState(dims.width);
    const [height, setHeight] = useState(dims.height);

    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: CONSTANTS.DND.ITEM_TYPES.WIDGET,
            item: { type: CONSTANTS.DND.ITEM_TYPES.WIDGET, data },
            canDrag: () => editing,
            end: (item, monitor) => handleDragEnd(item, monitor),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [editing]
    );

    useEffect(() => {
        dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const style = { opacity: isDragging ? 0.4 : 1, width, height };
    const stepX = gridCell.width + gridCell.padding;
    const stepY = gridCell.height + gridCell.padding;

    return (
        <ResizableBox
            style={{ zIndex: 2 }}
            className={'widget__no-edit'}
            width={width}
            height={height}
            draggableOpts={{ grid: [stepX, stepY] }}
            onResize={(e, { size }) => {
                e.stopPropagation();
                setWidth(size.width);
                setHeight(size.height);
            }}
            onResizeStop={(e, { size }) => {
                const stepsX = getSteps(size.width, stepX);
                const stepsY = getSteps(size.height, stepY);

                // save resize
            }}
        >
            <>
                {showDelete && (
                    <div
                        className="widget__action-button widget__action-remove"
                        onClick={(e) => handleDelete(data)}
                    >
                        <i className="fas fa-times fa-2x" />
                    </div>
                )}
                {showEdit && (
                    <div
                        className="widget__action-button widget__action-edit"
                        onClick={(e) => handleEdit(data)}
                    >
                        <i className="fas fa-bars" />
                    </div>
                )}
                <WidgetWrapper
                    ref={drag}
                    className={editing ? 'widget--draggable' : ''}
                    data={data}
                    style={style}
                >
                    {children}
                </WidgetWrapper>
            </>
        </ResizableBox>
    );
};

WidgetEditable.defaultProps = {
    gridCell: {
        width: K.CELL_DIM.WIDTH,
        height: K.CELL_DIM.HEIGHT,
        padding: K.CELL_DIM.PADDING,
    },
    handleDragEnd: (item, monitor) => {},
    handleDelete: (w) => {},
    handleEdit: (w) => {},
    showEdit: false,
    showDelete: false,
};

export const WidgetWrapper = forwardRef((props, ref) => {
    const { data, children, className, style = {} } = props;

    return (
        <div
            className={`widget-wrapper with-shadows ${className || ''}`}
            style={{ ...style, background: '#fff', zIndex: 2 }}
            ref={ref}
        >
            <div className="widget__body">{children}</div>
        </div>
    );
});
