import { AxiosRequestConfig } from "axios"
import client from "./client"

export const callEntityReportsIndex = async (entityId: number, params?: Record<string, any>) => {
    return await client.get(`/entity/${entityId}/report`, { params })
}

export const callBlockReportsIndex = async (entityId: number, blockId: number, params?: Record<string, any>) => {
    return await client.get(`/entity/${entityId}/block/${blockId}/report`, { params })
}

export const callBlockReportsShow = async (entityId: number, blockId: number, reportId: number) => {
    return await client.get(`/entity/${entityId}/block/${blockId}/report/${reportId}`)
}

export const callBlockReportsStore = async (entityId: number, blockId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/report`, data)
}

export const callBlockReportsUpdate = async (entityId: number, blockId: number, reportId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/report/${reportId}`, data, { headers: { "X-HTTP-Method-Override": "PUT" } })
}

export const callBlockReportsDestroy = async (entityId: number, blockId: number, reportId: number) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/report/${reportId}`, {}, { headers: { "X-HTTP-Method-Override": "DELETE" } })
}

export const callBlockReportsPreview = async (entityId: number, blockId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/report/preview`, data)
}

export const callEntityObjectivesIndex = async (entityId: number, params?: Record<string, any>) => {
    return await client.get(`/entity/${entityId}/objective`, { params })
}

export const callEntityObjectivesShow = async (entityId: number, objectiveId: number) => {
    return await client.get(`/entity/${entityId}/objective/${objectiveId}`)
}

export const callEntityObjectivesDestroy = async (entityId: number, objectiveId: number) => {
    return await client.post(`/entity/${entityId}/objective/${objectiveId}`, {}, {
        headers: {
            "X-HTTP-Method-Override": "DELETE",
        },
    })
}

export const callEntityBlockObjectivesStore = async (entityId: number, blockId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/objective`, data)
}

export const callEntityBlockObjectivesUpdate = async (entityId: number, blockId: number, objectiveId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/block/${blockId}/objective/${objectiveId}`, data, {
        headers: {
            "X-HTTP-Method-Override": "PUT",
        },
    })
}

export const callEntityObjectiveGoalsIndex = async (entityId: number, objectiveId: number) => {
    return await client.get(`/entity/${entityId}/objective/${objectiveId}/goal`)
}

export const callEntityObjectiveGoalsStore = async (entityId: number, objectiveId: number, data: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/objective/${objectiveId}/goal`, data)
}

export const callEntityObjectiveGoalUpdate = async (entityId: number, objectiveId: number, goalId: number, data?: Record<string, any>) => {
    return await client.post(`/entity/${entityId}/objective/${objectiveId}/goal/${goalId}`, data, {
        headers: {
            "X-HTTP-Method-Override": "PUT",
        },
    })
}

export const callEntityObjectiveGoalsDestroy = async (entityId: number, objectiveId: number, goalId: number) => {
    return await client.post(`/entity/${entityId}/objective/${objectiveId}/goal/${goalId}`, {}, { headers: { "X-HTTP-Method-Override": "DELETE" } })
}

export const callEntityUserPreferencesIndex = async (entityId: number) => {
    return await client.get(`/entity/${entityId}/user_preference`)
}

export const callEntityUserPreferencesStore = async (entityId: number, data: any[]) => {
    return await client.post(`/entity/${entityId}/user_preference`, data)
}

export const callPreferenceDestroy = async (entityId: number, preferenceId: number) => {
    return await client.post(`/entity/${entityId}/preference/${preferenceId}`, {}, { headers: { "X-HTTP-Method-Override": "DELETE" } })
}

export const callEntityBlockOee = async (entityId: number, blockId: number, params: Record<string, any>, options?: AxiosRequestConfig) => {
    return await client.get(`/entity/${entityId}/block/${blockId}/oee`, { ...options, params })
}
