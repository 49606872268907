import React from 'react';

const priorityIcon = ({ priority }) => {
    const icons = [
        { priority: 'high', icon: 'fas fa-arrow-up fa-2x', color: '#ea4444' },
        { priority: 'mid', icon: 'fas fa-arrows-alt-h fa-2x', color: '#ea7d24' },
        { priority: 'low', icon: 'fas fa-arrow-down fa-2x', color: '#55a556' },
    ];

    const icon = icons.find((i) => i.priority === priority);

    return icon ? (
        <span key={icon.priority} style={{ color: icon.color }}>
            <i className={icon.icon} />
        </span>
    ) : null;
};

export default priorityIcon;
