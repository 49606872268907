/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useState, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unstable_batchedUpdates } from 'react-dom';
import { isEmpty, values } from 'lodash';
import { Input, Select, DatePicker, Button } from 'antd';

import { CalculatorOutlined } from '@ant-design/icons';

// CONTEXT
import { TargetSettingContext } from '.';

// COMPONENTS
import AukTooltip from '../../components/AukTooltip';
import LabelWrapper from '../../components/LabelWrapper';
import ModalPanel from '../../components/ModalPanel';
import { flash } from '../../components/Flash';
import { Permission } from '../../components/Permission';
import { PanelHeader, PanelBody, PanelFooter } from '../../components/Panel';

// CONSTANTS
import { UPDATE, CREATE } from './Action';

// SELECTOR
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { assetsSelector, assetsState } from '../../../store/old/Assets/Assets.selector';

// HELPERS
import { defaultFilterOption } from '../../utils/helpers';
import translate from '../../utils/translate';

const validate = {
    value: (v) => isFinite(v) && v > 0,
    asset: (v) => v,
    range: (v) => v && v[0].isBefore(v[1]),
};

const { RangePicker } = DatePicker;

export const FormPlanModal = () => {
    const { showModal, action } = useContext(TargetSettingContext);

    if (showModal) {
        return (
            <FormModal label={action === UPDATE ? 'Edit Plan' : 'Create Plan'} />
        );
    }

    return null;
};

const TargetInput = ({
    value,
    onChange,
    isValid,
    stdTime,
    calculateOutput,
}) => {
    return (
        <LabelWrapper col={3} label={<span>{translate('target')}*</span>}>
            <div className="d-flex">
                <Input
                    style={{ flexShrink: 0 }}
                    className={`w-50 ${isValid ? '' : 'is-invalid'}`}
                    type="number"
                    onChange={onChange}
                    value={value}
                />
                <div
                    className="ml-2 d-flex align-items-center"
                    style={{
                        flexGrow: 1,
                        fontSize: 11,
                        opacity: !stdTime ? 0.3 : 1,
                    }}
                >
                    <AukTooltip.Help
                        placement="top"
                        title={
                            !stdTime ? 'Valid SKU-Asset standard time is required' : ''
                        }
                    >
                        <Button
                            className="d-flex align-items-center justify-content-center mr-1"
                            type="primary"
                            icon={<CalculatorOutlined />}
                            onClick={() => stdTime && calculateOutput()}
                            style={{
                                height: 24,
                                width: 24,
                                fontSize: 12,
                                cursor: !stdTime ? 'default' : 'pointer',
                            }}
                        />
                    </AukTooltip.Help>
                Use Standard Time {stdTime && `(${stdTime.std_time})`}
                </div>
            </div>
        </LabelWrapper>
    );
};

const NotesInput = ({ value, onChange }) => {
    return (
        <LabelWrapper col={3} label={translate('notes')}>
            <Input onChange={onChange} value={value} maxLength={50} />
        </LabelWrapper>
    );
};

const AssetSelect = ({ value, onChange, isValid }) => {
    const {
        store: { assetList },
    } = useContext(TargetSettingContext);
    const options = assetList.map((a) => ({
        value: a.asset_id,
        label: a.asset_name,
    }));

    return (
        <LabelWrapper col={3} label={<span>{translate('asset')}*</span>} id="asset">
            <Select
                className={`w-100 ${isValid ? '' : 'is-invalid'}`}
                options={options}
                value={value}
                onChange={onChange}
                getPopupContainer={() => document.getElementById('asset')}
                showSearch
                filterOption={defaultFilterOption}
            />
        </LabelWrapper>
    );
};

const SkuSelect = ({ value, onChange }) => {
    const {
        store: { skuList },
    } = useContext(TargetSettingContext);
    const options = skuList.map((s) => ({ value: s.sku_id, label: s.codeName }));

    return (
        <LabelWrapper
            col={3}
            label={<span>SKU ({translate('optional')})</span>}
            id="sku"
        >
            <Select
                className="w-100"
                options={options}
                value={value}
                onChange={onChange}
                getPopupContainer={() => document.getElementById('sku')}
                allowClear
                showSearch
                filterOption={defaultFilterOption}
            />
        </LabelWrapper>
    );
};

const DateInput = ({ value, onChange, isValid }) => {
    const user = useSelector(userSelector);

    return (
        <LabelWrapper
            col={3}
            label={<span>{translate('from')} - {translate('to')}*</span>}
            id="range"
        >
            <RangePicker
                format="YYYY-MM-DD HH:mm"
                className={`w-100 ${isValid ? '' : 'is-invalid'}`}
                value={value}
                suffixIcon={false}
                clearIcon={false}
                onChange={onChange}
                showTime={{ format: 'HH:mm' }}
                getPopupContainer={() => document.getElementById('range')}
                disabled={
                    !user.check_resource_policy('targets', false, true, false)
                }
            />
        </LabelWrapper>
    );
};

const FormModal = ({ label }) => {
    const {
        changeModalStatus,
        currentPlan,
        handleCreatePlan,
        handleUpdatePlan,
        changeConfirmationBoxStatus,
        action,
        setAction,
        setCurrentSKU,
    } = useContext(TargetSettingContext);

    const skuCharts = useSelector(appState => appState.skuCharts.skuCharts)
    const assets = useSelector(assetsSelector)

    const [submitted, handleChangeSubmit] = useState(false);
    const [value, handleChangeTarget] = useState(currentPlan.value);
    const [asset_id, handleChangeAsset] = useState(currentPlan.asset_id);
    const [sku_id, handleChangeSku] = useState(currentPlan.sku_id);
    const [range, handleChangeRange] = useState([
        moment(currentPlan.from),
        moment(currentPlan.to),
    ]);
    const [notes, handleChangeNotes] = useState(currentPlan.notes || '');
    const asset = useMemo(() => assets[asset_id], [assets, asset_id])
    const skuChart = asset_id && sku_id && skuCharts[`${sku_id}-${asset._primaryChart.chart_id}`];

    const handleCancel = () => {
        unstable_batchedUpdates(() => {
            changeModalStatus();
            setAction('');
        });
    };

    const handleDelete = () => {
        changeConfirmationBoxStatus();
    };

    const handleSubmit = () => {
        const data = formData();

        handleChangeSubmit(true);

        const errors = formErrors();
        if (errors) {
            return flash({
                message: 'Invalid inputs',
                details: (
                    <ol>
                        {values(errors).map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ol>
                ),
                status: 'warning',
            });
        }

        if (action === CREATE) return handleCreatePlan(data);
        if (action === UPDATE) return handleUpdatePlan(data);
    };

    const formData = () => {
        return {
            value,
            asset_id,
            sku_id: sku_id || null,
            from: range[0],
            to: range[1],
            notes: notes.trim(),
        };
    };

    const formErrors = () => {
        const errors = {};
        if (!validate.asset(asset_id)) errors.asset = 'Asset field is required.';
        if (!validate.value(value))
            errors.value = 'Target field (> 0) is required.';
        if (!validate.range(range))
            errors.range = 'Plan start should be before plan end.';

        return isEmpty(errors) ? null : errors;
    };

    const calculateTargetOutputFromStdTime = () => {
    // std time = x seconds per unit output
        const result = Math.floor(
            range[1].diff(range[0], 'seconds') * (1 / skuChart.std_time)
        );

        return handleChangeTarget(result);
    };

    return (
        <ModalPanel className="target-setting-plan-modal">
            <PanelHeader>{label}</PanelHeader>
            <PanelBody>
                <DateInput
                    isValid={submitted ? validate.range(range) : true}
                    value={range} // keep value in state
                    onChange={handleChangeRange}
                />
                <AssetSelect
                    isValid={submitted ? validate.asset(asset_id) : true}
                    value={asset_id}
                    onChange={handleChangeAsset}
                />
                <SkuSelect
                    value={sku_id}
                    onChange={(e) => {
                        action === CREATE && setCurrentSKU(e);
                        handleChangeSku(e);
                    }}
                />
                <TargetInput
                    stdTime={skuChart}
                    calculateOutput={calculateTargetOutputFromStdTime}
                    isValid={submitted ? validate.value(value) : true}
                    value={value}
                    onChange={(e) =>
                        e.target.value <= 999999 && handleChangeTarget(e.target.value)
                    }
                />
                <NotesInput
                    value={notes}
                    onChange={(e) => handleChangeNotes(e.target.value)}
                />
            </PanelBody>
            <PanelFooter>
                <button className="btn b-cancel" onClick={() => handleCancel()}>
                    {translate('cancel')}
                </button>
                {action === UPDATE ? (
                    <Permission forResource resource="targets" canDo="full">
                        <button className="btn b-delete" onClick={() => handleDelete()}>
                            {translate('delete')}
                        </button>
                    </Permission>
                ) : null}
                <button className="btn b-save" onClick={() => handleSubmit()}>
                    {translate('save')}
                </button>
            </PanelFooter>
        </ModalPanel>
    );
};
