import { makeRequest } from '../Shared/Shared.action';

// SELECTOR
import { currentEntitySelector } from '../Entity/Entity.selector';

// REDUX STORE
import { store } from '../..';

export const api_updateEntityImage = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/image`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_uploadImage = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/image`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_uploadTrackedIssueMedia = (tracked_issue_id, base64) => {
    const { entity_id } = currentEntitySelector(store.getState());
    const data = {
        file_type: 'image',
        base64,
    };

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue/${tracked_issue_id}/media`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
