export const DashboardConstants = {
    ACTIONS: {
        DASHBOARD_TOGGLE_SUMMARY_TILE_LOADING:
      'DASHBOARD_TOGGLE_SUMMARY_TILE_LOADING',
        DASHBOARD_CHANGE_VIEW_MODE: 'DASHBOARD_CHANGE_VIEW_MODE',
        DASHBOARD_CHANGE_MODE: 'DASHBOARD_CHANGE_MODE',
    },
    MODES: {
        VIEW: 'VIEW',
        EDIT: 'EDIT',
    },
    VIEW: {
        TIME_SERIES: 'TIME_SERIES',
        SUMMARY: 'SUMMARY',
        LABEL: 'LABEL',
    },
    CELL_DIM: {
        WIDTH: 170,
        HEIGHT: 250,
        PADDING: 20,
    },
};
