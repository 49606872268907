/* eslint-disable react/prop-types */
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import IconButton from "./IconButton";

const IconButtonClose = ({ className, onClick }) =>
    <IconButton className={className}
        onClick={onClick}
        icon={<CloseOutlined />}/>;

export default IconButtonClose;
