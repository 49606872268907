import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid"
import moment from "moment/moment"
import React from "react"
import { AutoSizer } from "react-virtualized"
import ProcessTreeTable from "../../../../../../components/Table/ProcessTreeTable/ProcessTreeTable"
import {
    objectiveDuration,
    rangeToTimeKey,
    TimeKey,
    TimeRange,
} from "../../../../../../store/actions/goals.action"
import { useAppSelector } from "../../../../../../store/hooks"
import { selectActiveEntity } from "../../../../../../store/selectors/entities.selectors"
import {
    selectCurrentProcess,
    selectObjective,
    selectObjectiveGoals,
    selectObjectiveLoading
} from "../../../../../../store/selectors/goals.selectors"
import EndGoalInput from "../../ObjectiveEndGoal/EndGoalInput/EndGoalInput"
import { getTimeFormat } from "../ObjectiveProgressivePlan"

const COLUMN_WIDTH = 120
const ProgressivePlanEdit = () => {
    const loading = useAppSelector(selectObjectiveLoading)
    const viewing = useAppSelector(selectObjective)
    const goals = useAppSelector(selectObjectiveGoals)
    const entity = useAppSelector(selectActiveEntity)
    const currentProcess = useAppSelector(selectCurrentProcess)

    if (!viewing) {
        return null
    }

    const timeFrame = objectiveDuration(viewing, entity)
    const [lastFrame] = timeFrame.splice(timeFrame.length-1, 1)
    const columns: GridColDef[] = timeFrame.map(column => generateTimeColumn(
        column,
        viewing.unitOfTime as moment.unitOfTime.Base,
        loading,
    ))

    const lastTimeKey = rangeToTimeKey(lastFrame)
    columns.push({
        field: lastTimeKey,
        headerName: getTimeFormat(lastFrame.startsAt, viewing.unitOfTime as moment.unitOfTime.Base),
        valueFormatter: (params: GridValueFormatterParams) => `${params.value ?? 0}%`,
        width: COLUMN_WIDTH,
        type: "string",
        align: "right",
        sortable: false,
    })

    const data: Datum[] = []
    for (const blockId in goals) {
        const item: Datum = { id: +blockId }
        for (const column of columns) {
            item[column.field] = goals[blockId][column.field]?.value
        }

        data.push(item)
    }

    return <AutoSizer>
        {({ width, height }) =>
            <ProcessTreeTable
                process={currentProcess}
                columns={columns}
                width={width}
                height={height}
                rowHeight={56}
                data={data}
            />
        }
    </AutoSizer>
}

const generateTimeColumn = (range: TimeRange, unitOfTime: moment.unitOfTime.Base, disabled: boolean): GridColDef => {
    const timeKey = rangeToTimeKey(range)
    return {
        field: timeKey,
        headerName: getTimeFormat(range.startsAt, unitOfTime),
        type: "string",
        width: COLUMN_WIDTH,
        sortable: false,
        align: "right",
        valueFormatter: (params: GridValueFormatterParams) => {
            return <EndGoalInput
                blockId={+params.id!}
                timeKey={timeKey}
                value={params.value}
                disabled={disabled}
            />
        },
    }
}

type Datum = {
    id: Domain.BlockId
    [key: TimeKey]: number | string
}

export default ProgressivePlanEdit
