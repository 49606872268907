import { put, takeLatest } from 'redux-saga/effects';

import { IssueConstants as K } from './Issues.constant';

// ISSUE SERVICES
import { api_updateIssues, api_getIssues } from './Issues.services';

// DEVICE ACTION
import { setIssuesState } from './Issues.action';

import { errorFlash, flash } from '../../../legacy/components/Flash';

// UPDATE MULTI ISSUE
function* handleUpdateMultipleIssues(action) {
    try {
        const response = yield api_updateIssues(action.payload);

        yield put(setIssuesState(response));

        flash({
            message: 'Updates successful',
            status: 'success',
        });

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateMultipleIssuesSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ISSUES_REQUEST,
        handleUpdateMultipleIssues
    );
}

// FETCH ISSUES
function* handleFetchIssuesRequest(action) {
    try {
        const entity_id = action.payload;
        const issues = yield api_getIssues(entity_id);
        yield put(setIssuesState(issues, true));

        if (action.callback) {
            action.callback(issues);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchIssuesRequestSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ISSUES_REQUEST,
        handleFetchIssuesRequest
    );
}
