import { store } from '../../store';
import { OEEFactory } from '../utils/oee';
import { Label } from './Label';
import { User } from './User';

export const parseBlockArguments = (b) => {
    return [
        b.block_id,
        b.parent_block_id,
        +b.entity_id,
        b.asset_id,
        b.operation,
        b.weightage,
        b.order,
        b.hierarchy_level,
        b.bn_mode,
        b.bn_block_id,
        b.block_name,
        b.user_blocks,
    ];
};

export class Block {
    constructor(
        block_id,
        parent_block_id = null,
        entity_id,
        asset_id = null,
        operation = 'or',
        weightage = 100,
        order,
        hierarchy_level,
        bn_mode = null,
        bn_block_id = null,
        block_name = '',
        user_blocks = []
    ) {
        this.block_id = block_id;
        this.parent_block_id = parent_block_id;
        this.entity_id = entity_id;
        this.asset_id = asset_id;
        this.operation = operation;
        this.weightage = weightage;
        this.order = order;
        this.hierarchy_level = hierarchy_level;
        this.bn_mode = bn_mode;
        this.bn_block_id = bn_block_id;
        this.block_name = block_name;
        this.user_blocks = user_blocks;
    }

    get labels() {
    // issue labels
        const {
            label: { labels: labelsState, by_block: blockLabelsMap },
        } = store.getState();

        return blockLabelsMap[this.block_id]
            ? blockLabelsMap[this.block_id]
                .map((label_id) => labelsState[label_id])
                .filter((l) => l)
                .sort((a, b) => b.from - a.from)
            : [];
    }

    get users() {
        const {
            user: { list },
        } = store.getState();

        return this.user_blocks.map((u) => list[u.user_id]).filter((u) => u);
    }

    get children() {
        const {
            blocks: { blocks, pc_directory },
        } = store.getState();
        const { block_id } = this;

        return pc_directory[block_id]
            ? pc_directory[block_id]
                .map((child_id) => blocks[child_id])
                .sort((a, b) => a.order - b.order)
            : [];
    }

    get oee() {
        const { data } = store.getState();

        return data.blocks[this.block_id] || OEEFactory({});
    }

    get asset() {
        const {
            assets: { assets },
        } = store.getState();

        return this.asset_id ? assets[this.asset_id] : null;
    }

    get label() {
        return this.asset ? this.asset.asset_name : this.block_name || '-';
    }

    get issues_excluded() {
        const {
            blocks: { blocks_issues_exclusions },
        } = store.getState();

        return blocks_issues_exclusions[this.block_id] || {};
    }

    get parent() {
        const {
            blocks: { blocks },
        } = store.getState();

        return this.parent_block_id ? blocks[this.parent_block_id] : null;
    }

    get hierarchy() {
        const result = [];

        let current = this;

        while (current) {
            result.push(current);
            current = current.parent;
        }

        return result;
    }

    get nextSibling() {
        if (!this.parent) return null;
        const siblings = this.parent.children;
        return siblings[siblings.indexOf(this) + 1];
    }

    get isSequence() {
        return this.operation === 'seq' || this.operation === 'sequence';
    }

    get lastInSequence() {
        if (!this.isLastChild) return false;

        if (this.isWithinSequenceBlock && this.isLastChild) return true;

        return false;
    }

    get isLastChild() {
        if (!this.parent) return false;

        return (
            this.parent.children[this.parent.children.length - 1].block_id ===
      this.block_id
        );
    }

    get isWithinSequenceBlock() {
        if (!this.parent) return false;

        return this.parent.isSequence;
    }

    get draw() {
        if (!this.parent)
            return { drawPrepend: false, drawSequence: false, drawPost: false };

        const drawPrepend = this.isWithinSequenceBlock && !this.isSequence;
        const drawSequence =
      this.parent && this.parent.isSequence && this.nextSibling;
        const drawPost = drawPrepend && !this.lastInSequence;

        return { drawPrepend, drawSequence, drawPost };
    }

    get isRestricted() {
        const {
            auth: {
                user: { policy, role_name },
            },
        } = store.getState();

        if (!policy) return false;

        if (role_name === 'owner') return false;

        return !policy.blocks.find((b) => b.block_id === this.block_id);
    }

    clone() {
        return { ...this };
    }
}
