import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addFusionsResource, removeFusionsResource } from './Fusions.action';
import { api_createFusion, api_updateFusion, api_deleteFusion } from './Fusions.services';
import { Fusion, parseFusionArguments } from '../../../legacy/models';
import { FusionConstants as K } from './Fusions.constants';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { fetchAssetCharts } from '../Assets/Assets.action';

function* handleCreateFusion(action) {
    try {
        const { entity_id, asset_id, data } = action.payload;
        const response = yield call(api_createFusion, asset_id, data);

        const fusion = new Fusion(
            ...parseFusionArguments({
                ...response,
                asset_id,
            })
        );

        yield all([
            put(addFusionsResource({ [fusion.fusion_id]: fusion })),
            put(fetchAssetCharts(entity_id, asset_id))
        ]);

        flash({ message: 'Fusion chart created', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createFusionSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_FUSION_REQUEST,
        handleCreateFusion
    );
}

// UPDATE FUSION
function* handleUpdateFusion(action) {
    try {
        const { asset_id, data } = action.payload;

        const response = yield call(api_updateFusion, asset_id, data);
        const fusion = new Fusion(
            ...parseFusionArguments({
                ...data,
                ...response,
            })
        );

        yield put(addFusionsResource({ [fusion.fusion_id]: fusion }));
        flash({ message: 'Fusion chart updated', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateFusionSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_FUSION_REQUEST,
        handleUpdateFusion
    );
}

// DELETE FUSION
function* handleDeleteFusion(action) {
    try {
        const { entity_id, asset_id, fusion_id } = action.payload;
        const response = yield call(api_deleteFusion, entity_id, asset_id, fusion_id);

        yield all([
            put(removeFusionsResource([fusion_id])),
            put(fetchAssetCharts(entity_id, asset_id))
        ])

        flash({ message: 'Fusion chart deleted', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteFusionSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_FUSION_REQUEST,
        handleDeleteFusion
    );
}
