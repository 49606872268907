export const SchedulesConstants = {
    ACTIONS: {
        GET_SCHEDULES_REQUEST: 'GET_SCHEDULES_REQUEST',
        SET_SCHEDULES_STATE: 'SET_SCHEDULES_STATE',
        SET_SCHEDULES_FILTER: 'SET_SCHEDULES_FILTER',
        RESET_SCHEDULES_STORE: 'RESET_SCHEDULES_STORE',

        SET_SHIFTS_RESOURCE: 'SET_SHIFTS_RESOURCE',
        ADD_SHIFTS_RESOURCE: 'ADD_SHIFTS_RESOURCE',
        REMOVE_SHIFTS_RESOURCE: 'REMOVE_SHIFTS_RESOURCE',
        GET_SHIFTS_REQUEST: 'GET_SHIFTS_REQUEST',
        CREATE_SHIFT_REQUEST: 'CREATE_SHIFT_REQUEST',
        UPDATE_SHIFT_REQUEST: 'UPDATE_SHIFT_REQUEST',
        STOP_SHIFT_REQUEST: 'STOP_SHIFT_REQUEST',
        DELETE_SHIFTS_REQUEST: 'DELETE_SHIFTS_REQUEST',
        SET_DOWNTIMES_RESOURCE: 'SET_DOWNTIMES_RESOURCE',
        ADD_DOWNTIMES_RESOURCE: 'ADD_DOWNTIMES_RESOURCE',
        REMOVE_DOWNTIMES_RESOURCE: 'REMOVE_DOWNTIMES_RESOURCE',
        GET_DOWNTIMES_REQUEST: 'GET_DOWNTIMES_REQUEST',
        CREATE_DOWNTIME_REQUEST: 'CREATE_DOWNTIME_REQUEST',
        UPDATE_DOWNTIME_REQUEST: 'UPDATE_DOWNTIME_REQUEST',
        STOP_DOWNTIME_REQUEST: 'STOP_DOWNTIME_REQUEST',
        DELETE_DOWNTIMES_REQUEST: 'DELETE_DOWNTIMES_REQUEST',
        GET_ASSET_SHIFTS_REQUEST: 'GET_ASSET_SHIFTS_REQUEST',
        ADD_ASSET_SHIFTS_RESOURCE: 'ADD_ASSET_SHIFTS_RESOURCE',
    },
    SHIFT: 'shift',
    DOWNTIME: 'downtime',
    FILTER_BY: {
        ALL: 'All',
        ACTIVE: 'Active',
        ARCHIVED: 'Archived',
    },
};
