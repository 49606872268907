import { WidgetConstants as K } from './Widget.constants';

export const ResetWidgetsState = () => {
    return {
        type: K.ACTIONS.RESET_WIDGETS_STATE,
    };
};

export const SetWidgetsResource = (widgets) => {
    return {
        type: K.ACTIONS.SET_WIDGETS_RESOURCE,
        payload: { widgets },
    };
};

export const AddWidgetsResource = (widgets) => {
    return {
        type: K.ACTIONS.ADD_WIDGETS_RESOURCE,
        payload: { widgets },
    };
};

export const RemoveWidgetsResource = (widget_ids) => {
    return {
        type: K.ACTIONS.REMOVE_WIDGETS_RESOURCE,
        payload: { widget_ids },
    };
};
