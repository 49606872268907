import { call, put, takeLatest } from 'redux-saga/effects';

// SERVICES
import { api_updateEntityImage } from './Images.services';

// ACTION
import { addEntitiesResource } from '../Entity/Entity.action';
import { updateEntityImageRequestSuccess } from './Images.action';

// REDUX STORE
import { store } from '../..';

// CONSTANTS
import { ImagesConstants as K } from './Images.constants';

// SELECTORS
import { currentEntitySelector } from '../Entity/Entity.selector';

// MODELS
import { Entity, parseEntityArguments } from '../../../legacy/models';
import { errorFlash } from '../../../legacy/components/Flash';

function* handleEntityImageRequest(action) {
    try {
        const { base64Img } = action.payload;
        const res = yield call(api_updateEntityImage, {
            base64Img,
            image_type: 'entity',
        });

        yield put(updateEntityImageRequestSuccess(res));
    } catch (error) {
        errorFlash(error);
    }
}

export function* entityImageRequestSaga() {
    yield takeLatest(K.ACTIONS.UPDATE_ENTITY_IMAGE_REQUEST, handleEntityImageRequest);
}

function* handleEntityImageRequestSuccess(action) {
    try {
        const { image_id, signedUrl } = action.payload;
        const previous = currentEntitySelector(store.getState());
        const entity = new Entity(
            ...parseEntityArguments({
                ...previous.clone(),
                entity_img_id: image_id,
                signedUrl,
            })
        );

        yield put(addEntitiesResource({ [entity.entity_id]: entity }));
    } catch (error) {
        errorFlash(error);
    }
}

export function* entityImageRequestSuccessSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ENTITY_IMAGE_REQUEST_SUCCESS,
        handleEntityImageRequestSuccess
    );
}
