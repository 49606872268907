/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { Input, Modal } from 'antd';
import { updateAssetStdTime } from '../../../../store/old/Assets/Assets.action';

import { InfoCircleOutlined } from '@ant-design/icons';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import translate from '../../../utils/translate';
import { createSkuChart, updateSkuChart } from '../../../../store/old/SkuCharts/SkuCharts.action';
import { stdTimeMaxLength } from '../SkuStandardTimes';

class ModalStandardTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stdTime: 0,
            autoUpdate: false,
            speed: false,
            showModalStandardtime: false,
            asset: null,
            skuChart: null,
            action: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    changeStateModalStandardTime(
        action,
        asset,
        skuChart,
    ) {
        if (skuChart) {
            return this.setState({
                action,
                asset,
                skuChart,
                showModalStandardtime: true,
                stdTime: skuChart.std_time,
                autoUpdate: skuChart.auto_update
            });
        }


        return this.setState({
            action,
            asset,
            showModalStandardtime: true,
            stdTime: asset.stdTime,
            autoUpdate: asset.autoUpdate,
            speed: asset.speed
        });
    }

    handleChangeInput = (e) => {
        if (
            e.target.value === '' ||
      (isFinite(e.target.value) && +e.target.value >= 0)
        ) {
            this.setState({ input: e.target.value });
        }
    };

    save() {
        const { entity_id } = this.props.entity;
        const { asset, action } = this.state;
        if (action === 'update-asset') {
            return this.props.updateAssetStandardTime(
                entity_id, 
                asset.asset_id,  
                {
                    speed: this.state.speed,
                    autoUpdate: this.state.autoUpdate,
                    stdTime: this.state.stdTime
                },
                () => {
                    this.setState({ showModalStandardtime: false, action: '' });
                }
            );
        }

        if (action === 'create-update-sku-chart') {
            const { skuChart } = this.state;
            if (skuChart.sku_chart_id) {
                return this.props.updateSkuChart(
                    entity_id,
                    asset.asset_id,
                    skuChart.chart_id,
                    skuChart.sku_id,
                    {
                        auto_update: this.state.autoUpdate,
                        std_time: this.state.stdTime,
                        sku_chart_id: skuChart.sku_chart_id
                    },
                    () => {
                        this.setState({ showModalStandardtime: false, action: '' });
                    }
                )
            }

            return this.props.createSkuChart(
                entity_id,
                asset.asset_id,
                skuChart.chart_id,
                skuChart.sku_id,
                {
                    auto_update: !!this.state.autoUpdate,
                    std_time: this.state.stdTime
                },
                () => {
                    this.setState({ showModalStandardtime: false, action: '' });
                }
            )
        }
    }

    handleChange(field, value) {
        this.setState({ [field]: value});
    }

    get generateTitle() {
        const { action, asset, skuChart } = this.state;

        if (!action) return '';

        if (action === 'update-asset') {
            return `Asset: ${asset.asset_name}`;
        }
        
        return `SKU: ${skuChart.sku.codeName} - Asset:${asset.asset_name}`;
    }

    render() {
        const { action, showModalStandardtime } = this.state;
        return (
            <Modal
                className="auk-modal custom-modal-oee-table"
                title={this.generateTitle}
                open={showModalStandardtime}
                onOk={this.save}
                onCancel={() => this.setState({ showModalStandardtime: false })}
            >
                {
                    action === 'create-update-sku-chart' ? (
                        <SkuChartStandardTimeForm 
                            handleChange={this.handleChange}
                            data={{
                                stdTime: this.state.stdTime,
                                autoUpdate: this.state.autoUpdate,
                            }}
                        />
                    ) : 
                    null
                }
                {
                    action === 'update-asset' ? (
                        <AssetStandardTimeForm 
                            handleChange={this.handleChange}
                            data={{
                                stdTime: this.state.stdTime,
                                autoUpdate: this.state.autoUpdate,
                                speed: this.state.speed,
                            }}
                        />
                    ) : 
                    null
                }
            </Modal>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        entity: currentEntitySelector(appState),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAssetStandardTime: (entity_id, asset_id, payload, callback) =>
            dispatch(updateAssetStdTime(entity_id, asset_id, payload, callback)),
        createSkuChart: (entity_id, asset_id, chart_id, sku_id, data, callback) => 
            dispatch(createSkuChart(entity_id, asset_id, chart_id, sku_id, data, callback)),
        updateSkuChart: (entity_id, asset_id, chart_id, sku_id, data, callback) => 
            dispatch(updateSkuChart(entity_id, asset_id, chart_id, sku_id, data, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ModalStandardTime);

const AssetStandardTimeForm = (props) => {
    return (
        <>
            <div className="row oee-table-lable-row">
                <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                    <>
                        <span style={{ textTransform: 'capitalize' }}>
                            {translate('speedloss')}
                        </span>
                (s)
                    </>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                    <Toggle
                        icons={false}
                        checked={props.data.speed}
                        onChange={(event) => { props.handleChange('speed', event.target.checked) }}
                    />
                </div>
            </div>
            <div className="row oee-table-lable-row">
                <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                    <>
                        <span style={{ textTransform: 'capitalize' }}>
                            {translate('autocycletime')}
                        </span>
                (s)
                    </>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                    <Toggle
                        icons={false}
                        checked={props.data.autoUpdate}
                        onChange={(event) => { props.handleChange('autoUpdate', event.target.checked) }}
                    />
                </div>
            </div>

            <div className="row oee-table-lable-row">
                <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                    <span>{translate('standardtimes')}</span>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-8">
                    <Input
                        maxLength={8}
                        value={props.data.stdTime}
                        onChange={(e) => { props.handleChange('stdTime', e.target.value) }}
                    />
                </div>
            </div>
        </>
    );
}

const SkuChartStandardTimeForm = (props) => {
    return (
        <>
            <div className="row oee-table-lable-row">
                <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                    <>
                        <p>
                            {translate('autocycletime')} (s)
                        </p>
                
                    </>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                    <Toggle
                        icons={false}
                        checked={props.data.autoUpdate}
                        onChange={(event) => { props.handleChange('autoUpdate', event.target.checked) }}
                    />
                </div>
            </div>
            <div className="row oee-table-lable-row">
                <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                    <div className="d-flex">
                        {translate('standardtimes')}
                        <span title="Set as 0 to remove" className="d-flex align-items-center ml-2">
                            <InfoCircleOutlined />
                        </span>
                    </div>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-8">
                    <Input
                        type="number"
                        maxLength={stdTimeMaxLength}
                        value={props.data.stdTime}
                        onChange={(e) => { props.handleChange('stdTime', e.target.value) }}
                    />
                </div>
            </div>
        </>
    );
}