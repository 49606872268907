/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { Result } from "antd"
import BlockModel from "../../api/models/block.model"
import { useParams } from "react-router-dom"
import useBlocks from "../../hooks/useBlocks"

const WithBlock = (props: Props) => {
    const params = useParams()
    const { blocks } = useBlocks()

    const [block, setBlock] = useState<BlockModel | undefined>(params.blockId ? blocks[params.blockId] : undefined)

    useEffect(() => {
        if (params.blockId && blocks[params.blockId]) {
            setBlock(blocks[params.blockId] as BlockModel)
            return
        }

        setBlock(undefined)
    }, [params.blockId])

    if (block) {
        return <React.Fragment key={block.blockId}>
            {props.render({ block })}
        </React.Fragment>
    }

    return <div className="d-flex w-100 h-100 justify-content-center align-items-center">
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
        />
    </div>
}

const withBlock = (Component: React.FC<any>) => {
    return (componentProps: any) => {
        return (
            <WithBlock
                render={(props) => <Component {...componentProps} {...props} />}
            />
        )
    }
}

export default withBlock

interface Props {
    render: (props: BlockRenderProps) => React.ReactNode 
}

export interface BlockRenderProps {
    block: BlockModel | null | undefined
}
