export const FusionConstants = {
    ACTIONS: {
        RESET_FUSIONS_STATE: 'RESET_FUSIONS_STATE',
        FETCH_FUSIONS_REQUEST: 'FETCH_FUSIONS_REQUEST',
        // FETCH_FUSIONS_REQUEST_SUCCESS: 'FETCH_FUSIONS_REQUEST_SUCCESS',
        // UPDATE_FUSION_SUCCESS: 'UPDATE_FUSION_SUCCESS',
        SET_FUSIONS_RESOURCE: 'SET_FUSIONS_RESOURCE',
        ADD_FUSIONS_RESOURCE: 'ADD_FUSIONS_RESOURCE',
        REMOVE_FUSIONS_RESOURCE: 'REMOVE_FUSIONS_RESOURCE',
        UPDATE_FUSION_REQUEST: 'UPDATE_FUSION_REQUEST',
        CREATE_FUSION_REQUEST: 'CREATE_FUSION_REQUEST',
        DELETE_FUSION_REQUEST: 'DELETE_FUSION_REQUEST',
    },
};
