import type BlockModel from "../models/block.model"

const BlockTransformer = (res: Record<any, any>): BlockModel => ({
    blockId: res.block_id,
    parentBlockId: res.parent_block_id,
    entityId: res.entity_id,
    assetId: res.asset_id,
    operation: res.operation,
    order: res.order,
    hierarchyLevel: res.hierarchy_level,
    weightage: res.weightage,
    bnMode: res.bn_mode,
    bnBlockId: res.bn_block_id,
    blockName: res.block_name,
    userBlocks: res.user_blocks,
    label: res.label,
    children: res.children.length > 0 ? res.children : undefined
})

export default BlockTransformer
