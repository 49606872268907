/* eslint-disable react/prop-types */
import React from 'react';

import './SPAWrapper.scss';
import { Layout } from 'antd';
export const SPAWrapper = (props) => {
    return (
        <Layout className={`spa-wrapper ${props.className || ''}`}>
            {props.children}
        </Layout>
    );
};
