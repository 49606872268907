import { values, isEmpty } from 'lodash';

export const devicesState = (appState) =>
    appState.devices.devices;

export const arrayConnectedDevices = (appState) => {
    return values(devicesState(appState))
        .filter((d) => d.asset)
        .sort((a, b) => b.device_id - a.device_id);
};

export const minDevicePushRate = (appState) => {
    const devices = devicesState(appState);

    return isEmpty(devices)
        ? 60
        : Math.min(...values(devices).map((d) => d.sampling_rate));
};

export const arrayDevices = (appState) => {
    return values(devicesState(appState));
};
