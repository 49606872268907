import * as d3 from 'd3';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { BaseChartSimple } from './BaseChart';
import {
    clearCanvas,
    genColor,
    getTimeIntervalBandwidth,
    getTimeSeriesBrushXSelection,
} from '../../utils/charts';
import { isInteger } from 'lodash';

const labelMarginBottom = 6;
const labelFontSize = 10;

export const getColumnHeight = (d, y, yAccessor) => {
    const yVal = yAccessor(d);
    if (yVal === 0) return 1;
    if (yVal > 0) return Math.max(y(0) - y(yVal), 1);
    return Math.max(y(yVal) - y(0), 1);
};

export const getColumnY = (d, y, yAccessor) => {
    const yVal = yAccessor(d);
    if (yVal === 0) return y(yVal) - 1;
    if (yVal > 0) return Math.min(y(yVal), y(0) - 1);
    return y(0);
};

export const getColumnLabels = (d, x, y, yAccessor) => {
    const minWidthForLabels = 50;

    const val = yAccessor(d);
    const width = getTimeIntervalBandwidth(d, x);

    if (!val || width < minWidthForLabels) return '';

    if (isInteger(val)) return val;

    return val.toFixed(1);
};

class ColumnChartSimple extends BaseChartSimple {
    constructor(props) {
        super(props);

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                getBandY,
                getBandwidth,
                getBandheight,
                xAccessor,
                yAccessor,
                colorAccessor,
                useDataLabels,
                getDataLabels
            },
            x,
            y,
            h,
            custom,
        } = this;

        const t = transition || null;

        const selection = custom
            .selectAll('.bar')
            .data(data, (d) => x(xAccessor(d)));

        selection
            .enter()
            .append('custom')
            .attr('class', 'bar')
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', (d) => (getBandY ? getBandY(d, y, yAccessor) : yAccessor(d)))
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', (d) =>
                getBandheight ? getBandheight(d, y, yAccessor) : h - y(yAccessor(d))
            )
            .attr('fill', colorAccessor);

        selection
            .transition(t)
            .duration(duration)
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', (d) => (getBandY ? getBandY(d, y, yAccessor) : yAccessor(d)))
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', (d) => getBandheight ? getBandheight(d, y, yAccessor) : h - y(yAccessor(d)))
            .attr('fill', colorAccessor);

        selection.exit().remove();

        const textSelection = custom
            .selectAll('.text')
            .data(data, (d) => x(xAccessor(d)));

        if (useDataLabels) {
        // enter
            textSelection
                .enter()
                .append('custom')
                .attr('class', 'text')
                .attr('text', (d) => getDataLabels(d, x, y, yAccessor))
                .attr('x', (d) => x(xAccessor(d)) + getBandwidth(d, x) / 2)
                .attr('y', (d) => Math.max(h - (getBandheight ? getBandheight(d, y, yAccessor) : h - y(yAccessor(d))) - labelMarginBottom, labelFontSize))

            // update
            textSelection
                .transition(t)
                .duration(duration)
                .attr('text', (d) => getDataLabels(d, x, y, yAccessor))
                .attr('x', (d) => x(xAccessor(d)) + getBandwidth(d, x) / 2)
                .attr('y', (d) => Math.max(h - (getBandheight ? getBandheight(d, y, yAccessor) : h - y(yAccessor(d))) - labelMarginBottom, labelFontSize))
        }

        // exit
        textSelection.exit().remove();

        this.redrawYGridLinesCustom();
    }

    redrawCanvas() {
        const { canvas, custom, w, h, props: { useDataLabels } } = this;

        // clear canvas
        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        this.redrawYGridLinesCanvas();

        // draw bars
        const bars = custom.selectAll('.bar');
        const labels = custom.selectAll('.text');
        const labelNodes = labels.nodes();

        bars.each(function (d, i) {
            var node = d3.select(this);

            if (node.empty()) return;

            context.beginPath();
            context.fillStyle = node.attr('fill');
            context.rect(
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width'),
                +node.attr('height')
            );
            context.fill();
            context.closePath();

            if (useDataLabels) {
                const labelNode = d3.select(labelNodes[i]);
                
                if (labelNode.empty()) return;

                context.beginPath();
                context.font = `italic 10px sans-serif`;
                context.fillStyle = '#222';
                context.textAlign = 'center';
                context.fillText(
                    labelNode.attr('text'),
                    labelNode.attr('x'),
                    labelNode.attr('y')
                );
                context.closePath();
            }
        });
    }

    redrawHidden() {
        const { hidden, custom, w, h, colorToNode } = this;

        // clear hidden canvas
        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const bars = custom.selectAll('.bar');
        bars.each(function (d, i) {
            var node = d3.select(this);

            if (node.empty()) return;
            
            hiddenContext.beginPath();
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.fillStyle = color;
            hiddenContext.rect(
                +node.attr('x'),
                0,
                Math.max(1, +node.attr('width')),
                h
            );
            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    showFocus(d) {
        const {
            props: { xAccessor, yAccessor, getBandwidth, getBandheight },
            x,
            y,
            h,
            focus,
        } = this;

        focus
            .attr('width', getBandwidth(d, x))
            .attr(
                'height',
                getBandheight ? getBandheight(d, y, yAccessor) : h - y(yAccessor(d))
            )
            .attr('opacity', 0.2)
            .attr(
                'transform',
                `translate(${x(xAccessor(d)) + 1}, ${getColumnY(d, y, yAccessor)})`
            );
    }
}

export class ColumnChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <ColumnChartSimple {...this.props} width={width} height={height} />
                )}
            </AutoSizer>
        );
    }
}

ColumnChart.defaultProps = {
    xDomain: [],
    yDomain: [],
    xAccessor: (d) => d,
    yAccessor: (d) => d,
    useBrush: false,
    snapBrush: getTimeSeriesBrushXSelection,
    getBandwidth: getTimeIntervalBandwidth,
    getBandheight: getColumnHeight,
    getBandY: getColumnY,
    colorAccessor: () => 'steelblue',
    useTooltip: true,
    htmlTooltip: (d) => d,
    useDataLabels: true,
    getDataLabels: getColumnLabels,
};

/* example */
/* 
  <ColumnChart
    xScale={d3.scaleTime()}  
    yScale={d3.scaleLinear()}  
    data={data}
    xDomain={[store.startDate, store.endDate]}
    yDomain={generateContinuousDomain(data, (d) => d.val)}
    xAccessor={(d) => d.time}
    yAccessor={(d) => d.val}
    useBrush={true}
    htmlTooltip={ColumnChartTooltip}
  /> 
*/
