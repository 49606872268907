import { put, call, takeLatest } from 'redux-saga/effects';

import { MetadataConstants as K } from './Metadata.constants';
import { Metadatum, parseMetadatumArguments } from '../../../legacy/models';
import { api_updateMetadatum } from './Metadata.services';
import { errorFlash } from '../../../legacy/components/Flash';
import { AddMetadataResource } from './Metadata.action';

function* handleUpdateMetadatumRequest(action) {
    try {
        const {
            payload: { asset_id, data },
            callback,
        } = action;

        const result = yield call(api_updateMetadatum, asset_id, data);
        const metadatum = new Metadatum(...parseMetadatumArguments(result));

        yield put(AddMetadataResource({ [metadatum.metadata_id]: metadatum }));

        callback && callback(metadatum);
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateMetadatumSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_METADATUM_REQUEST,
        handleUpdateMetadatumRequest
    );
}
