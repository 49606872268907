import { createAsyncThunk } from "@reduxjs/toolkit"
import { snacksAdd } from "../reducers/snacks.reducer"

export const snacksErrorMessage = createAsyncThunk<Promise<void>, string>(
    "snacks/error",
    async (message, { dispatch }) => {
        dispatch(
            snacksAdd({
                key: new Date().getTime() + Math.random(),
                message,
                options: { variant: "error" },
            }),
        )
    },
)

export const snacksSuccessMessage = createAsyncThunk<Promise<void>, string>(
    "snacks/error",
    async (message, { dispatch }) => {
        dispatch(
            snacksAdd({
                key: new Date().getTime() + Math.random(),
                message,
                options: { variant: "success" },
            }),
        )
    },
)
