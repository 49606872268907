import moment from 'moment';
import { clone } from 'lodash';
import CONSTANTS from '../../../../legacy/Constants';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { TrendConstants as K } from './TrendUI.constants';

const initialState = {
    metric: { id: 0, value: 'oee' },
    data_type: 'metadata',
    asset_id: -1,
    chart_id: -1, // fusion_id or metadata_id
    start_date: moment()
        .subtract('5', 'days')
        .set({ hour: 9, minute: 0, seconds: 0, milliseconds: 0 }),
    resolution: CONSTANTS.RESOLUTIONS[6],
    span: { x: 8, unit: 'hours' },
    step: { x: 1, unit: 'days' },
    single: false,
    repeats: 5,
    rows: [],
    data: {},
};

export const TrendReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
    case K.ACTIONS.TREND_UPDATE_NAVIGATOR:
        return updateStoreRedux(state, {
            ...action.payload,
        });
    case K.ACTIONS.TREND_CREATE_ROWS:
        return updateStoreRedux(state, {
            rows: state.rows.concat(clone(action.payload)),
        });
    case K.ACTIONS.TREND_FETCH_DATA_SUCCESS:
        return updateStoreRedux(state, {
            data: {
                ...state.data,
                ...action.payload,
            },
        });
    case K.ACTIONS.TREND_CHANGE_DATA_DISPLAY:
        const metric = { ...action.payload };
        return updateStoreRedux(state, {
            metric,
            rows: state.rows.map((row) => Object.assign({}, row, { metric })),
        });
    case K.ACTIONS.TREND_CLEAR_ROWS:
        return updateStoreRedux(state, {
            rows: [],
            data: {},
        });
    case K.ACTIONS.TREND_ROW_UPDATE:
        return updateStoreRedux(state, {
            rows: state.rows.map((row) => {
                if (row.uuid === action.payload.uuid) {
                    return { ...action.payload };
                }
                return row;
            }),
        });
    case K.ACTIONS.TREND_ROW_DELETE:
        const { payload: uuid } = action;
        return updateStoreRedux(state, {
            rows: state.rows.filter((row) => row.uuid !== uuid),
            data: {
                ...state.data,
                [uuid]: undefined,
            },
        });
    default:
        return updateStoreRedux(state, {});
    }
};
