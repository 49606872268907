import { InfoCircleOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import React from "react"

const PopoverBulkTagging = () => {
    return <Popover content={<span>Cannot apply on blocks, or assets with existing labels.</span>}>
        <InfoCircleOutlined />
    </Popover>
}

export default PopoverBulkTagging
