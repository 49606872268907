import { Tabs } from "antd"
import "./style.scss"

import React from "react"
import Page from "../../components/Page/Page"
import UsersTab from "../../legacy/routes/UsersManagement/UsersTab"
import GroupsTab from "../../legacy/routes/UsersManagement/GroupsTab"
import InvitesTab from "../../legacy/routes/UsersManagement/InvitesTab"
import UserPreferencesTab from "./components/UserPreferencesTab"
import { useAppSelector } from "../../store/hooks"
import { userSelector } from "../../store/old/Authentication/Authentication.selector"

const { TabPane } = Tabs
const UsersPage = () => {
    const user = useAppSelector(userSelector)
    const { isClientUser, isWhitelistedDemoUser } = user

    return <Page className="users-page" id="usersPage">
        <Tabs
            className="w-100 h-100"
            type={"card"}
            defaultActiveKey="1"
            destroyInactiveTabPane
            size="small"
        >
            <TabPane tab="Users" key="1">
                <UsersTab/>
            </TabPane>
            <TabPane tab="Groups" key="2">
                <GroupsTab/>
            </TabPane>
            {
                isClientUser as boolean || isWhitelistedDemoUser as boolean
                    ? <TabPane tab="Invites" key="3">
                        <InvitesTab/>
                    </TabPane>
                    : null
            }
            {
                isClientUser as boolean
                    ? <TabPane tab="Preferences" key="4">
                        <UserPreferencesTab/>
                    </TabPane>
                    : null
            }
        </Tabs>
    </Page>
}

export default UsersPage
