import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from "@mui/material"
import React from "react"
import ActionButton from "../Button/ActionButton/ActionButton"

const ConfirmationDialog = (props: Props) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleConfirm = () => {
        props.onConfirm()
        props.setOpen(false)
    }

    return <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleClose}>
                No
            </Button>
            <ActionButton onPress={handleConfirm} autoFocus>
                Yes
            </ActionButton>
        </DialogActions>
    </Dialog>
}

interface Props {
    title: string
    children: React.ReactNode
    onConfirm: () => void
    setOpen: (value: boolean) => void
    open: boolean
}

export default ConfirmationDialog
