/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AssetHeader from './AssetHeader';
import AssetBody from './AssetBody';
import { Permission } from '../../../components/Permission';
import { SPAWrapper } from '../../../components/SPAWrapper';
import { NoData } from '../../../components/None';
import LabellerDrawer, { AssetForwardLabeller, AssetIssueLabeller, AssetProductionLabeller, TargetProductionLabeller } from '../../Labels';
import CONSTANTS from '../../../Constants';
import { dataApiQueryParams } from '../../../utils/controls';
import { getDataSubarrByTime } from '../../../utils/helpers';
import { LABEL_DRAWER } from '../../Labels/components';
import withAsset from '../../../Wrappers/HOCs/withAsset';
import { fetchAssetLabels } from '../../../../store/old/Labels/Labels.action';
import { fetchAssetTargets } from '../../../../store/old/Target/Target.action';
import { fetchAssetChartsData, fetchAssetOeeData } from '../../../../store/old/Data/Data.action';
import { eventEmitter } from '../../../auxStore';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import './index.scss';
import { fetchBlockIssues } from '../../../../store/old/Blocks/Blocks.action';
import { withIssues } from '../../../Wrappers/HOCs/withIssues';
import { withSkus } from '../../../Wrappers/HOCs/withSkus';
import withRouter from '../../../Wrappers/HOCs/withRouter';
import { Label, Target } from '../../../models';
import DataDeleteModal from './components/DataDeleteModal';
import withStreaming from '../../../../lib/hoc/withStreaming';
import AukDrawerRight from '../../../components/AukDrawerRight';
import AssetProductionSummaryCarousel from './AssetProductionSummaryCarousel'
import { getChartsSkuLabelSummary, getSkuLabelsMap } from './helpers'

export const AssetDomainContext = React.createContext({});

class AssetViewSimple extends React.Component {
    constructor(props) {
        super(props);

        const {
            entity: { entity_id },
            asset: { block_id },
            asset,
        } = props;

        this.state = {
            brushRange: undefined,
            brushedData: [],
            nowTagging: '',
            labelSelection: undefined,
            targetSelection: undefined,
            showLabelDrawer: false,
            chartSelection: undefined,
            showOutput: false,
            showDataDelete: false,
            showSkuMultiple: false,
            skuMultipleChartSelection: null,
            skuLabels: transformSkuLabelsResource(this.skuMultipleCharts, asset.productionLabels)
        };

        this.refreshHandler = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => {
            const query = this.apiQueryParams();
            this.getData(query);
        });

        this.onBrushEnd = this.onBrushEnd.bind(this);
        this.onBrushCancel = this.onBrushCancel.bind(this);
        this.onClickOutput = this.onClickOutput.bind(this);

        props.getBlockIssues(entity_id, block_id);
    }

    componentWillUnmount() {
        this.refreshHandler();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.search !== this.props.router.location.search) {
            return this.onBrushCancel()
        }

        if (prevProps.labels !== this.props.labels || prevProps.asset !== this.props.asset ) {
            return this.setState({ skuLabels: transformSkuLabelsResource(this.skuMultipleCharts, this.props.asset.productionLabels) });
        }
    }

    apiQueryParams() {
        const { controls } = this.props;

        return { ...dataApiQueryParams(controls) };
    }

    getData(params) {
        const { getChartData, getOeeData, getLabels, getTargets, asset, entity } = this.props;
        const { entity_id } = entity;
        const { asset_id } = asset;
        const { date_range } = params;

        getChartData(entity_id, asset_id, params);
        getOeeData(entity_id, asset_id, params);
        getLabels(asset_id, { date_range });
        getTargets(asset_id, { date_range });
    }

    getBrushRange = (d, options = {}) => {
        const from = options.fromAccessor ? options.fromAccessor(d) : d.from;
        const to = options.toAccessor ? options.toAccessor(d) : d.to;

        const [windowStartDate, windowEndDate] = this.window.map((e) => moment(e));
        const [brushStartDate, brushEndDate] = [moment(from), moment(to)];

        return [
            moment.max(windowStartDate, brushStartDate).toDate(),
            moment.min(windowEndDate, brushEndDate).toDate(),
        ];
    };

    doubleClickLabel = (
        nowTagging,
        chartSelection,
        data,
        xScaleAccessor
    ) => {
        return (d, x) => {
            if (!x) x = xScaleAccessor;

            const brushRange = this.getBrushRange(d, {
                toAccessor: (l) => l._to,
            });

            const brushedData = getDataSubarrByTime(
                data,
                brushRange[0],
                brushRange[1]
            );

            this.setState({
                nowTagging,
                brushRange,
                labelSelection: d,
                showLabelDrawer: true,
                brushedData,
                chartSelection,
            }, this.closeSkuMultiple);

            this.moveBrushes(brushRange.map(x));
        };
    };

    onBrushEnd = (
        nowTagging,
        chartSelection,
        brushRange, // bounds
        scaled,
        brushedData
    ) => {
        const [brushFrom, brushTo] = brushRange;
        if (moment(brushFrom).isSame(moment(brushTo))) return this.onBrushCancel();

        this.moveBrushes(scaled);
        this.setState({
            nowTagging,
            chartSelection,
            brushRange,
            showLabelDrawer: true,
            brushedData,
        }, this.closeSkuMultiple);
    };

    onBrushCancel = () => {
        this.moveBrushes(null);
        this.setState({
            showLabelDrawer: false,
            chartSelection: undefined,
            brushRange: undefined,
            labelSelection: undefined,
            brushedData: undefined,
            nowTagging: '',
        });
    };

    onLabelModifySucccess = () => {
        eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH, true);
        this.onBrushCancel();
    }

    handleOpenDataDelete = () => {
        this.setState({ showDataDelete: true })
    }

    handleCloseDataDelete = () => {
        this.setState({ showDataDelete: false })
    }

    onClickOutput = () => {
        this.setState({ showOutput: !this.state.showOutput });
    };

    moveBrushes = (range) => {
        this.brushes.transition().call(d3.brushX().move, range);
    };

    openSkuMultiple = (chartIndex) => {
        this.setState({
            showSkuMultiple: true,
            skuMultipleChartSelection: chartIndex
        }, this.onBrushCancel);
    }

    closeSkuMultiple = () => {
        this.setState({
            showSkuMultiple: false,
            skuMultipleChartSelection: null
        })
    }

    get brushes() {
        return d3.select('.asset-default__body__charts').selectAll('.brush');
    }

    get window() {
        const { lower, upper } = this.props.window;
        return [new Date(lower), new Date(upper)];
    }

    get labelDrawerContent() {
        const { window, onLabelModifySucccess, handleOpenDataDelete } = this;
        const { asset } = this.props;
        const { nowTagging, labelSelection, brushRange, brushedData, chartSelection } = this.state;

        if (!nowTagging) return null;

        if (nowTagging === LABEL_DRAWER.TYPE.FORWARD_LABELLER)
            return (
                <AssetForwardLabeller asset={asset} window={window}/>
            );

        if (nowTagging === LABEL_DRAWER.TYPE.ISSUE_LABELLER)
            return (
                <AssetIssueLabeller
                    selection={
                        labelSelection && labelSelection instanceof Label && labelSelection.issue
                            ? labelSelection
                            : undefined
                    }
                    range={brushRange || [new Date(), new Date()]}
                    brushedData={brushedData}
                    showDataDelete={true}
                    asset={asset} 
                    openDataDelete={handleOpenDataDelete}
                    onSuccess={onLabelModifySucccess}
                />
            );

        if (nowTagging === LABEL_DRAWER.TYPE.PRODUCTION_LABELLER)
            return (
                <AssetProductionLabeller
                    selection={
                        labelSelection && labelSelection instanceof Label && !labelSelection.issue
                            ? labelSelection
                            : undefined
                    }
                    range={brushRange || [new Date(), new Date()]}
                    brushedData={brushedData}
                    summaryMode={chartSelection?.mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT ? 'total' : 'average'}
                    title={chartSelection?.chart_title}
                    asset={asset}
                    showDataDelete={true}
                    openDataDelete={handleOpenDataDelete}
                    onSuccess={onLabelModifySucccess}
                />
            );

        if (nowTagging === LABEL_DRAWER.TYPE.TARGET_PRODUCTION_LABELLER)
            return (
                <TargetProductionLabeller
                    selection={
                        labelSelection && labelSelection instanceof Target
                            ? labelSelection
                            : undefined
                    }
                    asset={asset}
                />
            );
    }

    get skuMultipleCharts () {
        return this.props.asset._charts.filter((chart) => chart.dataSource.mode === '1a')
    }

    transformSkuLabelsResource (productionLabels) {
        const groupedBySku = getSkuLabelsMap(productionLabels);
        const aggregated = getChartsSkuLabelSummary(this.skuMultipleCharts, groupedBySku)
        return {
            raw: groupedBySku,
            aggregated
        }
    }

    render() {
        const { controls, labels, data, asset } = this.props;
        const { brushRange, showLabelDrawer, showOutput, showDataDelete, showSkuMultiple, skuMultipleChartSelection, skuLabels } = this.state;
        const { window, doubleClickLabel, onBrushEnd, onBrushCancel, onClickOutput, handleCloseDataDelete, openSkuMultiple, closeSkuMultiple } = this;

        const context = {
            controls,
            asset,
            labels,
            data,
            window,
            doubleClickLabel,
            onBrushEnd,
            onBrushCancel,
            onClickOutput,
            brushRange,
            showOutput,
            openSkuMultiple,
            closeSkuMultiple,
            skuLabels
        };

        const assetHasCharts = !!asset.charts.length;
        

        return (
            <AssetDomainContext.Provider value={context}>
                <Permission resource="assets" forBlock blockId={asset.block_id}>
                    <SPAWrapper className="asset-view">
                        {assetHasCharts && (
                            <LabellerDrawer
                                key={asset.asset_id}
                                handlerOnClick={() =>
                                    this.setState({
                                        showLabelDrawer: true,
                                        nowTagging: LABEL_DRAWER.TYPE.FORWARD_LABELLER,
                                    })
                                }
                                onClose={onBrushCancel}
                                visible={showLabelDrawer}
                                withHandler
                            >
                                {this.labelDrawerContent}
                            </LabellerDrawer>
                        )}
                        <AukDrawerRight
                            autoFocus
                            visible={showSkuMultiple}
                            maskStyle={{ display: 'none', pointerEvents: 'none' }}
                            style={{ position: 'absolute', pointerEvents: 'none' }}
                            title="Product SKU Multiple"
                            onClose={this.closeSkuMultiple}
                        >
                            <AssetProductionSummaryCarousel 
                                charts={this.skuMultipleCharts} 
                                data={this.state.skuLabels}
                                selectedIndex={skuMultipleChartSelection}
                            />
                        </AukDrawerRight>
                        <div className="asset-default">
                            <AssetHeader />
                            {assetHasCharts ? (
                                <AssetBody skuMultipleCharts={this.skuMultipleCharts}/>
                            ) : (
                                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <NoData />
                                </div>
                            )}
                        </div>
                        {
                            brushRange ? 
                                <DataDeleteModal
                                    visible={showDataDelete}
                                    asset={asset}
                                    range={brushRange}
                                    close={handleCloseDataDelete}
                                /> : null
                        }
                    </SPAWrapper>
                </Permission>
            </AssetDomainContext.Provider>
        );
    }
}

const mapStateToProps = (appState) => ({
    labels: appState.label.labels,
    data: appState.data,
    entity: currentEntitySelector(appState),
});

const mapDispatchToProps = (dispatch) => ({
    getChartData: (entityId, assetId, query, callback) => dispatch(fetchAssetChartsData(entityId, assetId, query, callback)),
    getOeeData: (entityId, assetId, query, callback) => dispatch(fetchAssetOeeData(entityId, assetId, query, callback)),
    getLabels: (assetId, queryParams, callback) => dispatch(fetchAssetLabels(assetId, queryParams, callback)),
    getTargets: (assetId, query, callback) => dispatch(fetchAssetTargets(assetId, query, callback)),
    getBlockIssues: (entityId, blockId, callback) => dispatch(fetchBlockIssues(entityId, blockId, callback)),
});

const AssetView = connect(mapStateToProps, mapDispatchToProps)(AssetViewSimple);

export default withRouter(withIssues(withSkus(withAsset(withStreaming(AssetView)))));

const transformSkuLabelsResource = (charts, productionLabels) => {
    const groupedBySku = getSkuLabelsMap(productionLabels);
    const aggregated = getChartsSkuLabelSummary(charts, groupedBySku);

    return {
        raw: groupedBySku,
        aggregated
    }
}