/* eslint-disable react/prop-types */


// LIBRARIES
import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import FusionForm from '../../Fusions/FusionForm';

// REDUX
import {
    createFusion,
    updateFusion,
} from '../../../../store/old/Fusions/Fusions.action';

import './AssetFusions.scss';
import { userSelector } from '../../../../store/old/Authentication/Authentication.selector';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';

const { TabPane } = Tabs;

let createIndex = -1;

const AssetFusions = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(userSelector);
    const { entity_id } = useSelector(currentEntitySelector)
    const { asset, fusions } = props;

    const [createSelection, setCreateSelection] = useState([]);
    const [activeKey, setActiveKey] = useState(
        fusions.length ? `${fusions[0].fusion_id}` : null
    );

    const add = () => {
        setCreateSelection(
            createSelection.concat({ title: 'New fusion', key: createIndex })
        );
        setActiveKey(`${createIndex}`);
        createIndex--;
    };

    const remove = (targetKey) => {
        setCreateSelection(createSelection.filter((s) => s.key !== +targetKey));
    };

    const onEdit = (targetKey, action) => {
        if (action === 'add') add();
        if (action === 'remove') remove(targetKey);
    };

    const hasFusionsEditAccess = authUser.check_resource_policy(
        'fusions',
        false,
        true,
        false
    );

    return (
        <Tabs
            tabPosition="left"
            type={hasFusionsEditAccess ? 'editable-card' : 'card'}
            tabBarStyle={{ width: 200 }}
            destroyInactiveTabPane
            onEdit={onEdit}
            onChange={setActiveKey}
            activeKey={activeKey}
            className="asset-fusions"
        >
            {fusions.map((f) => (
                <TabPane
                    tab={<span title={f.chart_title}>{f.chart_title}</span>}
                    key={f.fusion_id}
                    closable={false}
                    style={{ position: 'relative' }}
                >
                    <FusionForm
                        asset={asset}
                        fusion={f}
                        submit={(data) => {
                            dispatch(updateFusion(asset.asset_id, data));
                        }}
                        onDelete={() =>
                            setActiveKey(fusions.length ? `${fusions[0].fusion_id}` : null)
                        }
                    />
                </TabPane>
            ))}
            {createSelection.map((s) => (
                <TabPane tab={s.title} key={s.key} closable>
                    <FusionForm
                        asset={asset}
                        submit={(data) =>
                            dispatch(
                                createFusion(entity_id, asset.asset_id, data, (created) => {
                                    setActiveKey(`${created.fusion_id}`);
                                    remove(s.key);
                                })
                            )
                        }
                    />
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AssetFusions;
