import { updateStoreRedux } from '../../../legacy/utils/redux';

// CONSTANT
import { SkuChartsConstants as K } from './SkuCharts.constant';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    skuCharts: {},
});

export const skuChartsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_SKU_CHARTS_STATE) {
        return updateStoreRedux(state, {
            ...action.payload
        });
    }

    if (action.type === K.ACTIONS.SET_SKU_CHARTS_RESOURCE) {
        return updateStoreRedux(state, {
            skuCharts: { ...action.payload.skuCharts },
        });
    }

    if (action.type === K.ACTIONS.ADD_SKU_CHARTS_RESOURCE) {
        return updateStoreRedux(state, {
            skuCharts: { ...state.skuCharts, ...action.payload.skuCharts },
        });
    }

    if (action.type === K.ACTIONS.RESET_SKU_CHARTS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    // if (action.type === K.ACTIONS.REMOVE_SKUS_RESOURCE) {
    //     const deleteSet = new Set(action.payload.sku_ids);
    //     const deletedState = pickBy(
    //         { ...state.skus },
    //         ({ sku_id }) => !deleteSet.has(sku_id)
    //     );

    //     return updateStoreRedux(state, {
    //         skus: deletedState,
    //     });
    // }

    return updateStoreRedux(state, {});
};
