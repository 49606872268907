/* eslint-disable react/prop-types */
import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import './InputMultiOption.scss';

const InputMultiOption = (props, ref) => {
    const { attributes, value, handleChange, defaultActive, style, valid } =
    props;

    const initialState = value;

    const [data, setData] = useState(initialState);
    const [active, setActive] = useState(defaultActive);

    const handleOnChange = (e, attr) => {
        const { id } = attr;
        const changed = { [id]: e.target.value };
        const result = { ...data, ...changed };
        handleChange ? handleChange(changed) : setData(result);
    };

    const handleFocus = (e, attr) => {
        handleOnChange(e, attr);
        setActive(attr.id);
    };

    useEffect(() => {
        setData(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
        getData: () => ({ ...data, active }),
        getValue: () => ({ [active]: data[active] }),
    }));

    const isActive = (attr) => attr.id === active;

    const activeClassed = (attr) => (isActive(attr) ? 'active' : 'inactive');

    const isValid = (attr, val) => {
        if (!isActive(attr)) return '';
        if (valid && !valid(val)) return 'is-invalid';
        return '';
    };

    return (
        <div className="auk__multi-option-input" style={style}>
            {attributes.map((attr) => {
                const val = data[attr.id] || '';

                return (
                    <div
                        className={`auk__multi-option-input__row row mx-0 ${activeClassed(
                            attr
                        )}`}
                        key={attr.id}
                    >
                        <label
                            htmlFor={attr.name}
                            className="auk__multi-option-input__prepend_label"
                        >
                            {attr.label}
                        </label>
                        <input
                            className={`auk__multi-option-input__input ${isValid(attr, val)}`}
                            type={props.type}
                            name={attr.name}
                            onChange={(e) => handleOnChange(e, attr)}
                            onFocus={(e) => handleFocus(e, attr)}
                            value={!isActive(attr) ? '' : val}
                        />
                    </div>
                );
            })}
        </div>
    );
};

// InputMultiOption.defaultProps = {
//   style: {},
//   type: 'text',
//   handleChange: (data) => data,
//   attributes: [],
//   defaults: { attribute: -1, value: '' },
// };

export default forwardRef(InputMultiOption);
