/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

import ImageInput from './ImageInput';

const ImageUpload = (props) => {
    const [img, setImg] = useState(props.value || '');

    const onChange = (v) => {
        setImg(v);
        props.onChange(v);
    };

    return (
        <div className="image-upload w-100 h-100" style={{ position: 'relative' }}>
            <ImageInput
                onChange={onChange}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: 2,
                    cursor: 'pointer',
                }}
            />
            <div
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{
                    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.75)',
                }}
            >
                {img ? (
                    <img className="w-100 h-100" src={img} />
                ) : (
                    <PlusCircleOutlined style={{ fontSize: 18 }} />
                )}
            </div>
        </div>
    );
};

export default ImageUpload;
