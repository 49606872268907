import { updateStoreRedux } from '../../../../legacy/utils/redux';
import CONSTANTS from '../../../../legacy/Constants';
import { OEEConstants as K } from './OEE.constants';
import { getMapFromArr } from '../../../../legacy/utils/helpers';
import { getMaskedOEE } from '../../../../legacy/utils/oee';

const initialState = () => ({
    masks: {},
    categories: CONSTANTS.CAT,
});

export const OEEReducer = (
    state = initialState(),
    action
) => {
    if (action.type === K.ACTIONS.SET_OEE_STATE) {
        const { masks } = action.payload;
        const categories = getMapFromArr(getMaskedOEE(masks), 'key');

        return updateStoreRedux(state, { masks, categories });
    }

    if (action.type === K.ACTIONS.RESET_OEE_STATE) {
        return updateStoreRedux(state, initialState());
    }

    return updateStoreRedux(state, {});
};
