import { Box, Popover, Typography } from "@mui/material"
import "./Counter.scss"
import React from "react"
import CircleIcon from "@mui/icons-material/Circle"
import InfoIcon from "@mui/icons-material/Info"
import classNames from "classnames"

const Counter = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <div className="counter">
            <Box className="counter-header">
                <div className="counter-header__label">
                    {props.label}
                </div>
                <Box className="counter-icons">

                    { props.withLoader
                        ? <div className="counter-icon">
                            <CircleIcon sx={{ fontSize: "14px" }} className={classNames({ blink: props.loading })}/>
                        </div>
                        : null }
                    { props.withTooltip
                        ? <>
                            <div
                                className="counter-icon"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <InfoIcon sx={{ fontSize: "14px", cursor: "pointer" }} />
                            </div>
                            <Popover
                                id="mouse-over-popover"
                                sx={{ pointerEvents: "none" }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Box sx={{ backgroundColor: "#2C3134", padding: "8px 4px", color: "#fff" }}>
                                    {props.tooltipContent}
                                </Box>
                            </Popover>
                        </>
                        : null }

                </Box>
            </Box>
            {
                <div className="counter-value" style={{ opacity: props.value === undefined ? 0.8 : 1 }}>
                    { props.value ?? <Typography sx={{ fontSize: "20px" }}>{props.placeholder}</Typography> }
                </div>
            }
        </div>
    )
}

interface Props {
    label: React.ReactNode
    value?: string | number
    placeholder?: string
    withLoader?: boolean
    loading?: boolean
    withTooltip?: boolean
    tooltipContent?: React.ReactNode
}

export default Counter
