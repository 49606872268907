import { call, put, takeLatest } from 'redux-saga/effects';

// SERVICES
import { api_getSkus, api_createSku, api_bulkCreateSku, api_deleteSku, api_updateSku } from './Sku.services';

// ACTION
import { addSkusResource, removeSkusResource, setSkusState, fetchSkus } from './Sku.action';

import { getMapFromArr } from '../../../legacy/utils/helpers';

// REDUX STORE
import { store } from '../..';

// MODEL
import { parseSkuArguments, Sku } from '../../../legacy/models';

// CONSTANT
import { SkuConstants as K } from './Sku.constant';
import { errorFlash } from '../../../legacy/components/Flash';
import { currentEntitySelector } from '../Entity/Entity.selector';

function* handleFetchSkus(action) {
    try {
        const { entity_id } = action.payload;
        const response = yield call(api_getSkus, entity_id);

        const skus = getMapFromArr(
            response.map((s) => new Sku(...parseSkuArguments(s))),
            'sku_id'
        );

        yield put(setSkusState(skus));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchSkusSaga() {
    yield takeLatest(
        K.ACTIONS.GET_SKUS_REQUEST,
        handleFetchSkus
    );
}

function* handleCreateSku(action) {
    try {
        const response = yield api_createSku(action.payload.sku);
        const sku = new Sku(...parseSkuArguments(response));

        yield put(addSkusResource({ [sku.sku_id]: sku }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createSkuSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_SKU_REQUEST,
        handleCreateSku
    );
}

function* handleEditSku(action) {
    try {
        const response = yield api_updateSku(action.payload.sku);
        const sku = new Sku(...parseSkuArguments(response));

        yield put(addSkusResource({ [sku.sku_id]: sku }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* editSkuSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_SKU_REQUEST,
        handleEditSku
    );
}

function* handleDeleteSku(action) {
    try {
        const { sku_id } = action.payload.sku;
        const response = yield api_deleteSku(sku_id);
        yield put(removeSkusResource([sku_id]));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteSkuSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_SKU_REQUEST,
        handleDeleteSku
    );
}

function* handleCreateBulkSku(action) {
    try {
        const appState = store.getState();
        const { entity_id } = currentEntitySelector(appState);
        const response = yield api_bulkCreateSku(action.payload.skus);

        yield put(fetchSkus(entity_id));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createBulkSkuSaga() {
    yield takeLatest(
        K.ACTIONS.BULK_CREATE_SKU_REQUEST,
        handleCreateBulkSku
    );
}
