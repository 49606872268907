/* eslint-disable react/prop-types */


import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

// REDUX
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import {
    blocksSelector,
    blocksState,
    entityBlock,
} from '../../../store/old/Blocks/Blocks.selector';
import {
    issuesState,
    rootIssues,
} from '../../../store/old/Issues/Issues.selector';
import { fetchBlocksIssues } from '../../../store/old/Blocks/Blocks.action';

// COMPONENTS
import AukTooltip from '../../components/AukTooltip';
import IssueEditor from './IssueEditor';
import IssuePermissionMatrix from './IssuePermissionMatrix';
import { SPAWrapper } from '../../components/SPAWrapper';

import { withEntity } from '../../Wrappers/HOCs/withEntity';

import './IssueManagement.scss';
import { withIssues } from '../../Wrappers/HOCs/withIssues';


class IssueManagementSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showIssueEditor: false };

        const { entity_id } = props.entity;
        props.fetchBlocksIssues(entity_id);

        this.toggleIssueEditor = this.toggleIssueEditor.bind(this);
    }

    toggleIssueEditor = () => {
        this.setState({ showIssueEditor: !this.state.showIssueEditor });
    };

    get userCanEditIssues() {
        const { authUser } = this.props;

        return authUser.check_resource_policy('issues', false, true, true);
    }

    get issueEditorButton() {
        return (
            <AukTooltip.Help title="Create or edit issues">
                <Button
                    className="auk-button auk-button--round"
                    onClick={this.toggleIssueEditor}
                    disabled={!this.userCanEditIssues}
                >
                    <i className="fas fa-edit" />
                </Button>
            </AukTooltip.Help>
        );
    }

    render() {
        const { userCanEditIssues, toggleIssueEditor } = this;
        const { showIssueEditor } = this.state;
        const { issues, rootIssues } = this.props;

        return (
            <SPAWrapper className="issue-management">
                <div className="issue-management__header">
                    <AukTooltip.Help title="Create or edit issues">
                        <Button
                            className="auk-button auk-button--round"
                            onClick={toggleIssueEditor}
                            disabled={!userCanEditIssues}
                        >
                            <i className="fas fa-edit" />
                        </Button>
                    </AukTooltip.Help>
                </div>
                <IssuePermissionMatrix
                    {...(this.props)}
                    userCanEdit={userCanEditIssues}
                />
                {showIssueEditor && (
                    <IssueEditor
                        issues={issues}
                        rootIssues={rootIssues}
                        toggle={this.toggleIssueEditor}
                    />
                )}
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        rootBlock: entityBlock(appState),
        rootIssues: rootIssues(issuesState(appState)),
        blocks: blocksSelector(appState),
        authUser: userSelector(appState),
        blocks_issues_exclusions: blocksState(appState).blocks_issues_exclusions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBlocksIssues: (entityId, callback) =>
            dispatch(fetchBlocksIssues(entityId, callback)),
    };
};

const IssueManagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(IssueManagementSimple);

export default withEntity(withIssues(IssueManagement));
