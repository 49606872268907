import { FusionConstants as K } from './Fusions.constants';

export const resetFusionsState = () => {
    return {
        type: K.ACTIONS.RESET_FUSIONS_STATE,
    };
};

export const setFusionsResource = (payload) => {
    return {
        type: K.ACTIONS.SET_FUSIONS_RESOURCE,
        payload,
    };
};


export const addFusionsResource = (payload) => {
    return {
        type: K.ACTIONS.ADD_FUSIONS_RESOURCE,
        payload,
    };
};

export const removeFusionsResource = (fusion_ids) => {
    return {
        type: K.ACTIONS.REMOVE_FUSIONS_RESOURCE,
        payload: { fusion_ids },
    };
};

export const createFusion = (entity_id, asset_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_FUSION_REQUEST,
        payload: { entity_id, asset_id, data },
        callback,
    };
};

export const updateFusion = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_FUSION_REQUEST,
        payload: { asset_id, data },
        callback,
    };
};

export const deleteFusion = (entity_id, asset_id, fusion_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_FUSION_REQUEST,
        payload: { entity_id, asset_id, fusion_id },
        callback,
    };
};
