import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { RegressionConstants as K } from './RegressionUI.constants';

const initialState = () => ({
    selection: -1, // correlation_id, -1 if no selection
    startDate: null,
    endDate: null,
    sourceX: null,
    sourceY: null,
    resolution: null,
    regression: null,
    upperBound: { dx: 0, dy: 0 },
    lowerBound: { dx: 0, dy: 0 },
    data: [],
});

export const RegressionUIReducer = (
    state = initialState(),
    action
) => {
    if (action.type === K.ACTIONS.REGRESSION_FETCH_DATA_REQUEST_SUCCESS) {
        const { data } = action.payload;
        return updateStoreRedux(state, { data });
    }

    if (action.type === K.ACTIONS.REGRESSION_SET_REGRESSION) {
        const { regression } = action.payload;
        return updateStoreRedux(state, { regression });
    }

    if (action.type === K.ACTIONS.REGRESSION_CHANGE_BOUND) {
        const { bound, value } = action.payload;
        return updateStoreRedux(state, {
            [bound]: { ...state[bound], ...value },
        });
    }

    if (action.type === K.ACTIONS.REGRESSION_CHANGE_FORM) {
        const { data } = action.payload;
        return updateStoreRedux(state, {
            ...data,
        });
    }

    if (action.type === K.ACTIONS.REGRESSION_RESET_STORE) {
        return updateStoreRedux(state, initialState());
    }

    return updateStoreRedux(state, {});
};
