export const it = {
    notes: 'Appunti',
    load: 'Fare portare',
    export: 'Esportare',
    unutilised: 'Un-utilizzati',
    breakdowns: 'guasti',
    threshold: 'Soglia',
    oeepreview: 'OEE anteprima',
    heatmappreview: 'Heatmap Anteprima',
    oeecolors: 'colori OEE',
    color: 'colore',
    reset: 'Ripristina',
    theme: 'Tema',
    defaultview: 'Visualizzazione predefinita',
    defaulttimeselection: 'Selezione del tempo di default',
    zoomlevel: 'Livello dello zoom',
    entity: 'Entità',
    preferences: 'Preferenze',
    custom: 'costume',
    enteryourmailtoreset: 'Inserisci la tua mail a resettare',
    goback: 'Torna indietro',
    resetpassword: 'Resetta la password',
    logout: 'Disconnettersi',
    ownersemail: 'Proprietari e-mail',
    optional: 'Opzionale',
    industry: 'Industria',
    timezone: 'Fuso orario',
    nocoderequesttojoin: 'Nessun codice? Richiesta di iscrizione',
    enterreferralcodeinstead: 'Inserisci il codice di riferimento, invece',
    setup: 'Impostare',
    company: 'Azienda',
    referralcode: 'codice di riferimento',
    submit: 'Invia',
    summary: 'Sommario',
    language: 'linguaggio',
    login: 'Accesso',
    signin: 'registrati',
    signup: 'Iscriviti',
    username: 'Nome utente',
    password: "Parola d'ordine",
    forgotpassword: 'Ha dimenticato la password?',
    rememberme: 'Ricordati di me',
    register: 'Registrati',
    registration: 'Registrazione',
    firstname: 'Nome di battesimo',
    lastname: 'Cognome',
    email: 'E-mail',
    mobile: 'Mobile',
    country: 'Nazione',
    contactnumber: 'Numero di contatto',
    appointment: 'Appuntamento',
    role: 'Ruolo',
    admin: 'Admin',
    owner: 'proprietario',
    editor: 'editore',
    viewer: 'spettatore',
    confirmpassword: 'conferma password',
    iacceptthe: 'accetto il',
    useragreement: 'Accordo per gli utenti',
    alreadyregisteredlogin: 'Già registrato? Accesso',
    softwareasaservicetermsofuse: 'Software-as-a-Service Condizioni di Utilizzo',
    dashboard: 'Pannello di controllo',
    streaming: 'Streaming',
    resolution: 'Risoluzione',
    seconds: 'secondi',
    second: 'secondo',
    minutes: 'minuti',
    minute: 'minuto',
    hours: 'ore',
    hour: 'Ora',
    weeks: 'settimane',
    week: 'Settimana',
    months: 'mesi',
    month: 'mese',
    quarters: 'alloggio',
    quarter: 'trimestre',
    years: 'anni',
    year: 'anno',
    from: 'A partire dal',
    to: 'Per',
    start: 'inizio',
    end: 'Fine',
    display: 'Schermo',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Time Series',
    gateway: 'porta',
    statusasof: 'Stato come di',
    active: 'attivo',
    offline: 'disconnesso',
    mergeasonemachine: 'Unire come una macchina',
    drawarrowstoformprocessmap: 'Disegnare frecce a forma mappa dei processi',
    openblockeditor: 'editor di blocco aperto',
    createnewtile: 'Crea nuovo Tile',
    summarytile: 'tile Sommario',
    selectablockthennameyoursummarytile:
    'Selezionare un blocco, quindi il nome la tua tessera sintesi',
    name: 'Nome',
    blockselector: 'selettore di blocco',
    asset: 'Risorsa',
    therearenolabelstaggedinthisperiod:
    'Non ci sono etichette taggate in questo periodo',
    standardtimes: 'Standard Time (s)',
    total: 'Totale',
    last: 'Scorso',
    oeeinput: 'ingresso OEE',
    chartcolor: 'grafico di colore',
    issueeditor: 'Editor problema',
    issuemangement: 'Mangement problema',
    cumulativeoutput: 'produzione cumulata',
    tag: 'Etichetta',
    label: 'Etichetta',
    valueval: 'Valore (Val)',
    time: 'Tempo',
    januaryjan: 'Gennaio (Jan)',
    februaryfeb: 'Febbraio (febbraio)',
    marchmar: 'March (Mar)',
    aprilapr: 'Di aprile (aprile)',
    may: 'Maggio',
    junejun: 'Giugno (giugno)',
    julyjul: 'Luglio (luglio)',
    augustaug: 'Agosto (agosto)',
    septembersep: 'Settembre (settembre)',
    octoberoct: 'Ottobre (ottobre)',
    novembernov: 'Novembre (novembre)',
    decemberdec: 'Dicembre (Dec)',
    day: 'Giorno',
    days: 'giorni',
    mondaymonm: 'Lunedi (Mon) M',
    tuesdaytuet: 'Martedì (mar) T',
    wednesdaywedw: 'Mercoledì (mercoledì) W',
    thursdaythut: 'Giovedi (gio) T',
    fridayfrif: 'Venerdì (Ven) F',
    saturdaysats: 'Sabato (Sat) S',
    sundaysuns: 'Domenica (Sun) S',
    edit: 'modificare',
    add: 'Inserisci',
    new: 'Nuovo',
    create: 'Creare',
    search: 'Ricerca',
    close: 'Vicino',
    cancel: 'Annulla',
    save: 'Salva',
    saved: 'Salvato',
    clear: 'Chiaro',
    enter: 'accedere',
    navigator: 'Navigatore',
    mandatoryfields: 'Campi obbligatori',
    select: 'Selezionare',
    merge: 'Merge',
    delete: 'Elimina',
    done: 'Fatto',
    filter: 'Filtro',
    table: 'tavolo',
    columns: 'colonne',
    rows: 'righe',
    cell: 'cellula',
    page: 'Pagina',
    previous: 'Precedente',
    next: 'Il prossimo',
    serialnumber: 'Numero di serie',
    manuallyinputserialnumber: 'Numero di serie inserire manualmente',
    updateasset: 'Aggiornamento Asset',
    machine: 'Macchina',
    brand: 'Marca',
    model: 'Modello',
    nodes: 'nodi',
    fusions: 'Fusions',
    chartorder: 'grafico Order',
    primary: 'Primario',
    adddevicechannel: 'Aggiungi dispositivo - Canale',
    pushratesec: 'Spingere Tasso (sec)',
    voltagev: 'Tensione (V)',
    sensor: 'Sensore',
    mode: 'Modalità',
    digitalcount: 'Conte digitale',
    digitalstate: 'State Digital',
    analogvoltage: 'tensione analogica',
    charttitle: 'grafico Titolo',
    unitmeasure: 'Unità di misura',
    attribute: 'Attributo',
    autocycletime: 'tempo Ciclo automatico',
    unittransform: 'unità Transform',
    leaveblankifnotapplicable: 'Lasciare in bianco se non pertinente',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'campi obbligatori (lasciare in bianco se il canale non in uso)',
    fusedchart: 'Fused Grafico',
    fusionname: 'Nome Fusion',
    charttype: 'Tipo di grafico',
    column: 'Colonna',
    line: 'Linea',
    state: 'Stato',
    expression: 'Espressione',
    combination: 'Combinazione',
    currentcharts: 'Grafici attuali',
    expressionconstructor: 'espressione Constructor',
    validoperators: 'Gli operatori validi',
    leaveblanktokeepexistingexpression:
    "Lascia in bianco per mantenere l'espressione esistente",
    oee: 'OEE',
    overallequipmenteffectiveness: 'Overall Equipment Effectiveness',
    legend: 'Leggenda',
    loading: 'Caricamento in corso',
    availability: 'Disponibilità',
    performance: 'Prestazione',
    quality: 'Qualità',
    unscheduled: 'non in programma',
    planneddowntime: "dell'inattività pianificata",
    changeoversetup: 'Commutazione / Setup',
    breakdown: 'Abbattersi',
    unutilized: 'Un-utilizzati',
    speedloss: 'perdita di velocità',
    minorstops: 'Interrompe minori',
    rework: 'rilavorazione',
    rejectscrap: 'Rifiuta / Scrap',
    nodata: 'Nessun dato',
    blockeditor: 'Editor blocchi',
    sort: 'Ordinare',
    logicblocks: 'blocchi di codice',
    sequence: 'Sequenza',
    and: 'E',
    or: 'O',
    utilizationheatmap: 'Utilizzo Heatmap',
    startdate: "Data d'inizio",
    enddate: 'Data di fine',
    daily: 'Quotidiano',
    weekly: 'settimanalmente',
    monthly: 'Mensile',
    quarterly: 'Trimestrale',
    yearly: 'Annuale',
    trendanalysis: 'Analisi delle tendenze',
    metricselection: 'Metric Selection',
    metric: 'metrico',
    data: 'Dati',
    target: 'Bersaglio',
    periodselection: 'selezione',
    span: 'campata',
    every: 'Ogni',
    repeat: 'Ripetere',
    repeats: 'ripetizioni',
    addsingle: 'Aggiungere singolo',
    paretoanalysis: 'Analisi di Pareto',
    calendartime: 'tempo del calendario',
    scheduledtime: 'Orario pianificato',
    operatingtime: 'Tempo di operatività',
    netruntime: 'tempo di esecuzione netto',
    effective: 'Efficace',
    loadingloss: 'Caricamento in corso Loss',
    availabilityloss: 'Perdita Disponibilità',
    performanceloss: 'Perdita delle prestazioni',
    qualityloss: 'Perdita di qualità',
    notagsinthiscategory: 'Nessun tag in questa categoria',
    overall: 'Complessivamente',
    tagged: 'Tagged',
    occurrence: 'avvenimento',
    skumanagement: 'SKU Gestione',
    category: 'Categoria',
    skuname: 'Nome Codice',
    skucode: 'Codice SKU',
    unitofmeasurementuom: 'Unità di misura (UOM)',
    description: 'Descrizione',
    csvupload: 'CSV Carica',
    template: 'Modello',
    notifications: 'notifiche',
    newnotification: 'nuova notifica',
    monitoring: 'Monitoraggio',
    topic: 'Argomento',
    frequency: 'Frequenza',
    recipients: 'destinatari',
    notifyby: 'Notifica tramite',
    sms: 'sms',
    alert: 'Mettere in guardia',
    report: 'rapporto',
    greaterthan: 'Più grande di',
    lessthan: 'Meno di',
    greaterthanorequal: 'Maggiore o uguale',
    lessthanorequal: 'Inferiore o uguale',
    equal: 'Pari',
    range: 'Gamma',
    continuousduration: 'Durata continua',
    usermanagement: 'Gestione utenti',
    user: 'Utente',
    users: 'utenti',
    invitenew: 'Invita Nuovo',
    status: 'stato',
    resend: 'Rispedisci',
    revoke: 'Revocare',
    invite: 'Invitare',
    invitees: 'invitati',
    addinviteesbypressingenterorclickingonthesign:
    "Aggiungere invitati premendo Invio o facendo clic sul segno '+'",
    schedules: 'Orari',
    shift: 'Cambio',
    profile: 'Profilo',
    changepassword: 'Cambia la password',
    deleteaccount: "Eliminare l'account",
    userguide: 'Guida utente',
    overviewandbasiccontrol: 'Descrizione e controllo di base',
    rangeandresolution: 'Gamma e Risoluzione',
    editingoptions: 'opzioni di modifica',
    mergetiles: 'piastrelle Unisci',
    detailedassetview: 'vista dettagliata di asset',
    assetoverview: 'panoramica Asset',
    summarystatistics: 'Statistiche riassuntive',
    dataanalysis: 'Analisi dei dati',
    oeeanoverview: 'OEE una panoramica',
    oeetoggle: 'OEE ginocchiera',
    addingamachine: 'Aggiunta di una macchina',
    nodeconfiguration: 'configurazione del nodo',
    deleteupdateassets: 'beni aggiornamento Elimina',
    oeesummarytile: 'tile sintesi OEE',
    oeetags: 'OEE Tag',
    issuemanagement: 'issue management',
    labelsandfiltering: 'Etichette e filtraggio',
    useraccess: 'user Access',
    group: 'Gruppo',
    groups: 'gruppi',
    joincompany: 'Aderire',
    notification: 'Notifica',
    createnotification: 'Creazione di notifica',
    node: 'Nodo',
    downtime: 'I tempi di inattività',
    downtimes: 'I tempi morti',
    repeatstartdate: 'Ripetere Data di inizio',
    repeatenddate: 'Ripetere Data fine',
    optional: 'Opzionale',
    createplan: 'Crea Piano',
    today: 'Oggi',
    starttime: 'Ora di inizio',
    endtime: 'Tempo scaduto',
    chart: 'Grafico',
    reject: 'Rifiutare',
    accept: 'Accettare',
    filterbydaterange: 'Filtro per intervallo di date',
    filterbymostrecent: 'Filtro dai più recenti',
    result: 'Risultato',
    type: 'genere',
    duration: 'Durata',
    by: 'di',
    title: 'Titolo',
    assigned: 'Assegnato',
    action: 'Azione',
    track: 'traccia',
    issue: 'Problema',
    priority: 'Priorità',
    requestor: 'richiedente',
    opened: 'ha aperto',
    assignee: 'assegnatario',
    root: 'radice',
    cause: 'Causa',
    identified: 'identificato',
    defined: 'definito',
    date: 'Data',
    resolve: 'risolvere',
    resolved: 'risoluto',
    targetresolvedate: 'Target Date determinazione',
    procurement: 'Approvvigionamento',
    choose: 'Scegliere',
    analyses: 'analisi',
    source: 'fonte',
    selection: 'Selezione',
    historical: 'Storico',
    mostrecent: 'Piu recente',
    regression: 'Regressione',
    trendline: 'linea di tendenza',
    upperbound: 'limite superiore',
    lowerbound: 'limite inferiore',
    preview: 'anteprima',
    period: 'periodo',
    series: 'serie',
    axis: 'asse',
    youhaveno: 'tu non hai',
    code: 'Codice',
    all: 'Tutti',
    upload: 'caricare',
    regressionanalysis: 'Analisi di regressione',
    issuetracker: 'issue tracker',
    labelsearch: 'etichetta Ricerca',
    gatewaysetup: 'Configurazione Gateway',
    setupgateway: 'Gateway Setup',
    radiomesh: 'maglie Radio',
    targetsetting: 'definizione degli obiettivi',
    updatetrackedissue: 'aggiornamento problema cingolato',
    shownewtrackedissue: 'mostrare nuova emissione cingolato',
    low: 'Basso',
    mid: 'medio',
    high: 'alto',
    linear: 'lineare',
    exponential: 'esponenziale',
    logarithmic: 'logaritmica',
    quadratic: 'quadratico',
    polynomial: 'polinomio',
    general: 'Generale',
    datataggingenrichment: 'Dati Tagging arricchimento',
    hardware: 'Hardware',
    nodemanagement: 'gestione nodo',
    createeditnotification: 'Crea Modifica notifica',
    conditions: 'condizioni',
    addsku: 'aggiungi SKU',
    editsku: 'modifica SKU',
    setupagateway: 'Gateway impostare un',
    deviceserialnumber: 'numero di serie del dispositivo',
    repeatsweekly: 'ripete ogni settimana',
    repeatsdaily: 'ripete tutti i giorni',
    repeatsmonthly: 'Si ripete ogni mese',
    repeatsannually: 'ripete ogni anno',
    repeatsevery: 'ripete ogni',
    hourly: 'ogni ora',
    biweekly: 'bisettimanale',
    standardtimemanagement: 'Gestione Dei Tempi Standard',
    autoupdate: 'Aggiornamento automatico',
    cycletime: 'Tempo di ciclo',
    notset: 'Non impostato',
    hourly: 'Ogni ora',
    oeesku: 'OEE-SKU',
    skuassetstandardtime: 'SKU-Asset Standard Time',
    permission: 'Gestione delle autorizzazioni',
};
