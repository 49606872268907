import { makeRequest } from '../Shared/Shared.action';
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

export const api_getPolicies = () => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy_data`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getPolicy = (policy) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy/${policy.policy_id}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createPolicy = (policy) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy`,
                method: 'POST',
                params: JSON.stringify({
                    policy_name: policy.policy_name,
                    blocks: policy.blocks,
                    resource_policies: policy.resource_policies,
                    users: policy.users,
                    groups: policy.groups,
                }),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updatePolicy = (policy) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy/${policy.policy_id}`,
                method: 'PUT',
                params: JSON.stringify(policy),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deletePolicy = (policy) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy/${policy.policy_id}`,
                method: 'DELETE',
                params: null,
                success: resolve,
                error: reject,
            });
        }
    );
};

// Apply Policy
export const api_applyPolicy = (policy) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/policy/${policy.policy_id}/apply`,
                method: 'PUT',
                params: JSON.stringify({ users: policy.users, groups: policy.groups }),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Get Resource
export const api_getResources = () => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/resource`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};
