import "./ImpactForm.scss"
import { FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material"
import moment from "moment"
import React, { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import Checkbox from "../../../components/Checkbox/Checkbox"
import NumberInput from "../../../components/Input/NumberInput/NumberInput"
import DescriptiveInput
    from "../../../components/Input/DescriptiveInput/DescriptiveInput"
import ProcessTreeSelect from "../../../components/ProcessSelector/ProcessTreeSelector"
import CurrencyConstants from "../../../constants/currencies.constants"
import { ImpactTypeEnum } from "../../../constants/impact.constants"
import AukDatePicker from "../../../components/DatePicker/DatePicker"

const MAX_LENGTH_INPUT = 13
const ImpactForm = (props: Props) => {
    const [process, setProcess] = useState<number | null>(props.values?.process ?? null)
    const [baselinePeriod, setBaselinePeriod] = useState<string>(props.values ? props.values.baselinePeriod as string : "")
    const [baselineStartDate, setBaselineStartDate] = useState<string | null>(props.values ? props.values.baselineStartDate as string : null)
    const [annualOperatingHours, setAnnualOperatingHours] = useState<string>(props.values ? props.values.annualOperatingHours as string : "")
    const [operationCostPerHour, setOperationCostPerHour] = useState<string>(props.values ? props.values.operationCostPerHour as string : "")
    const [yearlyBaseOutput, setYearlyBaseOutput] = useState<string>(props.values ? props.values.yearlyBaseOutput as string : "")
    const [marginPerPiece, setMarginPerPiece] = useState<string>(props.values ? props.values.marginPerPiece as string : "")
    const [benchmark, setBenchmark] = useState(props.values ? props.values.benchmark as string : "")
    const [currency, setCurrency] = useState(props.values ? props.values.currency as string : "")

    const formValues = useMemo(() => {
        return {
            process,
            baselinePeriod,
            baselineStartDate,
            annualOperatingHours,
            operationCostPerHour,
            benchmark,
            currency,
            yearlyBaseOutput,
            marginPerPiece
        }
    }, [process, baselinePeriod, baselineStartDate, annualOperatingHours, operationCostPerHour, benchmark, currency, yearlyBaseOutput, marginPerPiece])

    const handleChangeProcess = useCallback((value: number | null) => {
        setProcess(value)
        const updatedFormValues = getUpdatedFormValues(formValues, { process: value })
        props.onValuesChange(updatedFormValues)
        props.handlePreview(updatedFormValues)
    }, [formValues, props.handlePreview])

    const handleChangePeriod = useCallback((value: string) => {
        setBaselinePeriod(value)
        const updatedFormValues = getUpdatedFormValues(formValues, { baselinePeriod: value })
        props.onValuesChange(updatedFormValues)
        props.handlePreview(updatedFormValues)
    }, [formValues, props.handlePreview])

    const handleChangeStartDate = useCallback((value: string | null) => {
        setBaselineStartDate(value)
        const updatedFormValues = getUpdatedFormValues(formValues, { baselineStartDate: value })
        props.onValuesChange(updatedFormValues)
        props.handlePreview(updatedFormValues)
    }, [formValues, props.handlePreview])

    const handleChangeOperatingHoursYear = (value: string) => {
        setAnnualOperatingHours(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { annualOperatingHours: value }))
    }

    const handleChangeCostPerOperatingHour = useCallback((value: string) => {
        setOperationCostPerHour(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { operationCostPerHour: value }))
    }, [formValues])

    const handleChangeOutput = useCallback((value: string) => {
        setYearlyBaseOutput(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { yearlyBaseOutput: value }))
    }, [formValues])

    const handleChangeMarginPerPiece = useCallback((value: string) => {
        setMarginPerPiece(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { marginPerPiece: value }))
    }, [formValues])

    const handleChangeBenchmark = useCallback((value: string) => {
        setBenchmark(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { benchmark: value }))
    }, [formValues])

    const handleChangeCurrency = useCallback((value: string) => {
        setCurrency(value)
        props.onValuesChange(getUpdatedFormValues(formValues, { currency: value }))
    }, [formValues])

    useEffect(() => {
        if (props.values) {
            setProcess(props.values.process)
            setBaselinePeriod(props.values.baselinePeriod as string)
            setBaselineStartDate(props.values.baselineStartDate)
            setAnnualOperatingHours(props.values.annualOperatingHours as string)
            setOperationCostPerHour(props.values.operationCostPerHour as string)
            setYearlyBaseOutput(props.values.yearlyBaseOutput as string)
            setMarginPerPiece(props.values.marginPerPiece as string)
            setBenchmark(props.values.benchmark as string)
            setCurrency(props.values.currency as string)
        }
    }, [JSON.stringify(props.values)])

    const disabled = useMemo(() => !process || !baselinePeriod || !baselineStartDate, [process, baselinePeriod, baselineStartDate])

    return <div className="impact-form">
        <ImpactFormField>
            <ProcessTreeSelect
                label="Select block"
                onChange={handleChangeProcess}
                value={process}
            />
        </ImpactFormField>
        <ImpactFormField>
            <InputLabel id="impact-baseline-period-select">Baseline Period</InputLabel>
            <Select
                sx={{ width: "100%" }}
                labelId="impact-baseline-period-select"
                id="impact-baseline-period-select"
                value={baselinePeriod}
                label="Baseline Period"
                onChange={e => {
                    handleChangePeriod(e.target.value)
                }}
            >
                <MenuItem value={BaselinePeriodEnum.DAY}>Day</MenuItem>
                <MenuItem value={BaselinePeriodEnum.WEEK}>Week</MenuItem>
                <MenuItem value={BaselinePeriodEnum.MONTH}>Month</MenuItem>
            </Select>
        </ImpactFormField>
        <ImpactFormField>
            <AukDatePicker
                disableFuture
                label="Baseline Start Date"
                value={moment(baselineStartDate)}
                onChange={e => { handleChangeStartDate(e ? e.toISOString() : null) }}
                format="YYYY/MM/DD"
            />
        </ImpactFormField>
        {
            props.type === ImpactTypeEnum.COST_SAVINGS
                ?
                <>
                    <ImpactFormField>
                        <AnnualOperatingHoursInputCalculator value={annualOperatingHours}
                            onChange={handleChangeOperatingHoursYear}
                            disabled={disabled}/>
                    </ImpactFormField>
                    <ImpactFormField>
                        <NumberInput
                            label="Operating Cost per Hour"
                            maxLength={MAX_LENGTH_INPUT}
                            disabled={disabled}
                            value={operationCostPerHour}
                            onChange={(e) => {
                                handleChangeCostPerOperatingHour(e.target.value)
                            }}
                            decimalScale={3}
                        />
                    </ImpactFormField>
                </>

                :
                <>
                    <ImpactFormField>
                        <YearlyBaseOutputInputCalculator
                            value={yearlyBaseOutput}
                            onChange={handleChangeOutput}
                            disabled={disabled}
                        />
                    </ImpactFormField>
                    <ImpactFormField>
                        <NumberInput
                            label="Margin per piece"
                            maxLength={MAX_LENGTH_INPUT}
                            disabled={disabled}
                            value={marginPerPiece}
                            onChange={(e) => {
                                handleChangeMarginPerPiece(e.target.value)
                            }}
                            decimalScale={3}
                        />
                    </ImpactFormField>
                </>

        }
        <ImpactFormField>
            <NumberInput
                label="Benchmark (%)"
                disabled={disabled}
                value={benchmark}
                onChange={(e) => {
                    handleChangeBenchmark(e.target.value)
                }}
                max={100}
                decimalScale={0}
            />
        </ImpactFormField>
        <ImpactFormField>
            <InputLabel id="impact-currency-select">Currency</InputLabel>
            <Select
                sx={{ width: "100%" }}
                disabled={disabled}
                label="Currency"
                value={currency}
                onChange={(e) => {
                    handleChangeCurrency(e.target.value)
                }}
            >
                {CurrencyConstants.map(currency =>
                    <MenuItem key={currency.code} value={currency.code}>{currency.label}</MenuItem>
                )}
            </Select>
        </ImpactFormField>
        <ImpactFormField>
            <Button disabled={disabled} variant="outlined" onClick={props.onSave}>Save</Button>
        </ImpactFormField>
    </div>
}

const getUpdatedFormValues = (formValues: ImpactFormValues, record: Partial<ImpactFormValues>) => {
    return {
        ...formValues,
        ...record
    }
}

interface Props {
    values?: ImpactFormValues
    handlePreview: (values: ImpactFormValues) => void
    onValuesChange: (values: ImpactFormValues) => void
    onSave: () => void
    type: ImpactTypeEnum
}

export default ImpactForm

const AnnualOperatingHoursInputCalculator = (props: InputWithCalculatorProps) => {
    const [value, setValue] = useState<string>(props.value ?? "")
    const [showExpandedOperatingHours, setShowExpandedOperatingHours] = useState<boolean>(false)
    const [hoursPerDay, setHoursPerDay] = useState<string>("15")
    const [daysPerWeek, setDaysPerWeek] = useState<string>("5")
    const [weeksPerYear, setWeeksPerYear] = useState<string>("50")

    const clearExpandedFields = () => {
        setHoursPerDay("")
        setDaysPerWeek("")
        setWeeksPerYear("")
    }

    useEffect(() => {
        if (hoursPerDay && daysPerWeek && weeksPerYear) {
            const calculatedValue = (+hoursPerDay * +daysPerWeek * +weeksPerYear).toString()
            setValue(calculatedValue)
            props.onChange(calculatedValue)
        }
    }, [hoursPerDay, daysPerWeek, weeksPerYear])

    useEffect(() => { setValue(props.value) }, [props.value])

    return <>
        <NumberInput
            disabled={props.disabled}
            label="Annual Operating Hours"
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
                props.onChange(e.target.value)
                clearExpandedFields()
            }}
            maxLength={MAX_LENGTH_INPUT}
        />
        <Checkbox
            disabled={props.disabled}
            value={showExpandedOperatingHours}
            onChange={() => {
                setShowExpandedOperatingHours(!showExpandedOperatingHours)
            }}
        >
            Expand
        </Checkbox>
        {showExpandedOperatingHours && <>
            <DescriptiveInput
                type="number"
                label="hours per day"
                value={hoursPerDay}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setHoursPerDay(e.target.value)
                }}
                min={0}
                max={24}
                maxLength={2}
            />
            <DescriptiveInput
                type="number"
                label="days per week"
                value={daysPerWeek}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDaysPerWeek(e.target.value)
                }}
                min={0}
                max={7}
                maxLength={1}
            />
            <DescriptiveInput
                type="number"
                label="weeks per year"
                value={weeksPerYear}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setWeeksPerYear(e.target.value)
                }}
                min={0}
                max={52}
                maxLength={2}
            />
        </>}
    </>
}

const YearlyBaseOutputInputCalculator = (props: InputWithCalculatorProps) => {
    const [value, setValue] = useState<string>(props.value ?? "")
    const [showExpandedYearlyBaseOutput, setShowExpandedYearlyBaseOutput] = useState<boolean>(false)
    const [piecesPerWeek, setPiecesPerWeek] = useState<string>("50000")
    const [weeksPerYear, setWeeksPerYear] = useState<string>("50")

    const clearExpandedFields = () => {
        setPiecesPerWeek("")
        setWeeksPerYear("")
    }

    useEffect(() => {
        if (piecesPerWeek && isFinite(+piecesPerWeek) && weeksPerYear && isFinite(+weeksPerYear)) {
            const calculatedValue = (+piecesPerWeek * +weeksPerYear).toString()
            setValue(calculatedValue)
            props.onChange(calculatedValue)
        }
    }, [piecesPerWeek, weeksPerYear])

    const handleBaseOutputPerYear = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value.length > MAX_LENGTH_INPUT) {
            return
        }

        setValue(e.target.value)
        props.onChange(e.target.value)
        clearExpandedFields()
    }

    const handlePiecesBaseOutputPerWeek = (e: ChangeEvent<HTMLInputElement>): void => {
        setPiecesPerWeek(e.target.value)
    }

    const handleWeeksPerYear = (e: ChangeEvent<HTMLInputElement>): void => {
        setWeeksPerYear(e.target.value)
    }

    useEffect(() => { setValue(props.value) }, [props.value])

    return <>
        <NumberInput
            disabled={props.disabled}
            label="Base output per year"
            maxLength={MAX_LENGTH_INPUT}
            value={value}
            onChange={handleBaseOutputPerYear}
        />
        <Checkbox
            disabled={props.disabled}
            value={showExpandedYearlyBaseOutput}
            onChange={() => {
                setShowExpandedYearlyBaseOutput(!showExpandedYearlyBaseOutput)
            }}
        >
            Expand
        </Checkbox>
        {showExpandedYearlyBaseOutput && <>
            <DescriptiveInput
                type="number"
                width={100}
                label="pieces, base output per week"
                value={piecesPerWeek}
                onChange={handlePiecesBaseOutputPerWeek}
                maxLength={9}
            />
            <DescriptiveInput
                type="number"
                width={100}
                label="weeks per year"
                value={weeksPerYear}
                onChange={handleWeeksPerYear}
                min={0}
                max={52}
                maxLength={2}
            />
        </>}
    </>
}

interface InputWithCalculatorProps {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
}

const ImpactFormField = (props: FieldProps) => {
    return <FormControl fullWidth className="impact-form-field-wrapper">
        {props.children}
    </FormControl>
}

interface FieldProps {
    children: React.ReactNode
}

export interface ImpactFormValues {
    process: number | null
    baselinePeriod: string | null
    baselineStartDate: string | null
    annualOperatingHours: string | null
    operationCostPerHour: string | null
    yearlyBaseOutput: string | null
    marginPerPiece: string | null
    benchmark: string | null
    currency: string | null
}

enum BaselinePeriodEnum {
    DAY = "days",
    WEEK = "weeks",
    MONTH = "months"
}
