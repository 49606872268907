/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import * as d3 from 'd3';
import { findDOMNode } from 'react-dom';

import { BaseChartTooltip } from './tooltips';
import {
    updateHDPICanvas,
    roundRect,
    genColor,
    clearCanvas,
    canvasPixelRatio,
} from '../../utils/charts';

import './Charts_V1.scss';

const xAxisHeight = 30;

export class Heatmap extends React.Component {
    constructor(props) {
        super(props);

        this.x = d3.scaleBand().range([]).domain([]);
        this.y = d3.scaleBand().range([]).domain([]);
        this.color = d3.scaleLinear().clamp(true).domain([]).range([]);

        this.colorToNode = new Map();

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.x_svg = this.el.select('.svg--x-axis');
        this.x_g = this.el.select('.x-axis');
        this.canvas = this.el.select('canvas.main');
        this.hidden = this.el.select('canvas.hidden');

        this.focus = this.el.select('.focus');

        const custom = document.createElement('custom');
        this.custom = d3.select(custom);

        this.redraw();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.redraw();
        }
    }

    recalculate() {
        const {
            w,
            h,
            props: { xDomain, yDomain, colorRange, colorDomain },
        } = this;

        this.x = this.x.range([0, w]).domain(xDomain);
        this.y = this.y.range([h, 0]).domain(yDomain);
        this.color = this.color.range(colorRange).domain(colorDomain);
    }

    redraw() {
        setTimeout(() => {
            this.recalculate();
            this.redrawChartArea();

            const {
                colorToNode,
                redrawCustom,
                redrawCanvas,
                redrawHidden,
                props: { duration },
            } = this;

            redrawCustom();
            d3.timerFlush();
            const timer = d3.timer(function (elapsed) {
                if (elapsed > duration) {
                    timer.stop();
                }
                colorToNode.clear();
                redrawCanvas();
                redrawHidden();
            });
        });
    }

    redrawChartArea() {
        const {
            width,
            height,
            props: { xDomain, xAccessor, xTickAccessor, xTickFontWeight },
            x,
        } = this;

        this.x_svg.attr('width', width).attr('height', xAxisHeight);

        const xAxisSelection = this.x_g.selectAll('text').data(xDomain);

        xAxisSelection
            .enter()
            .append('text')
            .attr('x', function (d) {
                return x(d);
            })
            .attr('y', 10)
            .text(function (d, i) {
                return xTickAccessor ? xTickAccessor(d, i) : d;
            })
            .attr('font-size', 11)
            .attr('text-anchor', 'middle')
            .attr('font-weight', function (d, i) {
                return xTickFontWeight ? xTickFontWeight(d, i) : 300;
            });

        xAxisSelection
            .attr('x', function (d) {
                return x(d);
            })
            .attr('y', 10)
            .text(function (d, i) {
                return xTickAccessor ? xTickAccessor(d, i) : d;
            })
            .attr('font-size', 12)
            .attr('text-anchor', 'middle')
            .attr('font-weight', function (d, i) {
                return xTickFontWeight ? xTickFontWeight(d, i) : 300;
            });

        xAxisSelection.exit().remove();

        updateHDPICanvas(this.canvas.node(), this.w, this.h);
        updateHDPICanvas(this.hidden.node(), this.w, this.h);

        const self = this;
        this.canvas
            .on('mousemove', function () {
                const newEvent = new Event('mousemove', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                });
                newEvent.pageX = d3.event.pageX;
                newEvent.clientX = d3.event.clientX;
                newEvent.pageY = d3.event.pageY;
                newEvent.clientY = d3.event.clientY;
                newEvent.view = window;
                self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
            })
            .on('click', function () {
                const newEvent = new Event('click', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                });
                newEvent.pageX = d3.event.pageX;
                newEvent.clientX = d3.event.clientX;
                newEvent.pageY = d3.event.pageY;
                newEvent.clientY = d3.event.clientY;
                newEvent.view = window;
                self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
            });

        this.hidden
            .on('mousemove', function () {
                self.handleMouseMove(this);
            })
            .on('click', function () {
                self.handleClick(this);
            });

        this.el.on('mouseleave', function () {
            self.handleMouseLeave();
        });
    }

    redrawCustom() {
        const {
            x,
            y,
            color,
            custom,
            props: {
                data,
                transition,
                duration,
                gridSize,
                xAccessor,
                yAccessor,
                dataAccessor,
                colorAccessor,
            },
        } = this;

        const t = transition || null;

        const selection = custom
            .selectAll('.cell')
            .data(
                data,
                (d) => `${x(xAccessor(d))}${y(yAccessor(d))}${dataAccessor(d)}`
            );

        selection
            .enter()
            .append('custom')
            .attr('class', 'cell')
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', (d) => y(yAccessor(d)))
            .attr('width', gridSize)
            .attr('height', gridSize)
            .attr('text', (d, i) => dataAccessor(d))
            .attr('fill', (d) =>
                colorAccessor ? colorAccessor(color, d) : color(dataAccessor(d))
            );

        selection
            .transition(t)
            .duration(duration)
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', (d) => y(yAccessor(d)))
            .attr('width', gridSize)
            .attr('height', gridSize)
            .attr('text', (d, i) => dataAccessor(d))
            .attr('fill', (d) =>
                colorAccessor ? colorAccessor(color, d) : color(dataAccessor(d))
            );

        selection.exit().remove();
    }

    redrawCanvas() {
        const {
            canvas,
            custom,
            w,
            h,
            props: { gridSize },
        } = this;

        // clear canvas
        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        // draw bars
        const cells = custom.selectAll('.cell');
        cells.each(function (d, i) {
            var node = d3.select(this);
            context.save();
            context.globalAlpha = 0.8;
            context.beginPath();
            roundRect(
                context,
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width') - 1,
                +node.attr('height') - 1,
                2
            );
            context.fillStyle = node.attr('fill');
            context.strokeStyle = node.attr('fill');
            context.lineCap = 'round';
            context.fill();

            context.globalAlpha = 1;
            context.closePath();
            context.clip();

            context.font = `300 11px sans-serif`;
            context.fillStyle = '#222'; // colorAccessorText
            context.textAlign = 'center';
            context.fillText(
                node.attr('text'),
                +node.attr('x') + gridSize / 2,
                +node.attr('y') + (gridSize + 10) / 2
            );
            context.restore();
        });
    }

    redrawHidden() {
        const { hidden, custom, w, h, colorToNode } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const cells = custom.selectAll('.cell');
        cells.each(function (d, i) {
            const node = d3.select(this);

            hiddenContext.beginPath();
            const color = genColor(i);
            colorToNode.set(color, d);
            roundRect(
                hiddenContext,
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width') - 1,
                +node.attr('height') - 1,
                2
            );
            hiddenContext.fillStyle = color;
            hiddenContext.strokeStyle = color;
            hiddenContext.lineCap = 'round';
            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    handleMouseMove(DOMnode) {
        const {
            x,
            h,
            props: { xAccessor, useTooltip },
        } = this;

        if (useTooltip) {
            const d = this.identifyDatum(DOMnode);
            if (!d) {
                this.handleMouseLeave();
                return;
            }

            this.showFocus(d);
            this.showTooltip(d);
        }
    }

    handleClick(DOMnode) {
        if (this.props.handleClick) {
            const d = this.identifyDatum(DOMnode);
            d && this.props.handleClick(d);
        }
    }

    identifyDatum(DOMnode) {
        const { clientX, clientY } = window.event;
        const [mouseX, mouseY] = d3.mouse(DOMnode);
        const hiddenContext = this.hidden.node().getContext('2d');
        const pixelRatio = canvasPixelRatio(hiddenContext);
        const [r, g, b] = hiddenContext.getImageData(
            mouseX * pixelRatio,
            mouseY * pixelRatio,
            1,
            1
        ).data;

        const color = `rgb(${r},${g},${b})`;
        return this.colorToNode.get(color);
    }

    get width() {
        const {
            w,
            props: {
                margin: { left, right },
            },
        } = this;

        return w + left + right;
    }

    get w() {
        const {
            props: { xDomain, gridSize },
        } = this;

        return xDomain.length * gridSize;
    }

    get height() {
        const {
            h,
            props: {
                margin: { top, bottom },
            },
        } = this;

        return h + top + bottom;
    }

    get h() {
        const {
            props: { yDomain, gridSize },
        } = this;

        return yDomain.length * gridSize;
    }

    showTooltip(d) {
        const { clientX, clientY } = window.event;
        const { htmlTooltip } = this.props;

        const { top, bottom, height, width } = this.tooltip
            .node()
            .getBoundingClientRect();

        const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

        const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
        let dy = clientY + 10;
        let dx = clientX + 10;

        if (dy + height > windowHeight) {
            dy = dy - height;
        }

        if (dx + width > windowWidth) {
            dx = dx - width;
        }

        this.tooltip
            .style('top', `${dy}px`)
            .style('left', `${dx}px`)
            .style('visibility', 'visible')
            .style('opacity', 0.8)
            .html(htmlTooltip && htmlTooltip(d));
    }

    showFocus(d) {
        const {
            x,
            y,
            props: { xAccessor, yAccessor, margin },
        } = this;

        this.focus
            .style('visibility', 'visible')
            .style('top', `${y(yAccessor(d))}px`)
            .style('left', `${x(xAccessor(d)) + margin.left}px`);
    }

    handleMouseLeave() {
        this.tooltip.style('visibility', 'hidden').style('opacity', 0);
        this.focus.style('visibility', 'hidden');
    }

    render() {
        const {
            props: { margin, gridSize },
        } = this;
        return (
            <div className="chart-v1 heatmap--container">
                <div className="heatmap--x-axis" style={{ width: this.width }}>
                    <svg className="svg--x-axis">
                        <g transform={`translate(${margin.left},${margin.top})`}>
                            <g
                                className="x-axis"
                                height={xAxisHeight}
                                transform={`translate(${gridSize / 2},0)`}
                            />
                        </g>
                    </svg>
                </div>
                <div className="heatmap--chart">
                    <BaseChartTooltip ref={(node) => (this.tooltip = d3.select(node))} />
                    <canvas
                        className="hidden"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginBottom: margin.bottom,
                            marginRight: margin.right,
                            opacity: 0,
                        }}
                    />
                    <canvas
                        className="main"
                        style={{
                            marginLeft: margin.left,
                            marginBottom: margin.bottom,
                            marginRight: margin.right,
                        }}
                    />
                    <div
                        className="focus"
                        style={{
                            position: 'absolute',
                            cursor: 'crosshair',
                            pointerEvents: 'none',
                            width: gridSize - 1,
                            height: gridSize - 1,
                            background: 'none',
                            visibility: 'hidden',
                            border: '3px solid #616161',
                        }}
                    />
                </div>
            </div>
        );
    }
}

Heatmap.defaultProps = {
    xDomain: [],
    yDomain: [],
    margin: { top: 10, left: 10, right: 30, bottom: 20 },
    duration: 200,
};
