/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { withEntity } from '../../../Wrappers/HOCs/withEntity';
import withAsset from '../../../Wrappers/HOCs/withAsset';
import { Form, Input, Modal, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import moment from 'moment';
import {
    createAssetShiftTarget,
    deleteAssetShiftTarget,
    updateAssetShiftTarget,
} from '../../../../store/old/Target/Target.action';
import AukButton from '../../../components/AukButton';
import { flash } from '../../../components/Flash';

const timeFormat = 'hh:mmA';
const AssetShiftTargetModal = ({
    data,
    visible,
    onCancel,
    onSuccess,
    entity: { entity_id },
    asset: { asset_id },
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const title = useMemo(() => {
        if (!data) return '';
        const shiftStartDay = capitalize(moment(data.from).format('dddd'));
        const shiftStartTime = moment(data.from).format(timeFormat);
        const shiftEndDay = capitalize(moment(data.to).format('dddd'));
        const shiftEndTime = moment(data.to).format(timeFormat);

        return `Set production target for ${shiftStartDay}, ${shiftStartTime} to ${shiftEndDay} ${shiftEndTime}`;
    }, [data]);

    const submit = useCallback(
        (formValues) => {
            const { shift_id } = data;
            const value = +formValues.target.trim();
            if (data.hasTargetsSet) {
                dispatch(
                    updateAssetShiftTarget(
                        entity_id,
                        asset_id,
                        shift_id,
                        data.targets[0].target_id,
                        { value },
                        () => {
                            onSuccess && onSuccess();
                            onCancel();
                        }
                    )
                );
                return;
            }

            dispatch(
                createAssetShiftTarget(
                    entity_id,
                    asset_id,
                    shift_id,
                    { value },
                    () => {
                        onSuccess && onSuccess();
                        onCancel();
                    }
                )
            );
        },
        [data]
    );

    const deleteTarget = useCallback(() => {
        dispatch(
            deleteAssetShiftTarget(
                entity_id,
                asset_id,
                data.shift_id,
                data.targets[0].target_id,
                () => {
                    flash({ message: 'Target deleted' });
                    onSuccess && onSuccess();
                    onCancel();
                }
            )
        );
    }, [data]);


    return (
        <Modal
            className="auk-modal--small"
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={
                <div className="d-flex justify-content-between">
                    <AukButton.Cancel onClick={onCancel} />
                    {data && data.hasTargetsSet ? (
                        <Popconfirm
                            title={'Delete target?'}
                            onConfirm={deleteTarget}
                            okText="Yes"
                            cancelText="No"
                        >
                            <AukButton.Delete />
                        </Popconfirm>
                    ) : null}
                    <AukButton.Save
                        onClick={async () => {
                            try {
                                const values = await form.validateFields();
                                submit(values);
                                form.resetFields;
                            } catch (e) {}
                        }}
                    />
                </div>
            }
            centered
        >
            <Form
                form={form}
                layout="vertical"
                name="targetForm"
                onFinish={submit}
            >
                <Form.Item
                    initialValue={
                        data && data.hasTargetsSet ? data.targets[0].value : ''
                    }
                    name="target"
                    rules={[
                        {
                            required: true,
                            message: 'Target is required',
                        },
                    ]}
                >
                    <Input
                        placeholder="Set production target"
                        autoFocus
                        maxLength={7}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AssetShiftTargetModal;
