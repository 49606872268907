import { FormControl } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import BaseSelect, { type SelectChangeEvent } from "@mui/material/Select"
import React from "react"

const Select = <V = string, >(props: Props<V>) => {
    const { onValueChange, ...selectProps } = props
    const handleChange = (e: SelectChangeEvent<V>) => {
        if ("value" in e.target) {
            onValueChange(e.target.value as V)
        }
    }

    return <FormControl hiddenLabel={!props.label} fullWidth>
        <BaseSelect<V, "filled">
            onChange={handleChange}
            {...selectProps}
            variant="filled"
        >
            {props.options.map(
                ({ label, value }) => <MenuItem key={value} value={value}>{label}</MenuItem>
            )}
        </BaseSelect>
    </FormControl>
}

type Option = {
    label: string
    value: string | number
}

type Props<V> = {
    value: V
    onValueChange: (value: V) => void
    options: Option[]
    label?: string
    variant?: "filled" | "outlined"
    required?: boolean
    fullWidth?: boolean
}

export default Select
