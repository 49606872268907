/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Popover, Select, Tag } from 'antd';
import { InfoCircleOutlined, PercentageOutlined } from '@ant-design/icons';
import { CompactPicker } from 'react-color';

// CONSTANTS
import { LANGUAGE_OPTIONS } from '../../../store/old/UI/Controls/Controls.constants';

// COMPONENTS
import AukButton from '../../components/AukButton';
import { DonutChart } from '../../Charts/v1';
import { SWATCHES } from '../../components/ColorPicker';
import { Panel, PanelBody, PanelFooter } from '../../components/Panel';

// ACTION
import {
    createEntityPreferencesRequest,
    updateEntityPreferencesRequest,
} from '../../../store/old/Preference/Preference.action';

// HELPER
import './EntityPreferences.scss';
import translate from '../../utils/translate';

const generateGradient = (colors = []) => {
    let gradient = '';
    const newArray = colors.map((v) => v);

    newArray.sort((a, b) => a.th - b.th);

    newArray.forEach((v, index) => {
        if (index < 2) {
            gradient += ` ${v.color} ${v.th}%,`;
        } else {
            gradient += ` ${v.color} ${v.th}%`;
        }
    });

    return `linear-gradient(to right, ${gradient})`;
};

const PREFERENCE_VALIDATE = {
    COLOR_TH: [
        {
            transform: (value) => +value.th,
            type: 'number',
            min: 0,
            message: 'Cannot be less than 0.',
        },
    ],
};

const OEE_OPTIONS = [
    { value: false, label: 'OEE-1' },
    { value: true, label: 'OEE-2' },
];

const OEESKU_OPTIONS = [
    { value: false, label: 'Off' },
    { value: true, label: 'On' },
];

const LIVE_SHIFT_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const TWO_FA_OPTIONS = [
    { label: 'On', value: true },
    { label: 'Off', value: false },
];

const EntityPreferenceForm = (props) => {
    const data = useSelector((rootState) => rootState.preference.entity);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const save = useMemo(
        () =>
            data.preference_id
                ? updateEntityPreferencesRequest
                : createEntityPreferencesRequest,
        [data]
    );

    const submit = (formData) => {
        const { language, live_shift, oee_colors, oee, options } = formData;

        const params = {
            apply_to: 'entity',
            language,
            oee_colors,
            oee,
            options,
        };
        dispatch(save(params));
    };

    return (
        <Panel>
            <Form
                name="entityPreferenceForm"
                id="entityPreferenceForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="entity-preference-form"
                onFinish={() => submit(form.getFieldsValue(true))}
            >
                <PanelBody>
                    <Form.Item colon={false} label={'OEE Colors'}>
                        <div className="d-flex flex-column">
                            <div className="d-flex">
                                {data.oee_colors.map((c, i) => (
                                    <Form.Item
                                        colon={false}
                                        initialValue={c}
                                        name={['oee_colors', i]}
                                        key={i}
                                        className="mr-3"
                                        rules={PREFERENCE_VALIDATE.COLOR_TH}
                                    >
                                        <OEEColorInputSimple />
                                    </Form.Item>
                                ))}
                            </div>
                            <div style={{ fontSize: 12 }}>Preview Gradient</div>
                            <Form.Item
                                shouldUpdate={(prev, current) =>
                                    prev.oee_colors !== current.oee_colors
                                }
                                noStyle
                            >
                                {() => (
                                    <div
                                        className="oee-th-gradient"
                                        style={{
                                            backgroundImage: generateGradient(
                                                form.getFieldsValue(true).oee_colors
                                            ),
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name={'language'}
                        label={translate('language')}
                        initialValue={data.language}
                    >
                        <Select options={LANGUAGE_OPTIONS} />
                    </Form.Item>

                    <Form.Item
                        colon={false}
                        name={['oee', 'oee2']}
                        label="OEE Selection"
                        initialValue={data.oee ? data.oee.oee2 : false}
                    >
                        <Select options={OEE_OPTIONS} />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        name={['oee', 'sku_oee']}
                        label="OEE-SKU"
                        initialValue={
                            data.oee && data.oee.sku_oee !== undefined
                                ? data.oee.sku_oee
                                : true
                        }
                    >
                        <Select options={OEESKU_OPTIONS} />
                    </Form.Item>
                    <Form.Item
                        name={['options', 'label_notes']}
                        colon={false}
                        label={
                            <div
                                className="d-flex align-items-center justify-content-end"
                                style={{ marginRight: 8 }}
                            >
                Label Notes
                                <Popover placement="rightBottom" content={'Show label notes'}>
                                    <InfoCircleOutlined className="ml-2" />
                                </Popover>
                            </div>
                        }
                        initialValue={!!(data.options && data.options.label_notes)}
                    >
                        <Select options={LIVE_SHIFT_OPTIONS} />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={
                            <div
                                className="d-flex align-items-center justify-content-end"
                                style={{ marginRight: 8 }}
                            >
                2-Factor Auth{' '}
                                <Popover
                                    placement="rightBottom"
                                    content={
                                        <>
                                            <p>This is a premium feature.</p>
                                            <p>Please contact service@auk.industries for access.</p>
                                        </>
                                    }
                                >
                                    <Tag className="m-0 ml-2 text-uppercase" color="#ddd">
                    Premium
                                    </Tag>
                                </Popover>
                            </div>
                        }
                        disabled
                    >
                        <Select disabled value={false} options={TWO_FA_OPTIONS} />
                    </Form.Item>
                </PanelBody>
                <PanelFooter className="p-2">
                    <AukButton.Reset
                        className="mr-2"
                        onClick={() => form.resetFields()}
                    />
                    <AukButton.Save htmlType="submit" />
                </PanelFooter>
            </Form>
        </Panel>
    );
};

const OEEColorInputSimple = ({ value = {}, onChange }) => {
    const [th, setTh] = useState(+value.th);
    const [color, setColor] = useState(value.color);
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        onChange({ th, color });
    }, [th, color]);

    const styles = {
        popover: {
            position: 'absolute',
            zIndex: 2,
            top: 130,
        },
    };

    return (
        <div className="th-color-input">
            <div
                className="color-input"
                onClick={() => setShowPicker(!showPicker)}
                title={'Click to change color'}
            >
                <DonutChart
                    className="color-input__swatch"
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    showIndicators={false}
                    data={[
                        {
                            value: th,
                            color,
                        },
                    ]}
                />
            </div>
            {showPicker ? (
                <div style={styles.popover}>
                    <CompactPicker
                        colors={SWATCHES}
                        style={{ padding: 10 }}
                        color={color}
                        onChange={(v) => {
                            setColor(v.hex);
                        }}
                    />
                </div>
            ) : null}
            <Input
                size="small"
                className="color-input__th"
                type="number"
                value={th}
                onChange={(e) => setTh(e.target.value)}
                suffix={<PercentageOutlined />}
            />
        </div>
    );
};

export default EntityPreferenceForm;
