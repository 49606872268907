/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { difference } from 'lodash';
import { Input, Modal } from 'antd';

// COMPONENT
import ResourceRow from './ResourceRow';
import Select from '../../../../components/Select';
import BlockSelection from './BlockSelection';
import { flash } from '../../../../components/Flash';

// MODEL
import { Policy, ResourcePermission } from '../../../../models';

// ACTION COMPONENT
import PolicyAction from '../PolicyAction';

class CreateEditPolicyModal extends Component {
    policyNameRef = createRef();
    blockSelectionRef = createRef();
    policyActionRef = createRef();

    state = {
        policy_id: -1,
        policy_name: '',
        users: [],
        groups: [],
        blocks: [],
        resource_policies: [],
        showModalCreateEditPolicy: false,
        action: '',
        defaultSelectAll: new ResourcePermission(-1, 'nil'),
    };

    handleCloseModal = () => {
        this.setState({ showModalCreateEditPolicy: false });
    };

    handleSubmitModal = () => {
        const { policy_id, policy_name, users, groups, resource_policies, action } =
      this.state;

        const errors = [];

        const regex = new RegExp(/^[^*|\":<>[\]{}`\\()';@&$^%~!#+=?/.,]+$/);

        if (policy_name.length === 0) {
            errors.push(<p style={{ marginBottom: 0 }}>Policy can not be empty!</p>);
        }

        if (policy_name.length > 0 && !regex.test(policy_name)) {
            errors.push(
                <p style={{ marginBottom: 0 }}>
          Special Characters is not allowed for Policy!
                </p>
            );
        }

        if (groups.length === 0) {
            errors.push(<p>Group can not be empty!</p>);
        }

        if (errors.length > 0) {
            return flash({
                message:
          action === 'create' ? 'Create Policy Error' : 'Update Policy Error',
                status: 'error',
                details: errors,
            });
        }

        const users_ids_number = users.map((v) => Number(v));
        const groups_ids_number = groups.map((v) => Number(v));
        const blocks = this.blockSelectionRef.current.getSelectedBlocks.map((v) =>
            Number(v)
        );

        const policy = new Policy(
            policy_name,
            policy_id,
            blocks,
            users_ids_number,
            groups_ids_number,
            resource_policies
        );

        if (action === 'edit') {
            this.setState({ showModalCreateEditPolicy: false });
            this.policyActionRef.current.updatePolicy(policy);
        }

        if (action === 'create') {
            this.setState({ showModalCreateEditPolicy: false });
            this.policyActionRef.current.createPolicy(policy);
        }
    };

    changeStateCreateEditPolicyModal(
        policy,
        action,
        unExistedPolicy = []
    ) {
        const newArrayPolicy = policy.resource_policies.concat(
            unExistedPolicy.map((v) => {
                return {
                    policy_id: policy.policy_id,
                    resource: {
                        resource: v.resource,
                        resource_id: v.resource_id,
                        parent_resource_id: 'nil',
                    },
                    resource_id: v.resource_id,
                    rights: 'nil',
                };
            })
        );

        this.setState({
            showModalCreateEditPolicy: true,
            action: action,
            policy_id: policy.policy_id,
            policy_name: policy.policy_name,
            groups: policy.groups || [],
            users: policy.users || [],
            resource_policies: newArrayPolicy.filter((v) => v.resource),
            blocks: policy.blocks || [],
            defaultSelectAll:
        action === 'edit'
            ? new ResourcePermission(-1, 'null')
            : new ResourcePermission(-1, 'nil'),
        });
    }

    handleOnChange = (resourcePermission) => {
        const { resource_policies } = this.state;
        let immutableResources = List(resource_policies);
        let newArray = [];

        const indexResourcePermission = immutableResources.findIndex(
            (v) => v.resource_id === resourcePermission.resource_id
        );

        newArray = immutableResources.update(indexResourcePermission, (v) => {
            return {
                ...v,
                rights: resourcePermission.rights,
            };
        });

        this.setState({
            resource_policies: newArray.toJS(),
            defaultSelectAll: new ResourcePermission(-1, 'null'),
        });
    };

    handleSelectAll = (resourcePermission) => {
        const { resource_policies } = this.state;
        let immutableResources = List(resource_policies);
        let newArray = [];

        const newDefaultPolicy = new ResourcePermission(
            -1,
            resourcePermission.rights
        );

        newArray = immutableResources.map((v) => {
            return {
                ...v,
                rights: resourcePermission.rights,
            };
        });

        this.setState({
            resource_policies: newArray.toJS(),
            defaultSelectAll: newDefaultPolicy,
        });
    };

    handleUpdateState = (field, data) => {
        this.setState({ [field]: data });
    };

    render() {
        const {
            policy_name,
            groups,
            blocks,
            resource_policies,
            showModalCreateEditPolicy,
            action,
            defaultSelectAll,
        } = this.state;

        const {
            resources,
            groupsRedux,
            summary_tiles,
            rawArrayBlocks,
            groupFiltered,
            authUser,
        } = this.props;

        return (
            <Modal
                className="auk-modal"
                key={showModalCreateEditPolicy ? 1 : 0}
                title={action === 'create' ? 'Create Policy' : 'Update Policy'}
                visible={showModalCreateEditPolicy}
                onOk={this.handleSubmitModal}
                onCancel={this.handleCloseModal}
            >
                <div className="permission-create-policy">
                    <div className="row create-policy-item">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="row create-policy-input">
                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <p className="policy-item-label">Policy Name*</p>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-8">
                                    <Input
                                        type="text"
                                        value={policy_name}
                                        maxLength={50}
                                        onChange={(v) => {
                                            const value = v.target.value;

                                            this.setState({
                                                policy_name:
                          value.trim().length === 0 ? '' : v.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row create-policy-input">
                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <p className="policy-item-label">Groups*</p>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-8">
                                    <Select
                                        value={groups}
                                        isMulti
                                        options={groupFiltered}
                                        getOptionLabel={(opt) =>
                                            groupsRedux[Number(opt)].group_name
                                        }
                                        getOptionValue={(opt) => opt}
                                        onChange={(e) => this.handleUpdateState('groups', e)}
                                        placeholder="Groups"
                                    />
                                </div>
                            </div>
                            <div className="row create-policy-input resources">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <ResourceRow
                                        key={defaultSelectAll.resource_id}
                                        resource_id={defaultSelectAll.resource_id}
                                        resource_name="Select All"
                                        handleOnChange={this.handleSelectAll}
                                        rights={defaultSelectAll.rights}
                                    />
                                    {resource_policies.map((resourceItem) => {
                                        const resource_name = resources[
                                            Number(resourceItem.resource_id)
                                        ]
                                            ? resources[Number(resourceItem.resource_id)].resource
                                            : '';

                                        if (
                                            resource_name === 'entity_preferences' ||
                      resource_name === 'metadata'
                                        )
                                            return null;

                                        return (
                                            <ResourceRow
                                                key={resourceItem.resource_id}
                                                resource_id={resourceItem.resource_id}
                                                resource_name={
                                                    resources[Number(resourceItem.resource_id)]
                                                        ? resources[Number(resourceItem.resource_id)]
                                                            .resource
                                                        : ''
                                                }
                                                handleOnChange={this.handleOnChange}
                                                rights={resourceItem.rights}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="row" style={{ marginBottom: 10 }}>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <p className="policy-item-label">Allowed Access </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <BlockSelection
                                        ref={this.blockSelectionRef}
                                        blocks={rawArrayBlocks}
                                        checkedKeys={blocks}
                                        summary_tiles={summary_tiles}
                                        // isExpanded
                                        authUser={authUser}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PolicyAction ref={this.policyActionRef} />
            </Modal>
        );
    }
}

function filter(
    policies,
    data,
    currentUser,
    isUser
) {
    let ids = [];
    let objectKeys = [];

    if (isUser && currentUser) {
        objectKeys = Object.keys(data)
            .filter((v) => Number(v) !== currentUser.user_id)
            .map((v) => Number(v));
    }

    if (!isUser) {
        objectKeys = Object.keys(data).map((v) => Number(v));
    }

    if (isUser) {
        policies.forEach((v) => {
            ids = ids.concat(v.users);
        });
    } else {
        policies.forEach((v) => {
            ids = ids.concat(v.groups);
        });
    }

    return difference(objectKeys, ids);
}

const mapStateToProps = (rootState) => {
    return {
        authUser: rootState.auth.user,
        resources: rootState.permission.resources,
        usersRedux: rootState.user.list,
        groupsRedux: rootState.group.groups,
        summary_tiles: rootState.tiles.summary_tiles,
        userFiltered: filter(
            rootState.permission.policies,
            rootState.user.list,
            rootState.auth.user,
            true
        ),
        groupFiltered: filter(
            rootState.permission.policies,
            rootState.group.groups,
            null,
            false
        ),
    };
};

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(CreateEditPolicyModal);
