/* eslint-disable react/prop-types */

import React from 'react';

const Box = (props) => {
    const { children, height, width, onClick } = props;

    return (
        <div
            className="with-shadows"
            style={{ borderRadius: 2, height, width, cursor: 'pointer' }}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

Box.defaultProps = {
    height: 80,
    width: 80,
};


export const BoxContent = (props) => {
    const { icon, text, active } = props;

    return (
        <div
            className="d-flex w-100 h-100 flex-column justify-content-center align-items-center"
            style={{
                flexGrow: 1,
                color: active ? '#616161' : '#bbb',
            }}
        >
            <div className="d-flex flex-column">
                <div style={{ textAlign: 'center' }}>{icon}</div>
                <div className="mt-1" style={{ textAlign: 'center' }}>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default Box;
