import moment from 'moment';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import CONSTANTS from '../../../../legacy/Constants';
import { ControlsConstants as K } from './Controls.constants';

const initialState = {
    oee2: true,
    sku_oee: false,
    showHelpTooltips: true,
    initialized: false,
    streaming: false,
    startDate: moment().subtract(1, 'day'),
    endDate: moment(),
    resolution: CONSTANTS.RESOLUTIONS[6],
    span: '1 day',
};

export const ControlsReducer = (
    state = initialState,
    action
) => {
    if (action.type === K.ACTIONS.SET_CONTROL_PANEL_STATE) {
        return updateStoreRedux(state, {
            ...action.payload,
        });
    }

    if (action.type === K.ACTIONS.TOGGLE_OEE2) {
        return updateStoreRedux(state, {
            oee2: !state.oee2,
        });
    }

    if (action.type === K.ACTIONS.TOGGLE_OEE_SKU) {
        return updateStoreRedux(state, {
            sku_oee: !state.sku_oee,
        });
    }

    // return state;
    return updateStoreRedux(state, {});
};
