import { Carousel } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './AssetProductionSummaryCarousel.scss';
import { NoData } from '../../../components/None';
import VTable, { CustomFilterDropdown, CustomFilterIcon } from '../../../components/VTable';
import { regexMatch, sortAscAlphabet, uiDatetimeFormat } from '../../../utils/helpers';
import { EyeOutlined } from '@ant-design/icons';
import { isEmpty, round } from 'lodash';
import moment from 'moment';
import { getChartModifiedOutput, getSkuChartModifiedOutput, getSkuChartModifier, hasSkuOutput } from './helpers';

const AssetProductionSummaryCarousel = (props) => {
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current && props.selectedIndex !== null) {
            ref.current.goTo(props.selectedIndex)
        }
    }, [ref, props.selectedIndex])

    return (
        <Carousel ref={ref}>
            {
                props.charts
                    .map((chart, i) => {
                        return <Slide key={i} chart={chart} data={props.data} total={chart.dataSource.data.total}/>
                    })
            }
        </Carousel>
    )
}

export default AssetProductionSummaryCarousel;

const CarouselContentWrapper = (props) => {
    return <div className="carousel-content-wrapper">{props.children}</div>
}

const Slide = (props) => {
    const [selection, setSelection] = useState(null);

    const summaryData = useMemo(() => props.data.aggregated[props.chart.chart_id], [props.chart, props.data])
    const listData = useMemo(() => props.data.raw[selection] || [], [selection, props.data])
    return (
        <CarouselContentWrapper>
                <SkuSummary onSelect={setSelection} chart={props.chart} data={summaryData}/>
                <SkuList chart={props.chart} data={listData} />
        </CarouselContentWrapper>
    );
}

const SkuSummary = (props) => {
    if (isEmpty(props.data)) {
        return (
            <NoData
                className="h-50"
                description="No production tagged in this period"
            />
        )
    }

    return (
        <div className="h-50 w-100">
            <h6>{props.chart.title}</h6>
            <VTable
                className="sku-summary-table"
                rowHeight={42}
                columns={[
                    {
                        title: "SKU",
                        dataIndex: 'label',
                        sorter: (a, b) => sortAscAlphabet(a, b, (v) => v.label),
                        onFilter: (value, record) => regexMatch(record.label, value, { escape: true }),
                        filterDropdown: CustomFilterDropdown,
                        filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
                        width: 60,
                    },
                    {
                        title: "Quantity",
                        render: (text, record, index) => `${round(record.quantity, 2).toLocaleString()} pcs`,
                        sorter: (a, b) => a.quantity < b.quantity,
                        width: 60,
                    },
                    {
                        title: "Occur.",
                        dataIndex: 'occurrence',
                        sorter: (a, b) => a.occurrence < b.occurrence,
                        width: 30,
                    },
                    {   
                        title: '',
                        width: 20,
                        render: (text, record, index) => <EyeOutlined onClick={() => props.onSelect(record.id)}/>
                    },
                ]}
                dataSource={props.data}
                rowKey={i => i}
            />
        </div>
    )
}

const SkuList = (props) => {
    if (!props.data?.length) {
        return (
            <NoData
                className="h-50"
                description="Select an SKU to inspect"
            />
        )
    }

    const title = props.data[0].sku ? props.data[0].sku.codeName : '<SKU Unlabelled>';

    return (
        <div className="h-50 w-100">
            <div>{title}</div>
            <VTable
                className="sku-list-table"
                rowHeight={42}
                columns={[
                    {
                        title: "Tagged",
                        render: (text, record, index) => `${moment(record.from).format(uiDatetimeFormat)} - ${record.to ? moment(record.to).format(uiDatetimeFormat) : 'On-going'}`,
                        width: 60,
                    },
                    {
                        title: "Duration",
                        render: (text, record, index) => `${round(record.duration / 3600, 1).toLocaleString()} hr`,
                        sorter: (a, b) => (a.duration < b.duration),
                        width: 40,
                    },
                    {   
                        title: 'x',
                        width: 20,
                        render: (text, record, index) => `${getSkuChartModifier(props.chart, record.skuChartModifier)}x`
                    },
                    {   
                        title: 'Quantity',
                        width: 40,
                        render: (text, record, index) => {
                            if (hasSkuOutput(props.chart, record)) {
                                return round(getSkuChartModifiedOutput(props.chart, record.chartOutput[props.chart.chart_id], record.skuChartModifier[props.chart.chart_id]),2)
                            }

                            return round(getChartModifiedOutput(props.chart, record.chartOutput ? record.chartOutput[props.chart.chart_id] : 0),2)
                        }
                    },
                ]}
                dataSource={props.data}
                rowKey={i => i}
            />
        </div>
    )
}