import "./style.scss"
import cx from "classnames"
import React from "react"
import Divider from "@mui/material/Divider"

const VerticalDivider = (props: Props) => {
    return <Divider orientation="vertical" className={cx("vertical-divider", props.className)} flexItem />
}

interface Props {
    className?: string
}

export default VerticalDivider
