/* eslint-disable react/prop-types */
import React from 'react'

const OutputText = (props) => {
    if (!props.isShowOutput) {
        return null;
    }

    if (props.isNotSupported) {
        return <span className="pl-2"><strong>Output</strong>: Not supported</span>
    }

    const value = props.value ? props.value : 0;
    return <span className="pl-2"><strong>Output</strong>: {value ? value.toLocaleString() : '-'}</span>;
}

export default OutputText
