/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React from 'react';
import * as d3 from 'd3';
import { findDOMNode } from 'react-dom';
import { AutoSizer } from 'react-virtualized';

import { BaseChartTooltip } from './tooltips';
import { isNumber, isEqual } from 'lodash';
import {
    updateHDPICanvas,
    canvasPixelRatio,
    clearCanvas,
    genColor,
    generateContinuousDomain,
} from '../../utils/charts';

import './Charts_V1.scss';

const STYLES = {
    AXIS_THICKNESS: 1,
    AXIS_COLOR: '#222',
    RADIUS: 5,
    COLOR: '#c0000070',
};

class ScatterplotSimple extends React.Component {
    constructor(props) {
        super(props);

        const {
            xTickFormat,
            xTickSize,
            xTicks,
            yTickFormat,
            yTickSize,
            yTicks,
            mode,
        } = props;

        this.x = d3.scaleLinear();
        this.y = d3.scaleLinear();

        this.xAxisGen = d3
            .axisBottom()
            .tickFormat(xTickFormat || null)
            .tickSize(isNumber(xTickSize) ? xTickSize : 0)
            .ticks(isNumber(xTicks) ? xTicks : null)
            .tickSizeOuter(0);

        this.yAxisGen = d3
            .axisLeft()
            .tickFormat(yTickFormat || null)
            .tickSize(isNumber(yTickSize) ? yTickSize : 0)
            .ticks(isNumber(yTicks) ? yTicks : null);

        this.colorToNode = new Map();

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.svg = this.el.select('svg');
        this.g = this.svg.select('g');
        this.xAxis = this.svg.select('.x-axis');
        this.yAxis = this.svg.select('.y-axis');
        this.focusContainer = this.g.select('.focus-container');
        this.focus = this.focusContainer.select('.focus');
        this.overlay = this.g.select('.overlay');
        this.canvas = this.el.select('canvas.main');
        this.hidden = this.el.select('canvas.hidden');
        this.context = this.canvas.node().getContext('2d');

        const custom = document.createElement('custom');
        this.custom = d3.select(custom);

        this.redraw();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.width != this.props.width ||
      prevProps.height != this.props.height ||
      prevProps.data !== this.props.data ||
      !isEqual(prevProps.paths, this.props.paths)
        ) {
            this.redraw();
        }
    }

    redraw() {
        setTimeout(() => {
            this.recalculate();
            this.redrawChartArea();

            const {
                colorToNode,
                redrawCustom,
                redrawCanvas,
                redrawHidden,
                props: { duration },
            } = this;

            redrawCustom();
            d3.timerFlush();
            const timer = d3.timer(function (elapsed) {
                if (elapsed > duration) {
                    timer.stop();
                }
                colorToNode.clear();
                redrawCanvas();
                redrawHidden();
            });
        });
    }

    recalculate() {
        const {
            w,
            h,
            props: {
                xDomain,
                yDomain,
                xAccessor,
                yAccessor,
                data,
                colorScale,
                colorDomain,
            },
        } = this;

        const xRange = [0, w];
        const yRange = [h, 0];

        const domainX = xDomain || generateContinuousDomain(data, xAccessor, 1.2);
        const domainY = yDomain || generateContinuousDomain(data, yAccessor, 1.2);

        this.x.range(xRange).domain(domainX);
        this.y.range(yRange).domain(domainY);

        this.xAxisGen = this.xAxisGen.scale(this.x);
        this.yAxisGen = this.yAxisGen.scale(this.y);

        this.colorScale = colorScale ? colorScale.domain(colorDomain) : null;
    }

    redrawChartArea() {
    // axes and canvas dimensions
        const {
            w,
            h,
            xAxisGen,
            yAxisGen,
            props: { margin, yAxisLabel, xAxisLabel, transition },
        } = this;

        const t = transition || null;

        this.xAxis.call(xAxisGen);
        this.yAxis.call(yAxisGen);

        this.yAxis
            .select('.axis-label')
            .text(yAxisLabel)
            .attr('transform', `rotate(-90)`)
            .attr('x', -h / 2)
            .attr('y', -margin.left * 0.6)
            .attr('text-anchor', 'middle');

        this.xAxis
            .select('.axis-label')
            .text(xAxisLabel)
            .attr('x', w / 2)
            .attr('y', margin.bottom / 2);

        updateHDPICanvas(this.canvas.node(), this.w, this.h);
        updateHDPICanvas(this.hidden.node(), this.w, this.h);
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                xAccessor,
                yAccessor,
                colorAccessor,
                colorScale,
                xDomain,
                generatePathData,
                paths,
            },
            x,
            y,
            h,
            w,
            custom,
        } = this;
        
        const t = transition || null;

        const self = this;
        this.overlay
            .on('mousemove', function () {
                const newEvent = new Event('mousemove', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                });
                newEvent.pageX = d3.event.pageX;
                newEvent.clientX = d3.event.clientX;
                newEvent.pageY = d3.event.pageY;
                newEvent.clientY = d3.event.clientY;
                newEvent.view = window;
                self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            });

        this.hidden
            .on('mousemove', function () {
                self.handleMouseMove(this);
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            });

        // lines
        if (paths && paths.length) {
            const line_generator = d3
                .line()
                .x((d) => x(d[0]))
                .y((d) => y(d[1]));

            const lineSelection = custom.selectAll('.path').data(
                paths.map((p) => generatePathData(p, line_generator)),
                (d) => d.id
            );

            lineSelection
                .enter()
                .append('custom')
                .attr('class', 'path')
                .attr('stroke', '#545f66bb')
                .attr('d', (d) => d.data);

            lineSelection
                .transition(t)
                .duration(duration)
                .attr('d', (d) => d.data);

            lineSelection.exit().remove();
        }

        
        // dots
        const dotSelection = custom
            .selectAll('.dot')
            .data(data, (d, i) => `${i}${xAccessor(d)}${yAccessor(d)}`);

        const fill = (d) =>
            colorScale ? colorScale(colorAccessor(d)) : '#0B4F6C50';

        const cx = (d) => x(xAccessor(d));
        const cy = (d) => y(yAccessor(d));

        dotSelection
            .enter()
            .append('custom')
            .attr('class', 'dot')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('r', STYLES.RADIUS)
            .attr('fill', fill);

        dotSelection
            .transition(t)
            .duration(duration)
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('fill', fill);

        dotSelection.exit().transition().duration(0).remove();
    }

    redrawCanvas() {
        const {
            props: { xAccessor, yAccessor, paths },
            x,
            y,
            w,
            h,
            canvas,
            custom,
        } = this;

        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        // dots
        const dotSelection = custom.selectAll('.dot');
        dotSelection.each(function (d, i) {
            const node = d3.select(this);
            context.beginPath();
            context.arc(
                node.attr('cx'),
                node.attr('cy'),
                node.attr('r'),
                2 * Math.PI,
                false
            );
            context.fillStyle = node.attr('fill');
            context.fill();
            context.closePath();
        });

        if (paths && paths.length) {
            const lineSelection = custom.selectAll('.path');

            lineSelection.each(function (d, i) {
                const node = d3.select(this);
                context.save();
                d.lineDash && context.setLineDash(d.lineDash);
                context.beginPath();
                const p = new Path2D(d.data);
                context.lineWidth = 2;
                context.strokeStyle = node.attr('stroke');
                context.stroke(p);
                context.restore();
            });
        }
    }

    redrawHidden() {
        const { w, h, x, colorToNode, hidden, custom } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const dotSelection = custom.selectAll('.dot');
        dotSelection.each(function (d, i) {
            const node = d3.select(this);
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.beginPath();
            hiddenContext.arc(
                node.attr('cx'),
                node.attr('cy'),
                node.attr('r'),
                2 * Math.PI,
                false
            );
            hiddenContext.fillStyle = color;

            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    getNodeDatum(DOMnode) {
        const { clientX, clientY } = window.event;
        const [mouseX, mouseY] = d3.mouse(DOMnode);
        const hiddenContext = this.hidden.node().getContext('2d');
        const pixelRatio = canvasPixelRatio(hiddenContext);
        const [r, g, b] = hiddenContext.getImageData(
            mouseX * pixelRatio,
            mouseY * pixelRatio,
            1,
            1
        ).data;

        const color = `rgb(${r},${g},${b})`;
        const d = this.colorToNode.get(color);
        return d;
    }

    handleMouseMove(DOMnode) {
        const {
            x,
            h,
            props: { getBandwidth, xAccessor, htmlTooltip, useTooltip, refocus },
        } = this;

        if (useTooltip) {
            const d = this.getNodeDatum(DOMnode);
            if (!d) {
                this.handleMouseLeave();
                return;
            }

            this.showFocus(d);
            this.showTooltip(d);
        }
    }

    handleMouseLeave() {
        if (this.props.useTooltip) {
            this.hideFocus();
            this.hideTooltip();
        }
    }

    showTooltip(d) {
        const { clientX, clientY } = window.event;
        const { htmlTooltip } = this.props;

        const { top, bottom, height, width } = this.tooltip
            .node()
            .getBoundingClientRect();
        const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
        const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

        let dy = clientY + 10;
        let dx = clientX + 10;

        if (dy + height > windowHeight) dy = dy - height;
        if (dx + width > windowWidth) dx = windowWidth - width - 10;

        this.tooltip
            .style('top', `${dy}px`)
            .style('left', `${dx}px`)
            .style('visibility', 'visible')
            .style('opacity', 0.8)
            .html(htmlTooltip && htmlTooltip(d));
    }

    hideTooltip() {
        this.tooltip.style('opacity', 0).style('visibility', 'hidden');
    }

    showFocus(d) {
        const {
            props: { xAccessor, yAccessor, colorAccessor },
            x,
            y,
            focus,
        } = this;

        focus
            .attr('opacity', 1)
            .attr('r', STYLES.RADIUS + 1)
            .attr('cx', x(xAccessor(d)))
            .attr('cy', y(yAccessor(d)))
            .style('stroke', '#ddd');
    }

    hideFocus() {
        this.focus.attr('opacity', 0);
    }

    get w() {
        const {
            margin: { left, right },
            width,
        } = this.props;
        return width - left - right;
    }

    get h() {
        const {
            margin: { top, bottom },
            height,
        } = this.props;
        return height - top - bottom;
    }

    render() {
        const {
            clipId,
            w,
            h,
            props: {
                margin,
                title,
                subtitle,
                height,
                width,
                hideXAxis,
                hideYAxis,
                showYGrid,
            },
        } = this;

        return (
            <div className={`chart-v1 scatterplot ${this.props.className || ''}`}>
                {title ? <div className="chart-v1__title">{title}</div> : null}
                {subtitle ? <div className="chart-v1__subtitle">{subtitle}</div> : ''}
                <div style={{ position: 'relative' }}>
                    <BaseChartTooltip ref={(node) => (this.tooltip = d3.select(node))} />
                    <canvas
                        className="hidden"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            // opacity: 0.5,
                        }}
                    />
                    <canvas
                        className="main"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            // opacity: 0,
                        }}
                    />
                    <svg
                        className="chart-v1__svg"
                        width={width}
                        height={height}
                        style={{ position: 'absolute' }}
                    >
                        <g transform={`translate(${margin.left},${margin.top})`}>
                            <defs>
                                {/* <clipPath id={clipId}>
                  <rect width={w} height={h} />
                </clipPath> */}
                            </defs>
                            <g className="axis x-axis" transform={`translate(0,${h})`}>
                                <text className="axis-label" />
                            </g>
                            <g className="axis y-axis">
                                <text className="axis-label" />
                            </g>
                            <g className="focus-container">
                                <circle className="focus" />
                            </g>
                            <rect className="overlay" height={h} width={w} />
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

ScatterplotSimple.defaultProps = {
    width: 600,
    height: 200,
    margin: { top: 50, left: 50, right: 20, bottom: 100 },
    duration: 200,
    useTooltip: true,
};

export const Scatterplot = (props) => {
    return (
        <AutoSizer>
            {({ width, height }) => (
                <ScatterplotSimple {...props} width={width} height={height} />
            )}
        </AutoSizer>
    );
};
