import React from "react";

const withContext = (Component, contextType) => {
    return function WrapperComponent(props) {
        const context = React.useContext(contextType);

        return <Component {...props} context={context}/>;
    };
};

export default withContext;