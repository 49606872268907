

import { updateStoreRedux } from '../../../legacy/utils/redux';
import { CanvasConstants as K } from './Canvas.constants';

const initialReducer = () => ({
    canvasses: {},
});

export const canvassesReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.RESET_CANVAS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    if (action.type === K.ACTIONS.SET_CANVAS_RESOURCE) {
        const { canvasses } = action.payload;
        return updateStoreRedux(state, { canvasses });
    }

    return updateStoreRedux(state, {});
};
