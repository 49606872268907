/* eslint-disable react/prop-types */
import React from 'react';

import './InputGroupPrepend.scss';

const InputGroupPrepend = props => {
    const { styles, icon, input, className } = props;
    return (
        <div className={`input-group ${className || ''}`} style={(styles && styles.container) || {}}>
            <div className="input-group-prepend" style={(styles && styles.prependGroup) || {}}>
                <div className="input-group-text" style={(styles && styles.text) || {}}>
                    {icon}
                </div>
            </div>
            {input}
        </div>
    )
}

export default InputGroupPrepend;