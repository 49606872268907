import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initialiseAppAction } from "../../../store/actions/auth.action"
import { useAppDispatch } from "../../../store/hooks"

export function connectInitializedApp(Component, AltComponent) {
    return function WrapperComponent(props) {
        const dispatch = useAppDispatch()
        const init = useSelector((rootState) => rootState.app.initialized);

        useEffect(() => {
            void dispatch(initialiseAppAction())
        }, [])

        return init ? (
            <Component {...props} />
        ) : AltComponent ? (
            <AltComponent />
        ) : null;
    };
}
