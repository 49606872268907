import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Inbox from '../Inbox';
import CONSTANTS from '../../Constants';
import { errorFlash } from '../Flash';


const TIME_ELAPSED = 172800; // 2 days
const SystemInbox = (props) => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        fetch(CONSTANTS.CMS_API + 'releases?_sort=created_at:DESC&_limit=4', {
            method: 'GET',
        })
            .then(async (res) => {
                const data = await res.json();
                setContent(data);
            })
            .catch((e) => {
                errorFlash(e);
            });
    }, []);

    const showDot = (items) => {
        if (!items.length) return false;
        const mostRecent = items[0];
        const now = moment();
        const diff = now.diff(moment(mostRecent.created_at), 'seconds');

        return diff <= TIME_ELAPSED;
    };

    return (
        <Inbox
            showDot={showDot}
            items={content}
            titleAccessor={(d) => d.title}
            contentAccessor={(d) => d.description}
        />
    );
};
export default SystemInbox;
