import { store } from '../../store';

export const parseTrackedIssueArguments = (t) => {
    return [
        t.tracked_issue_id,
        t.created_at,
        t.reporter_id,
        t.assignee_id,
        t.priority,
        t.issue_id,
        t.assets ? t.assets.map((a) => (a.asset_id ? a.asset_id : a)) : [],
        t.description,
        t.root_cause,
        t.actions,
        t.title,
        t.status,
        t.target_date,
        t.close_date,
        t.tracked_media,
    ];
};

export class TrackedIssue {
    constructor(
        tracked_issue_id,
        created_at,
        reporter_id,
        assignee_id,
        priority,
        issue_id,
        _assets,
        description,
        root_cause,
        actions,
        title,
        status,
        target_date,
        close_date,
        tracked_media
    ) {
        this.tracked_issue_id = tracked_issue_id;
        this.created_at = created_at;
        this.reporter_id = reporter_id;
        this.assignee_id = assignee_id;
        this.priority = priority;
        this.issue_id = issue_id;
        this._assets = _assets;
        this.description = description;
        this.root_cause = root_cause;
        this.actions = actions;
        this.title = title;
        this.status = status;
        this.target_date = target_date;
        this.close_date = close_date;
        this.tracked_media = tracked_media || [];
    }

    get issue() {
        const {
            issues: { issues: issuesState },
        } = store.getState();
        return issuesState[this.issue_id] || null;
    }

    get assets() {
        const {
            assets: { assets: assetsState },
        } = store.getState();

        return this._assets.map((id) => assetsState[id]).filter((a) => a);
    }

    get assignee() {
        const {
            user: { list: usersState },
        } = store.getState();

        return usersState[this.assignee_id];
    }

    get reporter() {
        const {
            user: { list: usersState },
        } = store.getState();

        return usersState[this.reporter_id];
    }

    clone() {
        return {
            ...this,
            assets: this.assets,
        };
    }
}
