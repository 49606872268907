import _ from 'lodash';
import { store } from '../../store';

export const parseCorrelationArguments = (c) => {
    return [
        c.correlation_id,
        c.title,
        c.category,
        c.max_plot_size,
        c.model_name,
        c.model_parameters,
        c.threshold_type,
        c.threshold,
        c.autoUpdate,
        c.r2,
        c.x,
        c.y,
    ];
};

export class Correlation {
    constructor(
        correlation_id,
        title,
        category,
        max_plot_size,
        model_name,
        model_parameters,
        threshold_type,
        threshold,
        autoUpdate,
        r2,
        x,
        y
    ) {
        this.correlation_id = correlation_id;
        this.title = title;
        this.category = category;
        this.max_plot_size = max_plot_size;
        this.model_name = model_name;
        this.model_parameters = model_parameters;
        this.threshold_type = threshold_type;
        this.threshold = threshold;
        this.autoUpdate = autoUpdate;
        this.r2 = r2;
        this.x = x;
        this.y = y;
    }

    get sourceX() {
        const {
            metadata: { metadata: metadataState },
        } = store.getState();

        return metadataState[this.x];
    }

    get sourceY() {
        const {
            metadata: { metadata: metadataState },
        } = store.getState();

        return metadataState[this.y];
    }

    get assetX() {
        return this.sourceX.ofAsset;
    }

    get assetY() {
        return this.sourceY.ofAsset;
    }
}
