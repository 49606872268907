import moment from 'moment';
import CONSTANTS from '../Constants';

const SESSION_VALID_X = 3;
const SESSION_VALID_PERIOD = 'days';

class Session {
    authenticate(data) {
        localStorage.clear();
        localStorage.setItem(CONSTANTS.SESSION_TOKEN, data.token);
        localStorage.setItem(CONSTANTS.USER_ID, data.user_id);
        localStorage.setItem(CONSTANTS.USER_ENTITY_ID, data.entity_id);
        localStorage.setItem(CONSTANTS.USER_ENTITY_DENY_ACCESS, data.deny_access);
        localStorage.setItem(CONSTANTS.TIME_AUTHENTICATED, new Date());
    }

    get isExpired() {
        const timestamp = localStorage.getItem(CONSTANTS.TIME_AUTHENTICATED);

        if (!timestamp) return false;

        const expires = moment(timestamp).add(
            SESSION_VALID_X,
            SESSION_VALID_PERIOD
        );
        const now = new moment();

        return now.isAfter(expires);
    }

    get isAuthenticated() {
        return this.token;
    }

    get user_id() {
        return localStorage.getItem(CONSTANTS.USER_ID);
    }

    get entity_id() {
        return localStorage.getItem(CONSTANTS.USER_ENTITY_ID);
    }

    get token() {
        return localStorage.getItem(CONSTANTS.SESSION_TOKEN);
    }

    replaceToken(token) {
        localStorage.setItem(CONSTANTS.SESSION_TOKEN, token);
    }

    replaceEntityId(entityId) {
        localStorage.setItem(CONSTANTS.USER_ENTITY_ID, entityId);
    }

    get registeredEntity() {
        return localStorage.getItem(CONSTANTS.USER_ENTITY_ID) !== 'null';
    }

    get denyAccess() {
        return localStorage.getItem(CONSTANTS.USER_ENTITY_DENY_ACCESS) === 'true';
    }

    logout() {
        localStorage.clear();
    }
}

export const session = new Session();
