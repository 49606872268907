import React, { useCallback, useContext, useEffect } from "react"
import BlockRow from "./BlockRow"
import BlockRowHeader from "./BlockRowHeader"
import { useParams } from "react-router-dom"
import useBlock from "../../../hooks/useBlock"
import { BlockContent, BlockContentList, BlockContentMain } from "./BlockContent"
import BlockModel from "../../../api/models/block.model"
import { useAppSelector } from "../../../store/hooks"
import OeeAggregateChart from "./OeeAggregateChart"
import { eventEmitter } from "../../../legacy/auxStore"
import CONSTANTS from "../../../legacy/Constants"
import { BlockPageContext } from "../BlockPageContext"
import withStreaming, { ControlsRenderProps } from "../../../lib/hoc/withStreaming"
import { generateUrlQuery } from "../../../legacy/utils/url"
import OeeLegendPopover from "./OeeLegendPopover"

const OeeAggregateTab = (props: Props) => {
    const { getOeeData } = useContext(BlockPageContext)
    const { blockId } = useParams()
    const block = useBlock(+(blockId as unknown as number))
    const blocksOee = useAppSelector(appState => appState.ui.block.oee)
    const showOee2 = useAppSelector(appState => appState.ui.controls.oee2)

    const getBlockAggregateOee = useCallback((id: number) => {
        const data = blocksOee[id]
        if (!data) return []
        return showOee2 ? [data.aggregate_oee2] : [data.aggregate_oee]
    }, [blocksOee, showOee2])

    useEffect(() => {
        const refreshHandler = eventEmitter.on(
            CONSTANTS.EVENTS.REFRESH, 
            () => { getOeeData(props.controls) }
        )

        return () => refreshHandler()

    }, [generateUrlQuery(props.controls), props.controls.sku_oee])

    return <BlockContent>
        <BlockContentMain>
            <BlockRow 
                block={block}
                header={
                    <BlockRowHeader 
                        block={block} 
                        addOnAfter={<OeeLegendPopover/>}
                    />
                }
                chart={
                    <OeeAggregateChart height={"140px"} data={getBlockAggregateOee(block.blockId)}/>
                }
            />
        </BlockContentMain>
        <hr/>
        <BlockContentList>
            {
                (block.children as BlockModel[]).map(b => <BlockRow 
                    key={b.blockId} 
                    block={b} 
                    header={<BlockRowHeader block={b} withNavigate={true}/>}
                    chart={
                        <OeeAggregateChart height={"100px"} data={getBlockAggregateOee(b.blockId)}/>
                    }
                />)
            }
        </BlockContentList>
    </BlockContent>
}

interface Props extends ControlsRenderProps {}

export default withStreaming(OeeAggregateTab)