import { createSelector } from "reselect"
import { RootState } from "../index"

export const selectObjectiveViewMode = createSelector([(state: RootState) => state.goals.viewMode], (viewMode) => viewMode)

export const selectObjective = createSelector([(state: RootState) => state.goals.viewing], (viewing) => viewing)

export const selectObjectiveLoading = createSelector([(state: RootState) => state.goals.loading], (loading) => loading)

export const selectObjectiveOee = createSelector([(state: RootState) => state.goals.oee], (oeeMap) => oeeMap)

export const selectObjectiveGoals = createSelector([(state: RootState) => state.goals.goals], goals => goals)

export const selectObjectiveOriginalGoals = createSelector([(state: RootState) => state.goals.goalsOriginal], goalsOriginal => goalsOriginal)

export const selectCurrentProcess = createSelector([(state: RootState) => state.goals.currentProcess], currentProcess => currentProcess)
