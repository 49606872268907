import React, { type HTMLProps, useState, useCallback, useMemo } from "react"
import { Chip, Dialog, DialogContent, DialogTitle } from "@mui/material"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import { LanguageConstants } from "../../../constants/languages.constants"
import classNames from "classnames"

import "./style.scss"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { changeUserLanguage } from "../../../store/old/Preference/Preference.action"
import { languagePreference } from "../../../store/old/Preference/Preference.selector"

const LanguageButton = (props: Props) => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const language = useAppSelector(languagePreference)

    const isSelected = useCallback(
        (key: string) => key === language,
        [language]
    )

    const label = useMemo(() => LanguageConstants.find(opt => opt.value === language)?.nativeName, [language])

    return <>
        <Chip
            variant="outlined"
            className={props.className}
            icon={<LanguageOutlinedIcon/>}
            label={label}
            clickable
            onClick={() => { setOpen(true) }}
        />
        <Dialog onClose={() => { setOpen(false) }} open={open} fullWidth>
            <DialogTitle>Language</DialogTitle>
            <DialogContent className="language-select__body">
                {LanguageConstants.map((opt, i) => {
                    return (
                        <div
                            key={i}
                            className={classNames({
                                "language-select__item": true,
                                selected: isSelected(opt.value)
                            })}
                            onClick={() => dispatch(changeUserLanguage(opt.value))}
                        >
                            <div>{opt.nativeName}</div>
                        </div>
                    )
                })}
            </DialogContent>
        </Dialog>
    </>
}

export default LanguageButton

interface Props {
    className?: HTMLProps<HTMLElement>["className"]
    language?: string
}
