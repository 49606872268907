import { put, takeLatest } from 'redux-saga/effects';
import { getAllUsersSuccess, getAllUsersFailure, changeUserRoleFailure, deleteUserFailure } from './User.action';
import { api_getAllUsers, generateListUser, api_changeUserRole, api_deleteUser } from './User.services';
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

// CONSTANT
import { UserConstants as K } from './User.constants';
import { errorFlash } from '../../../legacy/components/Flash';

// GET ENTITY SERVICE
function* handleGetAllUsers(data) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield api_getAllUsers(
            entity_id,
            data.payload.query
        );
        const listUsers = generateListUser(response);
        yield put(getAllUsersSuccess(listUsers));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(getAllUsersFailure(error));
    }
}

export function* getAllUsers() {
    yield takeLatest(
        K.ACTIONS.FETCH_USERS_SYSTEM_REQUEST,
        handleGetAllUsers
    );
}

// CHANGE USER ROLE
function* handleChangeUserRole(data) {
    try {
        const currentState = store.getState().user.list;
        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield api_changeUserRole(
            entity_id,
            data.payload.single
        );

        const user = data.payload.single;

        const newState = {
            ...currentState,
            [user.user_id]: user,
        };

        yield put(getAllUsersSuccess(newState));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(changeUserRoleFailure(error));
    }
}

export function* changeUserRole() {
    yield takeLatest(
        K.ACTIONS.CHANGE_USER_ROLE_REQUEST,
        handleChangeUserRole
    );
}

// DELETE USER
function* handleDeleteUser(action) {
    try {
        const currentState = store.getState().user.list;

        const cloneState = Object.assign({}, currentState);

        const response = yield api_deleteUser(action.payload.entity_id, action.payload.user_id);

        delete cloneState[action.payload.user_id];

        yield put(getAllUsersSuccess(cloneState));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(deleteUserFailure(error));
    }
}

export function* deleteUser() {
    yield takeLatest(
        K.ACTIONS.DELETE_USER_REQUEST,
        handleDeleteUser
    );
}
