export const DataConstants = {
    ACTIONS: {
        FETCH_ASSET_CHARTS_DATA_REQUEST: 'FETCH_ASSET_CHARTS_DATA_REQUEST',
        FETCH_ASSET_OEE_DATA_REQUEST: 'FETCH_ASSET_OEE_DATA_REQUEST',
        FETCH_ASSET_OPERATOR_DATA_REQUEST: 'FETCH_ASSET_OPERATOR_DATA_REQUEST',
        FETCH_BLOCK_OEE_DATA_REQUEST: 'FETCH_BLOCK_OEE_DATA_REQUEST',
        FETCH_SUMMARY_TILE_DATA_REQUEST: 'FETCH_SUMMARY_TILE_DATA_REQUEST',
        FETCH_TILE_DATA_AND_LABELS_REQUEST: 'FETCH_TILE_DATA_AND_LABELS_REQUEST',
        ADD_BLOCKS_OEE_DATA_RESOURCE: 'ADD_BLOCKS_OEE_DATA_RESOURCE',
        ADD_TIME_SERIES_DATA_RESOURCE: 'ADD_TIME_SERIES_DATA_RESOURCE',
        ADD_SUMMARY_TILES_OEE_DATA_RESOURCE: 'ADD_SUMMARY_TILES_OEE_DATA_RESOURCE',
        CLEAR_DATA_RESOURCE: 'CLEAR_DATA_RESOURCE',
    },
};
