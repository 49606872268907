import "./ToggleButtons.scss"
import { type SxProps, ToggleButtonGroup } from "@mui/material"
import classNames from "classnames"
import React from "react"

const ToggleButtons = (props: Props) => {
    return (
        <ToggleButtonGroup
            className={classNames("auk-toggle-buttons", props.className)}
            color="primary"
            value={props.value}
            exclusive
            onChange={(event, value) => {
                if (props.nullable) {
                    props.onChange(event, value)
                    return
                }

                value !== null && props.onChange(event, value)
            }}
            sx={{
                width: "100%",
                height: "24px",
                borderRadius: "100px 0px 0px 100px",
                ".MuiToggleButton-primary": { width: "50%", color: "#001E2D !important", fontWeight: 500, fontSize: 14, letterSpacing: 0.1, textTransform: "none" },
                ".Mui-selected": { backgroundColor: "#C6E7FF !important" },
                ".Mui-selected:hover": { backgroundColor: "rgba(198,231,255,0.4)" },
                ".MuiToggleButton-root:hover": { backgroundColor: "rgba(198,231,255,0.4)" },
                ".MuiToggleButtonGroup-firstButton": { borderTopLeftRadius: 100, borderBottomLeftRadius: 100 },
                ".MuiToggleButtonGroup-lastButton": { borderTopRightRadius: 100, borderBottomRightRadius: 100 },
                ...props.sx
            }}
        >
            {props.children}
        </ToggleButtonGroup>

    )
}

interface Props {
    className?: string
    value: string
    onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void
    children?: React.ReactNode
    sx?: SxProps
    nullable?: boolean
}

export default ToggleButtons
