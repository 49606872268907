/* eslint-disable react/prop-types */

import React from 'react';
import { AutoSizer } from 'react-virtualized';

import './AutosizeText.scss';

const AutosizeText = (props) => {
    return (
        <div
            className={`autosize-text-wrapper ${props.className || ''}`}
            style={props.style}
        >
            <AutoSizer>
                {({ width, height }) => {
                    const { clamps = 1 } = props;
                    const percent = clamps > 1 ? 0.6 : 0.8;
                    const fontSize = Math.round((height / clamps) * percent);

                    return (
                        <div
                            className="autosize-text"
                            style={{ height, width, fontSize, WebkitLineClamp: clamps }}
                        >
                            {props.children}
                        </div>
                    );
                }}
            </AutoSizer>
        </div>
    );
};

export default AutosizeText;
