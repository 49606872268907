import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { WindowConstants as K } from './Window.constants';

const initialState = {
    visibility: true,
};

export const WindowReducer = (
    state = initialState,
    action
) => {
    if (action.type === K.ACTIONS.VISIBILITY_CHANGE) {
        return updateStoreRedux(state, {
            visibility: !state.visibility,
        });
    }

    return updateStoreRedux(state, {});
};
