import { updateStoreRedux } from '../../../legacy/utils/redux';
import { LabelConstants as K } from './Labels.constants';

const initialReducer = () => ({
    labels: {},
    by_block: {},
});

export const labelsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_LABELS_STATE) {
        const { labels, by_block } = action.payload;
        return updateStoreRedux(state, { labels, by_block });
    }

    if (action.type === K.ACTIONS.RESET_LABELS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    if (action.type === K.ACTIONS.SET_LABELS_RESOURCE) {
        const { labels } = action.payload;
        return updateStoreRedux(state, { labels });
    }

    if (action.type === K.ACTIONS.ADD_LABELS_RESOURCE) {
        const labels = { ...state.labels, ...action.payload.labels };
        return updateStoreRedux(state, { labels });
    }

    if (action.type === K.ACTIONS.REPLACE_LABEL_RESOURCE) {
        const { updated, original_label_id } = action.payload;
        const labels = { ...state.labels };
        delete labels[original_label_id];
        labels[updated.label_id] = updated;

        return updateStoreRedux(state, { labels });
    }

    if (action.type === K.ACTIONS.REMOVE_LABEL_RESOURCE) {
        const { payload: delete_id } = action;
        const deleteResult = { ...state.labels };
        delete deleteResult[delete_id];
        return updateStoreRedux(state, {
            labels: deleteResult,
        });
    }

    return updateStoreRedux(state, {});
};
