/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
// LIBRARIES
import { capitalize, isEqual } from 'lodash';
import React from 'react';
import moment from 'moment';

// COMPONENTS
import DaysOfWeekTabs from './DaysOfWeekTabs';
import LabelWrapper from '../../../components/LabelWrapper';

// HELPERS
import { regexMatch, uiDatetimeFormat } from '../../../utils/helpers';
import {
    getFormErrors,
    isRequiredField,
    validateSingleField,
} from '../../../utils/validate';
import { getOrdinalWeekOfMonth, getWeekOfMonth } from '../../../utils/date';

// STYLES
import './Scheduler.scss';
import {
    flash,
    generateValidationErrorDetails,
} from '../../../components/Flash';
import { TimePicker, DatePicker, Input, Select } from 'antd';
import FormModal from '../../../components/FormModal';
import translate from '../../../utils/translate';

const { RangePicker } = DatePicker;
const { RangePicker: TimeRangePicker } = TimePicker;

const floorSeconds = (m) => m.set({ seconds: 0, milliseconds: 0 });

const FIELDS = {
    NAME: 'name',
    RECURRING: 'recurring',
    RECUR_START: 'recur_start',
    RECUR_END: 'recur_end',
    FROM: 'from',
    TO: 'to',
    ASSETS: 'assets',
    RADIO_RECUR_END: 'radio_recur_end',
    FREQUENCY_X: 'x',
    FREQUENCY_PERIOD: 'period',
    CUSTOM_RECUR_WEEKLY: 'custom_recur_weekly',
    CUSTOM_RECUR_MONTHLY: 'custom_recur_monthly',
};

const SCHEDULE_VALIDATE = {
    RECURRING: {
        [FIELDS.NAME]: [
            {
                err: 'Name is required.',
                assessor: (d) => isRequiredField(d.name),
            },
        ],
        [FIELDS.FROM]: [
            {
                err: 'Start time is required.',
                assessor: (d) => isRequiredField(d.from),
            },
            {
                err: 'Start date cannot be earlier than now.',
                assessor: (d) => {
                    const recurStart = d.recur_start && d.recur_start.clone();
                    if (!recurStart) return false;

                    const hour = d.from.get('hour');
                    const minute = d.from.get('minute');

                    recurStart.set({ hour, minute });
                    return recurStart.isBefore(moment());
                },
            },
        ],
        [FIELDS.TO]: [
            {
                err: 'End time is required.',
                assessor: (d) => isRequiredField(d.from),
            },
        ],
        [FIELDS.RECUR_START]: [
            {
                err: 'Start date is required.',
                assessor: (d) => isRequiredField(d.recur_start),
            },
        ],
        [FIELDS.RECUR_END]: [
            {
                err: 'End date must be later than start date.',
                assessor: (d) => {
                    if (!d.radio_recur_end) return false;
                    return !(
                        moment.isMoment(d.recur_start) &&
            moment.isMoment(d.recur_end) &&
            d.recur_start.isBefore(d.recur_end)
                    );
                },
            },
        ],
        [FIELDS.FREQUENCY_X]: [
            {
                err: 'Frequency (magnitude) must be a positive integer',
                assessor: (d) => !(isFinite(d.x) && d.x > 0),
            },
        ],
        [FIELDS.FREQUENCY_PERIOD]: [
            {
                err: 'Frequency (period) is required',
                assessor: (d) => isRequiredField(d.period),
            },
        ],
        [FIELDS.CUSTOM_RECUR_WEEKLY]: [
            {
                err: 'Frequency (weekly) is required',
                assessor: (d) => {
                    if (d.period !== 'weeks') return false;
                    return !d.custom_recur_weekly.length;
                },
            },
        ],
        [FIELDS.CUSTOM_RECUR_MONTHLY]: [
            {
                err: 'Frequency (monthly) is required',
                assessor: (d) => {
                    if (d.period !== 'months') return false;
                    return !d.custom_recur_monthly;
                },
            },
        ],
    },
    NON_RECURRING: {
        [FIELDS.NAME]: [
            {
                err: 'Name is required.',
                assessor: (d) => isRequiredField(d.name),
            },
        ],
        [FIELDS.FROM]: [
            {
                err: 'Start time is required.',
                assessor: (d) => isRequiredField(d.from),
            },
            {
                err: 'Start time cannot be earlier than now.',
                assessor: (d) => d.from.isBefore(moment()),
            },
        ],
        [FIELDS.TO]: [
            {
                err: 'End time is required.',
                assessor: (d) => isRequiredField(d.from),
            },
            {
                err: 'Shift duration cannot be longer than 48 hours',
                assessor: (d) => {
                    if (d.type !== 'shift') return false;
                    return (
                        moment.duration(moment(d.to).diff(moment(d.from))).as('hours') > 48
                    );
                },
            },
        ],
    },
};

const _generateMonthlyRecurOptions = (recurStart) => {
    const startDate = recurStart.clone();
    const date = +startDate.format('D');
    const day = +startDate.format('E');
    const dayName = startDate.format('dddd');
    const { week } = getWeekOfMonth(startDate);

    const ordinalWeek = getOrdinalWeekOfMonth(week, startDate);

    return [
        {
            label: `Monthly on day ${date}`,
            value: JSON.stringify({ date }),
        },
        {
            label: `Monthly on the ${ordinalWeek} ${dayName}`,
            value: JSON.stringify({ day, week }),
        },
    ];
};

const REPEATS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const PERIODS = [
    { value: 'days', label: 'day' },
    { value: 'weeks', label: 'week' },
    { value: 'months', label: 'month' },
    { value: 'years', label: 'year' },
];

export default class Scheduler extends React.Component {
    constructor(props) {
        super(props);

        const { type } = props;

        this.nameAttr = `${type}_name`;

        this.state = {
            recurring: false,
            recur_start: null,
            recur_end: null,
            from: null,
            to: null,
            radio_recur_end: false,
            assets: [],
            name: '',
            x: 1,
            period: null,
            custom_recur_weekly: [],
            custom_recur_monthly: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
    // change frequency selection when new date toggled
        const { recur_start, recurring, period } = this.state;

        const isMonthlyRecur = recurring && period === 'months';
        if (isMonthlyRecur && !isEqual(prevState.recur_start, recur_start)) {
            return this.setState({ custom_recur_monthly: null });
        }
    }

    formErrors(fields) {
        return getFormErrors(fields, this.validator);
    }

    get recurFields() {
        const {
            assets,
            from,
            to,
            name,
            x,
            period,
            recur_start,
            custom_recur_weekly,
            custom_recur_monthly,
            radio_recur_end,
            recur_end,
        } = this.state;

        const { type } = this.props;

        return {
            assets,
            from,
            to,
            name,
            x,
            period,
            recur_start,
            custom_recur_weekly,
            custom_recur_monthly,
            radio_recur_end,
            recur_end,
            type,
        };
    }

    get nonrecurFields() {
        const { assets, from, to, name } = this.state;
        const { type } = this.props;
        return { assets, from, to, name, type };
    }

    get recurFormData() {
    // for input field validation, form submission
        const {
            recurring,
            from,
            to,
            recur_start,
            recur_end,
            custom_recur_monthly,
            custom_recur_weekly,
            assets,
            period,
            x,
            name,
        } = this.state;

        let frequency;
        if (period === 'months') {
            frequency = { period, x, ...custom_recur_monthly };
        } else if (period === 'weeks') {
            frequency = { period, x, days: custom_recur_weekly };
        } else {
            // years
            frequency = {
                period,
                x,
                month: +recur_start.format('M'),
                date: +recur_start.format('D'),
            };
        }

        return {
            assets,
            frequency,
            from: floorSeconds(from).toISOString(),
            to: floorSeconds(to).toISOString(),
            recurring,
            recur_start: recur_start ? floorSeconds(recur_start).toISOString() : null,
            recur_end: recur_end ? floorSeconds(recur_end).toISOString() : null,
            [this.nameAttr]: name,
        };
    }

    get nonRecurFormData() {
    // for input field validation, form submission
        const { from, to, assets, name, recurring } = this.state;

        return {
            recurring,
            assets,
            from: floorSeconds(from).toISOString(),
            to: floorSeconds(to).toISOString(),
            [this.nameAttr]: name,
        };
    }

    get validator() {
        return this.state.recurring
            ? SCHEDULE_VALIDATE.RECURRING
            : SCHEDULE_VALIDATE.NON_RECURRING;
    }

    flashErrors(errors) {
        flash({
            message: 'Please check invalid / incomplete fields',
            details: generateValidationErrorDetails(errors),
            status: 'warning',
        });
    }

    submit() {
        const { recurring } = this.state;
        const formFields = recurring ? this.recurFields : this.nonrecurFields;

        const errors = this.formErrors(formFields);

        if (errors.length) return this.flashErrors(errors);

        const formData = recurring ? this.recurFormData : this.nonRecurFormData;
        this.props.submit && this.props.submit(formData);
    }

    handleChange(arr) {
        const result = arr
            .map(([field, value]) => ({ [field]: value }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});
        this.setState(result);
    }

    get disabledDate() {
        return (d) => d.isBefore(this.startOfDay);
    }

    get startOfDay() {
        const { timezone } = this.props.entity;
        return moment().utcOffset(timezone).startOf('day');
    }

    render() {
        const assetsList = this.props.assetOptions.map(
            ({ asset_id, asset_name }) => ({
                value: asset_id,
                label: asset_name,
            })
        );

        const { validator, disabledDate, handleChange } = this;
        const { type, cancel } = this.props;

        const formProps = {
            ...this.state,
            assetsList,
            disabledDate,
            handleChange,
            validator,
            data: this.state,
            type,
        };

        return (
            <FormModal
                show={true}
                toggle={cancel}
                submit={this.submit}
                title={<>Create {capitalize(type)}</>}
                render={(props) => (
                    <>
                        {this.state.recurring ? (
                            <FormRecurring {...formProps} {...props} />
                        ) : (
                            <FormNonRecurring {...formProps} {...props} />
                        )}
                        <div
                            className="d-flex justify-content-end"
                            style={{ fontSize: 12 }}
                        >
              *{capitalize(type)} will be factored in for future computations
              and will not affect historical datasets.
                        </div>
                    </>
                )}
            />
        );
    }
}

const FormRecurring = (props) => {
    const {
        data,
        data: {
            assets,
            from,
            to,
            name,
            recurring,
            x,
            period,
            recur_start,
            custom_recur_weekly,
            custom_recur_monthly,
            radio_recur_end,
            recur_end,
        },
        handleChange,
        assetsList,
        disabledDate,
        submitted,
        validator,
    } = props;

    const invalidName =
    submitted && validateSingleField(validator, FIELDS.NAME, data).length;
    const invalidStartTime =
    submitted &&
    (validateSingleField(validator, FIELDS.FROM, data).length ||
      validateSingleField(validator, FIELDS.TO, data).length);
    const invalidRecurStart =
    submitted &&
    validateSingleField(validator, FIELDS.RECUR_START, data).length;
    const invalidX =
    submitted &&
    validateSingleField(validator, FIELDS.FREQUENCY_X, data).length;
    const invalidPeriod =
    submitted &&
    validateSingleField(validator, FIELDS.FREQUENCY_PERIOD, data).length;
    const invalidWeekly =
    submitted &&
    validateSingleField(validator, FIELDS.CUSTOM_RECUR_WEEKLY, data).length;
    const invalidMonthly =
    submitted &&
    validateSingleField(validator, FIELDS.CUSTOM_RECUR_MONTHLY, data).length;
    const invalidRecurEnd =
    submitted && validateSingleField(validator, FIELDS.RECUR_END, data).length;

    return (
        <form>
            <NameInput
                isInvalid={invalidName}
                value={name}
                handleChange={(e) => handleChange([[FIELDS.NAME, e.target.value]])}
            />
            <RecurSelect
                value={recurring}
                handleChange={(e) => handleChange([[FIELDS.RECURRING, e]])}
            />
            <RecurringTimeInput
                isInvalid={invalidStartTime}
                value={[from, to]}
                handleChange={([_from, _to]) =>
                    handleChange([
                        [FIELDS.FROM, _from],
                        [FIELDS.TO, _to],
                    ])
                }
            />
            <RecurringStartDateInput
                isInvalid={invalidRecurStart}
                disabledDate={disabledDate}
                value={recur_start}
                handleChange={(e) => handleChange([[FIELDS.RECUR_START, e]])}
            />
            <AssetsMultiSelect
                options={assetsList}
                value={assets}
                handleChange={(e) => handleChange([[FIELDS.ASSETS, e]])}
            />
            <FrequencyInputs
                isInvalidX={invalidX}
                isInvalidPeriod={invalidPeriod}
                value={{ x, period }}
                handleChangeX={(e) =>
                    handleChange([[FIELDS.FREQUENCY_X, e.target.value]])
                }
                handleChangePeriod={(e) => handleChange([[FIELDS.FREQUENCY_PERIOD, e]])}
            />
            {period === 'weeks' && (
                <WeeklyRecurOptions
                    isInvalid={invalidWeekly}
                    value={custom_recur_weekly || []}
                    handleChange={(e) => handleChange([[FIELDS.CUSTOM_RECUR_WEEKLY, e]])}
                />
            )}
            {period === 'months' && (
                <MonthlyRecurOptions
                    isInvalid={invalidMonthly}
                    options={recur_start ? _generateMonthlyRecurOptions(recur_start) : []}
                    value={
                        custom_recur_monthly ? JSON.stringify(custom_recur_monthly) : null
                    }
                    handleChange={(e) =>
                        handleChange([[FIELDS.CUSTOM_RECUR_MONTHLY, JSON.parse(e)]])
                    }
                />
            )}
            <RecurEndOptions
                isInvalid={invalidRecurEnd}
                checked={radio_recur_end}
                value={recur_end}
                disabledDate={disabledDate}
                handleClickRadio={(e) => handleChange([[FIELDS.RADIO_RECUR_END, e]])}
                handleChangeDate={(e) => handleChange([[FIELDS.RECUR_END, e]])}
            />
        </form>
    );
};

const FormNonRecurring = (props) => {
    const {
        data,
        data: { assets, from, to, name, recurring },
        handleChange,
        assetsList,
        submitted,
        disabledDate,
        validator,
        type,
    } = props;

    const validateData = { ...data, type };

    const invalidName =
    submitted && validateSingleField(validator, FIELDS.NAME, data).length;
    const invalidRange =
    submitted &&
    (validateSingleField(validator, FIELDS.FROM, validateData).length ||
      validateSingleField(validator, FIELDS.TO, validateData).length);

    return (
        <form>
            <NameInput
                isInvalid={invalidName}
                value={name}
                handleChange={(e) => handleChange([[FIELDS.NAME, e.target.value]])}
            />
            <RecurSelect
                value={recurring}
                handleChange={(e) => handleChange([[FIELDS.RECURRING, e]])}
            />
            <NonRecurringDatetimeInput
                isInvalid={invalidRange}
                disabledDate={disabledDate}
                value={[from, to]}
                handleChange={([_from, _to]) =>
                    handleChange([
                        [FIELDS.FROM, _from],
                        [FIELDS.TO, _to],
                    ])
                }
            />
            <AssetsMultiSelect
                options={assetsList}
                value={assets}
                handleChange={(e) => handleChange([[FIELDS.ASSETS, e]])}
            />
        </form>
    );
};

const NameInput = (props) => {
    const { value, handleChange, isInvalid } = props;
    return (
        <LabelWrapper label={translate('name')} col={3}>
            <Input
                className={`auk-input ${isInvalid ? 'is-invalid' : ''}`}
                value={value}
                onChange={handleChange}
                maxLength={20}
            />
        </LabelWrapper>
    );
};

const NonRecurringDatetimeInput = (props) => {
    const { isInvalid, value, handleChange, disabledDate } = props;

    return (
        <LabelWrapper label={translate('time')} col={3}>
            <RangePicker
                disabledDate={disabledDate}
                allowClear={false}
                format={uiDatetimeFormat}
                showTime={{ format: 'HH:mm' }}
                className={`auk-range-picker ${isInvalid ? 'is-invalid' : ''}`}
                value={value}
                onChange={handleChange}
            />
        </LabelWrapper>
    );
};

const RecurringTimeInput = (props) => {
    const { value, handleChange, isInvalid } = props;

    return (
        <LabelWrapper label={translate('time')} col={3}>
            <TimeRangePicker
                allowClear={false}
                format="HH:mm"
                className={`auk-timerange-picker ${isInvalid ? 'is-invalid' : ''}`}
                value={value}
                onChange={handleChange}
                order={false}
            />
        </LabelWrapper>
    );
};

const RecurringStartDateInput = (props) => {
    const { value, handleChange, isInvalid, disabledDate } = props;

    return (
        <LabelWrapper label={translate('start')} col={3}>
            <DatePicker
                format={uiDatetimeFormat}
                showTime={{ format: 'HH:mm' }}
                className={`auk-date-picker ${isInvalid ? 'is-invalid' : ''}`}
                allowClear={false}
                value={value}
                onChange={handleChange}
                placeholder={uiDatetimeFormat}
                disabledDate={disabledDate}
            />
        </LabelWrapper>
    );
};

const AssetsMultiSelect = (props) => {
    const { options, value, handleChange } = props;

    return (
        <LabelWrapper label={translate('asset')} col={3}>
            <Select
                className="auk-select"
                mode="multiple"
                showSearch
                options={options}
                value={value}
                onChange={handleChange}
                filterOption={(input, option) =>
                    regexMatch(option.label, input, { escape: true })
                }
                placeholder="Leave blank to apply to all"
            />
        </LabelWrapper>
    );
};

const RecurSelect = (props) => {
    const { value, handleChange } = props;
    return (
        <LabelWrapper label={translate('repeat')} col={3}>
            <Select
                className="auk-select"
                options={REPEATS}
                value={value}
                onChange={handleChange}
            />
        </LabelWrapper>
    );
};

const FrequencyInputs = (props) => {
    const {
        value,
        isInvalidX,
        isInvalidPeriod,
        handleChangeX,
        handleChangePeriod,
    } = props;

    return (
        <LabelWrapper label={translate('every')} col={3}>
            <div className="d-flex">
                <div className="col px-0 pr-1">
                    <Input
                        type="number"
                        className={`auk-input ${isInvalidX ? 'is-invalid' : ''}`}
                        value={value.x}
                        onChange={handleChangeX}
                    />
                </div>
                <div className="col px-0 pl-1">
                    <Select
                        className={`auk-select ${isInvalidPeriod ? 'is-invalid' : ''}`}
                        options={PERIODS}
                        value={value.period}
                        onChange={handleChangePeriod}
                        placeholder="e.g. (weeks)"
                    />
                </div>
            </div>
        </LabelWrapper>
    );
};

const WeeklyRecurOptions = (props) => {
    const { value, handleChange, isInvalid } = props;

    return (
        <LabelWrapper label={''} col={3}>
            <DaysOfWeekTabs
                className={isInvalid ? 'is-invalid' : ''}
                value={value}
                handleChange={handleChange}
            />
        </LabelWrapper>
    );
};

const MonthlyRecurOptions = (props) => {
    const { options, value, isInvalid, handleChange } = props;

    return (
        <LabelWrapper label={''} col={3}>
            <Select
                className={`auk-select ${isInvalid ? 'is-invalid' : ''}`}
                options={options}
                value={value}
                placeholder="Select repeat options"
                onSelect={handleChange}
            />
        </LabelWrapper>
    );
};

const RecurEndOptions = (props) => {
    const {
        checked,
        value,
        disabledDate,
        isInvalid,
        handleClickRadio,
        handleChangeDate,
    } = props;

    return (
        <>
            <LabelWrapper label={translate('end')} col={3}>
                <div className="d-flex align-items-center w-100 h-100">
                    <input
                        className="mr-2"
                        type="radio"
                        value={false}
                        onChange={() => handleClickRadio(false)}
                        checked={!checked}
                    />
          Never
                </div>
            </LabelWrapper>
            <LabelWrapper label=" " col={3}>
                <div className="d-flex flex-row justify-content-end">
                    <div className="d-flex p-0 align-items-center mr-3">
                        <input
                            className="mr-2"
                            type="radio"
                            value={true}
                            onChange={() => handleClickRadio(true)}
                            checked={checked}
                        />
            On
                    </div>
                    <div className="col pr-0">
                        <DatePicker
                            format={uiDatetimeFormat}
                            showTime={{ format: 'HH:mm' }}
                            className={`auk-date-picker ${isInvalid ? 'is-invalid' : ''}`}
                            value={value}
                            onChange={handleChangeDate}
                            placeholder={uiDatetimeFormat}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
            </LabelWrapper>
        </>
    );
};
