import { ArrowDropDown, ArrowRight } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import React, { type MouseEvent } from "react"
import TreeTableCell from "../TreeTableCell/TreeTableCell"

const HIERARCHY_PADDING = 32
const DEFAULT_PADDING = 48
const ProcessCell = (props: Props) => {
    const { node, ...cellProps } = props

    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation()
        props.onToggle(node, !node.isExpanded)
    }

    const isShowDropdown = node.hierarchy !== 1 && node.hasChildren
    const defaultPadding = !isShowDropdown ? DEFAULT_PADDING : 0
    const paddingLeft = (node.hierarchy - 1) * HIERARCHY_PADDING + defaultPadding
    return <TreeTableCell {...cellProps} style={{ paddingLeft }}>
        {isShowDropdown && <IconButton style={{ marginRight: 8 }} onClick={handleClick}>
            {node.isExpanded ? <ArrowDropDown/> : <ArrowRight/>}
        </IconButton>}
        <span style={{ paddingTop: 8, paddingBottom: 8 }}>{node.label}</span>
    </TreeTableCell>
}

interface Props {
    node: Domain.ProcessNode
    onToggle: (node: Domain.ProcessNode, show: boolean) => void
    width?: string | number
    height?: string | number
}

export default ProcessCell
