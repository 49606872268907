import moment from "moment"
import React from "react"
import { uiDatetimeFormatWithSeconds } from "../../../utils/helpers"
import { StopLabelIcon } from "."
import { AnyFunction } from "../../../../types/any"
import { Label } from "../../../models"

const IssueLabelListItem = (props: Props) => {
    return (
        <div className="label-vlist-item d-flex align-items-center px-2 justify-content-between">
            <div className="label-vlist-item__descriptor d-flex flex-column h-100">
                <div className="d-flex">
                    <span
                        className="mr-2"
                        style={{ fontSize: 14, color: props.data.issue.color }}
                    >
                        <i className="fas fa-square" />
                    </span>
                    <span>{props.data.issue.name}</span>
                </div>
                <div style={{ fontSize: 10 }}>
                    {moment(props.data.from).format(uiDatetimeFormatWithSeconds)} -{" "}
                    {props.data.isComplete
                        ? moment(props.data.to).format(uiDatetimeFormatWithSeconds)
                        : "On-going"}
                </div>
            </div>
            {!props.data.isComplete && !props.readOnly && 
                <StopLabelIcon
                    onClick={(e: any) => {
                        e.stopPropagation()
                        props.onStop && props.onStop(props.data)
                    }}
                />
            }
        </div>
    )
}

export default IssueLabelListItem

interface Props {
    data: Label;
    onStop?: AnyFunction;
    // onClick?: AnyFunction;
    readOnly?: boolean;
}