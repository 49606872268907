import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getMapFromArr } from '../../../legacy/utils/helpers';

// ACTION
import { setInvitationsResource, removeInvitationsResource, fetchInvitedUsers } from './Invitation.action';

// MODEL
import { User, parseUserArguments } from '../../../legacy/models';

// CONSTANT
import { InvitationConstants as K } from './Invitation.constant';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { api_createInviteUser, api_deleteUser, api_getAllUsers, api_resendInviteUser } from '../User/User.services';
import { isObject, uniq } from 'lodash';

// GET ALL INVITATION
function* handleGetAllInvitations(action) {
    try {
        const response = yield call(api_getAllUsers, action.payload.entity_id, { invite: true });

        const invitedUsers = getMapFromArr(
            response.map(u => new User(...parseUserArguments(u))),
            'user_id'
        );

        yield put(setInvitationsResource(invitedUsers))

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAllInvitationsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_INVITATIONS_REQUEST,
        handleGetAllInvitations
    );
}

// REVOKE INVITATION
function* handleRevokeInvitation(action) {
    try {
        yield call(api_deleteUser, action.payload.entity_id, action.payload.user_id);

        yield put(removeInvitationsResource([action.payload.user_id]));

        flash({ message: 'User successfully removed' })

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* revokeInvitationSaga() {
    yield takeLatest(
        K.ACTIONS.REVOKE_INVITATION_REQUEST,
        handleRevokeInvitation
    );
}

// CREATE INVITATION
function* handleCreateInvitation(action) {
    try {
        const { entity_id, requestor_id, invitees } = action.payload;

        const requests = uniq(invitees).map(email => ({
            requestor_id,
            email
        }));

        yield all(requests.map(req => call(api_createInviteUser, entity_id, req)));

        flash({ message: 'Users invited' })
        yield put(fetchInvitedUsers(entity_id))


        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createInvitationSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_INVITATION_REQUEST,
        handleCreateInvitation
    );
}

// CREATE INVITATION
function* handleResendInvitation(action) {
    try {
        const { entity_id, user_id, requestor_id } = action.payload;

        yield call(api_resendInviteUser, entity_id, user_id, { requestor_id });

        flash({ message: 'Invite sent' })

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* resendInvitationSaga() {
    yield takeLatest(
        K.ACTIONS.RESEND_INVITATION_REQUEST,
        handleResendInvitation
    );
}
