/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { round } from 'lodash';

import AukTooltip from '../../components/AukTooltip';
import CONSTANTS from '../../Constants';
import { PercentBar } from '../../Charts/v1';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';

import { getAppropriateResolution } from '../../utils/controls';
import { generateUrlQuery } from '../../utils/url';
import { CalendarOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Colors } from '../../Charts';

export const ShopfloorAssetCard = ({ data, handleSetTarget }) => {
    const navigate = useNavigate();
    const { asset, label } = data;
    const { _primaryChart, block_id } = asset;

    const handleOpenAssetPlanner = (e, selection) => {
        e.preventDefault();
        e.stopPropagation();
        handleSetTarget(selection)
    }

    if (!_primaryChart) {
        return (
            <div
                className={classNames({
                    'shopfloor-asset-card': true,
                    'shopfloor-asset-card--disabled': true,
                })}
            >
                <ShopfloorAssetCardHeader
                    label={label}
                    onClickPlanner={(e) => { handleOpenAssetPlanner(e, asset) }}
                />
                <ShopfloorAssetCardBody>Output undefined.</ShopfloorAssetCardBody>
                <ShopfloorAssetCardFooter>
          No primary chart defined.
                </ShopfloorAssetCardFooter>
            </div>
        );
    }

    if (_primaryChart.dataSource.mode !== '1a') {
        return (
            <div className="shopfloor-asset-card">
                <ShopfloorAssetCardHeader
                    label={label}
                    onClickPlanner={(e) => { handleOpenAssetPlanner(e, asset) }}
                />
                <ShopfloorAssetCardBody className="shopfloor-asset-card__bar">
                    <PercentBar width={'0%'} color={'#367556'} />
                </ShopfloorAssetCardBody>
                <ShopfloorAssetCardFooter>
                    <div>Not supported</div>
                    <Popover
                        placement="right"
                        content={
                            <div style={{ width: 200 }}>
                                <p>
                  We're working on it — we aren't able to provide production
                  counts for analog inputs yet.
                                </p>
                                <p>Thank you for your patience. Stay tuned! ☺</p>
                            </div>
                        }
                    >
                        <span className="shopfloor-asset-card__footer__link">
                            Learn More
                        </span>
                    </Popover>
                </ShopfloorAssetCardFooter>
            </div>
        );
    }

    const { currentShift } = data;
    const hasShift = currentShift;
    const hasShiftTarget =
    hasShift && currentShift.targets && currentShift.targets.length;
    const isLastShift = hasShift && !currentShift.is_live;

    if (!hasShift) {
        return (
            <div className="shopfloor-asset-card">
                <ShopfloorAssetCardHeader
                    label={label}
                    onClickPlanner={(e) => { handleOpenAssetPlanner(e, asset) }}
                />
                <ShopfloorAssetCardBody>No shifts found!</ShopfloorAssetCardBody>
                <ShopfloorAssetCardFooter>
                    <span>
                    Click here to <span className="shopfloor-asset-card__footer__link" onClick={() => navigate('/scheduling')}>add shifts</span>
                    </span>
                </ShopfloorAssetCardFooter>
            </div>
        );
    }

    const { from, to } = currentShift;
    const output = currentShift.output ? currentShift.output[0].value : 0;

    const assetUrl = (() => {
        const startDate = moment(from);
        const endDate = moment(to);
        const durationSeconds = endDate.diff(startDate, 'seconds');
        const resolution = getAppropriateResolution(durationSeconds, {
            res_x: 5,
            res_period: 'minutes',
        });

        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        return `${CONSTANTS.URLS.ASSET}/${block_id}${query}`
    })();

    if (!hasShiftTarget) {
        return (
            <AukTooltip.Info
                title={isLastShift ? 'Last shift' : ''}
                placement="bottom"
            >
                <Link
                    to={assetUrl}
                    className={classNames({
                        'shopfloor-asset-card': true,
                        'shopfloor-asset-card--with-transparency': isLastShift,
                    })}
                >
                    <ShopfloorAssetCardHeader
                        label={label}
                        onClickPlanner={(e) => { handleOpenAssetPlanner(e, asset) }}
                    />
                    <ShopfloorAssetCardBody className="shopfloor-asset-card__bar">
                        <PercentBar width={'0%'} color={'#367556'} />
                    </ShopfloorAssetCardBody>
                    <ShopfloorAssetCardFooter>
                        <div>{output.toLocaleString()} units</div>
                        {!isLastShift ? (
                            <div>
                                <span
                                    className="shopfloor-asset-card__footer__link"
                                    onClick={(e) => { handleOpenAssetPlanner(e, asset) }}
                                >
                                    + Set target
                                </span>
                            </div>
                        ) : null}
                    </ShopfloorAssetCardFooter>
                </Link>
            </AukTooltip.Info>
        );
    }

    const targetValue = currentShift.targets[0].value;
    const now = moment();
    const shiftStart = moment(currentShift.from);
    const shiftEnd = moment(currentShift.to);
    const shiftDuration = moment.duration(shiftEnd.diff(shiftStart)).as('seconds');
    const timeSinceShiftStart = moment.duration(now.diff(shiftStart)).as('seconds');
    const targetCursor = Math.ceil(targetValue / shiftDuration * timeSinceShiftStart)

    return (
        <AukTooltip.Info title={isLastShift ? 'Last shift' : ''} placement="bottom">
            <Link
                className={classNames({
                    'shopfloor-asset-card': true,
                    'shopfloor-asset-card--with-transparency': isLastShift,
                })}
                to={assetUrl}
            >
                <ShopfloorAssetCardHeader
                    label={label}
                    onClickPlanner={(e) => { handleOpenAssetPlanner(e, asset) }}
                />
                <ShopfloorAssetCardBody className="shopfloor-asset-card__bar">
                    <TargetActualProgressBar 
                        output={output}
                        target={targetValue}
                        cursor={targetCursor}
                    /> 
                </ShopfloorAssetCardBody>
                <ShopfloorAssetCardFooter>
                    <div>
                        {`${output.toLocaleString()} / ${targetCursor.toLocaleString()}`}
                    </div>
                    <div>
                        {targetValue.toLocaleString()} units
                    </div>
                </ShopfloorAssetCardFooter>
            </Link>
        </AukTooltip.Info>
    );
};

export const ShopfloorLineCard = ({ data, handleSetTarget }) => {
    const { line, assets: _assets, production } = data;
    const assets = useMemo(
        () => _assets.map((asset) => ({ label: asset.asset_name, asset })),
        [_assets]
    );

    return (
        <div className="shopfloor-line-card">
            <div className="shopfloor-line-card__label">{line.label}</div>
            <div className="shopfloor-line-card__content">
                {assets.map((a) => (
                    <ShopfloorAssetCard
                        key={a.asset.asset_id}
                        data={{ ...a, currentShift: production[a.asset.asset_id] }}
                        handleSetTarget={handleSetTarget}
                    />
                ))}
            </div>
        </div>
    );
};

export const calcLineCardHeight = (containerWidth, numAssets) => {
    const lineLabelHeight = 16;
    const lineLabelMarginBottom = 8;
    const assetCardWidth = 276;
    const assetCardHeight = 120;
    const assetCardMarginRight = 8;
    const assetCardMarginBottom = 8;
    const lineCardMarginLeft = 20;
    const lineCardMarginBottom = 40;

    const numAssetsPerRow = Math.floor(
        (containerWidth - lineCardMarginLeft) /
      (assetCardWidth + assetCardMarginRight)
    );
    const numRows = Math.ceil(numAssets / numAssetsPerRow);
    return (
        lineLabelHeight +
    lineLabelMarginBottom +
    lineCardMarginBottom +
    numRows * (assetCardHeight + assetCardMarginBottom)
    );
};

const COLORS = {
    GREEN: Colors.green[0],
    YELLOW: Colors.yellow[0],
    RED: Colors.red[6]
}

const TargetActualProgressBar = (props) => {
    const percentBar = round((props.output / props.target) * 100, 2);
    const percentCaret = round((props.cursor / props.target) * 100, 2);
    const rate = props.output / props.cursor;
    const color = rate >= 1 ? COLORS.GREEN : rate < 0.9 ? COLORS.RED : COLORS.YELLOW;

    return <div className="target-actual-progress-bar">
        <div className="target-actual__indicator-container">
            <AukTooltip.Info title="Current Expected">
                <CaretDownOutlined style={{ color, fontSize: 20, marginLeft: `${percentCaret}%` }}/>
            </AukTooltip.Info>
        </div>
        <PercentBar
            width={`${percentBar > 100 ? 100 : percentBar}%`}
            color={color}
        />
    </div>
}

const ShopfloorAssetCardHeader = (props) => {
    return <div className="shopfloor-asset-card__header">
        <div className="shopfloor-asset-card__label">{props.label}</div>
        <PlannerIcon onClick={props.onClickPlanner}/>
    </div>
}

const ShopfloorAssetCardBody = (props) => {
    return <div className={classNames(["shopfloor-asset-card__body", props.className])}>
        {props.children}
    </div>
}

const ShopfloorAssetCardFooter = (props) => {
    return <div className="shopfloor-asset-card__footer">{props.children}</div>
}

const PlannerIcon = (props) => {
    return <CalendarOutlined style={{ fontSize: 18, color: "#1890ff" }} onClick={props.onClick}/>
}