/* eslint-disable react/prop-types */

import React from 'react';
import { mapValues } from 'lodash';

// STORE
import { rootIssues } from '../../../store/old/Issues/Issues.selector';

// UTILS
import { permittedIssuesByBlocks } from '../../utils/issues';

export const generateIssuesTreeData = (node, options) => {
    const { name: title, issue_id, color, hierarchy_level } = node;

    let icon;
    hierarchy_level === 2 &&
    (icon = (
        <span style={{ fontSize: 14, color }}>
            <i className="fas fa-square" />
        </span>
    ));

    const additionalParameters = mapValues(options, (v) => v(node));

    if (node.children && !node.children.length) {
        return {
            label: title,
            title,
            value: issue_id,
            key: issue_id,
            icon,
            ...additionalParameters,
        };
    }

    const children = node.children.map((child) =>
        generateIssuesTreeData(child, options)
    );

    return {
        label: title,
        title,
        value: issue_id,
        key: issue_id,
        children,
        icon,
        ...additionalParameters,
    };
};

export const generatePermittedIssuesTreeData = (
    issues,
    block_ids,
    blocks_issues_exclusions
) => {
    const { issues: issuesResource } = issues;
    const mainIssues = rootIssues(issues);

    const permittedIssues = permittedIssuesByBlocks(
        issuesResource,
        block_ids,
        blocks_issues_exclusions
    );

    const permittedIssuesParents = permittedIssues
        .map((issue_id) => issuesResource[issue_id].parents)
        .reduce((acc, curr) => acc.concat(...curr), []);

    const issuesToDisplay = new Set([
        ...permittedIssues,
        ...permittedIssuesParents,
    ]);

    const generateTreeData = (node) => {
        const { name: title, issue_id, color, hierarchy_level } = node;
        const icon = hierarchy_level === 2 && (
            <span style={{ fontSize: 14, color }}>
                <i className="fas fa-square" />
            </span>
        );
        const disabled =
      hierarchy_level === 1 || !permittedIssues.includes(issue_id);
        if (node.children && !node.children.length) {
            return {
                label: title,
                title,
                value: issue_id,
                key: issue_id,
                icon,
                disabled,
            };
        }
        const children = node.children
            .filter((c) => issuesToDisplay.has(c.issue_id))
            .map((child) => generateTreeData(child));

        return {
            label: title,
            title,
            value: issue_id,
            key: issue_id,
            children,
            icon,
            disabled,
        };
    };

    return mainIssues.map((i) => generateTreeData(i));
};
