import { queryString } from '../../../legacy/utils/service';
import { makeRequest } from '../Shared/Shared.action';

// REDUX STORE
export const api_getSkuCharts = async (entity_id, query) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/sku-chart${queryString(query)}`,
                method: "GET",
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_getAssetSkuCharts = async (entity_id, asset_id) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/sku-chart`,
                method: "GET",
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_createSkuChart = async (entity_id, asset_id, chart_id, sku_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/chart/${chart_id}/sku/${sku_id}`,
                method: "POST",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_bulkCreateUpdateSkuCharts = async (entity_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/sku-chart`,
                method: "POST",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}

export const api_updateSkuChart = async (entity_id, asset_id, chart_id, sku_id, data) => {
    return await new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/chart/${chart_id}/sku/${sku_id}`,
                method: "PUT",
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            })
        }
    )
}