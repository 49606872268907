/* eslint-disable react/prop-types */

import React from 'react';
import LoadersHorizontal from '../../components/LoadersHorizontal';
import './WithLoaders.scss';

const WithLoaders = (props) => {
    const { children, isLoading = false } = props;

    return (
        <>
            <div className="widget__body--with-loaders">{children}</div>
            {isLoading && (
                <div className="widget__loaders">
                    <LoadersHorizontal />
                </div>
            )}
        </>
    );
};

WithLoaders.defaultProps = {
    isLoading: false,
};

export default WithLoaders;
