import { Popover } from "antd"
import React from "react"
import { OEELegend } from "../../../legacy/Charts"
import { InfoCircleOutlined } from "@ant-design/icons"

const OeeLegendPopover = () => {
    return <Popover placement="rightBottom" content={<OEELegend style={{ width: "80vw" }}/>}>
        <InfoCircleOutlined className="ml-2"/>
    </Popover>
}

export default OeeLegendPopover
