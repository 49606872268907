import React, { useEffect, useState } from 'react';
import LanguageSelection from './LanguageSelection';
import Banner from '../Banner';
import CONSTANTS from '../../Constants';

const HeaderUnauthenticated = () => {
    const [banner, setBanner] = useState(undefined);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(
            CONSTANTS.CMS_API + 'system-banner-unauthenticated',
            { method: 'GET', signal }
        )
            .then((response) => response.json())
            .then((result) => setBanner(result))
            .catch((e) => console.log(e));

        return () => controller.abort();
    }, []);

    return (
        <div className="d-flex flex-column w-100">
            <Banner display={banner && banner.display} data={banner} />
            <div className="header--unauth">
                <LanguageSelection />
            </div>
        </div>
    );
};

export default HeaderUnauthenticated;
