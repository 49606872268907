import "./NavSider.scss"
import { Layout, Menu, type MenuProps } from "antd"
import React, { useEffect, useMemo, useState } from "react"

import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined"
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined"
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Link, useLocation } from "react-router-dom"
import { useAppSelector } from "../../../store/hooks"
import { userSelector } from "../../../store/old/Authentication/Authentication.selector"
import { ROUTES } from "../../../routes/routes"
import { Typography, styled } from "@mui/material"
import { MenuOpen } from "@mui/icons-material"
import AdjustIcon from "@mui/icons-material/Adjust"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import ListOutlinedIcon from "@mui/icons-material/ListOutlined"
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined"
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined"
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import useBlocks from "../../../hooks/useBlocks"
import { arrayAssets } from "../../../store/old/Assets/Assets.selector"
import type BlockModel from "../../../api/models/block.model"
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined"
import translate from "../../../legacy/utils/translate"
import AlbumIcon from "@mui/icons-material/Album"

type MenuItem = Required<MenuProps>["items"][number]

function getItem (
    label: string,
    key: React.Key,
    show: boolean,
    icon?: React.ReactNode,
    href?: string,
    children?: MenuItem[],
): MenuItem {
    if (!show) return null
    return {
        title: "",
        label: href ? <Link to={href}><Typography variant="subtitle1">{translate(label)}</Typography></Link> : <Typography variant="subtitle1">{translate(label)}</Typography>,
        key,
        icon: icon ? href ? <Link to={href}><IconBox>{icon}</IconBox></Link> : <IconBox>{icon}</IconBox> : null,
        children: children?.filter(c => c),
    }
}

const NavSider = () => {
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(true)
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const authUser = useAppSelector(userSelector)

    const { root } = useBlocks()
    const assets = useAppSelector(arrayAssets)
    const rootId = (root as BlockModel).blockId
    const assetBlockId = assets.length ? assets[0].block_id : null

    useEffect(() => {
        const key = pageKeys[getBasePath(location.pathname)]
        setSelectedKeys(key ? [key] : [])
    }, [location.pathname])

    const menuItems = useMemo(() => [
        getItem("home", "home", true, <HouseOutlinedIcon/>, `${authUser.preference.default_view ?? ROUTES.DASHBOARD}?entity_id=${authUser.entity_id}`),
        getItem("operationshub", "operations_hub", true, collapsed ? <SpeedOutlinedIcon/> : undefined, undefined, [
            getItem("dashboard", PAGES.DASHBOARD, authUser.check_user_policy_url("dashboard"), <SpeedOutlinedIcon/>, ROUTES.DASHBOARD),
            getItem("productionachievement", PAGES.PRODUCTION_ACHIEVEMENT, true, <TrackChangesIcon />, `${ROUTES.DASHBOARD}/${ROUTES.PRODUCTION_ACHIEVEMENT}`),
            getItem("blockview", PAGES.BLOCK, authUser.check_user_policy_url("blocks"), <AdjustIcon/>, `${ROUTES.BLOCK}/${rootId}`),
            getItem("assetview", PAGES.ASSET, authUser.check_user_policy_url("assets"), <AdjustIcon/>, assetBlockId ? `${ROUTES.ASSET}/${assetBlockId}` : `${ROUTES.BLOCK}/${rootId}`)
        ]),
        getItem("planninghub", "plan_hub", true, collapsed ? <AccessTimeOutlinedIcon/> : undefined, undefined, [
            getItem("schedulING", PAGES.SCHEDULING, authUser.check_user_policy_url("scheduling"), <AccessTimeOutlinedIcon/>, ROUTES.SCHEDULING),
            getItem("skumanagement", PAGES.SKU_MANAGEMENT, authUser.check_user_policy_url("skus"), <WidgetsOutlinedIcon />, ROUTES.SKU_MANAGEMENT),
            getItem("standardtimemanagement", PAGES.STANDARD_TIMES, authUser.check_user_policy_url("standard_time"), <TimerOutlinedIcon />, ROUTES.STANDARD_TIMES),
            getItem("targetsetting", PAGES.TARGET_SETTING, authUser.check_user_policy_url("targets"), <CalendarTodayOutlinedIcon />, ROUTES.TARGET_SETTING)
        ]),
        getItem("ciimpact", "ci_hub", true, collapsed ? <AttachMoneyOutlinedIcon/> : undefined, undefined, [
            getItem("issuemanagement", PAGES.ISSUE_MANAGEMENT, authUser.check_user_policy_url("issues"), <ListOutlinedIcon />, ROUTES.ISSUE_MANAGEMENT),
            getItem("issuetracker", PAGES.ISSUE_TRACKER, authUser.check_user_policy_url("tracked_issues"), <DescriptionOutlinedIcon />, ROUTES.ISSUE_TRACKER),
            getItem("labelsearch", PAGES.SEARCH, authUser.check_user_policy_url("search"), <SearchOutlinedIcon />, ROUTES.SEARCH),
            getItem("notifications", PAGES.NOTIFICATIONS, authUser.check_user_policy_url("notifications"), <NotificationsNoneOutlinedIcon/>, ROUTES.NOTIFICATIONS),
            getItem("impactsuite", PAGES.IMPACT, true, <AttachMoneyOutlinedIcon/>, ROUTES.IMPACT),
            getItem("goalsetting", PAGES.GOALS, true, <AlbumIcon/>, ROUTES.GOALS),
        ]),
        getItem("advancedanalytics", "analyse_hub", true, collapsed ? <BrokenImageOutlinedIcon/> : undefined, undefined, [
            getItem("paretoanalysis", PAGES.PARETO, authUser.check_user_policy_url("pareto_analysis"), <SignalCellularAltOutlinedIcon sx={{ transform: "rotate(90deg) scaleX(-1)" }} />, `${ROUTES.PARETO}/${rootId}`),
            getItem("trendanalysis", PAGES.TREND, authUser.check_user_policy_url("trend_analysis"), <BrokenImageOutlinedIcon/>, ROUTES.TREND),
            getItem("utilizationheatmap", PAGES.UTILIZATION, authUser.check_user_policy_url("heatmap"), <AppsOutlinedIcon />, ROUTES.UTILIZATION),
            getItem("regressionanalysis", PAGES.REGRESSION, authUser.check_user_policy_url("regression_analysis"), <ScatterPlotOutlinedIcon/>, ROUTES.REGRESSION),
        ]),
        getItem("teamandorganization", "org_hub", true, collapsed ? <ManageAccountsOutlinedIcon/> : undefined, undefined, [
            getItem("usermanagement", PAGES.USER_MANAGEMENT, authUser.check_user_policy_url("users"), <GroupsOutlinedIcon/>, ROUTES.USER_MANAGEMENT),
            getItem("permission", PAGES.PERMISSIONS_MANAGEMENT, authUser.check_user_policy_url("permissions"), <ManageAccountsOutlinedIcon/>, ROUTES.PERMISSIONS_MANAGEMENT)
        ]),
        getItem("settingsandpreferences", "settings_hub", true, collapsed ? <SettingsOutlinedIcon/> : undefined, undefined, [
            getItem("hardware", PAGES.HARDWARE_MANAGEMENT, authUser.check_user_policy_url("radio_mesh"), <MemoryOutlinedIcon/>, ROUTES.HARDWARE_MANAGEMENT),
            getItem("assetmanagement", PAGES.ASSET_MANAGEMENT, authUser.check_user_policy_url("assets"), <SettingsOutlinedIcon/>, ROUTES.ASSET_MANAGEMENT),
            getItem("preferences", PAGES.PREFERENCES, true, <PaletteOutlinedIcon/>, ROUTES.PREFERENCES),
        ])
    ], [authUser, collapsed, rootId, assetBlockId, authUser.preference])

    return <Layout.Sider
        className="layout-auth__sider"
        collapsible
        width={284}
        collapsedWidth={50}
        trigger={<div onClick={() => { setCollapsed(!collapsed) }}><MenuOpen /></div>}
        defaultCollapsed={true}
        collapsed={collapsed}
    >
        <Menu
            mode="inline"
            items={menuItems}
            selectedKeys={selectedKeys}
            onSelect={(_) => {
                setSelectedKeys(_.selectedKeys)
                setCollapsed(true)
            }}
        />
    </Layout.Sider>
}

const IconBox = styled("div")({
    fontSize: "20px",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
})

const getBasePath = (str: string) => {
    const items = str.split("/")
    const regex = /(:\w)|(\d)/g

    return items.filter((s) => !s.match(regex)).join("/")
}

const PAGES: Record<string, string> = {
    DASHBOARD: "dashboard_page",
    PRODUCTION_ACHIEVEMENT: "production_achievement_page",
    BLOCK: "block_page",
    ASSET: "asset_page",
    SCHEDULING: "schedules_page",
    SKU_MANAGEMENT: "sku_page",
    STANDARD_TIMES: "std_time_page",
    TARGET_SETTING: "targets_page",
    ISSUE_MANAGEMENT: "issues_page",
    ISSUE_TRACKER: "issue_tracker_page",
    SEARCH: "labels_page",
    NOTIFICATIONS: "notifications_page",
    IMPACT: "impact_page",
    PARETO: "pareto_page",
    TREND: "trend_page",
    UTILIZATION: "utilization_page",
    REGRESSION: "regression_page",
    USER_MANAGEMENT: "users_page",
    PERMISSIONS_MANAGEMENT: "permissions_page",
    HARDWARE_MANAGEMENT: "hardware_page",
    ASSET_MANAGEMENT: "assets_page",
    PREFERENCES: "entity_page",
    GOALS: "goals_page"
}

const pageKeys: Record<string, string> = {
    [ROUTES.DASHBOARD]: PAGES.DASHBOARD,
    [`${ROUTES.DASHBOARD}/${ROUTES.PRODUCTION_ACHIEVEMENT}`]: PAGES.PRODUCTION_ACHIEVEMENT,
    [ROUTES.BLOCK]: PAGES.BLOCK,
    [ROUTES.ASSET]: PAGES.ASSET,
    [ROUTES.SCHEDULING]: PAGES.SCHEDULING,
    [ROUTES.SKU_MANAGEMENT]: PAGES.SKU_MANAGEMENT,
    [ROUTES.STANDARD_TIMES]: PAGES.STANDARD_TIMES,
    [ROUTES.TARGET_SETTING]: PAGES.TARGET_SETTING,
    [ROUTES.ISSUE_MANAGEMENT]: PAGES.ISSUE_MANAGEMENT,
    [ROUTES.ISSUE_TRACKER]: PAGES.ISSUE_TRACKER,
    [ROUTES.SEARCH]: PAGES.SEARCH,
    [ROUTES.NOTIFICATIONS]: PAGES.NOTIFICATIONS,
    [ROUTES.IMPACT]: PAGES.IMPACT,
    [ROUTES.PARETO]: PAGES.PARETO,
    [ROUTES.TREND]: PAGES.TREND,
    [ROUTES.UTILIZATION]: PAGES.UTILIZATION,
    [ROUTES.REGRESSION]: PAGES.REGRESSION,
    [ROUTES.USER_MANAGEMENT]: PAGES.USER_MANAGEMENT,
    [ROUTES.PERMISSIONS_MANAGEMENT]: PAGES.PERMISSIONS_MANAGEMENT,
    [ROUTES.HARDWARE_MANAGEMENT]: PAGES.HARDWARE_MANAGEMENT,
    [ROUTES.ASSET_MANAGEMENT]: PAGES.ASSET_MANAGEMENT,
    [ROUTES.PREFERENCES]: PAGES.PREFERENCES
}

export default NavSider
