import { Box, CircularProgress } from "@mui/material"
import { GridColDef, GridColumnHeaderParams, GridValueFormatterParams } from "@mui/x-data-grid"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { AutoSizer } from "react-virtualized"
import ProcessTreeTable from "../../../../../components/Table/ProcessTreeTable/ProcessTreeTable"
import {
    goalsFetchEndGoalDataAction,
    objectiveEndRange,
    objectiveEndTimeFrame,
    rangeToTimeKey,
    TimeKey,
    TimeRange,
} from "../../../../../store/actions/goals.action"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { selectActiveEntity } from "../../../../../store/selectors/entities.selectors"
import {
    selectCurrentProcess,
    selectObjective,
    selectObjectiveGoals,
    selectObjectiveLoading,
    selectObjectiveOee
} from "../../../../../store/selectors/goals.selectors"
import EndGoalInput from "./EndGoalInput/EndGoalInput"
import { getTimeFormat } from "../ObjectiveProgressivePlan/ObjectiveProgressivePlan"
import MouseOverPopover from "../../../../../components/Popover/MouseOverPopover"
import Typography from "antd/lib/typography/Typography"

const COLUMN_WIDTH = 120
const ObjectiveEndGoal = () => {
    const dispatch = useAppDispatch()

    const loading = useAppSelector(selectObjectiveLoading)
    const viewing = useAppSelector(selectObjective)
    const goals = useAppSelector(selectObjectiveGoals)
    const oee = useAppSelector(selectObjectiveOee)
    const entity = useAppSelector(selectActiveEntity)
    const currentProcess = useAppSelector(selectCurrentProcess)

    useEffect(() => {
        let promise: any
        if (viewing) {
            promise = dispatch(goalsFetchEndGoalDataAction())
        }

        return () => {
            if (promise) {
                promise.abort()
            }
        }
    }, [viewing?.blockId])

    if (!viewing) {
        return null
    }

    const timeFrame = objectiveEndTimeFrame(viewing, entity)
    const lastIndex = timeFrame.length - 1
    const jumpOffPoint = timeFrame[0]
    const currentOee = timeFrame[lastIndex]
    const timeColumns = [...timeFrame.slice(1, lastIndex)].map(column => generateTimeColumn(
        column,
        viewing.unitOfTime as moment.unitOfTime.Base,
    ))

    const defaultColumns: GridColDef[] = useMemo(() => [
        {
            field: "jumpOffPoint",
            renderHeader: () => <Box sx={{ display: "flex", alignItems:"center" }}>
                <Typography className="tree-table-cell">
                    {getTimeFormat(jumpOffPoint.startsAt, viewing.unitOfTime as moment.unitOfTime.Base)}
                </Typography>
                <MouseOverPopover 
                    id="jump-off-point-info"
                >
                    <Box sx={{ padding: "8px" }}>
                        <Box>
                        Jump-off Point: The period right before the goal plan starts.
                        </Box>
                    </Box>
                </MouseOverPopover>
            </Box>,
            type: "string",
            width: COLUMN_WIDTH,
            sortable: false,
            align: "right",
            valueFormatter : (params: GridValueFormatterParams) => {
                const now = moment()
                if (jumpOffPoint.startsAt.isAfter(now)) {
                    return "-"
                }
            
                return valueFormatter(params)
            },
        },
        {
            field: "currentOee",
            headerName: "Current OEE",
            type: "string",
            width: COLUMN_WIDTH,
            sortable: false,
            align: "right",
            valueFormatter,
        },
        {
            field: "endGoal",
            headerName: "End goal",
            type: "string",
            align: "right",
            width: 120,
        },
    ], [jumpOffPoint])

    const columns = [...defaultColumns]
    columns[0].field = rangeToTimeKey(jumpOffPoint)
    columns[1].field = rangeToTimeKey(currentOee)
    columns.splice(1, 0, ...timeColumns)

    const endTimeKey = rangeToTimeKey(objectiveEndRange(viewing, entity))

    const data: Datum[] = []
    const columnRef = [...columns.slice(0, columns.length-1)]
    for (const blockId in oee) {
        const id = +blockId
        let endGoal: string = ""
        if (goals[blockId] && goals[blockId][endTimeKey]) {
            endGoal = goals[blockId][endTimeKey].value.toString()
        }

        const item: Datum = { id, endGoal }
        for (const column of columnRef) {
            item[column.field] = oee[blockId][column.field] ?? undefined
        }

        data.push(item)
    }

    columns[columns.length-1].valueFormatter = (params: GridValueFormatterParams) => {
        return <EndGoalInput
            blockId={+params.id!}
            timeKey={endTimeKey}
            value={params.value}
            disabled={loading}
        />
    }

    return <AutoSizer>
        {({ width, height }) =>
            <ProcessTreeTable
                process={currentProcess}
                columns={columns}
                width={width}
                height={height}
                rowHeight={56}
                data={data}
            />
        }
    </AutoSizer>
}

const generateTimeColumn = (range: TimeRange, unitOfTime: moment.unitOfTime.Base): GridColDef => ({
    field: rangeToTimeKey(range),
    headerName: getTimeFormat(range.startsAt, unitOfTime),
    type: "string",
    width: COLUMN_WIDTH,
    sortable: false,
    align: "right",
    valueFormatter
    // : (params: GridValueFormatterParams) => {
    //     console.log("value formatter")
    //     const now = moment()
    //     if (range.startsAt.isAfter(now)) {
    //         return "-"
    //     }

    //     return valueFormatter(params)
    // },
})


const valueFormatter = ({ value }: GridValueFormatterParams): React.ReactNode => {
    if (value === undefined) {
        return <CircularProgress size={16} />
    }

    if (value === null) {
        return "-"
    }

    return value.toFixed(2) + "%"
}

type Datum = {
    id: Domain.BlockId
    endGoal: string
    [key: TimeKey]: Domain.Oee | string
}

export default ObjectiveEndGoal
