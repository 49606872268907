/* eslint-disable react/prop-types */
// Select component with custom styles
import React from 'react';
import Select from 'react-select';

import './Select.scss';

const _Select = (props) => {
    const styles = Object.assign(
        {},
        {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? 'gray' : 'white',
                    color: isFocused ? 'white' : 'inherit',
                };
            },
            menuPortal: (styles) => {
                return {
                    ...styles,
                    zIndex: 9999,
                };
            },
        },
        {
            ...props.styles,
        }
    );

    return (
        <Select
            menuPortalTarget={document.body}
            {...props}
            className={`react-select-container ${props.className || ''}`}
            classNamePrefix="react-select"
            styles={styles}
        />
    );
};

export default _Select;
