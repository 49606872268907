/* eslint-disable react/prop-types */
import React from 'react';

import './OnActiveInput.scss';

export default class OnActiveInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            submitted: false,
        };

        this.timeoutId = null;

        this.toggleActive = this.toggleActive.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleActive() {
        this.setState({ active: !this.state.active, submitted: false });
    }

    handleBlur() {
        this.timeoutId = setTimeout(() => {
            !this.state.submitted && this.props.handleBlur && this.props.handleBlur();
            this.setState({ active: false, submitted: false });
        }, 200);
    }

    handleFocus() {
        clearTimeout(this.timeoutId);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
            this.handleBlur();
            return;
        }
    }

    handleSubmit() {
        this.setState(
            {
                submitted: true,
            },
            () => {
                this.props.handleSubmit();
            }
        );
    }

    handleKeyUp(e) {
        if (e.keyCode === 27) {
            this.handleBlur();
        }
    }

    get placeholder() {
        const { value, placeholder, disabled } = this.props;
        return (
            <div
                className="onactive__placeholder"
                onClick={() => !disabled && this.toggleActive()}
            >
                {value || placeholder}
            </div>
        );
    }

    get input() {
        const { type, value, handleChange } = this.props;
        return (
            <div
                className="onactive__input-wrapper"
                ref={(node) => (this.inputWrapper = node)}
                onBlur={() => this.handleBlur()}
                onFocus={() => this.handleFocus()}
            >
                <input
                    className="form-control onactive__input "
                    type={type || 'text'}
                    autoFocus
                    value={value}
                    onChange={handleChange}
                    onKeyPress={this.handleKeyPress}
                    onKeyUp={this.handleKeyUp}
                    {...this.props.inputProps}
                />
                <div className="onactive__input-save" onClick={this.handleSubmit}>
                    <i className="fas fa-save" />
                </div>
            </div>
        );
    }

    render() {
        const { className, disabled } = this.props;
        return (
            <div
                className={`onactive__container ${disabled ? 'disabled' : ''} ${
                    className || ''
                }`}
            >
                {this.state.active ? this.input : this.placeholder}
            </div>
        );
    }
}

OnActiveInput.defaultProps = {
    placeholder: '',
};
