import { OEEConstants as K } from './OEE.constants';


export const setOEEState = (masks) => {
    return {
        type: K.ACTIONS.SET_OEE_STATE,
        payload: { masks },
    };
};

export const resetOEEState = () => {
    return {
        type: K.ACTIONS.RESET_OEE_STATE,
    };
};
