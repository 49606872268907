/* eslint-disable react/prop-types */

import _ from 'lodash';
import React from 'react';

import NotificationsModal from './components/NotificationsModal';

import { Table, Button } from 'antd';
import { CATEGORIES } from '../../../store/old/Notifications/Notifications.constants';

import './Notifications.scss';
import InputGroupPrepend from '../../components/InputGroupPrepend';
import { regexMatch } from '../../utils/helpers';
import AukTooltip from '../../components/AukTooltip';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';

class NotificationManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selection: null,
            modal: false,
            selectedRowKeys: [],
            filter: '',
        };

        this.showModal = this.showModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    handleCheckbox(selectedRowKeys) {
        this.setState({ selectedRowKeys });
    }

    handleChangeFilter(e) {
        this.setState({ filter: e.target.value });
    }

    editHandler(cellInfo) {
        return (
            <div className="d-flex justify-content-center">
                <Permission forResource resource="notifications" canDo="edit">
                    <span
                        className="p-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.showModal(cellInfo)}
                    >
                        <i className="far fa-edit" />
                    </span>
                </Permission>
                <Permission forResource resource="notifications" canDo="full">
                    <span
                        className="p-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.deleteNotification(cellInfo)}
                    >
                        <i className="far fa-trash-alt" />
                    </span>
                </Permission>
            </div>
        );
    }

    showModal(selection = null) {
        this.setState({ modal: true, selection });
    }

    cancelModal() {
        this.setState({ modal: false, selection: null });
    }

    get data() {
        const { notifications } = this.props;
        const { filter } = this.state;

        if (!filter) return notifications;

        return notifications.filter(
            ({ category, asset, device, metadatum, topic, recipients }) => {
                return (
                    regexMatch(category, filter, { escape: true }) ||
          regexMatch(asset ? asset.asset_name : '', filter, { escape: true }) ||
          regexMatch(metadatum ? metadatum.chart_title : '', filter, {
              escape: true,
          }) ||
          regexMatch(device ? device.mac_address : '', filter, {
              escape: true,
          }) ||
          regexMatch(topic || '', filter, { escape: true }) ||
          regexMatch(
              recipients.map((r) => r.group_name || r.email).join(' '),
              filter,
              { escape: true }
          )
                );
            }
        );
    }

    get columns() {
        return [
            {
                title: translate('category'),
                dataIndex: 'category',
                sorter: (a, b) => {
                    if (a.category < b.category) return -1;
                    if (a.category > b.category) return 1;
                    return 0;
                },
                render: (text, record, index) => _.capitalize(text || ''),
                width: 100,
            },
            {
                title: translate('monitoring'),
                dataIndex: 'monitoring',
                render: (text, record, index) => {
                    const { category, correlation, asset, metadatum, device, device_id } =
            record;

                    switch (category) {
                    case CATEGORIES.NODE:
                        return device && device.asset
                            ? `${device.mac_address} (${device.asset.asset_name})`
                            : '';
                    case CATEGORIES.ALERT:
                        return asset && metadatum
                            ? `${asset.asset_name}: ${metadatum.chart_title}`
                            : '';
                    case CATEGORIES.OUTLIER:
                        return correlation && correlation.title;
                    case CATEGORIES.GATEWAY:
                        const gw = this.props.gateways.find(
                            (item) => item.device_id === device_id
                        );
                        return gw ? `${gw.serial_number}` : '';
                    default:
                        return '';
                    }
                },
                width: 250,
            },
            {
                title: translate('topic'),
                dataIndex: 'topic',
                width: 100,
            },
            {
                title: translate('frequency'),
                dataIndex: 'frequency',
                width: 100,
                render: (text, record, index) => text || '-',
            },
            {
                title: translate('recipients'),
                dataIndex: 'recipients',
                render: (text, record, index) => {
                    const { users: userOptions, groups: groupOptions } = this.props;

                    const users = record.recipient_users.map(
                        (id) => userOptions[id] && userOptions[id].email
                    );
                    const groups = record.recipient_groups.map(
                        (id) => groupOptions[id] && groupOptions[id].group_name
                    );

                    const recipients = [...users, ...groups];

                    return (
                        <div className="notify-table-recipients">
                            {recipients.map((r, i) => (
                                <span className="recipient" key={i}>
                                    {r}
                                    {i === recipients.length - 1 ? '' : ',  '}
                                </span>
                            ))}
                        </div>
                    );
                },
                width: 300,
            },
            {
                title: '',
                dataIndex: 'action',
                render: (text, record, index) => this.editHandler(record),
                width: 50,
            },
        ];
    }

    render() {
        const modalProps = { toggle: this.cancelModal, ...this.state };
        const { filter, selectedRowKeys } = this.state;
        return (
            <div className="notifications">
                <div className="d-flex mb-3 justify-content-between">
                    <div className="col col-4 p-0">
                        <InputGroupPrepend
                            icon={<i className="fas fa-search" />}
                            input={
                                <input
                                    style={{ flexGrow: 1 }}
                                    value={filter}
                                    onChange={this.handleChangeFilter}
                                />
                            }
                        />
                    </div>
                    <Permission forResource resource="notifications" canDo="edit">
                        <div className="d-flex">
                            <AukTooltip.Help title={translate('addnotification')}>
                                <Button
                                    onClick={(e) => this.showModal()}
                                    className="auk-button auk-button--round"
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </AukTooltip.Help>
                        </div>
                    </Permission>
                </div>
                <Table
                    rowKey={(record) => record.notification_id}
                    columns={this.columns}
                    dataSource={this.data}
                    pagination={{ position: ['bottomCenter'] }}
                />
                <NotificationsModal {...this.props} {...modalProps} />
            </div>
        );
    }
}

export default NotificationManagement;
