/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CellStandardTime from './CellStandardTime'

class OeeGridCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { handleOpenModalStandardTime, isEnabled, checkPolicy } = this.props;
        const { asset, sku, std_time, auto_update } = this.props.data;

        return (
                <div
                    title={isEnabled ? '' : 'Asset speed must be turned on'}
                    className={`standard-time-oee-table ${
                        isEnabled ? 'enabled' : 'disabled'
                    }`}
                    onClick={(event) => {
                        setTimeout(() => {
                            if (!isEnabled) {
                                return;
                            }

                            if (!this.clickedToggle && handleOpenModalStandardTime && checkPolicy.isEditPermission) {
                                handleOpenModalStandardTime();
                            }
                        });

                        event.preventDefault();
                    }}
                >
                    <CellStandardTime autoUpdate={auto_update} stdTime={std_time}/>
                </div>
        );
    }
}

const checkPolicyStandardtime = (user) => {
    return {
        isEditPermission: user.check_resource_policy(
            'standard_time',
            false,
            true,
            false
        ),
    };
};

const mapStateToProps = (rootState) => {
    return {
        checkPolicy: checkPolicyStandardtime(rootState.auth.user),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(OeeGridCell);
