/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// ACTIONS
import {
    createSku,
    updateSku,
    deleteSku,
    bulkCreateSku,
} from '../../../store/old/Sku/Sku.action';

// COMPONENTS
import SkuManagement from './SKUManagement';
import { withSkus } from  '../../Wrappers/HOCs/withSkus';


class SkuManagementContainerSimple extends Component {
    onCreateSku = (sku, callback) => {
        this.props.createSku(sku, callback);
    };

    onEditSku = (sku, callback) => {
        this.props.editSku(sku, callback);
    };

    onDeleteSku = (sku, callback) => {
        this.props.deleteSku(sku, callback);
    };

    onBulkCreateSku = (skus, callback) => {
        this.props.bulkCreateSku(skus, callback);
    };

    render() {
        return (
            <SkuManagement
                onCreateSku={this.onCreateSku}
                onEditSku={this.onEditSku}
                onDeleteSku={this.onDeleteSku}
                onBulkCreateSku={this.onBulkCreateSku}
            />
        );
    }
}

const mapStateToProps = (rootState) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createSku: (sku, callback) =>
            dispatch(createSku(sku, callback)),
        editSku: (sku, callback) =>
            dispatch(updateSku(sku, callback)),
        deleteSku: (sku, callback) =>
            dispatch(deleteSku(sku, callback)),
        bulkCreateSku: (skus, callback) =>
            dispatch(bulkCreateSku(skus, callback)),
    };
};

const SkuManagementContainer = connect(mapStateToProps, mapDispatchToProps)(SkuManagementContainerSimple);

export default withSkus(SkuManagementContainer);
