import { findParentAt } from '../utils/helpers';
import { store } from '../../store';
import CONSTANTS from '../Constants';

export const parseIssueArguments = (i) => {
    return [
        i.issue_id,
        i.entity_id,
        i.issue,
        i.parent_issue_id,
        i.hierarchy_level,
        i.issue_code,
    ];
};

export const IssueColors = {
    Unscheduled: CONSTANTS.CAT.us.color,
    'Planned Downtime': CONSTANTS.CAT.pd.color,
    Breakdown: CONSTANTS.CAT.bd.color,
    'Setup/ Changeover': CONSTANTS.CAT.st.color,
    'Minor Stops': CONSTANTS.CAT.ms.color,
    'Speed Losses': CONSTANTS.CAT.sl.color,
    Rejects: CONSTANTS.CAT.rj.color,
    Rework: CONSTANTS.CAT.rw.color,
};

export const issueToOEE = {
    Unscheduled: 'us',
    'Planned Downtime': 'pd',
    Breakdown: 'bd',
    'Setup/ Changeover': 'st',
    'Minor Stops': 'ms',
    'Speed Losses': 'sl',
    Rejects: 'rj',
    Rework: 'rw',
};

export const OeeToIssue = {
    us: 'Unscheduled',
    pd: 'Planned Downtime',
    bd: 'Breakdown',
    st: 'Setup/ Changeover',
    ms: 'Minor Stops',
    sl: 'Speed Losses',
    rj: 'Rejects',
    rw: 'Rework',
};

export const SimpleIssue = (props) => {
    const {
        oee: { masks },
    } = store.getState().ui;

    const name = props.issue;
    const color = CONSTANTS.CAT[props.oee].color;

    const oeeCategory = `${
        masks[props.oee] ? masks[props.oee] : OeeToIssue[props.oee]
    }`;

    return Object.assign({}, props, { name, color, oeeCategory });
};

export class Issue {
    constructor(
        issue_id,
        entity_id,
        issue,
        parent_issue_id = null,
        hierarchy_level,
        issue_code = ''
    ) {
        this.issue_id = issue_id;
        this.entity_id = entity_id;
        this.issue = issue;
        this.parent_issue_id = parent_issue_id;
        this.hierarchy_level = hierarchy_level;
        this.issue_code = issue_code;
    }

    get name() {
        const {
            oee: { masks },
        } = store.getState().ui;

        const key = issueToOEE[this.issue];

        return key && masks[key] ? masks[key].mask : this.issue;
    }

    get parent() {
        const {
            issues: { issues: issuesState },
        } = store.getState();

        return issuesState[this.parent_issue_id];
    }

    get parents() {
        const recurse = (i, acc = []) => {
            if (!i.parent) return [];

            acc.push(i.parent.issue_id);
            recurse(i.parent, acc);
            return acc;
        };

        return recurse(this);
    }

    get children() {
        const {
            issues: { issues, pc_directory },
        } = store.getState();

        const { issue_id } = this;

        return pc_directory[issue_id]
            ? pc_directory[issue_id].map((issue_id) => issues[issue_id])
            : [];
    }

    get isNewIssue() {
        const {
            issues: { issues: issuesState },
        } = store.getState();

        return issuesState[this.issue_id] ? false : true;
    }

    get color() {
        if (this.hierarchy_level === 1) return null;
        return IssueColors[this.oeeCategory.issue];
    }

    get root() {
        const {
            issues: { issues: issuesState },
        } = store.getState();
        return findParentAt.call(
            this,
            issuesState,
            (node) => node.hierarchy_level,
            (node) => node.parent_issue_id,
            1
        );
    }

    get oeeCategory() {
        const {
            issues: { issues: issuesState },
        } = store.getState();
        return findParentAt.call(
            this,
            issuesState,
            (node) => node.hierarchy_level,
            (node) => node.parent_issue_id,
            2
        );
    }

    get oee() {
    // common attribute with DeletedIssue
        return issueToOEE[this.oeeCategory.issue];
    }

    get hierarchy() {
        const result = [];

        let current = this;

        while (current) {
            result.push(current);
            current = current.parent;
        }

        return result;
    }
}
