import React, { forwardRef, ReactEventHandler, useEffect, useState } from "react"
import TextInput from "../Input/TextInput/TextInput"

const DAY_FORMAT = "DD MMM YYYY"
const MONTH_FORMAT = "MMM YYYY"

const DateRangeInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [text, setText] = useState("")

    useEffect(() => {
        setText("")
    }, [props.type])

    useEffect(() => {
        if (!props.start && !props.end) {
            setText("")
            return
        }

        if (props.start?.isSame(props.end)) {
            if (props.type === DisplayType.MONTH) {
                setText(props.start?.format(MONTH_FORMAT))
                return
            }

            setText(props.start?.format(DAY_FORMAT))
        }

        if (props.type === DisplayType.MONTH) {
            setText(`${props.start?.format(MONTH_FORMAT) ?? "Start time"} - ${props.end?.format(MONTH_FORMAT) ?? "End time"}`)
            return
        }

        setText(`${props.start?.format(DAY_FORMAT) ?? "Start time"} - ${props.end?.format(DAY_FORMAT) ?? "End time"}`)
    }, [props.start?.valueOf(), props.end?.valueOf])

    return <TextInput
        aria-readonly
        readOnly
        ref={ref}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onSelect={props.onSelect}
        onClick={props.onPress}
        value={text}
    />
})

export interface Props {
    placeholder?: string
    type?: DisplayType
    start?: Time.DateValue
    end?: Time.DateValue
    onFocus?: ReactEventHandler
    onBlur?: ReactEventHandler
    onSelect?: ReactEventHandler
    onPress?: ReactEventHandler
}

DateRangeInput.displayName = "DateRangeInput"

export enum DisplayType {DAY, MONTH}

export default DateRangeInput
