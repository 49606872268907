import { queryStringData } from '../../../legacy/utils/service';
import { makeRequest } from '../Shared/Shared.action';

// Get All Blocks
export const api_getBlocks = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/block`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createBlock = (entity_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/block`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_updateBlocks = (entity_id, data) => {
    const block_id = Array.isArray(data) ? data[0].block_id : data.block_id;

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/block/${block_id}/single`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_deleteBlock = (entity_id, block_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/block/${block_id}/reorder`,
            method: 'DELETE',
            params: JSON.stringify({}),
            success: resolve,
            error: reject,
        });
    });
};

export const api_getBlocksIssues = (entity_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/im/entity/${entity_id}`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};

export const api_getBlockIssues = (
    entity_id,
    block_id
) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/im/entity/${entity_id}/block/${block_id}`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};

export const api_updateBlocksIssues = (entity_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/im/entity/${entity_id}`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_createBlockUsers = (entity_id, block_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/block/${block_id}/users`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_deleteBlockUsers = (entity_id, block_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/block/${block_id}/users`,
            method: 'DELETE',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_getBlockOutput = (entityId, blockId, queryParams) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entityId}/block/${blockId}/output${queryStringData(queryParams)}`,
                method: 'GET',
                monitor: true,
                success: resolve,
                error: reject,
            });
        }
    );
};
  
