import "./ImpactReportsList.scss"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Button, List, ListItem, Typography } from "@mui/material"
import classNames from "classnames"
import { values } from "lodash"
import moment from "moment"
import React, { useState } from "react"
import type ReportModel from "../../../api/models/report.model"
import PlainTextInputWithSubmit from "../../../components/Input/PlainTextInputWithSubmit/PlainTextInputWithSubmit"
import ConfirmPopoverButton from "../../../components/Popover/ConfirmPopoverButton"
import { convertNumberToWord } from "../../../lib/utils/convertNumberToWord"
import { useAppSelector } from "../../../store/hooks"

const ImpactReportsList = (props: Props) => {
    const reports = useAppSelector(state => values(state.reports.reports).sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix()))
    const viewing = useAppSelector(state => state.reports.viewing)
    const [isEditable, setEditable] = useState(false)

    const title = isEditable ? "Select file name to rename/delete" : "Open saved reports"
    const icon = isEditable
        ? <Button variant="text" onClick={() => {
            setEditable(false)
        }}>Done</Button>
        : <Button variant="text" onClick={() => {
            setEditable(true)
        }}>
            <EditOutlinedIcon className="impact-reports-list__header__icon" sx={{ fontSize: 18 }}/>
        </Button>

    return (
        <div className="impact-reports-list">
            <div className="impact-reports-list__header">
                <div className="impact-reports-list__header__title">{title}</div>
                {reports.length !== 0 ? icon : null}
            </div>
            <div className="impact-reports-list__items">
                <List>
                    {
                        reports.map(report =>
                            <ListItem key={report.id} disablePadding>
                                {
                                    isEditable
                                        ? <ImpactReportsEditableListItem
                                            data={report} handleUpdate={props.handleUpdate}
                                            handleDestroy={props.handleDestroy}
                                        />
                                        : <ImpactReportsListItem
                                            isActive={viewing?.id === report.id}
                                            cursor="pointer"
                                            onClick={() => {
                                                props.handleClick(report)
                                            }}
                                            data={report}
                                        />
                                }
                            </ListItem>
                        )
                    }
                </List>

            </div>
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    handleClick: (report: ReportModel) => void
    handleUpdate: (reportId: number, reportName: string) => void
    handleDestroy: (blockId: number, reportId: number) => void
}

export default ImpactReportsList

const ImpactReportsListItem = (props: ItemProps) => {
    return (
        <div className={classNames({
            "impact-reports-list-item": true,
            "impact-reports-list-item--active": props.isActive
        })} style={{ cursor: props.cursor ?? "default" }} onClick={props.onClick}>
            <div className="impact-reports-list-item__name">{props.data.reportName ?? "No file name"}</div>
            <div className="impact-reports-list-item__date">{getLastUpdatedString(props.data.updatedAt)}</div>
        </div>
    )
}

interface ItemProps {
    data: any
    onClick?: () => void
    cursor?: string
    isActive: boolean
}

const ImpactReportsEditableListItem = (props: EditableItemProps) => {
    const [isEditing, setIsEditing] = useState(false)
    const [reportName, setReportName] = useState<string>(props.data.reportName ?? "")

    return isEditing
        ?
        <div className="impact-reports-list-item impact-reports-list-item--editable">
            <PlainTextInputWithSubmit
                id={"report-form-name-input"}
                value={reportName}
                onChange={setReportName}
                onEscape={() => {
                    setIsEditing(false)
                }}
                onSubmit={() => {
                    props.handleUpdate(props.data.id, reportName)
                    setIsEditing(false)
                }}
            />
            <ConfirmPopoverButton
                id={`deleteReportPopover-${props.data.id}`}
                onConfirm={() => {
                    props.handleDestroy(props.data.blockId, props.data.id)
                }}
            >
                <Typography sx={{ p: 2 }}>Delete {props.data.reportName}?</Typography>
            </ConfirmPopoverButton>
        </div>
        :
        <ImpactReportsListItem isActive={false} cursor="text" data={props.data} onClick={() => {
            setIsEditing(true)
        }}/>
}

interface EditableItemProps {
    data: any
    handleUpdate: (reportId: number, reportName: string) => void
    handleDestroy: (blockId: number, reportId: number) => void
}

// const DAY_SECONDS = 86400

const getLastUpdatedString = (updatedAt: string) => {
    const now = moment()
    const then = moment(updatedAt)
    const dayOfYearNow = now.dayOfYear()
    const dayOfYearThen = then.dayOfYear()

    if (dayOfYearNow - dayOfYearThen > 2) {
        return then.format("DD MMM YYYY")
    }

    if (dayOfYearNow - dayOfYearThen === 2) {
        return "two days ago"
    }

    if (dayOfYearNow - dayOfYearThen === 1) {
        return "one day ago"
    }

    const diffHours = now.hours() - then.hours()

    if (diffHours === 0) {
        return "an hour ago"
    }

    return `${convertNumberToWord(diffHours + 1)} hours ago`
}
