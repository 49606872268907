/* eslint-disable react/prop-types */
import React from 'react';
import * as d3 from 'd3';
import { AutoSizer } from 'react-virtualized';

import CONSTANTS from '../Constants';
import { Axis, getHslParams } from '.';
import { connect } from 'react-redux';
import { languagePreference } from '../../store/old/Preference/Preference.selector';
import { translator } from '../../lib/translate';

class OEEWaterfallChartSimple extends React.Component {
    constructor(props) {
        super(props);

        this.barHeight = 30;
        this.barSpacing = 10;

        this.translator = translator(this.props.lang)
    }

    recalculateAxes() {
        const { barHeight, barSpacing } = this;
        const { xScale, yScale, data } = this.props;

        this.xScale = xScale.copy().range([0, this.width]);
        this.yScale = yScale
            .copy()
            .range(data.map((d, i) => i * (barHeight + barSpacing)));
    }

    translate(string) {
        return this.translator(string)
    }

    get height() {
        const { barHeight, barSpacing } = this;
        const { margin, data } = this.props;
        return data.length * (barHeight + barSpacing) + margin.top + margin.bottom;
    }

    get width() {
        const { margin } = this.props;
        return this.props.width - margin.left - margin.right;
    }

    get yAxis() {
        const { data, margin, yAssessor } = this.props;
        const { yScale, barHeight } = this;

        const fontSize = 12;

        const losses = data
            .filter((d) => d.type === 'loss')
            .reduce((acc, curr) => {
                if (!acc[curr.loss]) acc[curr.loss] = [curr];
                else acc[curr.loss].push(curr);

                return acc;
            }, {});

        const ticks = Object.keys(losses).map((k) => {
            const minY = d3.min(losses[k], (d) => yScale(yAssessor(d)));
            const maxY = d3.max(losses[k], (d) => yScale(yAssessor(d)) + barHeight);
            const dy = (maxY - minY) / 2;

            return {
                y: minY + dy,
                loss: <tspan>{this.translate(CONSTANTS.OEE.LOSS[k])}</tspan>,
            };
        });

        return (
            <Axis
                ticks={ticks}
                position={{ top: margin.top, left: 10 }}
                getTickLabel={(d) => d.loss}
                attributes={{
                    fontSize: fontSize,
                    textAnchor: 'middle',
                    transform: (tick) => `translate(0, ${tick.y})rotate(-90)`,
                }}
            />
        );
    }

    get bars() {
        const { width } = this;
        const { data, margin, xAssessor, yAssessor, handleClick } = this.props;

        const { barHeight, barSpacing, xScale, yScale } = this;

        let last_time = d3.max(data, xAssessor);

        const textTranslateX = function (d) {
            if (d.type === 'time') return 6;

            return xScale(last_time) > width - 6
                ? width - 6
                : Math.max(6, xScale(last_time) - 6);
        };

        const textAnchorX = function (d) {
            if (d.type === 'time') return 'start';

            if (xScale(last_time) < 140) return 'start';
            return 'end';
        };

        return (
            <g transform={`translate(${margin.left}, ${margin.top})`}>
                {data.map((d, i) => {
                    const { isActive, opacity } = d;
                    const isTime = d.type === 'time';
                    d.type === 'loss' && (last_time -= xAssessor(d));

                    const x = xAssessor(d);
                    const y = yAssessor(d);

                    const cursor = d.disabled ? 'not-allowed' : 'pointer';
                    const color = getHslParams(d.color);
                    const fontSize = isTime ? 14 : 12

                    return (
                        <g
                            key={i}
                            onClick={(e) => !d.disabled && handleClick && handleClick(d)}
                            style={{ cursor, opacity }}
                        >
                            <rect
                                fill={color}
                                height={barHeight}
                                width={Math.max(xScale(Math.abs(x)), 0)}
                                y={yScale(y)}
                                x={isTime ? 0 : xScale(last_time)}
                                rx={2}
                                style={{
                                    strokeWidth: isActive ? 3 : 1,
                                    stroke: color,
                                }}
                                transform={`translate(${x < 0 ? -xScale(Math.abs(x)) : 0}, 0)`}
                            />
                            <text
                                y={yScale(y) + (barSpacing + barHeight / 2) - (fontSize / 2)}
                                x={textTranslateX(d)}
                                textAnchor={textAnchorX(d)}
                                fontSize={fontSize}
                                fontWeight={isActive || isTime ? 350 : 300}
                                fill="#222"
                                fontStyle={
                                    d.type === 'loss' && d.duration < 0 ? 'italic' : 'normal'
                                }
                            >
                                {this.translate(d.labelText)}
                            </text>
                        </g>
                    );
                })}
            </g>
        );
    }

    render() {
        this.recalculateAxes();

        return (
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id={this.props.id}
                    ref={(node) => (this.chartRef = node)}
                    width={this.props.width}
                    height={this.height}
                    style={{ fontFamily: 'Gill Sans, sans-serif', background: '#fff' }}
                >
                    {this.bars}
                    {this.yAxis}
                </svg>
            </div>
        );
    }
}


const mapStateToProps = (appState) => {
    return {
        lang: languagePreference(appState)
    };
};

const ConnectedOeeWaterfallChartSimple = connect(mapStateToProps, null)(OEEWaterfallChartSimple);

export class OEEWaterfallChart extends React.Component {
    render() {
        return (
            <AutoSizer disableHeight>
                {({ width, height }) => (
                    <ConnectedOeeWaterfallChartSimple
                        {...this.props}
                        width={width}
                        height={height}
                    />
                )}
            </AutoSizer>
        );
    }
}

