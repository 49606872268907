/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { Checkbox, TreeSelect } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { depthFirstTraversal, regexMatch } from '../utils/helpers';

import './AukFilterSelectMulti.scss';
import classNames from 'classnames';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

const AukFilterSelectMulti = (props) => {
    const { className, data, onChange, checkAllMenuOption = false } = props;

    const defaultChecked = useMemo(
        () =>
            data
                .map((d) => depthFirstTraversal(d))
                .reduce((acc, curr) => acc.concat(...curr), [])
                .map((d) => d.value),
        []
    );

    const [values, setValues] = useState(defaultChecked);
    const [search, setSearch] = useState('');

    const searchHits = useMemo(() => {
        const result = [];

        data.forEach((tree) =>
            depthFirstTraversal(tree, (node) => {
                regexMatch(node.title || node.label, search, { escape: true }) &&
          result.push(node);
            })
        );

        return result;
    }, [data, search]);

    useDidUpdateEffect(() => {
        onChange && onChange(values)
    }, [values])

    const checkedAll = useMemo(
        () => searchHits.length === values.length,
        [values, searchHits]
    );

    const treeSelectProps = { ...props };
    delete treeSelectProps.checkAllMenuOption;

    return (
        <TreeSelect
            maxTagCount={1}
            maxTagPlaceholder={<EllipsisOutlined />}
            treeCheckable
            searchValue={search}
            onSearch={setSearch}
            showSearch
            showArrow
            treeNodeFilterProp="title"
            {...treeSelectProps}
            autoClearSearchValue={false}
            className={classNames({
                'auk-multi-select': true,
                'auk-select-filter': true,
                'auk-select-filter-multi': true,
                [className]: !!className,
            })}
            treeData={data}
            value={values}
            onChange={setValues}
            onBlur={(e) => setSearch(search)} // prevent search from resetting when using checkbox
            dropdownRender={(menu) => {
                return checkAllMenuOption ? (
                    <>
                        <div style={{ padding: 16 }}>
                            <Checkbox
                                className="mb-1"
                                checked={checkedAll}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    checkedAll
                                        ? setValues([])
                                        : setValues(searchHits.map((d) => d.value));
                                }}
                            >
                Check / Uncheck All
                            </Checkbox>
                            <hr className="m-0" />
                        </div>
                        {menu}
                    </>
                ) : (
                    menu
                );
            }}
        />
    );
};

AukFilterSelectMulti.defaultProps = {
    checkAllMenuOption: false,
};

export default AukFilterSelectMulti;
