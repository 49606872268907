/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useContext, useState, createRef, memo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { HorizontalVirtualizeNoScroll } from '../../components/virtualized';
import { TargetSettingContext } from '.';
import {
    filter,
    findIndex,
    first,
    last,
} from 'lodash';
import moment from 'moment';
import { Permission } from '../../components/Permission';

let assetIndexCache = 0;

export const Resolution = memo(({ viewPortHeight }) => {
    const { period, arrayResolutions, rightGroupSize } = useContext(
        TargetSettingContext
    );
    const [isDrawing, setIsDrawing] = useState(false);
    const [arrayIndex, setArrayIndex] = useState([]);

    const CanvasResolution = createRef();
    const visibleItemsResolution = period.visibleItemsResolution;
    const arrayResolutionsLength = arrayResolutions.length;
    const colWidth = rightGroupSize / (visibleItemsResolution + 1) + 0.02;

    function renderRow({ index }) {
        return (
            <Permission forResource resource="targets" canDo="edit">
                <ItemResolution
                    parentResolutionRef={CanvasResolution}
                    index={index}
                    isDrawing={isDrawing}
                    setIsDrawing={setIsDrawing}
                    arrayIndex={arrayIndex}
                    setArrayIndex={setArrayIndex}
                    colWidth={colWidth}
                />
            </Permission>
        );
    }

    return (
        <div ref={CanvasResolution}>
            <HorizontalVirtualizeNoScroll
                colWidth={() => colWidth}
                viewPortHeight={viewPortHeight}
                dataLength={arrayResolutionsLength}
                numsOfVisibleItems={visibleItemsResolution}
                renderRow={renderRow}
                showLoading={false}
            />
        </div>
    );
});

const ItemResolution = memo(
    ({
        index,
        isDrawing,
        setIsDrawing,
        arrayIndex,
        setArrayIndex,
        parentResolutionRef,
    }) => {
        const {
            arrayResolutions,
            plans,
            setSelectedAssetIndex,
            invokeCreatePlan,
            selectedAssetIndex,
            period,
            enableStickyTimeLine,
            mouseTop,
            setMouseTop,
            setActiveAssetId,
            assetsDisplayed,
        } = useContext(TargetSettingContext);

        let classResolution = 'resolution ';

        const isExistIndex = findIndex(arrayIndex, (v) => v === index);

        if (isExistIndex > -1) {
            classResolution += 'clicked';
        }

        function generateAssetIndex(e) {
            const element = parentResolutionRef.current.getBoundingClientRect();
            const MousePointTop = e.clientY;

            const assetIndex = Math.floor((MousePointTop - element.top) / 60);

            if (!enableStickyTimeLine && !isDrawing && mouseTop !== 300) {
                setMouseTop(300);
            }

            if (
                enableStickyTimeLine &&
        !isDrawing &&
        assetIndexCache !== assetIndex
            ) {
                assetIndexCache = assetIndex;

                setMouseTop(MousePointTop - element.top);
            }

            return assetIndex;
        }

        function handleClick(e) {
            const assetIndex =
        selectedAssetIndex === '' ? generateAssetIndex(e) : selectedAssetIndex;

            const currentResolutionId = arrayResolutions[index].id;

            const asset = assetsDisplayed[assetIndex];

            if (!asset) return;

            const plansForAsset = filter(plans, (v) => v.asset_id === asset.asset_id);

            const filteredPlan = filter(plansForAsset, (v) => {
                if (
                    currentResolutionId > moment(v.to).valueOf() &&
          currentResolutionId < moment(v.from).valueOf()
                ) {
                    return v;
                }
            });

            if (filteredPlan.length === 0) {
                if (!isDrawing) {
                    return unstable_batchedUpdates(() => {
                        const newArray = [index];

                        setActiveAssetId(asset.asset_id);

                        setSelectedAssetIndex(assetIndex);
                        setArrayIndex(newArray);
                        setIsDrawing(true);
                    });
                }

                invokeCreatePlan(arrayIndex, setArrayIndex, asset.asset_id);
                setIsDrawing(false);
            }
        }

        function handleMouseMove(e) {
            const assetIndex =
        selectedAssetIndex === '' ? generateAssetIndex(e) : selectedAssetIndex;

            if (isDrawing && index >= first(arrayIndex)) {
                const hoursStep = period.resolution / 60;

                const newArray = [];
                const firstIndex = first(arrayIndex);
                const startId = arrayResolutions[firstIndex].id;
                const lastId = moment(arrayResolutions[index].id)
                    .add(hoursStep, 'hours')
                    .valueOf();

                const asset = assetsDisplayed[assetIndex];

                if (!asset) return;

                const plansForAsset = filter(
                    plans,
                    (v) => v.asset_id === asset.asset_id
                );

                const filteredPlan = filter(plansForAsset, (v) => {
                    if (
                        startId < moment(v.from).valueOf() &&
            lastId > moment(v.to).valueOf()
                    ) {
                        return v;
                    }

                    if (
                        lastId < moment(v.to).valueOf() &&
            lastId > moment(v.from).valueOf()
                    ) {
                        return v;
                    }

                    if (
                        startId < moment(v.to).valueOf() &&
            startId > moment(v.from).valueOf()
                    ) {
                        return v;
                    }
                });

                if (filteredPlan.length === 0) {
                    for (let i = firstIndex + 1; i <= index; i++) {
                        newArray.push(i);
                    }

                    const newArrayIndex = [first(arrayIndex)].concat(newArray);
                    setArrayIndex(newArrayIndex);
                }
            }

            if (isDrawing && index < first(arrayIndex)) {
                const newArray = [];
                const firstIndex = first(arrayIndex);

                for (let i = index; i < firstIndex; i++) {
                    newArray.push(i);
                }

                const newArrayIndex = [first(arrayIndex)].concat(newArray);
                setArrayIndex(newArrayIndex);
            }
        }

        const generateClassForTooltip = () => {
            let classNameTootipLine = 'tooltip-line ';
            let classNameTootipWrapper = 'tooltip-resolution-wrapper ';

            if (last(arrayIndex) > first(arrayIndex) && arrayIndex.length > 0) {
                classNameTootipLine += 'right';
                classNameTootipWrapper += 'right';
            }

            if (last(arrayIndex) < first(arrayIndex) && arrayIndex.length > 0) {
                classNameTootipLine = 'tooltip-line';
                classNameTootipWrapper = 'tooltip-resolution-wrapper ';
            }

            return {
                classNameTootipLine: classNameTootipLine,
                classNameTootipWrapper: classNameTootipWrapper,
            };
        };

        if (last(arrayIndex) > first(arrayIndex) && arrayIndex.length > 0) {
            const hoursStep = period.resolution / 60;

            return (
                <div
                    className="plan-col-resolution"
                    onClick={handleClick}
                    onMouseEnter={handleMouseMove}
                >
                    <div className={classResolution} />
                    <div className={generateClassForTooltip().classNameTootipLine}>
                        <div
                            className={generateClassForTooltip().classNameTootipWrapper}
                            style={{
                                top: assetsDisplayed.length < 6 ? 150 : `${mouseTop}px`,
                            }}
                        >
                            <div className="tooltip-resolution">
                                {index === 0
                                    ? moment(arrayResolutions[index].id).format('MMM DD - HH:mm')
                                    : moment(arrayResolutions[index].id)
                                        .add(hoursStep, 'hours')
                                        .format('MMM DD - HH:mm')}
                            </div>
                            <div className="tooltip-rectangle" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div
                className="plan-col-resolution"
                onClick={handleClick}
                onMouseMove={handleMouseMove}
            >
                <div className={classResolution} />
                <div className={generateClassForTooltip().classNameTootipLine}>
                    <div
                        className={generateClassForTooltip().classNameTootipWrapper}
                        style={{ top: assetsDisplayed.length < 6 ? 150 : `${mouseTop}px` }}
                    >
                        <div className="tooltip-resolution">
                            {moment(arrayResolutions[index].id).format('MMM DD - HH:mm')}
                        </div>
                        <div className="tooltip-rectangle" />
                    </div>
                </div>
            </div>
        );
    }
);
