import { makeRequest } from '../Shared/Shared.action';

export const api_getTrackedIssueComments = (entity_id, tracked_issue_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue/${tracked_issue_id}/comment`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createTrackedIssueComment = (entity_id, tracked_issue_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue/${tracked_issue_id}/comment`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
