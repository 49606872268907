import React, { useMemo } from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import { Select } from 'antd';

const APPOINTMENTS = {
    "Vice President/President": "Vice President/President",
    "Executive": "Executive",
    "Plant Manager/Director": "Plant Manager/Director",
    "Production Manager": "Production Manager",
    "Production Supervisor": "Production Supervisor",
    "Production Operator": "Production Operator",
    "Engineering Manager": "Engineering Manager",
    "Process Engineer": "Process Engineer",
    "CI/Lean Manager": "CI/Lean Manager",
    "CI/Lean Engineer": "CI/Lean Engineer",
    "Planning Manager": "Planning Manager",
    "Planner/Scheduler": "Planner/Scheduler",
    "Maintenance Manager": "Maintenance Manager",
    "Maintenance Engineer": "Maintenance Engineer",
    "Maintenance Technician": "Maintenance Technician",
    "Quality Inspector": "Quality Inspector",
    "Quality Engineer": "Quality Engineer",
    "Quality Manager": "Quality Manager",
    "IT Engineer": "IT Engineer",
    "IT Manager": "IT Manager",
    "Finance": "Finance",
    "Finance Manager": "Finance Manager",
    "Others": "Others",
}

const UserAppointmentSelect = (props) => {
    const { lang, translate } = useTranslate()

    const options = useMemo(() => ([
        {label: translate(APPOINTMENTS["Vice President/President"]), value: "Vice President/President"},
        {label: translate(APPOINTMENTS["Executive"]), value: "Executive"},
        {label: translate(APPOINTMENTS["Plant Manager/Director"]), value: "Plant Manager/Director"},
        {label: translate(APPOINTMENTS["Production Manager"]), value: "Production Manager"},
        {label: translate(APPOINTMENTS["Production Supervisor"]), value: "Production Supervisor"},
        {label: translate(APPOINTMENTS["Production Operator"]), value: "Production Operator"},
        {label: translate(APPOINTMENTS["Engineering Manager"]), value: "Engineering Manager"},
        {label: translate(APPOINTMENTS["Process Engineer"]), value: "Process Engineer"},
        {label: translate(APPOINTMENTS["CI/Lean Manager"]), value: "CI/Lean Manager"},
        {label: translate(APPOINTMENTS["CI/Lean Engineer"]), value: "CI/Lean Engineer"},
        {label: translate(APPOINTMENTS["Planning Manager"]), value: "Planning Manager"},
        {label: translate(APPOINTMENTS["Planner/Scheduler"]), value: "Planner/Scheduler"},
        {label: translate(APPOINTMENTS["Maintenance Manager"]), value: "Maintenance Manager"},
        {label: translate(APPOINTMENTS["Maintenance Engineer"]), value: "Maintenance Engineer"},
        {label: translate(APPOINTMENTS["Maintenance Technician"]), value: "Maintenance Technician"},
        {label: translate(APPOINTMENTS["Quality Inspector"]), value: "Quality Inspector"},
        {label: translate(APPOINTMENTS["Quality Engineer"]), value: "Quality Engineer"},
        {label: translate(APPOINTMENTS["Quality Manager"]), value: "Quality Manager"},
        {label: translate(APPOINTMENTS["IT Engineer"]), value: "IT Engineer"},
        {label: translate(APPOINTMENTS["IT Manager"]), value: "IT Manager"},
        {label: translate(APPOINTMENTS["Finance"]), value: "Finance"},
        {label: translate(APPOINTMENTS["Finance Manager"]), value: "Finance Manager"},
        {label: translate(APPOINTMENTS["Others"]), value: "Others"},
        ]), 
    [lang])

    return <Select {...props}>
        {
            options.map(o => <Select.Option key={o.value} value={o.value} title={o.label}>
                {o.label}
            </Select.Option>)
        }
    </Select>
}

export default UserAppointmentSelect