import "./Drawer.scss"
import { Button, Drawer as MuiDrawer, type PaperProps, type SxProps } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"

const Drawer = (props: Props) => {
    return <MuiDrawer
        anchor={props.anchor ?? "right"}
        open={props.open}
        variant={props.variant ?? "persistent"}
        PaperProps={props.PaperProps}
        hideBackdrop={props.hideBackdrop}
        sx={{
            "& .MuiPaper-root": {
                width: props.width ?? "100%",
                padding: "8px 16px"
            },
            ...props.sx
        }}
    >
        {props.header && <DrawerHeader>{props.header}</DrawerHeader>}
        {props.close && <Button style={{ position: "absolute", top: 8, right: 8 }} onClick={props.close}>
            <CloseIcon/>
        </Button>}
        {props.children}
    </MuiDrawer>
}

interface Props {
    children: React.ReactNode
    container?: Element | (() => Element | null) | null | undefined
    open: boolean
    anchor: "left" | "top" | "right" | "bottom"
    width?: string
    header?: React.ReactNode
    close?: () => void
    sx?: SxProps
    variant?: "permanent" | "persistent" | "temporary"
    hideBackdrop?: boolean
    PaperProps?: PaperProps
}

const DrawerHeader = (props: DrawerHeaderProps) => {
    return <div className="drawer-header">
        {props.children}
    </div>
}

interface DrawerHeaderProps {
    children: React.ReactNode
}

export default Drawer
