/* eslint-disable react/prop-types */
// LIBRARIES
import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Modal } from 'antd';
import { ceil, round, values } from 'lodash';
import { useNavigate } from 'react-router';

// COMPONENTS
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import BlockCSVExportModal from '../Blocks/BlockCSVExportModal';
import BlockTitle from '../Assets/shared/BlockTitle';
import ParetoLabelList from './ParetoLabelList';
import ParetoList from './ParetoList';
import ParetoWaterfall from './ParetoWaterfall';
import TrackedIssueEditor from '../../components/Modal/TrackedIssueEditor';
import { SPAWrapper } from '../../components/SPAWrapper';
import { RolePermission } from '../../components/Permission';

// HELPERS
import CONSTANTS from '../../Constants';
import paretoCSV from './Pareto.csv_exporter';
import { flash } from '../../components/Flash';
import { getDefaultResolution } from '../../utils/controls';
import { oeePareto } from '../../utils/oee';
import { saveCsv, savePng, saveSvg } from '../../utils/dataExports';
import { getBlocksTreeData } from '../../utils/blocks';

// ACTIONS
import { createTrackedIssueRequest } from '../../../store/old/TrackedIssues/TrackedIssues.action';

import './ParetoAnalysis.scss';
import { ParetoContext } from '.';
import { generateUrlQuery } from '../../utils/url';
import Counter from '../../../components/Counter/Counter';
import { Box, Typography } from '@mui/material';
import findMeanTimeToRepair from '../../../lib/utils/findMeanTimeToRepair';
import findMeanTimeBetweenFailure from '../../../lib/utils/findMeanTimeBetweenFailure';
import { MttrTooltipContent, MtbfTooltipContent } from './ParetoCounterTooltips'
import { blocksSelector } from '../../../store/old/Blocks/Blocks.selector';
import useTranslate from '../../../hooks/useTranslate';

const EXPORT_KEY = 'export';
const CLASSIC_EXPORT_KEY = 'classic_export';

const oee1Fields = new Set(['ct', 'na', 'us', 'pd']);
const disabledFields = new Set(['na', 'uu']);
const loaIssues = new Set(['us', 'pd']);

const secondsToHours = (seconds) => (seconds / 3600).toFixed(2);
const durationPercent = (dur, base) => ((dur / base) * 100).toFixed(1);

const ParetoAnalysisFC = (props) => {
    const { block } = props;
    const { translate } = useTranslate();
    const blocks = useSelector(blocksSelector)

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {
        oeeData,
        labels,
        trackedIssues,
        controls: { oee2 },
        masks,
        issues,
    } = useContext(ParetoContext);

    const [showCSVExport, setShowCSVExport] = useState(false);
    const [showTrackedIssueEditor, setShowTrackedIssueEditor] = useState(false);
    const [trackedIssueParams, setTrackedIssueParams] = useState({});
    const [active, setActive] = useState(null);
    const [selection, setSelection] = useState(null);

    const clickToBlockView = () => {
        if (block.asset) {
            return navigate(`${CONSTANTS.URLS.ASSET}/${block.block_id}`);
        }

        return navigate(`${CONSTANTS.URLS.BLOCK}/${block.block_id}`);
    };

    const oeeWaterfallRaw = useMemo(() => block.oee.waterfall, [oeeData]);
    const oeeParetoRaw = useMemo(() => oeePareto(block.oee.effective_labels), [oeeData]);

    const clickCSVMenu = ({ key }) => {
        key === EXPORT_KEY ? setShowCSVExport(true) : download();
    };

    // waterfall
    const oeeWaterfallData = useMemo(() => {
        const scheduledTime = oeeWaterfallRaw.find(({ key }) => key === 'pt');
        return oeeWaterfallRaw.map((d, i) => {
            const isActive = active ? d.name === active.name : false;
            const label_percent_duration = oee2
                ? durationPercent(d.duration, scheduledTime.duration)
                : d.percent_duration.toFixed(1);
            const key = d.type === 'time'
                ? translate(d.name)
                : masks[d.key]
                    ? masks[d.key].abbreviation.toUpperCase()
                    : d.key.toUpperCase();

            const durHours = secondsToHours(d.duration) + 'hrs';

            let percentDur = `${
                oee2 ? 'OEE2: ' : 'OEE1: '
            } ${label_percent_duration}%`;

            const labelText = `${key} (${durHours}, ${percentDur})`;

            const validOEE2 = !oee1Fields.has(d.key);
            const alwaysDisable = disabledFields.has(d.key);
            const disabled =
        d.type === 'time'
            ? true
            : alwaysDisable
                ? true
                : oee2
                    ? !validOEE2
                    : false;
            const opacity = oee2 ? (validOEE2 ? 1 : 0.25) : 1;

            return {
                ...d,
                isActive,
                labelText,
                opacity,
                disabled,
            };
        });
    }, [oee2, masks, oeeWaterfallRaw, active]);

    // pareto
    const paretoTitle = useMemo(() => {
        if (active) {
            const loss = active.loss.toUpperCase();
            const name = masks[active.key] ? masks[active.key].mask : active.name;
            return loss + ' - ' + name;
        }
        return 'All Labels';
    }, [active]);

    const isTracked = useCallback(
        (issue) => {
            if (!block.asset) return false;

            return trackedIssues.find((item) => {
                return (
                    issue &&
          item.issue_id === issue.issue_id &&
          item.assets.find((asset) => asset.asset_id === block.asset.asset_id)
                );
            });
        },
        [trackedIssues]
    );

    const trackIssue = useCallback(
        (d) => {
            const { asset } = block;
            if (!asset) return;

            const { asset_id } = asset;

            if (!issues[d.issue.issue_id]) {
                return flash({
                    message: 'Cannot track a deleted issue',
                    status: 'warning',
                });
            }

            if (d.isTracked) {
                return flash({
                    message: 'This issue is being tracked',
                    status: 'info',
                });
            }

            setShowTrackedIssueEditor(true);
            setTrackedIssueParams({
                assets: [{ asset_id }],
                issue_id: d.issue && d.issue.issue_id,
            });
        },
        [issues]
    );

    const oeeParetoData = useMemo(() => {
        if (!oeeWaterfallRaw.length) return [];

        const duration = oee2
            ? oeeWaterfallRaw.find(({ key }) => key === 'pt').duration
            : oeeWaterfallRaw.find(({ key }) => key === 'ct').duration;

        const filtered = oeeParetoRaw.filter((d) => {
            const isOEE2 = oee2
                ? d.issue && d.issue.oee
                    ? !loaIssues.has(d.issue.oee)
                    : true
                : true;

            const isActive = active ? d.issue.oee === active.key : true;
            return isOEE2 && isActive;
        });

        const maxWidth = filtered.reduce(
            (acc, curr) =>
                curr.total_effective_duration > acc
                    ? curr.total_effective_duration
                    : acc,
            1
        );

        return filtered.map((d) => {
            const durationHours = (d.total_effective_duration / 3600).toFixed(2);
            const durationPercent = ((d.total_effective_duration / duration) *100).toFixed(1);

            const widthPercent = round(d.total_effective_duration / maxWidth, 2) * 100;

            return {
                ...d,
                issue: d.issue,
                color: d.issue.color,
                width: widthPercent,
                durationHours,
                durationPercent,
                label: (() =>
                    `${d.issue.name || 'DELETED'} (${durationHours}hrs, ${
                        oee2 ? 'OEE2: ' : 'OEE1: '
                    } ${durationPercent}%)`)(),
                isTracked: isTracked(d.issue),
            };
        });
    }, [oeeParetoRaw, active, masks, oee2, issues]);

    // modal
    const assetsBlocksMap = useMemo(() => {
        return values(blocks).reduce((acc, curr) => {
            if (!curr.asset) return acc;
            return { ...acc, [curr.asset.asset_id]: curr }
        }, {})
    }, [blocks]);

    const getLabelUrl = useCallback((l, blockId) => {
        const startDate = moment(l.from);
        const endDate = l.to ? moment(l.to) : moment();
        const duration = endDate.diff(startDate, 'seconds');
        const resolution = getDefaultResolution(duration);
        const pathname = `${CONSTANTS.URLS.ASSET}/${blockId}`;
        
        const query = generateUrlQuery({ startDate, endDate, resolution, span: 'Custom' });
        return `${pathname}${query}`
    }, []);

    const modalContent = useMemo(() => {
        if (!selection) return null;
        const data = block.oee.effective_labels
            .filter((l) => l.issue_id === +selection.issue.issue_id)
            .map((d) => {
                const assetBlock = assetsBlocksMap[d.asset_id];
                return { label: { ...d, asset: assetBlock.asset }, url: getLabelUrl(d, assetBlock.block_id) }
            });

        return <ParetoLabelList data={data} />;
    }, [assetsBlocksMap, selection, oeeData]);

    const breakdowns = useMemo(() => block.asset ? block.oee.effective_labels.filter(l => l.oee === 'bd') : [], [block.oee])
    const mtbf = useMemo(() => block.asset ? findMeanTimeBetweenFailure(block.oee.waterfall, breakdowns) : undefined, [block.oee, breakdowns])
    const mttr = useMemo(() => block.asset ? findMeanTimeToRepair(breakdowns) : undefined, [breakdowns])

    const download = useCallback(() => {
        if (!oeeWaterfallRaw.length) return;

        const csvStr = paretoCSV(oeeWaterfallRaw, oeeParetoRaw, block.asset ? { mtbf, mttr } : null, block);
        saveCsv(csvStr, 'pareto_export.csv');
    }, [oeeWaterfallRaw, oeeParetoRaw, mtbf, mttr]);

    return (
        <SPAWrapper className="pareto-analysis d-flex flex-column">
            <BlockCSVExportModal
                visible={showCSVExport}
                onCancel={() => setShowCSVExport(false)}
                block={block}
            />
            <Box sx={{ display: 'flex' }}>
                <BlockTitle
                    id={block ? block.block_id : null}
                    getTreeData={(node) => getBlocksTreeData(node)}
                >
                    <AukTooltip.Help title={translate('timeseries')}>
                        <AukButton.Outlined
                            className="auk-button--round mr-2"
                            onClick={clickToBlockView}
                            disabled={!block}
                        >
                            <i className="fas fa-chart-bar" />
                        </AukButton.Outlined>
                    </AukTooltip.Help>

                    <RolePermission accessLevel="editor">
                        <AukTooltip.Help title={translate('export')}>
                            <AukButton.Dropdown
                                disabled={!block}
                                className="auk-button--round mr-2"
                                icon={<i className="fas fa-download" />}
                                placement="bottomLeft"
                                trigger={['click']}
                                overlay={
                                    <Menu onClick={clickCSVMenu}>
                                        <Menu.Item key={EXPORT_KEY}>Export</Menu.Item>
                                        <Menu.Item key={CLASSIC_EXPORT_KEY}>Classic Export</Menu.Item>
                                    </Menu>
                                }
                            />
                        </AukTooltip.Help>
                    </RolePermission>
                </BlockTitle>
                {
                    block.asset ?
                        <Box sx={{ display: 'flex' }}>
                            <Counter 
                                label={<Typography variant="body2">MTBF</Typography>}
                                withTooltip
                                tooltipContent={<MtbfTooltipContent/>}
                                value={mtbf > 0 ? <ParetoCounterValue seconds={mtbf}/> : undefined}
                                placeholder="--"
                            />
                            <Counter
                                label={<Typography variant="body2">MTTR</Typography>}
                                withTooltip
                                tooltipContent={<MttrTooltipContent/>}
                                value={mttr > 0 ? <ParetoCounterValue seconds={mttr}/> : undefined}
                                placeholder="--"
                            />        
                        </Box>
                        : null
                }
            </Box>
            <div className="d-flex h-100" style={{ overflow: 'hidden', marginTop: '0.5em' }}>
                <ParetoWaterfall
                    data={oeeWaterfallData}
                    handleClick={(d) =>
                        d.issue_id && setActive(active && active.key === d.key ? null : d)
                    }
                />
                <div className="w-50 px-2">
                    {oeeParetoData.length ? (
                        <ParetoList
                            title={paretoTitle}
                            data={oeeParetoData}
                            handleTrack={(d) => trackIssue(d)}
                            handleInspect={(d) => setSelection(d)}
                        />
                    ) : null}
                </div>
                <TrackedIssueEditor
                    show={showTrackedIssueEditor}
                    data={trackedIssueParams}
                    save={(d) =>
                        dispatch(
                            createTrackedIssueRequest(d, () => {
                                flash({ message: 'Created new tracked issue' });
                                setShowTrackedIssueEditor(false);
                            })
                        )
                    }
                    toggle={() => setShowTrackedIssueEditor(false)}
                />
            </div>
            <Modal
                className="pareto-modal"
                width={780}
                title={selection ? selection.issue.issue : ''}
                visible={!!selection}
                onCancel={() => setSelection(null)}
                footer={
                    <div className="d-flex justify-content-end">
                        <AukButton.Blue onClick={() => setSelection(null)}>
              Ok
                        </AukButton.Blue>
                    </div>
                }
            >
                {modalContent}
            </Modal>
        </SPAWrapper>
    );
};

export default ParetoAnalysisFC;

const ParetoCounterValue = (props) => {
    const minutes = ceil(props.seconds / 60, 1);
    const hours = ceil(props.seconds / 3600, 1)
    return <div className="d-flex flex-column">
        <Typography sx={{ fontSize: "16px" }}>{hours.toLocaleString()} hours</Typography>
        <Typography sx={{ fontSize: "16px" }}>({minutes.toLocaleString()} minutes)</Typography>
    </div>
}

