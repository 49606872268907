/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Avatar, Menu, Popover, Tag } from 'antd';

import AukButton from './AukButton';
import AukTooltip from './AukTooltip';

import './Avatar.scss';
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = (props) => {
    const { nameFull, name_last, name_first, image } = props.user;
    const abbrev = (name_first[0] + name_last[0]).toUpperCase();
    const avatar = image ? <Avatar src={image} /> : <Avatar>{abbrev}</Avatar>;

    return <AukTooltip.Info title={nameFull}>{avatar}</AukTooltip.Info>;
};

export default UserAvatar;

export const UserNameWithIcon = (props) => {
    const {
        user,
        user: { nameFull, image, email, mobile },
    } = props;

    const icon = useMemo(
        () => (
            <span className="user-square-icon mr-1">
                {image ? (
                    <img src={image} />
                ) : (
                    <div className="user-square-placeholder">
                        <UserOutlined />
                    </div>
                )}
            </span>
        ),
        [image]
    );

    const content = (
        <div className="user-name-icon-popover-content-wrapper">
            {icon}
            <div className="user-name-icon-popover-content">
                <div className="text-overflow-ellipsis">{nameFull}</div>
                <div className="text-overflow-ellipsis">{email}</div>
                {mobile && <div className="text-overflow-ellipsis">{mobile}</div>}
            </div>
        </div>
    );

    return (
        <Popover content={content} color="gray">
            <Tag
                className="p-1 user-name-with-icon text-overflow-ellipsis"
                icon={icon}
                color={'volcano'}
            >
                {nameFull}
            </Tag>
        </Popover>
    );
};

export const UsersCondensed = (props) => {
    const { users } = props;
    if (!users.length) return null;

    const [firstUser] = users;
    if (users.length === 1)
        return (
            <div className="users-condensed">
                <UserAvatar user={firstUser} />
            </div>
        );

    const icon = <>+{users.length - 1}</>;

    return (
        <div className="users-condensed">
            <UserAvatar user={firstUser} />
            <AukButton.Dropdown
                className="auk-button--round users-dropdown"
                icon={icon}
                overlay={
                    <Menu>
                        {users.slice(1, users.length).map((u) => (
                            <Menu.Item key={u.user_id}>{u.nameFull}</Menu.Item>
                        ))}
                    </Menu>
                }
            />
        </div>
    );
};
