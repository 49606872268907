import { createSelector } from 'reselect';

export const skuState = (appState) =>
    appState.sku;

export const skusSelector = createSelector(skuState, (v) => v.skus);

export const arraySkus = (skusResource) => {
    // const skuMap = skuState(appState).skus;
    return Object.keys(skusResource)
        .map((sku_id) => skusResource[+sku_id])
        .sort((a, b) => {
            if (a.codeName < b.codeName) return -1;
            if (a.codeName > b.codeName) return 1;
            return 0;
        });
};
