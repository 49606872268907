import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import EntityPreferenceForm from './EntityPreferences';
import { NoAccess } from '../../components/None';
import { SPAWrapper } from '../../components/SPAWrapper';

import './index.scss';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import EntityForm from './EntityForm';

const { TabPane } = Tabs;

const EntityManagement = (props) => {
    const authUser = useSelector(userSelector);
    const userHasEditPermission = useMemo(
        () =>
            authUser.role_name === 'owner' ||
      authUser.check_resource_policy('entity_preferences', false, true, false),
        [authUser]
    );

    return (
        <SPAWrapper className="entity-config">
            {userHasEditPermission ? (
                <Tabs destroyInactiveTabPane className="entity-tabs" type="card">
                    <TabPane tab="Preferences" key="1">
                        <EntityPreferenceForm />
                    </TabPane>
                    <TabPane tab="Settings" key="2">
                        <EntityForm />
                    </TabPane>
                </Tabs>
            ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <NoAccess />
                </div>
            )}
        </SPAWrapper>
    );
};

export default EntityManagement;