import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { Panel, PanelBody, PanelFooter } from '../../components/Panel';
import { Form, Input, Select, TimePicker } from 'antd';
import AukButton from '../../components/AukButton';
import { updateEntity } from '../../../store/old/Entity/Entity.action';
import { flash } from '../../components/Flash';
import moment from 'moment';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { RolePermission } from '../../components/Permission';
import CONSTANTS from '../../Constants';
import { uniqBy } from 'lodash';

const TIMEZONES = uniqBy(CONSTANTS.TIMEZONES, (tz) => tz.offset)
    .filter((tz) => tz.offset.split(':')[1] === '00')
    .map((tz) => ({
        value: tz.offset,
        label: tz.offset,
    }));

const FIELDS = {
    ENTITY_NAME: 'entity_name',
    TIMEZONE: 'timezone',
    DAY_START: 'day_start',
};

const VALIDATE = {
    ENTITY_NAME: [
        {
            required: true,
            message: 'Entity name is required.',
        },
        {
            whitespace: true,
            message: 'Entity name is required.',
        },
    ],
};

const ENTITY_NAME_MAXLENGTH = 50;
const DISABLED_MINUTES = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
    42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

const EntityForm = (props) => {
    const data = useSelector(currentEntitySelector);
    const user = useSelector(userSelector);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { entity_id, entity_name, timezone, day_start } = data;

    const submit = (formData) => {
        const params = {
            entity_id,
            entity_name: formData.entity_name,
            day_start: formData.day_start ? formData.day_start.toISOString() : null,
        };

        if (user.role_name === 'admin') {
            params.timezone = formData.timezone.trim();
        }

        dispatch(updateEntity(params, () => flash({ message: 'Update success' })));
    };

    return (
        <Panel>
            <Form
                name="entityForm"
                id="entityForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="entity-form"
                onFinish={() => submit(form.getFieldsValue(true))}
            >
                <PanelBody>
                    <div className="entity-form__fields">
                        <Form.Item
                            colon={false}
                            name={FIELDS.ENTITY_NAME}
                            label="Entity Name"
                            initialValue={entity_name}
                            rules={VALIDATE.ENTITY_NAME}
                        >
                            <Input maxLength={ENTITY_NAME_MAXLENGTH} />
                        </Form.Item>
                        <RolePermission accessLevel="admin">
                            <Form.Item
                                colon={false}
                                name={FIELDS.TIMEZONE}
                                label="Timezone"
                                initialValue={timezone}
                            >
                                <Select options={TIMEZONES} allowClear={false} />
                            </Form.Item>
                        </RolePermission>
                        <Form.Item
                            colon={false}
                            name={FIELDS.DAY_START}
                            label="Start of Day"
                            initialValue={day_start ? moment(day_start) : null}
                        >
                            <TimePicker
                                className="w-100"
                                format={'HH:mm'}
                                disabledTime={() => ({
                                    disabledMinutes: () => DISABLED_MINUTES,
                                })}
                                placeholder="Select time"
                                showNow={false}
                            />
                        </Form.Item>
                    </div>
                </PanelBody>
                <PanelFooter className="p-2">
                    <AukButton.Reset
                        className="mr-2"
                        onClick={() => form.resetFields()}
                    />
                    <AukButton.Save htmlType="submit" />
                </PanelFooter>
            </Form>
        </Panel>
    );
};

export default EntityForm;
