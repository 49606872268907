import "./style.scss"
import React, { JSXElementConstructor } from "react"
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid"

const SimpleTable = <T,>(props: Props<T>) => {
    const handleSelect = (rowSelectionModel: GridRowSelectionModel) => {
        if (props.onSelect) {
            props.onSelect(rowSelectionModel)
        }
    }

    const handleRowClick = (params: GridRowParams) => {
        if (props.onRowClick) {
            props.onRowClick(params.row)
        }
    }

    return <DataGrid
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[10]}
        initialState={{
            pagination: {
                paginationModel: {
                    pageSize: 10,
                },
            },
        }}
        loading={props.loading}
        rows={props.rows}
        columns={props.columns}
        checkboxSelection={props.onSelect !== undefined}
        onRowClick={handleRowClick}
        onRowSelectionModelChange={handleSelect}
        slots={{ noRowsOverlay: props.placeholder }}
    />
}

interface Props<T> {
    onSelect?: (ids: (string | number)[]) => void
    onRowClick?: (value: any) => void
    rows: T[]
    columns: GridColDef[]
    loading?: boolean
    placeholder?: JSXElementConstructor<any>
}

export default SimpleTable
