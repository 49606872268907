/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { depthFirstTraversal } from '../../utils/helpers';
import { cloneBlockTree } from '../../utils/blocks';
import BlocksTreeNode from './BlocksTreeNode';
import './BlocksTree.scss';

const getInsertIndex = (targetOrder, insert) => {
    switch (insert) {
    case -1:
        return targetOrder - 1;
    case 0:
        return 0;
    default:
        return targetOrder;
    }
};

const getBlocksTreeMap = (block) => {
    const map = {};
    depthFirstTraversal(block, (node) => (map[node.block_id] = node));

    return map;
};

const BlocksTree = (props) => {
    const { draggable, data, selectable, canSelect, onSelect, onDrop } = props;

    const [treeData, setTreeData] = useState([cloneBlockTree(data)]);
    const [selected, setSelected] = useState(props.selected);

    useEffect(() => setSelected(props.selected), [props.selected]);
    useEffect(() => {
        setTreeData([cloneBlockTree(data)]);
    }, [data]);

    const treeMap = useMemo(() => getBlocksTreeMap(treeData[0]), [treeData]);
    const originalTreeMap = useMemo(() => getBlocksTreeMap(data), [data]);

    const handleDrop = useCallback(
        (dragNode, dropTarget, insert) => {
            if (isEqual(dragNode, dropTarget)) return;

            const dragged = treeMap[dragNode.block_id];
            const draggedParent = treeMap[dragNode.parent_block_id];

            // if insert value is 0, dropTarget is the new parent of the dragged node
            // if insert value is not 0, dropTarget is the new sibling of the dragged node
            // drop ALWAYS refers to the new parent node
            const drop =
        treeMap[
            insert === 0 ? dropTarget.block_id : dropTarget.parent_block_id
        ];

            dragged.parent_block_id = drop.block_id;
            dragged.hierarchy_level = drop.hierarchy_level + 1;

            draggedParent.children = draggedParent.children
                .filter((c) => c.block_id !== dragged.block_id)
                .map((c, i) => {
                    c.order = i + 1;
                    return c; // mutate original object for better performance
                });

            const insertIndex = getInsertIndex(dropTarget.order, insert);

            drop.children.splice(insertIndex, 0, dragged);
            drop.children = drop.children.map((c, i) => {
                c.order = i + 1;
                return c; // mutate original object for better performance
            });

            setTreeData(treeData.concat());
        },
        [treeMap, treeData]
    );

    const updateSelection = useMemo(() => {
        const result = [];
        depthFirstTraversal(treeData[0], (n) => {
            const originalBlock = originalTreeMap[+n.block_id];

            if (!originalBlock) return;

            if (
                originalBlock.parent_block_id !== n.parent_block_id ||
        originalBlock.order !== n.order ||
        originalBlock.hierarchy_level !== n.hierarchy_level
            ) {
                return result.push({
                    block_id: +n.block_id,
                    order: n.order,
                    parent_block_id: n.parent_block_id,
                    hierarchy_level: n.hierarchy_level,
                });
            }
        });

        return result.sort((a, b) => a.hierarchy_level - b.hierarchy_level);
    }, [treeData, originalTreeMap]);

    const handleClick = useCallback(
        (node) => {
            if (canSelect && !canSelect(node)) return;
            const result = node.block_id !== selected ? node : null;
            setSelected(result ? result.block_id : -1);
            onSelect && onSelect(result);
        },
        [selected]
    );

    useEffect(() => onDrop && onDrop(updateSelection), [updateSelection]);

    const dndCardProps = () => ({ handleDrop });
    const selectCardProps = (node) => ({
        handleClick,
        selected: selected === node.block_id,
    });

    const cardProps = useCallback(
        (node) => {
            let result = {};
            draggable && (result = { ...dndCardProps(node) });
            selectable && (result = { ...selectCardProps(node), ...result });
            return result;
        },
        [treeMap, treeData, selected]
    );

    const treeNodes = useMemo(() => {
        const populate = (nodes) => {
            const children = [];

            nodes.forEach((node) => {
                children.push(
                    <BlocksTreeNode
                        key={node.block_id}
                        node={node}
                        draggable={draggable}
                        selectable={selectable}
                        {...cardProps(node)}
                    >
                        {!!node.children && node.children.length
                            ? populate(node.children)
                            : null}
                    </BlocksTreeNode>
                );
            });

            return <div className="blocks-tree__nodes">{children}</div>;
        };

        return <>{populate(treeData)}</>;
    }, [treeData, selected, draggable]);

    return (
        <div className="blocks-tree">
            <div className="blocks-tree__body">{treeNodes}</div>
        </div>
    );
};

BlocksTree.defaultProps = {
    draggable: false,
    selectable: false,
    selected: -1,
    data: [],
    defaultSelected: -1,
};

export default BlocksTree;
