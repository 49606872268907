import { makeRequest } from '../Shared/Shared.action';

// login
export const api_authenticateUser = (data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: 'v1/login',
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Logout user
export const api_logoutUser = () => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: 'v1/logout',
            method: 'POST',
            params: JSON.stringify({}),
            success: resolve,
            error: reject,
        });
    });
};

// User Update Password
export const api_updateUserPassword = (request) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/user/${request.user_id}/password`,
            method: 'PUT',
            params: JSON.stringify(request.data),
            success: resolve,
            error: reject,
        });
    });
};

// User reset password
export const api_resetUserPassword = (request) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: 'v1/password',
            method: 'POST',
            params: JSON.stringify(request),
            success: resolve,
            error: reject,
        });
    });
};

// User verify email
export const api_verifyUserEmail = (request) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/user/${request.user_id}/verify`,
            method: 'PUT',
            params: JSON.stringify(request.data),
            success: resolve,
            error: reject,
        });
    });
};

// User reset password with code
export const api_resetUserPasswordWithCode = (request) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: 'v1/password',
            method: 'PUT',
            params: JSON.stringify({
                email: request.email,
                reset_code: request.reset_code,
                password: request.password,
                confirm_password: request.confirm_password,
            }),
            success: resolve,
            error: reject,
        });
    });
};

// Sign Up
export const api_signupUser = (request) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: 'v1/user',
                method: 'POST',
                params: JSON.stringify(request),
                success: resolve,
                error: reject,
            });
        }
    );
};
