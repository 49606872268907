// import { combineReducers } from 'redux';

// // REDUCERS
// import { connectRouter } from 'connected-react-router';
// import { authenticationReducer } from './Authentication/Authentication.reducer';
// import { preferencesReducer } from './Preference/Preference.reducer';
// import { entitiesReducer } from './Entity/Entity.reducer';
// import { assetsReducer } from './Assets/Assets.reducer';
// import { devicesReducer } from './Devices/Devices.reducer';
// import { metadataReducer } from './Metadata/Metadata.reducer';
// import { fusionsReducer } from './Fusions/Fusions.reducer';
// import { tilesReducer } from './Tiles/Tiles.reducer';
// import { uiReducer } from './UI/uiReducer';
// import { skusReducer } from './Sku/Sku.reducer';
// import { permissionsReducer } from './Permission/Permission.reducer';
// // import { LocalizationReducer } from '@aukindustries/auk-components';
// import { blocksReducer } from './Blocks/Blocks.reducer';
// import { issuesReducer } from './Issues/Issues.reducer';
// import { usersReducer } from './User/User.reducer';
// import { groupsReducer } from './Group/Group.reducer';
// import { notificationsReducer } from './Notifications/Notifications.reducer';
// import { correlationsReducer } from './Correlations/Correlations.reducer';
// import { schedulesReducer } from './Schedules/Schedules.reducer';
// import { labelsReducer } from './Labels/Labels.reducer';
// import { dataReducer } from './Data/Data.reducer';
// import { targetsReducer } from './Target/Target.reducer';
// import { trackedIssuesReducer } from './TrackedIssues/TrackedIssues.reducer';
// import { gatewaysReducer } from './Gateway/Gateway.reducer';
// import { invitationsReducer } from './Invitation/Invitation.reducer';
import CONSTANTS from '../../legacy/Constants';
// import { canvassesReducer } from './Canvas/Canvas.reducer';
// import { widgetsReducer } from './Widgets/Widget.reducer';
import { updateStoreRedux } from '../../legacy/utils/redux';

const initialAppState = { initialized: false, systemDowntime: null };

export const appDowntime = (system_downtime) => {
    return {
        type: CONSTANTS.REDUX_STORE.SYSTEM_DOWNTIME,
        payload: { systemDowntime: system_downtime },
    };
};

export const appInit = () => {
    return {
        type: CONSTANTS.REDUX_STORE.INITIALIZE,
    };
};

export const appUninit = () => {
    return {
        type: CONSTANTS.REDUX_STORE.UNINITIALIZE,
    };
};

export const appReducer = (state = initialAppState, action) => {
    if (action.type === CONSTANTS.REDUX_STORE.SYSTEM_DOWNTIME) {
        return updateStoreRedux(state, {
            systemDowntime: action.payload.systemDowntime,
        });
    }

    if (action.type === CONSTANTS.REDUX_STORE.INITIALIZE) {
        return updateStoreRedux(state, { initialized: true });
    }

    if (action.type === CONSTANTS.REDUX_STORE.INITIALIZE) {
        return updateStoreRedux(state, { initialized: true });
    }

    if (action.type === CONSTANTS.REDUX_STORE.UNINITIALIZE) {
        return updateStoreRedux(state, { initialized: false });
    }

    return updateStoreRedux(state, {});
};

// const allReducers = (history) =>
//     combineReducers({
//         auth: authenticationReducer,
//         assets: assetsReducer,
//         blocks: blocksReducer,
//         devices: devicesReducer,
//         metadata: metadataReducer,
//         fusions: fusionsReducer,
//         tiles: tilesReducer,
//         // localization: LocalizationReducer,
//         entity: entitiesReducer,
//         preference: preferencesReducer,
//         permission: permissionsReducer,
//         router: connectRouter(history),
//         ui: uiReducer,
//         sku: skusReducer,
//         issues: issuesReducer,
//         user: usersReducer,
//         group: groupsReducer,
//         label: labelsReducer,
//         data: dataReducer,
//         notifications: notificationsReducer,
//         correlations: correlationsReducer,
//         schedules: schedulesReducer,
//         target: targetsReducer,
//         trackedIssues: trackedIssuesReducer,
//         gateway: gatewaysReducer,
//         invitation: invitationsReducer,
//         app: AppReducer,
//         canvas: canvassesReducer,
//         widgets: widgetsReducer,
//     });

// const rootReducer = (history) => (state, action) => {
//     if (action.type === CONSTANTS.REDUX_STORE.RESET) {
//         state = { localization: state.localization, app: { ...initialAppState } };
//     }

//     return allReducers(history)(state, action);
// };

// export default rootReducer;
