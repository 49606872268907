import "./style.scss"
import React, { useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import Grid from "../../../components/Grid/Grid"
import Page from "../../../components/Page"
import { objectivesViewAction } from "../../../store/actions/goals.action"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import ObjectivePageContents from "./components/ObjectivePageContents/ObjectivePageContents"
import ObjectivePageHeader from "./components/ObjectivePageHeader/ObjectivePageHeader"
import { Box } from "@mui/material"
import ProcessTreeSelect from "../../../components/ProcessSelector/ProcessTreeSelector"
import TextButton from "../../../components/Button/TextButton/TextButton"
import { selectCurrentProcess, selectObjective } from "../../../store/selectors/goals.selectors"
import { setCurrentProcess } from "../../../store/reducers/goals.reducer"

const ObjectivePage = () => {
    const dispatch = useAppDispatch()
    const viewing = useAppSelector(selectObjective)
    const currentProcess = useAppSelector(selectCurrentProcess)
    const { objectiveId } = useParams()

    useEffect(() => {
        if (objectiveId) {
            void dispatch(objectivesViewAction(+objectiveId))
        }
    }, [])

    useEffect(() => {
        if (viewing) {
            dispatch(setCurrentProcess(viewing.blockId))
        }
    }, [viewing])

    const handleChangeProcess = (val: number | null) => {
        dispatch(setCurrentProcess(val))
    }

    const handleResetProcess = useCallback(() => {
        if (!viewing) {
            return void dispatch(setCurrentProcess(null))
        }

        void dispatch(setCurrentProcess(viewing.blockId))
    }, [viewing])

    return <Page>
        <Grid container direction="column" className="objective-page" columnSpacing="16px" wrap="nowrap">
            <ObjectivePageHeader />
            <Grid container item className="objective-page-filter">
                <Box sx={{ width: "50%", display:"flex", flexDirection: "row" }}>
                    <ProcessTreeSelect
                        process={viewing?.blockId}
                        disableClearable
                        size="small"
                        onChange={handleChangeProcess}
                        value={currentProcess}
                    />
                    <TextButton sx={{ flexShrink: 0 }} onPress={handleResetProcess}>Reset Filter</TextButton>
                </Box>
            </Grid>
            <Grid item className="objective-page-goals" flex={1}>
                <ObjectivePageContents />
            </Grid>
        </Grid>
    </Page>
}

export default ObjectivePage
