import { updateStoreRedux } from '../../../legacy/utils/redux';
import { AssetsConstants as K } from './Assets.constants';
import { difference, keys, pick, pickBy } from 'lodash';

const initialReducer = () => ({
    assets: {},
    operator: {
        skus: [],
        issues: [],
    },
    charts: {},
    skus: {}
});

export const assetsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_ASSETS_STATE) {
        return updateStoreRedux(state, {
            ...action.payload,
        });
    }

    if (action.type === K.ACTIONS.ADD_ASSETS_RESOURCE) {
        return updateStoreRedux(state, {
            assets: { ...state.assets, ...action.payload.assets },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_ASSETS_RESOURCE) {
        const deleteSet = new Set(action.payload.asset_ids);
        const deletedState = pickBy(
            { ...state.assets },
            ({ asset_id }) => !deleteSet.has(asset_id)
        );

        return updateStoreRedux(state, {
            assets: deletedState,
        });
    }

    if (action.type === K.ACTIONS.SET_CHARTS_RESOURCE) {
        return updateStoreRedux(state, {
            charts: {
                ...action.payload.charts,
            },
        });
    }

    if (action.type === K.ACTIONS.ADD_CHARTS_RESOURCE) {
        return updateStoreRedux(state, {
            charts: {
                ...state.charts,
                ...action.payload.charts,
            },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_CHARTS_RESOURCE) {
        if (!action.payload.chart_ids) {
            const deletedState = pick(
                { ...state.charts },
                difference(keys(state.charts), [action.payload.asset_id])
            );
    
            return updateStoreRedux(state, {
                charts: deletedState,
            });
        }

        const deleteSet = new Set(...action.payload.chart_ids);
        return updateStoreRedux(state, {
            charts: {
                ...state.charts,
                [action.payload.asset_id]: state.charts[asset_id].filter(({ chart_id }) => !deleteSet.has(chart_id))
            },
        });
    }

    if (action.type === K.ACTIONS.SET_ASSET_SKU_RESOURCE) {
        return updateStoreRedux(state, {
            skus: {
                ...action.payload.skus,
            },
        });
    }

    if (action.type === K.ACTIONS.ADD_ASSET_SKU_RESOURCE) {
        return updateStoreRedux(state, {
            skus: {
                ...state.skus,
                ...action.payload.skus,
            },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_ASSET_SKU_RESOURCE) {
        const deletedState = pick(
            { ...state.skus },
            difference(keys(state.skus), action,payload.asset_ids)
        );

        return updateStoreRedux(state, {
            skus: deletedState,
        });
    }

    if (action.type === K.ACTIONS.SET_ASSET_OPERATOR_PERMITTED_LABELS) {
        return updateStoreRedux(state, {
            operator: { ...action.payload },
        });
    }

    if (action.type === K.ACTIONS.RESET_ASSETS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
