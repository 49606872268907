import type ReportModel from "../models/report.model"
import { type ReportData, type ReportDataPoint, type ReportVariables } from "../models/report.model"

const ReportTransformer = (res: Record<any, any>): ReportModel => ({
    id: res.id,
    entityId: res.entity_id,
    blockId: res.block_id,
    startsAt: res.starts_at,
    endsAt: res.ends_at,
    variables: ReportVariablesTransformer(res.variables),
    data: ReportDataTransformer(res.data),
    reportName: res.report_name,
    reportType: res.report_type.toLowerCase(),
    createdAt: res.created_at,
    updatedAt: res.updated_at
})

export const ReportVariablesTransformer = (vars: any): ReportVariables => {
    return {
        unitOfTime: vars.unit_of_time,
        operatingHoursYear: vars.operating_hours_year ?? "",
        costPerOperatingHour: vars.cost_per_operating_hour ?? "",
        output: vars.output ?? "",
        marginPerPiece: vars.margin_per_piece ?? "",
        benchmark: vars.benchmark ?? "",
        currency: vars.currency ?? "",
        current: vars.current ?? undefined
    }
}

export const ReportDataTransformer = (data: any): ReportData | undefined => {
    if (!data) return undefined
    return {
        baseline: ReportDataPointTransformer(data.baseline),
        current: ReportDataPointTransformer(data.current),
        best: ReportDataPointTransformer(data.best),
    }
}

export const ReportDataPointTransformer = (datapoint: any): ReportDataPoint | null => {
    if (!datapoint) return null
    return {
        startsAt: datapoint.starts_at,
        endsAt: datapoint.ends_at,
        value: datapoint.value
    }
}

export default ReportTransformer
