import _ from 'lodash';
import { put, all, takeLatest } from 'redux-saga/effects';
import { watcherBuilder } from '../../Base/Base.saga';
import { api_getChannelData } from '../../Data/Data.services';
import { currentEntitySelector } from '../../Entity/Entity.selector';
import { correlationsState } from '../../Correlations/Correlations.selector';
import { regressionChartDomains } from './RegressionUI.selector';
import { RegressionConstants as K } from './RegressionUI.constants';
import { errorFlash } from '../../../../legacy/components/Flash';
import { parseTsData } from '../../../../legacy/utils/data';
import { changeRegressionBound, changeRegressionForm, fetchRegressionDataRequestSuccess, resetRegressionStore } from './RegressionUI.action';
import { regressionType } from '../../../../legacy/utils/regression';
import { store } from '../../..';

function* handleFetchRegressionData() {
    try {
        const appState = store.getState();
        const {
            ui: {
                regression: {
                    sourceX,
                    sourceY,
                    startDate,
                    endDate,
                    resolution: { res_x, res_period },
                },
            },
        } = appState;

        const { entity_id } = currentEntitySelector(appState);

        const dateRange = {
            lower: startDate.toISOString(),
            upper: endDate.toISOString(),
        };

        const query = {
            date_range: dateRange,
            res_x,
            res_period,
        };

        const [dataX, dataY] = yield all([
            api_getChannelData(entity_id, sourceX.metadata_id, query),
            api_getChannelData(entity_id, sourceY.metadata_id, query),
        ]);

        const aggregated = {};
        parseTsData(dataX.data).reduce((acc, curr) => {
            acc[curr.time.toISOString()] = { x: curr.val };
            return acc;
        }, aggregated);
        parseTsData(dataY.data).reduce((acc, curr) => {
            const ISOTime = curr.time.toISOString();
            const datum = acc[ISOTime];
            if (datum) {
                acc[ISOTime] = { ...datum, y: curr.val };
                return acc;
            }
            return acc;
        }, aggregated);
        const data = !_.isEmpty(aggregated)
            ? Object.keys(aggregated).map((k) => ({
                ...aggregated[k],
                time: new Date(k),
            }))
            : [];
        yield put(fetchRegressionDataRequestSuccess(data));
    } catch (e) {
        errorFlash(e);
    }
}

export function* regressionFetchDataSaga() {
    yield takeLatest(
        K.ACTIONS.REGRESSION_FETCH_DATA_REQUEST,
        handleFetchRegressionData
    );
}

// reset upper and lower bounds on model change
function* watchRegressionModelChange() {
    try {
        const appState = store.getState();
        const { y: yDomain } = regressionChartDomains(appState);

        const [yMin, yMax] = yDomain;
        const dy_default = (yMax - yMin) * 0.2;

        yield all([
            put(changeRegressionBound('lowerBound', { dx: 0, dy: -dy_default })),
            put(changeRegressionBound('upperBound', { dx: 0, dy: dy_default })),
        ]);
    } catch (e) {
        errorFlash(e);
    }
}

export function* regressionModelChangeSaga() {
    yield watcherBuilder(
        K.ACTIONS.REGRESSION_SET_REGRESSION,
        watchRegressionModelChange
    );
}

// watch for selection change and update form
function* handleRegressionSelectionChange(action) {
    try {
        const appState = store.getState();
        const cState = correlationsState(appState);
        const { selection } = action.payload;

        // don't do anything
        if (selection === -1) return;

        const { sourceX, sourceY, model_parameters, model_name, threshold } =
      cState[selection];

        const data = {
            sourceX,
            sourceY,
            regression: regressionType({ model_parameters, model_name }).d,
            upperBound: threshold.upper,
            lowerBound: threshold.lower,
            selection,
        };

        yield put(resetRegressionStore());
        yield put(changeRegressionForm(data));
    } catch (e) {
        errorFlash(e);
    }
}

export function* regressionSelectionChangeSaga() {
    yield takeLatest(
        K.ACTIONS.REGRESSION_SET_SELECTION,
        handleRegressionSelectionChange
    );
}

function* watchRegressionFormChange(action) {
    try {
        if (action.callback) {
            action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* regressionFormChangeSaga() {
    yield watcherBuilder(
        K.ACTIONS.REGRESSION_CHANGE_FORM,
        watchRegressionFormChange
    );
}
