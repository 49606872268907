export const controlsState = (appState) =>
    appState.ui.controls;

export const getResolution = (appState) => {
    return appState.ui.controls.resolution;
};

export const getWindow = (appState) => {
    const { startDate: lower, endDate: upper } = controlsState(appState);
    return { lower, upper };
};
