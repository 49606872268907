/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Layout, Switch } from 'antd';
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';

// COMPONENT
import AukTooltip from '../../components/AukTooltip';
import { SPAWrapper } from '../../components/SPAWrapper';
import { NoData } from '../../components/None';

import { fetchSkuCharts } from '../../../store/old/SkuCharts/SkuCharts.action';

// SELECTOR
import { assetsOrderedByBlock, assetsSelector } from '../../../store/old/Assets/Assets.selector';

// STYLE
import './index.scss';
import { withEntity } from '../../Wrappers/HOCs/withEntity';
import AukFilterSelectMulti from '../../components/AukFilterSelectMulti';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import VTable from '../../components/VTable';
import InputWithSubmit from '../../components/AukInput/InputWithSubmit';
import { updateAssetStdTime } from '../../../store/old/Assets/Assets.action';
import { toNumber } from 'lodash';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';

const VALIDATE = {
    STD_TIME: [
        (_form) => ({
            validator: (_, value) => {
                const val = toNumber(value)
                if (!isFinite(val)) {
                    return Promise.reject(new Error('Must be a number'));
                }

                if (val < 0) {
                    return Promise.reject(new Error('Must be greater than 0'));
                }

                return Promise.resolve();
            },
        }),
    ]
}

export const stdTimeMaxLength = 7;

const AssetStandardTimes = (props) => {
    const { entity: { entity_id }} = props;
    const dispatch = useDispatch();

    const authUser = useSelector(appState => appState.auth.user)
    const rootBlock = useSelector(entityBlock)
    const _assets = useMemo(() => assetsOrderedByBlock(authUser, rootBlock), [authUser, rootBlock])

    useEffect(() => {
        dispatch(fetchSkuCharts(entity_id, { primary: true }))
    }, []);

    
    const [assetsSelection, setAssetsSelection] = useState(_assets.map((a) => a.asset_id));

    const assets = useMemo(() => {
        const selectionIds = new Set(assetsSelection);
        return _assets.filter((a) => selectionIds.has(a.asset_id));
    }, [_assets, assetsSelection]);

    const assetsOptions = useMemo(
        () => _assets.map((a) => ({ value: a.asset_id, title: a.asset_name })),
        [_assets]
    );

    return (
        <SPAWrapper className="st-tab-content-container">
            <div className="d-flex mb-2">
                <div className="d-flex w-100">
                    <AukFilterSelectMulti
                        className="st-filter"
                        checkAllMenuOption={true}
                        data={assetsOptions}
                        onChange={setAssetsSelection}
                        maxTagCount={0}
                        maxTagPlaceholder={null}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select Assets
                            </>
                        }
                    />
                </div>
            </div>
            <Layout className="d-flex w-100 mt-2" style={{ flexGrow: 1 }}>
                {
                    assets.length ? 
                        <AssetList data={assets}/> :
                        <NoData
                            style={{ flexGrow: 1 }}
                            description="Select at least one asset and one SKU to continue"
                        />
                }
            </Layout>
        </SPAWrapper>
    );
};

export default withEntity(AssetStandardTimes);

const AssetList = (props) => {
    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector);

    const save = useCallback(
        (asset_id, params) => {
            dispatch(updateAssetStdTime(entity_id, asset_id, params));
        },
        []
    );

    const columns = useMemo(
        () => [
            {
                title: 'Asset',
                render: (text, record, index) => (
                    <AukTooltip.Info title={record.asset_name}>
                        <div className="text-overflow-ellipsis">
                            {record.asset_name}
                        </div>
                    </AukTooltip.Info>
                ),
                sorter: (a, b) => a.asset_name.localeCompare(b.asset_name),
                width: 300,
            },
            {
                title: 'Auto Cycle Time',
                render: (text, record, index) => {
                    if (!record._primaryChart?.dataSource) return '-'
                    return <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={record.autoUpdate}
                        onChange={(value) => {
                            save(
                                record.asset_id,
                                { autoUpdate: value }
                            )
                        }}
                    />
                },
                width: 180,
            },
            {
                title: 'Speed Loss',
                render: (text, record, index) => {
                    if (!record._primaryChart?.dataSource) return '-'
                    return <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={record.speed}
                        onChange={(value) => {
                            save(
                                record.asset_id,
                                { speed: value }
                            )
                        }}
                    />
                },
                width: 180,
            },
            {
                title: (
                    <div className="d-flex">
                        Standard Time (s){' '}
                        <AukTooltip.Help title="Set as 0 to remove">
                            <span className="d-flex align-items-center ml-2">
                                <InfoCircleOutlined />
                            </span>
                        </AukTooltip.Help>
                    </div>
                ),
                render: (text, record, index) => {
                    if (!record._primaryChart?.dataSource) return '-'
                    return <InputWithSubmit
                        rules={VALIDATE.STD_TIME}
                        value={record.stdTime}
                        submit={(value) => {
                            if (!value) return;
                            save(
                                record.asset_id,
                                { 
                                    stdTime: value,
                                }
                            )
                        }}
                    />
                },
                width: 180,
            },
        ],
        []
    );
    return (
        <div className="d-flex flex-column h-100">
            <VTable
                columns={columns}
                dataSource={props.data}
                rowKey={(asset) => asset.asset_id}
            />
        </div>
    );
};