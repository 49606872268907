/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'reactstrap';

import {
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
} from '../../../components/Panel';
import FormSelect from '../../../components/FormSelect';
import { connect } from 'react-redux';
import { arrayUsers, usersState } from '../../../../store/old/User/User.selector';
import { arrayGroups, groupsState } from '../../../../store/old/Group/Group.selector';
import { CreateNotificationRequest } from '../../../../store/old/Notifications/Notifications.action';
import { regressionState } from '../../../../store/old/UI/Regression/RegressionUI.selector';

const MODES = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
    { value: 'whatsapp', label: 'Whatsapp' },
];

const style = { minWidth: 600 };

class AnomalyNotificationFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { recipients: [], mode: [] };

        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
    }

    handleChangeMultiSelect(field, e) {
        this.setState({ [field]: e });
    }

    get form() {
        const { arrayGroups, arrayUsers } = this.props;
        const fields = [
            {
                label: 'Recipients',
                value: this.state.recipients,
                handleChange: (e) => this.handleChangeMultiSelect('recipients', e),
                options: arrayUsers.concat(arrayGroups),
                getOptionLabel: (opt) => opt.email || opt.group_name,
                getOptionValue: (opt) => opt.user_id || opt.group_id,
                placeholder: 'Choose recipients',
            },
            {
                label: 'Mode',
                value: this.state.mode,
                handleChange: (e) => this.handleChangeMultiSelect('mode', e),
                options: MODES,
                placeholder: 'Choose mode',
            },
        ];
        return (
            <>
                {fields.map((field, i) => (
                    <FormSelect
                        {...field}
                        key={i}
                        labelCol={3}
                        selectCol={9}
                        isMulti
                        hideSelectedOptions
                    />
                ))}
            </>
        );
    }

    get footerButtons() {
        const buttons = [
            {
                label: (
                    <span>
                        <i className="fas fa-times mr-1" />
            Cancel
                    </span>
                ),
                className: 'btn b-cancel',
                handleClick: () => this.props.toggle(),
            },
            {
                label: (
                    <span>
                        <i className="fas fa-save mr-1" />
            Save
                    </span>
                ),
                className: 'btn b-save',
                handleClick: () => {
                    this.props.createNotificationRequest(this.formData);
                    this.props.toggle();
                },
            },
        ];

        return (
            <>
                {buttons.map((btn, i) => (
                    <button key={i} className={btn.className} onClick={btn.handleClick}>
                        {btn.label}
                    </button>
                ))}
            </>
        );
    }

    get formData() {
        const recipient_users = this.state.recipients
            .filter((r) => r.user_id)
            .map((u) => u.user_id);
        const recipient_groups = this.state.recipients
            .filter((r) => r.group_id)
            .map((g) => g.group_id);
        const mode = this.state.mode.map((m) => m.value).join(',');

        return {
            recipient_users: recipient_users.length ? recipient_users : null,
            recipient_groups: recipient_groups.length ? recipient_groups : null,
            mode,
            category: 'outlier',
            correlation_id: this.props.regressionStore.selection,
        };
    }

    render() {
        return (
            <Modal style={style} isOpen={this.props.show} toggle={this.props.toggle}>
                <Panel>
                    <PanelHeader>Anomaly Detection</PanelHeader>
                    <PanelBody
                        className="d-flex flex-column justify-content-center"
                        style={{ minHeight: 300 }}
                    >
                        {this.form}
                    </PanelBody>
                    <PanelFooter>{this.footerButtons}</PanelFooter>
                </Panel>
            </Modal>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        arrayUsers: arrayUsers(appState),
        users: usersState(appState),
        arrayGroups: arrayGroups(appState),
        groups: groupsState(appState),
        regressionStore: regressionState(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNotificationRequest: (data) =>
            dispatch(CreateNotificationRequest(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnomalyNotificationFormModal);
