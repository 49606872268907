/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Select } from 'antd';

const FilterSearch = ({ selectProps, inputProps }) => {
    return (
        <Input.Search {...inputProps} addonBefore={<Select {...selectProps} />} />
    );
};

export default FilterSearch;
