export const el = {
    notes: 'σημειώσεις',
    load: 'πηγαίνω',
    export: 'Κατεβάστε',
    unutilised: 'Un-χρησιμοποιούμενη',
    breakdowns: 'κατανομές',
    threshold: 'Κατώφλι',
    oeepreview: 'ΟΕΕ προεπισκόπηση',
    heatmappreview: 'Heatmap Προεπισκόπηση',
    oeecolors: 'χρώματα Ο.Ε.Ε.',
    color: 'χρώμα',
    reset: 'Επαναφορά',
    theme: 'Θέμα',
    defaultview: 'προεπιλεγμένη προβολή',
    defaulttimeselection: 'Προεπιλογή επιλογή του χρόνου',
    zoomlevel: 'Επίπεδο ζουμ',
    entity: 'Οντότητα',
    preferences: 'προτιμήσεις',
    custom: 'Εθιμο',
    enteryourmailtoreset: 'Πληκτρολογήστε το e-mail σας για να επαναφέρετε',
    goback: 'Πήγαινε πίσω',
    resetpassword: 'Επαναφέρετε τον κωδικό πρόσβασης',
    logout: 'Αποσύνδεση',
    ownersemail: 'ιδιοκτήτες Email',
    optional: 'Προαιρετικός',
    industry: 'Βιομηχανία',
    timezone: 'Ζώνη ώρας',
    nocoderequesttojoin: 'Δεν Κώδικας Αίτημα Για Εγγραφή',
    enterreferralcodeinstead: 'Εισάγετε παραπομπή κώδικα αντί',
    setup: 'Ρύθμιση',
    company: 'Εταιρία',
    referralcode: 'παραπομπή κώδικα',
    submit: 'υποβάλλουν',
    summary: 'Περίληψη',
    language: 'Γλώσσα',
    login: 'Σύνδεση',
    signin: 'Συνδεθείτε',
    signup: 'Εγγραφείτε',
    username: 'Όνομα Χρήστη',
    password: 'Κωδικός πρόσβασης',
    forgotpassword: 'Ξεχάσατε τον κωδικό?',
    rememberme: 'Θυμήσου με',
    register: 'Κανω ΕΓΓΡΑΦΗ',
    registration: 'Εγγραφή',
    firstname: 'Ονομα',
    lastname: 'Επίθετο',
    email: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    mobile: 'Κινητό',
    country: 'Χώρα',
    contactnumber: 'Αριθμός επαφής',
    appointment: 'Ραντεβού',
    role: 'Ρόλος',
    admin: 'διαχειριστής',
    owner: 'ιδιοκτήτης',
    editor: 'συντάκτης',
    viewer: 'θεατής',
    confirmpassword: 'Επιβεβαίωση Κωδικού',
    iacceptthe: 'δέχομαι το',
    useragreement: 'Συμφωνία Χρήστη',
    alreadyregisteredlogin: 'Εχουν ήδη καταχωρηθεί? Σύνδεση',
    softwareasaservicetermsofuse: 'Software-as-a-Service Όροι Χρήσης',
    dashboard: 'Ταμπλό',
    streaming: 'ροής',
    resolution: 'Ανάλυση',
    seconds: 'δευτερόλεπτα',
    second: 'δεύτερος',
    minutes: 'λεπτά',
    minute: 'λεπτό',
    hours: 'ώρες',
    hour: 'Ωρα',
    weeks: 'εβδομάδες',
    week: 'Εβδομάδα',
    months: 'μήνες',
    month: 'μήνας',
    quarters: 'κατάλυμα',
    quarter: 'τέταρτο',
    years: 'χρόνια',
    year: 'έτος',
    from: 'Από',
    to: 'Προς το',
    start: 'αρχή',
    end: 'Τέλος',
    display: 'Απεικόνιση',
    oee1: 'ΟΕΕ-1',
    oee2: 'OEE2',
    timeseries: 'Χρονική σειρά',
    gateway: 'πύλη',
    statusasof: 'Η ιδιότητα του',
    active: 'ενεργός',
    offline: 'offline',
    mergeasonemachine: 'Συγχώνευση ως ένα μηχάνημα',
    drawarrowstoformprocessmap: 'Σχεδιάστε βέλη για να χάρτη διεργασία μορφή',
    openblockeditor: 'Ανοίξτε συντάκτης μπλοκ',
    createnewtile: 'Δημιουργία νέου Πλακάκια',
    summarytile: 'Περίληψη κεραμίδι',
    selectablockthennameyoursummarytile:
    'Επιλέξτε ένα μπλοκ, στη συνέχεια, αναφέρουμε συνοπτικά κεραμίδι σας',
    name: 'Ονομα',
    blockselector: 'επιλογέα Block',
    asset: 'Περιουσιακό στοιχείο',
    therearenolabelstaggedinthisperiod:
    'Δεν υπάρχουν ετικέτες με ετικέτα σε αυτή την περίοδο',
    standardtimes: 'Χειμερινή ώρα (ες)',
    total: 'Σύνολο',
    last: 'τελευταίος',
    oeeinput: 'εισόδου Ο.Ε.Ε.',
    chartcolor: 'χρώμα Διάγραμμα',
    issueeditor: 'Έκδοση συντάκτη',
    issuemangement: 'Διαχειρίσεως θέμα',
    cumulativeoutput: 'Αθροιστική εξόδου',
    tag: 'Ετικέτα',
    label: 'Επιγραφή',
    valueval: 'Αξία (Val)',
    time: 'χρόνος',
    januaryjan: 'Ιανουάριο (Ιαν)',
    februaryfeb: 'Φεβρουάριο (Φεβρουαρίου)',
    marchmar: 'Μαρτίου (Mar)',
    aprilapr: 'Απρίλιο (Απρίλιος)',
    may: 'Ενδέχεται',
    junejun: 'Ιούνιο (Ιούνιος)',
    julyjul: 'Ιούλιο (Ιούλιος)',
    augustaug: 'Αύγουστο (Αύγουστος)',
    septembersep: 'Σεπτεμβρίου (Σεπτέμβριο)',
    octoberoct: 'Οκτωβρίου (Οκτώβριος)',
    novembernov: 'Νοέμβριο (Νοέμβριος)',
    decemberdec: 'Δεκεμβρίου (DEC)',
    day: 'Ημέρα',
    days: 'Μέρες',
    mondaymonm: 'Δευτέρα (Δευ) Μ',
    tuesdaytuet: 'Τρίτη (Τρ) Τ',
    wednesdaywedw: 'Τετάρτη (Τετ) W',
    thursdaythut: 'Πέμπτη (Thu) T',
    fridayfrif: 'Παρασκευή (Παρασκευή) F',
    saturdaysats: 'Σάββατο (Sat) S',
    sundaysuns: 'Κυριακή (Sun) S',
    edit: 'Επεξεργασία',
    add: 'Προσθήκη',
    new: 'Νέος',
    create: 'Δημιουργώ',
    search: 'Αναζήτηση',
    close: 'Κλείσε',
    cancel: 'Ματαίωση',
    save: 'Αποθηκεύσετε',
    saved: 'Αποθηκευμένες',
    clear: 'Σαφή',
    enter: 'Εισαγω',
    navigator: 'Πλοηγός',
    mandatoryfields: 'Υποχρεωτικά πεδία',
    select: 'Επιλέγω',
    merge: 'Συγχώνευση',
    delete: 'Διαγράφω',
    done: 'Ολοκληρώθηκε',
    filter: 'Φίλτρο',
    table: 'Τραπέζι',
    columns: 'στήλες',
    rows: 'σειρές',
    cell: 'κύτταρο',
    page: 'Σελίδα',
    previous: 'Προηγούμενος',
    next: 'Επόμενο',
    serialnumber: 'Σειριακός αριθμός',
    manuallyinputserialnumber: 'Μη αυτόματη είσοδος Serial Number',
    updateasset: 'Ενημέρωση Ενεργητικού',
    machine: 'Μηχανή',
    brand: 'Μάρκα',
    model: 'Μοντέλο',
    nodes: 'κόμβοι',
    fusions: 'Συγχωνεύσεις',
    chartorder: 'Διάγραμμα Παραγγελία',
    primary: 'Πρωταρχικός',
    adddevicechannel: 'Προσθήκη συσκευής - Κανάλι',
    pushratesec: 'Πιέστε Rate (sec)',
    voltagev: 'Τάση (V)',
    sensor: 'Αισθητήρας',
    mode: 'Τρόπος',
    digitalcount: 'Ψηφιακή Καταμέτρηση',
    digitalstate: 'Ψηφιακή μέλος',
    analogvoltage: 'αναλογική τάση',
    charttitle: 'Διάγραμμα Τίτλος',
    unitmeasure: 'Μέτρο Μονάδα',
    attribute: 'Χαρακτηριστικό',
    autocycletime: 'Αυτόματη ώρα Κύκλος',
    unittransform: 'μονάδα Transform',
    leaveblankifnotapplicable: 'Αφήστε κενό αν δεν ισχύει',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Υποχρεωτικά πεδία (κενό άδεια αν το κανάλι δεν χρησιμοποιείται)',
    fusedchart: 'λιωμένο Διάγραμμα',
    fusionname: 'Όνομα Fusion',
    charttype: 'Διάγραμμα Τύπος',
    column: 'Στήλη',
    line: 'Γραμμή',
    state: 'κατάσταση',
    expression: 'Εκφραση',
    combination: 'Συνδυασμός',
    currentcharts: 'Τρέχουσα Διαγράμματα',
    expressionconstructor: 'έκφραση Κατασκευαστής',
    validoperators: 'Ισχύει φορείς',
    leaveblanktokeepexistingexpression:
    'Αφήστε το κενό για να διατηρήσετε τα υπάρχοντα έκφρασης',
    oee: 'Ο.Ε.Ε.',
    overallequipmenteffectiveness: 'Συνολική αποτελεσματικότητα Εξοπλισμός',
    legend: 'Θρύλος',
    loading: 'Φόρτωση',
    availability: 'Διαθεσιμότητα',
    performance: 'Εκτέλεση',
    quality: 'Ποιότητα',
    unscheduled: 'Μη προγραμματισμένη',
    planneddowntime: 'προγραμματισμένης διακοπής λειτουργίας',
    changeoversetup: 'Μεταγωγή / Ρύθμιση',
    breakdown: 'Επαθε βλάβη',
    unutilized: 'Un-χρησιμοποιούμενη',
    speedloss: 'Ταχύτητα Απώλεια',
    minorstops: 'Μικρά Στάσεις',
    rework: 'Rework',
    rejectscrap: 'Απόρριψη / Άχρηστα',
    nodata: 'Χωρίς δεδομένα',
    blockeditor: 'Αποκλεισμός συντάκτη',
    sort: 'Είδος',
    logicblocks: 'Logic Blocks',
    sequence: 'Αλληλουχία',
    and: 'Και',
    or: 'Ή',
    utilizationheatmap: 'αξιοποίηση Heatmap',
    startdate: 'Ημερομηνία έναρξης',
    enddate: 'Ημερομηνία λήξης',
    daily: 'Καθημερινά',
    weekly: 'Εβδομαδιαίος',
    monthly: 'Μηνιαίο',
    quarterly: 'Τριμηνιαίος',
    yearly: 'Ετήσια',
    trendanalysis: 'Trend Analysis',
    metricselection: 'Metric επιλογής',
    metric: 'Μετρικός',
    data: 'Δεδομένα',
    target: 'Στόχος',
    periodselection: 'Επιλογή περιόδου',
    span: 'Σπιθαμή',
    every: 'Κάθε',
    repeat: 'Επαναλαμβάνω',
    repeats: 'επαναλαμβάνει',
    addsingle: 'Προσθήκη Ενιαία',
    paretoanalysis: 'Pareto Analysis',
    calendartime: 'χρόνο Ημερολόγιο',
    scheduledtime: 'ΠΡΟΓΡΑΜΜΑΤΙΣΜΕΝΗ ωρα',
    operatingtime: 'Χρόνος λειτουργίας',
    netruntime: 'Τα καθαρά το χρόνο εκτέλεσης',
    effective: 'Αποτελεσματικός',
    loadingloss: 'Φόρτωση Απώλεια',
    availabilityloss: 'Απώλεια διαθεσιμότητα',
    performanceloss: 'Απώλεια Απόδοσης',
    qualityloss: 'Απώλεια ποιότητας',
    notagsinthiscategory: 'Δεν υπάρχουν ετικέτες σε αυτή την κατηγορία',
    overall: 'Συνολικά',
    tagged: 'Tagged',
    occurrence: 'Περιστατικό',
    skumanagement: 'Διαχείριση Κωδικός',
    category: 'Κατηγορία',
    skuname: 'Κωδικός Όνομα',
    skucode: 'Κωδικός Κωδικός',
    unitofmeasurementuom: 'Μονάδα Μέτρησης (UOM)',
    description: 'Περιγραφή',
    csvupload: 'CSV Μεταφόρτωση',
    template: 'Πρότυπο',
    notifications: 'Ειδοποιήσεις',
    newnotification: 'νέα κοινοποίηση',
    monitoring: 'Παρακολούθηση',
    topic: 'Θέμα',
    frequency: 'Συχνότητα',
    recipients: 'Παραλήπτες',
    notifyby: 'Ειδοποίηση με',
    sms: 'γραπτό μήνυμα',
    alert: 'Συναγερμός',
    report: 'Κανω ΑΝΑΦΟΡΑ',
    greaterthan: 'Μεγαλύτερος από',
    lessthan: 'Λιγότερο από',
    greaterthanorequal: 'Μεγαλύτερο ή ίσο',
    lessthanorequal: 'Μικρότερο ή ίσο',
    equal: 'Ισος',
    range: 'Εύρος',
    continuousduration: 'Συνεχής Διάρκεια',
    usermanagement: 'Διαχείριση χρηστών',
    user: 'Χρήστης',
    users: 'χρήστες',
    invitenew: 'Πρόσκληση Νέα',
    status: 'κατάσταση',
    resend: 'Επανάληψη αποστολής',
    revoke: 'Ανακαλώ',
    invite: 'Καλώ',
    invitees: 'Οι προσκεκλημένοι',
    addinviteesbypressingenterorclickingonthesign:
    'Προσθέστε προσκεκλημένοι πατώντας Enter ή κάντε κλικ στο σύμβολο «+»',
    schedules: 'Δρομολόγια',
    shift: 'Μετατόπιση',
    profile: 'Προφίλ',
    changepassword: 'Άλλαξε κωδικό',
    deleteaccount: 'Διαγραφή λογαριασμού',
    userguide: 'ΟΔΗΓΟΣ ΧΡΗΣΤΗ',
    overviewandbasiccontrol: 'Επισκόπηση και Βασικά Ελέγχου',
    rangeandresolution: 'Εύρος και Ανάλυση',
    editingoptions: 'επιλογές Επεξεργασία',
    mergetiles: 'πλακάκια συγχώνευση',
    detailedassetview: 'λεπτομερή εικόνα των περιουσιακών στοιχείων',
    assetoverview: 'επισκόπηση Asset',
    summarystatistics: 'Περίληψη Στατιστικά',
    dataanalysis: 'Ανάλυση δεδομένων',
    oeeanoverview: 'ΟΕΕ επισκόπηση',
    oeetoggle: 'ΟΕΕ εναλλαγή',
    addingamachine: 'Προσθέτοντας μια μηχανή',
    nodeconfiguration: 'διαμόρφωση κόμβου',
    deleteupdateassets: 'Διαγραφή περιουσιακών στοιχείων ενημέρωση',
    oeesummarytile: 'ΟΕΕ περίληψη κεραμίδι',
    oeetags: 'ΟΕΕ Ετικέτες',
    issuemanagement: 'διαχείρισης θέμα',
    labelsandfiltering: 'Ετικέτες και φιλτράρισμα',
    useraccess: 'πρόσβαση χρήστη',
    group: 'Ομάδα',
    groups: 'ομάδες',
    joincompany: 'Εγγραφή της εταιρείας',
    notification: 'Γνωστοποίηση',
    createnotification: 'Δημιουργία κοινοποίησης',
    node: 'Κόμβος',
    downtime: 'Χρόνος αργίας',
    downtimes: 'downtimes',
    repeatstartdate: 'Επανάληψη Ημερομηνία Έναρξης',
    repeatenddate: 'Επαναλάβετε Ημερομηνία Λήξης',
    optional: 'Προαιρετικός',
    createplan: 'Δημιουργία σχεδίου',
    today: 'Σήμερα',
    starttime: 'Ωρα έναρξης',
    endtime: 'Τέλος χρόνου',
    chart: 'Διάγραμμα',
    reject: 'Απορρίπτω',
    accept: 'Αποδέχομαι',
    filterbydaterange: 'φίλτρο ανά εύρος ημερομηνιών',
    filterbymostrecent: 'φίλτρο με πιο πρόσφατο',
    result: 'Αποτέλεσμα',
    type: 'Τύπος',
    duration: 'Διάρκεια',
    by: 'με',
    title: 'Τίτλος',
    assigned: 'Ανατεθεί',
    action: 'Δράση',
    track: 'πίστα',
    issue: 'Θέμα',
    priority: 'Προτεραιότητα',
    requestor: 'αιτούντα',
    opened: 'άνοιξε',
    assignee: 'Εντολοδόχος',
    root: 'ρίζα',
    cause: 'Αιτία',
    identified: 'Αναγνωρισθείς',
    defined: 'Ορίζεται',
    date: 'ημερομηνία',
    resolve: 'αποφασίζω',
    resolved: 'επιλυθεί',
    targetresolvedate: 'στόχου ημερομηνία αποφασιστικότητα',
    procurement: 'Προμήθεια',
    choose: 'Επιλέγω',
    analyses: 'αναλύσεις',
    source: 'Πηγή',
    selection: 'Επιλογή',
    historical: 'Ιστορικός',
    mostrecent: 'Πρόσφατα',
    regression: 'Οπισθοδρόμηση',
    trendline: 'γραμμή τάσης',
    upperbound: 'άνω όριο',
    lowerbound: 'χαμηλότερο όριο',
    preview: 'προεπισκόπηση',
    period: 'περίοδος',
    series: 'σειρά',
    axis: 'άξονας',
    youhaveno: 'δεν έχεις',
    code: 'Κώδικας',
    all: 'Ολα',
    upload: 'μεταφόρτωσης',
    regressionanalysis: 'Ανάλυση παλινδρόμησης',
    issuetracker: 'tracker θέμα',
    labelsearch: 'ετικέτα Αναζήτηση',
    gatewaysetup: 'Ρύθμιση πύλης',
    setupgateway: 'πύλη Ρύθμιση',
    radiomesh: 'ραδιόφωνο πλέγμα',
    targetsetting: 'καθορισμός στόχων',
    updatetrackedissue: 'ενημέρωση παρακολουθούνται θέμα',
    shownewtrackedissue: 'δείξει το νέο εντοπισμένες θέμα',
    low: 'χαμηλός',
    mid: 'στα μέσα',
    high: 'υψηλός',
    linear: 'γραμμικός',
    exponential: 'εκθετικός',
    logarithmic: 'λογαριθμική',
    quadratic: 'τετραγωνικός',
    polynomial: 'πολυώνυμος',
    general: 'Γενικός',
    datataggingenrichment: 'Δεδομένα προσθήκης ετικετών Εμπλουτισμός',
    hardware: 'Σκεύη, εξαρτήματα',
    nodemanagement: 'διαχείρισης κόμβος',
    createeditnotification: 'Δημιουργία επεξεργαστείτε κοινοποίηση',
    conditions: 'Συνθήκες',
    addsku: 'προσθέστε Κωδικός',
    editsku: 'επεξεργασία Κωδικός',
    setupagateway: 'Ρύθμιση μια πύλη',
    deviceserialnumber: 'σειριακό αριθμό της συσκευής',
    repeatsweekly: 'επαναλαμβάνει την εβδομάδα',
    repeatsdaily: 'επαναλαμβάνει καθημερινά',
    repeatsmonthly: 'επαναλαμβάνεται κάθε μήνα',
    repeatsannually: 'επαναλαμβάνεται σε ετήσια βάση',
    repeatsevery: 'επαναλαμβάνεται κάθε',
    hourly: 'ωριαίος',
    biweekly: 'ανά δύο εβδομάδες',
};
