export const AuthConstants = {
    ACTIONS: {
        LOGIN_REQUEST: 'LOGIN_REQUEST',
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        LOGIN_FAILURE: 'LOGIN_FAILURE',
        LOGOUT_REQUEST: 'LOGOUT_REQUEST',
        LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
        LOGOUT_FAILURE: 'LOGOUT_FAILURE',
        FETCH_USER_INFORMATION_REQUEST: 'FETCH_USER_INFORMATION_REQUEST',
        FETCH_USER_INFORMATION_SUCCESS: 'FETCH_USER_INFORMATION_SUCCESS',
        FETCH_USER_INFORMATION_FAILURE: 'FETCH_USER_INFORMATION_FAILURE',
        UPDATE_USER_INFORMATION: 'UPDATE_USER_INFORMATION',
        CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
        CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
        CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
        RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
        RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
        RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
        VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
        VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
        VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
        RESET_PASSWORD_WITH_CODE_REQUEST: 'RESET_PASSWORD_WITH_CODE_REQUEST',
        RESET_PASSWORD_WITH_CODE_SUCCESS: 'RESET_PASSWORD_WITH_CODE_SUCCESS',
        RESET_PASSWORD_WITH_CODE_FAILURE: 'RESET_PASSWORD_WITH_CODE_FAILURE',
        SIGNUP_REQUEST: 'SIGNUP_REQUEST',
        SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
        SIGNUP_FAILURE: 'SIGNUP_FAILURE',
        CHANGE_USER_POLICY: 'CHANGE_USER_POLICY',
        UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    },
};
