import React, { useContext } from 'react';
import { HorizontalVirtualizeNoScroll } from '../../components/virtualized';
import moment from 'moment';
import { TargetSettingContext } from '.';

export const TargetSettingTimeline = () => {
    const {
        period,
        arrayDates,
        leftGroupSize,
        rightGroupSize,
        enableStickyTimeLine,
    } = useContext(TargetSettingContext);

    function renderRowTimeline({ index }) {
        let className = 'text-timeline ';

        if (!arrayDates[index]) return null;

        if (
            !arrayDates[index].showDate &&
      index + 1 < arrayDates.length &&
      !moment(arrayDates[index].id).isSame(
          moment(arrayDates[index + 1].id),
          'day'
      )
        ) {
            className += 'show-border';
        }

        return (
            <div className={className}>
                {arrayDates[index].showDate ? (
                    <>
                        <p className="text-timeline-day">
                            {arrayDates[index].day}
                        </p>
                        <p className="text-timeline-time" style={{ marginLeft: 5 }}>
                            {arrayDates[index].time}
                        </p>
                    </>
                ) : (
                    <p className="text-timeline-time">{arrayDates[index].time}</p>
                )}
            </div>
        );
    }

    const colWidth = rightGroupSize / (period.visibleItems + 1);

    if (enableStickyTimeLine) {
        return (
            <div
                className="row timeline"
                style={{ position: 'fixed', top: 110, zIndex: 100000 }}
            >
                <div
                    className="target-setting-static-column"
                    style={{ width: leftGroupSize }}
                />

                <div
                    className="target-setting-timeline"
                    style={{ width: rightGroupSize }}
                >
                    <HorizontalVirtualizeNoScroll
                        colWidth={() => colWidth}
                        viewPortHeight={60}
                        dataLength={arrayDates.length}
                        numsOfVisibleItems={period.visibleItems}
                        renderRow={renderRowTimeline}
                        showLoading={false}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="row timeline">
            <div
                className="target-setting-static-column"
                style={{ width: leftGroupSize }}
            />

            <div
                className="target-setting-timeline"
                style={{ width: rightGroupSize }}
            >
                <HorizontalVirtualizeNoScroll
                    colWidth={() => colWidth}
                    viewPortHeight={60}
                    dataLength={arrayDates.length}
                    numsOfVisibleItems={period.visibleItems}
                    renderRow={renderRowTimeline}
                    showLoading={false}
                />
            </div>
        </div>
    );
};
