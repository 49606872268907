import type ObjectiveModel from "../models/objective.model"

const ObjectiveTransformer = (record: Record<any, any>): ObjectiveModel => ({
    id: record.id,
    blockId: record.block_id,
    blockName: record.block_name,
    userId: record.user_id,
    user: {
        userId: record.user.id,
        nameFirst: record.user.name_first,
        nameLast: record.user.name_last,
    },
    name: record.name,
    startsAt: record.starts_at,
    endsAt: record.ends_at,
    unitOfTime: record.unit_of_time,
    createdAt: record.created_at,
    updatedAt: record.updated_at,
    deletedAt: record.deleted_at,
})

export default ObjectiveTransformer
