import { store } from "../../..";

export const targetSettingQueryString = () => {
    const {
        ui: {
            targets: { startDate, period },
        },
        entity: { active: entity_id },
    } = store.getState();

    return (
        '?' +
    `&from=${startDate.toISOString()}` +
    `&window=${period.size}+${period.unit}` +
    `&entity_id=${entity_id}`
    );
};
