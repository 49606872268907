import moment from 'moment';
import { TargetsUIConstants as K } from '../../store/old/UI/TargetsUI/TargetsUI.constants';

export const buildTargetsUIState = (query) => {
    if (!query.window) return;
    const [size, unit] = query.window.split('+');

    const startDate = moment(query.from);

    const period = K.PERIOD_OPTIONS.find(
        (p) => p.size === +size && p.unit === unit
    );

    return { startDate, period };
};

const getTargetValueByResolution = (t, { res_x, res_period }) => {
    const intervalDurationSeconds = moment
        .duration(res_x, res_period)
        .as('seconds');
    const planDurationSeconds = moment(t.to).diff(moment(t.from), 'seconds');

    return (t.value / planDurationSeconds) * intervalDurationSeconds;
};

export const getTargetsPathCoords = (targets, timeframe, resolution) => {
    const [lower, upper] = timeframe;

    const horizontalPaths = [];
    const windowEnd = new Date(upper);
    let idx = 0;
    let time = new Date(lower);

    while (time < windowEnd) {
        const nextTarget = targets[idx];
        const hasPlannedProduction =
      nextTarget &&
      moment(time).isBetween(
          moment(nextTarget.from),
          moment(nextTarget.to),
          null,
          '[]'
      );

        if (hasPlannedProduction) {
            const targetOutput = getTargetValueByResolution(nextTarget, resolution);
            horizontalPaths.push(
                [new Date(nextTarget.from), targetOutput],
                [new Date(nextTarget.to), targetOutput]
            );
            const timeEnd =
        new Date(nextTarget.to) < windowEnd
            ? new Date(nextTarget.to)
            : windowEnd;
            time = timeEnd;
            idx++;
        } else {
            const NO_PLANNED_OUTPUT = 0;
            const timeEnd = nextTarget ? new Date(nextTarget.from) : windowEnd;
            horizontalPaths.push(
                [time, NO_PLANNED_OUTPUT],
                [timeEnd, NO_PLANNED_OUTPUT]
            );
            time = timeEnd;
        }
    }

    return horizontalPaths;
};
