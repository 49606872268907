import { DashboardConstants as K } from './Dashboard.constants';

export const toggleSummaryTileLoading = () => {
    return {
        type: K.ACTIONS.DASHBOARD_TOGGLE_SUMMARY_TILE_LOADING,
    };
};

export const ChangeDashboardViewMode = (viewMode) => {
    return {
        type: K.ACTIONS.DASHBOARD_CHANGE_VIEW_MODE,
        payload: { viewMode },
    };
};

export const ChangeDashboardMode = (mode) => {
    return {
        type: K.ACTIONS.DASHBOARD_CHANGE_MODE,
        payload: { mode },
    };
};
