/* eslint-disable react/prop-types */
import React from 'react';
import FormCustomField from './FormCustomField';

const FormInput = (props) => {
    const {
        label,
        handleChange,
        value,
        placeholder,
        type,
        className,
        labelCol,
        labelClassed,
        inputCol,
        inputClassed,
        inputName,
        required,
        disabled,
        keyPress,
        children,
        min,
        step,
        disableInputClass,
        pattern,
        maxLength = 50,
    } = props;

    return (
        <FormCustomField className={className}>
            {label && (
                <label
                    className={`col-${
                        labelCol || 4
                    } col-form-label d-flex justify-content-end ${labelClassed || ''}`}
                >
                    {label}
                </label>
            )}
            <div
                className={
                    disableInputClass ? '' : `col-${inputCol || 8} position-relative`
                }
            >
                <input
                    name={inputName || ''}
                    type={type || 'text'}
                    className={`form-control ${inputClassed || ''}`}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={handleChange}
                    required={required}
                    disabled={disabled}
                    onKeyPress={keyPress}
                    min={min}
                    step={step}
                    pattern={pattern}
                    maxLength={maxLength}
                />
                {children}
            </div>
        </FormCustomField>
    );
};

export default FormInput;
