import moment from 'moment';
import { RegressionConstants as K } from './RegressionUI.constants';
import { toUnix } from '../../../../legacy/utils/date';
import { generateContinuousDomain } from '../../../../legacy/utils/charts';

export const regressionState = (appState) => {
    return appState.ui.regression;
};

export const regressionChartDomains = (appState) => {
    const rState = regressionState(appState);
    const { data, endDate, startDate } = rState;

    return {
        x: generateContinuousDomain(data, K.ACCESSORS.x, 1.2),
        y: generateContinuousDomain(data, K.ACCESSORS.y, 1.2),
        color:
      startDate && endDate ? [toUnix(startDate), toUnix(moment(endDate))] : [],
    };
};
