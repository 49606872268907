import { Button } from "@mui/material"
import "./DrawerButton.scss"
import React from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Drawer from "../../Drawer/Drawer"

const DrawerButton = (props: Props) => {
    return (
        <>
            <Button size="small" onClick={props.handleOpen} sx={{ minWidth: 0, textTransform: "none" }}>{props.label}</Button>
            <Drawer
                anchor={props.anchor ?? "right"}
                open={props.open}
                container={props.container}
                width="80%"
                header={
                    <Button onClick={props.handleClose}>
                        <ChevronLeftIcon/>Done
                    </Button>
                }
            >
                {props.children}
            </Drawer>
        </>
    )
}

interface Props {
    label: React.ReactNode | string
    children: React.ReactNode
    open: boolean
    anchor?: "left" | "top" | "right" | "bottom"
    handleClose: () => void
    handleOpen: React.MouseEventHandler
    container?: Element | (() => Element | null) | null | undefined
}

export default DrawerButton
