import "./ViewTabs.scss"

import React from "react"
import { BlockConstants as K } from "../../../store/old/UI/block/block.constants"
import { setMode } from "../../../store/old/UI/block/block.action"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import AukButton from "../../../legacy/components/AukButton"


const MODES = [
    { label: "OEE Time Series", value: K.VIEWS.OEE_TIME_SERIES },
    { label: "Output", value: K.VIEWS.OUTPUT },
    { label: "OEE Aggregated", value: K.VIEWS.OEE_AGGREGATED }
]

const ViewTabs = () => {
    const dispatch = useAppDispatch()
    const { mode } = useAppSelector(appState => appState.ui.block)

    return <div className="block__tabs">
        {MODES.map((v) => {
            const isActive = mode === v.value
            return (
                <AukButton.ToggleBlue
                    key={v.value}
                    active={isActive}
                    onClick={() => { dispatch(setMode(v.value)) }}
                    style={{ fontSize: 12 }}
                >
                    {v.label}
                </AukButton.ToggleBlue>
            )
        })}
    </div>
}

export default ViewTabs