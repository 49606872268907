import { all, put, takeLatest } from 'redux-saga/effects';

import CONSTANTS from '../../legacy/Constants';
import { ResetDashboardTilesState } from './Tiles/Tiles.action';
import { resetAssetsState } from './Assets/Assets.action';
import { resetBlocksState } from './Blocks/Blocks.action';
import { ResetDevicesState } from './Devices/Devices.action';
import { resetFusionsState } from './Fusions/Fusions.action';
import { ResetMetadataState } from './Metadata/Metadata.action';
import { resetIssuesState } from './Issues/Issues.action';
import { resetPermissionsStore } from './Permission/Permission.action';
import { resetSchedulesStore } from './Schedules/Schedules.action';
import { appUninit } from './rootReducer';
import { resetSkusState } from './Sku/Sku.action';

function* handleSoftReset(action) {
    try {
        yield put(appUninit());
        yield put(CONSTANTS.REDUX_STORE.SOFT_RESET_UI);
        yield take('UI_STORE_RESET_SUCCESS')

        yield all([
            put(resetPermissionsStore()),
            put(ResetDashboardTilesState()),
            put(resetAssetsState()),
            put(resetBlocksState()),
            put(ResetDevicesState()),
            put(ResetMetadataState()),
            put(resetFusionsState()),
            put(resetIssuesState()),
            put(resetSchedulesStore()),
            put(resetSkusState()),
        ]);
    } catch (e) {}
}

export function* softResetStoreSaga() {
    yield takeLatest(CONSTANTS.REDUX_STORE.SOFT_RESET, handleSoftReset);
}
