export const entityPreferences = (appState) =>
    appState.preference.entity;

export const oeePreferencesSelector = (appState) => {
    const oee2 = appState.preference.entity.oee ? appState.preference.entity.oee.oee2 : true;
    const sku_oee = appState.preference.entity.oee && appState.preference.entity.oee.sku_oee !== undefined
        ? appState.preference.entity.oee.sku_oee
        : true;

    return { oee2, sku_oee }
}

export const languagePreference = (appState) =>
    appState.preference.user.language || appState.preference.entity.language;