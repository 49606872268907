import moment from 'moment';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { TargetsUIConstants as K } from './TargetsUI.constants';

const initialState = {
    startDate: moment().startOf('day'),
    period: K.DEFAULT_PERIOD_OPTION,
};

export const TargetsUIReducer = (
    state = initialState,
    action
) => {
    if (action.type === K.ACTIONS.TARGETS_SET_UI_STORE) {
        return updateStoreRedux(state, { ...action.payload });
    }

    return updateStoreRedux(state, {});
};
