import React from "react"
import ToggleButtons from "../../../components/ToggleButtons/ToggleButtons"
import { ImpactTypeEnum } from "../../../constants/impact.constants"
import { type SxProps, ToggleButton } from "@mui/material"

const ImpactToggleButtons = (props: Props) => {
    return <ToggleButtons
        className="impact-toggle-buttons"
        value={props.value}
        onChange={(_, val) => { props.onChange(val) }}
        sx={props.sx}
    >
        <ToggleButton value={ImpactTypeEnum.COST_SAVINGS}>Cost Savings</ToggleButton>
        <ToggleButton value={ImpactTypeEnum.REVENUE_GAIN}>Revenue Gain</ToggleButton>

    </ToggleButtons>
}

interface Props {
    value: ImpactTypeEnum
    onChange: (val: ImpactTypeEnum) => void
    sx?: SxProps
    nullable?: boolean
}

export default ImpactToggleButtons
