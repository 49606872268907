import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import { SetControls } from './Controls.action';
import { currentEntitySelector } from '../../Entity/Entity.selector';
import { ControlsConstants as K, SHIFT } from './Controls.constants';
import { isControlledView, getRangeFromTimeBlock, getBrowserTz, RESOLUTIONS_MAP } from '../../../../legacy/utils/controls';
import { store } from '../../..';

function* handleInitControlPanel(action) {
    try {
        const appState = store.getState();
        const {
            ui: { controls },
            preference,
        } = appState;

        const { tzStartDifference } = currentEntitySelector(appState);

        const oee2 = preference.entity.oee ? preference.entity.oee.oee2 : true;
        const sku_oee = preference.entity.oee && preference.entity.oee.sku_oee !== undefined
            ? preference.entity.oee.sku_oee
            : true;

        const now = new moment();

        let startDate, endDate, span, resolution, streaming;

        if (action.payload) {
            span = action.payload.span;
            resolution = action.payload.resolution;
            startDate = action.payload.startDate;
            endDate = action.payload.endDate;
            streaming = action.payload.streaming;
        } else if (preference.user.time) {
            span = preference.user.time.range;
            resolution = RESOLUTIONS_MAP[preference.user.time.resolution];
            streaming = true;
            const [size, unit] = preference.user.time.range.split(' ');
            [startDate, endDate] = getRangeFromTimeBlock(
                now,
                { size, unit },
                getBrowserTz(),
                tzStartDifference
            );
        } else {
            span = '1 day';
            resolution = controls.resolution;
            streaming = true;
            [startDate, endDate] = getRangeFromTimeBlock(
                now,
                { size: 1, unit: 'day' },
                getBrowserTz(),
                tzStartDifference
            );
        }

        const controlsState = {
            startDate,
            endDate,
            oee2,
            span,
            sku_oee,
            resolution,
            streaming,
            initialized: true
        }

        yield put(SetControls(controlsState));

        if (action.callback) {
            action.callback(controlsState)
        }
    } catch (e) {
        console.error(e);
    }
}

export function* initControlPanelSaga() {
    yield takeLatest(
        K.ACTIONS.INIT_CONTROL_PANEL,
        handleInitControlPanel
    );
}
