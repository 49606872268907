import "./style.scss"
import { Typography } from "@mui/material"
import classNames from "classnames"
import moment from "moment/moment"
import React from "react"
import Grid from "../../Grid/Grid"
import { CalendarProps } from "../types"
import DayCalendarButton from "./DayCalendarButton/DayCalendarButton"
import DayCalendarHeader from "./DayCalendarHeader/DayCalendarHeader"

const DayCalendar = (props: CalendarProps) => {
    const reference = props.referenceDate.clone().startOf("month")
    const firstDay = reference.isoWeekday()

    const rows: DayCalendarRows = []
    const firstDates: (moment.Moment | null)[] = []
    for (let i = 1; i <= 7; i++) {
        if (i < firstDay) {
            firstDates.push(null)
        } else {
            firstDates.push(reference.clone())
            reference.add(1, "day")
        }
    }

    rows.push(firstDates)
    for (let r = 1; r <= 5; r++) {
        const _row: DayCalendarRow = []
        for (let d = 1; d <= 7; d++) {
            if (reference.date() === 1) {
                _row.push(null)
                break
            }

            _row.push(reference.clone())
            reference.add(1, "day")
        }

        rows.push(_row)
    }

    const weekStart = props.referenceDate.clone().startOf("isoWeek")
    const weekdays: Weekday[] = []
    for (let i = 1; i <= 7; i++) {
        weekdays.push(weekStart.format("dddd") as Weekday)
        weekStart.add(1, "day")
    }

    const handlePress = (value: moment.Moment) => {
        props.onSelect(value)
    }

    const lastDate = props.referenceDate.clone().endOf("month").date()
    return <Grid className={classNames(props.className, "day-calendar")}>
        <Grid container className="day-calendar-actions">
            <Grid item className="calendar-actions-item">{props.actions?.left}</Grid>
            <Grid item flex={1} className="calendar-actions-year">
                <Typography variant="subtitle1">{props.referenceDate.format("MMM YYYY")}</Typography>
            </Grid>
            <Grid item className="calendar-actions-item">{props.actions?.right}</Grid>
        </Grid>
        <Grid container direction="column" className="day-calendar-buttons">
            <Grid container item direction="row" className="calendar-buttons-row">
                {weekdays.map(weekday => <DayCalendarHeader key={weekday} value={weekday}/>)}
            </Grid>
            {rows.map(
                (dates, i) => <Grid container item direction="row" className="calendar-buttons-row" key={i}>
                    {dates.map((date, i) => {
                        if (date === null) {
                            return <div key={i}/>
                        }

                        const calendarDate = date.date()
                        return <DayCalendarButton
                            key={date.format("YYYYMMDD")}
                            value={date}
                            start={props.start}
                            end={props.end}
                            disabled={props.excludeDays?.includes(date.isoWeekday())}
                            firstChild={calendarDate === 1}
                            lastChild={calendarDate === lastDate}
                            onPress={handlePress}
                        />
                    })}
                </Grid>
            )}
        </Grid>
    </Grid>
}

type Weekday = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
type DayCalendarRow = (moment.Moment | null)[]
type DayCalendarRows = DayCalendarRow[]

export default DayCalendar
