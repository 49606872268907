/* eslint-disable react/prop-types */
import React from 'react';
import AutosizeText from '../../components/AutosizeText';

import './PercentBar.scss';

export const PercentBar = (props) => {
    return (
        <div className="percent-bar-container" style={props.style}>
            {props.withLabel ? (
                <span className="percent-bar__label">
                    <AutosizeText>{props.label}</AutosizeText>
                </span>
            ) : null}
            <div className="percent-bar">
                <div
                    className="percent-bar__value"
                    style={{
                        width: props.width,
                        background: props.color,
                    }}
                />
            </div>
            {props.withLabel ? (
                <span className="percent-bar__value-label">
                    <AutosizeText>{props.value}%</AutosizeText>
                </span>
            ) : null}
        </div>
    );
};
