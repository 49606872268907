import "./style.scss"
import { CheckCircle, Cancel } from "@mui/icons-material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid"
import cx from "classnames"
import moment from "moment"
import React, { useMemo } from "react"
import { AutoSizer } from "react-virtualized"
import ProcessTreeTable from "../../../../../components/Table/ProcessTreeTable/ProcessTreeTable"
import { objectiveDuration, rangeToTimeKey, TimeKey, TimeRange } from "../../../../../store/actions/goals.action"
import { useAppSelector } from "../../../../../store/hooks"
import { selectActiveEntity } from "../../../../../store/selectors/entities.selectors"
import {
    selectCurrentProcess,
    selectObjective,
    selectObjectiveGoals,
    selectObjectiveOee
} from "../../../../../store/selectors/goals.selectors"
import useBlocks from "../../../../../hooks/useBlocks"
import { depthFirstTraversal } from "../../../../../legacy/utils/helpers"
import BlockModel from "../../../../../api/models/block.model"

const COLUMN_WIDTH = 120
const goalTargetIndicatorColumn: GridColDef = {
    field: "",
    headerName: " ",
    type: "string",
    width: 80,
    sortable: false,
    align: "right",
    valueFormatter: () => {
        return <div className="plan-container">
            <div className="plan-container-goal plan-container-goal--align-right">Goal</div>
            <div className="plan-container-goal plan-container-goal--align-right">Actual</div>
        </div>
    }
}

const ObjectiveProgressivePlan = () => {
    const viewing = useAppSelector(selectObjective)
    const goals = useAppSelector(selectObjectiveGoals)
    const oee = useAppSelector(selectObjectiveOee)
    const entity = useAppSelector(selectActiveEntity)
    const currentProcess = useAppSelector(selectCurrentProcess)
    const { blocks } = useBlocks()

    const blockIds = useMemo(() => {
        const result: number[] = []
        if (!currentProcess) return result

        depthFirstTraversal(blocks[currentProcess as number], (node: BlockModel) => result.push(node.blockId))
        return result
    }, [blocks, currentProcess])

    if (!viewing) {
        return null
    }

    const timeFrame = objectiveDuration(viewing, entity)
    const dynamicColumns: GridColDef[] = timeFrame.map(column => generateTimeColumn(
        column,
        viewing.unitOfTime as moment.unitOfTime.Base,
    ))

    const data: Datum[] = []
    for (let i = 0; i <  blockIds.length; i++) {
        const blockId = blockIds[i]
        const item: Datum = { id: +blockId }
        for (const column of dynamicColumns) {
            let goal = undefined
            if (goals[blockId] && goals[blockId][column.field] !== undefined) {
                goal = goals[blockId][column.field]
            }

            let blockOee = undefined
            if (oee[blockId] && oee[blockId][column.field] !== undefined) {
                blockOee = oee[blockId][column.field]
            }

            item[column.field] = { goal: goal?.value, oee: blockOee }
        }

        data.push(item)
    }

    const columns =  [goalTargetIndicatorColumn].concat(...dynamicColumns)

    return <AutoSizer>
        {({ width, height }) =>
            <ProcessTreeTable
                process={currentProcess}
                columns={columns}
                data={data}
                width={width}
                height={height}
                rowHeight={56}
            />
        }
    </AutoSizer>
}

const generateTimeColumn = (range: TimeRange, unitOfTime: moment.unitOfTime.Base): GridColDef => {
    const timeKey = rangeToTimeKey(range)
    return {
        field: timeKey,
        headerName: getTimeFormat(range.startsAt, unitOfTime),
        type: "string",
        width: COLUMN_WIDTH,
        sortable: false,
        align: "center",
        valueFormatter: (params: GridValueFormatterParams) => {
            const goal = params.value?.goal
            const oee = params.value?.oee

            const [delta, Icon] = calculateDelta(goal, oee)
            return <div className="plan-container">
                <div className="plan-container-goal">{goal !== undefined ? `${goal.toFixed(1)}%` : "-"}</div>
                <div className="plan-container-oee">
                    {oee !== undefined ? `${oee.toFixed(1)}%` : "-"}
                </div>
                {
                    Icon && <div className={cx({
                        "plan-container-icon": true,
                        "plan-container-icon-positive": delta && delta > 0,
                        "plan-container-icon-negative": delta && delta < 0,
                    })}>
                        <Icon/>
                    </div>
                }

            </div>
        },
    }
}

export const getTimeFormat = (time: moment.Moment, unitOfTime: moment.unitOfTime.Base) => {
    if (unitOfTime === "months") {
        return time.format("MMM YYYY")
    }

    if (unitOfTime === "weeks") {
        return `Week ${time.format("DD/MM")}`
    }

    throw new Error("Time format is invalid")
}

const calculateDelta = (goal?: number, oee?: number): [number?, OverridableComponent<any>?] => {
    if (goal === undefined || oee === undefined) {
        return [undefined, undefined]
    }

    const delta = oee - goal
    if (delta > 0) {
        return [delta, CheckCircle]
    }

    if (delta < 0) {
        return [delta, Cancel]
    }

    return [undefined, undefined]
}

type Datum = {
    id: Domain.BlockId
    [key: TimeKey]: { goal?: number, oee?: number } | number
}

export default ObjectiveProgressivePlan
