/* eslint-disable react/prop-types */
// LIBRARIES
import React from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import ImageInput from './ImageInput';


// HOCS
import { withEntity } from '../Wrappers/HOCs/withEntity';
import { updateEntityImageRequest } from '../../store/old/Images/Images.action';

import './CompanyLogo.scss'
class CompanyLogo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            img: this.props.entity.image || '',
        };

        this.handleChangeImage = this.handleChangeImage.bind(this);
    }

    handleChangeImage(result) {
        this.setState({ img: result }, () => this.props.updateEntityImage(result));
    }

    get imgInput() {
        return (
            <ImageInput
                ref={(node) => (this.imageInput = node)}
                icon={`fas fa-plus-circle`}
                onChange={this.handleChangeImage}
                style={{
                    position: 'absolute',
                    color: this.state.img ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.11)',
                }}
            />
        );
    }

    get image() {
        return this.state.img ? (
            <img
                onDoubleClick={() => this.imageInput && this.imageInput.click()}
                style={{ width: '100%', height: '100%' }}
                src={this.state.img}
                alt="entity"
            />
        ) : null;
    }

    render() {
        return (
            <div className="company-logo">
                <div className="content-comp-logo d-flex justify-content-center align-items-center">
                    {this.imgInput}
                    {this.image}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        // entity: currentEntitySelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEntityImage: (base64Img) =>
            dispatch(updateEntityImageRequest(base64Img)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEntity(CompanyLogo));
