export const PreferenceConstants = {
    ACTIONS: {
        CHANGE_USER_LANGUAGE: 'CHANGE_USER_LANGUAGE',
        SET_ENTITY_PREFERENCES: 'SET_ENTITY_PREFERENCES',
        GET_ENTITY_PREFERENCES_REQUEST: 'GET_ENTITY_PREFERENCES_REQUEST',
        GET_ENTITY_PREFERENCES_SUCCESS: 'GET_ENTITY_PREFERENCES_SUCCESS',
        GET_ENTITY_PREFERENCES_FAILURE: 'GET_ENTITY_PREFERENCES_FAILURE',
        UPDATE_ENTITY_PREFERENCES_REQUEST: 'UPDATE_ENTITY_PREFERENCES_REQUEST',
        UPDATE_ENTITY_PREFERENCES_SUCCESS: 'UPDATE_ENTITY_PREFERENCES_SUCCESS',
        UPDATE_ENTITY_PREFERENCES_FAILURE: 'UPDATE_ENTITY_PREFERENCES_FAILURE',
        CREATE_ENTITY_PREFERENCES_REQUEST: 'CREATE_ENTITY_PREFERENCES_REQUEST',
        CREATE_ENTITY_PREFERENCES_SUCCESS: 'CREATE_ENTITY_PREFERENCES_SUCCESS',
        SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',
        CREATE_ENTITY_PREFERENCES_FAILURE: 'CREATE_ENTITY_PREFERENCES_FAILURE',
        GET_USER_PREFERENCES_REQUEST: 'GET_USER_PREFERENCES_REQUEST',
        GET_USER_PREFERENCES_SUCCESS: 'GET_USER_PREFERENCES_SUCCESS',
        GET_USER_PREFERENCES_FAILURE: 'GET_USER_PREFERENCES_FAILURE',
        UPDATE_USER_PREFERENCES_REQUEST: 'UPDATE_USER_PREFERENCES_REQUEST',
        UPDATE_USER_PREFERENCES_SUCCESS: 'UPDATE_USER_PREFERENCES_SUCCESS',
        UPDATE_USER_PREFERENCES_FAILURE: 'UPDATE_USER_PREFERENCES_FAILURE',
        CREATE_USER_PREFERENCES_REQUEST: 'CREATE_USER_PREFERENCES_REQUEST',
        CREATE_USER_PREFERENCES_SUCCESS: 'CREATE_USER_PREFERENCES_SUCCESS',
        CREATE_USER_PREFERENCES_FAILURE: 'CREATE_USER_PREFERENCES_FAILURE',
        SET_ASSET_OPERATOR_PREFERENCES: 'SET_ASSET_OPERATOR_PREFERENCES',
    },
};
