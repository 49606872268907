import React, { isValidElement } from 'react';

import './Flash.scss';
import { notification } from 'antd';
import { keys } from 'lodash';

export const flash = (data) => {
    let { status: type, details: description } = data;

    if (typeof description === 'object' && !isValidElement(description)) {
        description = keys(description).reduce(
            (acc, curr) => (acc += `${description[curr]} `),
            ''
        );
    }

    return notification[type || 'success']({
        ...data,
        description,
    });
};

export const errorFlash = (err) => {
    console.error(err);
    flash({ ...err, status: 'error' });
};

/* 
  validation errors are of the form Array<string> 
*/
export const generateValidationErrorDetails = (errors) => {
    return (
        <ol>
            {errors.map((e, i) => (
                <li key={i}>{e}</li>
            ))}
        </ol>
    );
};
