import "./style.scss"
import ReactDOM from "react-dom"
import { Backdrop, type BackdropProps, CircularProgress } from "@mui/material"
import React from "react"

const LoadingTemplate = () => {
    return <div className="wrapper">
        <CircularProgress />
    </div>
}

export const LoadingBackdrop = (props: BackdropProps) => {
    return ReactDOM.createPortal(
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>,
        document.body,
    )
}

export default LoadingTemplate
