import React from 'react';
import { useSelector } from 'react-redux';

// SELECTOR
import {
    currentEntitySelector,
    entitiesSelector,
    organisationSelector,
} from '../../../store/old/Entity/Entity.selector';

export function withEntity(
    Component,
    AltComponent
) {
    return function WrapperComponent(props) {
        const entity = useSelector(currentEntitySelector);
        const organisation = useSelector(organisationSelector);
        const entities = useSelector(entitiesSelector);

        return entity ? (
            <Component
                {...props}
                key={entity.entity_id}
                organisation={organisation}
                entities={entities}
                entity={entity}
            />
        ) : AltComponent ? (
            <AltComponent />
        ) : null;
    };
}
