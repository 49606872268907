import { ar } from "./en-ar"
import { de } from "./en-de"
import { el } from "./en-el"
import { en } from "./en-en"
import { es } from "./en-es"
import { fr } from "./en-fr"
import { id } from "./en-id"
import { it } from "./en-it"
import { ja } from "./en-ja"
import { ko } from "./en-ko"
import { ms } from "./en-ms"
import { pt } from "./en-pt"
import { ru } from "./en-ru"
import { sv } from "./en-sv"
import { th } from "./en-th"
import { tr } from "./en-tr"
import { vi } from "./en-vi"
import { zh } from "./en-zh"

type LanguageFiles = Record<string, LanguageMap>

type LanguageMap = Record<string, string>

const languageFiles: LanguageFiles = {
    ar,
    de,
    el,
    en,
    es,
    fr,
    id,
    it,
    ja,
    ko,
    ms,
    pt,
    ru,
    sv,
    th,
    tr,
    vi,
    zh,
}

export default languageFiles
