/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import AukButton from '../../AukButton';
import IconButtonClose from '../../IconButton/IconButtonClose';
import TextArea from '../../AukInput/TextArea';
import { PanelBody, PanelHeader } from '../../Panel';
import classNames from 'classnames';
import './SimpleTextModal.scss';
import DismissTimerIndicator from './DismissTimerIndicator/DismissTimerIndicator';

const ANIMATION_TIMEOUT_MS = 300;
const SimpleTextModal = ({
    title,
    value,
    maxLength,
    dismissMs,
    isOpen,
    isLoading,
    onClose,
    onChange,
    onSave,
}) => {
    const dismissTimerRef = useRef();
    const transitionTimerRef = useRef();
    const [_isOpen, _setIsOpen] = useState(isOpen ? isOpen : false);
    const [_value, _setValue] = useState(value ? value : '');
    const [isDismissCancelled, setIsDismissCancelled] = useState(false);
    const [transition, setTransition] = useState(false);

    const onChangeValue = (value) => {
        if (onChange !== undefined) {
            onChange(value);
        }

        _setValue(value);
    };

    const _onClose = () => {
        setTransition(false);
        clearTimeout(dismissTimerRef.current);
        dismissTimerRef.current = null;
        transitionTimerRef.current = setTimeout(() => {
            if (onClose !== undefined) {
                onClose();
            }

            _setIsOpen(false);
        }, ANIMATION_TIMEOUT_MS);
    };

    const setDismissTimer = (timeout) => {
        dismissTimerRef.current = setTimeout(() => {
            _onClose();
        }, timeout);

        setIsDismissCancelled(false);
    };

    const onTextFocus = () => {
        clearTimeout(dismissTimerRef.current);
        setIsDismissCancelled(true);
    };

    useEffect(() => {
        return () => {
            clearTimeout(dismissTimerRef.current);
            clearTimeout(transitionTimerRef.current);
        };
    }, []);

    useEffect(() => {
        if (_isOpen) {
            setTransition(true);
            clearTimeout(transitionTimerRef.current);
            if (dismissMs !== undefined) {
                setTimeout(() => {
                    setDismissTimer(dismissMs);
                }, ANIMATION_TIMEOUT_MS);
            }
            return;
        }

        onChangeValue('');
    }, [_isOpen]);

    useEffect(() => {
        if (value !== undefined) {
            _setValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (!isOpen) {
            _onClose();
            return;
        }

        _setIsOpen(true);
    }, [isOpen]);

    return createPortal(
        <div className={classNames({ 'st-modal': true, visible: _isOpen })}>
            <div
                className={classNames({
                    'st-modal-backdrop': true,
                    mounted: transition,
                })}
                onClick={() => _onClose()}
            />
            <div
                className={classNames({
                    'st-modal-content': true,
                    mounted: transition,
                })}
            >
                <PanelHeader className="st-modal-content-header">
                    <div className="content-header-title">{title}</div>
                    <div className="content-header-actions">
                        {dismissMs && transition && !isDismissCancelled && (
                            <DismissTimerIndicator dismissMs={dismissMs} />
                        )}
                        <IconButtonClose onClick={() => _onClose()} />
                    </div>
                </PanelHeader>
                <PanelBody className="st-modal-content-body">
                    <TextArea
                        onChange={(e) => onChangeValue(e.target.value)}
                        onFocus={dismissMs ? onTextFocus : undefined}
                        value={_value}
                        showCount
                        maxLength={maxLength}
                    />
                </PanelBody>
                <div className="st-modal-content-actions">
                    <AukButton.Save onClick={() => onSave(_value)} loading={isLoading} />
                </div>
            </div>
        </div>,
        document.body
    );
};

export default SimpleTextModal;
