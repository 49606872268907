
import * as d3 from 'd3';
import React, { useCallback, useContext, useMemo } from 'react';
import { Collapse, Popover } from 'antd';
import { isEmpty, round } from 'lodash';
import { AreaChartOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AssetDomainContext } from '.';
import CONSTANTS from "../../../Constants"
import AssetWidget from './AssetWidget';
import ChartEditable from './components/ChartEditable';
import OEEStackColumnChart from '../../../Charts/OEEStackColumnChart';
import { OEEDials } from '../../../Widgets';
import { ColumnChart, LabelsChart, LineChart } from '../../../Charts/v1';
import { Colors, OEELegend } from '../../../Charts';
import { ColumnChartTooltip, LabelsChartTooltip, TargetsChartTooltip } from '../../../Charts/v1/tooltips/templates';
import { NoData } from '../../../components/None';
import { generateContinuousDomain } from '../../../utils/charts';
import { labelDisplayText } from '../../../utils/labels';
import { LABEL_DRAWER } from '../../Labels/components';
import AukButton from '../../../components/AukButton';
import AukTooltip from '../../../components/AukTooltip';

const MARGIN = { top: 10, left: 35, right: 20, bottom: 20 };

const { ISSUE_LABELLER, PRODUCTION_LABELLER, TARGET_PRODUCTION_LABELLER } = LABEL_DRAWER.TYPE;

const labelsProps = {
    margin: { top: 0, left: 35, right: 20, bottom: 0 },
    xAccessor: (d) => new Date(d.from),
    x2Accessor: (d) => new Date(d._to),
    colorAccessorLabel: (d) => d.color,
    colorAccessorText: () => '#fafafa',
    textAccessor: labelDisplayText,
    useTooltip: true,
    htmlTooltip: LabelsChartTooltip,
    keyAccessor: (d) => d.label_id,
};

const targetsProps = {
    margin: labelsProps.margin,
    xAccessor: labelsProps.xAccessor,
    x2Accessor: (d) => new Date(d.to),
    colorAccessorLabel: () => '#2176FF',
    colorAccessorText: () => '#fafafa',
    textAccessor: (d) => (d.sku ? d.sku.codeName : 'Planned Production'),
    useTooltip: true,
    htmlTooltip: TargetsChartTooltip,
    keyAccessor: (d) => d.target_id,
};

const AssetBody = (props) => {
    return (
        <div className="asset-default__body">
            <div className="asset-default__body__charts">
                <CollapsibleOEEYieldChart />
                <CollapsibleChannelAndFusionCharts skuMultipleCharts={props.skuMultipleCharts}/>
            </div>
            <div className="asset-default__body__widgets">
                <OEEDialsWidget />
                <CumulativeChartWidget />
            </div>
        </div>
    );
};

export default AssetBody;

const CollapsibleOEEYieldChart = () => {
    const {
        asset,
        onBrushCancel,
        onBrushEnd,
        controls,
        doubleClickLabel,
        onClickOutput,
        window,
        showOutput,
    } = useContext(AssetDomainContext);

    return (
        <div className="asset-default__oee-chart">
            <Collapse ghost defaultActiveKey={[1]}>
                <Collapse.Panel
                    className="mb-2"
                    key={1}
                    header={
                        <div className="d-flex align-items-center">
                            <span className="mr-2" style={{ fontSize: 12 }}>
                Overall Equipment Effectiveness
                            </span>

                            <Popover
                                placement="rightBottom"
                                content={
                                    <span
                                        className="legend-popover-container d-flex"
                                        style={{ width: '60vw' }}
                                    >
                                        <OEELegend />
                                    </span>
                                }
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </div>
                    }
                >
                    <div
                        className="w-100 d-flex flex-column"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="d-flex justify-content-end">
                            <AukTooltip.Help
                                title={
                                    showOutput ? 'Click to show OEE' : 'Click to show output'
                                }
                            >
                                <AukButton.ToggleBlue
                                    className="d-flex align-items-center mb-2"
                                    active={showOutput}
                                    size="small"
                                    style={{
                                        marginRight: 20,
                                        opacity: showOutput ? 1 : 0.6,
                                    }}
                                    onClick={onClickOutput}
                                >
                                    <AreaChartOutlined /> Output
                                </AukButton.ToggleBlue>
                            </AukTooltip.Help>
                        </div>
                        <div style={{ height: 18 }}>
                            <LabelsChart
                                className="issue-labels-chart"
                                {...labelsProps}
                                xScale={d3.scaleTime()}
                                xDomain={window}
                                data={asset.issueLabels}
                                onDoubleClick={doubleClickLabel(
                                    ISSUE_LABELLER,
                                    asset.block,
                                    asset.oee.oee
                                )}
                            />
                        </div>
                        <div style={{ height: 140 }}>
                            {showOutput ? (
                                asset._primaryChart.dataSource.mode === '2a' ? (
                                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    Output chart not available for digital state inputs.
                                    </div>
                                ) : (
                                    <ColumnChart
                                        data={asset.oee.oee}
                                        xScale={d3.scaleTime()}
                                        yScale={d3.scaleLinear()}
                                        xDomain={window}
                                        yDomain={generateContinuousDomain(
                                            asset.oee.oee,
                                            (d) => d.yield
                                        )}
                                        margin={MARGIN}
                                        colorAccessor={() => Colors.green[0]}
                                        xAccessor={(d) => d.time}
                                        yAccessor={(d) => d.yield}
                                        useBrush={true}
                                        onBrushEnd={(bounds, scaled, brushElement, data) =>
                                            onBrushEnd(
                                                ISSUE_LABELLER,
                                                asset.block,
                                                bounds,
                                                scaled,
                                                data
                                            )
                                        }
                                        onBrushCancel={onBrushCancel}
                                        htmlTooltip={(d) => `Output: ${d.yield}`}
                                        showYGrid={true}
                                    />
                                )
                            ) : (
                                <OEEStackColumnChart
                                    xDomain={window}
                                    data={controls.oee2 ? asset.oee.oee2 : asset.oee.oee}
                                    margin={MARGIN}
                                    brush={{
                                        useBrush: true,
                                        onBrushEnd: (bounds, scaled, brushElement, data) =>
                                            onBrushEnd(
                                                ISSUE_LABELLER,
                                                asset.block,
                                                bounds,
                                                scaled,
                                                data
                                            ),
                                        onBrushCancel: onBrushCancel,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

const CollapsibleChannelAndFusionCharts = (props) => {
    const {
        asset,
        onBrushCancel,
        onBrushEnd,
        controls,
        doubleClickLabel,
        window,
        openSkuMultiple,
        skuLabels
    } = useContext(AssetDomainContext);

    const validSkuChartIndex = props.skuMultipleCharts
        .reduce((acc, curr, i) => ({...acc, [curr.chart_id]: i}), {})

    return (
        <div className="asset-default__charts">
            <Collapse ghost defaultActiveKey={asset._charts.map((c, i) => c.chart_id)}>
                {asset._charts.map((c, i) => {
                    const isPrimary = i === 0;
                    const aggregatedChartSkuLabels = skuLabels.aggregated[c.chart_id];
                    const totalSkuQuantity = aggregatedChartSkuLabels ? aggregatedChartSkuLabels.reduce((acc, curr) => acc += curr.quantity, 0) : ''
                    return (
                        <Collapse.Panel
                            key={c.chart_id}
                            header={
                                <span style={{ fontSize: 12 }}>
                                    {c.title}{' '}
                                    <i style={{ fontSize: 11 }}>({c.units})</i>
                                </span>
                            }
                        >
                            {isPrimary && (
                                <div className="asset-default__charts__labels pb-3">
                                    <div style={{ height: 18 }}>
                                        <LabelsChart
                                            className="targets-chart"
                                            {...targetsProps}
                                            xScale={d3.scaleTime()}
                                            xDomain={window}
                                            data={asset.targets}
                                            onDoubleClick={doubleClickLabel(
                                                TARGET_PRODUCTION_LABELLER,
                                                undefined,
                                                []
                                            )}
                                        />
                                    </div>
                                    <div style={{ height: 18 }}>
                                        <LabelsChart
                                            className="production-labels-chart"
                                            {...labelsProps}
                                            xScale={d3.scaleTime()}
                                            xDomain={window}
                                            data={asset.productionLabels}
                                            onDoubleClick={doubleClickLabel(
                                                PRODUCTION_LABELLER,
                                                asset._primaryChart.dataSource,
                                                asset._primaryChart.dataSource.data
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            <div style={{ height: 140 }}>
                                <ChartEditable
                                    isPrimary={isPrimary}
                                    meta={c.dataSource}
                                    asset={asset}
                                    controls={controls}
                                    window={window}
                                    margin={MARGIN}
                                    onBrushCancel={onBrushCancel}
                                    onBrushEnd={(bounds, scaled, brushElement, data) =>
                                        onBrushEnd(PRODUCTION_LABELLER, c.dataSource, bounds, scaled, data)
                                    }
                                    onClickTitle={() => {
                                        openSkuMultiple(validSkuChartIndex[c.chart_id])
                                    }}
                                    appendTitle={totalSkuQuantity ? `/ ${totalSkuQuantity.toLocaleString()} pcs` : ''}
                                />
                            </div>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

const OEEDialsWidget = () => {
    const { asset, controls } = useContext(AssetDomainContext);

    const data = isEmpty(asset.oee)
        ? {
            final_effective: 0,
            loading: 0,
            availability: 0,
            performance: 0,
            quality: 0,
        }
        : {
            final_effective: asset.oee.overall.final_effective,
            loading: asset.oee.overall.loading,
            availability: asset.oee.overall.availability,
            performance: asset.oee.overall.performance,
            quality: asset.oee.overall.quality,
        };

    return (
        <AssetWidget style={{ height: 220, flexShrink: 0 }}>
            <div className="w-100 h-100 d-flex">
                <OEEDials display="column" data={data} oee2={controls.oee2} />
            </div>
        </AssetWidget>
    );
};

const yAccessor = (d) => d.val;
const xAccessor = (d) => d.time;
const yTickFormat = (d) => (`${d}`.length > 4 ? d.toExponential(1) : d);

const CumulativeChartWidget = () => {
    const { asset, window } = useContext(AssetDomainContext);
    const primary = useMemo(() => asset._primaryChart, [asset]);

    if (!primary?.dataSource) return null;

    const { data, mode } = primary.dataSource;

    const yDomain = generateContinuousDomain(data.cumulative, yAccessor);
    const show = mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT;

    return (
        <AssetWidget style={{ flexGrow: 1 }}>
            {show ? (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            fontSize: 11,
                            right: '1em',
                            fontWeight: 400,
                        }}
                    >
                        <i>Total: {round(data.total, 2)}</i>
                    </div>
                    <LineChart
                        margin={{ top: 10, left: 35, right: 15, bottom: 20 }}
                        xScale={d3.scaleTime()}
                        yScale={d3.scaleLinear()}
                        data={data.cumulative}
                        xDomain={window}
                        yDomain={yDomain}
                        xAccessor={xAccessor}
                        yAccessor={yAccessor}
                        useBrush={false}
                        htmlTooltip={ColumnChartTooltip}
                        yTickFormat={yTickFormat}
                        dots={false}
                        showYGrid={true}
                        xTicks={4}
                        isArea
                    />
                </>
            ) : (
                <NoData
                    className="h-100"
                    description="Cumulative chart not available"
                />
            )}
        </AssetWidget>
    );
};
