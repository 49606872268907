import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import PreferencePanes from './OperatorConsolePreferencePanes';
import { setAssetOperatorSettings } from '../../../../../store/old/Assets/Assets.action';
import './OperatorPreferencesModal.scss';

class OperatorPreferencesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...props.preferences };

        this.handleChangePanelDisplay = this.handleChangePanelDisplay.bind(this);
        this.handleAddAlert = this.handleAddAlert.bind(this);
        this.handleChangeAlert = this.handleChangeAlert.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(prevProps.preferences, this.props.preferences)
        ) {
            this.setState({ ...this.props.preferences });
        }
    }

    // display panels
    handleChangePanelDisplay(name) {
        this.setState({
            panels: {
                ...this.state.panels,
                [name]: !this.state.panels[name],
            },
        });
    }

    // alerts
    handleAddAlert() {
        const newAlert = {
            cat: null,
            dur_x: '',
            dur_period: null,
        };
        this.setState({ alerts: this.state.alerts.concat(newAlert) });
    }

    handleChangeAlert(index, field, value) {
        this.setState((prevState) => {
            const { alerts } = prevState;
            const item = alerts[index];
            item[field] = value;
            return { alerts: alerts.concat() };
        });
    }

    handleDeleteAlert(index) {
        this.setState((prevState) => {
            const alerts = prevState.alerts.filter((a, i) => i !== index);
            return { alerts };
        });
    }

    handleSubmit() {
        this.props.setOperatorConsolePreference(this.formData);

        this.props.toggle();
    }

    get formData() {
        const { panels, alerts, apply_to, preference_id } = this.state;

        return {
            panels,
            apply_to,
            preference_id,
            asset_id: this.props.asset.asset_id,
            alerts: alerts.filter((a) => a.cat && a.dur_x && a.dur_period),
        };
    }

    get assetOptions() {}

    render() {
        const {
            props: { show, toggle },
            handleSubmit,
        } = this;

        return (
            <Modal
                className="auk-modal operator-preferences-modal"
                title="Configure Operator Console"
                open={show}
                onOk={handleSubmit}
                onCancel={toggle}
            >
                <PreferencePanes
                    asset={this.props.asset}
                    dateRange={this.props.dateRange}
                    controls={this.props.controls}
                    handleChangePanelDisplay={this.handleChangePanelDisplay}
                    handleAddAlert={this.handleAddAlert}
                    handleChangeAlert={this.handleChangeAlert}
                    handleDeleteAlert={this.handleDeleteAlert}
                    {...this.state}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        skus: rootState.sku.skus,
        preferences: rootState.preference.asset,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOperatorConsolePreference: (data) =>
            dispatch(setAssetOperatorSettings(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperatorPreferencesModal);
