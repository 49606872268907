import { makeRequest } from '../Shared/Shared.action';
import { Group } from '../../../legacy/models';

export const api_getUserGroups = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/group`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createUserGroup = (entity_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/group`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateUserGroup = (entity_id, group_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/group/${group_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteUserGroup = (entity_id, group_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/group/${group_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};
