import "./style.scss"
import React, { type ChangeEventHandler, forwardRef } from "react"
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material"
import BaseCheckbox from "@mui/material/Checkbox"

const Checkbox = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    return <FormControl>
        <FormControlLabel
            required={props.required}
            className="checkbox-control-label"
            label={props.children}
            control={<BaseCheckbox ref={ref} disabled={props.disabled ?? false} name={props.name} color="secondary" onChange={props.onChange} checked={props.value}/>}
        />
        {props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </FormControl>
})

interface Props {
    children: React.ReactNode
    name?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    value?: boolean
    required?: boolean
    error?: boolean
    helperText?: string
    disabled?: boolean
}

Checkbox.displayName = "Checkbox"

export default Checkbox
