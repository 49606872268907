/* eslint-disable react/prop-types */
import { Input } from 'antd';
import React, { useMemo } from 'react';
import classNames from 'classnames';

import './TextArea.scss';

const TextArea = (props) => {
    const { value, showCount, className = '', maxLength } = props;

    const countExceeded = useMemo(() => {
        if (!showCount) return false;
        return value.length >= maxLength;
    }, [showCount, value]);

    return (
        <Input.TextArea
            {...props}
            className={classNames({
                'auk-input-textarea': true,
                'auk-input-textarea-show-count--red': countExceeded,
                [className]: true,
            })}
        />
    );
};

export default TextArea;
