/* eslint-disable react/prop-types */
import React from 'react';

import './LoadersHorizontal.scss';

export default class LoadersHorizontal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { count: 0 };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ count: (this.state.count % 3) + 1  })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="loaders-horizontal" style={this.props.style}>
                {
                    [...Array(this.state.count).keys()]
                        .map(i => <div key={i} className="circle"></div>)
                }
            </div>
        )
    }
}