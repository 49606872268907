const createNodes = (block: any, nodes: Domain.ProcessNode[] = [], options: Domain.ProcessNodeOptions = {}): Domain.ProcessNode[] => {
    nodes.push(createNode(block, options))
    if (!block.children || block.children.length === 0) {
        return nodes
    }

    for (const child of block.children) {
        createNodes(child, nodes, options)
    }

    return nodes
}

const createNode = (block: any, options: Domain.ProcessNodeOptions = {}): Domain.ProcessNode => ({
    id: block.blockId,
    label: block.label,
    hierarchy: block.hierarchyLevel,
    isVisible: options.isVisible ? options.isVisible(block) : true,
    isExpanded: options.isExpanded ? options.isExpanded(block) : true,
    hasChildren: block.children && block.children.length > 0,
})

export default createNodes
