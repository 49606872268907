import React from "react"
import { type SnackbarKey, useSnackbar } from "notistack"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { snacksRemove } from "../store/reducers/snacks.reducer"

let displayed: SnackbarKey[] = []

const useSnack = () => {
    const dispatch = useAppDispatch()
    const snacks = useAppSelector(({ snacks }) => snacks)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)]
    }

    React.useEffect(() => {
        snacks.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                closeSnackbar(key)
                return
            }

            if (displayed.includes(key)) {
                return
            }

            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose !== undefined) {
                        options.onClose(event, reason, myKey)
                    }
                },
                onExited: (_, myKey) => {
                    dispatch(snacksRemove(myKey))
                    removeDisplayed(myKey)
                },
            })

            storeDisplayed(key)
        })
    }, [snacks, closeSnackbar, enqueueSnackbar, dispatch])

    return snacks
}

export default useSnack
