import { Entity } from '../../../legacy/models';
import { EntityConstants as K } from './Entity.constants';

export const fetchOrganizationRequest = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_ORGANIZATION_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const setOrganizationState = (entities) => {
    return {
        type: K.ACTIONS.SET_ENTITIES_STATE,
        payload: { entities },
    };
};

export const addEntitiesResource = (entities) => {
    return {
        type: K.ACTIONS.ADD_ENTITIES_RESOURCE,
        payload: { entities },
    };
};

export const getEntityInformationRequest = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_ENTITY_INFORMATION_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const changeEntityFlow = (entity_id, callback) => {
    return {
        type: K.ACTIONS.CHANGE_ENTITY_FLOW,
        payload: { entity_id },
        callback,
    };
};

export const setActiveEntity = (entity_id) => {
    return {
        type: K.ACTIONS.SET_ACTIVE_ENTITY,
        payload: { active: entity_id },
    };
};

export const updateEntity = (entity, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY,
        payload: { entity },
        callback,
    };
};
