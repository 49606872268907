/* eslint-disable react/prop-types */
// LIBRARIES
import React from 'react';
import moment from 'moment';
import { DatePicker, Select } from 'antd';

// CONSTANT
import CONSTANTS from '../../../Constants';

// STYLE
import './RangeSelect.scss';

// HELPERS
import { flash } from '../../../components/Flash';
import AukButton from '../../../components/AukButton';
import AukTooltip from '../../../components/AukTooltip';
import translate from '../../../utils/translate';

const { RangePicker } = DatePicker;

const OPTIONS = { RECENT: 'recent', RANGE: 'range' };

export default class RangeSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            period: '7 days',
            range: [null, null],
            radio: OPTIONS.RECENT,
            tempRange: [],
        };

        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeDatetime = this.handleChangeDatetime.bind(this);

        this.setHackValue = this.setHackValue.bind(this);
        this.setSuperficialDates = this.setSuperficialDates.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
    }

    handleChangeDatetime(e) {
        this.setState({ radio: OPTIONS.RANGE, range: e });
    }

    setHackValue(dates) {
        this.setState({ hackValue: dates });
    }

    setSuperficialDates(dates) {
        this.setState({ tempRange: dates });
    }

    onOpenChange(open) {
        if (open) {
            this.setHackValue([]);
            this.setSuperficialDates([]);
        } else {
            this.setHackValue(undefined);
        }
    }

    handleChangeSelect(e) {
        this.setState({
            radio: OPTIONS.RECENT,
            period: e,
        });
    }

    handleChangeRadio(e) {
        this.setState({ radio: e.target.value });
    }

    get filterByDate() {
        const isRange = this.state.radio === OPTIONS.RANGE;
        const { range, hackValue, tempRange } = this.state;

        const disabledDate = (current) => {
            if (!tempRange || tempRange.length === 0) {
                return false;
            }
            const tooLate = tempRange[0] && current.diff(tempRange[0], 'days') > 31;
            const tooEarly = tempRange[1] && tempRange[1].diff(current, 'days') > 31;
            return tooEarly || tooLate;
        };

        return (
            <>
                <div className="mb-2">
                    <RadioInput
                        checked={isRange}
                        value={OPTIONS.RANGE}
                        onChange={this.handleChangeRadio}
                        label={translate('filterbydaterange')}
                    />
                </div>
                <div>
                    <RangePicker
                        disabled={!isRange}
                        value={hackValue || range}
                        onChange={this.handleChangeDatetime}
                        format="YYYY/MM/DD"
                        onCalendarChange={this.setSuperficialDates}
                        disabledDate={disabledDate}
                        onOpenChange={this.onOpenChange}
                        allowClear={false}
                    />
                </div>
            </>
        );
    }

    get filterByRecent() {
        const isRecent = this.state.radio === OPTIONS.RECENT;
        const periods = CONSTANTS.PERIODS.filter((p) => {
            const { size, unit } = p;
            return (
                moment.duration(size, unit).as('hours') >= 24 &&
        moment.duration(size, unit).as('months') <= 1
            );
        });

        const options = periods.map((p) => {
            let unit = p.unit;

            if (p.size === 1 && p.unit === 'days') unit = 'day';

            if (p.size === 1 && p.unit === 'months') unit = 'months';

            if (p.size === 1 && p.unit === 'hours') unit = 'hour';

            if (p.size === 1 && p.unit === 'minutes') unit = 'minute';

            return {
                value: `${p.size} ${p.unit}`,
                label: (
                    <>
                        {translate('Last')} {p.size}{' '}
                        <p
                            style={{
                                display: 'inline',
                                margin: 0,
                                textTransform: 'lowercase',
                            }}
                        >
                            {translate(unit)}
                        </p>
                    </>
                ),
            };
        });

        return (
            <>
                <div className="mb-2">
                    <RadioInput
                        checked={isRecent}
                        value={OPTIONS.RECENT}
                        onChange={this.handleChangeRadio}
                        label={translate('filterbymostrecent')}
                    />
                </div>
                <div>
                    <Select
                        options={options}
                        onChange={(e) => this.handleChangeSelect(e)}
                        value={this.state.period}
                        disabled={!isRecent}
                    />
                </div>
            </>
        );
    }

    get date_range() {
        const {
            state: { radio },
        } = this;
        let upper, lower;

        if (radio === OPTIONS.RANGE) {
            upper = this.state.range[1].endOf('day');
            lower = this.state.range[0].startOf('day');
        } else {
            const [size, unit] = this.state.period.split(' ');
            upper = new moment();
            lower = new moment().subtract(size, unit).startOf('day');
        }

        return [lower, upper];
    }

    get isValidRange() {
        const {
            state: { radio },
        } = this;

        if (radio !== OPTIONS.RANGE) {
            return true;
        }

        return this.state.range.filter((d) => d).length;
    }

    render() {
        return (
            <>
                <div className="date-select__options">
                    <div className="date-select__option">{this.filterByDate}</div>
                    <div className="date-select__option">{this.filterByRecent}</div>
                    <div className="date-select__load">
                        <AukTooltip.Help title={translate('load')}>
                            <AukButton.Blue
                                className="auk-button--round"
                                onClick={() => {
                                    if (!this.isValidRange)
                                        return flash({
                                            message: 'Date range cannot be empty',
                                            status: 'warning',
                                        });
                                    this.props.handleSubmit(this.date_range);
                                }}
                            >
                                <i className="fas fa-play-circle" />
                            </AukButton.Blue>
                        </AukTooltip.Help>
                    </div>
                </div>
            </>
        );
    }
}

const RadioInput = (props) => {
    const { label } = props;
    return (
        <>
            <input type="radio" {...props} />
            <span className="ml-2">{label}</span>
        </>
    );
};
