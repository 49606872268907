import { Group } from '../../../legacy/models';


export const groupsState = (appState) =>
    appState.group.groups;

export const arrayGroups = (appState) => {
    const groupsMap = groupsState(appState);
    return Object.keys(groupsMap)
        .map((group_id) => groupsMap[+group_id])
        .sort((a, b) => {
            if (a.group_name < b.group_name) return -1;
            if (a.group_name > b.group_name) return 1;
            return 0;
        });
};
