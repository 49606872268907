import { put, call, takeLatest } from 'redux-saga/effects';
import { CorrelationConstants as K } from './Correlations.constants';
import {
    api_createCorrelation,
    api_deleteCorrelation,
    api_getCorrelations,
    // api_updateCorrelation,
} from './Correlations.services';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { Correlation, parseCorrelationArguments } from '../../../legacy/models';
import {
    createCorrelationRequestSuccess,
    deleteCorrelationRequestSuccess,
    fetchCorrelationsRequestSuccess,
    // updateCorrelationRequestSuccess,
} from './Correlations.action';
import { errorFlash } from '../../../legacy/components/Flash';

function* handleFetchCorrelationsRequest(action) {
    try {
        const correlations = yield call(api_getCorrelations, action.payload.entity_id);

        const result = getMapFromArr(
            correlations.map((n) => new Correlation(...parseCorrelationArguments(n))),
            'correlation_id'
        );
        yield put(fetchCorrelationsRequestSuccess(result));
    } catch (e) {
        errorFlash(e);
    }
}

export function* fetchCorrelationsList() {
    yield takeLatest(K.ACTIONS.FETCH_CORRELATIONS_REQUEST, handleFetchCorrelationsRequest);
}

function* handleCreateCorrelationRequest(action) {
    try {
        const { data, entity_id } = action.payload;

        const result = yield call(api_createCorrelation, entity_id, data);
        const correlation = new Correlation(...parseCorrelationArguments(result));

        yield put(createCorrelationRequestSuccess(correlation));

        if (action.callback) {
            action.callback(correlation);
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* createCorrelationRequestSaga() {
    yield takeLatest(K.ACTIONS.CREATE_CORRELATION_REQUEST, handleCreateCorrelationRequest);
}

// function* handleUpdateCorrelationRequest(action) {
//     try {
//         const { data, entity_id, correlation_id } = action.payload;

//         const result = yield call(api_updateCorrelation, entity_id, correlation_id, data);
//         const correlation = new Correlation(...parseCorrelationArguments(result));

//         yield put(updateCorrelationRequestSuccess(correlation));

//         if (action.callback) {
//             action.callback(correlation);
//         }
//     } catch (e) {
//         errorFlash(e);
//     }
// }

// export function* updateCorrelationRequestSaga() {
//     yield takeLatest(K.ACTIONS.UPDATE_CORRELATION_REQUEST, handleUpdateCorrelationRequest);
// }

function* handleDeleteCorrelationRequest(action) {
    try {
        const { correlation_id, entity_id } = action.payload;

        yield call(api_deleteCorrelation, entity_id, correlation_id);
        yield put(deleteCorrelationRequestSuccess(correlation_id));

        if (action.callback) {
            action.callback(correlation_id);
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteCorrelationRequestSaga() {
    yield takeLatest(K.ACTIONS.DELETE_CORRELATION_REQUEST, handleDeleteCorrelationRequest);
}
