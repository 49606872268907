export enum ImpactTypeEnum {
    COST_SAVINGS = "cost_savings",
    REVENUE_GAIN = "revenue_gain"
}

export enum ImpactEnum {
    BASELINE = "Baseline",
    CURRENT = "Current",
    BEST = "Best",
    BENCHMARK_GOAL = "Benchmark / Goal",
    CAPTURED_DELTA = "Captured",
    POTENTIAL_DELTA = "Potential",
    BENCHMARK_DELTA = "Benchmark",
}

export const xAxisDisplaySet = new Set([ImpactEnum.BASELINE, ImpactEnum.CURRENT, ImpactEnum.BEST, ImpactEnum.BENCHMARK_GOAL])

export const IMPACT_COLORS = {
    BASELINE: "#DDE3EA",
    CURRENT: "#C6E7FF",
    BEST: "#C6E7FF",
    BENCHMARK: "#216487",
    DELTA: "#206487",
}
