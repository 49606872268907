/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { debounce, intersection, isEqual, values } from 'lodash';
import { defaultCellRangeRenderer } from 'react-virtualized';
import DashboardArrow from './component/DashboardArrow';
import DashboardWidget from './DashboardWidget';
import DndGridCanvas from '../../Widgets/DndGridCanvas';
import CONSTANTS from '../../Constants';
import { DashboardConstants as K } from '../../../store/old/UI/Dashboard/Dashboard.constants';
import { eventEmitter } from '../../auxStore';
import { flash } from '../../components/Flash';
import { editSummaryTileRequest, editTileRequest } from '../../../store/old/Tiles/Tiles.action';
import { ResetLabelsState } from '../../../store/old/Labels/Labels.action';
import { DashboardContext } from './DashboardContext';
import withContext from '../../Wrappers/HOCs/withContext';
import GridCell from './component/GridCell';
import { dataApiQueryParams } from '../../utils/controls';
import { fetchSummaryTilesData, fetchTilesData } from '../../../store/old/Data/Data.action';
import withStreaming from '../../../lib/hoc/withStreaming';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { startLoading, stopLoading } from '../../../store/old/UI/Loaders/Loader.action';

const gridCell = {
    width: K.CELL_DIM.WIDTH,
    height: K.CELL_DIM.HEIGHT,
    padding: K.CELL_DIM.PADDING,
};

const OVERSCAN_COUNT = 3;

const getDefaultViewport = () => {
    return {
        up: 0,
        down: Math.ceil(window.innerWidth / (gridCell.width)) + OVERSCAN_COUNT,
        left: 0,
        right: Math.ceil(window.innerHeight / (gridCell.height)) + OVERSCAN_COUNT
    }
}

const getOccupiedCells = (w) => {
    const result = [];
    for (let i = 0; i < w.width; i++) {
        for (let j = 0; j < w.height; j++) {
            const x = w.position_x + i;
            const y = w.position_y + j;
            result.push(`${x}-${y}`);
        }
    }

    return result;
};

const getNewPosition = (w, offset) => {
    const unitDx = gridCell.width + gridCell.padding;
    const unitDy = gridCell.height + gridCell.padding;

    if (!offset) return null;

    const dx = Math.round(offset.x / unitDx);
    const dy = Math.round(offset.y / unitDy);

    const original = { position_x: w.position_x, position_y: w.position_y };
    const result = {
        position_x: w.position_x + dx,
        position_y: w.position_y + dy,
    };

    return isEqual(original, result) ? null : result;
};

const getGridData = (widgets) => {
    return values(widgets).reduce((acc, curr) => {
        const { position_x, position_y } = curr;

        const x = position_x.toString();
        const y = position_y.toString();

        if (acc[y]) {
            acc[y][x] = curr;
        } else {
            acc[y] = { ...acc[y], [x]: curr };
        }

        return acc;
    }, {});
};

class DashboardCanvasSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            memo: new Set(),
            gridData: getGridData(props.widgets),
            viewport: getDefaultViewport(),
            lastFetchedWindow: undefined,
        };

        this.refreshHandler = eventEmitter.on(
            CONSTANTS.EVENTS.REFRESH,
            this.refresh
        );

        this.getData = this.getData.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChangeViewport = this.handleChangeViewport.bind(this);
        this.handleCanvasDrop = this.handleCanvasDrop.bind(this);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.widgets !== this.props.widgets) {
            return this.setState({ gridData: getGridData(this.props.widgets) });
        }

        if (prevState.viewport !== this.state.viewport) {
            if (this.debouncedViewportChangeHandler) {
                this.debouncedViewportChangeHandler.cancel();
            }
            this.debouncedViewportChangeHandler = debounce(() => {
                const { getData, getDataQuery } = this;
                const { controls } = this.props;
                const fetch = this.widgetsToFetch;
                this.setMemo(new Set(Array.from(prevState.memo).concat(...fetch)));
                const lastFetchedWindow = controls.streaming
                    ? this.state.lastFetchedWindow
                    : null; // fetch most recent window if streaming
                return getData(fetch, getDataQuery(lastFetchedWindow));
            }, 500);
            this.debouncedViewportChangeHandler();
        }
    };

    componentWillUnmount = () => {
        this.refreshHandler();
    };

    getDataQuery = (customRange) => {
        return Object.assign(
            {},
            dataApiQueryParams(this.props.controls),
            customRange && { date_range: customRange }
        );
    };

    getData = (widgetIds, resRange) => {
        const tileIds = [];
        const summaryTileIds = [];
        const { entity_id } = this.props.entity;

        widgetIds.forEach((widgetId) => {
            const [type, id] = widgetId.split('_');
            type === 't' ? tileIds.push(+id) : summaryTileIds.push(+id);
        });

        this.props.startLoading();
        if (tileIds.length) {
            this.props.getTilesData(entity_id, tileIds, resRange);
        }

        if (summaryTileIds.length) {
            this.props.getSummaryTilesData(entity_id, summaryTileIds, resRange);
        }

        this.props.stopLoading();
    };

    refresh = () => {
        this.props.resetLabelsState();
        const { getDataQuery, getData } = this;
        const query = getDataQuery();
        const widgetIds = this.widgetsInViewport.map((w) => w.widget_id);
        getData(widgetIds, query);
        this.setMemo(new Set(widgetIds));
        this.setState({ lastFetchedWindow: query.date_range });
    };

    handleChangeViewport = (viewport) => {
        this.setState({ viewport });
    };

    setMemo = (memo) => {
        this.setState({ memo });
    };

    updateWidget = (w, params) => {
        const { updateTile, updateSummaryTile } = this.props;
        const [type, id] = w.widget_id.split('_');
        const isTile = type === 't';
        const handler = isTile ? updateTile : updateSummaryTile;

        let reqBody = { ...params };
        isTile ? (reqBody.tile_id = id) : (reqBody.summary_tile_id = id);

        return handler(reqBody);
    };

    handleCanvasDrop = (item, monitor) => {
        const moved = getNewPosition(
            item.data,
            monitor.getDifferenceFromInitialOffset()
        );

        if (!moved) return;

        const incoming = getOccupiedCells({ ...item.data, ...moved });
        const isMoveAllowed = !intersection(incoming, this.occupiedCells).length;

        if (!isMoveAllowed) {
            return flash({
                message: 'Cell(s) already occupied',
                status: 'warning',
            });
        }

        this.updateWidget(item.data, moved);
    };

    cellRender = (data, coordinates) => {
        const { row, col } = coordinates;
        if (data) {
            return <DashboardWidget data={data} gridCell={gridCell} />;
        }

        const { editing, handleClickGridCell } = this.props.context;

        return (
            <GridCell
                editing={editing}
                handleClick={() => handleClickGridCell(col, row)}
            />
        );

    };

    cellRangeRenderer = (d) => {
        const children = defaultCellRangeRenderer(d);
        children.push(
            <div key={1}>
                <DashboardArrow />
            </div>
        );
        return children;
    };

    get widgetsInViewport() {
        const { viewport, gridData } = this.state;
        const result = [];
        for (let i = viewport.up; i <= viewport.down; i++) {
            for (let j = viewport.left; j <= viewport.right; j++) {
                const y = i.toString();
                const x = j.toString();
                if (gridData[y] && gridData[y][x]) {
                    result.push(gridData[y][x]);
                }
            }
        }

        return result;
    }

    get widgetsToFetch() {
        const { memo } = this.state;
        return this.widgetsInViewport
            .filter((w) => !memo.has(w.widget_id))
            .map((w) => w.widget_id);
    }

    get occupiedCells() {
        return this.widgetsInViewport.reduce(
            (acc, curr) => acc.concat(...getOccupiedCells(curr)),
            []
        );
    }

    render() {
        const {
            state: { gridData },
            handleChangeViewport,
            cellRender,
            cellRangeRenderer,
            handleCanvasDrop,
        } = this;

        return (
            <DndGridCanvas
                data={gridData}
                onSectionRendered={handleChangeViewport}
                cellRender={cellRender}
                gridCell={gridCell}
                handleDrop={handleCanvasDrop}
                cellRangeRenderer={cellRangeRenderer}
            />
        );
    }
}

const mapStateToProps = (rootState) => ({
    entity: currentEntitySelector(rootState)
});

const mapDispatchToProps = (dispatch) => ({
    getSummaryTilesData: (entityId, ids, query, callback) => dispatch(fetchSummaryTilesData(entityId, ids, query, callback)),
    getTilesData: (entityId, ids, query, callback) => dispatch(fetchTilesData(entityId, ids, query, callback)),
    updateSummaryTile: (data, callback) => dispatch(editSummaryTileRequest(data, callback)),
    updateTile: (data, callback) => dispatch(editTileRequest(data, callback)),
    resetLabelsState: () => dispatch(ResetLabelsState()),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
});

const DashboardCanvas = connect(mapStateToProps, mapDispatchToProps)(withStreaming(withContext(DashboardCanvasSimple, DashboardContext)));

export default DashboardCanvas;
