/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

// ACTION
import { VerifyEmail } from '../../../store/old/Authentication/Authentication.action';

// CONSTANT
import CONSTANTS from '../../Constants';

// SERVICE
import { getQueryParams } from '../../utils/service';

// COMPONENTS
import {
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
} from '../../components/Panel';
import AukButton from '../../components/AukButton';

class EmailVerification extends Component {
    state = {
        verified: false,
        successTimeout: 3,
    };

    componentWillUnmount() {
        clearInterval(this.redirectTimer);
    }

    get verificationParams() {
        const query = window.location.search;
        const queryObj = getQueryParams(query);

        return {
            email: queryObj.from,
            token: queryObj.token,
            user_id: Number(queryObj.user_id),
        };
    }

    verifyEmail() {
        this.props.verifyEmail(this.verificationParams, () => {
            this.setState({ verified: true });

            this.redirectTimer = setInterval(() => {
                this.setState((prev) => {
                    const newSuccessTimeout = prev.successTimeout - 1;
                    return {
                        successTimeout: newSuccessTimeout,
                    };
                });
            }, 1000);
        });
    }

    get waitPrompt() {
        const { verified, successTimeout } = this.state;
        let message = '';
        if (verified) {
            message = `Success! Redirecting in ${successTimeout} seconds`;
        } else {
            message = 'Please wait...';
        }

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-5 col-md-offset-5 align-self-end">
                            <Panel>
                                <PanelHeader>Verify Email</PanelHeader>
                                <PanelBody>{message}</PanelBody>
                                <PanelFooter />
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get verifyPrompt() {
        const { email } = this.verificationParams;

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-5 col-md-offset-5 align-self-end">
                            <Panel>
                                <PanelHeader>Verify Email</PanelHeader>
                                <PanelBody className="d-flex justify-content-center">
                                    <AukButton.Blue onClick={this.verifyEmail.bind(this)}>
                                        Verify {email}
                                    </AukButton.Blue>
                                </PanelBody>
                                <PanelFooter />
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { verified, successTimeout } = this.state;

        const loginRedirect = (
            <Navigate
                to={CONSTANTS.URLS.LOGIN}
                state={{ from: CONSTANTS.URLS.DASHBOARD }}
            />
        );

        return !verified
            ? this.verifyPrompt
            : verified && successTimeout === 0
                ? loginRedirect
                : this.waitPrompt;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyEmail: (payload, callback) =>
            dispatch(VerifyEmail(payload, callback)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(EmailVerification);
