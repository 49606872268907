import { pickBy, values } from 'lodash';
import { updateStoreRedux } from '../../../legacy/utils/redux';
import { BlocksConstants as K } from './Blocks.constants';
import { toMappedArrays } from '../../../legacy/utils/helpers';

const initialReducer = () => ({
    blocks: {},
    blocks_issues_exclusions: {},
    blocks_bn: new Set(),
    pc_directory: {},
});

export const blocksReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_BLOCKS_STATE) {
        let newState = { ...action.payload };

        if (action.payload.blocks) {
            // generate a new directory everytime blocks state is updated
            newState.pc_directory = toMappedArrays(
                values(action.payload.blocks),
                (b) => b.parent_block_id,
                (b) => b.block_id
            );
        }

        return updateStoreRedux(state, newState);
    }

    if (action.type === K.ACTIONS.ADD_BLOCKS_RESOURCE) {
        const blocks = { ...state.blocks, ...action.payload.blocks };
        const pc_directory = toMappedArrays(
            values(blocks),
            (b) => b.parent_block_id,
            (b) => b.block_id
        );

        return updateStoreRedux(state, {
            blocks,
            pc_directory,
        });
    }

    if (action.type === K.ACTIONS.REMOVE_BLOCKS_RESOURCE) {
        const deleteSet = new Set(action.payload.block_ids);
        const deletedState = pickBy(
            { ...state.blocks },
            ({ block_id }) => !deleteSet.has(block_id)
        );

        const pc_directory = toMappedArrays(
            values(deletedState),
            (b) => b.parent_block_id,
            (b) => b.block_id
        );

        return updateStoreRedux(state, {
            blocks: deletedState,
            pc_directory,
        });
    }

    if (action.type === K.ACTIONS.SET_BLOCK_BOTTLENECK) {
        return updateStoreRedux(state, {
            blocks_bn: new Set([
                ...Array.from(state.blocks_bn),
                ...action.payload.blocks_bn,
            ]),
        });
    }

    if (action.type === K.ACTIONS.ADD_BLOCKS_ISSUES_RESOURCE) {
        return updateStoreRedux(state, {
            blocks_issues_exclusions: {
                ...state.blocks_issues_exclusions,
                ...action.payload.blocks_issues_exclusions,
            },
        });
    }

    if (action.type === K.ACTIONS.RESET_BLOCKS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
