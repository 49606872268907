import React from 'react';
import classNames from 'classnames';
import CONSTANTS from '../../../Constants';
import './BlockOperation.scss'

const BlockOperation = (props) => {
    const { operation, bn_mode } = props.block;
    return (
        <div className={classNames(['block-operation', props.className])}>
            {operation !== 'or' && (
                <BottleneckIndicator value={bn_mode || CONSTANTS.BLOCKS.BN_MODE_DEFAULT} />
            )}
            <div className="block-operation__label">{operation}</div>
        </div>
    );
};

const BottleneckIndicator = ({ value }) => {
    return (
        <div
            className="blocks-tree__node__badge"
            style={{ background: CONSTANTS.BLOCKS.BN_MODE[value].color }}
        >
            <i>{value.substring(0, 1)}</i>
        </div>
    );
};

export default BlockOperation;