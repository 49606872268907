import { updateStoreRedux } from '../../../legacy/utils/redux';
import { UserConstants as K } from './User.constants';

const initialReducer = {
    list: {},
    error: {},
};

export const usersReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.FETCH_USERS_SYSTEM_SUCCESS) {
        return updateStoreRedux(state, {
            list: action.payload.list,
        });
    }

    if (
        action.type === K.ACTIONS.FETCH_USERS_SYSTEM_FAILURE ||
        action.type === K.ACTIONS.DELETE_USER_FAILURE ||
        action.type === K.ACTIONS.CHANGE_USER_ROLE_FAILURE
    ) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    return updateStoreRedux(state, {});
};
