import { call, put, takeLatest } from 'redux-saga/effects';

// ACTION
import {
    addGroupsResource,
    getAllGroupsRequest,
    setGroupsResource,
    removeGroupsResource,
} from './Group.action';

// MODEL
import { Group, parseGroupArguments } from '../../../legacy/models';

// User SERVICES
import {
    api_getUserGroups,
    api_createUserGroup,
    api_updateUserGroup,
    api_deleteUserGroup,
} from './Group.services';

// HELPERS
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

// CONSTANT
import { GroupConstants as K } from './Group.constant';
import { errorFlash } from '../../../legacy/components/Flash';
import { getMapFromArr } from '../../../legacy/utils/helpers';

// GET ALL GROUP
function* handleGetAllGroups(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const response = yield call(api_getUserGroups, entity_id);
        const groups = getMapFromArr(
            response.map((g) => new Group(...parseGroupArguments(g))),
            'group_id'
        );

        yield put(setGroupsResource(groups));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAllGroupsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_GROUPS_REQUEST,
        handleGetAllGroups
    );
}

// DELETE GROUP
function* handleDeleteGroup(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { group_id } = action.payload;

        const response = yield call(
            api_deleteUserGroup,
            entity_id,
            group_id
        );

        yield put(removeGroupsResource([group_id]));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteGroupSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_GROUP_REQUEST,
        handleDeleteGroup
    );
}

// UPDATE GROUP
function* handleUpdateGroup(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield call(
            api_updateUserGroup,
            entity_id,
            action.payload.group.group_id,
            action.payload.group
        );

        const group = new Group(...parseGroupArguments(response));

        yield put(addGroupsResource({ [group.group_id]: group }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateGroupSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_GROUP_REQUEST,
        handleUpdateGroup
    );
}

// CREATE GROUP
function* handleCreateGroup(action) {
    try {
        const response = yield call(
            api_createUserGroup,
            action.payload.group.entity_id,
            action.payload.group
        );

        yield put(getAllGroupsRequest());

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createGroupSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_GROUP_REQUEST,
        handleCreateGroup
    );
}
