import React from "react"
import { useAppSelector } from "../../../../../store/hooks"
import { selectObjectiveViewMode } from "../../../../../store/selectors/goals.selectors"
import ObjectiveEndGoal from "../ObjectiveEndGoal/ObjectiveEndGoal"
import ObjectiveProgressivePlan from "../ObjectiveProgressivePlan/ObjectiveProgressivePlan"
import ProgressivePlanEdit from "../ObjectiveProgressivePlan/ProgressivePlanEdit/ProgressivePlanEdit"

const ObjectivePageContents = () => {
    const viewMode = useAppSelector(selectObjectiveViewMode)
    if (viewMode === "endGoalEdit") {
        return <ObjectiveEndGoal />
    }

    if (viewMode === "planEdit") {
        return <ProgressivePlanEdit />
    }

    return <ObjectiveProgressivePlan />
}

export default ObjectivePageContents
