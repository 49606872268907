import { omit, pickBy } from "lodash";

// Roll up query params into query string
export const queryString = (query) =>
    query
        ? '?' +
      Object.keys(query)
          .map((param) => param + '=' + query[param])
          .join('&')
        : '';

export const queryStringData = (query) => {
    return queryString({
        ...query,
        date_range: JSON.stringify(query.date_range),
    });
};

// split a query string into its params
export const getQueryParams = (query) => {
    if (!query) return;

    const arr = (query).split(/[&\\?]/);
    const params = {};

    arr.forEach((param) => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    });

    return omit(pickBy(params, val => !!val), "");
};
