/* eslint-disable react/prop-types */
import React from 'react';
import FormCustomField from './FormCustomField';
import Select from './Select';

const FormSelect = (props) => {
    const {
        label,
        handleChange,
        value,
        placeholder,
        options,
        isClearable,
        className,
        labelCol,
        labelClassed,
        selectCol,
        selectStyles,
        isDisabled,
        disableSelectClass,
        customSelectClass,
        isMulti,
        hideSelectedOptions,
        getOptionLabel,
        getOptionValue
    } = props;

    return (
        <FormCustomField className={className}>
            { label && 
        <label className={`col-${labelCol || 4} col-form-label d-flex justify-content-end ${labelClassed || ''}`}>
            {label}
        </label> 
            }
            <div className={disableSelectClass ? customSelectClass : `col-${selectCol || 8} `}>
                <Select
                    menuPortalTarget={document.body}
                    className="font-weight-normal"
                    onChange={handleChange}
                    styles={selectStyles}
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    hideSelectedOptions={hideSelectedOptions}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                />
            </div>
        </FormCustomField>
    );
}

export default FormSelect;