import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import BlockRow from "./BlockRow"
import BlockRowHeader from "./BlockRowHeader"
import { useParams } from "react-router-dom"
import useBlock from "../../../hooks/useBlock"
import { BlockContent, BlockContentList, BlockContentMain } from "./BlockContent"
import BlockModel from "../../../api/models/block.model"
import { eventEmitter } from "../../../legacy/auxStore"
import CONSTANTS from "../../../legacy/Constants"
import withStreaming, { ControlsRenderProps } from "../../../lib/hoc/withStreaming"
import { generateUrlQuery } from "../../../legacy/utils/url"
import { BlockPageContext } from "../BlockPageContext"
import { useAppSelector } from "../../../store/hooks"
import OutputChart from "./OutputChart"
import { values } from "lodash"
import OutputDescriptionPopover from "../../../legacy/routes/Blocks/components/OutputDescriptionPopover"
import OutputText from "../../../legacy/routes/Blocks/components/OutputText"
import LabellerDrawer, { AssetProductionLabeller } from "../../../legacy/routes/Labels"
import { BlockProductionLabelCreate, BlockProductionLabelView } from "../../../legacy/routes/Labels/BlockProductionLabeller"
import { cancelBrushSelection, moveBrushSelection } from "../../../legacy/utils/charts"
import * as d3 from "d3"
import { Label } from "../../../legacy/models"
import { withSkus } from "../../../legacy/Wrappers/HOCs/withSkus"
import useBlocks from "../../../hooks/useBlocks"

const OutputTimeSeriesTab = (props: Props) => {
    const { getOutputData, getOeeData } = useContext(BlockPageContext)
    const { blockId } = useParams()
    const { blocks } = useBlocks()
    const assets = useAppSelector(appState => appState.assets.assets)
    const block = useBlock(+(blockId as unknown as number))
    const blocksOutput = useAppSelector(appState => appState.ui.block.output)
    const blocksSkuLabels = useAppSelector(appState => appState.ui.block.skuLabels)

    const [drawerVisible, setDrawerVisible] = useState(false)
    const [brushedData, setBrushedData] = useState<any[]>([])
    const [brushedRange, setBrushedRange] = useState<Date[]>([])
    const [labelSelection, setLabelSelection] = useState<Label | undefined>(undefined)
    const [chartSelection, setChartSelection] = useState<number | undefined>(undefined)

    const getBlockOutput = useCallback((id: number) => {
        return blocksOutput[id] ?? []
    }, [blocksOutput])

    const getBlockLabels = useCallback((id: number) => {
        if (blockId && id === +blockId) {
            return values(blocksSkuLabels).reduce((acc, blockLabels) => acc.concat(...blockLabels), [])
        }
        return blocksSkuLabels[id] ?? []
    }, [blocksSkuLabels])


    useEffect(() => {
        const refreshHandler = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => {
            getOutputData(props.controls)
            getOeeData(props.controls)
        })

        return () => refreshHandler()
    }, [generateUrlQuery(props.controls), props.controls.sku_oee])

    const xDomain = useMemo(() => [new Date(props.window.lower), new Date(props.window.upper)], [props.window])

    const dataMap = useMemo(() => {
        const blocks = [block].concat(...block.children ?? [])
        return blocks.reduce((acc, curr) => {
            return acc.set(curr.blockId, {
                labels: getBlockLabels(curr.blockId),
                output: getBlockOutput(curr.blockId)
            })
        }, new Map)
    }, [blocksOutput, blocksSkuLabels])

    const onBrushCancel = () => {
        setChartSelection(undefined)
        setDrawerVisible(false)
        setBrushedData([])
        setLabelSelection(undefined)
        const brushes = d3.selectAll(".brush")
        cancelBrushSelection(brushes)
    }

    const onSubmit = () => {
        eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH, {})
        onBrushCancel()
    }


    const labellerContent = useMemo(() => {
        if (!chartSelection) return null

        // main chart, label selected
        if (brushedRange && labelSelection && block.blockId === chartSelection) {
            return <BlockProductionLabelView range={brushedRange} brushedData={brushedData} selection={labelSelection}/>
        }

        // main chart, brushed
        if (block.blockId === chartSelection) {
            return <BlockProductionLabelCreate 
                range={brushedRange} 
                brushedData={brushedData} 
                block={block} 
                onSubmit={onSubmit}
            />
        }

        const assetBlocks = new Set(block.children?.filter(child => child.assetId).map(b => b.blockId))

        // child block, label selected
        if (brushedRange && labelSelection && !assetBlocks.has(chartSelection as number)) {
            return <BlockProductionLabelView range={brushedRange} brushedData={brushedData} selection={labelSelection}/>
        }

        // child block, brushed
        if (!assetBlocks.has(chartSelection as number)) {
            return <BlockProductionLabelCreate 
                range={brushedRange} 
                brushedData={brushedData} 
                block={blocks[chartSelection as number]} 
                onSubmit={onSubmit}
            />
        }

        // child asset, label selected
        const assetBlock = blocks[chartSelection as number] as BlockModel
        const asset = assets[assetBlock.assetId as number]
        if (brushedRange && labelSelection && asset.asset_id === labelSelection.asset_id) {
            return <AssetProductionLabeller
                selection={labelSelection}
                range={brushedRange}
                brushedData={brushedData}
                summaryMode={"total"}
                asset={asset}
                showDataDelete={false}
                onSuccess={onSubmit}
            />
        }
        
        // child asset, brushed
        return <AssetProductionLabeller
            range={brushedRange}
            brushedData={brushedData}
            summaryMode={"total"}
            asset={asset}
            showDataDelete={false}
            onSuccess={onSubmit}
        />
        
    }, [blocks, block, assets, brushedRange, brushedData, labelSelection, chartSelection])

    return <BlockContent>
        <LabellerDrawer
            key={blockId}
            onClose={onBrushCancel}
            visible={drawerVisible}
            withHandler={false}
        >
            {labellerContent}
        </LabellerDrawer>
        <BlockContentMain>
            <BlockRow 
                block={block} 
                header={<BlockRowHeader block={block} addOnAfter={<OutputDescriptionPopover operation={block.operation}/>} />}
                chart={
                    <OutputChart
                        xDomain={xDomain}
                        height={"140px"} 
                        data={dataMap.get(block.blockId)}
                        labelsEditable={!!block.assetId}
                        onBrushEnd={(bounds: any, scaled: any, brushElement: any, data: any) => {
                            setLabelSelection(undefined)
                            setChartSelection(block.blockId)
                            setDrawerVisible(true)
                            setBrushedRange(bounds)
                            setBrushedData(data)
                            const brushes = d3.selectAll(".brush")
                            moveBrushSelection(brushes, scaled)
                        }}
                        onBrushCancel={onBrushCancel}
                        onLabelClick={(d: Label, brushedRange: Date[], brushedData: any) => {
                            setChartSelection(block.blockId)
                            setDrawerVisible(true)
                            setLabelSelection(d)
                            setBrushedRange(brushedRange)
                            setBrushedData(brushedData)
                        }}
                    />
                }
            />
        </BlockContentMain>
        <hr/>
        <BlockContentList>
            {
                (block.children as BlockModel[]).map(b => <BlockRow 
                    key={b.blockId}
                    block={b} 
                    header={
                        <BlockRowHeader 
                            block={b} 
                            withNavigate={true} 
                            addOnAfter={<OutputText value={dataMap.get(b.blockId)?.output.total} isShowOutput/>}
                        />
                    }
                    chart={
                        <OutputChart
                            xDomain={xDomain}
                            height={"100px"} 
                            data={dataMap.get(b.blockId)}
                            labelsEditable={!!b.assetId}
                            onBrushEnd={(bounds: any, scaled: any, brushElement: any, data: any) => {
                                if (!b.assetId) {
                                    setLabelSelection(undefined)
                                }
                                setChartSelection(b.blockId)
                                setDrawerVisible(true)
                                setBrushedRange(bounds)
                                setBrushedData(data)
                                const brushes = d3.selectAll(".brush").filter(function () {
                                    return this !== brushElement.node()
                                })
                                cancelBrushSelection(brushes)
                            }}
                            onBrushCancel={onBrushCancel}
                            onLabelClick={(d: Label, brushedRange: Date[], brushedData: any) => {
                                setChartSelection(b.blockId)
                                setDrawerVisible(true)
                                setLabelSelection(d)
                                setBrushedRange(brushedRange)
                                setBrushedData(brushedData)
                            }}
                        />
                    }
                />)
            }
        </BlockContentList>
    </BlockContent>
}

interface Props extends ControlsRenderProps {}

export default withSkus(withStreaming(OutputTimeSeriesTab))