/* eslint-disable react/prop-types */
import { FilterOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';

import './AukFilterSelect.scss';

const AukFilterSelect = (props) => {
    const { className } = props;
    return (
        <Select
            {...props}
            suffixIcon={<FilterOutlined />}
            className={classNames({
                'auk-button': true,
                'auk-select-filter': true,
                'auk-select-filter-single': true,
                [className]: !!className,
            })}
        />
    );
};

export default AukFilterSelect;
