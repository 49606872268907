import * as d3 from 'd3';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { BaseChartSimple } from './BaseChart';
import {
    genColor,
    clearCanvas,
    getTimeIntervalBandwidth,
    getTimeSeriesBrushXSelection,
} from '../../utils/charts';

import './Charts_V1.scss';

export const getStackColumnBandheight = (d, y) => {
    const height = y(d[0]) - y(d[1]);
    return height >= 0 ? height : 0;
};

export const getStackColumnLabels = (d, x, y, yAccessor) => {
    const minHeightForLabels = 16;
    const minWidthForLabels = 50;

    const { key, data } = d;

    const percent = yAccessor(data, key);
    const height = getStackColumnBandheight(d, y);
    const width = getTimeIntervalBandwidth(d.data, x);

    if (
        percent <= 0.0 ||
    height < minHeightForLabels ||
    width < minWidthForLabels
    )
        return '';

    return `${percent.toFixed(1)}%`;
};

const keyAccessor = (x, xAccessor) => {
    return function (d, i) {
        const xPos = x(xAccessor(d.data));
        return `${i}${d.key}${xPos}${d[0]}${d[1]}`;
    };
};

class StackColumnChartSimple extends BaseChartSimple {
    constructor(props) {
        super(props);

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                getBandwidth,
                getBandheight,
                useDataLabels,
                getDataLabels,
                xAccessor,
                yAccessor,
                keys,
                colorAccessor,
            },
            x,
            y,
            h,
            custom,
        } = this;

        const generated = d3.stack().keys(keys).value(yAccessor)(data);

        const stack = generated
            .map((dataFrame) => {
                const k = dataFrame.key;
                dataFrame.forEach((point) => (point.key = k));
                return dataFrame;
            })
            .reduce((acc, curr) => acc.concat(curr), [])
            .reverse();

        const t = transition || null;

        /* bars -- canvas */
        const selection = custom
            .selectAll('.bar')
            .data(stack, keyAccessor(x, xAccessor));

        // enter
        selection
            .enter()
            .append('custom')
            .attr('class', 'bar')
            .attr('x', (d) => x(xAccessor(d.data)))
            .attr('y', (d) => y(d[1]))
            .attr('width', (d) => getBandwidth(d.data, x))
            .attr('height', (d) => getBandheight(d, y))
            .attr('fill', (d) => colorAccessor(d));

        // update
        selection
            .transition(t)
            .duration(duration)
            .attr('x', (d) => x(xAccessor(d.data)))
            .attr('y', (d) => y(d[1]))
            .attr('width', (d) => getBandwidth(d.data, x))
            .attr('height', (d) => getBandheight(d, y));

        // exit
        selection.exit().remove();

        /* bars -- hidden canvas */
        const hiddenSelection = custom.selectAll('.hidden-bar').data(data);

        // enter
        hiddenSelection
            .enter()
            .append('custom')
            .attr('class', 'hidden-bar')
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', 0)
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', h);

        // update
        hiddenSelection
            .attr('x', (d) => x(xAccessor(d)))
            .attr('y', 0)
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', h);

        // exit
        hiddenSelection.exit().remove();

        /* bar labels */
        const textSelection = custom
            .selectAll('.text')
            .data(stack, keyAccessor(x, xAccessor));

        if (useDataLabels) {
            // enter
            textSelection
                .enter()
                .append('custom')
                .attr('class', 'text')
                .attr('text', (d) => getDataLabels(d, x, y, yAccessor))
                .attr('x', (d) => x(xAccessor(d.data)) + getBandwidth(d.data, x) / 2)
                .attr('y', (d) => y(d[1]) + getBandheight(d, y) / 2 + 4);

            // update
            textSelection
                .transition(t)
                .duration(duration)
                .attr('text', (d) => getDataLabels(d, x, y, yAccessor))
                .attr('x', (d) => x(xAccessor(d.data)) + getBandwidth(d.data, x) / 2)
                .attr('y', (d) => y(d[1]) + getBandheight(d, y) / 2 + 4);
        }

        // exit
        textSelection.exit().remove();
    }

    redrawCanvas() {
        const { canvas, custom, w, h } = this;
        const { useDataLabels } = this.props;

        // clear canvas
        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        const bars = custom.selectAll('.bar');
        const labels = custom.selectAll('.text');
        const labelNodes = labels.nodes();

        bars.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;

            context.beginPath();
            context.fillStyle = node.attr('fill');
            context.rect(
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width'),
                +node.attr('height')
            );
            context.fill();
            context.closePath();

            if (useDataLabels) {
                const labelNode = d3.select(labelNodes[i]);

                if (labelNode.empty()) return;

                context.beginPath();
                context.font = `italic 10px sans-serif`;
                context.fillStyle = '#222';
                context.textAlign = 'center';
                context.fillText(
                    labelNode.attr('text'),
                    labelNode.attr('x'),
                    labelNode.attr('y')
                );
                context.closePath();
            }
        });
    }

    redrawHidden() {
        const { hidden, custom, w, h, colorToNode } = this;

        // clear hidden canvas
        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const hiddenBars = custom.selectAll('.hidden-bar');
        hiddenBars.each(function (d, i) {
            var node = d3.select(this);

            if (node.empty()) return;
            hiddenContext.beginPath();
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.fillStyle = color;
            hiddenContext.rect(
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width'),
                +node.attr('height')
            );

            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }
}

export class StackColumnChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <StackColumnChartSimple
                        {...this.props}
                        width={width}
                        height={height}
                    />
                )}
            </AutoSizer>
        );
    }
}

StackColumnChart.defaultProps = {
    data: [],
    keys: [],
    xDomain: [],
    yDomain: [0, 100],
    hideYAxis: true,
    getBandwidth: getTimeIntervalBandwidth,
    getBandheight: getStackColumnBandheight,
    useDataLabels: true,
    getDataLabels: (d) => d,
    xAccessor: (d) => d.time,
    yAccessor: (d, key) => d[key],
    colorAccessor: () => 'steelblue',
    useBrush: true,
    snapBrush: getTimeSeriesBrushXSelection,
    htmlTooltip: (d) => d,
    useTooltip: true,
};

// example OEE chart

/* 
  <div style={{ height: 180 }}>
    <StackColumnChart
      xScale: d3.scaleTime(),
      yScale: d3.scaleLinear(),
      xDomain={[startDate, endDate]}
      yDomain={[0, 100]}
      keys={keys}
      data={oeeChartData}
      getBandwidth={getTimeIntervalBandwidth}
      getDataLabels={getStackColumnLabels}
      htmlTooltip={OEEChartTooltip}
      colorAccessor={(d) => CONSTANTS.CAT[d.key].color}
      xTickFormat={multiFormat}
    />
  </div> 
*/
