import moment from 'moment';
import { UtilizationConstants as K } from './Utilization.constants';
import { store } from '../../..';

export const utilizationState = (appState) => {
    return appState.ui.utilization;
};

export const utilizationResolutions = (date_range) => {
    const [from, to] = date_range;
    const rangeDurationHours = moment.duration(to.diff(from)).as('hours');

    return K.RESOLUTIONS.map((res) => {
        const { min, max } = res;
        const disabled = !(rangeDurationHours >= min && rangeDurationHours <= max);
        return { ...res, disabled };
    });
};

export const utilizationQueryString = (utilizationControls, entity_id) => {
    const {
        resolution: { res_x, res_period },
        date_range: [from, to],
    } = utilizationControls;
    
    return (
        '?' +
    `from=${from.toISOString()}` +
    `&to=${to.toISOString()}` +
    `&res_x=${res_x}` +
    `&res_period=${res_period}` +
    `&entity_id=${entity_id}`
    );
};
