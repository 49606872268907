import { Shift, Downtime } from '../../../legacy/models';
import { SchedulesConstants as K } from './Schedules.constants';

export const fetchSchedules = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_SCHEDULES_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const setSchedulesState = (shifts = {}, downtimes = {}, initialized = true) => {
    return {
        type: K.ACTIONS.SET_SCHEDULES_STATE,
        payload: { shifts, downtimes, initialized },
    };
};

export const fetchShifts = (callback) => {
    return {
        type: K.ACTIONS.GET_SHIFTS_REQUEST,
        callback,
    };
};

export const setShiftsResource = (shifts) => {
    return {
        type: K.ACTIONS.SET_SHIFTS_RESOURCE,
        payload: { shifts },
    };
};

export const addShiftsResource = (shifts) => {
    return {
        type: K.ACTIONS.ADD_SHIFTS_RESOURCE,
        payload: { shifts },
    };
};

export const removeShiftsResource = (shiftIds) => {
    return {
        type: K.ACTIONS.REMOVE_SHIFTS_RESOURCE,
        payload: { shiftIds },
    };
};

export const createShift = (shift, callback) => {
    return {
        type: K.ACTIONS.CREATE_SHIFT_REQUEST,
        payload: { shift },
        callback,
    };
};

export const updateShift = (shift, callback) => {
    return {
        type: K.ACTIONS.UPDATE_SHIFT_REQUEST,
        payload: { shift },
        callback,
    };
};

export const stopRecurringShift = (shift_id, callback) => {
    return {
        type: K.ACTIONS.STOP_SHIFT_REQUEST,
        payload: { shift_id },
        callback,
    };
};

export const deleteShifts = (shifts, callback) => {
    return {
        type: K.ACTIONS.DELETE_SHIFTS_REQUEST,
        payload: { shifts },
        callback,
    };
};

export const fetchDowntimes = (callback) => {
    return {
        type: K.ACTIONS.GET_DOWNTIMES_REQUEST,
        callback,
    };
};

export const setDowntimesResource = (downtimes) => {
    return {
        type: K.ACTIONS.SET_DOWNTIMES_RESOURCE,
        payload: { downtimes },
    };
};

export const addDowntimesResource = (downtimes) => {
    return {
        type: K.ACTIONS.ADD_DOWNTIMES_RESOURCE,
        payload: { downtimes },
    };
};

export const removeDowntimesResource = (downtimeIds) => {
    return {
        type: K.ACTIONS.REMOVE_DOWNTIMES_RESOURCE,
        payload: { downtimeIds },
    };
};

export const createDowntime = (downtime, callback) => {
    return {
        type: K.ACTIONS.CREATE_DOWNTIME_REQUEST,
        payload: { downtime },
        callback,
    };
};

export const updateDowntime = (downtime, callback) => {
    return {
        type: K.ACTIONS.UPDATE_DOWNTIME_REQUEST,
        payload: { downtime },
        callback,
    };
};

export const stopRecurringDowntime = (downtime_id, callback) => {
    return {
        type: K.ACTIONS.STOP_DOWNTIME_REQUEST,
        payload: { downtime_id },
        callback,
    };
};

export const deleteDowntimes = (downtimes, callback) => {
    return {
        type: K.ACTIONS.DELETE_DOWNTIMES_REQUEST,
        payload: { downtimes },
        callback,
    };
};

export const setSchedulesFilter = (filter) => {
    return {
        type: K.ACTIONS.SET_SCHEDULES_FILTER,
        payload: { filter },
    };
};

export const resetSchedulesStore = () => {
    return {
        type: K.ACTIONS.RESET_SCHEDULES_STORE,
    };
};

export const fetchAssetShifts = (entity_id, asset_id, query, callback) => {
    return {
        type: K.ACTIONS.GET_ASSET_SHIFTS_REQUEST,
        payload: {
            entity_id,
            asset_id,
            query,
        },
        callback,
    };
};

export const addAssetShiftsResource = (assetShifts) => {
    return {
        type: K.ACTIONS.ADD_ASSET_SHIFTS_RESOURCE,
        payload: { assetShifts },
    };
};
