/* eslint-disable react/prop-types */
import React from 'react';
import { AutoSizer, Grid, ScrollSync } from 'react-virtualized';

import './VSpreadsheet.scss';

// LOCKED AXES
export default class VSpreadsheet extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            indicator: null,
        };

        this._renderBodyCell = this._renderBodyCell.bind(this);
        this._renderHeaderCell = this._renderHeaderCell.bind(this);
        this._renderLeftSideCell = this._renderLeftSideCell.bind(this);

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    get rowCount() {
        return this.props.data.length;
    }

    get columnCount() {
        return this.props.data[0] ? this.props.data[0].length : 0;
    }

    handleMouseMove(e) {
        if (!this.props.showMarkingLines) return;
        e.persist();
        const { mainGrid, rowCount, columnCount } = this;
        const { scrollbarWidth, cellHeight, cellWidth } = this.props;

        if (!mainGrid) return;

        const { pageX, pageY } = e;
        const { top, left } = mainGrid.getBoundingClientRect();

        const x = pageX - left;
        const y = pageY - top;

        const gridWidth = cellWidth * columnCount;
        const gridHeight = cellHeight * rowCount;

        if (x > gridWidth || y > gridHeight) return;

        this.setState({ indicator: { x, y } });
    }

    handleMouseLeave() {
        if (!this.props.showMarkingLines) return;
        this.setState({ indicator: null });
    }

    render() {
        const {
            showMarkingLines,
            scrollbarWidth,
            cellWidth,
            cellHeight,
            headerHeight,
            overflowHeaderHeight,
            axisWidth,
            overscanColumnCount,
            overscanRowCount,
        } = this.props;

        const { indicator } = this.state;

        const { rowCount, columnCount } = this;

        return (
            <ScrollSync>
                {({
                    clientHeight,
                    clientWidth,
                    onScroll,
                    scrollHeight,
                    scrollLeft,
                    scrollTop,
                    scrollWidth,
                }) => {
                    return (
                        <div className="GridRow">
                            <div className={'GridColumn'}>
                                <AutoSizer>
                                    {({ width, height }) => {
                                        return (
                                            <div style={{ position: 'relative' }}>
                                                {!!columnCount && showMarkingLines && indicator && (
                                                    <>
                                                        <div
                                                            id="x-indicator"
                                                            style={{
                                                                position: 'absolute',
                                                                top: indicator.y + headerHeight,
                                                                left: axisWidth,
                                                                width: Math.min(
                                                                    cellWidth * columnCount,
                                                                    width - axisWidth - scrollbarWidth
                                                                ),
                                                                height: 1,
                                                            }}
                                                        />
                                                        <div
                                                            id="y-indicator"
                                                            style={{
                                                                position: 'absolute',
                                                                top: headerHeight,
                                                                left: indicator.x + axisWidth,
                                                                height: Math.min(
                                                                    cellHeight * rowCount,
                                                                    height - headerHeight + scrollbarWidth
                                                                ),
                                                                width: 1,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                <div
                                                    style={{
                                                        height: headerHeight,
                                                        // position: 'sticky',
                                                        top: 0,
                                                        marginLeft: axisWidth,
                                                    }}
                                                >
                                                    <Grid
                                                        className={'HeaderGrid'}
                                                        columnWidth={cellWidth}
                                                        columnCount={columnCount}
                                                        height={headerHeight}
                                                        overscanColumnCount={overscanColumnCount}
                                                        cellRenderer={this._renderHeaderCell}
                                                        rowHeight={Math.max(
                                                            overflowHeaderHeight,
                                                            headerHeight
                                                        )}
                                                        rowCount={1}
                                                        scrollLeft={scrollLeft}
                                                        width={width - axisWidth}
                                                        onSectionRendered={() => {}} // refresh even if column count doesn't change
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        // position: 'sticky',
                                                        top: headerHeight,
                                                        left: 0,
                                                        // overflow: 'hidden',
                                                        zIndex: 10,
                                                        backgroundColor: '#fff',
                                                        width: axisWidth,
                                                    }}
                                                >
                                                    <Grid
                                                        key={this.props.columns
                                                            .map((c) =>
                                                                this.props.columnKeyAccessor
                                                                    ? this.props.columnKeyAccessor(c)
                                                                    : c
                                                            )
                                                            .join('')}
                                                        overscanColumnCount={overscanColumnCount}
                                                        overscanRowCount={overscanRowCount}
                                                        cellRenderer={this._renderLeftSideCell}
                                                        columnWidth={axisWidth}
                                                        columnCount={1}
                                                        className={'LeftSideGrid'}
                                                        height={Math.min(
                                                            height - headerHeight,
                                                            rowCount * cellHeight
                                                        )}
                                                        rowHeight={cellHeight}
                                                        rowCount={rowCount}
                                                        scrollTop={scrollTop}
                                                        width={axisWidth + scrollbarWidth}
                                                        onSectionRendered={() => {}} // refresh even if row count doesn't change
                                                    />
                                                </div>
                                                <div
                                                    ref={(node) => (this.mainGrid = node)}
                                                    // onMouseMove={this.handleMouseMove}
                                                    // onMouseLeave={this.handleMouseLeave}
                                                    style={{
                                                        color: 'black',
                                                        width: width - axisWidth,
                                                        position: 'absolute',
                                                        top: headerHeight,
                                                        left: axisWidth,
                                                    }}
                                                >
                                                    <div
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseLeave={this.handleMouseLeave}
                                                    >
                                                        <Grid
                                                            className={'BodyGrid'}
                                                            columnWidth={cellWidth}
                                                            columnCount={columnCount}
                                                            height={
                                                                Math.min(
                                                                    height - headerHeight,
                                                                    rowCount * cellHeight
                                                                ) + scrollbarWidth
                                                            }
                                                            onScroll={onScroll}
                                                            overscanColumnCount={overscanColumnCount}
                                                            overscanRowCount={overscanRowCount}
                                                            rowHeight={cellHeight}
                                                            rowCount={rowCount}
                                                            width={width - axisWidth}
                                                            onSectionRendered={this.props.onSectionRendered}
                                                            cellRenderer={({
                                                                columnIndex,
                                                                key,
                                                                rowIndex,
                                                                style,
                                                            }) =>
                                                                this._renderBodyCell({
                                                                    columnIndex,
                                                                    key,
                                                                    rowIndex,
                                                                    style,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </AutoSizer>
                            </div>
                        </div>
                    );
                }}
            </ScrollSync>
        );
    }

    _renderBodyCell({ columnIndex, key, rowIndex, style }) {
        const {
            data,
            renderBodyCell,
            highlightOnHover,
            columns,
            rows,
            columnKeyAccessor,
            rowKeyAccessor,
        } = this.props;

        const value = data[rowIndex][columnIndex];

        const rowData = rows[rowIndex];
        const colData = columns[columnIndex];

        key =
      rowKeyAccessor && columnKeyAccessor
          ? `${rowKeyAccessor(rowData)}-${columnKeyAccessor(colData)}`
          : key;

        return (
            <div
                className={`cell ${highlightOnHover ? 'highlightable' : ''}`}
                key={key}
                style={style}
            >
                {renderBodyCell
                    ? renderBodyCell({ columnIndex, key, rowIndex, style, value })
                    : value}
            </div>
        );
    }

    _renderHeaderCell({ columnIndex, key, rowIndex, style }) {
        const { columns, columnAccessor, renderHeaderCell, columnKeyAccessor } =
      this.props;
        const data = columns[columnIndex];
        const value = columnAccessor(data);
        key = columnKeyAccessor ? columnKeyAccessor(data) : key;

        return (
            <div className={'headerCell'} key={key} style={style}>
                {renderHeaderCell
                    ? renderHeaderCell({ columnIndex, key, rowIndex, style, value })
                    : value}
            </div>
        );
    }

    _renderLeftSideCell({ columnIndex, key, rowIndex, style }) {
        const { rows, rowAccessor, renderLeftSideCell, rowKeyAccessor } =
      this.props;
        const data = rows[rowIndex];
        const value = rowAccessor(rows[rowIndex]);
        key = rowKeyAccessor ? rowKeyAccessor(data) : key;

        return (
            <div className="leftCell" key={key} style={style}>
                {renderLeftSideCell
                    ? renderLeftSideCell({ columnIndex, key, rowIndex, style, value })
                    : value}
            </div>
        );
    }
}

VSpreadsheet.defaultProps = {
    scrollbarWidth: 10,
    showMarkingLines: true,
    cellWidth: 75,
    cellHeight: 40,
    headerHeight: 40,
    axisWidth: 75,
    overscanColumnCount: 0,
    overscanRowCount: 5,
    highlightOnHover: true,
    overflowHeaderHeight: 0,
};
