export const tr = {
    notes: 'Notlar',
    load: 'Git',
    export: 'Indir',
    unutilised: 'Un kullanılan',
    breakdowns: 'arızalar',
    threshold: 'eşik',
    oeepreview: 'OEE önizleme',
    heatmappreview: 'Isı Haritası Önizleme',
    oeecolors: 'OEE renkleri',
    color: 'renk',
    reset: 'Sıfırla',
    theme: 'Tema',
    defaultview: 'Varsayılan görünüm',
    defaulttimeselection: 'Varsayılan zamanla seçimi',
    zoomlevel: 'Yakınlaştırma seviyesi',
    entity: 'varlık',
    preferences: 'Tercihler',
    custom: 'görenek',
    enteryourmailtoreset: 'sıfırlamak için posta girin',
    goback: 'Geri dön',
    resetpassword: 'Şifreyi yenile',
    logout: 'Çıkış Yap',
    ownersemail: 'sahipleri Email',
    optional: 'İsteğe bağlı',
    industry: 'sanayi',
    timezone: 'Saat dilimi',
    nocoderequesttojoin: 'Hayır Kod Üyelik için talep',
    enterreferralcodeinstead: 'yerine sevk kodunu girin',
    setup: 'Kurmak',
    company: 'şirket',
    referralcode: 'Yönlendirme Kodu',
    submit: 'Sunmak',
    summary: 'özet',
    language: 'Dil',
    login: 'Oturum aç',
    signin: 'oturum aç',
    signup: 'kaydol',
    username: 'Kullanıcı adı',
    password: 'Parola',
    forgotpassword: 'Parolanızı mı unuttunuz?',
    rememberme: 'Beni hatırla',
    register: 'Kayıt ol',
    registration: 'kayıt',
    firstname: 'İsim',
    lastname: 'Soyadı',
    email: 'E-posta',
    mobile: 'seyyar',
    country: 'ülke',
    contactnumber: 'İletişim numarası',
    appointment: 'Randevu',
    role: 'rol',
    admin: 'yönetim',
    owner: 'sahip',
    editor: 'editör',
    viewer: 'izleyici',
    confirmpassword: 'Şifreyi Onayla',
    iacceptthe: 'kabul ediyorum',
    useragreement: 'Kullanıcı Sözleşmesi',
    alreadyregisteredlogin: 'Zaten kayıtlı? Oturum aç',
    softwareasaservicetermsofuse: 'Kullanım Software-as-a-Service Şartları',
    dashboard: 'Gösterge Paneli',
    streaming: 'Yayın Akışı',
    resolution: 'çözüm',
    seconds: 'saniye',
    second: 'ikinci',
    minutes: 'dakika',
    minute: 'dakika',
    hours: 'saatler',
    hour: 'Saat',
    weeks: 'haftalar',
    week: 'Hafta',
    months: 'ay',
    month: 'ay',
    quarters: 'kışla',
    quarter: 'çeyrek',
    years: 'yıl',
    year: 'yıl',
    from: 'itibaren',
    to: 'için',
    start: 'Başlat',
    end: 'Son',
    display: 'Görüntüle',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Zaman serisi',
    gateway: 'geçit',
    statusasof: 'itibariyle Durumu',
    active: 'aktif',
    offline: 'çevrimdışı',
    mergeasonemachine: 'Makine ile birleştirme',
    drawarrowstoformprocessmap: 'şeklinde süreci haritaya okları çizin',
    openblockeditor: 'Açık blok editörü',
    createnewtile: 'Yeni Çini oluşturma',
    summarytile: 'Özet karo',
    selectablockthennameyoursummarytile:
    'Bir blok seçin, ardından özet karo isim',
    name: 'ad',
    blockselector: 'Blok seçici',
    asset: 'Varlık',
    therearenolabelstaggedinthisperiod: 'Bu dönemde etiketlendi hiç etiket yok',
    standardtimes: 'Standart saat (ler)',
    total: 'Toplam',
    last: 'Son',
    oeeinput: 'OEE girişi',
    chartcolor: 'Grafik rengi',
    issueeditor: 'Sayı Editör',
    issuemangement: 'Sayı Mangement',
    cumulativeoutput: 'Kümülatif çıktı',
    tag: 'Etiket',
    label: 'Etiket',
    valueval: 'Değer (Val)',
    time: 'Zaman',
    januaryjan: 'Ocak (Ocak)',
    februaryfeb: 'Şubat (Şubat)',
    marchmar: 'Mart (Mart)',
    aprilapr: 'Nisan (Nisan)',
    may: 'Mayıs',
    junejun: 'Haziran (Haziran)',
    julyjul: 'Temmuz (Temmuz)',
    augustaug: 'Ağustos (Ağustos)',
    septembersep: 'Eylül (Eylül)',
    octoberoct: 'Ekim (Ekim)',
    novembernov: 'Kasım (Kasım)',
    decemberdec: 'Aralık (Aralık)',
    day: 'Gün',
    days: 'günler',
    mondaymonm: 'Pazartesi (Pazartesi) M',
    tuesdaytuet: 'Salı (Sal) T',
    wednesdaywedw: 'Çarşamba (Çar) W',
    thursdaythut: 'Perşembe günü (Per), T',
    fridayfrif: 'Cuma (Cuma) F',
    saturdaysats: 'Cumartesi (Cts) S',
    sundaysuns: 'Pazar günü (Güneş) S',
    edit: 'Düzenle',
    add: 'Ekle',
    new: 'Yeni',
    create: 'Oluşturmak',
    search: 'Arama',
    close: 'Kapat',
    cancel: 'İptal etmek',
    save: 'Kayıt etmek',
    saved: 'Kayıtlı',
    clear: 'Açık',
    enter: 'Giriş',
    navigator: 'denizci',
    mandatoryfields: 'Zorunlu alanlar',
    select: 'seçmek',
    merge: 'Birleştirmek',
    delete: 'Sil',
    done: 'Bitti',
    filter: 'filtre',
    table: 'tablo',
    columns: 'sütunlar',
    rows: 'satırlar',
    cell: 'hücre',
    page: 'Sayfa',
    previous: 'Önceki',
    next: 'Sonraki',
    serialnumber: 'Seri numarası',
    manuallyinputserialnumber: 'El ile giriş Seri Numarası',
    updateasset: 'Güncelleme Varlık',
    machine: 'makine',
    brand: 'Marka',
    model: 'model',
    nodes: 'Düğümler',
    fusions: 'Fusions',
    chartorder: 'Grafik Sipariş',
    primary: 'Birincil',
    adddevicechannel: 'Cihaz Ekle - Kanal',
    pushratesec: 'Puan (sn) itin',
    voltagev: 'Gerilimi (V)',
    sensor: 'sensor',
    mode: 'kip',
    digitalcount: 'Dijital Sayısı',
    digitalstate: 'Dijital Devlet',
    analogvoltage: 'Analog Gerilim',
    charttitle: 'Grafik başlığı',
    unitmeasure: 'Birim ölçü',
    attribute: 'nitelik',
    autocycletime: 'Oto Çevrim süresi',
    unittransform: 'Birim Dönüşümü',
    leaveblankifnotapplicable: 'Bırak boş uygulanabilir değilse',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Zorunlu alanlar (bırakın boş kanal kullanılmadığı takdirde)',
    fusedchart: 'Sigortalı Şeması',
    fusionname: 'Füzyon Adı',
    charttype: 'Grafik türü',
    column: 'sütun',
    line: 'Hat',
    state: 'Durum',
    expression: 'ifade',
    combination: 'kombinasyon',
    currentcharts: 'Güncel Grafikler',
    expressionconstructor: 'İfade Oluşturucu',
    validoperators: 'Geçerli operatörler',
    leaveblanktokeepexistingexpression:
    'Varolan ifadesini tutmak için boş bırakın',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Genel Ekipman Etkinliği',
    legend: 'efsane',
    loading: 'Yükleniyor',
    availability: 'Kullanılabilirlik',
    performance: 'Verim',
    quality: 'Kalite',
    unscheduled: 'programda olmayan',
    planneddowntime: 'Planlanan Kesinti',
    changeoversetup: 'Geçiş / Kurulum',
    breakdown: 'Yıkmak',
    unutilized: 'Un kullanılan',
    speedloss: 'hız Kaybı',
    minorstops: 'Minör Duraklar',
    rework: 'Tamir',
    rejectscrap: 'Reddet / Hurda',
    nodata: 'Veri yok',
    blockeditor: 'Blok Editör',
    sort: 'Çeşit',
    logicblocks: 'Mantık Blokları',
    sequence: 'Sıra',
    and: 'Ve',
    or: 'Veya',
    utilizationheatmap: 'kullanım İlgi haritası',
    startdate: 'Başlangıç ​​tarihi',
    enddate: 'Bitiş tarihi',
    daily: 'Günlük',
    weekly: 'Haftalık',
    monthly: 'Aylık',
    quarterly: 'üç aylık',
    yearly: 'Yıllık',
    trendanalysis: 'Moda analizi',
    metricselection: 'Metrik Seçimi',
    metric: 'Metrik',
    data: 'Veri',
    target: 'Hedef',
    periodselection: 'Dönem Seçimi',
    span: 'karış',
    every: 'Her',
    repeat: 'Tekrar et',
    repeats: 'Tekrarlar',
    addsingle: 'Tek ekle',
    paretoanalysis: 'Pareto Analizi',
    calendartime: 'Takvim saat',
    scheduledtime: 'Planlanmış zaman',
    operatingtime: 'Operasyon zamanı',
    netruntime: 'Net çalışma zamanı',
    effective: 'etkili',
    loadingloss: 'Yükleme Kaybı',
    availabilityloss: 'Kullanılabilirlik Kaybı',
    performanceloss: 'Performans Kaybı',
    qualityloss: 'Kalite Kaybı',
    notagsinthiscategory: 'Bu kategoride etiket yok',
    overall: 'tüm',
    tagged: 'Etiketli',
    occurrence: 'olay',
    skumanagement: 'SKU Yönetimi',
    category: 'Kategori',
    skuname: 'SKU Adı',
    skucode: 'SKU Kodu',
    unitofmeasurementuom: 'Ölçüm Birimi (MKM)',
    description: 'Açıklama',
    csvupload: 'CSV Yükleme',
    template: 'şablon',
    notifications: 'Bildirimler',
    newnotification: 'Yeni Bildirim',
    monitoring: 'İzleme',
    topic: 'konu',
    frequency: 'Sıklık',
    recipients: 'Alıcılar',
    notifyby: 'ile bilgilendir',
    sms: 'SMS',
    alert: 'Uyarmak',
    report: 'Bildiri',
    greaterthan: 'büyüktür',
    lessthan: 'Daha az',
    greaterthanorequal: 'Büyüktür veya eşittir',
    lessthanorequal: 'Az veya eşit',
    equal: 'Eşit',
    range: 'Aralık',
    continuousduration: 'Sürekli Süre',
    usermanagement: 'Kullanıcı yönetimi',
    user: 'kullanıcı',
    users: 'Kullanıcılar',
    invitenew: 'Yeni Davet',
    status: 'durum',
    resend: 'Yeniden gönder',
    revoke: 'İptal Et',
    invite: 'Davet et',
    invitees: 'Davetliler',
    addinviteesbypressingenterorclickingonthesign:
    "Enter tuşuna basarak veya '+' işaretini tıklayarak davetlileri ekleyin",
    schedules: 'Programları',
    shift: 'Vardiya',
    profile: 'Profil',
    changepassword: 'Şifre değiştir',
    deleteaccount: 'Hesabı sil',
    userguide: 'Kullanici rehberi',
    overviewandbasiccontrol: 'Genel bakış ve Temel Kontrol',
    rangeandresolution: 'Menzil ve Çözünürlük',
    editingoptions: 'düzenleme seçenekleri',
    mergetiles: 'Birleştirme fayans',
    detailedassetview: 'Ayrıntılı varlık görünümü',
    assetoverview: 'varlık genel bakış',
    summarystatistics: 'Özet İstatistikler',
    dataanalysis: 'Veri analizi',
    oeeanoverview: 'OEE bir bakış',
    oeetoggle: 'OEE geçiş',
    addingamachine: 'bir makine ekleme',
    nodeconfiguration: 'Düğüm yapılandırma',
    deleteupdateassets: 'Sil güncelleme varlıklar',
    oeesummarytile: 'OEE özeti karo',
    oeetags: 'OEE Etiketler',
    issuemanagement: 'konu yönetimi',
    labelsandfiltering: 'Etiketler ve filtreleme',
    useraccess: 'Kullanıcı erişimi',
    group: 'grup',
    groups: 'Gruplar',
    joincompany: 'şirket Üyelik',
    notification: 'Bildirim',
    createnotification: 'bildirim oluşturma',
    node: 'düğüm',
    downtime: 'Kesinti',
    downtimes: 'Duruş süreleri',
    repeatstartdate: 'Başlangıç ​​tarihi tekrarlayın',
    repeatenddate: 'Tekrarlama Bitiş Tarihi',
    optional: 'İsteğe bağlı',
    createplan: 'Planı Yaratma',
    today: 'Bugün',
    starttime: 'Başlangıç ​​saati',
    endtime: 'Bitiş zamanı',
    chart: 'Grafik',
    reject: 'reddetmek',
    accept: 'Kabul etmek',
    filterbydaterange: 'Tarih aralığına göre filtre',
    filterbymostrecent: 'En son tarafından filtre',
    result: 'Sonuç',
    type: 'tip',
    duration: 'süre',
    by: 'tarafından',
    title: 'Başlık',
    assigned: 'atanan',
    action: 'Aksiyon',
    track: 'Izlemek',
    issue: 'Konu',
    priority: 'öncelik',
    requestor: 'istekte',
    opened: 'açıldı',
    assignee: 'vekil',
    root: 'kök',
    cause: 'Sebep olmak',
    identified: 'Belirlenen',
    defined: 'Tanımlı',
    date: 'tarih',
    resolve: 'çözmek',
    resolved: 'kararlı',
    targetresolvedate: 'Hedef çözmek tarih',
    procurement: 'tedarik',
    choose: 'Seç',
    analyses: 'analizler',
    source: 'Kaynak',
    selection: 'seçim',
    historical: 'Tarihi',
    mostrecent: 'En yeni',
    regression: 'gerileme',
    trendline: 'eğilim çizgisi',
    upperbound: 'üst sınırı',
    lowerbound: 'alt sınır',
    preview: 'Ön izleme',
    period: 'dönem',
    series: 'dizi',
    axis: 'eksen',
    youhaveno: 'Hiç var',
    code: 'kod',
    all: 'Herşey',
    upload: 'yükleme',
    regressionanalysis: 'Regresyon analizi',
    issuetracker: 'sorun izleyici',
    labelsearch: 'Etiket Arama',
    gatewaysetup: 'Ağ geçidi Kurulumu',
    setupgateway: 'Kurulum geçidi',
    radiomesh: 'radyo örgü',
    targetsetting: 'hedef ayarı',
    updatetrackedissue: 'Güncelleştirme izlenen konu',
    shownewtrackedissue: 'yeni paletli sorunu göstermek',
    low: 'düşük',
    mid: 'orta',
    high: 'yüksek',
    linear: 'doğrusal',
    exponential: 'üstel',
    logarithmic: 'logaritmik',
    quadratic: 'ikinci dereceden',
    polynomial: 'polinom',
    general: 'Genel',
    datataggingenrichment: 'Veri Etiketleme Zenginleştirme',
    hardware: 'Donanım',
    nodemanagement: 'Düğüm yönetimi',
    createeditnotification: 'düzenlemek bildirim oluşturma',
    conditions: 'Koşullar',
    addsku: 'SKU eklemek',
    editsku: 'düzenlemek SKU',
    setupagateway: 'Kurulum bir geçit',
    deviceserialnumber: 'cihazın seri numarası',
    repeatsweekly: 'hafta yinelenir',
    repeatsdaily: 'günlük tekrarlar',
    repeatsmonthly: 'aylık tekrarlar',
    repeatsannually: 'yılda tekrarlar',
    repeatsevery: 'tekrarlar her',
    hourly: 'saatlik',
    biweekly: 'iki haftada bir',
};
