import { updateStoreRedux } from '../../../legacy/utils/redux';
import { DataConstants as K } from './Data.constants';


const initialReducer = {
    blocks: {},
    summary_tiles: {},
    metadata: {},
    fusions: {},
};

export const dataReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.ADD_BLOCKS_OEE_DATA_RESOURCE) {
        return updateStoreRedux(state, {
            blocks: { ...state.blocks, ...action.payload.blocks },
        });
    }

    if (action.type === K.ACTIONS.ADD_TIME_SERIES_DATA_RESOURCE) {
        return updateStoreRedux(state, {
            metadata: { ...state.metadata, ...action.payload.metadata },
            fusions: { ...state.fusions, ...action.payload.fusions },
        });
    }

    if (action.type === K.ACTIONS.ADD_SUMMARY_TILES_OEE_DATA_RESOURCE) {
        return updateStoreRedux(state, {
            summary_tiles: {
                ...state.summary_tiles,
                ...action.payload.summary_tiles,
            },
        });
    }

    if (action.type === K.ACTIONS.CLEAR_DATA_RESOURCE) {
        const { resources } = action.payload;
        const cleared = resources.reduce(
            (acc, curr) => ({ ...acc, [curr]: {} }),
            {}
        );
        return updateStoreRedux(state, cleared);
    }

    return updateStoreRedux(state, {});
};
