/* eslint-disable react/prop-types */

import React from 'react';
import AutosizeText from '../../components/AutosizeText';

import './WidgetLabel.scss';

const WidgetLabel = (props) => {
    return (
        <AutosizeText className="widget-label" style={{ height: '20%' }} clamps={2}>
            {props.children}
        </AutosizeText>
    );
};

export default WidgetLabel;
