/* eslint-disable react/prop-types */
import React from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import './ColorLegendSequential.scss';

export default class ColorLegendSequential extends React.Component {
    componentDidMount() {
        this.redraw();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height ||
      !_.isEqual(prevProps.domain, this.props.domain)
        ) {
            this.redraw();
        }
    }

    redraw(n = 512) {
    // Adapted from "@mbostock/color-ramp"
    // https://observablehq.com/@d3/sequential-scales?collection=@d3/d3-scale-chromatic
    // if (!this.canvas) return;

        const { width, height, scale, domain } = this.props;

        // const scale = scaleGen.domain(domain);
        scale.domain(domain);
        const ctx = this.canvas.getContext('2d');

        this.canvas.width = width;
        this.canvas.height = height;
        this.canvas.style.width = width;
        this.canvas.style.height = height;

        const t = scale.ticks(n);
        const barWidth = this.canvas.width / t.length;

        for (let i = 0; i < t.length; ++i) {
            ctx.fillStyle = scale(t[i]);
            ctx.fillRect(i * barWidth, 0, barWidth * 100, 20);
        }
    }

    render() {
        return (
            <div className="color-legend" style={{ width: this.props.width }}>
                <canvas ref={(node) => (this.canvas = node)} />
                {this.props.indicators && (
                    <div className="d-flex justify-content-between">
                        {this.props.indicators.map((i, idx) => {
                            return (
                                <div key={idx} style={{ fontSize: 11 }}>
                                    {i}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

ColorLegendSequential.defaultProps = {
    width: 400,
    height: 10,
    scale: (() => d3.scaleSequential(d3.interpolateGnBu))(),
};
