/* eslint-disable react/prop-types */
import React from 'react';

const FormCustomField = (props) => (
    <div
        className={`row form-group ${props.className || ''}`}
        style={props.style}
        id={props.id}
    >
        {props.children}
    </div>
);

export default FormCustomField;
