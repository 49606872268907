
import { Popover, Typography } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import React, { useState } from "react"
import theme from "../../styles/theme"

const MouseOverPopover = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) }
    const handleClosePopover = () => { setAnchorEl(null) }

    const open = Boolean(anchorEl)
    const id = open ? props.id : undefined

    return <div>
        <Typography
            onMouseEnter={handleOpenPopover}
            onMouseLeave={handleClosePopover}
            sx={{ display: "flex", alignItems: "center", color: theme.palette.primary.main }}
        >
            { props.icon ? props.icon : <InfoOutlinedIcon/> }
        </Typography>
        <Popover
            id={id}
            sx={{
                pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            onClose={handleClosePopover}
            disableRestoreFocus
        >
            { props.children }
        </Popover>
    </div>
}

interface Props {
    icon? : React.ReactNode
    children: React.ReactNode
    id: string
}

export default MouseOverPopover