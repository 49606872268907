import "./style.scss"
import { FormControl, FormLabel } from "@mui/material"
import classNames from "classnames"
import React, { type ChangeEventHandler } from "react"
import NumberInput from "../NumberInput/NumberInput"
import TextInput from "../TextInput/TextInput"

const DescriptiveInput = (props: Props) => {
    if (props.type === "number") {
        return <FormControl className={classNames("descriptive-input", props.className)}>
            <NumberInput
                hiddenLabel
                className="descriptive-input-field"
                variant="outlined"
                id={props.id}
                sx={{ width: props.width ?? 50 }}
                width={props.width ?? 50}
                value={props.value}
                onChange={props.onChange}
                maxLength={props.maxLength}
                min={props.min}
                max={props.max}
            />
            <FormLabel sx={{ fontSize: 14, paddingLeft: "6px", flex: 1 }}>{props.label}</FormLabel>
        </FormControl>
    }

    return <FormControl className={classNames("descriptive-input", props.className)}>
        <TextInput
            hiddenLabel
            className="descriptive-input-field"
            variant="outlined"
            sx={{ width: props.width ?? 50 }}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
        />
        <FormLabel sx={{ fontSize: 14, paddingLeft: "6px" }}>{props.label}</FormLabel>
    </FormControl>
}

interface Props {
    min?: number
    max?: number
    maxLength?: number
    onChange: ChangeEventHandler
    value: string
    label: string
    type?: React.InputHTMLAttributes<unknown>["type"]
    className?: string
    id?: string
    width?: number
}

export default DescriptiveInput
