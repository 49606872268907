import { makeRequest } from '../Shared/Shared.action';
import { Notification } from '../../../legacy/models';
import { store } from '../..';

export const api_getNotifications = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/notification`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createNotification = (data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/notification`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateNotification = (data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();
    const { notification_id } = data;
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/notification/${notification_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteNotification = (notification_id) => {
    const {
        entity: { active: entity_id },
    } = store.getState();

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/notification/${notification_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};
