import { updateStoreRedux } from '../../../legacy/utils/redux';
import { IssueConstants as K } from './Issues.constant';
import { Issue, parseIssueArguments } from '../../../legacy/models';
import { flattenTree, getMapFromArr, toMappedArrays } from '../../../legacy/utils/helpers';

const initialReducer = () => ({
    issues: {},
    pc_directory: {},
    initialized: false,
});

export const issuesReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_ISSUES_STATE) {
        let { issues, initialized } = action.payload;

        issues = flattenIssueTree(issues);
        issues = issues.reduce((acc, curr) => acc.concat(...curr), []);

        const pc_directory = toMappedArrays(
            issues,
            (i) => i.parent_issue_id,
            (i) => i.issue_id
        );
        issues = getMapFromArr(
            issues.map((i) => new Issue(...parseIssueArguments(i))),
            'issue_id'
        );

        return updateStoreRedux(state, {
            issues,
            pc_directory,
            initialized,
        });
    }

    if (action.type === K.ACTIONS.RESET_ISSUES_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};

const flattenIssueTree = (arrRootIssues) => {
    return arrRootIssues.map((rootCategory) =>
        flattenTree(rootCategory, (node) => node.children)
    );
};
