import { makeRequest } from '../Shared/Shared.action';
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

export const api_createTrackedIssue = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateTrackedIssue = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());
    const { tracked_issue_id } = data;

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue/${tracked_issue_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteTrackedIssue = (tracked_issue_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue/${tracked_issue_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getTrackedIssues = () => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tracked_issue`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};
