/* eslint-disable react/prop-types */
import React from 'react';

// STYLE
import './index.scss';
import { SPAWrapper } from '../../components/SPAWrapper';
import SkuStandardTimes from './SkuStandardTimes';
import AssetStandardTimes from './AssetStandardTimes';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const StandardTimeManagement = (props) => {
    return <SPAWrapper className="st-management">
    <Tabs destroyInactiveTabPane className="st-tabs" type="card">
        <TabPane tab="Asset" key="1">
            <AssetStandardTimes/>
        </TabPane>
        <TabPane tab="SKU" key="2">
            <SkuStandardTimes/>
        </TabPane>
    </Tabs>
</SPAWrapper>
}

export default StandardTimeManagement;
