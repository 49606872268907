export const th = {
    notes: 'หมายเหตุ',
    load: 'โหลด',
    export: 'ดาวน์โหลด',
    unutilised: 'เครื่องจักรไม่ถูกใช้ประโยชน์',
    breakdowns: 'เครื่องจักรขัดข้อง (Breakdowns)',
    threshold: 'เกณฑ์',
    oeepreview: 'ตัวอย่าง OEE',
    heatmappreview: 'ตัวอย่าง Heatmap',
    oeecolors: 'สี OEE',
    color: 'สี',
    reset: 'รีเซ็ต',
    theme: 'ธีม',
    defaultview: 'มุมมองเริ่มต้น',
    defaulttimeselection: 'ค่าเวลาเริ่มต้น',
    zoomlevel: 'ระดับการซูม',
    entity: 'ภาพรวมสูงสุด',
    preferences: 'ตั้งค่าการแสดงผล',
    custom: 'กำหนดเอง',
    enteryourmailtoreset: 'ใส่เมลของคุณเพื่อรีเซ็ต',
    goback: 'ย้อนกลับ',
    resetpassword: 'รีเซ็ตรหัสผ่าน',
    logout: 'ออกจากระบบ',
    ownersemail: 'อีเมลเจ้าของ',
    optional: 'ไม่บังคับ',
    industry: 'อุตสาหกรรม',
    timezone: 'เขตเวลา',
    nocoderequesttojoin: 'ไม่มีรหัส? ขอเข้าร่วม',
    enterreferralcodeinstead: 'ป้อนรหัสอ้างอิงแทน',
    setupchangeover:
    'เครื่องจักรถูกปรับตั้ง/เปลี่ยนรุ่นการผลิต (Setup/Changeover)',
    company: 'บริษัท',
    referralcode: 'รหัสอ้างอิง',
    submit: 'ส่ง',
    summary: 'สรุป',
    language: 'ภาษา',
    login: 'เข้าสู่ระบบ',
    signin: 'เข้าสู่ระบบ',
    signup: 'ลงทะเบียน',
    username: 'ชื่อผู้ใช้',
    password: 'รหัสผ่าน',
    forgotpassword: 'ลืมรหัสผ่าน?',
    rememberme: 'จดจำฉัน',
    register: 'ลงทะเบียน',
    registration: 'การลงทะเบียน',
    firstname: 'ชื่อจริง',
    lastname: 'นามสกุล',
    email: 'อีเมล์',
    mobile: 'เบอร์โทรศัพท์มือถือ',
    country: 'ประเทศ',
    contactnumber: 'เบอร์ติดต่อ',
    appointment: 'ตำแหน่ง',
    role: 'หน้าที่',
    admin: 'แอดมิน',
    owner: 'เจ้าของ',
    editor: 'ผู้แก้ไข',
    viewer: 'ผู้ชม',
    confirmpassword: 'ยืนยันรหัสผ่าน',
    iacceptthe: 'ฉันยอมรับ',
    useragreement: 'ข้อตกลงการใช้',
    alreadyregisteredlogin: 'ลงทะเบียนเรียบร้อยแล้ว? เข้าสู่ระบบ',
    softwareasaservicetermsofuse: 'เงื่อนไขการใช้งาน Software-as-a-Service',
    dashboard: 'แดชบอร์ด',
    streaming: 'สตรีมมิ่ง',
    resolution: 'ความละเอียด',
    seconds: 'วินาที',
    second: 'วินาที',
    minutes: 'นาที',
    minute: 'นาที',
    hours: 'ชั่วโมง',
    hour: 'ชั่วโมง',
    weeks: 'สัปดาห์',
    week: 'สัปดาห์',
    months: 'เดือน',
    month: 'เดือน',
    quarters: 'ไตรมาส',
    quarter: 'ไตรมาส',
    years: 'ปี',
    year: 'ปี',
    from: 'ช่วงเวลา',
    to: 'ถึง',
    start: 'เริ่มต้น',
    end: 'สิ้นสุด',
    display: 'แสดง',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'อนุกรมเวลา',
    gateway: 'เกตเวย์',
    statusasof: 'สถานะ',
    active: 'กำลังใช้งานอยู่',
    offline: 'ออฟไลน์',
    mergeasonemachine: 'รวมเป็นเครื่องเดียวกัน',
    drawarrowstoformprocessmap: 'วาดลูกศร เพื่อสร้างแผนผังกระบวนการทำงาน',
    openblockeditor: 'เปิดตัวแก้ไขบล็อก',
    createnewtile: 'สร้างบล็อคใหม่',
    summarytile: 'บล็อคสรุป',
    selectablockthennameyoursummarytile: 'เลือกบล็อก และตั้งชื่อบล็อคสรุปของคุณ',
    name: 'ชื่อ',
    blockselector: 'ตัวเลือกบล็อก',
    asset: 'สินทรัพย์',
    therearenolabelstaggedinthisperiod: 'มีป้ายไม่มีแท็กในช่วงเวลานี้',
    standardtimes: 'เวลามาตรฐาน (s)',
    total: 'รวม',
    last: 'ที่แล้ว',
    oeeinput: 'การป้อนข้อมูล OEE',
    chartcolor: 'สีแผนภูมิ',
    issueeditor: 'แก้ไขปัญหา',
    issuemangement: 'Mangement ปัญหา',
    cumulativeoutput: 'การส่งออกสะสม',
    tag: 'แท็ก',
    label: 'แท็ก',
    valueval: 'มูลค่า (Val)',
    time: 'เวลา',
    januaryjan: 'มกราคม ( ม.ค. )',
    februaryfeb: 'กุมภาพันธ์ (ก.พ.)',
    marchmar: 'มีนาคม ( มี.ค. )',
    aprilapr: 'เมษายน (เม.ษ.)',
    may: 'พฤษภาคม (พ.ค.)',
    junejun: 'มิถุนายน (มิ.ย.)',
    julyjul: 'กรกฎาคม (ก.ค.)',
    augustaug: 'สิงหาคม (ส.ค.)',
    septembersep: 'กันยายน (ก.ย.)',
    octoberoct: 'ตุลาคม (ต.ค.)',
    novembernov: 'พฤศจิกายน (พ.ย.)',
    decemberdec: 'ธันวาคม (ธ.ค.)',
    day: 'วัน',
    days: 'วัน',
    mondaymonm: 'จันทร์ (จ)',
    tuesdaytuet: 'อังคาร (อ)',
    wednesdaywedw: 'พุธ (พ)',
    thursdaythut: 'พฤหัสบดี (พฤ)',
    fridayfrif: 'ศุกร์ (ศ)',
    saturdaysats: 'เสาร์ (ส)',
    sundaysuns: 'อาทิตย์ (อา)',
    edit: 'แก้ไข',
    add: 'เพิ่ม',
    new: 'ใหม่',
    create: 'สร้าง',
    search: 'ค้นหา',
    close: 'ปิด',
    cancel: 'ยกเลิก',
    save: 'บันทึก',
    saved: 'บันทึก',
    clear: 'ชัดเจน',
    enter: 'เข้าสู่',
    navigator: 'เนวิเกเตอร์',
    mandatoryfields: 'จำเป็นต้องใส่',
    select: 'เลือก',
    merge: 'ผสาน',
    delete: 'ลบ',
    done: 'เสร็จสิ้น',
    filter: 'ตัวกรอง',
    table: 'ตาราง',
    columns: 'คอลัมน์',
    rows: 'แถว',
    cell: 'เซลล์',
    page: 'หน้า',
    previous: 'ก่อนหน้า',
    next: 'ต่อไป',
    serialnumber: 'หมายเลขลำดับ',
    manuallyinputserialnumber: 'ใส่หมายเลขซีเรียลด้วยตนเอง',
    updateasset: 'อัพเดทสินทรัพย์',
    machine: 'เครื่องจักร',
    brand: 'แบรนด์',
    model: 'รุ่น',
    nodes: 'โหนด',
    fusions: 'การรวม',
    chartorder: 'จัดลำดับชาร์ต',
    primary: 'หลัก',
    adddevicechannel: 'เพิ่มอุปกรณ์ - ช่อง',
    pushratesec: 'อัตราการอัพเดท (วินาที)',
    voltagev: 'แรงดัน (V)',
    sensor: 'เซนเซอร์',
    mode: 'โหมด',
    digitalcount: 'จำนวนดิจิตอล',
    digitalstate: 'รัฐดิจิตอล',
    analogvoltage: 'แรงดันไฟฟ้าแบบอะนาล็อก',
    charttitle: 'แผนภูมิชื่อเรื่อง',
    unitmeasure: 'วัดหน่วย',
    attribute: 'คุณลักษณะ',
    autocycletime: 'เวลาอัตโนมัติวงจร',
    unittransform: 'หน่วยแปลง',
    leaveblankifnotapplicable: 'เว้นว่างไว้หากไม่ได้บังคับ',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'ข้อมูลที่จำเป็น (เว้นว่างหากช่องไม่ได้ใช้งาน)',
    fusedchart: 'แผนภูมิผสม',
    fusionname: 'ฟิวชั่นชื่อ',
    charttype: 'รูปแบบชาร์ต',
    column: 'คอลัมน์',
    line: 'ไลน์',
    state: 'สถานะ',
    expression: 'การแสดงออก',
    combination: 'การรวมกัน',
    currentcharts: 'ชาร์ตปัจจุบัน',
    expressionconstructor: 'การสร้างเงื่อนไง',
    validoperators: 'คำสั่งที่สามารถใช้ได้',
    leaveblanktokeepexistingexpression: 'เว้นว่างเพื่อคงเงื่อนไขเดิม',
    oee: 'OEE',
    overallequipmenteffectiveness: 'ประสิทธิผลโดยรวมของเครื่องจักร',
    legend: 'คำอธิบายแผนภูมิ',
    loading: 'กำลังโหลด',
    availability: 'อัตราการเดินเครื่องจักร',
    performance: 'ประสิทธิภาพ',
    quality: 'คุณภาพ',
    unscheduled: 'ไม่มีแผนการผลิต',
    planneddowntime: 'หยุดตามแผน',
    changeoversetup: 'เซ็ทอัพเพื่อเปลี่ยนสินค้า',
    breakdown: 'เครื่องจักรเสีย',
    unutilized: 'ไม่ถูกใช้',
    speedloss: 'สูญเสียความเร็วของเครื่องจักร',
    speedlosses: 'สูญเสียความเร็วในการผลิต (Speed Losses)',
    minorstops: 'ความสูญเสียจากการหยุดระยะสั้น (Minor Stops)',
    rework: 'ผลผลิตที่ต้องแก้ไข หรือทำซ้ำ (Rework)',
    rejects: 'ผลผลิตที่ไม่ผ่านคุณภาพ (Rejects)',
    rejectscrap: 'ของเสีย',
    nodata: 'ไม่มีข้อมูล',
    blockeditor: 'บล็อกผู้แก้ไข',
    sort: 'ประเภท',
    logicblocks: 'ลอจิกบล็อก',
    sequence: 'ลำดับ',
    and: 'และ',
    or: 'หรือ',
    utilizationheatmap: 'ฮีทแมพแสดงการใช้งาน',
    startdate: 'วันที่เริ่มต้น',
    enddate: 'วันที่สิ้นสุด',
    daily: 'รายวัน',
    weekly: 'รายสัปดาห์',
    monthly: 'รายเดือน',
    quarterly: 'รายไตรมาส',
    yearly: 'รายปี',
    trendanalysis: 'วิเคราะห์แนวโน้ม',
    metricselection: 'การเลือกตัวชี้วัด',
    metric: 'เมตริก',
    data: 'ข้อมูล',
    target: 'เป้าหมาย',
    periodselection: 'การเลือกช่วงเวลา',
    span: 'ระยะ',
    every: 'ทุกๆ',
    repeat: 'ซ้ำ',
    repeats: 'ซ้ำ',
    addsingle: 'เพิ่มโสด',
    paretoanalysis: 'การวิเคราะห์พาเรโต้',
    // calendartime: 'ปฏิทินเวลา',
    // scheduledtime: 'ตารางเวลา',
    // operatingtime: 'เวลาการทำงาน',
    // netruntime: 'เวลาทำงานสุทธิ',
    effective: 'มีประสิทธิภาพ',
    loadingloss: 'สูญเสียเวลารับภาระงาน',
    availabilityloss: 'สูญเสียอัตราการเดินเครื่อง',
    performanceloss: 'สูญเสียประสิทธิภาพ',
    qualityloss: 'การสูญเสียคุณภาพ',
    notagsinthiscategory: 'ไม่มีแท็กในหมวดหมู่นี้',
    overall: 'ทั้งหมด',
    tagged: 'แท็ก',
    occurrence: 'การเกิดขึ้น',
    skumanagement: 'การจัดการ SKU',
    category: 'หมวดหมู่',
    skuname: 'ชื่อ SKU',
    skucode: 'รหัส SKU',
    unitofmeasurementuom: 'หน่วยวัด (UOM)',
    description: 'คำอธิบาย',
    csvupload: 'อัปโหลดไฟล์CSV',
    template: 'แบบฟอร์ม',
    notifications: 'การแจ้งเตือน',
    newnotification: 'การแจ้งเตือนใหม่',
    monitoring: 'การมอนิเตอร์',
    topic: 'หัวข้อ',
    frequency: 'ความถี่',
    recipients: 'ผู้รับ',
    notifyby: 'แจ้งโดย',
    sms: 'ข้อความ sms',
    alert: 'แจ้งเตือน',
    report: 'รายงาน',
    greaterthan: 'มากกว่า',
    lessthan: 'น้อยกว่า',
    greaterthanorequal: 'มากกว่าหรือเท่ากับ',
    lessthanorequal: 'น้อยกว่าหรือเท่ากับ',
    equal: 'เท่ากัน',
    range: 'ช่วง',
    continuousduration: 'ระยะเวลาต่อเนื่อง',
    usermanagement: 'การจัดการผู้ใช้',
    user: 'ผู้ใช้',
    users: 'ผู้ใช้',
    invitenew: 'เชิญใหม่',
    status: 'สถานะ',
    resend: 'ส่งซ้ำ',
    revoke: 'เรียกกลับ',
    invite: 'เชิญ',
    invitees: 'ผู้ได้รับเชิญ',
    addinviteesbypressingenterorclickingonthesign:
    'เพิ่มผู้ใช้ โดยกด Enter หรือคลิกที่เครื่องหมาย +',
    schedules: 'ตารางเวลา',
    shift: 'กะการทำงาน',
    profile: 'โปรไฟล์',
    changepassword: 'เปลี่ยนรหัสผ่าน',
    deleteaccount: 'ลบบัญชี',
    userguide: 'คู่มือผู้ใช้',
    overviewandbasiccontrol: 'ภาพรวมและการควบคุมขั้นพื้นฐาน',
    rangeandresolution: 'ช่วงเวลาและความละเอียด',
    editingoptions: 'ตัวเลือกการแก้ไข',
    mergetiles: 'รวมบล็อก',
    detailedassetview: 'มุมมองรายละเอียดของสินทรัพย์',
    assetoverview: 'ภาพรวมของสินทรัพย์',
    summarystatistics: 'สรุปสถิติ',
    dataanalysis: 'การวิเคราะห์ข้อมูล',
    oeeanoverview: 'ภาพรวม OEE',
    oeetoggle: 'สลับ OEE',
    addingamachine: 'เพิ่มเครื่อง',
    nodeconfiguration: 'การกำหนดค่าโหนด',
    deleteupdateassets: 'ลบการอัพเดทสินทรัพย์',
    oeesummarytile: 'บล็อกสรุป OEE',
    oeetags: 'OEE แท็ก',
    issuemanagement: 'การจัดการปัญหา',
    labelsandfiltering: 'ชื่อแท็กและการจัดเรียง',
    useraccess: 'การเข้าถึงของผู้ใช้',
    group: 'กลุ่ม',
    groups: 'กลุ่ม',
    joincompany: 'เข้าร่วมบริษัท',
    notification: 'การแจ้งเตือน',
    createnotification: 'สร้างการแจ้งเตือน',
    node: 'โหนด',
    downtime: 'หยุดทำงาน',
    downtimes: 'หยุดทำงาน',
    repeatstartdate: 'วันเริ่มต้น',
    repeatenddate: 'วันสิ้นสุด',
    optional: 'ไม่บังคับ',
    createplan: 'สร้างแพลน',
    today: 'วันนี้',
    starttime: 'เวลาเริ่มต้น',
    endtime: 'เวลาสิ้นสุด',
    chart: 'แผนภูมิ',
    reject: 'ปฏิเสธ',
    accept: 'ยอมรับ',
    filterbydaterange: 'เรียงตามช่วงวันที่',
    filterbymostrecent: 'เรียงตามล่าสุด',
    result: 'ผลลัพธ์',
    type: 'ชนิด',
    duration: 'ระยะเวลา',
    by: 'โดย',
    title: 'หัวข้อ',
    assigned: 'ที่ได้รับมอบหมาย',
    action: 'การจัดการ',
    track: 'ติดตาม',
    issue: 'ปัญหา',
    priority: 'ลำดับความสำคัญ',
    requestor: 'ผู้รีเควส',
    opened: 'เปิด',
    assignee: 'ผู้ได้รับมอบหมาย',
    root: 'ต้นตอ',
    cause: 'สาเหตุ',
    identified: 'ระบุ',
    defined: 'ที่กำหนดไว้',
    date: 'วันที่',
    resolve: 'แก้ไข',
    resolved: 'ถูกแก้ไขแล้ว',
    targetresolvedate: 'เป้าหมายวันที่จะแก้ไข',
    procurement: 'จัดซื้อจัดจ้าง',
    choose: 'เลือก',
    analyses: 'การวิเคราะห์',
    source: 'แหล่ง',
    selection: 'การเลือก',
    historical: 'ประวัติศาสตร์',
    mostrecent: 'ล่าสุด',
    regression: 'การถอยหลัง',
    trendline: 'เส้นแนวโน้ม',
    upperbound: 'ขอบเขตบน',
    lowerbound: 'ลดผูกพัน',
    preview: 'ภาพตัวอย่าง',
    period: 'ระยะเวลา',
    series: 'ชุด',
    axis: 'แกน',
    youhaveno: 'คุณไม่มี',
    code: 'รหัส',
    all: 'ทั้งหมด',
    upload: 'อัพโหลด',
    regressionanalysis: 'การวิเคราะห์การถดถอย',
    issuetracker: 'ติดตามปัญหา',
    labelsearch: 'ค้นหารายละเอียดการผลิต',
    gatewaysetup: 'การติดตั้งเกตเวย์',
    setupgateway: 'ติดตั้งเกตเวย์',
    radiomesh: 'เครือข่ายเมช',
    targetsetting: 'การตั้งค่าเป้าหมาย',
    updatetrackedissue: 'อัพเดทการติดตามปัญหา',
    shownewtrackedissue: 'แสดงการติดตามปัญหาใหม่',
    low: 'ต่ำ',
    mid: 'ปานกลาง',
    high: 'สูง',
    linear: 'เชิงเส้น',
    exponential: 'เอกซ์โพเนนเชียล (เลขชี้กำลัง)',
    logarithmic: 'ลอการิทึม (มาตราส่วนล็อก)',
    quadratic: 'ควอดราติก (สมการกำลังสอง)',
    polynomial: 'พอลลิโนเมียล (พหุนาม)',
    general: 'ทั่วไป',
    datataggingenrichment: 'การติดแท็กให้ข้อมูลสมบูรณ์มากขึ้น',
    hardware: 'ฮาร์ดแวร์',
    nodemanagement: 'การจัดการโหนด',
    createeditnotification: 'สร้างแก้ไขการแจ้งเตือน',
    conditions: 'เงื่อนไข',
    addsku: 'เพิ่ม SKU',
    editsku: 'แก้ไข SKU',
    setupagateway: 'ติดตั้งเกตเวย์',
    deviceserialnumber: 'หมายเลขซีเรียลของอุปกรณ์',
    repeatsweekly: 'ซ้ำรายสัปดาห์',
    repeatsdaily: 'ซ้ำรายวัน',
    repeatsmonthly: 'ซ้ำรายเดือน',
    repeatsannually: 'ซ้ำทุกปี',
    repeatsevery: 'ซ้ำทุกๆ',
    hourly: 'รายชั่วโมง',
    biweekly: 'ทุก 2 สัปดาห์',
    standardtimemanagement: 'การจัดการเวลามาตรฐาน',
    autoupdate: 'อัปเดตอัตโนมัติ',
    cycletime: 'รอบเวลา',
    notset: 'ไม่ได้ตั้งค่า',
    hourly: 'รายชั่วโมง',
    oeesku: 'OEE-SKU',
    skuassetstandardtime: 'เวลามาตรฐานของ SKU',
    permission: 'การอนุญาตการเข้าถึง',
    hardware: 'อุปกรณ์ฮาร์ดแวร์',
    assetmanagement: 'การจัดการสินทรัพย์',
    currentshift: 'กะทำงานปัจจุบัน',
    sum: 'สรุป',
    modifiedby: 'ระบุโดย',
    defaultoeeselection: 'ค่า OEE เริ่มต้น',
    operationshub: 'ศูนย์กลางโอเปอเรชั่น',
    productionachievement: 'ผลสัมฤทธิ์ในการผลิต',
    blockview: 'แสดงข้อมูลแบบกลุ่ม',
    assetview: 'แสดงรายละเอียดเครื่องจักร',
    planninghub: 'ศูนย์กลางการวางแผนผลิต',
    ciimpact: 'การปรับปรุงอย่างต่อเนื่องและผลลัพท์',
    impactsuite: 'เครื่องมือวัดผลตอบแทน',
    advancedanalytics: 'การวิเคราะห์ข้อมูลเชิงลึก',
    teamandorganization: 'ทีมงานและองค์กร',
    settingsandpreferences: 'ตั้งค่าและปรับลักษณะการใช้งาน',
    calendartime: 'เวลาปฏิทิน',
    scheduledproductiontime: 'เวลาในการผลิตที่กำหนด',
    grossoperatingtime: 'เวลาดำเนินการรวม',
    netoperatingtime: 'เวลาดำเนินการสุทธิ',
    editasset: 'แก้ไขเนื้อหา',
    assetid: 'รหัสสินทรัพย์',
    iotnodes: 'IoT โหนด',
    version: 'เวอร์ชัน',
    inputtype: 'ประเภทอินพุต',
    noisefilter: 'ตัวกรองสัญญาณรบกวน',
    minmaxfilter: 'ตัวกรองต่ำสุด/สูงสุด',
    scheduling: 'การจัดตารางเวลา',
    block: 'ปิดกั้น',
    goalsetting: 'เป้าหมาย OEE',
    vicepresidentpresident: "รองประธาน/ประธาน",
    executive: "ผู้บริหาร",
    plantmanagerdirector: "ผู้จัดการโรงงาน/ผู้อำนวยการ",
    productionmanager: "ผู้จัดการฝ่ายผลิต",
    productionsupervisor: "หัวหน้าฝ่ายผลิต",
    productionoperator: "พนักงานฝ่ายผลิต",
    engineeringmanager: "ผู้จัดการฝ่ายวิศวกรรม",
    processengineer: "วิศวกรกระบวนการ",
    cileanmanager: "ผู้จัดการ CI/ลีน",
    cileanengineer: "วิศวกร CI/ลีน",
    planningmanager: "ผู้จัดการฝ่ายวางแผน",
    plannerscheduler: "นักวางแผน/ผู้วางตารางงาน",
    maintenancemanager: "ผู้จัดการฝ่ายบำรุงรักษา",
    maintenanceengineer: "วิศวกรบำรุงรักษา",
    maintenancetechnician: "ช่างเทคนิคบำรุงรักษา",
    qualityinspector: "ผู้ตรวจสอบคุณภาพ",
    qualityengineer: "วิศวกรคุณภาพ",
    qualitymanager: "ผู้จัดการฝ่ายคุณภาพ",
    itengineer: "วิศวกรไอที",
    itmanager: "ผู้จัดการฝ่ายไอที",
    finance: "การเงิน",
    financemanager: "ผู้จัดการฝ่ายการเงิน",
    others: "อื่นๆ",
};
