/* eslint-disable */
import React from 'react';
import CONSTANTS from '../../Constants';
import './Arrow.scss';

export class Arrow extends React.Component {
    get offset() {
        const { cellHeight, cellPadding } = this.props;

        switch (this.props.data.offsetType) {
        case CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE:
            return {
                x1: -cellPadding / 2,
                y1: 0.5 * cellHeight,
                x2: -cellPadding / 2,
                y2: -0.5 * cellHeight,
            };
        case CONSTANTS.ARROWS.OFFSET.HORIZONTAL_LINE:
            return {
                x1: -(cellPadding / 2),
                y1: 0.5 * cellHeight,
                x2: cellPadding,
                y2: 0.5 * cellHeight,
            };
      // default:
      // return {
      //   x1: 0.6 * cellWidth,
      //   y1: 0.33 * cellHeight,
      //   x2: 0.1 * cellWidth,
      //   y2: 0.33 * cellHeight,
      // };
        }
    }

    get coords() {
        let { x1, y1, x2, y2 } = this.props.data;
        const { margin, cellPadding, cellHeight } = this.props;
        const { offset } = this;

        x1 += offset.x1;
        y1 += offset.y1;
        x2 += offset.x2;
        y2 += offset.y2;

        const translate = {
            x: Math.min(x1, x2) - margin.left,
            y: Math.min(y1, y2) - margin.top,
        };

        x1 = x1 - translate.x - margin.left;
        y1 = y1 - translate.y - margin.top;
        x2 = x2 - translate.x - margin.left;
        y2 = y2 - translate.y - margin.top;

        const source = {
            // dressing from top right corner, y attribute not necessary
            x: x1 + cellPadding * 1.5,
            y: y1,
        };
        const destination = {
            // dressing from bottom left corner
            x: x2 - cellPadding,
            y:
        (y2 > y1 ? y2 - cellHeight / 2 : y2 + cellHeight / 2 + cellPadding) -
        cellPadding / 2,
        };

        const p1 = `${source.x},${y1}`;
        const p2 = `${source.x},${destination.y}`;
        const p3 = `${destination.x},${destination.y}`;
        const p4 = `${destination.x},${y2}`;

        return { x1, y1, x2, y2, p1, p2, p3, p4, translate };
    }

    get path() {
        const { x1, y1, x2, y2, p1, p2, p3, p4 } = this.coords;

        if (
            this.props.data.offsetType === CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE ||
      (y1 === y2 && x2 > x1)
        ) {
            return `M${x1},${y1} L${x2},${y2}`;
        }

        return `M${x1},${y1} L${p1} L${p2} L${p3} L${p4} L${x2},${y2}`;
    }

    get width() {
        const {
            coords: { x2, x1 },
            props: { margin, cellPadding },
        } = this;
        return Math.abs(x2 - x1) + margin.left + margin.right + cellPadding;
    }

    get height() {
        const {
            coords: { y1, y2, p1, p2, p3, p4 },
            props: { margin, cellPadding },
        } = this;

        const yCoords = [p1, p2, p3, p4]
            .map((pairs) => +pairs.split(',')[1])
            .concat(y1, y2);
        return (
            Math.abs(Math.max(...yCoords) - Math.min(...yCoords)) +
      margin.top +
      margin.bottom +
      cellPadding
        );
    }

    render() {
        const { x1, y1, x2, y2, translate } = this.coords;
        const { margin } = this.props;
        const { arrowhead } = this.props.data;

        return x1 !== x2 || y1 !== y2 ? (
            <svg
                ref="svg"
                className="arrow"
                width={this.width}
                height={this.height}
                style={{
                    top: translate.y,
                    left: translate.x,
                    zIndex:
            this.props.data.offsetType === CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE
                ? 2000
                : 1000,
                }}
            >
                <defs>
                    <marker
                        id="arrowhead"
                        viewBox="0 0 60 60"
                        refX="50"
                        refY="30"
                        markerUnits="strokeWidth"
                        markerWidth="5"
                        markerHeight="5"
                        orient="auto"
                    >
                        <path d="M 0 0 L 60 30 L 0 60 z" fill="dimgray" />
                    </marker>
                </defs>
                <g ref="g" transform={`translate(${margin.left},${margin.top})`}>
                    <path
                        className="line"
                        d={this.path}
                        markerEnd={arrowhead ? 'url(#arrowhead)' : ''}
                    />
                    <circle className="point line-point" cx={x1} cy={y1} r={4} />
                    {this.props.data.offsetType ===
            CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE && (
                        <circle className="point line-point" cx={x2} cy={y2} r={4} />
                    )}
                </g>
            </svg>
        ) : null;
    }
}

Arrow.defaultProps = {
    data: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    },
    margin: {
        top: 25,
        left: 25,
        right: 25,
        bottom: 25,
    },
};
