import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: { main: "#206487" },
        secondary: { main: "#216487" },
        error: { main: "#BA1A1A" },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    display: "block",
                    height: "32px",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    color: "var(--secondary)",
                    textDecoration: "none",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: "12px",
                    paddingRight: "12px"
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 360,
            md: 1024,
            lg: 1440,
            xl: 1536,
        },
    }
})

export default theme
