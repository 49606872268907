import { Layout } from "antd"
import React from "react"
import "./NavTemplate.scss"
import Header from "../../../legacy/components/Layout/Header"
import ControlPanel from "../../../legacy/components/ControlPanel/ControlPanel"
import withUrlQuery from "../../../legacy/Wrappers/HOCs/withUrlQuery"
import NavSider from "./NavSider"

const NavTemplate = (props: Props) => {
    return <Layout className="layout-auth" id="layoutAuth">
        <Header/>
        <Layout>
            <NavSider/>
            <Layout.Content className="layout-auth__content">
                <ControlPanel/>
                {props.children}
            </Layout.Content>
        </Layout>
    </Layout>
}

interface Props {
    children: React.ReactNode
}

export default withUrlQuery(NavTemplate)
