import { createSelector } from 'reselect';

export const gatewayState = (appState) =>
    appState.gateway;

export const arrayGateways = createSelector(gatewayState, (v) => {
    const array = [];

    const gateways = v.gateways;

    for (const gateway_id in gateways) {
        if (gateways.hasOwnProperty(gateway_id)) {
            const item = gateways[Number(gateway_id)];

            array.push(item);
        }
    }

    return array;
});
