import img from '../../assets/images/demoAsset.jpeg';
import { TimeSeriesFactory } from '../utils/data';

export const dashboardData = {
    tiles: [
        {
            tile_id: 1,
            asset_id: 1,
            process_id: 1,
            original_process_id: 1,
            label: 'process 1',
            position_x: 0,
            position_y: 0,
        },
        {
            tile_id: 2,
            asset_id: 2,
            process_id: 2,
            original_process_id: 2,
            label: 'process 1',
            position_x: 0,
            position_y: 1,
        },
        {
            tile_id: 3,
            asset_id: 3,
            process_id: 3,
            original_process_id: 3,
            label: 'process 2',
            position_x: 1,
            position_y: 1,
        },
        {
            tile_id: 4,
            asset_id: 4,
            process_id: 4,
            original_process_id: 4,
            label: 'process 3',
            position_x: 2,
            position_y: 1,
        },
        {
            tile_id: 5,
            asset_id: 5,
            process_id: 5,
            original_process_id: 5,
            label: 'process 3',
            position_x: 2,
            position_y: 2,
        },
        {
            tile_id: 6,
            asset_id: 6,
            process_id: 6,
            original_process_id: 6,
            label: 'process 3',
            position_x: 2,
            position_y: 0,
        },
        {
            tile_id: 7,
            asset_id: 7,
            process_id: 7,
            original_process_id: 7,
            label: 'process 4',
            position_x: 3,
            position_y: 1,
        },
        {
            tile_id: 8,
            asset_id: 8,
            process_id: 8,
            original_process_id: 8,
            label: 'process 5',
            position_x: 4,
            position_y: 1,
        },
        {
            tile_id: 9,
            asset_id: 9,
            process_id: 9,
            original_process_id: 9,
            label: 'process 5',
            position_x: 4,
            position_y: 2,
        },
        {
            tile_id: 10,
            asset_id: 10,
            process_id: 10,
            original_process_id: 10,
            label: 'process 5',
            position_x: 4,
            position_y: 0,
        },
        {
            tile_id: 11,
            asset_id: 11,
            process_id: 11,
            original_process_id: 11,
            label: 'process 6',
            position_x: 5,
            position_y: 1,
        },
    ],
    processes: [
        {
            id: 1,
            process_name: 'drilling',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 2,
            process_name: 'baking',
            process_type: 'discrete',
            cluster: null,
            border_color: 'black',
        },
        {
            id: 3,
            process_name: 'roasting',
            process_type: 'discrete',
            cluster: null,
            border_color: 'red',
        },
        {
            id: 4,
            process_name: 'mixing',
            process_type: 'discrete',
            cluster: null,
            border_color: 'green',
        },
        {
            id: 5,
            process_name: 'slicing',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 6,
            process_name: 'grinding',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 7,
            process_name: 'boiling',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 8,
            process_name: 'steaming',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 9,
            process_name: 'frying',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
        {
            id: 10,
            process_name: 'dicing',
            process_type: 'continuous',
            cluster: null,
            border_color: 'blue',
        },
    ],
    assets: [
        {
            id: 1,
            asset_name: 'asset 1',
            target: null,
            target_frequency: null,
            target_duration: null,
            target_start: '2017-11-02T09:10:35.000Z',
            cycle_time: null,
        },
        {
            id: 2,
            asset_name: 'asset 2',
            target: null,
            target_frequency: null,
            target_duration: null,
            target_start: '2017-11-02T09:10:35.000Z',
            cycle_time: null,
        },
        {
            id: 3,
            asset_name: 'asset 3',
            target: null,
            target_frequency: null,
            target_duration: null,
            target_start: '2017-11-02T09:10:35.000Z',
            cycle_time: null,
        },
        {
            id: 4,
            asset_name: 'asset 4',
            target: null,
            target_frequency: null,
            target_duration: null,
            target_start: '2017-11-02T09:10:35.000Z',
            cycle_time: null,
        },
        {
            id: 5,
            asset_name: 'asset 5',
            target: null,
            target_frequency: null,
            target_duration: null,
            target_start: '2017-11-02T09:10:35.000Z',
            cycle_time: null,
        },
    ],
    devices: {
        count: 6,
        rows: [
            {
                id: 4,
                serial_number: 'sn4444444444',
                model_id: 2,
                mac_address: '0013a20041020m1b',
                entity_id: 2,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes444-444-444',
                Metadatum: {
                    id: 1,
                    chart_order: 1,
                    channel: '1',
                    operating_voltage: 24,
                    sampling_rate: 60,
                    sensor: 'motion',
                    sensor_img_id: 1,
                    asset_id: 1,
                    original_asset_id: 1,
                    asset_img_id: 2,
                    attribute_name: 'location',
                    multiplication_factor: 1,
                    input_output: 'output',
                    mode: 'analog',
                    entity_id: 1,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: {
                        id: 2,
                        image_url: '1-asset.url',
                    },
                    sensor_img: {
                        id: 1,
                        image_url: '4-sensor.url',
                    },
                },
            },
            {
                id: 5,
                serial_number: 'sn55555555555',
                model_id: 2,
                mac_address: '0013a20041020m2a',
                entity_id: 2,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes555-555-555',
                Metadatum: {
                    id: 2,
                    chart_order: 1,
                    channel: '1',
                    operating_voltage: 12,
                    sampling_rate: 60,
                    sensor: 'PIR',
                    sensor_img_id: 3,
                    asset_id: 1,
                    original_asset_id: 2,
                    asset_img_id: 4,
                    attribute_name: 'rate',
                    multiplication_factor: 1,
                    input_output: 'output',
                    mode: 'count',
                    entity_id: 1,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: {
                        id: 4,
                        image_url: '2-asset.url',
                    },
                    sensor_img: {
                        id: 3,
                        image_url: '5-sensor.url',
                    },
                },
            },
            {
                id: 6,
                serial_number: 'sn66666666666',
                model_id: 2,
                mac_address: '0013a20041020m3a',
                entity_id: 3,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes666-666-666',
                Metadatum: {
                    id: 3,
                    chart_order: 1,
                    channel: '1',
                    operating_voltage: 24,
                    sampling_rate: 60,
                    sensor: 'Lightgate',
                    sensor_img_id: 5,
                    asset_id: 3,
                    original_asset_id: 3,
                    asset_img_id: 6,
                    attribute_name: 'count',
                    multiplication_factor: 1,
                    input_output: 'input',
                    mode: 'count',
                    entity_id: 1,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: {
                        id: 6,
                        image_url: '3-asset.url',
                    },
                    sensor_img: {
                        id: 5,
                        image_url: '6-sensor.url',
                    },
                },
            },
            {
                id: 7,
                serial_number: 'sn7777777777',
                model_id: 2,
                mac_address: 'ma7777777777',
                entity_id: 3,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes777-777-777',
                Metadatum: {
                    id: 4,
                    chart_order: 2,
                    channel: '1',
                    operating_voltage: 12,
                    sampling_rate: 60,
                    sensor: 'noise',
                    sensor_img_id: 7,
                    asset_id: 3,
                    original_asset_id: 3,
                    asset_img_id: 6,
                    attribute_name: 'sound',
                    multiplication_factor: 1,
                    input_output: 'output',
                    mode: 'analog',
                    entity_id: 1,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: {
                        id: 6,
                        image_url: '3-asset.url',
                    },
                    sensor_img: {
                        id: 7,
                        image_url: '7-sensor.url',
                    },
                },
            },
            {
                id: 8,
                serial_number: 'sn8888888888',
                model_id: 2,
                mac_address: 'ma8888888888',
                entity_id: 4,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes888-888-888',
                Metadatum: {
                    id: 5,
                    chart_order: 1,
                    channel: '1',
                    operating_voltage: 24,
                    sampling_rate: 60,
                    sensor: 'vibration',
                    sensor_img_id: 9,
                    asset_id: 4,
                    original_asset_id: 4,
                    asset_img_id: 8,
                    attribute_name: 'vibration',
                    multiplication_factor: 1,
                    input_output: 'input',
                    mode: 'analog',
                    entity_id: 4,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: {
                        id: 8,
                        image_url: '4-asset.url',
                    },
                    sensor_img: {
                        id: 9,
                        image_url: '8-sensor.url',
                    },
                },
            },
            {
                id: 9,
                serial_number: 'sn9999999999',
                model_id: 2,
                mac_address: 'ma9999999999',
                entity_id: 4,
                channel_mask: '0xFFFF',
                network_id: '0xFFFF',
                destination_address: '0xFFFF',
                aes_key: 'aes999-999-999',
                Metadatum: {
                    id: 6,
                    chart_order: 1,
                    channel: '1',
                    operating_voltage: 12,
                    sampling_rate: 60,
                    sensor: 'lightgate',
                    sensor_img_id: 10,
                    asset_id: 5,
                    original_asset_id: 5,
                    asset_img_id: null,
                    attribute_name: 'lightgate',
                    multiplication_factor: 1,
                    input_output: 'input',
                    mode: 'count',
                    entity_id: 4,
                    units: null,
                    unit_conversion: null,
                    analog_conversion: null,
                    chart_type: null,
                    chart_y_axis_label: null,
                    chart_y_axis_interval: null,
                    asset_img: null,
                    sensor_img: {
                        id: 10,
                        image_url: '9-sensor.url',
                    },
                },
            },
        ],
    },
    connections: [
        {
            connection_id: 1,
            entity_id: 2,
            from_process: 1,
            to_process: 3,
        },
        {
            connection_id: 2,
            entity_id: 3,
            from_process: 2,
            to_process: 3,
        },
        {
            connection_id: 3,
            entity_id: 4,
            from_process: 3,
            to_process: 4,
        },
        {
            connection_id: 4,
            entity_id: 4,
            from_process: 4,
            to_process: 7,
        },
        {
            connection_id: 5,
            entity_id: 4,
            from_process: 7,
            to_process: 8,
        },
        {
            connection_id: 6,
            entity_id: 4,
            from_process: 8,
            to_process: 11,
        },
        {
            connection_id: 7,
            entity_id: 4,
            from_process: 9,
            to_process: 11,
        },
        {
            connection_id: 8,
            entity_id: 4,
            from_process: 10,
            to_process: 11,
        },
    ],
};

export const barChartData = (() => {
    const data = [
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:15:00.000Z',
            sku: 7,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:20:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1419,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:25:00.000Z',
            sku: 7,
            mode: '1a',
            val: 683,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:30:00.000Z',
            sku: 7,
            mode: '1a',
            val: 519,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:35:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1311,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:40:00.000Z',
            sku: 7,
            mode: '1a',
            val: 272,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:45:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1204,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:50:00.000Z',
            sku: 7,
            mode: '1a',
            val: 898,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T08:55:00.000Z',
            sku: 7,
            mode: '1a',
            val: 171,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:00:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1461,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:05:00.000Z',
            sku: 7,
            mode: '1a',
            val: 470,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:10:00.000Z',
            sku: 7,
            mode: '1a',
            val: 894,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:15:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1208,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:20:00.000Z',
            sku: 7,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:25:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1419,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:30:00.000Z',
            sku: 7,
            mode: '1a',
            val: 683,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:35:00.000Z',
            sku: 7,
            mode: '1a',
            val: 519,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:40:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1311,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:45:00.000Z',
            sku: 7,
            mode: '1a',
            val: 272,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:50:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1204,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T09:55:00.000Z',
            sku: 7,
            mode: '1a',
            val: 898,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:00:00.000Z',
            sku: 7,
            mode: '1a',
            val: 171,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:05:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1461,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:10:00.000Z',
            sku: 7,
            mode: '1a',
            val: 470,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:15:00.000Z',
            sku: 7,
            mode: '1a',
            val: 894,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:20:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1208,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:25:00.000Z',
            sku: 7,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:30:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1419,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:35:00.000Z',
            sku: 7,
            mode: '1a',
            val: 683,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:40:00.000Z',
            sku: 7,
            mode: '1a',
            val: 519,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:45:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1311,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:50:00.000Z',
            sku: 7,
            mode: '1a',
            val: 272,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T10:55:00.000Z',
            sku: 7,
            mode: '1a',
            val: 1204,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T11:00:00.000Z',
            sku: 7,
            mode: '1a',
            val: 898,
        },
        {
            dmac: 'dmac1',
            time: '2018-04-26T11:05:00.000Z',
            sku: 7,
            mode: '1a',
            val: 171,
        },
    ];
    data.forEach((d) => {
        d.time = new Date(d.time);
        d.int = 300000;
    });
    return data.sort((a, b) => a.time - b.time);
})();

export const stateChartData = (() => {
    const data = [
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T09:35:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T09:40:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T09:45:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T09:50:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T09:55:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:00:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:05:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'on' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:10:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:15:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:20:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:25:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:30:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:35:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:40:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:45:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:50:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T10:55:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:00:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:05:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:10:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'on' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:15:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:20:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:25:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:30:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:35:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:40:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:45:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:50:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T11:55:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:00:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:05:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'blink' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:10:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:15:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'off' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'on' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:20:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'blink' },
                { color: 'green', state: 'off' },
            ],
        },
        {
            dmac: '0013a2004device3',
            time: '2018-04-26T12:25:00.000Z',
            sku: 7,
            mode: '2b',
            val: [
                { color: 'red', state: 'blink' },
                { color: 'yellow', state: 'off' },
                { color: 'green', state: 'blink' },
            ],
        },
    ];
    data.forEach((d) => (d.time = new Date(d.time)));
    return data.sort((a, b) => a.time - b.time);
})();

export const dummySummaryTile = {
    label: 'Summary Production Line A',
    data: {
        final_effective: 32.9,
        loading: 100,
        availability: 64.4,
        performance: 51.1,
        quality: 100,
    },
};

export const demoMachineData = (() => {
    const data = [
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:27:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:28:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 167,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:29:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:30:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 123,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:31:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 14,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:32:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:33:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:34:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:35:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 128,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:36:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:37:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 165,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:38:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 170,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:39:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 166,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:40:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 176,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:41:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 57,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:42:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 2,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:43:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:44:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:45:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 0,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:46:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 3,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:47:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 49,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:48:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 160,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:49:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 48,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:50:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 124,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:51:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:52:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 162,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:53:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 161,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:54:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:55:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 165,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:56:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:57:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 155,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:58:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 163,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 13:59:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 141,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:00:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 166,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:01:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 161,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:02:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 174,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:03:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 170,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:04:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 180,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:05:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 204,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:06:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 192,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:07:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 179,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:08:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 175,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:09:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 167,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:10:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 170,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:11:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 187,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:12:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 186,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:13:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 165,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:14:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 183,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:15:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 185,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:16:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 136,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:17:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 169,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:18:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 165,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:19:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 207,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:20:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 192,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:21:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 203,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:22:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 188,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:23:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 207,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:24:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 187,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:25:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 177,
        },
        {
            dmac: 'dd000139967aaa03',
            time: 'Thu Feb 14 2019 14:26:00 GMT+0800 (Singapore Standard Time)',
            int: 60000,
            mode: '1a',
            val: 182,
        },
    ];
    data.forEach((d) => {
        d.time = new Date(d.time);
    });
    return data;
})();

export const dummyAssetTile = {
    asset_id: 2,
    chart: true,
    position_x: 0,
    position_y: 0,
    status: 'green',
    asset: {
        primary: {
            chart_type: 'column',
            data: TimeSeriesFactory(demoMachineData),
            mode: '1a',
            // aggregate: 'Total Count: 8334 pcs',
            units: 'pcs',
        },
        asset_id: 2,
        // primaryChartType: 'column',
        // primaryChartData: demoMachineData,
        asset_img: { signedUrl: img },
        // primaryHasData: true,
        asset_name: 'Machine 01',
        labels: [],
    },
};
