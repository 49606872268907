export const pt = {
    notes: 'Notas',
    load: 'Buscar',
    export: 'Exportar',
    unutilised: 'Un-utilizados',
    breakdowns: 'Separação',
    threshold: 'Limite',
    oeepreview: 'visualização OEE',
    heatmappreview: 'Heatmap Pré-Visualização',
    oeecolors: 'cores OEE',
    color: 'cor',
    reset: 'Redefinir',
    theme: 'Tema',
    defaultview: 'Visualização padrão',
    defaulttimeselection: 'selecção de tempo padrão',
    zoomlevel: 'Nível de zoom',
    entity: 'Entidade',
    preferences: 'preferências',
    custom: 'personalizadas',
    enteryourmailtoreset: 'Digite seu e-mail para redefinir',
    goback: 'Volte',
    resetpassword: 'redefinição de senha',
    logout: 'Sair',
    ownersemail: 'Proprietários Email',
    optional: 'Opcional',
    industry: 'Indústria',
    timezone: 'Fuso horário',
    nocoderequesttojoin: 'No Code pedido de adesão',
    enterreferralcodeinstead: 'Introduzir código de referência em vez',
    setup: 'Configuração',
    company: 'Companhia',
    referralcode: 'Código de Referencia',
    submit: 'Enviar',
    summary: 'Resumo',
    language: 'Língua',
    login: 'Conecte-se',
    signin: 'assinar em',
    signup: 'inscrever-se',
    username: 'Nome do usuário',
    password: 'Senha',
    forgotpassword: 'Esqueceu a senha?',
    rememberme: 'Lembre de mim',
    register: 'Registro',
    registration: 'Cadastro',
    firstname: 'Primeiro nome',
    lastname: 'Último nome',
    email: 'O email',
    mobile: 'Móvel',
    country: 'País',
    contactnumber: 'Número de contato',
    appointment: 'Compromisso',
    role: 'Função',
    admin: 'administrador',
    owner: 'proprietário',
    editor: 'editor',
    viewer: 'espectador',
    confirmpassword: 'Confirme a Senha',
    iacceptthe: 'Eu aceito o',
    useragreement: 'Termo de Acordo do Usuário',
    alreadyregisteredlogin: 'Já registrado? Conecte-se',
    softwareasaservicetermsofuse: 'Software-as-a-Service Termos de Uso',
    dashboard: 'painel de controle',
    streaming: 'Transmissão',
    resolution: 'Resolução',
    seconds: 'segundos',
    second: 'segundo',
    minutes: 'minutos',
    minute: 'minuto',
    hours: 'horas',
    hour: 'Hora',
    weeks: 'semanas',
    week: 'Semana',
    months: 'meses',
    month: 'mês',
    quarters: 'alojamento',
    quarter: 'trimestre',
    years: 'anos',
    year: 'ano',
    from: 'De',
    to: 'Para',
    start: 'começar',
    end: 'Fim',
    display: 'Exibição',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Séries Temporais',
    gateway: 'Porta de entrada',
    statusasof: 'Situação em',
    active: 'ativo',
    offline: 'desligada',
    mergeasonemachine: 'Fundir como uma máquina',
    drawarrowstoformprocessmap: 'Desenhar setas para mapear processo formulário',
    openblockeditor: 'editor bloco aberto',
    createnewtile: 'Criar Nova Tile',
    summarytile: 'azulejo resumo',
    selectablockthennameyoursummarytile:
    'Selecione um bloco, em seguida, nomeie sua telha resumo',
    name: 'Nome',
    blockselector: 'seletor de bloco',
    asset: 'De ativos',
    therearenolabelstaggedinthisperiod:
    'Não existem etiquetas marcadas nesse período',
    standardtimes: 'Horário padrão (s)',
    total: 'Total',
    last: 'Último',
    oeeinput: 'input OEE',
    chartcolor: 'carta de cor',
    issueeditor: 'editor de Edição',
    issuemangement: 'Mangement questão',
    cumulativeoutput: 'produção acumulada',
    tag: 'etiqueta',
    label: 'Rótulo',
    valueval: 'Valor (Val)',
    time: 'Tempo',
    januaryjan: 'Janeiro (Jan)',
    februaryfeb: 'Fevereiro (fevereiro)',
    marchmar: 'Março (Mar)',
    aprilapr: 'Abril (abril)',
    may: 'Posso',
    junejun: 'Junho (Jun)',
    julyjul: 'Julho (julho)',
    augustaug: 'Agosto (agosto)',
    septembersep: 'Setembro (Set)',
    octoberoct: 'Outubro (outubro)',
    novembernov: 'Novembro (novembro)',
    decemberdec: 'Dezembro (Dec)',
    day: 'Dia',
    days: 'Dias',
    mondaymonm: 'Segunda-feira (Mon) M',
    tuesdaytuet: 'Terça-feira (terça-feira) T',
    wednesdaywedw: 'Quarta-feira (quarta-feira) W',
    thursdaythut: 'Quinta-feira (qui) T',
    fridayfrif: 'Sexta-feira (sex) F',
    saturdaysats: 'Sábado (Sat) S',
    sundaysuns: 'Domingo (Sun) S',
    edit: 'Editar',
    add: 'Adicionar',
    new: 'Novo',
    create: 'Crio',
    search: 'Procurar',
    close: 'Perto',
    cancel: 'Cancelar',
    save: 'Salve ',
    saved: 'Salvou',
    clear: 'Claro',
    enter: 'Entrar',
    navigator: 'Navegador',
    mandatoryfields: 'Campos obrigatórios',
    select: 'selecionar',
    merge: 'mesclar',
    delete: 'Excluir',
    done: 'Feito',
    filter: 'Filtro',
    table: 'Tabela',
    columns: 'colunas',
    rows: 'linhas',
    cell: 'célula',
    page: 'Página',
    previous: 'Anterior',
    next: 'Próximo',
    serialnumber: 'Número de série',
    manuallyinputserialnumber: 'Número de série de entrada manualmente',
    updateasset: 'atualização de ativos',
    machine: 'Máquina',
    brand: 'marca',
    model: 'Modelo',
    nodes: 'Nodes',
    fusions: 'Fusions',
    chartorder: 'Gráfico Order',
    primary: 'primário',
    adddevicechannel: 'Adicionar dispositivo - Canal',
    pushratesec: 'Empurrar Taxa (s)',
    voltagev: 'Voltagem (V)',
    sensor: 'Sensor',
    mode: 'Modo',
    digitalcount: 'Conde digital',
    digitalstate: 'State digital',
    analogvoltage: 'Tensão analógica',
    charttitle: 'Título do gráfico',
    unitmeasure: 'Unidade de medida',
    attribute: 'Atributo',
    autocycletime: 'O tempo de ciclo Auto',
    unittransform: 'unidade Transform',
    leaveblankifnotapplicable: 'Deixe em branco se não for aplicável',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Os campos obrigatórios (em branco licença se o canal não está em uso)',
    fusedchart: 'Fused Gráfico',
    fusionname: 'Nome de fusão',
    charttype: 'Tipo de Gráfico',
    column: 'Coluna',
    line: 'Linha',
    state: 'Estado',
    expression: 'Expressão',
    combination: 'Combinação',
    currentcharts: 'Gráficos atuais',
    expressionconstructor: 'Construtor Expression',
    validoperators: 'operadores válidos',
    leaveblanktokeepexistingexpression:
    'Deixe em branco para manter expressão existente',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Overall Equipment Effectiveness',
    legend: 'lenda',
    loading: 'Carregando',
    availability: 'Disponibilidade',
    performance: 'atuação',
    quality: 'Qualidade',
    unscheduled: 'Sem marcação',
    planneddowntime: 'tempo de inatividade planejado',
    changeoversetup: 'Mudança / Configuração',
    breakdown: 'Demolir',
    unutilized: 'Un-utilizados',
    speedloss: 'Perda de velocidade',
    minorstops: 'menores Stops',
    rework: 'Retrabalho',
    rejectscrap: 'Rejeitar / Scrap',
    nodata: 'não há dados',
    blockeditor: 'editor de bloco',
    sort: 'Ordenar',
    logicblocks: 'blocos lógicos',
    sequence: 'Seqüência',
    and: 'E',
    or: 'Ou',
    utilizationheatmap: 'Heatmap utilização',
    startdate: 'Data de início',
    enddate: 'Data final',
    daily: 'Diariamente',
    weekly: 'Semanal',
    monthly: 'Por mês',
    quarterly: 'Trimestral',
    yearly: 'Anual',
    trendanalysis: 'Análise de tendências',
    metricselection: 'Seleção Metric',
    metric: 'métrico',
    data: 'Dados',
    target: 'Alvo',
    periodselection: 'Seleção período',
    span: 'Período',
    every: 'Cada',
    repeat: 'Repetir',
    repeats: 'repete',
    addsingle: 'Adicionar Individual',
    paretoanalysis: 'Análise Pareto',
    calendartime: 'tempo de calendário',
    scheduledtime: 'Horário marcado',
    operatingtime: 'Tempo operacional',
    netruntime: 'tempo de execução Net',
    effective: 'Eficaz',
    loadingloss: 'Carregando Loss',
    availabilityloss: 'Perda disponibilidade',
    performanceloss: 'Perda de desempenho',
    qualityloss: 'Perda de qualidade',
    notagsinthiscategory: 'Nenhuma tag nesta categoria',
    overall: 'No geral',
    tagged: 'Tagged',
    occurrence: 'Ocorrência',
    skumanagement: 'Gestão SKU',
    category: 'Categoria',
    skuname: 'Nome SKU',
    skucode: 'Código SKU',
    unitofmeasurementuom: 'Unidade de Medição (UOM)',
    description: 'Descrição',
    csvupload: 'CSV Carregar',
    template: 'Modelo',
    notifications: 'notificações',
    newnotification: 'nova notificação',
    monitoring: 'Monitorização',
    topic: 'Tópico',
    frequency: 'Frequência',
    recipients: 'destinatários',
    notifyby: 'Notificar por',
    sms: 'SMS',
    alert: 'Alerta',
    report: 'Relatório',
    greaterthan: 'Maior que',
    lessthan: 'Menor que',
    greaterthanorequal: 'Maior ou igual',
    lessthanorequal: 'Menor ou igual',
    equal: 'Igual',
    range: 'Alcance',
    continuousduration: 'Duração contínua',
    usermanagement: 'Gerenciamento de usuários',
    user: 'Do utilizador',
    users: 'Comercial',
    invitenew: 'Convidar Novo',
    status: 'estado',
    resend: 'Reenviar',
    revoke: 'Revogar',
    invite: 'Convite',
    invitees: 'Convidados',
    addinviteesbypressingenterorclickingonthesign:
    "Adicionar convidados pressionando Enter ou clicando no sinal '+'",
    schedules: 'horários',
    shift: 'Mudança',
    profile: 'Perfil',
    changepassword: 'Mudar senha',
    deleteaccount: 'Deletar conta',
    userguide: 'Guia de usuario',
    overviewandbasiccontrol: 'Descrições e Controle Básico',
    rangeandresolution: 'Gama e resolução',
    editingoptions: 'editar opções',
    mergetiles: 'telhas de mesclagem',
    detailedassetview: 'vista de ativos detalhado',
    assetoverview: 'visão geral de ativos',
    summarystatistics: 'Resumo Estatísticas',
    dataanalysis: 'Análise de dados',
    oeeanoverview: 'OEE uma visão geral',
    oeetoggle: 'OEE alternância',
    addingamachine: 'Adicionando uma máquina',
    nodeconfiguration: 'configuração do nó',
    deleteupdateassets: 'ativos Atualizar Excluir',
    oeesummarytile: 'telha resumo OEE',
    oeetags: 'OEE Etiquetas',
    issuemanagement: 'gerenciamento de problemas',
    labelsandfiltering: 'Etiquetas e filtragem',
    useraccess: 'Acesso do usuário',
    group: 'Grupo',
    groups: 'grupos',
    joincompany: 'Junte-se a empresa',
    notification: 'Notificação',
    createnotification: 'Criar notificação',
    node: 'Nó',
    downtime: 'Tempo de inatividade',
    downtimes: 'downtimes',
    repeatstartdate: 'Repita Data de Início',
    repeatenddate: 'Data de Término Repita',
    optional: 'Opcional',
    createplan: 'Criar Plano',
    today: 'Hoje',
    starttime: 'start Time',
    endtime: 'Fim do tempo',
    chart: 'Gráfico',
    reject: 'Rejeitar',
    accept: 'Aceitar',
    filterbydaterange: 'filtro por período',
    filterbymostrecent: 'filtro por mais recentes',
    result: 'Resultado',
    type: 'Tipo',
    duration: 'Duração',
    by: 'de',
    title: 'Título',
    assigned: 'atribuído',
    action: 'Açao',
    track: 'rastrear',
    issue: 'Questão',
    priority: 'Prioridade',
    requestor: 'solicitante',
    opened: 'aberto',
    assignee: 'cessionário',
    root: 'raiz',
    cause: 'Causa',
    identified: 'Identificado',
    defined: 'Definiram',
    date: 'encontro',
    resolve: 'resolver',
    resolved: 'resolvido',
    targetresolvedate: 'data de determinação alvo',
    procurement: 'Compras',
    choose: 'Escolher',
    analyses: 'análises',
    source: 'Fonte',
    selection: 'Seleção',
    historical: 'Histórico',
    mostrecent: 'Mais recente',
    regression: 'Regressão',
    trendline: 'linha de tendência',
    upperbound: 'limite superior',
    lowerbound: 'limite inferior',
    preview: 'visualização',
    period: 'período',
    series: 'Series',
    axis: 'eixo',
    youhaveno: 'você não tem',
    code: 'Código',
    all: 'Tudo',
    upload: 'Envio',
    regressionanalysis: 'Análise de regressão',
    issuetracker: 'issue tracker',
    labelsearch: 'etiqueta Pesquisa',
    gatewaysetup: 'Configuração de gateway',
    setupgateway: 'gateway de Setup',
    radiomesh: 'mesh de rádio',
    targetsetting: 'definição de metas',
    updatetrackedissue: 'atualização questão rastreado',
    shownewtrackedissue: 'mostrar nova edição tracked',
    low: 'baixo',
    mid: 'médio',
    high: 'Alto',
    linear: 'linear',
    exponential: 'exponencial',
    logarithmic: 'logarítmica',
    quadratic: 'quadrático',
    polynomial: 'polinomial',
    general: 'Geral',
    datataggingenrichment: 'Dados Tagging Enriquecimento',
    hardware: 'ferragens',
    nodemanagement: 'gerenciamento de nó',
    createeditnotification: 'Criar notificação de edição',
    conditions: 'condições',
    addsku: 'adicionar SKU',
    editsku: 'editar SKU',
    setupagateway: 'gateway de configurar um',
    deviceserialnumber: 'número de série do dispositivo',
    repeatsweekly: 'repete-se semanalmente',
    repeatsdaily: 'repete diariamente',
    repeatsmonthly: 'repete-se mensalmente',
    repeatsannually: 'repete anualmente',
    repeatsevery: 'repete a cada',
    hourly: 'de hora em hora',
    biweekly: 'quinzenal',
};
