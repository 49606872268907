import type React from "react"
import { useEffect, useRef } from "react"

const useDidUpdateEffect = (func: React.EffectCallback, deps: React.DependencyList | undefined) => {
    const didMount = useRef(false)

    useEffect(() => {
        let cleanup: any
        if (didMount.current) {
            cleanup = func()
        } else didMount.current = true

        return () => cleanup?.()
    }, deps)
}

export default useDidUpdateEffect
