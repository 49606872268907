import { LoaderConstants as K } from './Loader.constants';

export const startLoading = () => {
    return {
        type: K.ACTIONS.START_LOADING,
    };
};

export const stopLoading = () => {
    return {
        type: K.ACTIONS.STOP_LOADING,
    };
};
