
import { call, put, takeLatest } from 'redux-saga/effects';
import { TargetConstants as K } from './Target.constant';
import { getMapFromArr } from '../../../legacy/utils/helpers';

// MODEL
import { Target } from '../../../legacy/models';


// SERVICE
import {
    api_getEntityTargets,
    api_createTarget,
    api_updateTarget,
    api_deleteTarget,
    api_bulkCreateTargets,
    api_getAssetTargets,
    api_createAssetShiftTarget,
    api_updateAssetShiftTarget,
    api_deleteAssetShiftTarget,
} from './Target.service';

// ACTION
import {
    addTargetsResource,
    removeTargetsResource,
    setTargetsResource,
} from './Target.action';

import { errorFlash } from '../../../legacy/components/Flash';

function* handleFetchEntityTargets(action) {
    try {
        const response = yield call(api_getEntityTargets, action.payload);
        const targets = response.map((t) => new Target(t));
        const targetsResource = getMapFromArr(targets, 'target_id');
        yield put(setTargetsResource(targetsResource));

        if (action.callback) {
            action.callback(targets);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchEntityTargetsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ENTITY_TARGETS_REQUEST,
        handleFetchEntityTargets
    );
}

function* handleFetchAssetTargets(action) {
    try {
        const { asset_id, query } = action.payload;
        const response = yield call(api_getAssetTargets, asset_id, query);
        const targets = response.map((t) => new Target(t));
        const targetsResource = getMapFromArr(targets, 'target_id');
        yield put(setTargetsResource(targetsResource));

        if (action.callback) {
            action.callback(targets);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getchAssetTargetsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_TARGETS_REQUEST,
        handleFetchAssetTargets
    );
}

function* handleCreateTarget(action) {
    try {
        const response = yield call(api_createTarget, action.payload.target);
        const target = new Target(response);
        yield put(addTargetsResource({ [target.target_id]: target }));

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createTargetSaga() {
    yield takeLatest(K.ACTIONS.CREATE_TARGET_REQUEST, handleCreateTarget);
}

function* handleBulkCreateTargets(action) {
    try {
        const { targets } = action.payload;

        if (targets.length) {
            const response = yield call(api_bulkCreateTargets, { targets });

            const createdTargets = getMapFromArr(
                response.map((t) => new Target(t)),
                'target_id'
            );

            yield put(addTargetsResource(createdTargets));

            if (action.callback) {
                action.callback(response);
            }
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* bulkCreateTargetsSaga() {
    yield takeLatest(
        K.ACTIONS.BULK_CREATE_TARGETS_REQUEST,
        handleBulkCreateTargets
    );
}

// UDPATE TARGET
function* handleUpdateTarget(action) {
    try {
        const response = yield call(api_updateTarget, action.payload.target);
        const target = new Target(response);
        yield put(addTargetsResource({ [target.target_id]: target }));

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateTargetSaga() {
    yield takeLatest(K.ACTIONS.UPDATE_TARGET_REQUEST, handleUpdateTarget);
}

function* handleDeleteTarget(action) {
    try {
        const response = yield call(api_deleteTarget, action.payload.target_id);
        yield put(removeTargetsResource([action.payload.target_id]));

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteTargetSaga() {
    yield takeLatest(K.ACTIONS.DELETE_TARGET_REQUEST, handleDeleteTarget);
}

function* handleCreateAssetShiftTarget(action) {
    try {
        const { entity_id, asset_id, shift_id, target } = action.payload;

        yield call(
            api_createAssetShiftTarget,
            entity_id,
            asset_id,
            shift_id,
            target
        );

        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createAssetShiftTargetSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET_SHIFT_TARGET_REQUEST,
        handleCreateAssetShiftTarget
    );
}

function* handleUpdateAssetShiftTarget(action) {
    try {
        const { entity_id, asset_id, shift_id, target_id, target } = action.payload;

        yield call(
            api_updateAssetShiftTarget,
            entity_id,
            asset_id,
            shift_id,
            target_id,
            target
        );

        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetShiftTargetSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_SHIFT_TARGET_REQUEST,
        handleUpdateAssetShiftTarget
    );
}

function* handleDeleteAssetShiftTarget(action) {
    try {
        const { entity_id, asset_id, shift_id, target_id } = action.payload;

        yield call(
            api_deleteAssetShiftTarget,
            entity_id,
            asset_id,
            shift_id,
            target_id
        );

        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteAssetShiftTargetSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_ASSET_SHIFT_TARGET_REQUEST,
        handleDeleteAssetShiftTarget
    );
}
