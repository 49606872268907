import { updateStoreRedux } from '../../../legacy/utils/redux';
import { EntityConstants as K } from './Entity.constants';
import { getMapFromArr, toMappedArrays } from '../../../legacy/utils/helpers';
import { values } from 'lodash';

const initialReducer = {
    active: -1,
    entities: {},
    pc_directory: {},
};

export const entitiesReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.SET_ENTITIES_STATE) {
        const { payload } = action;
        const entities = getMapFromArr(payload.entities, 'entity_id');

        const pc_directory = toMappedArrays(
            payload.entities,
            (b) => b.parent_entity_id,
            (b) => b.entity_id
        );

        return updateStoreRedux(state, {
            entities,
            pc_directory,
        });
    }

    if (action.type === K.ACTIONS.ADD_ENTITIES_RESOURCE) {
        const { payload } = action;
        const entities = { ...state.entities, ...payload.entities };

        const pc_directory = toMappedArrays(
            values(entities),
            (b) => b.parent_entity_id,
            (b) => b.entity_id
        );

        return updateStoreRedux(state, {
            entities,
            pc_directory,
        });
    }

    if (action.type === K.ACTIONS.SET_ACTIVE_ENTITY) {
        return updateStoreRedux(state, {
            active: action.payload.active,
        });
    }

    return updateStoreRedux(state, {});
};
