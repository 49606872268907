import { all, put, takeLatest } from 'redux-saga/effects';
import CONSTANTS from '../../../legacy/Constants';
import { resetRegressionStore } from './Regression/RegressionUI.action';
import { ClearTrendRows } from './Trend/TrendUI.action';

function* handleSoftResetUI() {
    try {
        yield all([put(resetRegressionStore()), put(ClearTrendRows())]);
        yield put({ type: 'UI_STORE_RESET_SUCCESS' })
    } catch (e) {}
}

export function* softResetUIStoreSaga() {
    yield takeLatest(CONSTANTS.REDUX_STORE.SOFT_RESET_UI, handleSoftResetUI);
}
