import moment from 'moment';
import { chunk, inRange, isNumber } from 'lodash';
import * as d3 from 'd3';
import { numberToOrdinal } from './helpers';

export const toUnix = (m) => m.unix();

const convertTzForUtcOffset = (tz) => {
    if (!inRange(tz, -16, 16)) {
        return tz * 60;
    }
    return tz;
};

export function timeStart(time, resolution, timezone = '+08:00') {
    const { res_x, res_period } = resolution;
    if (isNumber(timezone)) timezone = convertTzForUtcOffset(timezone);
    const t = moment(time).utcOffset(timezone);
    const value = t.get(res_period);
    const multiple = Math.floor(value / res_x);
    switch (res_period) {
    case 'weeks':
        return t.startOf('isoWeek');
    case 'hours':
        return t.set(getSubHourlyUnits({ hours: res_x * multiple }));
    case 'minutes':
        return t.set(getSubHourlyUnits({ minutes: res_x * multiple }));
    case 'seconds':
        return t.set(getSubHourlyUnits({ seconds: res_x * multiple }));
    default:
        return t.startOf(res_period);
    }
}

export function timeEnd(time, resolution, timezone = '+08:00') {
    const { res_x, res_period } = resolution;
    if (isNumber(timezone)) timezone = convertTzForUtcOffset(timezone);
    const t = moment(time).utcOffset(timezone);
    const value = t.get(res_period);
    const multiple = Math.floor(value / res_x) + 1;
    switch (res_period) {
    case 'weeks':
        return t.startOf('isoWeek').add(1, 'week');
    case 'hours':
        return t.set(getSubHourlyUnits({ hours: res_x * multiple }));
    case 'minutes':
        return t.set(getSubHourlyUnits({ minutes: res_x * multiple }));
    case 'seconds':
        return t.set(getSubHourlyUnits({ seconds: res_x * multiple }));
    default:
        return t.startOf(res_period).add(res_x, res_period);
    }
}

function getSubHourlyUnits(arg) {
    const { hours, minutes, seconds } = arg;
    if (isNumber(hours))
        return { hours, minutes: 0, seconds: 0, milliseconds: 0 };
    if (isNumber(minutes)) return { minutes, seconds: 0, milliseconds: 0 };
    if (isNumber(seconds)) return { seconds, milliseconds: 0 };
    return {};
}

export const getTimePeriodArray = (date_range, res_x, res_period, timezone) => {
    let current = moment(date_range.lower).utcOffset(timezone);
    const rangeEnd = moment(date_range.upper).utcOffset(timezone);
    const intervals = [];
    while (current.isBefore(rangeEnd)) {
        const intervalEnd = timeEnd(current, { res_x, res_period }, timezone);
        intervals.push({
            lower: current.toISOString(),
            upper: intervalEnd.toISOString(),
        });
        current = intervalEnd;
    }

    const last = intervals[intervals.length - 1];
    last.upper = rangeEnd.toISOString();

    return intervals;
};

export const segmentQueryRange = (range, resolution, tz) => {
    const { res_x, res_period } = resolution;
    const { upper, lower } = range;

    const duration = moment(upper).diff(moment(lower), 'seconds');
    const limit = moment.duration(1, 'week').as('seconds');

    if (duration < limit)
        return [
            {
                lower: moment(lower).toISOString(),
                upper: moment(upper).toISOString(),
            },
        ]; // single fetch if less than 1 week
    const intervals = getTimePeriodArray(range, res_x, res_period, tz);
    if (res_period === 'months') return intervals; // fetch per element if monthly res

    const step = Math.ceil(intervals.length / (duration / limit));

    return chunk(intervals, step).map((segment) => {
        return {
            lower: segment[0].lower,
            upper: segment[segment.length - 1].upper,
        };
    });
};

export const getWeekOfMonth = (m) => {
    const yy = m.year(),
        mm = m.month(),
        dd = m.day(),
        date = m.date();
    const daysInMonth = getDaysInMonth(yy, mm + 1);

    const calendarRange = d3.range(1, daysInMonth + 1, 1);

    const dayDates = calendarRange.reduce((acc, curr) => {
        const d = new moment({ year: yy, month: mm, date: curr });
        const dayOfWeek = d.day();
        acc[dayOfWeek] = acc[dayOfWeek] ? acc[dayOfWeek].concat(curr) : [curr];
        return acc;
    }, {});

    return {
        week: dayDates[dd].findIndex((_d) => _d === date) + 1,
        dayDates,
    };
};

export const getDaysInMonth = (y, m) => {
    return new Date(y, m, 0).getDate();
};

export const getOrdinalWeekOfMonth = (week, datetime) => {
    const { dayDates } = getWeekOfMonth(datetime);
    const day = +datetime.format('E') % 7; // sunday is 0
    const max = dayDates[day].length;

    if (week === max) return 'last';
    return numberToOrdinal(week);
};
