import { store } from '../../store';

export const parseSkuChartArguments = (v) => {
    return [
        +v.sku_chart_id, 
        +v.chart_id, 
        +v.sku_id,
        +v.chart.asset.asset_id,
        v.auto_update, 
        v.std_time, 
        v.modifier
    ];
};

export class SkuChart {
    constructor(
        sku_chart_id,
        chart_id,
        sku_id,
        asset_id,
        auto_update,
        std_time,
        modifier
    ) {
        this.sku_chart_id = sku_chart_id;
        this.chart_id = chart_id;
        this.sku_id = sku_id;
        this.asset_id = asset_id;
        this.auto_update = auto_update;
        this.std_time = std_time || 0;
        this.modifier = modifier;
    }

    get uuid() {
        return `${this.sku_id}-${this.chart_id}`;
    }

    get chart() {
        const {
            assets: { charts },
        } = store.getState();

        return charts[this.asset_id][this.chart_id]
    }

    get asset() {
        const {
            assets: { assets },
        } = store.getState();

        return assets[this.asset_id];
    }

    get sku() {
        const {
            sku: { skus: skusState },
        } = store.getState();

        return skusState[this.sku_id];
    }
}
