import { updateStoreRedux } from '../../../legacy/utils/redux';
import { User } from '../../../legacy/models';

const initialReducer = {
    user: undefined,
    error: {},
};

export const authenticationReducer = (
    state = initialReducer,
    action
) => {
    switch (action.type) {
    case 'LOGIN_SUCCESS':
        return updateStoreRedux(state, {
            user: action.user,
        });

    case 'LOGOUT_SUCCESS':
        return updateStoreRedux(state, {
            user: initialReducer.user,
        });

    case 'LOGIN_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'FETCH_USER_INFORMATION_SUCCESS':
        return updateStoreRedux(state, {
            user: action.user,
        });

    case 'FETCH_USER_INFORMATION_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'CHANGE_PASSWORD_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'RESET_PASSWORD_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'VERIFY_EMAIL_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'UPDATE_USER_INFORMATION':
        const newUser = new User(
            state.user.user_id,
            action.user.email || state.user.email,
            action.user.name_first || state.user.name_first,
            action.user.name_last || state.user.name_last,
            action.user.mobile || state.user.mobile,
            action.user.country_code || state.user.country_code,
            action.user.role_name || state.user.role_name,
            action.user.appointment || state.user.appointment,
            action.user.entity_id || state.user.entity_id,
            action.user.confirmed || state.user.confirmed,
            action.user.user_img || state.user.user_img,
            action.user.user_img_id || state.user.user_img_id
        );
        return updateStoreRedux(state, {
            user: newUser,
        });

    case 'SIGNUP_FAILURE':
        return updateStoreRedux(state, {
            error: action.error,
        });

    case 'CHANGE_USER_POLICY':
        return updateStoreRedux(state, {
            user: action.user,
        });

    default:
        return updateStoreRedux(state, {});
    }
};
