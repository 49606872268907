export const fr = {
    notes: 'Remarques',
    load: 'Rapporter',
    export: 'Exporter',
    unutilised: 'Un-utilisé',
    breakdowns: 'pannes',
    threshold: 'Seuil',
    oeepreview: 'Aperçu OEE',
    heatmappreview: 'heatmap Aperçu',
    oeecolors: 'couleurs OEE',
    color: 'Couleur',
    reset: 'Réinitialiser',
    theme: 'Thème',
    defaultview: 'Vue par défaut',
    defaulttimeselection: 'Sélection de temps par défaut',
    zoomlevel: 'Le niveau de zoom',
    entity: 'Entité',
    preferences: 'préférences',
    custom: 'Douane',
    enteryourmailtoreset: 'Entrez votre mail pour réinitialiser',
    goback: 'Retourner',
    resetpassword: 'Réinitialiser le mot de passe',
    logout: 'Se déconnecter',
    ownersemail: 'propriétaires Email',
    optional: 'Optionnel',
    industry: 'Industrie',
    timezone: 'Fuseau horaire',
    nocoderequesttojoin: "Aucun code demande d'adhésion",
    enterreferralcodeinstead: 'Entrez le code de référence à la place',
    setup: 'Installer',
    company: 'Compagnie',
    referralcode: 'Code de Parrainage',
    submit: 'Soumettre',
    summary: 'Résumé',
    language: 'Langue',
    login: "S'identifier",
    signin: 'se connecter',
    signup: "S'inscrire",
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    forgotpassword: 'mot de passe oublié?',
    rememberme: 'Souviens-toi de moi',
    register: "S'inscrire",
    registration: 'enregistrement',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'Email',
    mobile: 'Mobile',
    country: 'Pays',
    contactnumber: 'Numéro de contact',
    appointment: 'Rendez-vous',
    role: 'Rôle',
    admin: 'admin',
    owner: 'propriétaire',
    editor: 'éditeur',
    viewer: 'téléspectateur',
    confirmpassword: 'Confirmez le mot de passe',
    iacceptthe: "J'accepte le",
    useragreement: "Accord de l'utilisateur",
    alreadyregisteredlogin: "Déjà enregistré? S'identifier",
    softwareasaservicetermsofuse:
    "Software-as-a-Service Conditions d'utilisation",
    dashboard: 'Tableau de bord',
    streaming: 'Diffusion',
    resolution: 'Résolution',
    seconds: 'secondes',
    second: 'seconde',
    minutes: 'minutes',
    minute: 'minute',
    hours: 'heures',
    hour: 'Heure',
    weeks: 'semaines',
    week: 'La semaine',
    months: 'mois',
    month: 'mois',
    quarters: 'quarts',
    quarter: 'trimestre',
    years: 'années',
    year: 'an',
    from: 'De',
    to: 'À',
    start: 'début',
    end: 'Fin',
    display: 'Afficher',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Des séries chronologiques',
    gateway: 'passerelle',
    statusasof: 'Statut de',
    active: 'actif',
    offline: 'hors ligne',
    mergeasonemachine: 'Fusionner en une seule machine',
    drawarrowstoformprocessmap:
    'Dessiner des flèches à la forme de carte processus',
    openblockeditor: 'éditeur de bloc ouvert',
    createnewtile: 'Créer un nouveau carrelage',
    summarytile: 'carrelage Résumé',
    selectablockthennameyoursummarytile:
    'Sélectionnez un bloc, puis nommez votre tuile sommaire',
    name: 'Nom',
    blockselector: 'bloc sélecteur',
    asset: 'Atout',
    therearenolabelstaggedinthisperiod:
    "Il n'y a pas d'étiquettes marquées dans cette période",
    standardtimes: 'Temps standard (s)',
    total: 'Total',
    last: 'Dernier',
    oeeinput: 'entrée OEE',
    chartcolor: 'couleur graphique',
    issueeditor: 'problème éditeur',
    issuemangement: 'problème Mangement',
    cumulativeoutput: 'sortie cumulative',
    tag: 'Marque',
    label: 'Étiquette',
    valueval: 'Valeur (Val)',
    time: 'Temps',
    januaryjan: 'Janvier (Jan)',
    februaryfeb: 'Février (février)',
    marchmar: 'Mars (mars)',
    aprilapr: 'Avril (avril)',
    may: 'Mai',
    junejun: 'Juin (juin)',
    julyjul: 'Juillet (juillet)',
    augustaug: 'Août (août)',
    septembersep: 'Septembre (septembre)',
    octoberoct: 'Octobre (octobre)',
    novembernov: 'Novembre (novembre)',
    decemberdec: 'Décembre (Dec)',
    day: 'journée',
    days: 'Journées',
    mondaymonm: 'Lundi (du lundi) M',
    tuesdaytuet: 'Mardi (mar) T',
    wednesdaywedw: 'Mercredi (mer) W',
    thursdaythut: 'Jeudi (ven) t',
    fridayfrif: 'Vendredi (ven) F',
    saturdaysats: 'Samedi (samedi) S',
    sundaysuns: 'Dimanche (dim) S',
    edit: 'Éditer',
    add: 'Ajouter',
    new: 'Nouveau',
    create: 'Créer',
    search: 'Chercher',
    close: 'Fermer',
    cancel: 'Annuler',
    save: 'sauver',
    saved: 'Enregistré',
    clear: 'Clair',
    enter: 'Entrer',
    navigator: 'Navigateur',
    mandatoryfields: 'Champs obligatoires',
    select: 'Sélectionner',
    merge: 'Fusionner',
    delete: 'Supprimer',
    done: 'Terminé',
    filter: 'Filtre',
    table: 'Table',
    columns: 'Colonnes',
    rows: 'Lignes',
    cell: 'cellule',
    page: 'Page',
    previous: 'précédent',
    next: 'Prochain',
    serialnumber: 'Numéro de série',
    manuallyinputserialnumber: 'Manuellement entrée Numéro de série',
    updateasset: 'Mise à jour des actifs',
    machine: 'Machine',
    brand: 'Marque',
    model: 'Modèle',
    nodes: 'nœuds',
    fusions: 'fusions',
    chartorder: 'Tableau de commande',
    primary: 'Primaire',
    adddevicechannel: 'Ajouter un périphérique - Canal',
    pushratesec: 'Pousser Taux (s)',
    voltagev: 'Tension (V)',
    sensor: 'Capteur',
    mode: 'Mode',
    digitalcount: 'Nombre numérique',
    digitalstate: 'État numérique',
    analogvoltage: 'tension analogique',
    charttitle: 'Titre du graphique',
    unitmeasure: 'Unité de mesure',
    attribute: 'Attribut',
    autocycletime: 'Temps de cycle automatique',
    unittransform: 'unité de transformation',
    leaveblankifnotapplicable: 'Laissez un vide si non applicable',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Les champs obligatoires (congé de blanc si le canal non utilisé)',
    fusedchart: 'Graphique Fused',
    fusionname: 'Fusion Nom',
    charttype: 'type de graphique',
    column: 'Colonne',
    line: 'Ligne',
    state: 'Etat',
    expression: 'Expression',
    combination: 'Combinaison',
    currentcharts: 'Graphiques en cours',
    expressionconstructor: "Constructeur d'expression",
    validoperators: 'Les opérateurs valides',
    leaveblanktokeepexistingexpression:
    "Laissez le champ vide pour maintenir l'expression existante",
    oee: 'OEE',
    overallequipmenteffectiveness: "Efficacité Générale de l'Appareil",
    legend: 'Légende',
    loading: 'Chargement',
    availability: 'Disponibilité',
    performance: 'Performance',
    quality: 'Qualité',
    unscheduled: 'Non programmé',
    planneddowntime: 'prévue Downtime',
    changeoversetup: 'Changement / Configuration',
    breakdown: 'Panne',
    unutilized: 'Un-utilisé',
    speedloss: 'Perte de vitesse',
    minorstops: 'Arrêts mineurs',
    rework: 'Rework',
    rejectscrap: 'Rejeter / Scrap',
    nodata: 'Pas de données',
    blockeditor: 'Editeur de blocs',
    sort: 'Trier',
    logicblocks: 'Les blocs logiques',
    sequence: 'Séquence',
    and: 'Et',
    or: 'Ou',
    utilizationheatmap: 'utilisation Heatmap',
    startdate: 'Date de début',
    enddate: 'Date de fin',
    daily: 'du quotidien',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    quarterly: 'Trimestriel',
    yearly: 'Annuel',
    trendanalysis: 'Analyse de tendance',
    metricselection: 'Sélection métrique',
    metric: 'Métrique',
    data: 'Les données',
    target: 'Cible',
    periodselection: 'période de sélection',
    span: 'Envergure',
    every: 'Chaque',
    repeat: 'Répéter',
    repeats: 'répétitions',
    addsingle: 'Ajouter simple',
    paretoanalysis: 'Analyse pareto',
    calendartime: 'temps de calendrier',
    scheduledtime: 'Heure prévue',
    operatingtime: 'Temps de fonctionnement',
    netruntime: 'temps de fonctionnement net',
    effective: 'Efficace',
    loadingloss: 'Perte de chargement',
    availabilityloss: 'disponibilité Perte',
    performanceloss: 'Perte de performance',
    qualityloss: 'La perte de la qualité',
    notagsinthiscategory: 'Pas de tags dans cette catégorie',
    overall: 'Global',
    tagged: 'marqué',
    occurrence: 'Occurrence',
    skumanagement: 'SKU gestion',
    category: 'Catégorie',
    skuname: 'SKU Nom',
    skucode: 'code de SKU',
    unitofmeasurementuom: 'Unité de mesure (UDM)',
    description: 'La description',
    csvupload: 'CSV Télécharger',
    template: 'Modèle',
    notifications: 'notifications',
    newnotification: 'nouvelle notification',
    monitoring: 'surveillance',
    topic: 'Sujet',
    frequency: 'La fréquence',
    recipients: 'Les bénéficiaires',
    notifyby: 'Notif.via',
    sms: 'SMS',
    alert: 'Alerte',
    report: 'rapport',
    greaterthan: 'Plus grand que',
    lessthan: 'Moins que',
    greaterthanorequal: 'Meilleur que ou égal',
    lessthanorequal: 'Inférieur ou égal',
    equal: 'Égal',
    range: 'Intervalle',
    continuousduration: 'Durée continue',
    usermanagement: 'Gestion des utilisateurs',
    user: 'Utilisateur',
    users: 'Utilisateurs',
    invitenew: 'Inviter Nouveau',
    status: 'statut',
    resend: 'Recevoir à nouveau',
    revoke: 'Révoquer',
    invite: 'Inviter',
    invitees: 'invitees',
    addinviteesbypressingenterorclickingonthesign:
    'Ajouter des invités en appuyant sur Entrée ou en cliquant sur le signe « + »',
    schedules: 'Des horaires',
    shift: 'Décalage',
    profile: 'Profil',
    changepassword: 'Changer le mot de passe',
    deleteaccount: 'Supprimer le compte',
    userguide: "Mode d'emploi",
    overviewandbasiccontrol: 'Présentation et contrôle de base',
    rangeandresolution: 'Gamme et résolution',
    editingoptions: "Options d'édition",
    mergetiles: 'tuiles de fusion',
    detailedassetview: 'Vue détaillée des actifs',
    assetoverview: "Vue d'ensemble d'actifs",
    summarystatistics: 'Statistiques sommaires',
    dataanalysis: "L'analyse des données",
    oeeanoverview: 'Un aperçu OEE',
    oeetoggle: 'bascule OEE',
    addingamachine: "Ajout d'une machine",
    nodeconfiguration: 'configuration de nœuds',
    deleteupdateassets: 'Supprimer actifs de mise à jour',
    oeesummarytile: 'Carrelage sommaire OEE',
    oeetags: 'Mots clés OEE',
    issuemanagement: 'gestion des problèmes',
    labelsandfiltering: 'Les étiquettes et le filtrage',
    useraccess: 'Accès utilisateur',
    group: 'Groupe',
    groups: 'Groupes',
    joincompany: "Joignez-vous à l'entreprise",
    notification: 'Notification',
    createnotification: 'Créer notification',
    node: 'Nœud',
    downtime: "temps d'arrêt",
    downtimes: "Les temps d'immobilisation",
    repeatstartdate: 'Répéter Date de début',
    repeatenddate: 'Répéter Date de fin',
    optional: 'Optionnel',
    createplan: 'Créer un plan',
    today: "Aujourd'hui",
    starttime: 'Heure de début',
    endtime: 'Heure de fin',
    chart: 'Graphique',
    reject: 'Rejeter',
    accept: "J'accepte",
    filterbydaterange: 'Filtrer par plage de dates',
    filterbymostrecent: 'Filtrer par le plus récent',
    result: 'Résultat',
    type: 'Type',
    duration: 'Durée',
    by: 'par',
    title: 'Titre',
    assigned: 'Attribué',
    action: 'action',
    track: 'Piste',
    issue: 'Problème',
    priority: 'Priorité',
    requestor: 'demandeur',
    opened: 'ouvert',
    assignee: 'Cessionnaire',
    root: 'racine',
    cause: 'Cause',
    identified: 'Identifiés',
    defined: 'défini',
    date: 'Date',
    resolve: 'résoudre',
    resolved: 'résolu',
    targetresolvedate: 'date cible de détermination',
    procurement: 'Approvisionnement',
    choose: 'Choisir',
    analyses: 'analyses',
    source: 'La source',
    selection: 'Sélection',
    historical: 'Historique',
    mostrecent: 'Le plus récent',
    regression: 'Régression',
    trendline: 'ligne de tendance',
    upperbound: 'limite supérieure',
    lowerbound: 'borne inférieure',
    preview: 'Aperçu',
    period: 'période',
    series: 'séries',
    axis: 'axe',
    youhaveno: "vous n'avez pas",
    code: 'Code',
    all: 'Tout',
    upload: 'télécharger',
    regressionanalysis: 'Analyse de régression',
    issuetracker: "Traqueur d'incidents",
    labelsearch: 'étiquette Recherche',
    gatewaysetup: 'Configuration passerelle',
    setupgateway: "passerelle d'installation",
    radiomesh: 'la radio mesh',
    targetsetting: 'définition des objectifs',
    updatetrackedissue: 'mise à jour question suivi',
    shownewtrackedissue: 'montrer nouveau numéro tracked',
    low: 'faible',
    mid: 'milieu',
    high: 'haute',
    linear: 'linéaire',
    exponential: 'exponentiel',
    logarithmic: 'logarithmique',
    quadratic: 'quadratique',
    polynomial: 'polynôme',
    general: 'Général',
    datataggingenrichment: "L'enrichissement Balisage des données",
    hardware: 'Matériel',
    nodemanagement: 'gestion Node',
    createeditnotification: "Créer notification d'édition",
    conditions: 'Conditions',
    addsku: 'ajouter sku',
    editsku: 'modifier SKU',
    setupagateway: "la configuration d'une passerelle",
    deviceserialnumber: 'Dispositif numéro de série',
    repeatsweekly: 'répète toutes les semaines',
    repeatsdaily: 'répète tous les jours',
    repeatsmonthly: 'répète chaque mois',
    repeatsannually: 'répète chaque année',
    repeatsevery: 'répète tous les',
    hourly: 'toutes les heures',
    biweekly: 'bihebdomadaire',
};
