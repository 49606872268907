/* eslint-disable react/prop-types */

// LIBRARIES
import React from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import RegressionAnalysisComponent from './RegressionAnalysis.component';

// ACTIONS
import { fetchCorrelations } from '../../../store/old/Correlations/Correlations.action';
import { getAllGroupsRequest } from '../../../store/old/Group/Group.action';

// SELECTORS
import { regressionChartDomains } from '../../../store/old/UI/Regression/RegressionUI.selector';

// HELPERS
import {
    collateRegressionMetaFromD3,
    getFittedYFromDataset,
    model_name,
} from '../../utils/regression';


import { flash } from '../../components/Flash';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const isValidDatum = (d) => d.x && d.y;

class RegressionAnalysisContainer extends React.Component {
    constructor(props) {
        super(props);

        const { entity_id } = props.entity;

        props.getCorrelations(entity_id);
        props.getGroups();

        this.getCorrelationFormData = this.getCorrelationFormData.bind(this);
    }

    get predictor() {
        const { regression } = this.props.regressionStore;
        const { regressionMeta } = this;

        if (!regression) return null;

        return function (x) {
            return getFittedYFromDataset(regression, regressionMeta, x);
        };
    }

    get regressionMeta() {
    // metadata for regression model, NOT device metadata
        const {
            correlations,
            regressionStore: { data, selection, regression },
            domains,
        } = this.props;

        if (!regression || !data) return null;

        if (selection !== -1) {
            const selected = correlations[selection];
            const { model_parameters, r2 } = selected;
            return { model_parameters, r2 };
        }

        const r_generator = regression
            .fn()
            .x((d) => d.x)
            .y((d) => d.y)
            .domain(domains.x);

        return collateRegressionMetaFromD3(r_generator(this.data));
    }

    get data() {
        return this.props.regressionStore.data.filter((d) => isValidDatum(d));
    }

    getCorrelationFormData = (data) => {
        const { sourceX, sourceY, regression, upperBound, lowerBound } =
      this.props.regressionStore;

        if (!sourceX || !sourceY) {
            return;
        }

        if (!this.regressionMeta) {
            flash({
                message: 'Please select a regression type',
                status: 'warning',
            });
            return;
        }

        const { model_parameters, r2 } = this.regressionMeta;

        return {
            x: sourceX.metadata_id,
            y: sourceY.metadata_id,
            model_parameters: model_parameters,
            model_name: model_name(regression.label).toLowerCase(),
            threshold: { upper: upperBound, lower: lowerBound },
            r2: r2,
            max_plot_size: 1000,
            threshold_type: 'offset',
            ...data,
        };
    };

    render() {
        return (
            <RegressionAnalysisComponent
                data={this.data}
                predict={this.predictor}
                regressionMeta={this.regressionMeta}
                getCorrelationFormData={this.getCorrelationFormData}
            />
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        regressionStore: appState.ui.regression,
        correlations: appState.correlations.correlations,
        domains: regressionChartDomains(appState),
        entity: currentEntitySelector(appState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCorrelations: (entityId) => dispatch(fetchCorrelations(entityId)),
        getGroups: () => dispatch(getAllGroupsRequest()),
    };
};

const TrendAnalysisContainerConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegressionAnalysisContainer);

export default TrendAnalysisContainerConnected;
