import { TrackedIssuesConstants as K } from './TrackedIssues.constants';

export const getTrackedIssuesRequest = (callback) => {
    return {
        type: K.ACTIONS.GET_TRACKED_ISSUES_REQUEST,
        callback,
    };
};

export const getTrackedIssuesRequestSuccess = (trackedIssues) => {
    return {
        type: K.ACTIONS.GET_TRACKED_ISSUES_REQUEST_SUCCESS,
        payload: { trackedIssues },
    };
};

export const createTrackedIssueRequest = (data, callback) => {
    return {
        type: K.ACTIONS.CREATE_TRACKED_ISSUE_REQUEST,
        payload: { data },
        callback,
    };
};

export const createTrackedIssueRequestSuccess = (trackedIssue) => {
    return {
        type: K.ACTIONS.CREATE_TRACKED_ISSUE_REQUEST_SUCCESS,
        payload: { trackedIssue },
    };
};

export const updateTrackedIssueRequest = (data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_TRACKED_ISSUE_REQUEST,
        payload: { data },
        callback,
    };
};

export const updateTrackedIssueRequestSuccess = (trackedIssue) => {
    return {
        type: K.ACTIONS.UPDATE_TRACKED_ISSUE_REQUEST_SUCCESS,
        payload: { trackedIssue },
    };
};

export const deleteTrackedIssueRequest = (trackedIssue, callback) => {
    return {
        type: K.ACTIONS.DELETE_TRACKED_ISSUE_REQUEST,
        payload: { trackedIssue },
        callback,
    };
};

export const deleteTrackedIssueRequestSuccess = (tracked_issue_id) => {
    return {
        type: K.ACTIONS.DELETE_TRACKED_ISSUE_REQUEST_SUCCESS,
        payload: { tracked_issue_id },
    };
};

export const makeTrackedIssueRequestFailure = (error) => {
    return {
        type: K.ACTIONS.MAKE_TRACKED_ISSUES_REQUEST_FAILURE,
        payload: { error },
    };
};
