import "./style.scss"
import { GridApiContext, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import useBlocks from "../../../hooks/useBlocks"
import createNodes from "../../../lib/utils/process/createNodes"
import Grid from "../../Grid/Grid"
import ProcessCell from "./ProcessCell/ProcessCell"
import ProcessTreeContents from "./ProcessTreeContents/ProcessTreeContents"
import TreeTableHeader from "./TreeTableHeader/TreeTableHeader"
import { Row } from "./TreeTableRow/TreeTableRow"
import { breadthFirstSearch } from "../../../legacy/utils/helpers"
import BlockModel from "../../../api/models/block.model"

const nameColumn: GridColDef = {
    field: "node",
    headerName: "Asset Name",
    type: "string",
    width: 360,
    headerClassName: "process-name-header",
}

const ProcessTreeTable = <T extends Datum, >(props: Props<T>) => {
    const GridContext = React.createContext(GridApiContext)
    const { root: plant } = useBlocks()
    const [options, setOptions] = useState<Domain.ProcessNode[]>([])

    useEffect(() => {
        if (props.process) {
            const block: BlockModel = breadthFirstSearch([plant], (node: BlockModel) => node.blockId === props.process)
            if (props.defaultExpandAll) {
                setOptions(createNodes(block))
                return
            }

            const expandedSet = new Set([block.blockId])
            const childBlockIds = block.children ? block.children.map(b => b.blockId) : []
            const visibleSet = new Set([block.blockId].concat(...childBlockIds))
            setOptions(createNodes(block, [], { 
                isVisible: (node: BlockModel) => visibleSet.has(node.blockId),
                isExpanded: (node: BlockModel) => expandedSet.has(node.blockId)
            }))
        }
    }, [props.process])

    const handleToggle = (node: Domain.ProcessNode, isExpanded: boolean) => {
        const index = options.findIndex((option) => option.id === node.id)
        if (index === -1) {
            return
        }

        const newOption: Domain.ProcessNode = {
            ...options[index],
            isExpanded,
        }

        const newOptions = [...options]
        newOptions.splice(index, 1, newOption)
        let nextIndex = index + 1
        let nextExpanded = isExpanded
        let previousHierarchy = node.hierarchy
        const maxIndex = newOptions.length - 1
        while (nextIndex <= maxIndex && newOptions[nextIndex].hierarchy > node.hierarchy) {
            if (newOptions[nextIndex].hierarchy < previousHierarchy) {
                nextExpanded = isExpanded
            }

            newOptions[nextIndex].isVisible = nextExpanded
            if (isExpanded && newOptions[nextIndex].hasChildren) {
                nextExpanded = newOptions[nextIndex].isExpanded
            }

            previousHierarchy = newOptions[nextIndex].hierarchy
            nextIndex++
        }

        setOptions(newOptions)
    }

    const rows: ProcessRow[] = []
    for (const option of options) {
        if (option.isVisible) {
            const datum = props.data.find(({ id }) => id === option.id)
            rows.push({
                ...datum ?? {},
                id: option.id.toString(),
                node: option,
            } as ProcessRow)
        }
    }

    nameColumn.renderCell = (params: GridRenderCellParams<any, Domain.ProcessNode>) => <ProcessCell
        key={params.value!.label}
        width={nameColumn.width}
        node={params.value!}
        onToggle={handleToggle}
    />

    const columns = [nameColumn, ...props.columns]
    const rowHeight = props.rowHeight ?? 56
    return <Grid container className="process-tree-table" style={{
        width: props.width,
        height: props.height,
    }}>
        <GridApiContext.Provider value={GridContext}>
            <Grid container className="tree-table-container">
                <TreeTableHeader columns={columns as any} style={{ height: rowHeight }}/>
                <ProcessTreeContents<ProcessRow>
                    width={props.width}
                    height={props.height}
                    rowHeight={rowHeight}
                    freeze={props.freeze}
                    columns={columns}
                    rows={rows}
                />
            </Grid>
        </GridApiContext.Provider>
    </Grid>
}

interface Props<T> {
    columns: GridColDef[]
    height: number
    width: number
    data: T[]
    rowHeight?: number
    freeze?: [number, number?]
    process: number | null
    defaultExpandAll?: boolean
}

type Datum = { id: Domain.BlockId, [key: string]: any }
type ProcessRow = Row & { node: Domain.ProcessNode }

export default ProcessTreeTable
