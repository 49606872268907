/* eslint-disable react/prop-types */
import React from 'react';
import LabelWrapper from './LabelWrapper';

import './DualFieldInputRow.scss';

const DualFieldInputRow = (props) => {
    const {
        rows: [rowFirst, rowSecond],
        className,
        labelCol,
        labelClassed,
    } = props;

    return (
        <div className={`d-flex ${className || ''}`}>
            <div className="w-50 pr-1">
                <LabelWrapper
                    col={labelCol}
                    label={rowFirst.label}
                    className={labelClassed || ''}
                >
                    <input {...rowFirst.input} className={`form-control`} />
                </LabelWrapper>
            </div>
            <div className="w-50 pl-1">
                <LabelWrapper
                    col={labelCol}
                    label={rowSecond.label}
                    className={labelClassed || ''}
                >
                    <input {...rowSecond.input} className={`form-control`} />
                </LabelWrapper>
            </div>
        </div>
    );
};

export default DualFieldInputRow;
