/* eslint-disable react/prop-types */
import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';

// CONSTANT
import CONSTANTS from '../../Constants';

// EVENT EMITTER
import { eventEmitter } from '../../auxStore';

// ACTIONS
import { fetchBlockOeeData } from '../../../store/old/Data/Data.action';

// COMPONENTS
import ParetoAnalysisComponent from './ParetoAnalysis.component';
import { withIssues } from '../../Wrappers/HOCs/withIssues';
import { NoData } from '../../components/None';

// HELPERS
import { dataApiQueryParams } from '../../utils/controls';
import { getTrackedIssuesRequest } from '../../../store/old/TrackedIssues/TrackedIssues.action';
import { arrayTrackedIssues } from '../../../store/old/TrackedIssues/TrackedIssues.selector';
import withRouter from '../../Wrappers/HOCs/withRouter';
import withBlock from '../../Wrappers/HOCs/withBlock';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import withStreaming from '../../../lib/hoc/withStreaming';


class ParetoAnalysis extends Component {
    constructor(props) {
        super(props);

        this.props.fetchTrackedIssues();

        this.refreshEvent = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => {
            if (!this.props.block) return;
            const query = this.payload();
            this.getData(query);
        });
    }

    componentWillUnmount() {
        this.refreshEvent();
    }

    payload() {
        return { ...dataApiQueryParams(this.props.controls) };
    }

    getData(query) {
        const { getBlocksOEEData, block, entity } = this.props;

        if (!block) return;

        getBlocksOEEData(entity.entity_id, block.block_id, { ...query }, { withLabels: true });
    }

    render() {

        const {
            blocks,
            oeeData,
            labels,
            masks,
            controls,
            trackedIssues,
            showLabelNotes,
            issues,
            block
        } = this.props;

        const context = {
            blocks,
            oeeData,
            labels,
            masks,
            controls,
            block,
            trackedIssues,
            showLabelNotes,
            issues,
        };

        return (
            <ParetoContext.Provider value={context}>
                {block ? (
                    <ParetoAnalysisComponent block={block} />
                ) : (
                    <NoData className="h-100" description="Record does not exist" />
                )}
            </ParetoContext.Provider>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        entity: currentEntitySelector(rootState),
        blocks: rootState.blocks.blocks,
        oeeData: rootState.data.blocks,
        labels: rootState.label.labels,
        masks: rootState.ui.oee.masks,
        trackedIssues: arrayTrackedIssues(rootState),
        showLabelNotes: !!(
            rootState.preference.entity.options &&
      rootState.preference.entity.options.label_notes
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBlocksOEEData: (entityId, blockId, query, options, callback) => dispatch(fetchBlockOeeData(entityId, blockId, query, options, callback)),
        fetchTrackedIssues: () => dispatch(getTrackedIssuesRequest()),
    };
};

const ConnectedParetoAnalysis = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ParetoAnalysis));

export default withIssues(withBlock(withStreaming(ConnectedParetoAnalysis)));

export const ParetoContext = createContext({});
