import { isEmpty, isNumber, keys } from "lodash";

const UNLABELLED = -1;

export const getSkuLabelsMap = (productionLabels) => {
    return productionLabels.reduce((acc, curr) => {
        if (curr.sku?.sku_id && curr.chartOutput) {
            return {
                ...acc,
                [curr.sku.sku_id]: acc[curr.sku.sku_id] ? acc[curr.sku.sku_id].concat(curr) : [curr]
            }
        }
        return {
            ...acc,
            [UNLABELLED]: acc[UNLABELLED] ? acc[UNLABELLED].concat(curr) : [curr]
        }
    }, {});
}

export const getChartsSkuLabelSummary = (charts, skuLabelsMap) => {
    return charts.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.chart_id]: getSingleChartSkuLabelSummary(curr, skuLabelsMap)
        }
    }, {});
}

export const getSingleChartSkuLabelSummary = (chart, skuLabelsMap) => {
    const labelledArray = keys(skuLabelsMap)
        .filter(key => key != UNLABELLED)
        .reduce((result, key) => {
            const values = skuLabelsMap[key];
            const aggregate = aggregateSkuLabels(chart, key, values)
            return result.concat(aggregate);
        }, []);
    
    const totalUnmodifiedLabelOutput = keys(skuLabelsMap)
        .filter(key => key != UNLABELLED)
        .reduce((acc, key) => {
            const values = skuLabelsMap[key];
            return acc += sumSkuLabelsOutput(chart, values)
        }, 0);

    return labelledArray.concat({ 
        occurrence: '-',
        quantity: chart.modifier * (chart.dataSource.data.total - totalUnmodifiedLabelOutput),
        label: '<SKU Unlabelled>', 
        id: UNLABELLED
    });
}

export const aggregateSkuLabels = (chart, sku_id, labels) => {
    return labels
        .reduce((acc, curr) => {
            if (hasSkuOutput(chart, curr)) {
                return {
                    ...acc,
                    label: curr.sku.codeName,
                    quantity: acc.quantity + getSkuChartModifiedOutput(chart, curr.chartOutput[chart.chart_id], curr.skuChartModifier[chart.chart_id])
                }
            }

            return {
                ...acc,
                label: curr.sku.codeName,
                quantity: acc.quantity + getChartModifiedOutput(chart, curr.chartOutput ? curr.chartOutput[chart.chart_id] : 0)
            }

        }, { occurrence: labels.length, quantity: 0, id: sku_id });
}

export const sumSkuLabelsOutput = (chart, labels) => {
    return labels.reduce((acc, curr) => {
        if (curr.chartOutput && curr.chartOutput[chart.chart_id]) {
            return acc += curr.chartOutput[chart.chart_id];
        }

        return acc;
    }, 0);
}

export const getSkuChartModifier = (chart, labelPersistedModifiers = {}) => {
    if (isEmpty(labelPersistedModifiers)) return chart.modifier;

    return isNumber(labelPersistedModifiers[chart.chart_id]) ?
        labelPersistedModifiers[chart.chart_id] : chart.modifier;
}

export const getSkuChartModifiedOutput = (chart, output = 0, skuChartModifier) => {
    const modifier = isNumber(skuChartModifier) ? skuChartModifier : chart.modifier; // chart modifier defaults to 1
    return modifier * output;
}

export const getChartModifiedOutput = (chart, output = 0) => {
    return chart.modifier * output;
}

export const hasSkuOutput = (chart, label) => {
    const hasChartOutput = label.chartOutput && isNumber(label.chartOutput[chart.chart_id]);
    const hasSkuChartMultiple = label.skuChartModifier && isNumber(label.skuChartModifier[chart.chart_id]);

    return hasChartOutput && hasSkuChartMultiple
}