import "./BlockContent.scss"
import React from "react"

export const BlockContent = (props: Props) => {
    return <div className="block-content">
        {props.children}
    </div>
}

export const BlockContentMain = (props: Props) => {
    return <div className="block-content__main">{props.children}</div>
}

export const BlockContentList = (props: Props) => {
    return <div className="block-content__list">{props.children}</div>
}

interface Props {
    children: React.ReactNode
}

