/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    updatePolicyRequest,
    createPolicyRequest,
    getPoliciesRequest,
} from '../../../../store/old/Permission/Permission.action';

class PolicyAction extends Component {
    updatePolicy = (policy, callback) => {
        this.props.updatePolicy(policy, () => {
            if (callback) {
                callback();
            }
        });
    };

    createPolicy = (policy, callback) => {
        this.props.createPolicy(policy, () => {
            this.props.getPolicies();

            if (callback) {
                callback();
            }
        });
    };

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePolicy: (policy, callback) =>
            dispatch(updatePolicyRequest(policy, callback)),
        createPolicy: (policy, callback) =>
            dispatch(createPolicyRequest(policy, callback)),
        getPolicies: (callback) =>
            dispatch(getPoliciesRequest(callback)),
    };
};

export default connect(
    null,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(PolicyAction);
