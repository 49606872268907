import React from 'react';

import './AssetWidget.scss';

interface iProps {
  children: Node;
  style?: any;
}

const AssetWidget = (props: iProps) => (
    <div className="asset-widget" style={props.style}>
        {props.children}
        <hr />
    </div>
);

export default AssetWidget;
