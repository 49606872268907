
import moment from 'moment';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { BlockConstants as K } from './block.constants';
import { omit } from 'lodash';

const getInitialState = () => {
    return {
        mode: K.VIEWS.OEE_AGGREGATED,
        oee: {},
        output: {},
        issueLabels: {},
        skuLabels: {},
        loading: {}
    };
};

export const BlockPageReducer = (
    state = { ...getInitialState() },
    action
) => {
    switch (action.type) {
        case K.ACTIONS.BLOCK_SET_OEE_DATA:
            return updateStoreRedux(state, {
                oee: action.payload.oee,
            });
        case K.ACTIONS.BLOCK_SET_ISSUE_LABELS:
            return updateStoreRedux(state, {
                issueLabels: action.payload.labels,
            });
        case K.ACTIONS.BLOCK_SET_OUTPUT_DATA:
            return updateStoreRedux(state, {
                output: action.payload.output,
            });
        case K.ACTIONS.BLOCK_SET_SKU_LABELS:
            return updateStoreRedux(state, {
                skuLabels: action.payload.labels,
            });
        case K.ACTIONS.BLOCK_SET_MODE:
            return updateStoreRedux(state, {
                mode: action.payload.mode,
            });
        case K.ACTIONS.BLOCK_RESET_STORE:
            return updateStoreRedux(state, omit(getInitialState(), ["mode", "loading"]));
    default:
        return updateStoreRedux(state, {});
    }
};
