/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { Input, Tree } from 'antd';

const { Search } = Input;

import './SearchableTree.scss';
import { depthFirstTraversal, regexMatch } from '../utils/helpers';
import { uniq } from 'lodash';

const getParentKeys = (node, parentAccessor, parentKeyAccessor) => {
    const result = [];
    const recurse = (n) => {
        result.push(parentKeyAccessor(n));
        const parent = parentAccessor ? parentAccessor(n) : n.parent;
        if (parent) recurse(parent);
    };
    recurse(node);
    return result;
};

const SearchableTree = (props) => {
    const {
        defaultSearch,
        defaultExpanded,
        tree,
        data,
        parentKeyAccessor,
        parentAccessor,
        nodeKeyAccessor,
    } = props;

    const [search, setSearch] = useState(defaultSearch || '');
    const [expanded, setExpanded] = useState(defaultExpanded || []);

    const searchHits = useMemo(() => {
        const result = [];

        tree.treeData.forEach((tree) =>
            depthFirstTraversal(tree, (node) => {
                const key = nodeKeyAccessor ? nodeKeyAccessor(node) : node.key;
                const datum = data[key];
                search &&
          regexMatch(node.label, search, { escape: true }) &&
          result.push(datum);
            })
        );

        return result;
    }, [search]);

    const expandParents = useMemo(() => {
        if (!searchHits.length) return defaultExpanded;

        return uniq(
            searchHits
                .map((hit) => getParentKeys(hit, parentAccessor, parentKeyAccessor))
                .reduce((acc, curr) => acc.concat(...curr), [])
        );
    }, [searchHits]);

    useEffect(() => {
        search && setExpanded(expandParents);
    }, []);

    useEffect(() => {
        setExpanded(expandParents);
    }, [expandParents]);

    return (
        <div className="searchable-tree">
            <Search
                className="searchable-tree__search-input"
                {...props.search}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
            />
            <div className="searchable-tree__tree-container">
                <Tree
                    showIcon
                    {...props.tree}
                    onExpand={(v) => setExpanded(v)}
                    expandedKeys={expanded}
                    filterTreeNode={(n) =>
                        search && regexMatch(n.label, search, { escape: true })
                    }
                />
            </div>
        </div>
    );
};

export default SearchableTree;
