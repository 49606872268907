import "./style.scss"
import classNames from "classnames"
import React from "react"

const Page = (props: Props) => {
    return <div id={props.id} className={classNames("page", props.className)}>
        {props.children}
    </div>
}

interface Props {
    children: React.ReactNode
    className?: string
    id?: string
}

export default Page
