// Event store, used for dashboard and asset view
class EventStore {
    constructor() {
        this.events = {};

        this.clicks = 0;
    }

    add(eventName, eventHandler) {
        window.addEventListener(eventName, eventHandler, false);
        this.events[eventName] = eventHandler;
    }

    remove(eventName) {
        window.removeEventListener(eventName, this.events[eventName]);
        delete this.events[eventName];
    }

    removeAll() {
        Object.keys(this.events).forEach((eventName) => {
            this.remove(eventName);
        });
    }

    startClick() {
        this.clicks++;

        setTimeout(() => {
            this.clicks--;
        }, 250);
    }

    onDoubleClick() {
        this.startClick();
        return this.clicks >= 2;
    }
}

export const eventStore = new EventStore();
