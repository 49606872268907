/* eslint-disable react/prop-types */


import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import { Checkbox, DatePicker, Form, Modal, Select } from 'antd';

// EVENTS
import { eventEmitter } from '../../../../auxStore';

// API
import { api_deleteDeviceData } from '../../../../../store/old/Data/Data.services';

// HELPERS
import CONSTANTS from '../../../../Constants';
import { uiDatetimeFormatWithSeconds } from '../../../../utils/helpers';
import { errorFlash } from '../../../../components/Flash';

// STATE
import { currentEntitySelector } from '../../../../../store/old/Entity/Entity.selector';

// INTERFACE
import moment from 'moment';
import { NoAccess } from '../../../../components/None';

const { RangePicker } = DatePicker;

const DataDeleteModal = (props) => {
    const { visible, asset, range, close } = props;
    const { devices } = asset;
    const { entity_id } = useSelector(currentEntitySelector);
    const [form] = Form.useForm();

    const [deviceId, setDeviceId] = useState(
        devices.length ? devices[0].device_id : -1
    );

    const device = useMemo(() => {
        return asset.devices
            ? asset.devices.find((d) => d.device_id === deviceId)
            : null;
    }, [deviceId]);

    const handleFormValuesChange = useCallback((changedValues) => {
        const formFieldName = Object.keys(changedValues)[0];
        if (formFieldName === 'device_id') {
            setDeviceId(changedValues[formFieldName]);
            form.setFieldsValue({ channels: [] });
        }
    }, []);

    const deleteData = async () => {
        try {
            const { range, device_id, delete_fusion, channels } =
        await form.validateFields();

            const [lower, upper] = range.map((d) => d.toISOString());
            const params = {
                date_range: { lower, upper },
            };

            channels.length && (params.channels = channels);
            delete_fusion && (params.delete_fusion = true);

            await api_deleteDeviceData(entity_id, device_id, params);
            eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
            close();
        } catch (e) {
            errorFlash(e);
        }
    };

    useEffect(() => {
        form.setFieldsValue({ range: range.map((d) => moment(d)) });
    }, [form, range]);

    return (
        <Modal
            title="Data Deletion"
            key={`${asset.asset_id}${visible ? 1 : 0}`}
            visible={visible}
            width={600}
            onOk={deleteData}
            onCancel={close}
            okText="Proceed"
            forceRender
            okButtonProps={{ disabled: !device }}
        >
            <Form
                name="dataDelete"
                id="dataDeleteForm"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                className="data-delete-form"
                onValuesChange={handleFormValuesChange}
            >
                {' '}
                {device ? (
                    <>
                        <Form.Item
                            name="range"
                            label="Range"
                            initialValue={range.map((d) => moment(d))}
                        >
                            <RangePicker
                                className="w-100"
                                showTime
                                format={uiDatetimeFormatWithSeconds}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item name="device_id" initialValue={deviceId} label="Device">
                            <Select
                                options={asset.devices.map((d) => ({
                                    label: d.mac_address,
                                    value: d.device_id,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name="channels" initialValue={[]} label="Channels">
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Leave blank to delete all channels"
                                options={device.metadata.map((m) => ({
                                    label: m.chart_title,
                                    value: m.channel,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name="delete_fusion"
                            valuePropName="checked"
                            label="Fusion"
                            initialValue={false}
                        >
                            <Checkbox>Delete associated fusion data</Checkbox>
                        </Form.Item>
                    </>
                ) : (
                    <NoAccess description={'Cannot delete fusion data.'} />
                )}
            </Form>
        </Modal>
    );
};

export default DataDeleteModal;
