import { mapValues, values } from "lodash"
import { useAppSelector } from "../store/hooks"
import { blocksSelector } from "../store/old/Blocks/Blocks.selector"
import BlockTransformer from "../api/transformers/block.transformer"
import { toMappedArrays } from "../legacy/utils/helpers"
import type BlockModel from "../api/models/block.model"
import { useMemo } from "react"

const useBlocks = () => {
    const blocksResource = useAppSelector(blocksSelector)
    const _blocks = useMemo(() => mapValues(blocksResource, b => BlockTransformer(b)), [blocksResource])

    const parentChildMap = useMemo(() => toMappedArrays(
        values(_blocks),
        (b: BlockModel) => b.parentBlockId,
        (b: BlockModel) => b.blockId
    ), [_blocks])

    const blocks = useMemo(() => mapValues(_blocks, b => {
        b.children = parentChildMap[b.blockId]
            ? parentChildMap[b.blockId]
                .map((childId: number) => _blocks[childId])
                .sort((a: BlockModel, b: BlockModel) => a.order - b.order)
            : []
        return b
    }), [parentChildMap])

    const root = useMemo(() => values(blocks).find(b => !b.parentBlockId), [blocks])

    return { blocks, root }
}

export default useBlocks
