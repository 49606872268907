/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import { Spinner } from '../Spinner';
import HeaderUnauthenticated from './HeaderUnauthenticated';
import HeaderAuthenticated from './HeaderAuthenticated';

// STORES

import './Header.scss';
import { session } from '../../auxStore';
import withRouter from '../../Wrappers/HOCs/withRouter';


class HeaderSimple extends Component {
    render() {
        const { user } = this.props;

        return (
            <div className="header">
                <nav className="navbar navbar-expand-lg navbar-dark nav-color">
                    <Spinner /> 
                    {!session.isAuthenticated || !user ? (
                        <HeaderUnauthenticated />
                    ) : (
                        <HeaderAuthenticated user={user} />
                    )}
                </nav>
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return { user: appState.auth.user };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HeaderSimple));
