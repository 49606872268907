import "./style.scss"
import React from "react"
import AddIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"

const AddButton = (props: Props) => {
    return <Button size="small" onClick={props.onPress}>
        <AddIcon className="btn-add-icon" />
        <span>{props.children}</span>
    </Button>
}

interface Props {
    onPress: () => void
    children: React.ReactNode
}

export default AddButton
