import { values } from 'lodash';
import { createSelector } from 'reselect';
import { Target } from '../../../legacy/models';

export const targetsState = (appState) =>
    appState.target;

export const targetsSelector = createSelector(targetsState, (v) => {
    return v.targets;
});

export const arrayTargets = (appState) =>
    values(targetsState(appState).targets);
