import { UtilizationConstants as K } from './Utilization.constants';

export const utilizationUpdateRange = (date_range) => {
    return {
        type: K.ACTIONS.UTILIZATION_UPDATE_RANGE,
        payload: { date_range },
    };
};

export const utilizationUpdateResolution = (resolution) => {
    return {
        type: K.ACTIONS.UTILIZATION_UPDATE_RESOLUTION,
        payload: { resolution },
    };
};

export const utilizationFetchData = (entity_id, block_id, callback) => {
    return {
        type: K.ACTIONS.UTILIZATION_FETCH_DATA_REQUEST,
        payload: { block_id, entity_id },
        callback
    };
};

export const utilizationResetStore = () => {
    return { type: K.ACTIONS.RESET_UTILIZATION_STORE };
};
