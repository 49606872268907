export const SkuChartsConstants = {
    ACTIONS: {
        FETCH_SKU_CHARTS: 'FETCH_SKU_CHARTS',
        FETCH_ASSET_SKU_CHARTS: 'FETCH_ASSET_SKU_CHARTS',
        CREATE_SKU_CHART: 'CREATE_SKU_CHART',
        BULK_CREATE_UPDATE_SKU_CHARTS: 'BULK_CREATE_UPDATE_SKU_CHARTS',
        UPDATE_SKU_CHART: 'UPDATE_SKU_CHART',
        RESET_SKU_CHARTS_STATE: 'RESET_SKU_CHARTS_STATE',
        SET_SKU_CHARTS_STATE: 'SET_SKU_CHARTS_STATE',
        SET_SKU_CHARTS_RESOURCE: 'SET_SKU_CHARTS_RESOURCE',
        ADD_SKU_CHARTS_RESOURCE: 'ADD_SKU_CHARTS_RESOURCE',
    },
};
