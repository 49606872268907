export const sv = {
    notes: 'Anteckningar',
    load: 'Apportera',
    export: 'Exportera',
    unutilised: 'Un utnyttjade',
    breakdowns: 'haverier',
    threshold: 'Tröskel',
    oeepreview: 'OEE förhandsgranskning',
    heatmappreview: 'Heatmap Preview',
    oeecolors: 'OEE färger',
    color: 'Färg',
    reset: 'Återställa',
    theme: 'Tema',
    defaultview: 'Standardläget',
    defaulttimeselection: 'Standard tid val',
    zoomlevel: 'Zoomnivå',
    entity: 'Entitet',
    preferences: 'Inställningar',
    custom: 'Beställnings',
    enteryourmailtoreset: 'Fyll i din e-post för att återställa',
    goback: 'Gå tillbaka',
    resetpassword: 'Återställ lösenord',
    logout: 'Logga ut',
    ownersemail: 'Ägare Email',
    optional: 'Frivillig',
    industry: 'Industri',
    timezone: 'Tidszon',
    nocoderequesttojoin: 'Ingen kodskontrollen Gå',
    enterreferralcodeinstead: 'Ange remiss kod istället',
    setup: 'Uppstart',
    company: 'Företag',
    referralcode: 'värvningskod',
    submit: 'Skicka in',
    summary: 'Sammanfattning',
    language: 'Språk',
    login: 'Logga in',
    signin: 'Logga in',
    signup: 'Bli Medlem',
    username: 'Användarnamn',
    password: 'Lösenord',
    forgotpassword: 'Glömt ditt lösenord?',
    rememberme: 'Kom ihåg mig',
    register: 'Registrera',
    registration: 'Registrering',
    firstname: 'Förnamn',
    lastname: 'Efternamn',
    email: 'E-post',
    mobile: 'Mobil',
    country: 'Land',
    contactnumber: 'Kontaktnummer',
    appointment: 'Utnämning',
    role: 'Roll',
    admin: 'administration',
    owner: 'ägare',
    editor: 'redaktör',
    viewer: 'visare',
    confirmpassword: 'Bekräfta lösenord',
    iacceptthe: 'jag accepterar',
    useragreement: 'Användaravtal',
    alreadyregisteredlogin: 'Redan registrerad? Logga in',
    softwareasaservicetermsofuse: 'Software-as-a-Service Villkor',
    dashboard: 'instrumentbräda',
    streaming: 'Strömning',
    resolution: 'Upplösning',
    seconds: 'sekunder',
    second: 'andra',
    minutes: 'minuter',
    minute: 'minut',
    hours: 'timmar',
    hour: 'Timme',
    weeks: 'Veckor',
    week: 'Vecka',
    months: 'månader',
    month: 'månad',
    quarters: 'kvartal',
    quarter: 'fjärdedel',
    years: 'år',
    year: 'år',
    from: 'Från',
    to: 'Till',
    start: 'Start',
    end: 'Slutet',
    display: 'Visa',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Tidsföljder',
    gateway: 'inkörsport',
    statusasof: 'Status som av',
    active: 'aktiva',
    offline: 'off-line',
    mergeasonemachine: 'Slå samman som en maskin',
    drawarrowstoformprocessmap: 'Rita pilar till formen processkarta',
    openblockeditor: 'Öppna blocket redaktör',
    createnewtile: 'Skapa ny Tile',
    summarytile: 'Sammanfattning kakel',
    selectablockthennameyoursummarytile:
    'Välj ett block, sedan namnge ditt sammanfattande kakel',
    name: 'namn',
    blockselector: 'Block väljare',
    asset: 'Tillgång',
    therearenolabelstaggedinthisperiod:
    'Det finns inga etiketter taggade under denna period',
    standardtimes: 'Standard Tid (s)',
    total: 'Total',
    last: 'Sista',
    oeeinput: 'OEE ingång',
    chartcolor: 'diagram färg',
    issueeditor: 'Issue Editor',
    issuemangement: 'Issue Mangement',
    cumulativeoutput: 'kumulativ utsignal',
    tag: 'Märka',
    label: 'Märka',
    valueval: 'Värde (Val)',
    time: 'Tid',
    januaryjan: 'Januari (Jan)',
    februaryfeb: 'Februari (februari)',
    marchmar: 'Mars (mar)',
    aprilapr: 'April (april)',
    may: 'Maj',
    junejun: 'Juni (juni)',
    julyjul: 'Juli (juli)',
    augustaug: 'Augusti (augusti)',
    septembersep: 'September (Sep)',
    octoberoct: 'Oktober (oktober)',
    novembernov: 'November (Nov)',
    decemberdec: 'December (Dec)',
    day: 'Dag',
    days: 'dagar',
    mondaymonm: 'Måndag (mån) M',
    tuesdaytuet: 'Tisdag (tis) T',
    wednesdaywedw: 'Onsdag (ons) W',
    thursdaythut: 'Torsdag (to) T',
    fridayfrif: 'Fredag ​​(fre) F',
    saturdaysats: 'Lördag (lör) S',
    sundaysuns: 'Söndag (Sun) S',
    edit: 'Redigera',
    add: 'Lägg till',
    new: 'Ny',
    create: 'Skapa',
    search: 'Sök',
    close: 'Stänga',
    cancel: 'Annullera',
    save: 'Spara',
    saved: 'sparade',
    clear: 'Klar',
    enter: 'Stiga på',
    navigator: 'Navigatör',
    mandatoryfields: 'Obligatoriska fält',
    select: 'Välj',
    merge: 'Sammanfoga',
    delete: 'Radera',
    done: 'Gjort',
    filter: 'Filtrera',
    table: 'Tabell',
    columns: 'kolonner',
    rows: 'rader',
    cell: 'cell',
    page: 'Sida',
    previous: 'Tidigare',
    next: 'Nästa',
    serialnumber: 'Serienummer',
    manuallyinputserialnumber: 'Manuellt inmatnings serienummer',
    updateasset: 'Uppdatera Asset',
    machine: 'Maskin',
    brand: 'varumärke',
    model: 'Modell',
    nodes: 'Knutpunkter',
    fusions: 'fusioner',
    chartorder: 'diagram Order',
    primary: 'Primär',
    adddevicechannel: 'Lägg till enhet - Kanal',
    pushratesec: 'Driva Rate (sek)',
    voltagev: 'Spänning (V)',
    sensor: 'Sensor',
    mode: 'Läge',
    digitalcount: 'digital Count',
    digitalstate: 'digital State',
    analogvoltage: 'analog Voltage',
    charttitle: 'diagram Titel',
    unitmeasure: 'måttenhet',
    attribute: 'Attribut',
    autocycletime: 'Auto Cykeltid',
    unittransform: 'enhet Trans',
    leaveblankifnotapplicable: 'Lämna tomt om ej tillämpligt',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Obligatoriska fält (lämna tomt om kanalen inte används)',
    fusedchart: 'smält-diagram',
    fusionname: 'Fusion Namn',
    charttype: 'diagrammet Typ',
    column: 'Kolumn',
    line: 'Linje',
    state: 'stat',
    expression: 'Uttryck',
    combination: 'Kombination',
    currentcharts: 'Aktuella Charts',
    expressionconstructor: 'expressions Constructor',
    validoperators: 'giltiga operatörer',
    leaveblanktokeepexistingexpression:
    'Lämna tomt för att behålla befintliga uttryck',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Overall Equipment Effectiveness',
    legend: 'Legend',
    loading: 'Läser in',
    availability: 'Tillgänglighet',
    performance: 'Prestanda',
    quality: 'Kvalitet',
    unscheduled: 'Oplanerade',
    planneddowntime: 'planerade driftstopp',
    changeoversetup: 'Övergången / Setup',
    breakdown: 'Bryta ner',
    unutilized: 'Un utnyttjade',
    speedloss: 'hastighet Förlust',
    minorstops: 'mindre Stoppar',
    rework: 'Göra om',
    rejectscrap: 'Avvisa / Skrot',
    nodata: 'Inga data',
    blockeditor: 'Block Editor',
    sort: 'Sortera',
    logicblocks: 'Logic Blocks',
    sequence: 'Sekvens',
    and: 'Och',
    or: 'Eller',
    utilizationheatmap: 'utnyttjande Heatmap',
    startdate: 'Start datum',
    enddate: 'Slutdatum',
    daily: 'Dagligen',
    weekly: 'Varje vecka',
    monthly: 'En gång i månaden',
    quarterly: 'Kvartals',
    yearly: 'Årlig',
    trendanalysis: 'Trend analys',
    metricselection: 'Metric Selection',
    metric: 'Metrisk',
    data: 'Data',
    target: 'Mål',
    periodselection: 'period Selection',
    span: 'Spänna',
    every: 'Varje',
    repeat: 'Upprepa',
    repeats: 'upprepningar',
    addsingle: 'Lägg Single',
    paretoanalysis: 'Pareto Analys',
    calendartime: 'kalendertid',
    scheduledtime: 'Schemalagd tid',
    operatingtime: 'driftstid',
    netruntime: 'Netto körtid',
    effective: 'Effektiv',
    loadingloss: 'Loading Loss',
    availabilityloss: 'tillgänglighet Loss',
    performanceloss: 'prestanda förlust',
    qualityloss: 'kvalitetsförlust',
    notagsinthiscategory: 'Inga taggar i denna kategori',
    overall: 'Övergripande',
    tagged: 'Taggade',
    occurrence: 'Förekomst',
    skumanagement: 'SKU Hantering',
    category: 'Kategori',
    skuname: 'SKU Namn',
    skucode: 'SKU Code',
    unitofmeasurementuom: 'Måttenhet (UOM)',
    description: 'Beskrivning',
    csvupload: 'CSV upp',
    template: 'Mall',
    notifications: 'anmälningar',
    newnotification: 'New Anmälan',
    monitoring: 'Övervakning',
    topic: 'Ämne',
    frequency: 'Frekvens',
    recipients: 'mottagare',
    notifyby: 'Meddela efter',
    sms: 'SMS',
    alert: 'Varna',
    report: 'Rapportera',
    greaterthan: 'Större än',
    lessthan: 'Mindre än',
    greaterthanorequal: 'Större än eller lika med',
    lessthanorequal: 'Mindre än eller lika',
    equal: 'Likvärdig',
    range: 'Räckvidd',
    continuousduration: 'kontinuerlig Varaktighet',
    usermanagement: 'Användarhantering',
    user: 'Användare',
    users: 'användare',
    invitenew: 'Bjud New',
    status: 'status',
    resend: 'Skicka igen',
    revoke: 'Återkalla',
    invite: 'Bjuda',
    invitees: 'inbjudna',
    addinviteesbypressingenterorclickingonthesign:
    "Lägg inbjudna genom att trycka på Enter eller klicka på skylten '+'",
    schedules: 'scheman',
    shift: 'Flytta',
    profile: 'Profil',
    changepassword: 'Ändra lösenord',
    deleteaccount: 'Radera konto',
    userguide: 'Användarguide',
    overviewandbasiccontrol: 'Översikt och Basman',
    rangeandresolution: 'Range och upplösning',
    editingoptions: 'redigera alternativ',
    mergetiles: 'Sammanfoga kakel',
    detailedassetview: 'detaljerad tillgångs vy',
    assetoverview: 'Asset översikt',
    summarystatistics: 'Sammanfattande statistik',
    dataanalysis: 'Dataanalys',
    oeeanoverview: 'OEE en översikt',
    oeetoggle: 'OEE växla',
    addingamachine: 'Tillsats av en maskin',
    nodeconfiguration: 'nod konfiguration',
    deleteupdateassets: 'Radera uppdatera tillgångar',
    oeesummarytile: 'OEE sammanfattning kakel',
    oeetags: 'OEE Tags',
    issuemanagement: 'ärendehantering',
    labelsandfiltering: 'Etiketter och filtrering',
    useraccess: 'User Access',
    group: 'Grupp',
    groups: 'grupper',
    joincompany: 'Gå företag',
    notification: 'Underrättelse',
    createnotification: 'Skapa anmälan',
    node: 'Nod',
    downtime: 'Driftstopp',
    downtimes: 'stilleståndstider',
    repeatstartdate: 'Upprepa startdatum',
    repeatenddate: 'Upprepa Slutdatum',
    optional: 'Frivillig',
    createplan: 'Skapa Plan',
    today: 'I dag',
    starttime: 'Starttid',
    endtime: 'Sluttid',
    chart: 'Diagram',
    reject: 'Avvisa',
    accept: 'Acceptera',
    filterbydaterange: 'filtret genom datumintervall',
    filterbymostrecent: 'filtret efter senaste',
    result: 'Resultat',
    type: 'Typ',
    duration: 'Varaktighet',
    by: 'förbi',
    title: 'Titel',
    assigned: 'Tilldelad',
    action: 'Handling',
    track: 'Spår',
    issue: 'Problem',
    priority: 'Prioritet',
    requestor: 'förfrågaren',
    opened: 'öppnad',
    assignee: 'innehavare',
    root: 'rot',
    cause: 'Orsak',
    identified: 'identifierade',
    defined: 'Definierat',
    date: 'datum',
    resolve: 'lösa',
    resolved: 'löst',
    targetresolvedate: 'Mål lösa datum',
    procurement: 'Anskaffning',
    choose: 'Välja',
    analyses: 'analyser',
    source: 'Källa',
    selection: 'Urval',
    historical: 'Historisk',
    mostrecent: 'Senaste',
    regression: 'regression',
    trendline: 'trendlinje',
    upperbound: 'övre gräns',
    lowerbound: 'lägre bunden',
    preview: 'förhandsvisning',
    period: 'period',
    series: 'serier',
    axis: 'axel',
    youhaveno: 'du har inga',
    code: 'Koda',
    all: 'Allt',
    upload: 'ladda upp',
    regressionanalysis: 'Regressionsanalys',
    issuetracker: 'fråga tracker',
    labelsearch: 'Label Sök',
    gatewaysetup: 'Gateway Setup',
    setupgateway: 'inställnings gateway',
    radiomesh: 'radio mesh',
    targetsetting: 'inställning målet',
    updatetrackedissue: 'uppdatering spårade problemet',
    shownewtrackedissue: 'visar ny band fråga',
    low: 'låg',
    mid: 'mitten',
    high: 'hög',
    linear: 'linjär',
    exponential: 'exponentiell',
    logarithmic: 'logaritmisk',
    quadratic: 'kvadratisk',
    polynomial: 'polynom',
    general: 'Allmän',
    datataggingenrichment: 'Data Tagging Anrikning',
    hardware: 'Hårdvara',
    nodemanagement: 'Node management',
    createeditnotification: 'Skapa redigera anmälan',
    conditions: 'Betingelser',
    addsku: 'lägga sku',
    editsku: 'redigera SKU',
    setupagateway: 'setup en gateway',
    deviceserialnumber: 'enhetens serienummer',
    repeatsweekly: 'upprepas varje vecka',
    repeatsdaily: 'upprepar dagligen',
    repeatsmonthly: 'upprepar månadsvis',
    repeatsannually: 'upprepar årligen',
    repeatsevery: 'upprepas varje',
    hourly: 'varje timme',
    biweekly: 'som utkommer varannan vecka',
};
