/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TYPES/ INTERFACES
import { Block, parseBlockArguments } from '../../models';

// REDUX
import {
    currentEntitySelector,
    entityState,
} from '../../../store/old/Entity/Entity.selector';
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';

// COMPONENTS
import WidgetLabel from '../components/WidgetLabel';
import { OEEDials } from './OEEDials';

// UTILS
import CONSTANTS from '../../Constants';

import './OEESummary.scss';
import { changeEntityFlow } from '../../../store/old/Entity/Entity.action';
import { useNavigate } from 'react-router-dom';


export const OEESummary = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { oee2 } = useSelector(controlsState);
    const controls = useSelector(controlsState);
    const { entity_id: current_entity_id } = useSelector(currentEntitySelector);
    const { data, donutProps, canDoubleClick } = props;

    const handleDoubleClick = useCallback(() => {
        const { block } = data;
        if (block.entity_id !== current_entity_id) {
            dispatch(changeEntityFlow(block.entity_id));
        }

        navigate(generateBlockOrAssetUrl(block));
    }, [data]);

    const generateBlockOrAssetUrl = useCallback(
        (_block) => {
            if (_block.asset) {
                return `${CONSTANTS.URLS.ASSET}/${_block.block_id}`;
            }

            return `${CONSTANTS.URLS.BLOCK}/${_block.block_id}`;
        },
        [controls]
    );

    return (
        <div
            className="viz-oee-summary"
            onDoubleClick={() => canDoubleClick && handleDoubleClick()}
        >
            <WidgetLabel>{data.label}</WidgetLabel>
            <OEEDials
                display="column"
                data={data.data}
                oee2={oee2}
                donutProps={donutProps}
            />
        </div>
    );
};

OEESummary.defaultProps = {
    canDoubleClick: false,
    data: {
        block: new Block(...parseBlockArguments({})),
        data: {
            final_effective: 0,
            loading: 0,
            availability: 0,
            performance: 0,
            quality: 0,
        },
        label: 'OEE Summary',
    },
};
