import "./style.scss"
import { Box, Typography } from "@mui/material"
import moment from "moment/moment"
import React from "react"
import { displayUnitOfTime } from "../../../../../api/models/objective.model"
import ActionButton from "../../../../../components/Button/ActionButton/ActionButton"
import TextButton from "../../../../../components/Button/TextButton/TextButton"
import VerticalDivider from "../../../../../components/Divider/VerticalDivider/VerticalDivider"
import Grid from "../../../../../components/Grid/Grid"
import { goalsSaveAction, goalsSaveEndGoalsAction, objectiveUpdateAction } from "../../../../../store/actions/goals.action"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import EditableInput from "../../../../../components/Input/EditableInput/EditableInput"
import { goalsUpdate, setViewMode } from "../../../../../store/reducers/goals.reducer"
import {
    selectObjective,
    selectObjectiveLoading,
    selectObjectiveOriginalGoals,
    selectObjectiveViewMode
} from "../../../../../store/selectors/goals.selectors"
import BackButton from "../../../../../components/Button/BackButton/BackButton"
import { useNavigate } from "react-router-dom"

const ObjectivePageHeader = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const viewMode = useAppSelector(selectObjectiveViewMode)
    const viewing = useAppSelector(selectObjective)
    const loading = useAppSelector(selectObjectiveLoading)
    const originalGoals = useAppSelector(selectObjectiveOriginalGoals)

    const handleSaveProgressiveGoals = () => {
        void dispatch(goalsSaveAction())
    }

    const handleSaveEndGoals = () => {
        void dispatch(goalsSaveEndGoalsAction())
    }

    const handleSubmitNameChange = (value: string) => {
        if (!viewing) return
        void dispatch(objectiveUpdateAction({ 
            objectiveId: viewing.id,
            blockId: viewing.blockId,
            name: value
        }))
    }

    const handlePlanEditClick = () => {
        dispatch(goalsUpdate(originalGoals))
        void dispatch(setViewMode("planEdit"))
    }

    const handleEndGoalEditClick = () => {
        dispatch(goalsUpdate(originalGoals))
        void dispatch(setViewMode("endGoalEdit"))
    }

    const handleCancelEdit = () => {
        dispatch(goalsUpdate(originalGoals))
        void dispatch(setViewMode("default"))
    }

    const handleBackToList = () => {
        dispatch(goalsUpdate(originalGoals))
        navigate("/goals")
    }

    return  <Grid container item className="objective-page-header" direction="row" columns={{ md: 13, lg: 13 }}>
        <Grid item className="objective-page-header__info__title" md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <EditableInput
                    id={"objective-name-input"}
                    value={viewing?.name ?? "-"}
                    onSubmit={(value) => {handleSubmitNameChange(value as string)}}
                />
                <Grid container item className="objective-page-header__info__description" direction="row">
                    <Typography variant="body1">
                        { viewing ? formatObjectiveRange(viewing.startsAt, viewing.endsAt, viewing.unitOfTime) : "" }
                    </Typography>
                    <VerticalDivider />
                    <Typography variant="body1">
                        {viewing?.unitOfTime && displayUnitOfTime(viewing.unitOfTime)}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
        <Grid item className="objective-page-header__actions">
            {viewMode !== "default" && <BackButton onPress={handleCancelEdit} />}
            {viewMode === "default" && <TextButton onPress={handleBackToList}>{"<"} Back to list</TextButton>}
            {viewMode === "endGoalEdit" && <TextButton onPress={handlePlanEditClick} disabled={loading}>Edit progressive goals</TextButton>}
            {viewMode === "planEdit" && <TextButton onPress={handleEndGoalEditClick} disabled={loading}>Edit end goal</TextButton>}
            {viewMode === "default" && <TextButton onPress={handleEndGoalEditClick} disabled={loading}>Edit</TextButton>}
            {viewMode === "endGoalEdit" && <ActionButton onPress={handleSaveEndGoals} disabled={loading}>Save end goals</ActionButton>}
            {viewMode === "planEdit" && <ActionButton onPress={handleSaveProgressiveGoals} disabled={loading}>Save progressive goals</ActionButton>}
        </Grid>
    </Grid>

    
}

export default ObjectivePageHeader

const formatObjectiveRange = (startsAt: string, endsAt: string, unitOfTime: string) => {
    if (unitOfTime === "months") {
        return `${moment(startsAt).format("MMM YYYY")} - ${moment(endsAt).format("MMM YYYY")}`
    }

    return `${moment(startsAt).format("DD MMM YYYY")} - ${moment(endsAt).format("DD MMM YYYY")}`
}
