/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, keys, mapValues, pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Checkbox, Form, Modal } from 'antd';

import { Label, parseLabelArguments } from '../../models';

import { errorFlash } from '../../components/Flash';
import { dataApiQueryParams } from '../../utils/controls';
import { depthFirstTraversal, getAllLeaves } from '../../utils/helpers';
import { multiSaveCsv } from '../../utils/dataExports';
import { OEEFactory } from '../../utils/oee';
import { TimeSeriesFactory } from '../../utils/data';

import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { fusionsState } from '../../../store/old/Fusions/Fusions.selector';
import { metadataState } from '../../../store/old/Metadata/Metadata.selector';
import { oeeCategoriesSelector } from '../../../store/old/UI/OEE/OEE.selector';
import { blocksSelector } from '../../../store/old/Blocks/Blocks.selector';

import {
    api_getAssetsChartsData,
    api_getBlockOEEData,
} from '../../../store/old/Data/Data.services';
import { api_getAssetsLabels } from '../../../store/old/Labels/Labels.services';
import AukButton from '../../components/AukButton';
import './BlockCSVExportModal.scss';
import { assetsSelector, } from '../../../store/old/Assets/Assets.selector';
import { isLabelInRange } from '../../utils/labels';
import moment from 'moment';

const OEE_SUMMARY_CSV = 'oee_summary';
const OEE_RAW_CSV = 'oee_detailed';
const DATA_SUMMARY_CSV = 'data_summary';
const DATA_RAW_CSV = 'data_detailed';
const ISSUES_SUMMARY_CSV = 'issues_summary';
const ISSUES_RAW_CSV = 'issues_detailed';
const PRODUCTS_SUMMARY_CSV = 'products_summary';
const PRODUCTS_RAW_CSV = 'products_detailed';

const BlockCSVExportModal = (props) => {
    const { visible, onCancel, block } = props;
    return (
        <Modal
            destroyOnClose
            className="auk-modal block-csv__modal"
            title="Export to CSV"
            open={visible}
            onCancel={onCancel}
            footer={
                <div className="d-flex">
                    <AukButton.Outlined onClick={onCancel}>Cancel</AukButton.Outlined>
                </div>
            }
        >
            <BlockCSVExportForm block={block} cancel={onCancel} />
        </Modal>
    );
};

const BlockCSVExportForm = ({ block, cancel }) => {
    const [form] = Form.useForm();
    const appState = useSelector((s) => s);
    const controls = controlsState(appState);
    const oeeCategories = oeeCategoriesSelector(appState);
    const { entity_id } = currentEntitySelector(appState);
    const metadataResource = metadataState(appState);
    const fusionsResource = fusionsState(appState);
    const blocksResource = blocksSelector(appState);
    const assetsResource = assetsSelector(appState);
    const showLabelNotes = !!(
        appState.preference.entity.options &&
    appState.preference.entity.options.label_notes
    );

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [exports, setExports] = useState();

    let worker; // csv worker

    const assetIds = useMemo(
        () => getAllLeaves(block, (b) => b.asset_id),
        [block]
    ).map((b) => b.asset_id);

    const prepareExport = async () => {
        try {
            const fetchOEECSV =
        formData[OEE_RAW_CSV] ||
        formData[OEE_SUMMARY_CSV] ||
        formData[ISSUES_RAW_CSV] ||
        formData[ISSUES_SUMMARY_CSV];
            const fetchDataCSV = formData[DATA_RAW_CSV] || formData[DATA_SUMMARY_CSV];
            const fetchLabelsCSV =
        formData[ISSUES_RAW_CSV] ||
        formData[ISSUES_SUMMARY_CSV] ||
        formData[PRODUCTS_RAW_CSV] ||
        formData[PRODUCTS_SUMMARY_CSV];

            const dataQuery = dataApiQueryParams(controls);
            const { date_range } = dataQuery;
            const rangeStart = moment(date_range.lower);
            const rangeEnd = moment(date_range.upper);

            const promises = [
                fetchOEECSV &&
          api_getBlockOEEData(entity_id, block.block_id, dataQuery),
                fetchDataCSV && api_getAssetsChartsData(entity_id, assetIds, dataQuery),
                fetchLabelsCSV &&
          api_getAssetsLabels(entity_id, assetIds, { date_range }),
            ];

            setLoading(true);
            setSubmitted(true);
            const [oeeData, chartsData, labelsData] = await Promise.all(promises);
            const message = {
                showLabelNotes,
                date_range: mapValues(date_range, (m) => m.toDate()),
                csv: formData,
                oeeCategories,
                oeeData:
          oeeData &&
          depthFirstTraversal(oeeData, (b) => {
              b.children &&
              b.children.sort(
                  (first, second) =>
                      blocksResource[first.block_id].order -
                  blocksResource[second.block_id].order
              );
          }).map((b) => {
              const block = blocksResource[b.block_id];
              const { asset_id, asset_name = '' } = block.asset || {}
              const block_name = block.block_name || '';
              return {
                  ...b,
                  ...OEEFactory(b),
                  asset_id,
                  asset_name,
                  block_name,
              };
          }),
                chartsData:
          chartsData &&
          chartsData.map((d) => {
              const tsSeries = TimeSeriesFactory(d.data);
              delete tsSeries.aggregate;

              const chart = d.metadata_id
                  ? metadataResource[d.metadata_id]
                  : fusionsResource[d.fusion_id];
              const { mode, units, asset_id, chart_title } = chart;
              const { asset_name } = assetsResource[asset_id];
              return {
                  ...d,
                  data: tsSeries,
                  mode,
                  units,
                  asset_name,
                  chart_title,
              };
          }),
                labelsData:
          labelsData &&
          labelsData
              .reduce((acc, curr) => acc.concat(...curr), [])
              .map((l) => {
                let {
                    attributes,
                    from,
                    to,
                    issue,
                    sku,
                    skuStdTime,
                    asset,
                    notes,
                } = new Label(...parseLabelArguments(l));
                if (issue)
                    issue = {
                        ...issue,
                    };
                return {
                    ...l,
                    from,
                    to,
                    attributes,
                    issue,
                    sku,
                    skuStdTime,
                    asset_name: asset.asset_name,
                    notes,
                };
            })
              .filter((l) => isLabelInRange(l, rangeStart, rangeEnd) && !l.issue),
            };

            worker = new window.Worker('../../../../workers/block-csv.js');
            worker.postMessage(message);
            worker.onerror = (err) => {
                throw err;
            };
            worker.onmessage = (e) => {
                const result = pickBy(e.data.response, (v) => v);
                const files = keys(result).map((k) => ({
                    fileName: `${k}.csv`,
                    data: result[k],
                }));

                setLoading(false);
                setExports(files);
                worker.terminate();
            };
        } catch (e) {
            errorFlash(e);
        }
    };

    const download = () => {
        multiSaveCsv(exports);
        cancel();
    };

    useEffect(() => () => worker && worker.terminate(), []);
    useEffect(() => () => form && form.resetFields(), []);
    const disableOK = useMemo(
        () =>
            isEmpty(
                pickBy(formData, (v) => {
                    return v;
                })
            ),
        [formData]
    );

    return (
        <Form
            name="csvForm"
            id="csvForm"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            className="block-csv__form"
            onValuesChange={(changed, all) => setFormData(all)}
        >
            <Form.Item label="OEE">
                <div className="d-flex">
                    <Form.Item valuePropName="checked" noStyle name={OEE_SUMMARY_CSV}>
                        <Checkbox disabled={submitted}>Summary</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" noStyle name={OEE_RAW_CSV}>
                        <Checkbox disabled={submitted}>Detailed</Checkbox>
                    </Form.Item>
                </div>
            </Form.Item>
            <Form.Item label="Data">
                <div className="d-flex">
                    <Form.Item valuePropName="checked" noStyle name={DATA_SUMMARY_CSV}>
                        <Checkbox disabled={submitted}>Summary</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" noStyle name={DATA_RAW_CSV}>
                        <Checkbox disabled={submitted}>Detailed</Checkbox>
                    </Form.Item>
                </div>
            </Form.Item>
            <Form.Item label="Issue Labels">
                <div className="d-flex">
                    <Form.Item valuePropName="checked" noStyle name={ISSUES_SUMMARY_CSV}>
                        <Checkbox disabled={submitted}>Summary</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" noStyle name={ISSUES_RAW_CSV}>
                        <Checkbox disabled={submitted}>Detailed</Checkbox>
                    </Form.Item>
                </div>
            </Form.Item>
            <Form.Item label="Product Labels">
                <div className="d-flex">
                    <Form.Item
                        valuePropName="checked"
                        noStyle
                        name={PRODUCTS_SUMMARY_CSV}
                    >
                        <Checkbox disabled={submitted}>Summary</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" noStyle name={PRODUCTS_RAW_CSV}>
                        <Checkbox disabled={submitted}>Detailed</Checkbox>
                    </Form.Item>
                </div>
            </Form.Item>
            <Form.Item colon={false} label=" ">
                <div className="d-flex">
                    <AukButton.Outlined
                        className="mr-2"
                        disabled={submitted || disableOK}
                        onClick={prepareExport}
                    >
            Get Files
                    </AukButton.Outlined>
                    {loading ? (
                        <Form.Item colon={false} label=" ">
                            <AukButton.Blue loading={loading} disabled>
                Preparing data for export. Please wait...
                            </AukButton.Blue>
                        </Form.Item>
                    ) : exports ? (
                        <Form.Item colon={false} label=" ">
                            <AukButton.Blue onClick={download}>
                Your files are ready for download.
                            </AukButton.Blue>
                        </Form.Item>
                    ) : null}
                </div>
            </Form.Item>
        </Form>
    );
};

export default BlockCSVExportModal;
