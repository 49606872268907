export const UserConstants = {
    ACTIONS: {
        FETCH_USERS_SYSTEM_REQUEST: 'FETCH_USERS_SYSTEM_REQUEST',
        FETCH_USERS_SYSTEM_SUCCESS: 'FETCH_USERS_SYSTEM_SUCCESS',
        FETCH_USERS_SYSTEM_FAILURE: 'FETCH_USERS_SYSTEM_FAILURE',
        CHANGE_USER_ROLE_REQUEST: 'CHANGE_USER_ROLE_REQUEST',
        CHANGE_USER_ROLE_FAILURE: 'CHANGE_USER_ROLE_FAILURE',
        DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
        DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
    },
};
