import "./style.scss"
import React from "react"
import { ButtonBase } from "@mui/material"
import classNames from "classnames"
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined"

const BackButton = (props: Props) => {
    return <ButtonBase onClick={props.onPress} className={classNames("btn-back", props.className)}>
        <ChevronLeftOutlinedIcon/>
        <span>Back</span>
    </ButtonBase>
}

interface Props {
    className?: string
    onPress?: () => void
}

export default BackButton
