import { pickBy } from 'lodash';
import { store } from '../../store';

export const parseCanvasArguments = (c) => {
    return [
        c.canvas_id === undefined ? -1 : c.canvas_id,
        c.type || 'dashboard',
        c.order || 0,
        c.label,
    ];
};

export class Canvas {
    constructor(canvas_id, type, order, label) {
        this.canvas_id = canvas_id;
        this.type = type;
        this.order = order;
        this.label = label;
    }

    get widgets() {
        const { widgets: widgetsResource } = store.getState().widgets;

        return pickBy(widgetsResource, (w) => w.canvas_id === this.canvas_id);
    }
}
