import type React from "react"
import { useEffect, useState } from "react"
import { errorFlash } from "../legacy/components/Flash"
import { Label } from "../legacy/models"

const useLabelCollision = (promise: () => Promise<any>, selection: Label | undefined, deps: React.DependencyList | undefined) => {
    const [labelled, setLabelled] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        promise()
            .then((result: any) => {
                if (selection) {
                    result = result.filter((l: any) => l.label_id !== selection.label_id)
                }
                if (result.length) { 
                    setLabelled(true)
                    return
                }
                setLabelled(false)
            })
            .catch(errorFlash)
            .finally(() => setLoading(false))
    }, deps)

    return { labelled, loading }
}

export default useLabelCollision