import { api_getAssetOEEData, api_getChannelData, api_getFusionData } from '../../Data/Data.services';
import { api_getAssetLabels } from '../../Labels/Labels.services';

export const api_getRowData = (entity_id, rowData) => {
    const {
        asset_id,
        chart_id,
        data_type,
        start_date,
        resolution: { res_x, res_period },
        span: { x: span_x, unit: span_unit },
        sku_oee,
    } = rowData;

    const end_date = start_date.clone().add(span_x, span_unit);
    const date_range = {
        lower: start_date.toISOString(),
        upper: end_date.toISOString(),
    };

    const query = { res_x, res_period, date_range, sku_oee };

    const promises = [
        api_getAssetOEEData(entity_id, asset_id, query),
        api_getAssetLabels(asset_id, { date_range }),
    ];

    if (data_type === 'metadata') {
        promises.push(api_getChannelData(entity_id, chart_id, query));
    } else if (data_type === 'fusion') {
        promises.push(api_getFusionData(entity_id, chart_id, query));
    }

    return Promise.all(promises);
};
