export const CorrelationConstants = {
    ACTIONS: {
        FETCH_CORRELATIONS_REQUEST: 'FETCH_CORRELATIONS_REQUEST',
        FETCH_CORRELATIONS_REQUEST_SUCCESS: 'FETCH_CORRELATIONS_REQUEST_SUCCESS',
        CREATE_CORRELATION_REQUEST: 'CREATE_CORRELATION_REQUEST',
        CREATE_CORRELATION_REQUEST_SUCCESS: 'CREATE_CORRELATION_REQUEST_SUCCESS',
        UPDATE_CORRELATION_REQUEST: 'UPDATE_CORRELATION_REQUEST',
        UPDATE_CORRELATION_REQUEST_SUCCESS: 'UPDATE_CORRELATION_REQUEST_SUCCESS',
        DELETE_CORRELATION_REQUEST: 'DELETE_CORRELATION_REQUEST',
        DELETE_CORRELATION_REQUEST_SUCCESS: 'DELETE_CORRELATION_REQUEST_SUCCESS',
    },
};
