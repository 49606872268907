import { PreferenceConstants as K } from './Preference.constants';

export const changeUserLanguage = (language) => {
    return {
        type: K.ACTIONS.CHANGE_USER_LANGUAGE,
        payload: { language },
    };
};

// GET ENTITY PREFERENCES
export const setEntityPreferences = (pref) => {
    return {
        type: K.ACTIONS.SET_ENTITY_PREFERENCES,
        payload: {
            entity: pref,
        },
    };
};

// export const getEntityPreferencesRequest = (callback) => {
//     return {
//         type: K.ACTIONS.GET_ENTITY_PREFERENCES_REQUEST,
//         callback,
//     };
// };

// export const GetEntityPreferencesSuccess = (entity) => {
//     return {
//         type: K.ACTIONS.GET_ENTITY_PREFERENCES_SUCCESS,
//         payload: {
//             entity,
//         },
//     };
// };

// export const GetEntityPreferencesFailure = (error) => {
//     return {
//         type: K.ACTIONS.GET_ENTITY_PREFERENCES_FAILURE,
//         payload: {
//             error,
//         },
//     };
// };

// CREATE ENTITY PREFERENCES
export const createEntityPreferencesRequest = (entity, callback) => {
    return {
        type: K.ACTIONS.CREATE_ENTITY_PREFERENCES_REQUEST,
        payload: {
            entity,
        },
        callback,
    };
};

export const createEntityPreferencesSuccess = (entity) => {
    return {
        type: K.ACTIONS.CREATE_ENTITY_PREFERENCES_SUCCESS,
        payload: {
            entity,
        },
    };
};

export const createEntityPreferencesFailure = (error) => {
    return {
        type: K.ACTIONS.CREATE_ENTITY_PREFERENCES_FAILURE,
        payload: {
            error,
        },
    };
};

// UPDATE ENTITY PREFERENCES
export const updateEntityPreferencesRequest = (entity, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_PREFERENCES_REQUEST,
        payload: {
            entity,
        },
        callback,
    };
};

export const updateEntityPreferencesSuccess = (entity) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_PREFERENCES_SUCCESS,
        payload: {
            entity,
        },
    };
};

export const updateEntityPreferencesFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_ENTITY_PREFERENCES_FAILURE,
        payload: {
            error,
        },
    };
};

// GET USER PREFERENCES
export const setUserPreferences = (pref) => {
    return {
        type: K.ACTIONS.SET_USER_PREFERENCES,
        payload: {
            user: pref,
        },
    };
};

// export const getUserPreferencesRequest = (callback) => {
//     return {
//         type: K.ACTIONS.GET_USER_PREFERENCES_REQUEST,
//         callback,
//     };
// };

// export const GetUserPreferencesSuccess = (user) => {
//     return {
//         type: K.ACTIONS.GET_USER_PREFERENCES_SUCCESS,
//         payload: {
//             user,
//         },
//     };
// };

// export const GetUserPreferencesFailure = (error) => {
//     return {
//         type: K.ACTIONS.GET_USER_PREFERENCES_FAILURE,
//         payload: {
//             error,
//         },
//     };
// };

// CREATE USER PREFERENCES
export const createUserPreferencesRequest = (user, callback) => {
    return {
        type: K.ACTIONS.CREATE_USER_PREFERENCES_REQUEST,
        payload: {
            user,
        },
        callback,
    };
};

export const createUserPreferencesSuccess = (user) => {
    return {
        type: K.ACTIONS.CREATE_USER_PREFERENCES_SUCCESS,
        payload: {
            user,
        },
    };
};

export const createUserPreferencesFailure = (error) => {
    return {
        type: K.ACTIONS.CREATE_USER_PREFERENCES_FAILURE,
        payload: {
            error,
        },
    };
};

// UPDATE USER PREFERENCES
export const updateUserPreferencesRequest = (user, callback) => {
    return {
        type: K.ACTIONS.UPDATE_USER_PREFERENCES_REQUEST,
        payload: {
            user,
        },
        callback,
    };
};

export const updateUserPreferencesSuccess = (user) => {
    return {
        type: K.ACTIONS.UPDATE_USER_PREFERENCES_SUCCESS,
        payload: {
            user,
        },
    };
};

export const updateUserPreferencesFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_USER_PREFERENCES_FAILURE,
        payload: {
            error,
        },
    };
};

// SET OPERATOR CONSOLE PREFERENCES
export const setAssetOperatorPreferences = (asset) => {
    return {
        type: K.ACTIONS.SET_ASSET_OPERATOR_PREFERENCES,
        payload: {
            asset,
        },
    };
};
