import React, { forwardRef } from "react"

const getValidationError = (error?: string, label?: string) => {
    switch (error) {
        case "required":
            return `${label ? label[0].toUpperCase() + label.slice(1).toLowerCase() : "This field"} is required`
        case "emailValidation":
            return "Must be a valid email address"
        case "passwordLengthValidation":
            return "Password must be between 8-20 characters long"
        case "passwordStrengthValidation":
            return "Must contain at least 1 uppercase letter, 1 lowercase letter and 1 numeral"
        case "passwordMustMatchValidation":
            return "Password does not match"
    }

    return undefined
}

const FormField = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const errorText = getValidationError(props.error, props.label)
    return <div ref={ref} className={props.className}>
        {props.render({ errorText, label: props.label })}
    </div>
})

interface Props {
    className?: string
    label?: string
    error?: string
    render: (props: any) => React.ReactNode
}

FormField.displayName = "FormField"

export default FormField
