/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';

export const Axis = (props) => {
    const { getTickLabel, line, ticks, handleClick, position, label } = props;
    const attributes = props.attributes || {};

    return (
        <>
            <g transform={`translate(${position.left}, ${position.top})`}>
                {
                    line && <rect {...line} />
                }
                {
                    ticks.map((tick, i) => {
                        const attr = 
              Object.keys(attributes)
                  .reduce((acc, curr) => {
                      acc[curr] = 
                    _.isFunction(attributes[curr]) ? 
                        attributes[curr](tick) : 
                        attributes[curr];
                      return acc;
                  }, {});

                        return (
                            <text 
                                {...attr} 
                                key={i} 
                                onClick={(e) => handleClick && handleClick(e, tick)}
                            >
                                { getTickLabel ? getTickLabel(tick) : tick }
                            </text>
                        );
                    })
                }
                { 
                    label && <text textAnchor="middle" {...label}>{label.label}</text>
                }
            </g>
        </>
    );
}