import { GridColDef } from "@mui/x-data-grid"
import React from "react"
import { List, OnScrollParams } from "react-virtualized"
import { ListRowRenderer } from "react-virtualized/dist/es/List"
import TreeTableRow, { Row } from "../TreeTableRow/TreeTableRow"

const TreeTablePane = <T extends Row, >(props: Props<T>) => {
    const rowRenderer: ListRowRenderer = ({
        index,
        isScrolling,
        key,
        style,
    }) => {
        return <TreeTableRow<T>
            key={key}
            style={style}
            index={index}
            isScrolling={isScrolling}
            columns={props.columns}
            rows={props.rows}
        />
    }

    const columnsWidth = props.columns.reduce((sum, column) => sum + (column.width ?? 0), 0)
    const width: number = props.width !== undefined && columnsWidth < props.width ? props.width : columnsWidth + 20
    return <List
        rowCount={props.rows.length}
        height={props.height - 20}
        width={width}
        rowHeight={props.rowHeight}
        rowRenderer={rowRenderer}
    />
}

interface Props<T> {
    columns: GridColDef[]
    height: number
    rowHeight: number
    rows: T[]
    width?: number
    onScroll?: (params: OnScrollParams) => void
    scrollTop?: number
}

export default TreeTablePane
