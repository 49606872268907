import { updateStoreRedux } from '../../../legacy/utils/redux';
import { SchedulesConstants as K } from './Schedules.constants';
import { pickBy } from 'lodash';

const initialReducer = () => {
    return {
        shifts: {},
        downtimes: {},
        filter: K.FILTER_BY.ACTIVE,
        initialized: false,
        assets: {},
    };
};

export const schedulesReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_SCHEDULES_FILTER) {
        return updateStoreRedux(state, {
            filter: action.payload.filter,
        });
    }

    if (action.type === K.ACTIONS.SET_SCHEDULES_STATE) {
        return updateStoreRedux(state, {
            shifts: { ...action.payload.shifts },
            downtimes: { ...action.payload.downtimes },
            initialized: true,
        });
    }

    if (action.type === K.ACTIONS.SET_SHIFTS_RESOURCE) {
        return updateStoreRedux(state, {
            shifts: { ...action.payload.shifts },
        });
    }

    if (action.type === K.ACTIONS.ADD_SHIFTS_RESOURCE) {
        return updateStoreRedux(state, {
            shifts: { ...state.shifts, ...action.payload.shifts },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_SHIFTS_RESOURCE) {
        const deleteSet = new Set(action.payload.shiftIds);
        const deletedState = pickBy(
            { ...state.shifts },
            ({ shift_id }) => !deleteSet.has(shift_id)
        );

        return updateStoreRedux(state, {
            shifts: deletedState,
        });
    }

    if (action.type === K.ACTIONS.SET_DOWNTIMES_RESOURCE) {
        return updateStoreRedux(state, {
            downtimes: { ...action.payload.downtimes },
        });
    }

    if (action.type === K.ACTIONS.ADD_DOWNTIMES_RESOURCE) {
        return updateStoreRedux(state, {
            downtimes: { ...state.downtimes, ...action.payload.downtimes },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_DOWNTIMES_RESOURCE) {
        const deleteSet = new Set(action.payload.downtimeIds);
        const deletedState = pickBy(
            { ...state.downtimes },
            ({ downtime_id }) => !deleteSet.has(downtime_id)
        );

        return updateStoreRedux(state, {
            downtimes: deletedState,
        });
    }

    if (action.type === K.ACTIONS.ADD_ASSET_SHIFTS_RESOURCE) {
        return updateStoreRedux(state, {
            assets: { ...state.assets, ...action.payload.assetShifts },
        });
    }

    if (action.type === K.ACTIONS.RESET_SCHEDULES_STORE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
