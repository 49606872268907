import React from 'react';

import Select from './Select';

const SelectWithDisabledOptions = (props) => (
    <Select
        {...props}
        styles={{
            option: (styles, { data, isFocused }) => {
                const defaults = {
                    ...styles,
                    backgroundColor: isFocused ? 'gray' : 'white',
                    color: isFocused ? 'white' : 'inherit',
                };
                const custom = Object.assign(
                    {},
                    defaults,
                    data.disabled && {
                        backgroundColor: 'white',
                        color: 'lightgray',
                        pointerEvents: 'none',
                    }
                );
                return custom;
            },
        }}
    />
);

export default SelectWithDisabledOptions;
