export const BlocksConstants = {
    ACTIONS: {
        SET_BLOCKS_STATE: 'SET_BLOCKS_STATE',
        ADD_BLOCKS_RESOURCE: 'ADD_BLOCKS_RESOURCE',
        REMOVE_BLOCKS_RESOURCE: 'REMOVE_BLOCKS_RESOURCE',
        RESET_BLOCKS_STATE: 'RESET_BLOCKS_STATE',
        GET_BLOCKS_REQUEST: 'GET_BLOCKS_REQUEST',
        CREATE_BLOCK_REQUEST: 'CREATE_BLOCK_REQUEST',
        UPDATE_BLOCKS_REQUEST: 'UPDATE_BLOCKS_REQUEST',
        UPDATE_BLOCK_REQUEST: 'UPDATE_BLOCK_REQUEST',
        DELETE_BLOCK_REQUEST: 'DELETE_BLOCK_REQUEST',
        SET_BLOCK_BOTTLENECK: 'SET_BLOCK_BOTTLENECK',
        GET_BLOCK_ISSUES_REQUEST: 'GET_BLOCK_ISSUES_REQUEST',
        GET_BLOCKS_ISSUES_REQUEST: 'GET_BLOCKS_ISSUES_REQUEST',
        UPDATE_BLOCKS_ISSUES_REQUEST: 'UPDATE_BLOCKS_ISSUES_REQUEST',
        UPDATE_BLOCK_USERS_REQUEST: 'UPDATE_BLOCK_USERS_REQUEST',
        ADD_BLOCKS_ISSUES_RESOURCE: 'ADD_BLOCKS_ISSUES_RESOURCE',
    },
};
