import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT
import LoadingScreen from '../../components/Loading';

import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

import { skuState } from '../../../store/old/Sku/Sku.selector';
import { fetchSkus } from '../../../store/old/Sku/Sku.action';

export function withSkus(Component) {
    return function WrapperComponent(props) {
        const { entity_id } = useSelector(currentEntitySelector);
        const skuStore = useSelector(skuState);
        const { initialized, skus } = skuStore;
        const dispatch = useDispatch();
        useEffect(() => {
            if (initialized) return;
            dispatch(fetchSkus(entity_id));
        }, []);

        return initialized ? (
            <Component {...props} skus={skus} />
        ) : (
            <LoadingScreen description={'Loading SKUs...'} />
        );
    };
}
