import { useMemo } from "react"
import { useAppSelector } from "../store/hooks"
import { currentEntitySelector } from "../store/old/Entity/Entity.selector"
import type EntityModel from "../api/models/entity.model"
import EntityTransformer from "../api/transformers/entity.transformer"

const useCurrentEntity = () => {
    const _entity = useAppSelector(currentEntitySelector)

    const entity: EntityModel = useMemo(() => EntityTransformer(_entity), [_entity])
    return entity
}

export default useCurrentEntity
