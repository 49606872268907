import "react-vis/dist/style.css"
import "./ImpactChart.scss"
import moment from "moment"
import React, { useMemo } from "react"
import { AutoSizer } from "react-virtualized"
import {
    HorizontalGridLines,
    HorizontalRectSeries,
    LabelSeries,
    type LabelSeriesPoint,
    type RVValueEventHandler,
    VerticalBarSeries,
    type VerticalBarSeriesPoint,
    XAxis,
    XYPlot,
    YAxis,
} from "react-vis"
import { IMPACT_COLORS, ImpactEnum } from "../../../constants/impact.constants"
import classNames from "classnames"
import { Popover } from "@mui/material"
import ExportOverlay from "../../../components/ExportOverlay/ExportOverlay"
import { savePng } from "../../../legacy/utils/dataExports"

const ImpactChart = (props: Props) => {
    const mdScreen = useMemo(() => props.screen === "md", [props.screen])
    const chartMargins = useMemo(() => mdScreen ? MARGIN_MD : MARGIN_SM, [mdScreen])
    const data = props.data as VerticalBarSeriesPoint[]

    const dataLabels: LabelSeriesPoint[] = useMemo(() =>
        props.labelData
            .filter(d => d.type === "data" && d.y > 0)
            .map((d) => ({ ...d, yOffset: 10 })),
    [props.labelData])

    const deltaLabels: LabelSeriesPoint[] = useMemo(() =>
        props.labelData
            .filter(d => d.type === "delta" && d.delta > 0)
            .map(d => ({ ...d, yOffset: mdScreen ? -60 : -30 }))
    , [props.labelData])

    const deltaLabelsTitleData = useMemo(() =>
        deltaLabels
            .map(d => ({
                ...d,
                label: `${d.x}*`,
                yOffset: mdScreen ? -30 : -15
            })), [deltaLabels])

    const deltaLabelsSubtitleData = useMemo(() =>
        deltaLabels.map(d => ({
            ...d,
            label: "(ARR)",
            yOffset: mdScreen ? -10 : -7
        })), [deltaLabels])

    const markerData = useMemo(() => {
        if (!props.data.length) return []
        return [{
            x: props.data[props.data.length - 1].x,
            x0: props.data[0].x,
            y: props.data[1].y,
            y0: props.data[1].y,
        }]
    }, [props.data])

    return <AutoSizer>
        {({ width, height }) => {
            const markerWidth = width - chartMargins.left - chartMargins.right
            return <ExportOverlay 
                items={{ toPng: () => savePng(props.id, `${props.id}.png`, { pixelRatio: 2 }) }}
                style={{ height, width, position: "relative" }}
            >
                <div id={props.id} style={{ height, width, position: "relative" }}>
                    <XYPlot
                    // id="impactChart"
                        className="impact-chart"
                        xType="ordinal"
                        width={width}
                        height={height - FOOTNOTE_MARGIN}
                        colorRange={colorRange}
                        colorType="category"
                        strokeType="literal"
                        fillType="literal"
                        margin={chartMargins}
                        yDomain={[0, 100]}
                    >
                        <HorizontalGridLines/>
                        <XAxis tickFormat={(value) => props.xTickFormat ? props.xTickFormat(value, width / data.length * 0.8) : value}/>
                        <YAxis title="OEE (%)" style={{
                            title: { transform: "translate(10px, -10px)" },
                        }}/>
                        <VerticalBarSeries
                            className="impact-chart__bar-series"
                            data={props.data.length > 0 ? data : emptyDataset}
                            barWidth={0.8}
                            onValueClick={props.onClick}
                        />
                        <HorizontalRectSeries
                            className="impact-chart__marker"
                            data={markerData}
                            stroke="#BA1A1A"
                            style={{
                                height: 1,
                                width: markerWidth,
                                transform: `translate(-${markerWidth / (props.data.length * 2)}px, -1px)`
                            }}
                        />
                        <LabelSeries
                            labelAnchorY="text-before-edge"
                            labelAnchorX="middle"
                            data={dataLabels}
                            className="impact-chart__label-point"
                        />
                        <LabelSeries
                            labelAnchorY="text-after-edge"
                            labelAnchorX="middle"
                            data={deltaLabels}
                            className="impact-chart__label-point__title"
                        />
                        <LabelSeries
                            labelAnchorY="text-after-edge"
                            labelAnchorX="middle"
                            data={deltaLabelsTitleData}
                            className="impact-chart__label-point__subtext"
                        />
                        <LabelSeries
                            labelAnchorY="text-after-edge"
                            labelAnchorX="middle"
                            data={deltaLabelsSubtitleData}
                            className="impact-chart__label-point__subtext"
                        />
                    </XYPlot>
                    <div className="impact-chart__header">Select for more information</div>
                    {!props.hideFootnote && <div className="impact-chart__footnote">
                  *ARR = Annual run rate, annualised cost savings/revenue gain based on improved OEE
                    </div>}
                </div>
            </ExportOverlay>
        }}
    </AutoSizer>
}

interface Props {
    id?: string
    data: ImpactComparisonChartSeriesPoint[]
    labelData: LabelSeriesPoint[]
    onClick?: RVValueEventHandler<VerticalBarSeriesPoint>
    xTickFormat?: any
    hideFootnote?: boolean
    screen: "sm" | "md"
}

export interface ImpactComparisonChartSeriesPoint extends VerticalBarSeriesPoint {
    type: "data" | "delta"
    delta?: number
    withAnnualisedValue?: boolean
}

export const getXTickLabel = (label: ImpactEnum, dates: string[] | undefined, size: "md" | "sm"): string[] => {
    const main: string = impactXTickLabels[size][label]

    if (!dates) return [main]

    const formatter = size === "md" ? xTickDateFormatMd : xTickDateFormatSm
    const startsAt = moment(dates[0]).format(formatter)
    const endsAt = moment(dates[1]).format(formatter)

    return [main, startsAt, endsAt]
}

export const ImpactChartXTick = (props: XTickProps) => {
    const [label, startsAt, endsAt] = props.label

    const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null)
    const handleClick = (event: React.MouseEvent<SVGElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? `impactChartPopover-${label}` : undefined

    if (!startsAt) {
        return <g className="impact-chart__x-tick">
            <text textAnchor="middle" >
                <tspan className="impact-chart__x-tick__title" x="0" dy="1em">{label}</tspan>
            </text>
        </g>
    }

    if (!props.popover) {
        return <g className="impact-chart__x-tick">
            <text textAnchor="middle">
                <tspan className="impact-chart__x-tick__title" x="0" dy="1em">{label}</tspan>
                <tspan className="impact-chart__x-tick__subtext" x="0" dy="20px">{startsAt} -</tspan>
                <tspan className="impact-chart__x-tick__subtext" x="0" dy="1em">{endsAt}&nbsp;&nbsp;</tspan>
            </text>
        </g>
    }

    return <g className={classNames({ "impact-chart__x-tick": true, "impact-chart__x-tick--with-popover": !!props.popover })}>
        <text textAnchor="middle"
            onClick={handleClick}>
            <tspan className="impact-chart__x-tick__title" x="0" dy="1em">{label} &#x270E;</tspan>
            <tspan className="impact-chart__x-tick__subtext" x="0" dy="20px">{startsAt} -</tspan>
            <tspan className="impact-chart__x-tick__subtext" x="0" dy="1em">{endsAt}&nbsp;&nbsp;</tspan>
        </text>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            {props.popover({ handleClose })}
        </Popover>
    </g>
}

const xTickDateFormatMd = "DD MMM YYYY"
const xTickDateFormatSm = "YYYY/MM/DD"
const impactXTickLabels = {
    md: {
        [ImpactEnum.BASELINE]: ImpactEnum.BASELINE,
        [ImpactEnum.CURRENT]: ImpactEnum.CURRENT,
        [ImpactEnum.BEST]: ImpactEnum.BEST,
        [ImpactEnum.BENCHMARK_GOAL]: ImpactEnum.BENCHMARK_GOAL,
        [ImpactEnum.CAPTURED_DELTA]: ImpactEnum.CAPTURED_DELTA,
        [ImpactEnum.POTENTIAL_DELTA]: ImpactEnum.POTENTIAL_DELTA,
        [ImpactEnum.BENCHMARK_DELTA]: ImpactEnum.BENCHMARK_DELTA,
    },
    sm: {
        [ImpactEnum.BASELINE]: ImpactEnum.BASELINE,
        [ImpactEnum.CURRENT]: ImpactEnum.CURRENT,
        [ImpactEnum.BEST]: ImpactEnum.BEST,
        [ImpactEnum.BENCHMARK_GOAL]: "Goal",
        [ImpactEnum.CAPTURED_DELTA]: ImpactEnum.CAPTURED_DELTA,
        [ImpactEnum.POTENTIAL_DELTA]: ImpactEnum.POTENTIAL_DELTA,
        [ImpactEnum.BENCHMARK_DELTA]: ImpactEnum.BENCHMARK_DELTA,
    }
}

interface XTickProps {
    label: string[]
    popover?: (popoverProps: ({ handleClose: () => void })) => React.ReactNode
    width?: number
}

const PADDING = 24
const MARGIN_MD = { top: 60 + PADDING, left: 40 + PADDING, right: 40 + PADDING, bottom: 70 + PADDING }
const MARGIN_SM = { top: 60 + PADDING, left: 16 + PADDING, right: PADDING, bottom: 70 + PADDING }
const FOOTNOTE_MARGIN = 22

const colorRange = [IMPACT_COLORS.BASELINE, IMPACT_COLORS.CURRENT, IMPACT_COLORS.BEST, IMPACT_COLORS.BENCHMARK, IMPACT_COLORS.DELTA]
const emptyDataset: VerticalBarSeriesPoint[] = [
    { x: ImpactEnum.BASELINE, y: 0 },
    { x: ImpactEnum.CAPTURED_DELTA, y: 0 },
    { x: ImpactEnum.CURRENT, y: 0 },
    { x: ImpactEnum.POTENTIAL_DELTA, y: 0 },
    { x: ImpactEnum.BEST, y: 0 },
    { x: ImpactEnum.POTENTIAL_DELTA, y: 0 },
    { x: ImpactEnum.BENCHMARK_GOAL, y: 0 },
]

export default ImpactChart
