import _ from 'lodash';

export const trackedIssuesState = (appState) => appState.trackedIssues;

export const arrayTrackedIssues = (appState) => {
    const trackedIssuesMap = trackedIssuesState(appState).trackedIssues;

    return _.values(trackedIssuesMap).sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
    });
};
