
import { intersection, isEqual, keys, omit } from 'lodash';
import PriorityQueue from './PriorityQueue';

// https://javascript.plainenglish.io/from-zero-to-dijkstra-10a19301f299

export default class UndirectedGraph {
    vertices;
    adjacencyList;

    constructor(vertices = [], edges = {}) {
        this.vertices = new Set();
        this.adjacencyList = new Map();

        for (let i = 0; i < vertices.length; i++) {
            this.addVertex(vertices[i]);
        }

        const paths = keys(edges);
        for (let j = 0; j < paths.length; j++) {
            const path = paths[j];
            const [v1, v2] = path.split('-');
            const weight = edges[path];
            this.addEdge(v1, v2, weight);
        }
    }

    addVertex(vertex) {
        this.vertices.add(vertex);
        if (!this.adjacencyList.has(vertex))
            this.adjacencyList.set(vertex, new Map());
    }

    addEdge(v1, v2, weight) {
        const v1Map = this.adjacencyList.get(v1);
        const v2Map = this.adjacencyList.get(v2);

        if (!v1Map || !v2Map) return;

        v1Map.set(v2, weight);
        v2Map.set(v1, weight);
    }

    removeEdge(v1, v2) {
        const v1Map = this.adjacencyList.get(v1);
        const v2Map = this.adjacencyList.get(v2);

        if (!v1Map || !v2Map) return;

        v1Map.delete(v2);
        v2Map.delete(v1);
    }

    removeVertex(vertex) {
        const adjacents = this.adjacencyList.get(vertex);
        if (!adjacents) return;

        const adjacentVertices = adjacents.keys();

        for (let [adjacentVertex] of adjacentVertices) {
            this.removeEdge(vertex, adjacentVertex);
        }

        this.vertices.delete(vertex);
        this.adjacencyList.delete(vertex);
    }

    dijkstra(start, destinations = []) {
        const { vertices, adjacencyList } = this;
        const queue = new PriorityQueue();
        const distances = {};
        const steps = {};
        const visited = new Set();

        destinations = destinations.length ? destinations : Array.from(vertices);

        let current;

        // init distances
        for (let vertex of vertices) {
            const priority = start === vertex ? 0 : Infinity;
            distances[vertex] = priority;
            steps[vertex] = null;
        }

        queue.enqueue(start, 0);
        while (queue.values.length) {
            current = queue.dequeue().value;
            visited.add(current);

            const neighbors = adjacencyList.get(current);
            if (!neighbors) continue;

            if (current || distances[current] !== Infinity) {
                for (let [neighbor] of neighbors) {
                    if (visited.has(neighbor)) continue;

                    let nextWeight = neighbors.get(neighbor);
                    let candidate = distances[current] + nextWeight;

                    if (candidate < distances[neighbor]) {
                        distances[neighbor] = candidate;
                        steps[neighbor] = current;

                        // enqueue priority queue with new smallest distance to move along shortest path
                        queue.enqueue(neighbor, candidate);
                    }
                }
            }

            if (
                isEqual(
                    new Set(intersection(Array.from(visited), destinations)),
                    new Set(destinations)
                )
            ) {
                break;
            }
        }

        const paths = destinations.reduce((acc, curr) => {
            let position = curr;
            const path = [curr];

            while (steps[position]) {
                path.push(steps[position]);
                position = steps[position];
            }

            return {
                ...acc,
                [curr]: { path: path.reverse(), distance: distances[curr] },
            };
        }, {});

        return paths;
    }
}

// const PASS = 40;
// const FAIL = 65;

// export const dummyGraphData = [
//   {
//     type: 'gateway',
//     node: 'A',
//     links: [
//       { node: 'F', rssi: PASS },
//       { node: 'B', rssi: PASS },
//       { node: 'C', rssi: PASS },
//     ],
//   },
//   {
//     type: 'node',
//     node: 'B',
//     links: [
//       { node: 'A', rssi: FAIL },
//       { node: 'G', rssi: PASS },
//       { node: 'D', rssi: PASS },
//     ],
//   },
//   {
//     type: 'relay',
//     node: 'F',
//     links: [
//       { node: 'A', rssi: PASS },
//       { node: 'G', rssi: PASS },
//     ],
//   },
//   {
//     type: 'relay',
//     node: 'C',
//     links: [
//       { node: 'A', rssi: PASS },
//       { node: 'D', rssi: PASS },
//       { node: 'E', rssi: PASS },
//     ],
//   },
//   {
//     type: 'relay',
//     // type: 'node',
//     node: 'G',
//     links: [
//       { node: 'F', rssi: PASS },
//       { node: 'B', rssi: PASS },
//       { node: 'D', rssi: PASS },
//       { node: 'H', rssi: PASS },
//     ],
//   },
//   {
//     type: 'node',
//     node: 'D',
//     links: [
//       { node: 'B', rssi: PASS },
//       { node: 'C', rssi: PASS },
//       { node: 'E', rssi: PASS },
//       { node: 'G', rssi: PASS },
//     ],
//   },
//   {
//     type: 'node',
//     node: 'E',
//     links: [
//       { node: 'C', rssi: PASS },
//       { node: 'D', rssi: PASS },
//       { node: 'H', rssi: PASS },
//     ],
//   },
//   {
//     type: 'node',
//     node: 'H',
//     links: [
//       { node: 'E', rssi: PASS },
//       { node: 'G', rssi: PASS },
//     ],
//   },
// ];
