
import { put, call, all, take, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import CONSTANTS from '../../../legacy/Constants';

// MODEL
import { Entity, parseEntityArguments } from '../../../legacy/models';

// ACTION
import {
    setOrganizationState,
    getEntityInformationRequest,
    addEntitiesResource,
    setActiveEntity,
} from './Entity.action';

import { appInitFlow } from '../Shared/Shared.action';

import { store } from '../..';
import { session } from '../../../legacy/auxStore';

// ENTITY SERVICES
import {
    api_getOrganization,
    api_getEntity,
    api_updateEntity,
} from './Entity.services';
import { EntityConstants as K } from './Entity.constants';
import { flattenTree } from '../../../legacy/utils/helpers';
import { setEntityPreferences } from '../Preference/Preference.action';
import { errorFlash } from '../../../legacy/components/Flash';
import { softResetStore } from '../Root.actions';


function* handleGetOrganisationRequest(action) {
    try {
        const { entity_id } = action.payload;
        const { active } = store.getState().entity;

        const res = yield call(api_getOrganization, entity_id);
        const entities = flattenTree(res, (node) => node.children).map(
            (e) => new Entity(...parseEntityArguments(e))
        );

        yield put(setOrganizationState(entities));
        yield* handleGetEntityInformation({ payload: { entity_id: active } });
        yield put(appInitFlow(active));

        action.callback && action.callback(entities);
    } catch (e) {
        errorFlash(e);
    }
}

export function* fetchOrgRequestSaga() {
    yield takeLatest(
        K.ACTIONS.GET_ORGANIZATION_REQUEST,
        handleGetOrganisationRequest
    );
}

function* handleChangeEntity(action) {
    try {
        const {
            payload: { entity_id },
            callback,
        } = action;

        if (session.isAuthenticated) {
            yield put(softResetStore());

            yield all([
                put(setActiveEntity(entity_id)),
                put(getEntityInformationRequest(entity_id)),
                put(appInitFlow(entity_id)),
            ]);

            yield take(CONSTANTS.REDUX_STORE.INITIALIZE);
            callback && callback(store.getState());
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* changeEntitySaga() {
    yield takeLatest(K.ACTIONS.CHANGE_ENTITY_FLOW, handleChangeEntity);
}

// GET ENTITY SERVICE
function* handleGetEntityInformation(action) {
    try {
        const response = yield api_getEntity(action.payload.entity_id);

        !isEmpty(response.preference) &&
      (yield put(setEntityPreferences(response.preference)));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getEntityInformation() {
    yield takeLatest(
        K.ACTIONS.GET_ENTITY_INFORMATION_REQUEST,
        handleGetEntityInformation
    );
}

// UPDATE ENTITY
function* handleUpdateEntity(action) {
    try {
        const { entity_id } = action.payload.entity;
        const response = yield api_updateEntity(action.payload.entity);

        const { entities } = store.getState().entity;
        const entity = entities[entity_id];

        yield put(
            addEntitiesResource({
                [entity_id]: new Entity(
                    ...parseEntityArguments({ ...entity, ...response })
                ),
            })
        );

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateEntitySaga() {
    yield takeLatest(K.ACTIONS.UPDATE_ENTITY, handleUpdateEntity);
}
