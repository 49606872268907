import React from "react"
import { Outlet } from "react-router-dom"
import LanguageButton from "../Button/LanguageButton/LanguageButton"

const LayoutUnauthenticated = () => {
    return <div>
        <LanguageButton className="language"/>
        <Outlet/>
    </div>
}

export default LayoutUnauthenticated
