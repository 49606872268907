import * as d3 from 'd3';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { BaseChartSimple } from './BaseChart';
import { genColor, clearCanvas, canvasPixelRatio } from '../../utils/charts';

import './Charts_V1.scss';

export const getStackBarBandwidth = (d, x) => {
    return x(d[1]) - x(d[0]);
};

export const getStackBarLabels = (d, x, y) => {
    const minWidthForLabels = 50;

    const { key, data } = d;

    const percent = data[key];
    const width = getStackBarBandwidth(d, x);

    if (!percent) return '';
    if (percent <= 0.0 || width < minWidthForLabels) return '';

    return `${percent.toFixed(1)}%`;
};

export const getStackBarYRange = (domain) => {
    return (h) =>
        domain.map((ele, i) => {
            const step = h / 3;
            return i === 0 ? 0 : i * step;
        });
};

export class StackBarChartV1Simple extends BaseChartSimple {
    constructor(props) {
        super(props);

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    redrawCustom() {
        const {
            x,
            y,
            h,
            custom,
            props: {
                transition,
                duration,
                data,
                getBandwidth,
                useDataLabels,
                getDataLabels,
                keys,
                yAccessor,
                yDomain,
                colorAccessor,
            },
        } = this;

        const generated = d3.stack().keys(keys)(data);
        const stack = generated
            .map((dataFrame, i) => {
                const k = dataFrame.key;
                dataFrame.forEach((point) => (point.key = k));
                return dataFrame;
            })
            .reduce((acc, curr) => acc.concat(curr), [])
            .reverse();

        const t = transition || null;
        this.barheight = h / yDomain.length - 1;

        /* bars */
        const selection = custom.selectAll('.bar').data(stack, (d, i) => {
            return `${i}${d.key}${d[0]}${d[1]}`;
        });

        // enter
        selection
            .enter()
            .append('custom')
            .attr('class', 'bar')
            .attr('x', (d) => x(d[0]) + 1)
            .attr('y', (d, i) => y(yAccessor(d.data)))
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', (d) => this.barheight)
            .attr('fill', (d) => colorAccessor(d));

        // update
        selection
            .transition(t)
            .duration(duration)
            .attr('x', (d) => x(d[0]) + 1)
            .attr('y', (d) => y(yAccessor(d.data)))
            .attr('width', (d) => getBandwidth(d, x))
            .attr('height', (d) => this.barheight);

        // exit
        selection.exit().remove();

        /* bar labels */
        if (useDataLabels) {
            const textSelection = custom.selectAll('.text').data(stack, (d, i) => {
                return `${i}${d.key}${d[0]}${d[1]}`;
            });

            // enter
            textSelection
                .enter()
                .append('custom')
                .attr('class', 'text')
                .attr('text', (d) => getDataLabels(d, x, y))
                .attr('x', (d) => x(d[0]) + getBandwidth(d, x) / 2)
                .attr('y', (d) => y(yAccessor(d.data)) + this.barheight / 2 + 4);

            // update
            textSelection
                .transition(t)
                .duration(duration)
                .attr('text', (d) => getDataLabels(d, x, y))
                .attr('x', (d) => x(d[0]) + getBandwidth(d, x) / 2)
                .attr('y', (d) => y(yAccessor(d.data)) + this.barheight / 2 + 4);

            // exit
            textSelection.exit().remove();
        }
    }

    redrawCanvas() {
        const { canvas, custom, w, h } = this;

        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        // draw bars
        const bars = custom.selectAll('.bar');
        bars.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;

            context.beginPath();
            context.fillStyle = node.attr('fill');
            context.rect(
                +node.attr('x'),
                +node.attr('y'),
                +node.attr('width'),
                +node.attr('height')
            );
            context.fill();
            context.closePath();
        });

        // draw text
        const labels = custom.selectAll('.text');
        labels.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;

            context.font = `italic 10px sans-serif`;
            context.fillStyle = '#222';
            context.textAlign = 'center';
            context.fillText(node.attr('text'), node.attr('x'), node.attr('y'));
        });
    }

    redrawHidden() {
        const { hidden, custom, w, h, colorToNode } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const bars = custom.selectAll('.bar');
        bars.each(function (d, i) {
            const node = d3.select(this);

            if (node.empty()) return;
            
            hiddenContext.beginPath();
            const color = genColor(i);
            colorToNode.set(color, d);

            hiddenContext.fillStyle = color;
            hiddenContext.rect(
                +node.attr('x'),
                +node.attr('y'),
                Math.max(1, +node.attr('width')),
                // +node.attr('width'),
                +node.attr('height')
            );
            hiddenContext.fill();
            hiddenContext.closePath();
        });
    }

    handleMouseMove(DOMnode) {
        const {
            x,
            y,
            h,
            props: { getBandwidth, htmlTooltip, useTooltip, yAccessor },
            hidden,
        } = this;

        if (useTooltip) {
            const { clientX, clientY } = window.event;
            const [mouseX, mouseY] = d3.mouse(DOMnode);
            const hiddenContext = hidden.node().getContext('2d');
            const pixelRatio = canvasPixelRatio(hiddenContext);
            const [r, g, b] = hiddenContext.getImageData(
                mouseX * pixelRatio,
                mouseY * pixelRatio,
                1,
                1
            ).data;
            const color = `rgb(${r},${g},${b})`;
            const d = this.colorToNode.get(color);

            if (!d) return this.handleMouseLeave();

            this.focus
                .attr('width', getBandwidth(d, x))
                .attr('height', this.barheight)
                .attr('opacity', 0.2)
                .attr(
                    'transform',
                    `translate(${x(d[0]) + 1}, ${y(yAccessor(d.data))})`
                );

            this.tooltip
                .style('top', `${clientY + 10}px`)
                .style('left', `${clientX + 10}px`)
                .style('opacity', 0.8)
                .style('visibility', 'visible')
                .html(htmlTooltip && htmlTooltip(d));
        }
    }
}

export class StackBarChartV1 extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <StackBarChartV1Simple
                        {...this.props}
                        width={width}
                        height={height}
                    />
                )}
            </AutoSizer>
        );
    }
}

StackBarChartV1.defaultProps = {
    colorAccessor: () => 'steelblue',
    data: [],
    keys: [],
    xScale: d3.scaleLinear(),
    yScale: d3.scaleOrdinal(),
    getBandwidth: getStackBarBandwidth,
    xDomain: [0, 100],
    hideYAxis: true,
    useBrush: false,
    useDataLabels: true,
    useDataLabels: true,
    getDataLabels: (d) => d,
    htmlTooltip: (d) => d,
    useTooltip: true,
    xTickFormat: (d) => d,
};

// exmaple OEE aggregate chart
/*
  <div style={{ height: 180 }}>
    <StackBarChartV1
      yDomain={aggregateYDomain} // y value map
      getYRange={getStackBarYRange(aggregateYDomain)}
      yAccessor={(d) => d.row} // use .row as identifier for y placement
      keys={keys}
      data={[aggregateDataset]} // each element in data should have a unique identifier (y placement)
      getBandwidth={getStackBarBandwidth}
      useDataLabels={true}
      getDataLabels={getStackBarLabels}
      useBrush={true}
      htmlTooltip={AggregateChartTooltip}
      colorAccessor={(d) => CONSTANTS.CAT[d.key].color}
    />
  </div>
*/
