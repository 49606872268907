/* eslint-disable react/prop-types */
import React from 'react';
import FormCustomField from './FormCustomField';

const LabelWrapper = (props) => {
    const { col = 4, className, label, labelStyle, id } = props;

    return (
        <FormCustomField className={className} id={id}>
            <div
                className={`col-${col} col-form-label d-flex justify-content-end align-items-center`}
                style={labelStyle}
            >
                <label style={{ textAlign: 'right' }}>{label}</label>
            </div>
            <div className={`col-${12 - col} position-relative`}>
                {props.children}
            </div>
        </FormCustomField>
    );
};

export default LabelWrapper;
