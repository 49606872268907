/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import React from 'react';
import { isEqual } from 'lodash';
import { findDOMNode } from 'react-dom';
import { AutoSizer } from 'react-virtualized';

import { BaseChartTooltip } from './tooltips';
import { updateHDPICanvas } from '../../utils/charts';

import './Charts_V1.scss';

const findNodeByPosition = (nodes, x, y, radius) => {
    const rSq = radius * radius;
    let i;
    for (i = nodes.length - 1; i >= 0; --i) {
        const node = nodes[i],
            dx = x - node.x,
            dy = y - node.y,
            distSq = dx * dx + dy * dy;
        if (distSq < rSq) {
            return node;
        }
    }
    // No node selected
    return undefined;
};

// REFERENCES
// https://bl.ocks.org/jodyphelan/5dc989637045a0f48418101423378fbd
// https://observablehq.com/@fbunt/d3-force-directed-graph-on-canvas-with-drag-pan-and-zoom
// https://jsfiddle.net/jyrzq1sL/

class ForceChartSimple extends React.Component {
    constructor(props) {
        super(props);

        this.transform = d3.zoomIdentity;
        this.linkStrength = d3
            .scaleLinear()
            .domain(props.linkDomain)
            .range(props.linkRange);

        this.drawLink = this.drawLink.bind(this);
        this.drawNode = this.drawNode.bind(this);
        this.drawText = this.drawText.bind(this);
        this.drag = this.drag.bind(this);
        this.zoom = this.zoom.bind(this);
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.canvas = this.el.select('canvas.main');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.width != this.props.width ||
      prevProps.height != this.props.height ||
      !isEqual(prevProps.data, this.props.data)
        ) {
            this.recalculate();
            this.redraw();
        }
    }

    redraw() {
        const { radius } = this.props;
        const self = this;
        updateHDPICanvas(this.canvas.node(), this.w, this.h);

        const zoom_handler = d3.zoom().on('zoom', this.zoom);

        this.simulation.on('tick', this.ticked.bind(this));

        this.canvas
            .on('mousemove', function (d) {
                const p = d3.mouse(this);
                const [x, y] = self.transform.invert(p);
                const node = findNodeByPosition(self.nodes, x, y, radius * 2);
                self.hoveredNode = node;
                self.ticked();
                node ? self.showTooltip() : self.hideTooltip();
            })
            .call(this.drag(this.simulation))
            .call(zoom_handler);
    }

    ticked() {
        const {
            canvas,
            w,
            h,
            drawNode,
            drawLink,
            drawText,
            links,
            nodes,
            transform,
            props,
        } = this;
        const { nodeAccessor } = props;

        const context = canvas.node().getContext('2d');
        context.save();
        context.clearRect(0, 0, w, h);
        context.translate(this.transform.x, this.transform.y);
        context.scale(this.transform.k, this.transform.k);

        context.beginPath();
        links.forEach((d) => drawLink(d, context));

        nodes.forEach((node) => {
            const isHovered =
        nodeAccessor(node) ===
        (this.hoveredNode && nodeAccessor(this.hoveredNode));
            context.beginPath();
            drawNode(node, context, isHovered);
            drawText(node, context, isHovered);
        });
        context.restore();
    }

    drawLink(d, ctx) {
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(d.source.x, d.source.y);
        ctx.lineTo(d.target.x, d.target.y);
        ctx.strokeStyle = `rgba(187,187,187,${this.linkStrength(d.value)})`;
        ctx.stroke();
        ctx.restore();
    }

    drawNode(d, ctx, hovered) {
        const { radius } = this.props;
        ctx.save();
        ctx.strokeStyle = '#fff';
        ctx.moveTo(d.x + radius, d.y);
        ctx.arc(d.x, d.y, radius, 0, 2 * Math.PI);
        ctx.fillStyle = d.color; // change node color
        ctx.fill();

        if (hovered) {
            ctx.strokeStyle = '#222';
            ctx.lineWidth = '2px';
            ctx.stroke();
        }

        ctx.restore();
    }

    drawText(d, ctx, hovered) {
        const { nodeAccessor } = this.props;
        const label = nodeAccessor(d);
        ctx.save();
        const text = label
            ? `${label.substring(label.length - 4, label.length)}`
            : '';
        ctx.font = hovered ? `400 12px sans-serif` : `300 11px sans-serif`;
        ctx.fillStyle = '#222';
        ctx.fillText(text, d.x + 8, d.y);
        ctx.restore();
    }

    recalculate() {
        const { data, nodeAccessor } = this.props;

        this.nodes = data.nodes.map((n) => ({ ...n }));
        this.links = data.links.map((l) => ({ ...l }));

        this.simulation = d3
            .forceSimulation(this.nodes)
            .force('link', d3.forceLink(this.links).id(nodeAccessor))
            .force('charge', d3.forceManyBody().strength(-80).distanceMax(150))
            .force('center', d3.forceCenter(this.w / 2, this.h / 2));
    }

    drag(simulation) {
        const { radius } = this.props;
        const self = this;

        function dragsubject() {
            const x = self.transform.invertX(d3.event.x);
            const y = self.transform.invertY(d3.event.y);
            const node = findNodeByPosition(self.nodes, x, y, radius);
            if (node) {
                node.x = self.transform.applyX(node.x);
                node.y = self.transform.applyY(node.y);
            }
            // else: No node selected, drag container
            return node;
        }

        function dragstarted() {
            if (!d3.event.active) simulation.alphaTarget(0.3).restart();
            self.hideTooltip();
            d3.event.subject.fx = self.transform.invertX(d3.event.x);
            d3.event.subject.fy = self.transform.invertY(d3.event.y);
        }

        function dragged() {
            d3.event.subject.fx = self.transform.invertX(d3.event.x);
            d3.event.subject.fy = self.transform.invertY(d3.event.y);
        }

        function dragended() {
            if (!d3.event.active) simulation.alphaTarget(0);
            d3.event.subject.fx = null;
            d3.event.subject.fy = null;
        }

        return d3
            .drag()
            .subject(dragsubject)
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended);
    }

    zoom() {
        this.transform = d3.event.transform;
        this.ticked();
    }

    getContentForTooltip() {
        const { data, getTooltipContent, nodeAccessor } = this.props;
        const { hoveredNode } = this;
        if (!hoveredNode) return null;

        const linkedNodes = data.links.filter(
            (l) => l.source === nodeAccessor(hoveredNode)
        );

        return getTooltipContent(hoveredNode, linkedNodes);
    }

    showTooltip(d) {
        let { clientX, clientY } = window.event;

        const { top, bottom, height, width } = this.tooltip
            .node()
            .getBoundingClientRect();

        const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
        const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

        let dy = clientY + 10;
        let dx = clientX + 10;
        if (dy + height > windowHeight) dy = windowHeight - height - 10;
        if (dx + width > windowWidth) dx = windowWidth - width - 10;

        this.tooltip
            .style('top', `${dy}px`)
            .style('left', `${dx}px`)
            .style('visibility', 'visible')
            .style('opacity', 0.8)
            .html(this.getContentForTooltip());
    }

    hideTooltip() {
        this.tooltip.style('opacity', 0).style('visibility', 'hidden');
    }

    get w() {
        const {
            margin: { left, right },
            width,
        } = this.props;
        return width - left - right;
    }

    get h() {
        const {
            margin: { top, bottom },
            height,
        } = this.props;
        return height - top - bottom;
    }

    render() {
        const {
            w,
            h,
            props: { margin, height, width },
        } = this;

        return (
            <div className={`chart-v1 ${this.props.className || ''}`}>
                <div style={{ position: 'relative' }}>
                    <BaseChartTooltip ref={(node) => (this.tooltip = d3.select(node))} />
                    <canvas
                        className="main"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                        }}
                    />
                </div>
            </div>
        );
    }
}

ForceChartSimple.defaultProps = {
    width: 600,
    height: 200,
    margin: { top: 0, left: 0, right: 0, bottom: 0 },
    duration: 200,
    pointerEvents: true,
    radius: 4,
};

export class ForceChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <ForceChartSimple
                        {...this.props}
                        ref={this.props.innerRef}
                        width={width}
                        height={height}
                    />
                )}
            </AutoSizer>
        );
    }
}
