import { GridColDef } from "@mui/x-data-grid"
import React from "react"
import { ScrollSync } from "react-virtualized"
import Grid from "../../../Grid/Grid"
import TreeTablePane from "../TreeTablePane/TreeTablePane"
import { Row } from "../TreeTableRow/TreeTableRow"

const ProcessTreeContents = <T extends Row, >(props: Props<T>) => {
    const paneProps = { height: props.height - props.rowHeight, width: props.width, rowHeight: props.rowHeight }
    if (!props.freeze) {
        return <TreeTablePane {...paneProps} columns={props.columns} rows={props.rows}/>
    }

    const [left, right] = props.freeze
    return <ScrollSync>
        {({ scrollTop, onScroll }) => <Grid container direction="row">
            {left && <TreeTablePane {...paneProps} scrollTop={scrollTop} columns={props.columns} rows={props.rows}/>}
            <TreeTablePane {...paneProps} scrollTop={scrollTop} columns={props.columns} rows={props.rows}/>
            {right && <TreeTablePane {...paneProps} onScroll={onScroll} columns={props.columns} rows={props.rows}/>}
        </Grid>}
    </ScrollSync>
}

interface Props<T> {
    columns: GridColDef[]
    height: number
    width: number
    rowHeight: number
    rows: T[]
    freeze?: [number, number?]
}

export default ProcessTreeContents
