export const GatewayConstants = {
    ACTIONS: {
        ADD_GATEWAY_REQUEST: 'ADD_GATEWAY_REQUEST',
        SET_GATEWAYS_RESOURCE: 'SET_GATEWAYS_RESOURCE',
        ADD_GATEWAYS_RESOURCE: 'ADD_GATEWAYS_RESOURCE',
        SET_GATEWAYS_STATUS_RESOURCE: 'SET_GATEWAYS_STATUS_RESOURCE',
        ADD_GATEWAYS__STATUS_RESOURCE: 'ADD_GATEWAYS_STATUS_RESOURCE',
        GET_ENTITY_GATEWAY_STATUS_REQUEST: 'GET_ENTITY_GATEWAY_STATUS_REQUEST',
        GET_ENTITY_GATEWAY_STATUS_SUCCESS: 'GET_ENTITY_GATEWAY_STATUS_SUCCESS',
        GET_ENTITY_GATEWAY_STATUS_FAILURE: 'GET_ENTITY_GATEWAY_STATUS_FAILURE',
    },
};
