/* eslint-disable react/prop-types */

import { isEmpty, pickBy, round } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveFilled } from '@ant-design/icons';

// HELPERS
import CONSTANTS from '../../Constants';
import { flash } from '../../components/Flash';
import { checkFutureLabel, compileLabel, fetchAssetSkuLabels, generateStopLabel, getSpan } from '../../utils/labels';

// REDUX
import { skusSelector } from '../../../store/old/Sku/Sku.selector';

// COMLPONENTS
import AukButton from '../../components/AukButton';
import { DangerZone, DangerZoneItem } from '../../components/DangerZone';
import { Labeller, LabellerBody, LabellerBodyItem, LabellerHeader, LabellerHeaderItem, LabelRangeZoom } from './components';

// INTERFACES
import { LabelNotesInput } from '.';
import { CreateLabel, DeleteLabel, StopLabel, UpdateLabel } from '../../../store/old/Labels/Labels.action';
import ProductionLabelListItem from './components/ProductionLabelListItem';
import ProductionFieldsInputs from './components/ProductionFieldsInputs';
import useLabelCollision from '../../../hooks/useLabelCollision';
import { showLabelNotesSelector } from '../../../store/old/Labels/Labels.selector';

export const AssetProductionLabeller = (props) => {
    const dispatch = useDispatch();
    const { asset, showDataDelete, openDataDelete, onSuccess, summaryMode, title } = props;
    const skus = useSelector(skusSelector);
    const showLabelNotes = useSelector(showLabelNotesSelector)

    const {
        range,
        selection,
        brushedData,
    } = props;

    const { asset_id } = asset;
    const [formData, setFormData] = useState({});
    const [notes, setNotes] = useState(selection ? selection.notes : '');

    const [brushStart, brushEnd] = range;

    const { labelled, loading } = useLabelCollision(() => fetchAssetSkuLabels(asset.asset_id, range), selection, [range])

    const key = useMemo(() => (selection ? selection.label_id : ''), [selection]);
    useEffect(() => {
        selection && setNotes(selection.notes);
    }, [selection]);

    const brushSummary = useMemo(() => {
        if (summaryMode !== 'total') {
            const value =
        brushedData.reduce((acc, curr) => (acc += curr.int * curr.val), 0) / // totalValue
        brushedData.reduce((acc, curr) => (acc += curr.int), 0); // total time

            return `Average: ${round(value, 2)}`
        }

        const value = brushedData.reduce((acc, curr) => (acc += curr.val), 0);
        return `Total: ${round(value, 2)}`
    }, [summaryMode, range, brushedData]);

    const spanDuration = useMemo(() => getSpan(range), [range]);

    const handleSave = useCallback(() => {
        const values = {
            ...pickBy(formData, (v) => v && `${v}`.trim()),
        };

        if (isEmpty(values))
            return flash({
                message: 'Inputs required to commence tagging',
                status: 'warning',
            });

        if (values.sku && !skus[values.sku])
            return flash({
                message: 'Inputs required to commence tagging',
                status: 'warning',
            });

        if (showLabelNotes) values.notes = notes;

        if (labelled) {
            return flash({
                message: 'Cannot overwrite existing label(s)',
                status: 'warning',
            });
        }

        const requestPayload = compileLabel({
            label_id: selection ? selection.label_id : null,
            from: brushStart,
            to: brushEnd,
            values,
        });

        if (checkFutureLabel(requestPayload))
            return flash({
                message: 'Cannot create label in future time.',
                status: 'warning',
            });

        if (selection) {
            dispatch(UpdateLabel(asset_id, requestPayload, onSuccess));
            return;
        }

        dispatch(CreateLabel(asset_id, requestPayload, onSuccess));
    }, [labelled, formData, range, notes, showLabelNotes]);

    const handleDelete = () => {
        if (!selection) return;
        if (!selection.isComplete) {
            return flash({
                message: 'Please stop label before deleting.',
                status: 'warning',
            });
        }
        dispatch(DeleteLabel(asset_id, selection, onSuccess));
    };

    return (
        <Labeller className="production-labeller" id="productionLabeller" key={key}>
            <LabellerHeader className="flex-column">
                <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
                <LabellerHeaderItem label="Range">
                    <LabelRangeZoom
                        range={range}
                        disableZoom={selection ? !selection.isComplete : false}
                    />
                </LabellerHeaderItem>
            </LabellerHeader>
            <LabellerBody>
                <LabellerBodyItem
                    header={`${title || ''} Selection`}
                    addonHeader={showDataDelete ? [
                        <DangerZoneItem
                            key={key}
                            title="Delete device data"
                            description="Delete highlighted data."
                            handleClick={openDataDelete}
                            action="Delete"
                            confirmText="Are you sure ? This action is irreversible."
                        />,
                    ] : undefined}
                >
                    {brushSummary}
                </LabellerBodyItem>
                {selection ? (
                    <LabellerBodyItem header={'Label'}>
                        <ProductionLabelListItem
                            data={selection}
                            onStop={(d) => dispatch(StopLabel(asset.asset_id, generateStopLabel(d), onSuccess))}
                        />
                    </LabellerBodyItem>
                ) : null}
                {selection ? (
                    <LabellerBodyItem header={'SKU Standard Time'}>
                        {selection.skuStdTime ? `${selection.skuStdTime} s` : '-'}
                    </LabellerBodyItem>
                ) : null}
                <LabellerBodyItem header={selection ? 'Edit' : 'Create'}>
                    <ProductionFieldsInputs fields={asset.labelFields} data={selection} onChange={setFormData} />
                </LabellerBodyItem>
                {showLabelNotes ? (
                    <LabellerBodyItem header="Notes">
                        <LabelNotesInput
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </LabellerBodyItem>
                ) : null}
                <AukButton.Blue
                    className="my-3"
                    icon={<SaveFilled />}
                    onClick={handleSave}
                    disabled={!!loading}
                >
          Save
                </AukButton.Blue>
                {selection && (
                    <LabellerBodyItem header="Last Modified">
                        <div className="labeller__last-modified">
                            {selection.lastModified}
                        </div>
                    </LabellerBodyItem>
                )}
                {selection ? (
                    <DangerZone header="Delete">
                        <DangerZoneItem
                            title="Label"
                            description="Delete selected label."
                            handleClick={handleDelete}
                            action="Delete"
                            confirmText="Are you sure? This may affect your OEE."
                        />
                    </DangerZone>
                ) : null}
            </LabellerBody>
        </Labeller>
    );
};
