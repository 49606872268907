/* eslint-disable react/prop-types */

import React from 'react';
import { Badge, Button, Dropdown, Menu, Steps, Tag } from 'antd';
import moment from 'moment';
import UserAvatar from '../../../components/Avatar';
import { uiDateFormat } from '../../../utils/helpers';
import { DELETED_USER } from '../../../models';

import PriorityIcon from './PriorityIcon';


const { Step } = Steps;

const TrackedIssueListItem = (props) => {
    const { data, onClick, selected } = props;

    const color = data.issue ? data.issue.color : 'black';
    const issue = data.issue ? data.issue.name : 'Deleted';
    const assignee = data.assignee ? data.assignee : DELETED_USER;
    const reporter = data.reporter ? data.reporter : DELETED_USER;
    const actions = data.actions ? data.actions.join('\n') : 'No actions taken';
    const close_date = data.close_date
        ? moment(data.close_date).format(uiDateFormat)
        : '-';

    const currentStep = data.close_date
        ? 2
        : data.actions
            ? 1
            : data.root_cause
                ? 0
                : -1;

    return (
        <div
            className={`tracked-issue-list-item ${selected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <div className="d-flex align-items-center p-2 justify-content-between">
                <div className="d-flex align-items-center">
                    <UserAvatar user={assignee} />
                    <PriorityIcon priority={data.priority} />
                    <strong className="mx-2">{data.title}</strong>
                    <Tag color={color}>{issue}</Tag>
                </div>
                <div className="d-flex" style={{ maxWidth: '30%' }}>
                    {data.assets.length <= 3 ? (
                        data.assets.map(
                            (a, i) =>
                                a && (
                                    <div key={i}>
                                        <Tag className="mb-1">{a.asset_name}</Tag>
                                    </div>
                                )
                        )
                    ) : (
                        <Dropdown
                            overlay={
                                <Menu onClick={({ domEvent }) => domEvent.stopPropagation()}>
                                    {data.assets.map(
                                        (a) =>
                                            a && (
                                                <Menu.Item key={a.asset_id}>{a.asset_name}</Menu.Item>
                                            )
                                    )}
                                </Menu>
                            }
                        >
                            <Badge count={data.assets.length}>
                                <Button
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                >
                  ...
                                </Button>
                            </Badge>
                        </Dropdown>
                    )}
                </div>
            </div>
            <i className="p-2">
        Reported by <code>{reporter.nameFull}</code>
            </i>
            <div className="p-2">
                <Steps size="small" current={currentStep}>
                    <Step
                        title="Root Cause"
                        description={data.root_cause || 'No root cause identified'}
                    />
                    <Step title="Action" description={actions} />
                    <Step title="Closed" description={close_date} />
                </Steps>
            </div>
        </div>
    );
};

export default TrackedIssueListItem;
