import { TrendConstants as K } from './TrendUI.constants';

export const TrendLoadData = (payload, callback) => {
    return {
        type: K.ACTIONS.TREND_LOAD_DATA,
        payload,
        callback,
    };
};

export const TrendRefreshData = (callback) => {
    return {
        type: K.ACTIONS.TREND_REFRESH_DATA,
        callback,
    };
};

export const TrendSetNavigatorState = (payload) => {
    return {
        type: K.ACTIONS.TREND_UPDATE_NAVIGATOR,
        payload,
    };
};

export const TrendCreateRows = (payload) => {
    return {
        type: K.ACTIONS.TREND_CREATE_ROWS,
        payload,
    };
};

export const TrendFetchDataRequest = (payload) => {
    return {
        type: K.ACTIONS.TREND_FETCH_DATA_REQUEST,
        payload,
    };
};

export const TrendFetchDataSuccess = (payload) => {
    return {
        type: K.ACTIONS.TREND_FETCH_DATA_SUCCESS,
        payload,
    };
};

export const TrendSetDataDisplay = (metric) => {
    return {
        type: K.ACTIONS.TREND_CHANGE_DATA_DISPLAY,
        payload: metric,
    };
};

export const ClearTrendRows = () => {
    return { type: K.ACTIONS.TREND_CLEAR_ROWS };
};

export const TrendRowUpdate = (row, callback) => {
    return {
        type: K.ACTIONS.TREND_ROW_UPDATE,
        payload: row,
        callback,
    };
};

export const RemoveTrendRow = (uuid) => {
    return {
        type: K.ACTIONS.TREND_ROW_DELETE,
        payload: uuid,
    };
};
