export const TrendConstants = {
    ACTIONS: {
        TREND_LOAD_DATA: 'TREND_LOAD_DATA',
        TREND_REFRESH_DATA: 'TREND_REFRESH_DATA',
        TREND_UPDATE_NAVIGATOR: 'TREND_UPDATE_NAVIGATOR',
        TREND_CREATE_ROWS: 'TREND_CREATE_ROWS',
        TREND_FETCH_DATA_REQUEST: 'TREND_FETCH_DATA_REQUEST',
        TREND_FETCH_DATA_SUCCESS: 'TREND_FETCH_DATA_SUCCESS',
        TREND_CHANGE_DATA_DISPLAY: 'TREND_CHANGE_DATA_DISPLAY',
        TREND_CLEAR_ROWS: 'TREND_CLEAR_ROWS',
        TREND_RESET_STORE: 'TREND_RESET_STORE',
        TREND_ROW_UPDATE: 'TREND_ROW_UPDATE',
        TREND_ROW_DELETE: 'TREND_ROW_DELETE',
    },
    METRICS: [
        { id: 0, value: 'oee' },
        { id: 1, value: 'data' },
        { id: 2, value: 'aggregated' },
    ],
    SPAN_PERIODS: {
        MINUTES: 'minutes',
        HOURS: 'hours',
        DAYS: 'days',
        MONTHS: 'months',
    },
    STEP_PERIODS: {
        HOURS: 'hours',
        DAYS: 'days',
        WEEKS: 'weeks',
        MONTHS: 'months',
        QUARTERS: 'quarters',
        YEARS: 'years',
    },
};
