/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';

import Select from '../../components/Select';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { connect } from 'react-redux';
import { createGroupRequest, deleteGroupRequest, updateGroupRequest } from '../../../store/old/Group/Group.action';
import AukButton from '../../components/AukButton';
import { Popconfirm } from 'antd';
import { flash } from '../../components/Flash';

class ModalGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            group_id: '',
            groupNameValid: false,
            formErrors: { groupName: '' },
            formValid: false,
            groupMembers: [],
        };

        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const groupMemberProps = props.groupMembers;

        const newGroupMemberProps = groupMemberProps.map(
            (userItem) => userItem.user || userItem
        );

        this.setState(
            {
                groupMembers: newGroupMemberProps,
                groupName: props.grName,
                group_id: props.group_id,
                groupNameValid: false,
                formErrors: { groupName: '' },
                formValid: false,
            },
            () => this.validateField(this.state.groupName)
        );
    }

    handleCreate(members, group_name) {
        const { entity, createGroup } = this.props;

        const users = members.map((u) => u.user_id);

        if (!users.length) {
            flash({ message: 'Cannot create group: please specify users.', status: 'warning' });
            return;
        }
        createGroup({ entity_id: entity.entity_id, group_name, users }, () => {
            this.props.close();
        });
    }

    handleUpdate(groupMembers, group_name, group_id) {
        const users = groupMembers.map((m) => m.user_id);

        this.props.updateGroup({ group_name, users, group_id }, () => {
            this.props.close();
        });
    }

    handleDelete(group_id) {
        this.props.deleteGroup(group_id, () => {
            this.props.close();
        });
    }

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({ groupName: e.target.value }, () => {
            this.validateField(value);
        });
    };

    handleChangeSelect(e) {
        this.setState({ groupMembers: e });
    }

    validateField(value) {
        let fieldValidationErrors = this.state.formErrors;
        let groupNameValid = this.state.groupNameValid;

        groupNameValid = value.trim().length >= 2;
        fieldValidationErrors.groupName = groupNameValid ? '' : ' is invalid';

        this.setState(
            {
                formErrors: fieldValidationErrors,
                groupNameValid: groupNameValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({ formValid: this.state.groupNameValid });
    }

    get footerCreate() {
        const { groupName, formValid, groupMembers } = this.state;
        return (
            <div className="panel-footer p-3">
                <AukButton.Outlined onClick={this.props.close}>
                    {translate('cancel')}
                </AukButton.Outlined>
                <AukButton.Blue
                    disabled={!formValid}
                    onClick={() => this.handleCreate(groupMembers, groupName)}
                >
                    {translate('done')}
                </AukButton.Blue>
            </div>
        );
    }

    get footerEdit() {
        const { groupName, groupMembers, group_id } = this.state;
        return (
            <div className="panel-footer p-3">
                <AukButton.Outlined onClick={this.props.close}>
                    {translate('cancel')}
                </AukButton.Outlined>

                <Permission forResource resource="users" canDo="full">
                    <Popconfirm
                        title={"This will permanently delete the group. Proceed?"}
                        onConfirm={() => this.handleDelete(group_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <AukButton.Red>
                            {translate('delete')}
                        </AukButton.Red>
                    </Popconfirm>
                </Permission>

                <AukButton.Blue
                    onClick={() => this.handleUpdate(groupMembers, groupName, group_id)}
                >
                    {translate('save')}
                </AukButton.Blue>
            </div>
        );
    }

    render() {
        const { userList, show, editing, close } = this.props;
        const { groupName, groupNameValid } = this.state;

        const groupNameInput = (
            <input
                type="text"
                className={`form-control ${
                    groupName === '' ? '' : groupNameValid ? 'is-valid' : 'is-invalid'
                }`}
                placeholder="E.g. Procurement"
                value={groupName}
                onChange={(event) => this.handleChange(event)}
                maxLength={80}
            />
        );

        return (
            <Modal
                isOpen={show}
                toggle={close}
                backdrop="static"
            >
                <div className="panel modal--user-groups">
                    <div className="panel-header">
                        <p className="panel-title">
                            {!editing ? 'Create Group' : 'Manage Group'}
                        </p>
                    </div>
                    <div className="panel-body">
                        <table className="table t-current">
                            <tbody>
                                <tr>
                                    <td className="right td">
                                        <label className="check">
                                            Group Name
                                        </label>
                                    </td>
                                    <td className="td">{groupNameInput}</td>
                                </tr>
                                <tr>
                                    <td className="right td">
                                        <label className="check">
                                            User(s)
                                        </label>
                                    </td>
                                    <td className="td">
                                        <Select
                                            value={this.state.groupMembers}
                                            options={userList}
                                            getOptionLabel={(opt) =>
                                                `${opt.name_first} ${opt.name_last}`
                                            }
                                            getOptionValue={(opt) => opt.user_id}
                                            onChange={this.handleChangeSelect}
                                            isMulti
                                            hideSelectedOptions
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!editing ? this.footerCreate : this.footerEdit}
                </div>
            </Modal>
        );
    }
}

ModalGroups.propTypes = {
    userList: PropTypes.array,
};

const mapStateToProps = (root) => {
    return {
        entity: currentEntitySelector(root)
    };
};

const mapDisplayToProps = (dispatch) => {
    return {
        updateGroup: (group, callback) => dispatch(updateGroupRequest(group, callback)),
        createGroup: (group, callback) => dispatch(createGroupRequest(group, callback)),
        deleteGroup: (group_id, callback) => dispatch(deleteGroupRequest(group_id, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDisplayToProps
)(ModalGroups);