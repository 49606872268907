/* eslint-disable react/prop-types */

import React, { useCallback, useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlayCircleFilled } from '@ant-design/icons';
import { isEmpty, pickBy } from 'lodash';
import moment from 'moment';

// COMPONENTS
import AukButton from '../../components/AukButton';
import { flash } from '../../components/Flash';
import { NoAccess } from '../../components/None';
import {
    Labeller,
    LabellerBody,
    LabellerBodyItem,
    LabellerHeader,
} from './components';

// REDUX
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { showLabelNotesSelector } from '../../../store/old/Labels/Labels.selector';

// HELPERS
import { uiDatetimeFormatWithSeconds } from '../../utils/helpers';
import { generateForwardLabel, generateStopLabel } from '../../utils/labels';

import { LabelNotesInput } from '.';
import './index.scss';
import { CreateLabel, StopLabel } from '../../../store/old/Labels/Labels.action';
import PermittedIssuesTreeSelect from './components/PermittedIssuesTreeSelect';
import IssueLabelListItem from './components/IssueLabelListItem';
import ProductionLabelListItem from './components/ProductionLabelListItem';
import ProductionFieldsInputs from './components/ProductionFieldsInputs';

const { TabPane } = Tabs;

export const AssetForwardLabeller = (props) => {
    const dispatch = useDispatch();
    const { asset, window } = props;
    const showLabelNotes = useSelector(showLabelNotesSelector)

    const { streaming } = useSelector(controlsState);

    const [issueFormData, setIssueFormData] = useState({ issue_id: null });
    const [productionFormData, setProductionFormData] = useState({});
    const [notes, setNotes] = useState('');

    const { asset_id } = asset;

    const startIssueLabel = useCallback(() => {
        if (!issueFormData.issue_id)
            return flash({ message: 'Please select an issue', status: 'warning' });

        dispatch(CreateLabel(
            asset_id,
            generateForwardLabel({
                issue: issueFormData.issue_id,
                notes: showLabelNotes ? notes : undefined,
            })
        ));
    }, [issueFormData, showLabelNotes, notes]);

    const startProductionLabel = useCallback(() => {
        const values = {
            ...pickBy(productionFormData, (v) => v && `${v}`.trim()),
        };

        if (isEmpty(values))
            return flash({
                message: 'Inputs required to commence tagging',
                status: 'warning',
            });

        if (showLabelNotes) values.notes = notes;

        dispatch(CreateLabel(asset_id, generateForwardLabel(values)));
    }, [productionFormData, notes, showLabelNotes]);

    return (
        <Labeller className="forward-labeller" id="forwardLabeller">
            <LabellerHeader className="flex-column">
                <strong>Forward Tagging</strong>
                <div style={{ fontSize: 12 }}>
          Last Refreshed:{' '}
                    {streaming
                        ? moment(window[1]).format(uiDatetimeFormatWithSeconds)
                        : '-'}
                </div>
            </LabellerHeader>
            <Tabs
                className="forward-labeller-tabs w-100"
                defaultActiveKey="1"
                size="small"
                destroyInactiveTabPane
                onChange={() => setNotes('')}
            >
                <TabPane tab="Issue" key="1">
                    <LabellerBody>
                        {asset.liveIssueLabel && (
                            <>
                                <LabellerBodyItem header="Currently Tagging">
                                    <IssueLabelListItem
                                        data={asset.liveIssueLabel}
                                        onStop={(d) =>
                                            dispatch(StopLabel(asset.asset_id, generateStopLabel(d)))
                                        }
                                    />
                                </LabellerBodyItem>
                                {showLabelNotes ? (
                                    <LabellerBodyItem header="Notes">
                                        <LabelNotesInput
                                            value={asset.liveIssueLabel.notes}
                                            disabled={true}
                                        />
                                    </LabellerBodyItem>
                                ) : null}
                            </>
                        )}
                        {streaming && !asset.liveIssueLabel ? (
                            <>
                                <LabellerBodyItem header="Start Issue Label">
                                    <PermittedIssuesTreeSelect forBlocks={[asset.block_id]} onChange={setIssueFormData} />
                                </LabellerBodyItem>
                                {showLabelNotes ? (
                                    <LabellerBodyItem header="Notes">
                                        <LabelNotesInput
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </LabellerBodyItem>
                                ) : null}
                                <AukButton.Blue
                                    className="w-100 my-3"
                                    icon={<PlayCircleFilled />}
                                    onClick={startIssueLabel}
                                >
                  Start issue label
                                </AukButton.Blue>
                            </>
                        ) : !streaming ? (
                            <NoAccess
                                description={'Forward tagging is only enabled when streaming.'}
                            />
                        ) : null}
                    </LabellerBody>
                </TabPane>
                <TabPane tab="Production" key="2">
                    <LabellerBody>
                        {asset.liveProductLabel && (
                            <>
                                <LabellerBodyItem header="Currently Tagging">
                                    <ProductionLabelListItem
                                        data={asset.liveProductLabel}
                                        onStop={(d) =>
                                            dispatch(StopLabel(asset.asset_id, generateStopLabel(d)))
                                        }
                                    />
                                </LabellerBodyItem>
                                {showLabelNotes ? (
                                    <LabellerBodyItem header="Notes">
                                        <LabelNotesInput
                                            value={asset.liveProductLabel.notes}
                                            disabled={true}
                                        />
                                    </LabellerBodyItem>
                                ) : null}
                            </>
                        )}
                        {streaming && !asset.liveProductLabel ? (
                            <>
                                <LabellerBodyItem header="Start Production Label">
                                    <ProductionFieldsInputs fields={asset.labelFields} onChange={setProductionFormData} />
                                </LabellerBodyItem>
                                {showLabelNotes ? (
                                    <LabellerBodyItem header="Notes">
                                        <LabelNotesInput
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </LabellerBodyItem>
                                ) : null}
                                <AukButton.Blue
                                    className="w-100 my-3"
                                    icon={<PlayCircleFilled />}
                                    onClick={startProductionLabel}
                                >
                  Start production label
                                </AukButton.Blue>
                            </>
                        ) : !streaming ? (
                            <NoAccess
                                description={'Forward tagging is only enabled when streaming.'}
                            />
                        ) : null}
                    </LabellerBody>
                </TabPane>
            </Tabs>
        </Labeller>
    );
};
