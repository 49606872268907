import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

interface ProtectedRouteProps {
    isAllowed: boolean
    redirectPath?: string
    children?: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    isAllowed,
    redirectPath = "/",
    children,
}) => {
    const location = useLocation()

    if (!isAllowed) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />
    }

    return <>{children ?? <Outlet />}</>
}

export default ProtectedRoute
