import "./style.scss"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button } from "@mui/material"
import React from "react"
import theme from "../../../styles/theme"

const DeleteButton = (props: Props) => {
    return <Button
        className={props.className}
        size={props.size ?? "medium"}
        onClick={props.onPress}
        sx={{ color: theme.palette.warning.main }}
        disabled={props.disabled ?? false}
    >
        <DeleteIcon className="btn-delete-icon"/>
        Delete
    </Button>
}

interface Props {
    className?: string
    onPress?: React.MouseEventHandler
    size?: "small" | "medium" | "large"
    disabled?: boolean
}

export default DeleteButton
