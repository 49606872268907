import React, { useMemo } from "react"
import ChartWrapper from "../../../components/Chart/ChartWrapper"
import { StackBarChartV1, getStackBarLabels, getStackBarYRange } from "../../../legacy/Charts/v1"
import * as d3 from "d3"
import { chartOeeKeys, oeeChartAccessors } from "../../../legacy/utils/oee"
import { AggregateChartTooltip } from "../../../legacy/Charts/v1/tooltips/templates"


const OeeAggregateChart = (props: Props) => {
    const yDomain = useMemo(() => props.data.map(yAccessor), [props.data])

    return <ChartWrapper height={props.height || "100%"}>
        <StackBarChartV1
            xScale={d3.scaleLinear()}
            yScale={d3.scaleOrdinal()}
            yDomain={yDomain}
            getYRange={getStackBarYRange(yDomain)}
            keys={chartOeeKeys}
            data={props.data}
            useDataLabels={true}
            getDataLabels={getStackBarLabels}
            yAccessor={yAccessor}
            htmlTooltip={AggregateChartTooltip}
            colorAccessor={oeeChartAccessors.color}
        />
    </ChartWrapper>
}

interface Props {
    data: any;
    height: string | number
}

const yAccessor = (d: any, i: number) => i

export default OeeAggregateChart
