import { pickBy } from 'lodash';
import { updateStoreRedux } from '../../../legacy/utils/redux';
import { WidgetConstants as K } from './Widget.constants';

const initialReducer = () => ({
    widgets: {},
});

export const widgetsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.RESET_WIDGETS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    if (action.type === K.ACTIONS.SET_WIDGETS_RESOURCE) {
        const { widgets } = action.payload;
        return updateStoreRedux(state, { widgets });
    }

    if (action.type === K.ACTIONS.ADD_WIDGETS_RESOURCE) {
        const { widgets } = action.payload;
        return updateStoreRedux(state, {
            widgets: { ...state.widgets, ...widgets },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_WIDGETS_RESOURCE) {
        const deleteSet = new Set(action.payload.widget_ids);
        const deletedState = pickBy(
            { ...state.widgets },
            ({ widget_id }) => !deleteSet.has(widget_id)
        );

        return updateStoreRedux(state, {
            widgets: deletedState,
        });
    }

    return updateStoreRedux(state, {});
};
