import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';

// REDUX STORE
import { store } from '../..';

export const api_createEntityPreference = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/preference`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateEntityPreference = (data, preference_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/preference/${preference_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Create user preferences
export const api_createUserPreference = (data) => {
    const { user_id } = store.getState().auth.user;
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}/preference`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Update user preferences
export const api_updateUserPreference = (data, preference_id) => {
    const { user_id } = store.getState().auth.user;

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}/preference/${preference_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getAssetOperatorPreferences = (entity_id, asset_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/preference`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};
