export const TrackedIssuesConstants = {
    ACTIONS: {
        CREATE_TRACKED_ISSUE_REQUEST: 'CREATE_TRACKED_ISSUE_REQUEST',
        CREATE_TRACKED_ISSUE_REQUEST_SUCCESS:
      'CREATE_TRACKED_ISSUE_REQUEST_SUCCESS',
        GET_TRACKED_ISSUES_REQUEST: 'GET_TRACKED_ISSUES_REQUEST',
        GET_TRACKED_ISSUES_REQUEST_SUCCESS: 'GET_TRACKED_ISSUES_REQUEST_SUCCESS',
        UPDATE_TRACKED_ISSUE_REQUEST: 'UPDATE_TRACKED_ISSUE_REQUEST',
        UPDATE_TRACKED_ISSUE_REQUEST_SUCCESS:
      'UPDATE_TRACKED_ISSUE_REQUEST_SUCCESS',
        DELETE_TRACKED_ISSUE_REQUEST: 'DELETE_TRACKED_ISSUE_REQUEST',
        DELETE_TRACKED_ISSUE_REQUEST_SUCCESS:
      'DELETE_TRACKED_ISSUE_REQUEST_SUCCESS',
        MAKE_TRACKED_ISSUES_REQUEST_FAILURE: 'MAKE_TRACKED_ISSUES_REQUEST_FAILURE',
    },
};
