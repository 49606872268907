/* eslint-disable react/prop-types */

import React from 'react';
import { Radio } from 'antd';

// MODEL
import { ResourcePermission } from '../../../../models';
import { PureComponent } from 'react';

const resourcesRight = ['nil', 'view', 'edit', 'full'];


const mapperResource = (name) => {
    switch (name) {
    case 'tracked_issues':
        return 'Issue Tracker';
    case 'search':
        return 'Label Search';
    case 'targets':
        return 'Target Setting';
    case 'users':
        return 'User Management';
    case 'permissions':
        return 'Permission Management';
    case 'heatmap':
        return 'Utilization Heatmap';
    case 'standard_time':
        return 'Standard Time';
    case 'skus':
        return 'SKU Management';
    case 'issues':
        return 'Issue Management';
    default:
        return name.replace(/[_]/g, ' ').toLowerCase();
    }
};

export default class ResourceRow extends PureComponent {
    state = {
        rights: 'nil',
    };

    componentDidMount() {
        this.updateRightInsideLocalState(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateRightInsideLocalState(nextProps);
    }

    updateRightInsideLocalState = (props) => {
        if (props.rights) {
            this.setState({ rights: props.rights });
        }
    };

    handleChangeValue = (value) => {
        const { resource_id, handleOnChange } = this.props;
        const resourcePermission = new ResourcePermission(resource_id, value);

        this.setState({ rights: value });
        handleOnChange(resourcePermission);
    };

    render() {
        const { resource_name } = this.props;

        const { rights } = this.state;
        return (
            <div className="row resource-item">
                <div
                    className="col-xs-12 col-sm-12 col-md-4"
                    style={{ textTransform: 'capitalize', fontWeight: 400 }}
                >
                    {mapperResource(resource_name)}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                    <Radio.Group
                        onChange={(v) => this.handleChangeValue(v.target.value)}
                        value={rights}
                        className="custom-selection-resources"
                    >
                        {resourcesRight.map((v) => (
                            <Radio key={v} value={v} className="resource-checker">
                                {v}
                            </Radio>
                        ))}
                    </Radio.Group>
                </div>
            </div>
        );
    }
}
