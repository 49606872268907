import React from 'react';

import './ImagePlaceholder.scss';

const ImagePlaceholder = () => {
    return (
        <span className="auk-image-placeholder">
            <i className="far fa-images" />
        </span>
    );
};

export default ImagePlaceholder;
