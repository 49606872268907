import { store } from '../../store';
import { floorTimeByResolution } from './controls';

export const setURLQuery = (s) => {
    if (window.history.pushState && s) {
        const newURL = window.location.origin + window.location.pathname + s;
        window.history.pushState({ path: newURL }, '', newURL);
    }
};

export const generateUrlQuery = (params, entity_id) => {
    const {
        entity: { active },
        ui: { controls },
    } = store.getState();

    params = params || controls;
    entity_id = entity_id || active;

    const s = getQueryStringForNonStreaming(params);

    return s + `&entity_id=${entity_id}`;
};

const getQueryStringForNonStreaming = (params) => {
    const { resolution, startDate, endDate, span } = params;
    const { res_x, res_period } = resolution;

    return (
        '?' +
        `from=${encodeURIComponent(floorTimeByResolution(startDate, resolution).toISOString())}` +
        `&to=${encodeURIComponent(floorTimeByResolution(endDate, resolution).toISOString())}` +
        `&res_x=${res_x}` +
        `&res_period=${res_period}` +
        `&span=${span.replace(' ', '+')}`
    );
};
