import { InvitationConstants as K } from './Invitation.constant';

export const setInvitationsResource = (invitations) => {
    return {
        type: K.ACTIONS.SET_INVITATIONS_RESOURCE,
        payload: { invitations },
    };
}

export const addInvitationsResource = (invitations) => {
    return {
        type: K.ACTIONS.ADD_INVITATIONS_RESOURCE,
        payload: { invitations },
    };
}

export const removeInvitationsResource = (user_ids) => {
    return {
        type: K.ACTIONS.REMOVE_INVITATIONS_RESOURCE,
        payload: { user_ids },
    };
}

export const fetchInvitedUsers = (entity_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_INVITATIONS_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const createInviteUser = (entity_id, requestor_id, invitees, callback) => {
    return {
        type: K.ACTIONS.CREATE_INVITATION_REQUEST,
        payload: { entity_id, requestor_id, invitees },
        callback,
    };
};

export const resendInviteUser = (entity_id, user_id, requestor_id, callback) => {
    return {
        type: K.ACTIONS.RESEND_INVITATION_REQUEST,
        payload: { entity_id, user_id, requestor_id },
        callback,
    };
};

export const removeInviteUser = (entity_id, user_id, callback) => {
    return {
        type: K.ACTIONS.REVOKE_INVITATION_REQUEST,
        payload: { entity_id, user_id },
        callback,
    };
};