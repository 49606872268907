import 'whatwg-fetch';
import CONSTANTS from '../Constants';

export const REST_METHODS = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
};


const server = process.env.REACT_APP_OLD_API_URL;
export const METHOD_OVERRIDE = new Set([REST_METHODS.PUT, REST_METHODS.DELETE]);

export const http = async ({ path, options, success, error }) => {
    try {
        const response = await fetch(server + path, options);

        if (!response.ok) {
            let errObj;
            if (
                response.headers.get('content-type').indexOf('application/json') === -1
            ) {
                errObj = {
                    statusText: response.statusText,
                    res: response.text(),
                    content: 'text',
                };
            } else {
                errObj = {
                    statusText: response.statusText,
                    res: response.json(),
                    content: 'json',
                };
            }

            throw errObj;
        }

        const data =
      response.headers.get('content-type').indexOf('application/json') === -1
          ? await response.text()
          : await response.json();
        success(data);
    } catch (err) {
        if (err.res) {
            const e = await err.res;
            error(e);
        }
    }
};
