import { updateStoreRedux } from '../../../legacy/utils/redux';

import { TilesConstants as K } from './Tiles.constants';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    tiles: {},
    summary_tiles: {},
    error: {},
});

export const tilesReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_DASHBOARD_TILES_STATE) {
        const { tiles, summary_tiles } = action.payload;
        return updateStoreRedux(state, { tiles, summary_tiles });
    }

    if (action.type === K.ACTIONS.ADD_TILES_STATE) {
        const { tile } = action.payload;
        return updateStoreRedux(state, {
            tiles: { ...state.tiles, [tile.tile_id]: tile },
        });
    }

    if (action.type === K.ACTIONS.SET_TILES_STATE) {
        return updateStoreRedux(state, {
            tiles: action.payload.tiles,
        });
    }

    if (action.type === K.ACTIONS.DELETE_TILES_STATE) {
        const deleteSet = new Set(action.payload.tile_ids);
        const deletedState = pickBy(
            { ...state.tiles },
            ({ tile_id }) => !deleteSet.has(tile_id)
        );

        return updateStoreRedux(state, {
            tiles: deletedState,
        });
    }

    if (
        action.type === K.ACTIONS.GET_ALL_SUMMARY_TILES_SUCCESS ||
    action.type === K.ACTIONS.CREATE_SUMMARY_TILE_SUCCESS
    ) {
        return updateStoreRedux(state, {
            summary_tiles: action.payload.summary_tiles,
        });
    }

    if (action.type === K.ACTIONS.EDIT_SUMMARY_TILE_SUCCESS) {
        const { summary_tile } = action.payload;
        return updateStoreRedux(state, {
            summary_tiles: {
                ...state.summary_tiles,
                [summary_tile.summary_tile_id]: summary_tile,
            },
        });
    }

    if (action.type === K.ACTIONS.DELETE_SUMMARY_TILE_SUCCESS) {
        const cloned = { ...state.summary_tiles };
        action.payload.summary_tile_ids.forEach((id) => delete cloned[id]);

        return updateStoreRedux(state, {
            summary_tiles: cloned,
        });
    }

    if (action.type === K.ACTIONS.EDIT_TILE_SUCCESS) {
        const { tile } = action.payload;
        return updateStoreRedux(state, {
            tiles: { ...state.tiles, [tile.tile_id]: tile },
        });
    }

    if (
        action.type === K.ACTIONS.GET_ALL_SUMMARY_TILES_FAILURE ||
    action.type === K.ACTIONS.EDIT_SUMMARY_TILE_FAILURE ||
    action.type === K.ACTIONS.CREATE_SUMMARY_TILE_FAILURE ||
    action.type === K.ACTIONS.DELETE_SUMMARY_TILE_FAILURE ||
    action.type === K.ACTIONS.MERGE_TILE_FAILURE ||
    action.type === K.ACTIONS.UPDATE_TILE_POSITION_FAILURE
    ) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    if (action.type === K.ACTIONS.RESET_DASHBOARD_TILES_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
