/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserMultiFormatReader } from '@zxing/library/esm5';

// COMPONENTS
import ModalPanel from './ModalPanel';
import { PanelHeader, PanelFooter, PanelBody } from './Panel';

import { generateId } from '../utils/helpers';

import './BarcodeScanner.scss';
import { errorFlash } from './Flash';

const ZXing = require('@zxing/library');

export default class BarcodeScanner extends React.Component {
    constructor(props) {
        super(props);

        this.handleScan = this.handleScan.bind(this);
    }

    handleScan(code) {
        this.props.handleScan(code);
        this.props.cancel();
    }

    render() {
        return (
            <ModalPanel className="labeller--barcode">
                <PanelHeader>{this.props.label}</PanelHeader>
                <PanelBody className="d-flex justify-content-center align-items-center">
                    <ZXScanner onScan={this.handleScan} />
                </PanelBody>
                <PanelFooter>
                    <button onClick={() => this.props.cancel()} className="btn b-cancel">
            CANCEL
                    </button>
                </PanelFooter>
            </ModalPanel>
        );
    }
}

export class ZXScanner extends React.Component {
    constructor(props) {
        super(props);

        this.reader = new BrowserMultiFormatReader();
        this.id = generateId();

        this.state = { devices: [], device: null };

        this.handleChangeVideoDevice = this.handleChangeVideoDevice.bind(this);
    }

    componentDidMount() {
        this.decodeFromVideoDevice();
    }

    componentWillUnmount() {
        this.reader.stopStreams();
        this.reader.reset();
    }

    handleChangeVideoDevice(device) {
        this.setState({ device }, this.decodeFromVideoDevice);
    }

    decodeFromVideoDevice() {
        this.reader.decodeFromVideoDevice(undefined, this.id, (res, err) => {
            if (res) this.props.onScan(res.text);
            if (err && !(err instanceof ZXing.NotFoundException)) {
                errorFlash(err);
            }
        });
    }

    render() {
        const line = '1px solid #ddd';

        return (
            <div className="p-3" style={{ borderTop: line, borderBottom: line }}>
                <video id={this.id} style={{ width: 300, border: line }} />
            </div>
        );
    }
}
