/* eslint-disable react/prop-types */
import _ from "lodash"
import React from 'react';
import { connect } from 'react-redux';
import { TreeSelect } from 'antd';
import LabelWrapper from '../../components/LabelWrapper';
import ModalPanel from '../../components/ModalPanel';
import BlocksTree from '../Blocks/BlocksTree';
import { PanelHeader, PanelBody, PanelFooter } from '../../components/Panel';
import { breadthFirstSearch, depthFirstTraversal } from '../../utils/helpers';
import { flash } from '../../components/Flash';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { api_getBlocks } from '../../../store/old/Blocks/Blocks.services';
import './SummaryTileEdit.scss';

const getTreeData = (node) => {
    if (node.children && node.children.length) {
        return {
            title: node.entity_name,
            value: node.entity_id,
            children: node.children.map((c) => getTreeData(c)),
        };
    }

    return {
        title: node.entity_name,
        value: node.entity_id,
    };
};

class SummaryTileEdit extends React.Component {
    constructor(props) {
        super(props);

        const { block_id } = this.props.summaryTile || {};

        this.state = {
            block_id: block_id || null,
            entity: null,
            entityBlocks: {},
        };

        this.handleClickBlock = this.handleClickBlock.bind(this);
        this.handleSelectEntity = this.handleSelectEntity.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const entities = [];
        depthFirstTraversal(this.props.entity, (node) =>
            entities.push(node.entity_id)
        );

        const promises = entities.map((entity_id) => api_getBlocks(entity_id));

        Promise.all(promises).then((res) => {
            const entityBlocks = res.reduce((acc, curr) => {
                const { entity_id } = curr[0];
                const blocks = curr[0];
                depthFirstTraversal(blocks, (node) => {
                    if (!node.children || !node.children.length) return;
                    node.children = node.children.sort((a, b) => a.order - b.order);
                });
                return { ...acc, [entity_id]: blocks };
            }, {});

            const entity =
        this.isUpdate && this.props.summaryTile.block_id // if associated block is removed
            ? _.values(entityBlocks).find((val) => {
                return breadthFirstSearch(
                    [val],
                    (node) => node.block_id === this.props.summaryTile.block_id
                );
            })
            : this.props.entity;

            this.setState({ entityBlocks, entity: +entity.entity_id });
        });
    }

    get blocks() {
        const { entityBlocks, entity } = this.state;

        return entityBlocks[entity] ? [entityBlocks[entity]] : [];
    }

    get blocksTreeData() {
        const { entityBlocks, entity } = this.state;
        return entityBlocks[entity];
    }

    handleClickBlock(block) {
        this.setState({ block_id: block.block_id });
    }

    handleSelectEntity(e) {
        this.setState({ entity: e });
    }

    submit() {
        if (!this.formValid) {
            return flash({
                message: 'Please select a block',
                status: 'warning',
            });
        }

        const { block_id, entity: entity_id } = this.state;

        this.props.submit({ block_id, entity_id });
    }

    get formValid() {
    // only allow if block_id is present on currently selected entity
        return (
            !!this.state.block_id &&
      breadthFirstSearch(
          this.blocks,
          (node) => node.block_id === this.state.block_id
      )
        );
    }

    get isUpdate() {
        return !!this.props.summaryTile;
    }

    render() {
        return (
            <ModalPanel className="block-editor summary-tile-edit">
                <PanelHeader>
                    {this.isUpdate ? 'Edit Summary Tile' : 'Add Summary Tile'}
                </PanelHeader>
                <PanelBody>
                    {this.state.entity && (
                        <>
                            <LabelWrapper label={'Organisation'} col={3}>
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    value={this.state.entity}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={[getTreeData(this.props.entity)]}
                                    treeDefaultExpandAll
                                    onChange={(val) => this.handleSelectEntity(val)}
                                />
                            </LabelWrapper>
                            <div className="row justify-content-center">
                                {this.blocksTreeData && (
                                    <BlocksTree
                                        selectable
                                        selected={this.state.block_id}
                                        onSelect={({ block_id }) => this.setState({ block_id })}
                                        data={this.blocksTreeData}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </PanelBody>
                <PanelFooter>
                    <button onClick={this.props.cancel} className="btn b-cancel">
                        <i className="fas fa-times"></i>
                        <span style={{ marginLeft: 10 }}>CANCEL</span>
                    </button>
                    <button onClick={this.submit} className="btn b-save">
                        <i className="fas fa-check" />
                        <span style={{ marginLeft: 10 }}>Done</span>
                    </button>
                </PanelFooter>
            </ModalPanel>
        );
    }
}

SummaryTileEdit.defaultProps = {
    summaryTile: null,
};

const mapStateToProps = (rootState) => ({
    user: rootState.auth.user,
    entity: currentEntitySelector(rootState),
});

export default connect(mapStateToProps)(SummaryTileEdit);
