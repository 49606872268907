/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import BarcodeScanner from './BarcodeScanner';

const WithBarcode = (props) => {
    const [display, setDisplay] = useState(false);

    const closeDisplay = () => setDisplay(false);

    const handleClick = () => {
        setDisplay(true);
        props.handleClick && props.handleClick();
    };

    const handleScan = (code) => {
        props.handleScan(code);
        closeDisplay();
    };

    return (
        <div className="barcode-wrapper w-100 d-flex flex-row justify-content-center align-items-center">
            <div style={{ flexGrow: 1 }}>{props.children}</div>
            <div className="barcode-container">
                <div
                    style={{
                        position: 'absolute',
                        height: props.height || 20,
                        width: props.width || 24,
                        backgroundColor: 'white',
                        borderRadius: 15,
                        marginTop: -3,
                    }}
                />
                <span className="barcode-style" onClick={handleClick}>
                    <i className="fas fa-barcode" />
                </span>
            </div>
            {display && (
                <BarcodeScanner handleScan={handleScan} cancel={closeDisplay} />
            )}
        </div>
    );
};

export default WithBarcode;
