import { pickBy, values } from 'lodash';

import { updateStoreRedux } from '../../../legacy/utils/redux';
import { TargetConstants as K } from './Target.constant';
import { getForeignKeyAssociations } from '../../../legacy/utils/helpers';

const getAssetTargetsState = (arr) =>
    getForeignKeyAssociations(
        arr,
        (d) => d.target_id,
        (d) => d.asset_id
    );

const getSkuTargetsState = (arr) =>
    getForeignKeyAssociations(
        arr,
        (d) => d.target_id,
        (d) => d.sku_id
    );

const initialReducer = () => ({
    targets: {},
    assetsTargets: {},
    skusTargets: {},
});

export const targetsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_TARGETS_RESOURCE) {
        const targetsState = action.payload.targets;

        return updateStoreRedux(state, {
            targets: targetsState,
            assetsTargets: getAssetTargetsState(values(targetsState)),
            skusTargets: getSkuTargetsState(values(targetsState)),
        });
    }

    if (action.type === K.ACTIONS.ADD_TARGETS_RESOURCE) {
        const { targets } = action.payload;
        const updatedTargetsState = {
            ...state.targets,
            ...targets,
        };

        return updateStoreRedux(state, {
            targets: updatedTargetsState,
            assetsTargets: getAssetTargetsState(values(updatedTargetsState)),
            skusTargets: getSkuTargetsState(values(updatedTargetsState)),
        });
    }

    if (action.type === K.ACTIONS.REMOVE_TARGETS_RESOURCE) {
        const deleteSet = new Set(action.payload.target_ids);
        const deletedState = pickBy(
            { ...state.targets },
            ({ target_id }) => !deleteSet.has(target_id)
        );

        return updateStoreRedux(state, {
            targets: deletedState,
            assetsTargets: getAssetTargetsState(values(deletedState)),
            skusTargets: getSkuTargetsState(values(deletedState)),
        });
    }

    if (action.type === K.ACTIONS.RESET_TARGETS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
