import React from "react"
import { Label } from "../../../models"
import { StopLabelIcon } from "."
import { labelDisplayText } from "../../../utils/labels"
import moment from "moment"
import { uiDatetimeFormatWithSeconds } from "../../../utils/helpers"
import { AnyFunction } from "../../../../types/any"

const ProductionLabelListItem = (props: Props) => {
    return (
        <div className="label-vlist-item d-flex align-items-center px-2 justify-content-between">
            <div className="label-vlist-item__descriptor d-flex flex-column h-100">
                <div className="d-flex">{labelDisplayText(props.data)}</div>
                <div style={{ fontSize: 10 }}>
                    {moment(props.data.from).format(uiDatetimeFormatWithSeconds)} -{" "}
                    {props.data.isComplete
                        ? moment(props.data.to).format(uiDatetimeFormatWithSeconds)
                        : "On-going"}
                </div>
                <div style={{ fontSize: 10 }}>
          Output: {props.data.isComplete ? props.data.output : "On-going"}
                </div>
            </div>
            {!props.data.isComplete && !props.readOnly && 
                <StopLabelIcon
                    onClick={(e: any) => {
                        e.stopPropagation()
                        props.onStop && props.onStop(props.data)
                    }}
                />
            }
        </div>
    )
}

export default ProductionLabelListItem

interface Props {
    data: Label;
    onStop?: AnyFunction;
    readOnly?: boolean;
}