import { Typography } from '@mui/material';
import React from 'react';

export const MttrTooltipContent = () => {
    return <Typography variant="caption">
        <p>MTTR: <strong>Mean Time To Repair</strong></p>
        <p><strong>Lower</strong> MTTR represents higher <strong style={{ textDecoration: 'underline' }}>efficiency</strong> of the restoration team</p>
        <p>Mean Time To Repair is calculated as:</p>
        <p>Total BD time / Sum of occurrence of BD tagging or <br />
        BD total / Sum of occurrence of BD tagging</p>
    </Typography>
}

export const MtbfTooltipContent = () => {
    return <Typography variant="caption">
        <p>MTBF: <strong>Mean Time Between Failure</strong></p>
        <p><strong>Higher</strong> MTBF represents higher <strong style={{ textDecoration: 'underline' }}>reliability</strong> of the machine/equipment/line</p>
        <p>Mean Time Between Failure is calculated as:</p>
        <p>Total uptime / Sum of occurrence of BD tagging or <br/>
        (Scheduled production hours - UU total - BD <br/> total) / Sum of occurrence of BD tagging</p>
    </Typography>
}