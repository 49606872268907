/* eslint-disable react/prop-types */
import React from 'react';

import './Spinner.scss';
import { useAppSelector } from '../../store/hooks';
import { LoadingOutlined } from '@ant-design/icons';

export const Spinner = () => {
    const loading = useAppSelector((appState) => appState.ui.loader.count)
    return loading ? <LoadingOutlined className="spinner" spin /> : null
}

