/* eslint-disable react/prop-types */

import React from 'react';

const Circle = (props) => {
    const { color, width = 12, height = 12 } = props;
    return (
        <div
            className="mr-2"
            style={{
                backgroundColor: color,
                borderRadius: '50%',
                width,
                height,
            }}
        />
    );
};

export default Circle;
