import { type UserPreferenceModel } from "../models/preference.model"

export const UserPreferenceTransformer = (res: Record<any, any>): UserPreferenceModel => ({
    preferenceId: res.preference_id,
    entityId: res.entity_id,
    userId: res.user_id,
    applyTo: res.apply_to,
    language: res.language,
    time: res.time,
    defaultView: res.default_view,
    user: {
        name: `${res.user.name_first} ${res.user.name_last}`,
        email: res.user.email
    }
})
