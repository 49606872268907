import React from "react"
import languageFiles from "../Localization"
import { languagePreference } from "../../store/old/Preference/Preference.selector"
import { useAppSelector } from "../../store/hooks"
import { capitalize } from "lodash"

interface TranslateProps {
    content: string | string[]
}

const Translate: React.FC<TranslateProps> = ({ content }) => {
    const lang = useAppSelector(languagePreference)
    content = Array.isArray(content) ? content : [content]

    const keys = content.map(word => word
        .replace(/\s/g, "")
        .replace(/[^\w\s]/gi, "")
        .replace(/\//gi, "")
        .toLowerCase())

    const language = languageFiles[lang]

    let shouldUseDefault = false
    for (const key of keys) {
        if (!language[key]) {
            shouldUseDefault = true
            break
        }
    }

    let result
    if (shouldUseDefault) {
        result = keys.map(key => languageFiles.en[key] || capitalize(key)).join(" ")
    } else {
        result = keys.map(key => language[key])
    }

    return result
}

const translate = (content: string) => {
    return <Translate content={content} />
}

export default translate
