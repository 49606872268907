import { all, call, race, take } from "redux-saga/effects"
import { segmentQueryRange } from "../../../legacy/utils/date"
import { api_getBlockOEEData } from "./Data.services"
import { flattenTree } from "../../../legacy/utils/helpers"
import { OEEFactory } from "../../../legacy/utils/oee"
import { flatten, keys, mapValues } from "lodash"
import CONSTANTS from "../../../legacy/Constants"
import { Label, parseLabelArguments } from "../../../legacy/models"

export function* batchBlockOee({ entity, block_id, query, options = {} }) {
    const { date_range, res_x, res_period, sku_oee } = query
    const { entity_id, tzStartDifference } = entity

    let blocksOeeResource
    let blocksLabelsResource = []

    if (!sku_oee) {
        const res = yield call(api_getBlockOEEData, entity_id, block_id, query)

        blocksOeeResource = flattenTree(res, (n) => n.children).reduce(
            (acc, curr) => ({ ...acc, [curr.block_id]: OEEFactory(curr, { noLabels: !options.withLabels }) }),
            {}
        )

        if (options.withLabels) {
            blocksLabelsResource = generateBlockLabelsResource(res)
        }
    } else {
        const queries = segmentQueryRange(
            date_range,
            { res_x, res_period },
            tzStartDifference
        ).map((r) => ({
            ...query,
            date_range: r,
        }))

        const step = 2
        const raw = []
        let index = 0

        while (index < queries.length) {
            const next = index + step

            const { batch } = yield race({
                batch: all(
                    queries
                        .slice(index, next)
                        .map((r) => call(api_getBlockOEEData, entity_id, block_id, r))
                ),
                cancel: take(CONSTANTS.EVENTS.CANCEL_TASK),
            })

            if (batch) {
                raw.push(...batch)

                const rawCombined = flatten(
                    raw.map((item) => flattenTree(item, (n) => n.children))
                ).reduce((acc, curr) => {
                    if (!acc[curr.block_id])
                        return { ...acc, [curr.block_id]: { ...curr } }

                    acc[curr.block_id].oee = acc[curr.block_id].oee.concat(...curr.oee)

                    if (options.withLabels) {
                        acc[curr.block_id].effective_labels = (acc[curr.block_id].effective_labels || []).concat(...curr.effective_labels || [])
                    } 
                    return acc
                }, {})

                blocksOeeResource = mapValues(rawCombined, d => OEEFactory(d, { noLabels: !options.withLabels }))

                if (options.withLabels) {
                    blocksLabelsResource = raw
                        .map(generateBlockLabelsResource)
                        .reduce((acc, curr) => {
                            keys(curr).map(
                                (block_id) =>
                                    acc[block_id] = acc[block_id]
                                        ? { ...acc[block_id], ...curr[block_id] }
                                        : { ...curr[block_id] }
                            )
                            return acc
                        }, {})
                }

                index = next
            } else {
                break
            }
        }
    }

    return { oee: blocksOeeResource, labels: blocksLabelsResource }
}

const generateBlockLabelsResource = (root) => {
    return flattenTree(root, (n) => n.children)
        .map((b) => {
            const labels = b.effective_labels
                ? b.effective_labels.reduce((acc, curr) => {
                    const l = new Label(
                        ...parseLabelArguments({
                            ...curr,
                            block_id: b.block_id,
                        })
                    )
                    acc[l.label_id] = l
                    return acc
                }, {})
                : []

            return { [b.block_id]: labels }
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
}

export const getMachineStatusQuery = (query, samplingRate) => {
    const { res_x, res_period } = getClosestResolution(samplingRate);
    const range = Math.max(samplingRate, CONSTANTS.MAJOR_STOP_THRESHOLD);
    return {
        date_range: {
            upper: query.date_range.upper,
            lower: query.date_range.upper.clone().subtract(range, 'seconds')
        },
        res_x,
        res_period
    }
}

const getClosestResolution = (seconds) => {
    const minutes = Math.floor(seconds / 60)

    if (minutes === 0) {
        return { res_x: 1, res_period: 'minutes' } // fetch at minimum 1 minute resolution
    }

    return { res_x: minutes, res_period: 'minutes' }
}