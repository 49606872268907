export const id = {
    notes: 'Catatan',
    load: 'Mendapatkan',
    export: 'Unduh',
    unutilised: 'Un-digunakan',
    breakdowns: 'kerusakan',
    threshold: 'Ambang',
    oeepreview: 'pratinjau OEE',
    heatmappreview: 'heatmap Preview',
    oeecolors: 'warna OEE',
    color: 'warna',
    reset: 'ulang',
    theme: 'Tema',
    defaultview: 'tampilan default',
    defaulttimeselection: 'pemilihan waktu default',
    zoomlevel: 'Tingkat zoom',
    entity: 'Kesatuan',
    preferences: 'preferensi',
    custom: 'Adat',
    enteryourmailtoreset: 'Masukkan email Anda untuk me-reset',
    goback: 'Kembali',
    resetpassword: 'ulang kata sandi',
    logout: 'Keluar',
    ownersemail: 'pemilik Email',
    optional: 'Pilihan',
    industry: 'Industri',
    timezone: 'Zona waktu',
    nocoderequesttojoin: 'Tidak ada kode? Minta untuk bergabung ',
    enterreferralcodeinstead: 'Masukkan kode referral bukan',
    setup: 'Mempersiapkan',
    company: 'Perusahaan',
    referralcode: 'kode referral',
    submit: 'Kirimkan',
    summary: 'Ringkasan',
    language: 'Bahasa',
    login: 'Gabung',
    signin: 'Masuk',
    signup: 'Daftar',
    username: 'Nama pengguna',
    password: 'Kata sandi',
    forgotpassword: 'Tidak ingat kata sandi?',
    rememberme: 'Ingat saya',
    register: 'Daftar',
    registration: 'Registrasi',
    firstname: 'Nama depan',
    lastname: 'Nama keluarga',
    email: 'Surel',
    mobile: 'mobil',
    country: 'Negara',
    contactnumber: 'Nomor kontak',
    appointment: 'Janji',
    role: 'Wewenang',
    admin: 'admin',
    owner: 'pemilik',
    editor: 'editor',
    viewer: 'penonton',
    confirmpassword: 'konfirmasi sandi',
    iacceptthe: 'saya menerima',
    useragreement: 'perjanjian pengguna',
    alreadyregisteredlogin: 'Sudah terdaftar? Gabung',
    softwareasaservicetermsofuse: 'Software-as-a-Service Syarat Penggunaan',
    dashboard: 'Dasbor',
    streaming: 'Streaming',
    resolution: 'Resolusi',
    seconds: 'detik',
    second: 'kedua',
    minutes: 'menit',
    minute: 'menit',
    hours: 'jam',
    hour: 'Jam',
    weeks: 'minggu',
    week: 'Minggu',
    months: 'bulan',
    month: 'bulan',
    quarters: 'tempat tinggal',
    quarter: 'perempat',
    years: 'tahun',
    year: 'tahun',
    from: 'Dari',
    to: 'Untuk',
    start: 'Mulailah',
    end: 'Akhir',
    display: 'pameran',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Time Series',
    gateway: 'pintu gerbang',
    statusasof: 'Status sebagai dari',
    active: 'aktif',
    offline: 'Pengunjung',
    mergeasonemachine: 'Gabung sebagai salah satu mesin',
    drawarrowstoformprocessmap: 'Menggambar panah untuk peta proses bentuk',
    openblockeditor: 'Terbuka blok Editor',
    createnewtile: 'Buat New Tile',
    summarytile: 'Ringkasan genteng',
    selectablockthennameyoursummarytile:
    'Pilih blok, kemudian nama ubin ringkasan Anda',
    name: 'Nama',
    blockselector: 'blok pemilih',
    asset: 'Aset',
    therearenolabelstaggedinthisperiod: 'Tidak ada label tag di periode ini',
    standardtimes: 'Waktu Standar (s)',
    total: 'Total',
    last: 'Terakhir',
    oeeinput: 'OEE masukan',
    chartcolor: 'warna grafik',
    issueeditor: 'Editor masalah',
    issuemangement: 'isu Mangement',
    cumulativeoutput: 'Output kumulatif',
    tag: 'Menandai',
    label: 'Label',
    valueval: 'Nilai (Val)',
    time: 'Waktu',
    januaryjan: 'Januari (Jan)',
    februaryfeb: 'Februari (Februari)',
    marchmar: 'Maret (Mar)',
    aprilapr: 'April (April)',
    may: 'Mungkin',
    junejun: 'Juni (Juni)',
    julyjul: 'Juli (Juli)',
    augustaug: 'Agustus (Agustus)',
    septembersep: 'September (September)',
    octoberoct: 'Oktober (Oktober)',
    novembernov: 'November (November)',
    decemberdec: 'Desember (Desember)',
    day: 'Hari',
    days: 'hari-hari',
    mondaymonm: 'Senin (Mon) M',
    tuesdaytuet: 'Selasa (Tue) T',
    wednesdaywedw: 'Rabu (Wed) W',
    thursdaythut: 'Kamis (Thu) T',
    fridayfrif: 'Jumat (Fri) F',
    saturdaysats: 'Sabtu (Sat) S',
    sundaysuns: 'Minggu (Sun) S',
    edit: 'mengedit',
    add: 'Menambahkan',
    new: 'Baru',
    create: 'Membuat',
    search: 'Cari',
    close: 'Menutup',
    cancel: 'Membatalkan',
    save: 'Menyimpan',
    saved: 'Diselamatkan',
    clear: 'Bersih',
    enter: 'Memasukkan',
    navigator: 'Navigator',
    mandatoryfields: 'Bidang wajib',
    select: 'Pilih',
    merge: 'Menggabungkan',
    delete: 'Menghapus',
    done: 'selesai',
    filter: 'Saring',
    table: 'Meja',
    columns: 'kolom',
    rows: 'baris',
    cell: 'sel',
    page: 'Halaman',
    previous: 'Sebelumnya',
    next: 'Lanjut',
    serialnumber: 'Nomor seri',
    manuallyinputserialnumber: 'Manual masukan Serial Number',
    updateasset: 'Perbarui Aset',
    machine: 'Mesin',
    brand: 'Merek',
    model: 'Model',
    nodes: 'node',
    fusions: 'Fusions',
    chartorder: 'Orde grafik',
    primary: 'Utama',
    adddevicechannel: 'Tambah Device - Saluran',
    pushratesec: 'Mendorong Rate (detik)',
    voltagev: 'Tegangan (V)',
    sensor: 'Sensor',
    mode: 'Mode',
    digitalcount: 'Hitungan digital',
    digitalstate: 'Negara digital',
    analogvoltage: 'analog Voltage',
    charttitle: 'Judul grafik',
    unitmeasure: 'Unit Ukur',
    attribute: 'Atribut',
    autocycletime: 'Waktu Auto Cycle',
    unittransform: 'Unit Transform',
    leaveblankifnotapplicable: 'Kosongkan jika tidak berlaku',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'bidang wajib (cuti kosong jika saluran tidak digunakan)',
    fusedchart: 'Fused Bagan',
    fusionname: 'Nama Fusion',
    charttype: 'Jenis bagan',
    column: 'Kolom',
    line: 'Baris',
    state: 'Negara',
    expression: 'Ekspresi',
    combination: 'Kombinasi',
    currentcharts: 'Charts saat ini',
    expressionconstructor: 'ekspresi Constructor',
    validoperators: 'operator Hari',
    leaveblanktokeepexistingexpression:
    'Biarkan kosong untuk menjaga ekspresi yang ada',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Keseluruhan Peralatan Efektivitas',
    legend: 'Legenda',
    loading: 'Memuat',
    availability: 'Ketersediaan',
    performance: 'prestasi',
    quality: 'Kualitas',
    unscheduled: 'terjadwal',
    planneddowntime: 'Planned Downtime',
    changeoversetup: 'Changeover / Pengaturan',
    breakdown: 'Kerusakan',
    unutilized: 'Un-digunakan',
    speedloss: 'Rugi kecepatan',
    minorstops: 'minor Stops',
    rework: 'Mengolah lagi',
    rejectscrap: 'Tolak / Scrap',
    nodata: 'Tidak ada data',
    blockeditor: 'blok Editor',
    sort: 'Menyortir',
    logicblocks: 'Logic Blocks',
    sequence: 'Urutan',
    and: 'Dan',
    or: 'Atau',
    utilizationheatmap: 'pemanfaatan Heatmap',
    startdate: 'Mulai tanggal',
    enddate: 'Tanggal akhir',
    daily: 'Harian',
    weekly: 'Mingguan',
    monthly: 'Bulanan',
    quarterly: 'Triwulanan',
    yearly: 'Tahunan',
    trendanalysis: 'Analisis trend',
    metricselection: 'Seleksi metrik',
    metric: 'Metrik',
    data: 'Data',
    target: 'Target',
    periodselection: 'Seleksi periode',
    span: 'Menjangkau',
    every: 'Setiap',
    repeat: 'Ulang',
    repeats: 'mengulangi',
    addsingle: 'Tambah Satu',
    paretoanalysis: 'Analisis Pareto',
    // calendartime: 'waktu kalender',
    // scheduledtime: 'waktu yang dijadwalkan',
    // operatingtime: 'waktu operasi',
    // netruntime: 'run time Net',
    effective: 'Efektif',
    loadingloss: 'Memuat Rugi',
    availabilityloss: 'Ketersediaan Rugi',
    performanceloss: 'kinerja Rugi',
    qualityloss: 'Rugi kualitas',
    notagsinthiscategory: 'Tidak ada tag dalam kategori ini',
    overall: 'Secara keseluruhan',
    tagged: 'Tagged',
    occurrence: 'Kejadian',
    skumanagement: 'Manajemen SKU',
    category: 'Kategori',
    skuname: 'Nama SKU',
    skucode: 'Kode SKU',
    unitofmeasurementuom: 'Unit Pengukuran (UOM)',
    description: 'Deskripsi',
    csvupload: 'CSV Upload',
    template: 'Template',
    notifications: 'pemberitahuan',
    newnotification: 'Pemberitahuan baru',
    monitoring: 'pemantauan',
    topic: 'Tema',
    frequency: 'Frekuensi',
    recipients: 'Penerima',
    notifyby: 'Beritahu oleh',
    sms: 'SMS',
    alert: 'Waspada',
    report: 'Melaporkan',
    greaterthan: 'Lebih besar dari',
    lessthan: 'Kurang dari',
    greaterthanorequal: 'Lebih besar dari atau sama',
    lessthanorequal: 'Kurang dari atau sama',
    equal: 'Sama',
    range: 'Jarak',
    continuousduration: 'terus menerus Durasi',
    usermanagement: 'manajemen pengguna',
    user: 'pemakai',
    users: 'pengguna',
    invitenew: 'Undangan Baru',
    status: 'status',
    resend: 'Kirim Ulang',
    revoke: 'Mencabut',
    invite: 'Undang',
    invitees: 'undangan',
    addinviteesbypressingenterorclickingonthesign:
    "Menambahkan undangan dengan menekan Enter atau mengklik '+' tanda",
    schedules: 'Jadwal',
    shift: 'Bergeser',
    profile: 'Profil',
    changepassword: 'Ganti kata sandi',
    deleteaccount: 'Hapus akun',
    userguide: 'Panduan pengguna',
    overviewandbasiccontrol: 'Ikhtisar dan Kontrol Dasar',
    rangeandresolution: 'Range dan Resolusi',
    editingoptions: 'Pilihan mengedit',
    mergetiles: 'ubin Gabung',
    detailedassetview: 'pandangan aset rinci',
    assetoverview: 'Sekilas aset',
    summarystatistics: 'Ringkasan Statistik',
    dataanalysis: 'Analisis data',
    oeeanoverview: 'OEE gambaran',
    oeetoggle: 'OEE beralih',
    addingamachine: 'Menambahkan mesin',
    nodeconfiguration: 'konfigurasi Node',
    deleteupdateassets: 'aset pembaruan Delete',
    oeesummarytile: 'OEE Ringkasan genteng',
    oeetags: 'OEE Tags',
    issuemanagement: 'manajemen masalah',
    labelsandfiltering: 'Label dan penyaringan',
    useraccess: 'Akses pengguna',
    group: 'Kelompok',
    groups: 'Grup',
    joincompany: 'bergabung dengan perusahaan',
    notification: 'Pemberitahuan',
    createnotification: 'Buat pemberitahuan',
    node: 'Node',
    downtime: 'penghentian',
    downtimes: 'downtime',
    repeatstartdate: 'Ulangi Tanggal Mulai',
    repeatenddate: 'Ulangi Tanggal Akhir',
    optional: 'Pilihan',
    createplan: 'Buat Rencana',
    today: 'Hari ini',
    starttime: 'Waktu mulai',
    endtime: 'Akhir waktu',
    chart: 'Grafik',
    reject: 'Menolak',
    accept: 'Menerima',
    filterbydaterange: 'filter dengan rentang tanggal',
    filterbymostrecent: 'filter dengan terbaru',
    result: 'Hasil',
    type: 'Tipe',
    duration: 'Durasi',
    by: 'oleh',
    title: 'Judul',
    assigned: 'ditugaskan',
    action: 'Tindakan',
    track: 'jalur',
    issue: 'Isu',
    priority: 'Prioritas',
    requestor: 'Pemohon',
    opened: 'dibuka',
    assignee: 'Penerima',
    root: 'akar',
    cause: 'Sebab',
    identified: 'diidentifikasi',
    defined: 'ditetapkan',
    date: 'tanggal',
    resolve: 'menyelesaikan',
    resolved: 'terselesaikan',
    targetresolvedate: 'tanggal tekad Target',
    procurement: 'Pembelian',
    choose: 'Memilih',
    analyses: 'analisis',
    source: 'Sumber',
    selection: 'Pilihan',
    historical: 'Historis',
    mostrecent: 'terbaru',
    regression: 'Regresi',
    trendline: 'garis tren',
    upperbound: 'atas terikat',
    lowerbound: 'batas bawah',
    preview: 'pratinjau',
    period: 'Titik',
    series: 'seri',
    axis: 'sumbu',
    youhaveno: 'Anda tidak memiliki',
    code: 'Kode',
    all: 'Semua',
    upload: 'Upload',
    regressionanalysis: 'Analisis regresi',
    issuetracker: 'pelacak isu',
    labelsearch: 'label Pencarian',
    gatewaysetup: 'Gateway Pengaturan',
    setupgateway: 'pengaturan gerbang',
    radiomesh: 'jala radio',
    targetsetting: 'penetapan target',
    updatetrackedissue: 'Pembaruan masalah dilacak',
    shownewtrackedissue: 'menunjukkan masalah dilacak baru',
    low: 'rendah',
    mid: 'pertengahan',
    high: 'tinggi',
    linear: 'linear',
    exponential: 'eksponensial',
    logarithmic: 'logaritma',
    quadratic: 'kuadrat',
    polynomial: 'polinomial',
    general: 'Umum',
    datataggingenrichment: 'Data Tagging Pengayaan',
    hardware: 'Perangkat keras',
    nodemanagement: 'manajemen Node',
    createeditnotification: 'Buat mengedit notifikasi',
    conditions: 'Kondisi',
    addsku: 'menambahkan sku',
    editsku: 'mengedit SKU',
    setupagateway: 'setup gerbang',
    deviceserialnumber: 'nomor seri perangkat',
    repeatsweekly: 'mengulangi mingguan',
    repeatsdaily: 'mengulangi harian',
    repeatsmonthly: 'mengulangi bulanan',
    repeatsannually: 'mengulangi per tahun',
    repeatsevery: 'mengulangi setiap',
    hourly: 'per jam',
    biweekly: 'dua minggu sekali',
    standardtimemanagement: 'Manajemen Waktu Standar',
    notset: 'Tidak diatur',
    oeesku: 'OEE-SKU',
    skuassetstandardtime: 'SKU-Aset Waktu Standar',
    permission: 'Manajemen izin',
    cycletime: 'waktu siklus',
    operationshub: 'Pusat Operasi',
    productionachievement: 'Pencapaian Produksi',
    blockview: 'Tampilan Blok',
    assetview: 'Tampilan Aset',
    planninghub: 'Pusat Perencanaan',
    ciimpact: 'Peningkatan Berkelanjutan & Dampak',
    impactsuite: 'Dampak',
    advancedanalytics: 'Analysis Tingkat Lanjut',
    teamandorganization: 'Tim dan Organisasi',
    settingsandpreferences: 'Pengaturan dan Preferensi',
    calendartime: 'Waktu Kalender',
    scheduledproductiontime: 'Waktu Produksi Terjadwal',
    grossoperatingtime: 'Waktu Pengoperasian Kotor',
    netoperatingtime: 'Waktu Pengoperasian Bersih',
    editasset: 'Ubah Aset',
    assetid: 'Aset ID',
    iotnodes: 'Node IoT',
    version: 'Versi',
    inputtype: 'Tipe Input',
    noisefilter: 'Penyaring Noise',
    minmaxfilter: 'Filter Min/Max ',
    scheduling: 'Penjadwalan',
    goalsetting: 'Penetapan Tujuan',
    vicepresidentpresident: "Wakil Presiden/Presiden",
    executive: "Eksekutif",
    plantmanagerdirector: "Manajer Pabrik/Direktur",
    productionmanager: "Manajer Produksi",
    productionsupervisor: "Supervisor Produksi",
    productionoperator: "Operator Produksi",
    engineeringmanager: "Manajer Teknik",
    processengineer: "Insinyur Proses",
    cileanmanager: "Manajer CI/Lean",
    cileanengineer: "Insinyur CI/Lean",
    planningmanager: "Manajer Perencanaan",
    plannerscheduler: "Perencana/Penjadwal",
    maintenancemanager: "Manajer Pemeliharaan",
    maintenanceengineer: "Insinyur Pemeliharaan",
    maintenancetechnician: "Teknisi Pemeliharaan",
    qualityinspector: "Inspektur Kualitas",
    qualityengineer: "Insinyur Kualitas",
    qualitymanager: "Manajer Kualitas",
    itengineer: "Insinyur TI",
    itmanager: "Manajer TI",
    finance: "Keuangan",
    financemanager: "Manajer Keuangan",
    others: "Lainnya",
};
