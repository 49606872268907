import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';

// REDUX STORE
import { store } from '../..';

export const api_getSkus = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/sku`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createSku = (sku) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/sku`,
                method: 'POST',
                params: JSON.stringify(sku),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateSku = (sku) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/sku/${sku.sku_id}`,
                method: 'PUT',
                params: JSON.stringify(sku),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteSku = (sku_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/sku/${sku_id}`,
            method: 'DELETE',
            params: null,
            success: resolve,
            error: reject,
        });
    });
};

export const api_bulkCreateSku = (skus) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/skus`,
            method: 'POST',
            params: JSON.stringify({ skus }),
            success: resolve,
            error: reject,
        });
    });
};
