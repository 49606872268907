export const parseSkuArguments = (sku) => {
    return [
        sku.asset_id || -1,
        sku.sku_id,
        sku.name,
        sku.code,
        sku.category,
        sku.description,
        +sku.entity_id,
        sku.uom,
    ];
};

export const SimpleSku = (props) => {
    const codeName = `(${props.code || ''}) ${props.name} `;
    return Object.assign({}, props, { codeName });
};

export class Sku {
    constructor(
        asset_id,
        sku_id,
        name,
        code,
        category,
        description,
        entity_id,
        uom
    ) {
        this.asset_id = asset_id;
        this.sku_id = sku_id;
        this.name = name;
        this.code = code;
        this.category = category || '';
        this.description = description || '';
        this.entity_id = entity_id;
        this.uom = uom;
    }

    get codeName() {
        return `(${this.code || ''}) ${this.name}`;
    }
}
