import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { keyBy } from "lodash"
import type ObjectiveModel from "../../api/models/objective.model"
import { GoalsUnitOfTimeEnum } from "../../constants/goals.constants"
import { TimeKey } from "../actions/goals.action"

const initialState: GoalsState = {
    viewMode: "default",
    dialog: {
        add: false,
    },
    unitOfTime: GoalsUnitOfTimeEnum.MONTHLY,
    loading: false,
    list: {},
    viewing: null,
    goals: {},
    goalsOriginal: {},
    oee: {},
    currentProcess: null
}

export const goalsSlice = createSlice({
    name: "goals",
    initialState,
    reducers: {
        toggleDialog: (state, action: PayloadAction<{ type: "add", value: boolean }>) => {
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    [action.payload.type]: action.payload.value,
                },
            }
        },
        setUnitOfTime: (state, action: PayloadAction<GoalsUnitOfTimeEnum>) => {
            state.unitOfTime = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        clear: ({
            unitOfTime,
            dialog,
        }) => {
            return {
                ...initialState,
                unitOfTime,
                dialog: { ...dialog },
            }
        },
        set: (state, action: PayloadAction<ObjectiveModel[]>) => {
            state.list = keyBy(action.payload, "id")
        },
        view: (state, action: PayloadAction<ObjectiveModel | null>) => {
            state.viewing = action.payload
        },
        setViewMode: (state, action: PayloadAction<ObjectiveViewMode>) => {
            state.viewMode = action.payload
        },
        updateName: (state, action: PayloadAction<string>) => {
            if (state.viewing === null) {
                return state
            }

            state.viewing.name = action.payload
        },
        oeeUpdate: (state, action: PayloadAction<OeeMap>) => {
            state.oee = action.payload
        },
        originalGoalsUpdate: (state, action: PayloadAction<GoalMap>) => {
            state.goalsOriginal = action.payload
        },
        goalsUpdate: (state, action: PayloadAction<GoalMap>) => {
            state.goals = action.payload
        },
        setCurrentProcess: (state, action: PayloadAction<null | number>) => {
            state.currentProcess = action.payload
        },
    },
})

interface GoalsState {
    viewMode: ObjectiveViewMode
    dialog: {
        add: boolean
    }
    unitOfTime: GoalsUnitOfTimeEnum.MONTHLY | GoalsUnitOfTimeEnum.WEEKLY
    loading: boolean
    list: Record<number, ObjectiveModel>
    viewing: ObjectiveModel | null
    goals: GoalMap
    oee: OeeMap,
    currentProcess: null | number,
    goalsOriginal: GoalMap // this should not be changed unless new objective loaded
}

export type GoalMap = Record<Domain.BlockId, Record<TimeKey, { value: number, goalId?: number }>>
export type OeeMap = Record<Domain.BlockId, Record<TimeKey, Domain.Oee>>
export type ObjectiveViewMode = "default" | "planEdit" | "endGoalEdit"

export const {
    setUnitOfTime,
    toggleDialog,
    clear,
    set,
    setLoading,
    view,
    oeeUpdate,
    goalsUpdate,
    originalGoalsUpdate,
    updateName,
    setViewMode,
    setCurrentProcess
} = goalsSlice.actions

export default goalsSlice.reducer
