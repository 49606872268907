/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import Select from '../../../components/Select';
import CONSTANTS from '../../../Constants';
import './OEECategorySelect.scss';

export const OEECategorySelectOptions = (categories) => {
    return Object.keys(CONSTANTS.CAT).map((k, i) => {
        return {
            value: k,
            data: categories[k],
        };
    });
};

const OEECategorySelect = (props) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fas fa-filter" />
            </components.DropdownIndicator>
        );
    };

    const square = (color) => (
        <div
            style={{
                backgroundColor: color,
                width: '12px',
                height: '12px',
                borderRadius: 1,
                marginRight: 5,
            }}
        />
    );

    return (
        <Select
            className="oee-cat-select mb-2"
            closeMenuOnSelect={false}
            components={{ DropdownIndicator }}
            placeholder="Select OEE categories"
            getOptionLabel={(opt, i) => (
                <div className="d-flex align-items-center" key={i}>
                    {square(opt.data.color)}
                    {opt.data.name}
                </div>
            )}
            isMulti
            {...props}
        />
    );
};

export default OEECategorySelect;
