import { useSelector } from "react-redux"
import { languagePreference } from "../store/old/Preference/Preference.selector"
import { useMemo } from "react"
import { translator } from "../lib/translate"

const useTranslate = () => {
    const lang = useSelector(languagePreference)
    const translate = useMemo(() => translator(lang), [lang])

    return { lang, translate }
}

export default useTranslate
