import { Entity } from '../../../legacy/models';
import { makeRequest } from '../Shared/Shared.action';

// Get organization (entity tree)
export const api_getOrganization = (entity_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/organization`,
            success: resolve,
            error: reject,
        });
    });
};

// Get entity
export const api_getEntity = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createEntity = (user_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}/entity`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_requestJoinEntity = (user_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}/invite`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_referralJoinEntity = (user_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}/invite`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateEntity = (data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${data.entity_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
