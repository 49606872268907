/* eslint-disable react/prop-types */
import React from 'react';

export function Logo(props) {
    return (
        <div className={props.className}>
            <img src={props.src} />
        </div>
    );
}
