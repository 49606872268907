/* eslint-disable react/prop-types */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import { mapValues, values } from 'lodash';
import React, { useEffect, useState } from 'react';

const BATCH_REC_DEFAULTS = { in: 1, out: 1, upp: 99999, low: 0.001 };
const BATCH_REC_MIN = 0;
const BATCH_REC_MAX = 99999;

export const validateBatchRecognition = (mode, value) => {
    const isAnalog = mode === '3a';

    if (!isAnalog) return Promise.resolve();

    value = mapValues(value, (v) => +v);

    if (!value.in || !value.out || !value.upp || !value.low) {
        return Promise.reject(new Error('Missing/invalid inputs'));
    }

    for (const val of values(value)) {
        if (val < BATCH_REC_MIN) {
            return Promise.reject(
                new Error('Batch recognition fields must be greater than zero')
            );
        }

        if (val > BATCH_REC_MAX) {
            return Promise.reject(
                new Error('Batch recognition fields cannot be greater than 99999')
            );
        }
    }

    if (value.low >= value.upp) {
        return Promise.reject(
            new Error('Low threshold has to be less than high threshold')
        );
    }

    return Promise.resolve();
};

const BatchRecognitionInputs = ({ value, onChange }) => {
    value = value || BATCH_REC_DEFAULTS;
    const [inValue, setInValue] = useState(value.in);
    const [outValue, setOutValue] = useState(value.out);
    const [uppValue, setUppValue] = useState(value.upp);
    const [lowValue, setLowValue] = useState(value.low);

    useEffect(() => {
        onChange({
            in: inValue,
            out: outValue,
            upp: uppValue,
            low: lowValue,
        });
    }, [inValue, outValue, uppValue, lowValue]);

    return (
        <div className="d-flex flex-column">
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>High</span>}
                className="w-100"
                value={uppValue}
                onChange={(e) => setUppValue(e.target.value)}
                placeholder="Default: 99999"
            />
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>Low</span>}
                className="w-100"
                value={lowValue}
                onChange={(e) => setLowValue(e.target.value)}
                placeholder="Default: 0.001"
            />
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>In</span>}
                className="w-100"
                value={inValue}
                onChange={(e) => setInValue(e.target.value)}
                placeholder="Default: 1"
            />
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>Out</span>}
                className="w-100"
                value={outValue}
                onChange={(e) => setOutValue(e.target.value)}
                placeholder="Default: 1"
            />
        </div>
    );
};

export default BatchRecognitionInputs;

export const BatchRecognitionFormLabel = () => {
    return (
        <span>
      Cycle Time
            <Popover
                placement="right"
                content={
                    <div
                        className="d-flex flex-column"
                        style={{ width: 250, textAlign: 'left' }}
                    >
                        <p>
                            <span className="emphasise">High</span>: Upper bound of threshold
              within which equipment is recognized as productive;
                        </p>
                        <p>
                            <span className="emphasise">Low</span>: Lower bound of threshold
              within which equipment is recognized as productive;
                        </p>
                        <p>
                            <span className="emphasise">In (sec)</span>: Minimum continuous
              duration inside threshold boundaries for equipment to be
              recognized as productive;
                        </p>
                        <p>
                            <span className="emphasise">Out (sec)</span>: Minimum continuous
              duration outside threshold boundaries for equipment to be
              recognized as non-productive;
                        </p>
                    </div>
                }
            >
                <InfoCircleOutlined className="ml-2" />
            </Popover>
        </span>
    );
};
