import moment from "moment"
import type EntityModel from "../models/entity.model"

const EntityTransformer = (res: Record<any, any>): EntityModel => ({
    entityId: res.entity_id,
    entityName: res.entity_name,
    hierarchyLevel: res.hierarchy_level,
    parentEntityId: res.parent_entity_id,
    timezone: res.timezone,
    gateways: res.gateways,
    signedUrl: res.signedUrl,
    dayStart: res.day_start,
    dayStartTimezone: findDayStartTimezone(res),
    createdAt: res.created_at
})

const findDayStartTimezone = (res: Record<any, any>) => {
    const tzHours = (() => {
        const sign = res.timezone[0] === "+" ? 1 : -1
        const hour = +res.timezone.split(":")[0].replace(/[+/-]/, "")
        return hour * sign
    })()

    if (!res.day_start) return tzHours

    const utcDayStartHour = moment.utc(res.day_start).get("hour")
    const utcMidnightHour = moment
        .utc(moment().utcOffset(res.timezone).startOf("day"))
        .get("hour")

    const diff = (utcDayStartHour + 24 - utcMidnightHour) % 24
    return tzHours - diff
}

export default EntityTransformer
