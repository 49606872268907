
import { createSelector } from 'reselect';

import { depthFirstTraversal } from '../../../legacy/utils/helpers';
import { entityBlock } from '../Blocks/Blocks.selector';

export const assetsState = (appState) =>
    appState.assets;

export const arrayAssets = (appState) => {
    const root = entityBlock(appState);

    return assetsOrderedByBlock(appState.auth.user, root)
};


export const mapsAssetsByPolicy = (authUser, assetsResource) => {
    let newMapAssets = {};

    const blocksSet = new Set();

    if (authUser.policy) {
        authUser.policy.blocks.forEach((v) => {
            blocksSet.add(v.block_id);
        });
    }

    for (let key in assetsResource) {
        let item = assetsResource[key];

        const block_id = item.block_id;

        if (
            authUser.role_name === 'owner' ||
      !authUser.policy ||
      blocksSet.has(block_id)
        ) {
            newMapAssets = {
                ...newMapAssets,
                [item.asset_id]: item,
            };
        }
    }

    return newMapAssets;
};

export const arrayAssetsWithoutUsingBlock = (
    assetPolicies
) => {
    // const newMapAssetsByPolicy = mapsAssetsByPolicy(appState);
    const arrAssets = [];

    for (let key in assetPolicies) {
        let item = assetPolicies[key];
        arrAssets.push(item);
    }

    arrAssets.sort((a, b) => {
        if (a.asset_name.toLowerCase() < b.asset_name.toLowerCase()) {
            return -1;
        }

        if (a.asset_name.toLowerCase() > b.asset_name.toLowerCase()) {
            return 1;
        }

        return 0;
    });

    return arrAssets;
};

export const assetsSelector = createSelector(assetsState, (v) => v.assets);

export const assetsOrderedByBlock = (user, rootBlock) => {
    const blocksSet = new Set();

    if (user.policy) {
        user.policy.blocks.forEach((v) => {
            blocksSet.add(v.block_id);
        });
    }

    if (!rootBlock) return [];

    const orderedBlocks = depthFirstTraversal(rootBlock);
    const assets = orderedBlocks
        .filter((b) => {
            if (b.asset) {
                const block_id = b.asset.block_id;

                if (
                    user.role_name === 'owner' ||
                    !user.policy ||
                    blocksSet.has(block_id)
                ) {
                    return b.asset;
                }
            }
        })
        .map((b) => b.asset);

    return assets;
};
