import "./style.scss"
import cx from "classnames"
import React, { CSSProperties } from "react"
import Grid from "../../../Grid/Grid"

const TreeTableCell = (props: Props) => {
    return <Grid
        className={cx(props.className, "tree-table-cell")}
        style={{ ...props.style, width: props.width, height: props.height }}
        item
    >
        {props.children}
    </Grid>
}

interface Props {
    className?: string
    width?: string | number
    height?: string | number
    children?: React.ReactNode
    style?: CSSProperties
}

export default TreeTableCell
