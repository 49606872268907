import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type ReportModel from "../../api/models/report.model"
import { keyBy, pickBy } from "lodash"

interface ReportsState {
    loading: boolean
    reports: Record<string | number, ReportModel>
    viewing: ReportModel | null
}

const initialState: ReportsState = {
    loading: false,
    reports: {},
    viewing: null,
}

export const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        clear: () => {
            return initialState
        },
        set: (state, action: PayloadAction<ReportModel[]>) => {
            state.reports = keyBy(action.payload, "id")
        },
        add: (state, action: PayloadAction<ReportModel[]>) => {
            state.reports = { ...state.reports, ...keyBy(action.payload, "id") }
        },
        remove: (state, action: PayloadAction<number>) => {
            state.reports = pickBy(state.reports, report => report.id !== action.payload)
        },
        view: (state, action: PayloadAction<ReportModel | null>) => {
            state.viewing = action.payload
        }
    },
})

export const { clear, set, setLoading, view, add, remove } = reportsSlice.actions

export default reportsSlice.reducer
