export const EntityConstants = {
    ACTIONS: {
        SET_ENTITIES_STATE: 'SET_ENTITIES_STATE',
        ADD_ENTITIES_RESOURCE: 'ADD_ENTITIES_RESOURCE',
        GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
        GET_ENTITY_INFORMATION_REQUEST: 'GET_ENTITY_INFORMATION_REQUEST',
        CHANGE_ENTITY_FLOW: 'CHANGE_ENTITY_FLOW',
        SET_ACTIVE_ENTITY: 'SET_ACTIVE_ENTITY',
        UPDATE_ENTITY: 'UPDATE_ENTITY',
    },
};
