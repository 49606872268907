
import { updateStoreRedux } from '../../../legacy/utils/redux';
import { GroupConstants as K } from './Group.constant';
import { pickBy } from 'lodash';

const initialReducer = {
    groups: {},
};

export const groupsReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.SET_GROUPS_RESOURCE) {
        return updateStoreRedux(state, {
            groups: action.payload.groups,
        });
    }

    if (action.type === K.ACTIONS.ADD_GROUPS_RESOURCE) {
        return updateStoreRedux(state, {
            groups: { ...state.groups, ...action.payload.groups },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_GROUPS_RESOURCE) {
        const deleteSet = new Set(action.payload.group_ids);
        const deletedState = pickBy(
            { ...state.groups },
            ({ group_id }) => !deleteSet.has(group_id)
        );

        return updateStoreRedux(state, {
            groups: deletedState,
        });
    }

    return updateStoreRedux(state, {});
};
