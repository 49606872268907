/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import LabelWrapper from '../../../components/LabelWrapper';
import Select from '../../../components/Select';
import InputMultiOption from '../../../components/InputMultiOption';

import '../Trend.scss';

import { TrendConstants as K } from '../../../../store/old/UI/Trend/TrendUI.constants';

import { flash } from '../../../components/Flash';
import { DatePicker } from 'antd';
import { getAllowableResolutions } from '../../../utils/controls';
import AukButton from '../../../components/AukButton';
import { uiDatetimeFormat } from '../../../utils/helpers';

const spanAttributes = _.values(K.SPAN_PERIODS).map((p, i) => ({
    id: i + 1,
    label: _.capitalize(p),
    name: p,
}));

const stepAttributes = _.values(K.STEP_PERIODS).map((p, i) => ({
    id: i + 1,
    label: _.capitalize(p),
    name: p,
}));

const unwrapMultiOptionInputField = ({ x, unit }, attributes) => {
    const unit_id = attributes.find((a) => a.name === unit).id;
    return { [unit_id]: `${x}` };
};

const collateMultiOptionInputField = (obj, attributes) => {
    const [key, val] = _.toPairs(obj)[0];
    const unit = attributes.find((a) => a.id === +key).name;
    return { x: val, unit };
};

export default class Navigator extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getStateFromProps();

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.asset && prevState.asset.asset_id) !==
      (this.state.asset && this.state.asset.asset_id)
        ) {
            this.setState({ chart: null });
        }

        if (!_.isEqual(prevState.span, this.state.span)) {
            const { allowedResolutions } = this;
            const len = allowedResolutions.length;
            this.setState({
                resolution: len ? allowedResolutions[Math.floor(len / 2)] : null,
            });

            return;
        }
    }

    getStateFromProps() {
        const {
            trendStore: {
                data_type,
                asset_id,
                chart_id,
                metric,
                start_date,
                resolution,
                span,
                step,
                single,
                repeats,
            },
            assets,
            fusions,
            metadata,
        } = this.props;

        const chart = (() => {
            switch (data_type) {
            case 'metadata':
                return metadata[chart_id] || null;
            case 'fusion':
                return fusions[chart_id] || null;
            default:
                return null;
            }
        })();

        return {
            metric: K.METRICS.find((m) => m.id === metric.id),
            asset: assets[asset_id] || null,
            chart,
            start_date,
            resolution,
            step: unwrapMultiOptionInputField(step, stepAttributes),
            span: unwrapMultiOptionInputField(span, spanAttributes),
            single,
            repeats,
        };
    }

    handleChange(field, value) {
        this.setState({ [field]: value });
    }

    handleChangeMultiOption(field, value) {
        this.setState({ [field]: { ...value } });
    }

    get isValidForm() {
        const {
            asset,
            chart,
            start_date,
            step,
            span,
            resolution,
            repeats,
            single,
        } = this.state;

        if (!single && (repeats <= 0 || repeats > 10)) {
            flash({
                message: 'Repeats must be given a value between 1-10',
                status: 'warning',
            });
            return false;
        }

        const [, stepVal] = _.toPairs(step)[0];
        const [, spanVal] = _.toPairs(span)[0];

        if (
            !asset ||
      !chart ||
      !start_date ||
      !stepVal ||
      !spanVal ||
      !resolution
        ) {
            flash({
                message: 'Incomplete parameters',
                details: 'Please fill in missing fields.',
                status: 'error',
            });
            return false;
        }

        return true;
    }

    get data() {
        const {
            metric,
            asset,
            chart,
            start_date,
            resolution,
            step,
            span,
            single,
            repeats,
        } = this.state;

        return {
            metric,
            asset_id: asset.asset_id,
            chart_id: chart.fusion_id || chart.metadata_id,
            data_type: chart.fusion_id ? 'fusion' : 'metadata',
            start_date,
            resolution,
            step: collateMultiOptionInputField(step, stepAttributes),
            span: collateMultiOptionInputField(span, spanAttributes),
            single,
            repeats: +repeats,
            block_id: asset.block_id,
        };
    }

    get allowedResolutions() {
        const span = collateMultiOptionInputField(this.state.span, spanAttributes);

        return getAllowableResolutions({
            duration: moment.duration(span.x, span.unit).as('seconds'),
        });
    }

    render() {
        const {
            state: {
                metric,
                step,
                span,
                resolution,
                chart,
                asset,
                start_date,
                single,
                repeats,
            },
            props: { assets, fusions, metadata, arrayAssets },
            handleChange,
            handleChangeMultiOption,
        } = this;

        return (
            <div className="trend-navigator">
                <div className="trend-navigator__body">
                    <div className="metric-selection d-flex justify-content-center align-items-center flex-column mb-3">
                        <h6 className="mb-2">Metric Selection</h6>
                        <LabelWrapper label="Metric" className="w-100">
                            <Select
                                menuPortalTarget={document.body}
                                options={K.METRICS}
                                value={metric}
                                getOptionLabel={(opt) => opt.value.toUpperCase()}
                                onChange={handleChange.bind(this, 'metric')}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Asset" className="w-100">
                            <Select
                                menuPortalTarget={document.body}
                                options={arrayAssets}
                                value={asset}
                                getOptionLabel={(opt) => opt.asset_name}
                                onChange={handleChange.bind(this, 'asset')}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Chart" className="w-100">
                            <Select
                                menuPortalTarget={document.body}
                                options={asset ? asset.charts : []}
                                value={chart}
                                getOptionLabel={(opt) => opt.chart_title}
                                onChange={handleChange.bind(this, 'chart')}
                            />
                        </LabelWrapper>
                    </div>
                    <div className="period-selection d-flex justify-content-center align-items-center flex-column">
                        <h6 className="mb-2">Period Selection</h6>
                        <LabelWrapper label="Start Time" className="w-100">
                            <DatePicker
                                format={uiDatetimeFormat}
                                showTime={{ format: 'HH:mm' }}
                                value={start_date}
                                onChange={handleChange.bind(this, 'start_date')}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Span" className="w-100 wrap-multi-input">
                            <InputMultiOption
                                attributes={spanAttributes}
                                value={span}
                                handleChange={handleChangeMultiOption.bind(this, 'span')}
                                defaultActive={2}
                                valid={(val) => !!+val}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Resolution" className="w-100">
                            <Select
                                menuPortalTarget={document.body}
                                options={this.allowedResolutions}
                                value={resolution}
                                getOptionLabel={(opt) => `${opt.res_x} ${opt.res_period}`}
                                onChange={handleChange.bind(this, 'resolution')}
                                styles={{
                                    control: (controlStyles) => {
                                        return Object.assign(
                                            {},
                                            { ...controlStyles },
                                            !resolution && { border: '1px solid #dc3545' }
                                        );
                                    },
                                }}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Every" className="w-100 wrap-multi-input">
                            <InputMultiOption
                                attributes={stepAttributes}
                                value={step}
                                handleChange={handleChangeMultiOption.bind(this, 'step')}
                                defaultActive={2}
                                valid={(val) => !!+val}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Repeats" className="w-100">
                            <input
                                className={`form-control ${
                                    repeats <= 0 || repeats > 10 ? 'is-invalid' : ''
                                }`}
                                style={{ opacity: !!single ? 0.8 : 1 }}
                                type="number"
                                value={repeats}
                                onChange={(e) => handleChange('repeats', e.target.value)}
                                disabled={!!single}
                            />
                        </LabelWrapper>
                        <LabelWrapper label="Single" className="w-100">
                            <div
                                className="d-flex align-items-center"
                                style={{ width: '100%', height: '100%' }}
                            >
                                <input
                                    type="checkbox"
                                    checked={single}
                                    onChange={(e) => handleChange('single', e.target.checked)}
                                />
                            </div>
                        </LabelWrapper>
                    </div>
                </div>
                <div className="trend-navigator__footer">
                    <AukButton.Outlined onClick={this.props.handleClickClear}>
            Clear
                    </AukButton.Outlined>
                    <AukButton.Blue
                        onClick={() =>
                            this.isValidForm &&
              this.props.handleClickUpdateNavigator(this.data)
                        }
                    >
            Load
                    </AukButton.Blue>
                </div>
            </div>
        );
    }
}
