/* eslint-disable react/prop-types */

import React from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import { useDrop } from 'react-dnd';
// CONSTANTS
import CONSTANTS from '../Constants';
import { DashboardConstants as K } from '../../store/old/UI/Dashboard/Dashboard.constants';

const getCellData = (data, row, col) => {
    const y = row.toString();
    const x = col.toString();

    return !data[y] ? null : data[y][x] || null;
};

const GridCanvas = (props) => {
    const {
        overscanCount,
        onSectionRendered,
        cellRender,
        data,
        columnCount,
        rowCount,
        gridCell,
        handleDrop,
        cellRangeRenderer,
    } = props;

    const [collectedProps, drop] = useDrop(
        () => ({
            accept: CONSTANTS.DND.ITEM_TYPES.WIDGET,
            drop: (item, monitor) => {
                handleDrop(item, monitor);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [data]
    );

    const _onSectionRendered = ({
        columnStartIndex,
        columnStopIndex,
        rowStartIndex,
        rowStopIndex,
    }) => {
        const up = Math.max(0, rowStartIndex - overscanCount),
            down = rowStopIndex + overscanCount,
            left = Math.max(0, columnStartIndex - overscanCount),
            right = columnStopIndex + overscanCount;

        onSectionRendered && onSectionRendered({ up, down, left, right });
    };

    const _cellRender = ({ columnIndex, key, rowIndex, style }) => {
        const cellData = getCellData(data, rowIndex, columnIndex);
        return (
            <div key={key} style={{ ...style, padding: gridCell.padding / 2 }}>
                {cellRender(cellData, { row: rowIndex, col: columnIndex })}
            </div>
        );
    };

    return (
        <AutoSizer className="w-100 h-100">
            {({ width, height }) => {
                return (
                    <div ref={drop}>
                        <Grid
                            cellRenderer={_cellRender}
                            columnCount={columnCount}
                            rowCount={rowCount}
                            columnWidth={gridCell.width + gridCell.padding}
                            rowHeight={gridCell.height + gridCell.padding}
                            width={width}
                            height={height}
                            onSectionRendered={_onSectionRendered}
                            cellRangeRenderer={cellRangeRenderer}
                        />
                    </div>
                );
            }}
        </AutoSizer>
    );
};

GridCanvas.defaultProps = {
    columnCount: 40,
    rowCount: 40,
    gridCell: {
        width: K.CELL_DIM.WIDTH,
        height: K.CELL_DIM.HEIGHT,
        padding: K.CELL_DIM.PADDING,
    },
    overscanCount: 0,
    data: {},
    onSectionRendered: (viewport) => null,
    cellRender: (data, coords) => null,
    handleDrop: (item, monitor) => null,
};

export default GridCanvas;
