/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { Modal } from 'reactstrap';
import { fileDatetimeFormat } from '../utils/helpers';
import { store } from '../../store';
import { getWindow } from '../../store/old/UI/Controls/Controls.selector';

class CSVDownloadPrefixer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { prefix: props.defaultPrefix || '' };
        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { defaultPrefix } = this.props;
        if (defaultPrefix !== prevProps.defaultPrefix) {
            this.setState({ prefix: defaultPrefix });
        }
    }

    handleChangeInput(e) {
        this.setState({ prefix: e.target.value });
    }

    get defaultRange() {
        const { lower, upper } = getWindow(store.getState());

        return `${moment(lower).format(fileDatetimeFormat)}_${moment(upper).format(
            fileDatetimeFormat
        )}`;
    }

    render() {
        return (
            <Modal isOpen={this.props.show} toggle={this.props.toggle}>
                <div className="panel">
                    <div className="panel-header">
                        <p className="panel-title">
                            <span style={{ marginLeft: 15 }}>Export to CSV</span>
                        </p>
                    </div>
                    <div className="panel-body">
                        <div className="container">
                            <div className="row mb-3">
                                <div className="col-5 pr-0 d-flex align-items-center">
                                    <label>Enter a prefix for your files: </label>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        className="form-control is-valid"
                                        placeholder=""
                                        value={this.state.prefix}
                                        onChange={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <p style={{ textAlign: 'right', fontSize: 10 }}>
                (Preview) <i>{this.state.prefix}</i>_
                                {this.props.date ? this.props.date : this.defaultRange}
                            </p>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button onClick={this.props.toggle} className="btn b-cancel">
              CANCEL
                        </button>
                        <button
                            className="btn b-save"
                            onClick={() => {
                                this.props.save(this.state.prefix);
                                this.props.toggle();
                            }}
                        >
              EXPORT
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CSVDownloadPrefixer;
