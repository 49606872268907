/* eslint-disable react/prop-types */
// LIBRARIES
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { List } from 'antd';

// COMPONENTS
import AnomalyNotificationFormModal from './components/AnomalyNotificationFormModal';
import ColorLegendSequential from '../../Charts/v1/ColorLegendSequential';
import CorrelationFormModal from './components/CorrelationFormModal';
import RegressionControls from './RegressionControls';
import { Scatterplot } from '../../Charts/v1';
import { Permission } from '../../components/Permission';

// CONSTANTS
import { RegressionConstants as K } from '../../../store/old/UI/Regression/RegressionUI.constants';

// HELPERS
import { uiDatetimeFormatWithSeconds } from '../../utils/helpers';
import { trendlineEquation } from '../../utils/regression';

// SELECTORS
import {
    regressionChartDomains,
    regressionState,
} from '../../../store/old/UI/Regression/RegressionUI.selector';

// ACTIONS
import { changeRegressionSelection } from '../../../store/old/UI/Regression/RegressionUI.action';
import { deleteCorrelationRequest } from '../../../store/old/Correlations/Correlations.action';

import './RegressionAnalysis.scss';
import { flash } from '../../components/Flash';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const getAxisLabel = (chart) =>
    `${chart.ofAsset.asset_name}: ${chart.chart_title}`;

const colorScale = d3.scaleSequential(d3.interpolateGnBu);

const regressionTooltip = (x, y, d) => {
    return (
        '<div>' +
    '<ul>' +
    `<li>${moment(d.time).format(uiDatetimeFormatWithSeconds)}</li>` +
    '<li>' +
    `(x) ${x} : ${d.x}` +
    '</li>' +
    '<li>' +
    `(y) ${y} : ${d.y}` +
    '</li>' +
    '</ul>' +
    '</div>'
    );
};

class RegressionAnalysisComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            correlationFormModal: false,
            notificationFormModal: false,
        };
    }

    toggleFormModal(type) {
        this.setState({ [type]: !this.state[type] });
    }

    get scatterchartProps() {
        const {
            regressionStore: { sourceX, sourceY, lowerBound, upperBound },
            predict,
            data,
            domains,
        } = this.props;

        const lineDash = [5, 3];
        const paths = [
            { id: 'trend', dx: 0, dy: 0 },
            {
                id: 'upperBound',
                class: 'bound',
                ...upperBound,
                lineDash,
            },
            {
                id: 'lowerBound',
                class: 'bound',
                ...lowerBound,
                lineDash,
            },
        ];

        const xAxisLabel = getAxisLabel(sourceX);
        const yAxisLabel = getAxisLabel(sourceY);
        return {
            colorScale,
            data,
            paths: predict ? paths : null,
            generatePathData: this.genPath.bind(this),
            xAccessor: K.ACCESSORS.x,
            yAccessor: K.ACCESSORS.y,
            colorAccessor: K.ACCESSORS.color,
            xDomain: domains.x,
            yDomain: domains.y,
            colorDomain: domains.color,
            xAxisLabel,
            yAxisLabel,
            useTooltip: true,
            htmlTooltip: (d) => regressionTooltip(xAxisLabel, yAxisLabel, d),
        };
    }

    get colorLegend() {
        const { data } = this.props.regressionStore;
        if (!(data && data.length)) return null;

        return (
            <div className="color-legend-wrapper">
                <ColorLegendSequential
                    scale={colorScale}
                    domain={this.props.domains.color}
                    indicators={['', '', 'Latest']}
                />
            </div>
        );
    }

    genPath(d, line_generator) {
        const { predict, domains } = this.props;
        const [x1, x2] = domains.x;

        const quantiles = 200;
        const { dx, dy } = d;

        const points = d3
            .range(x1, x2, (x2 - x1) / quantiles)
            .map((x) => [x, predict(x + dx) + dy])
            .filter((p) => isFinite(p[0]) && isFinite(p[1]));

        return { ...d, data: line_generator(points) };
    }

    get trendlineEqn() {
        const { regressionMeta } = this.props;
        const { regression } = this.props.regressionStore;
        if (!regression || !regressionMeta) return null;

        const { r2, model_parameters } = regressionMeta;

        return (
            <div className="trendline-eqn m-2">
                <div className="d-flex">R-square: {r2.toFixed(5)}</div>
                {r2 ? (
                    <div className="d-flex">
            Best-fit: {trendlineEquation(regression.value, model_parameters)}
                    </div>
                ) : null}
            </div>
        );
    }

    get isDemoUser() {
        const { authUser } = this.props;
        const list = ['demo_owner@auk.industries', 'admin_demo@auk.industries'];
        return list.includes(authUser.email);
    }

    advancedFeaturePrompt() {
        return flash({
            message: 'This is an advanced feature',
            details: 'Please contact support@auk.industries for access.',
            status: 'info',
        });
    }

    render() {
        const padding = 8;

        const toggleCorrelationForm = this.toggleFormModal.bind(
            this,
            'correlationFormModal'
        );
        const toggleNotificationForm = this.toggleFormModal.bind(
            this,
            'notificationFormModal'
        );

        return (
            <div
                className="d-flex w-100 flex-row regression-analysis"
                style={{ height: '100%' }}
            >
                <div style={{ flexGrow: 1, padding, position: 'relative' }}>
                    <div
                        style={{
                            border: '1px solid #ddd',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        {this.trendlineEqn}
                        {!!this.props.regressionStore.data.length && (
                            <Scatterplot {...this.scatterchartProps} />
                        )}
                        {this.colorLegend}
                    </div>
                </div>
                <div style={{ flexShrink: 0, padding }}>
                    <RegressionControls
                        handleClickSave={
                            this.isDemoUser
                                ? toggleCorrelationForm
                                : this.advancedFeaturePrompt
                        }
                        handleClickAlert={
                            this.isDemoUser
                                ? toggleNotificationForm
                                : this.advancedFeaturePrompt
                        }
                    />
                </div>

                <div className="list-analyses" style={{ flexShrink: 0, padding }}>
                    <List
                        header={<div>Saved Analyses</div>}
                        dataSource={_.values(this.props.correlations)}
                        renderItem={(c) => (
                            <ListItem
                                {...c}
                                isActive={
                                    this.props.regressionStore.selection === c.correlation_id
                                }
                                handleClick={this.props.handleClickSelection}
                                handleClickDelete={(correlation_id) =>
                                    this.props.handleDeleteCorrelation(correlation_id)
                                }
                            />
                        )}
                    />
                </div>
                <CorrelationFormModal
                    getCorrelationFormData={this.props.getCorrelationFormData}
                    show={this.state.correlationFormModal}
                    toggle={toggleCorrelationForm}
                />
                <AnomalyNotificationFormModal
                    show={this.state.notificationFormModal}
                    toggle={toggleNotificationForm}
                />
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        authUser: appState.auth.user,
        regressionStore: regressionState(appState),
        domains: regressionChartDomains(appState),
        correlations: appState.correlations.correlations,
        entity: currentEntitySelector(appState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleClickSelection: (correlationId) => dispatch(changeRegressionSelection(correlationId)),
        handleDeleteCorrelation: (entityId, correlationId, cb) => dispatch(deleteCorrelationRequest(entityId, correlationId, cb)),
        changeRegressionSelection: (id) => dispatch(changeRegressionSelection(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegressionAnalysisComponent);

class ListItem extends React.Component {
    render() {
        const { title, correlation_id, isActive } = this.props;

        return (
            <div
                className={`list-item ${isActive ? 'active' : ''}`}
                onClick={() => this.props.handleClick(correlation_id)}
            >
                <div>
                    <strong>{title}</strong>
                </div>
                <Permission forResource resource="regression_analysis" canDo="full">
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            this.props.handleClickDelete(correlation_id);
                        }}
                        style={{ color: '#222' }}
                    >
                        <i className="fas fa-trash-alt" />
                    </span>
                </Permission>
            </div>
        );
    }
}
