/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import CONSTANTS from '../Constants';
import { evalViewport } from '../utils/helpers';
import { oeeCategoriesSelector } from '../../store/old/UI/OEE/OEE.selector';
import translate from '../utils/translate';

import './OEELegend.scss';

export const OEELegend = (props) => {
    const categories = useSelector(oeeCategoriesSelector);
    return (
        <div
            className={`legend ${props.vertical ? 'vertical' : ''}`}
            style={props.style}
        >
            {props.title && <div className="legend-title">{props.title}</div>}
            {_.values(categories)
                .sort((a, b) =>
                    props.vertical ? a.legendOrder : a.legendOrder - b.legendOrder
                )
                .map((cat, i) => {
                    return (
                        <div key={i} className="legend-keys">
                            <div
                                className="legend-color"
                                style={{ background: cat.color }}
                            ></div>
                            <span>
                                {evalViewport(CONSTANTS.VIEWPORTS.BREAKPOINTS.PHONE) ? (
                                    `[${cat.prefix.toUpperCase()}]`
                                ) : (
                                    <>
                                        [{cat.prefix}] {translate(cat.altLabel)}
                                    </>
                                )}
                            </span>
                        </div>
                    );
                })}
        </div>
    );
};
