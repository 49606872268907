/* eslint-disable react/prop-types */

import React from 'react';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './AukTooltip.scss';

const Help = (props) => {
    const { color = 'gray', title, placement = 'top' } = props;
    return (
        <Tooltip
            color={color}
            title={title}
            placement={placement}
            arrowPointAtCenter
        >
            {props.children}
        </Tooltip>
    );
};

const Warn = (props) => {
    const { color = '#ff4d4f', title, placement = 'top' } = props;
    return (
        <Tooltip
            color={color}
            title={title}
            placement={placement}
            arrowPointAtCenter
        >
            {props.children || <ExclamationCircleOutlined style={{ color }} />}
        </Tooltip>
    );
};

const Info = (props) => {
    const { color = '#40a9ff', title, placement = 'top' } = props;
    return (
        <Tooltip
            color={color}
            title={title}
            placement={placement}
            arrowPointAtCenter
        >
            {props.children}
        </Tooltip>
    );
};

export default { Help, Warn, Info };
