/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { isEqual } from 'lodash';

// COMPONENT
import { Checkbox } from 'antd';
import { Tree } from 'antd';

const { TreeNode } = Tree;

const loop = (data, authUser) => {
    const isEditPermission = authUser.check_resource_policy(
        'permissions',
        false,
        true,
        false
    );

    return data.map((item) => {
        const title = item.asset ? item.asset.asset_name : item.block_name || '-';
        if (item.children) {
            return (
                <TreeNode
                    selectable={false}
                    disabled={!isEditPermission}
                    key={item.block_id}
                    title={title}
                >
                    {loop(item.children, authUser)}
                </TreeNode>
            );
        }

        return (
            <TreeNode
                selectable={false}
                disabled={!isEditPermission}
                key={item.block_id}
                title={title}
            />
        );
    });
};

export default class BlockSelection extends Component {
    arrayBlocks = [];

    state = {
        defaultCheckedKeys: [],
        checkedKeys: [],
        checkAll: false,
    };

    flattenArray = (data) => {
        const dataLength = data.length;

        for (let i = 0; i < dataLength; i++) {
            this.arrayBlocks.push(data[i]);

            if (data[i].children) {
                this.flattenArray(data[i].children);
            }
        }
    };

    componentDidMount() {
        const { checkedKeys, blocks } = this.props;
        let checkedKeysString = [];

        this.flattenArray(blocks);
        const { arrayBlockIds } = this;

        if (checkedKeys) {
            checkedKeysString = checkedKeys.map((v) => `${v}`);
        }

        this.setState({
            checkedKeys: checkedKeysString,
            checkAll: isEqual(new Set(arrayBlockIds), new Set(checkedKeysString)),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.checkedKeys !== this.state.checkedKeys) {
            this.setState({
                checkAll: isEqual(
                    new Set(this.arrayBlockIds),
                    new Set(this.state.checkedKeys)
                ),
            });
        }
    }

    onCheck = (checkedKeys) => {
        this.setState({ checkedKeys: checkedKeys.checked });
    };

    checkAll = () => {
        this.setState({ checkedKeys: this.arrayBlockIds, checkAll: true });
    };

    uncheckAll = () => {
        this.setState({ checkedKeys: [], checkAll: false });
    };

    get getSelectedBlocks() {
        return this.state.checkedKeys;
    }

    get arrayBlockIds() {
        return this.arrayBlocks.map((v) => `${v.block_id}`);
    }

    get checkUncheckAll() {
        return (
            <>
                <div style={{ marginLeft: 24 }}>
                    <Checkbox
                        checked={this.state.checkAll}
                        onChange={() =>
                            this.state.checkAll ? this.uncheckAll() : this.checkAll()
                        }
                    >
            Check/Uncheck All
                    </Checkbox>
                </div>
                <hr />
            </>
        );
    }

    render() {
        const { blocks, authUser } = this.props;
        const { checkedKeys } = this.state;

        if (!authUser) return null;

        return (
            <div className="w-100">
                {this.checkUncheckAll}
                <Tree
                    checkable
                    defaultExpandAll
                    checkedKeys={checkedKeys}
                    onCheck={this.onCheck}
                    showIcon={false}
                    checkStrictly
                >
                    {loop(blocks, authUser)}
                </Tree>
            </div>
        );
    }
}
