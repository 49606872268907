import "./style.scss"
import React from "react"
import Grid from "../../../../../components/Grid/Grid"
import IconAdd from "../../../../../components/Icons/IconAdd"
import Link from "../../../../../components/Link/Link"

const ObjectivesEmptyPlaceholder = (props: Props) => {
    return <Grid container className="objectives-empty-placeholder" direction="column" columnSpacing="16px">
        <Grid item>
            <IconAdd className="empty-placeholder-icon" />
        </Grid>
        <Grid item>No goals made</Grid>
        <Grid item>
            <Link onPress={() => props.onAdd()}>Create Goal</Link>
        </Grid>
    </Grid>
}

interface Props {
    onAdd: () => void
}

export default ObjectivesEmptyPlaceholder
