/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { connect } from 'react-redux';

// CONSTANT
import CONSTANTS from '../../Constants';

// COMPONENT
import Select from '../../components/Select';

// MOBX STORE
import { errorFlash, flash } from '../../components/Flash';

// API
import { fetchOrganizationRequest, setActiveEntity } from '../../../store/old/Entity/Entity.action';
import { FetchUser, LogOut } from '../../../store/old/Authentication/Authentication.action';
import { api_createEntity } from '../../../store/old/Entity/Entity.services';
import { session } from '../../auxStore';
import withRouter from '../../Wrappers/HOCs/withRouter';
import translate from '../../utils/translate';
import AukButton from '../../components/AukButton';


export class JoinCompanySimple extends React.Component {
    state = {
        referralCode: '',
        email: '',
        company: '',
        industry: {
            name: '',
        },
        timezone: {
            offset: '',
        },
        description: '',
        isOpenSetup: false,
        isOpenRequest: false,
        referralCodeBColor: false,
    };

    setupEntity = () => {
        api_createEntity(this.props.user.user_id, {
            entity_name: this.state.company,
            industry: this.state.industry.name,
            entity_description: this.state.description,
            timezone: this.state.timezone.offset,
            weightage: 100,
        })
            .then((res) => {
                this.props.fetchUser((user) => {
                    session.replaceToken(res.token);
                    session.replaceEntityId(res.entity_id);
                    this.props.setActiveEntity(res.entity_id);
                    this.props.fetchOrg(res.entity_id);
                    this.props.router.navigate(CONSTANTS.URLS.SETUP_GATEWAY);
                });
            })
            .catch((e) => {
                errorFlash(e);
            });
    };

    handleSubmit = () => {
        if (this.showSubmit) {
            this.setupEntity()
        }
    };

    handleChangeRefCode = (e) => {
        this.setState({ referralCode: e.target.value });
    };

    handleChangeCompEmail = (e) => {
        this.setState({ email: e.target.value });
    };

    handleChangeCompany = (e) => {
        this.setState({ company: e.target.value });
    };

    handleChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    };

    handleChangeSelect = (field, e) => {
        this.setState({ [field]: e });
    };

    handleClickBySetup = () => {
        const { isOpenSetup, company, description, industry } = this.state;

        if (
            company !== '' ||
      description !== '' ||
      industry !== '' ||
      isOpenSetup
        ) {
            this.setState({
                isOpenSetup: !isOpenSetup,
                referralCodeBColor: false,
                company: '',
                description: '',
                industry: {
                    name: '',
                },
                isOpenRequest: false,
                email: '',
            });
        } else {
            this.setState({
                isOpenSetup: !isOpenSetup,
                referralCodeBColor: true,
                isOpenRequest: false,
                email: '',
            });
        }
    };

    navigate = () => {
        this.props.router.navigate(CONSTANTS.URLS.DASHBOARD);
    };

    get showSubmit() {
        const { isOpenRequest, isOpenSetup } = this.state;

        return !isOpenRequest && isOpenSetup
    }

    render() {
        const {
            isOpenSetup,
            isOpenRequest,
            email,
            company,
            industry,
            description,
        } = this.state;

        const industries = CONSTANTS.ENTITY.INDUSTRIES;

        const requestRow = (
            <div>
                <div className="row pad-btm-10">
                    <div className="col-3 d-flex justify-content-end align-items-center">
                        <label className="check text-align-right">Owner's email</label>
                    </div>
                    <div className="col-9">
                        <input
                            type="email"
                            className="form-control"
                            placeholder={translate("email")}
                            value={email}
                            onChange={this.handleChangeCompEmail}
                        />
                    </div>
                </div>
            </div>
        );

        const setupFields = [
            {
                label: translate("company"),
                content: (
                    <input
                        type="text"
                        className="form-control"
                        value={company}
                        onChange={this.handleChangeCompany}
                    />
                ),
            },
            {
                label: translate('timezone'),
                content: (
                    <Select
                        value={this.state.timezone}
                        options={CONSTANTS.TIMEZONES}
                        getOptionLabel={(opt) => opt.label}
                        onChange={(e) => this.handleChangeSelect('timezone', e)}
                        isRequired
                    />
                ),
            },
            {
                label: translate('industry'),
                content: (
                    <Select
                        value={industry}
                        options={industries}
                        getOptionLabel={(opt) => opt.name}
                        onChange={(e) => this.handleChangeSelect('industry', e)}
                        isClearable
                    />
                ),
            },
            {
                label: <span>{translate("description")}{" "}({translate('optional')})</span>,
                content: (
                    <input
                        type="text"
                        className="form-control"
                        value={description}
                        onChange={this.handleChangeDescription}
                    />
                ),
            },
        ];

        const Table = (
            <div>
                <div className="row pad-btm-10 justify-content-end">
                    <div className="col">
                        Registration successful! Please contact your system administrator for full access.
                    </div>
                </div>

                {/* prompt with setup entity */}
                <div className="forgot pad-btm-10 d-flex justify-content-between align-items-center">
                    <span className="my-2" style={{ color: 'red', fontSize: 11 }}>
                  Administrator only:{' '}
                        <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={this.handleClickBySetup}
                        >
                    click here
                        </span>{' '}
                  to setup new company
                    </span>
                </div>
                {isOpenSetup
                    ? setupFields.map((obj, i) => (
                        // eslint-disable-next-line react/jsx-indent
                        <div key={i} className="row pad-btm-10">
                            <div className="col-3 d-flex justify-content-end align-items-center">
                                <label className="check text-align-right">{obj.label}</label>
                            </div>
                            <div className="col-9">{obj.content}</div>
                        </div>
                    ))
                    : isOpenRequest && requestRow}
            </div>
        );

        return (
            <div className="join-company">
                <div className="container-fluid">
                    <div className="row justify-content-center vertical-offset-100">
                        <div className="col col-md-6 col-md-offset-5 align-self-end">
                            <div className="panel">
                                <div className="panel-header">
                                    <p className="panel-title">
                                        Welcome!
                                    </p>
                                </div>
                                <div className="panel-body">{Table}</div>
                                <div className="panel-footer p-3">
                                    <AukButton.Outlined 
                                        onClick={() => {this.props.logout(() => {
                                            this.props.router.navigate('/login');
                                            window.location.reload();
                                        })}}
                                    >
                                        {translate('logout')}
                                    </AukButton.Outlined>
                                    {
                                        this.showSubmit ? (
                                            <AukButton.Blue onClick={this.handleSubmit}>
                                                {translate('submit')}
                                            </AukButton.Blue>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrg: (entity_id) =>
            dispatch(fetchOrganizationRequest(entity_id)),
        fetchUser: (callback) => dispatch(FetchUser(callback)),
        setActiveEntity: (entity_id) =>
            dispatch(setActiveEntity(entity_id)),
        logout: (callback) => {
            dispatch(LogOut(callback))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(JoinCompanySimple));
