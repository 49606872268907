import { store } from '../../store';

export const tileToWidget = (t) => {
    let widget_id;
    let widget_type_id;
    if (t.tile_id) {
        widget_id = `t_${t.tile_id}`;
        widget_type_id = 1;
    } else {
        widget_id = `st_${t.summary_tile_id}`;
        widget_type_id = 2;
    }

    return new Widget(
        ...parseWidgetArguments({
            widget_id,
            widget_type_id,
            position_x: t.position_x,
            position_y: t.position_y,
            width: 1,
            height: 1,
            label: '',
            image_id: null,
            data_source_id: null,
            canvas_id: 0,
            report_id: null,
        })
    );
};

export const parseWidgetArguments = (w) => {
    return [
        w.widget_id,
        w.position_x,
        w.position_y,
        w.width,
        w.height,
        w.widget_type_id,
        w.label,
        w.image_id,
        w.data_source_id,
        w.canvas_id,
        w.report_id,
    ];
};

export class Widget {
    constructor(
        widget_id,
        position_x,
        position_y,
        width = 1,
        height = 1,
        widget_type_id,
        label,
        image_id,
        data_source_id,
        canvas_id,
        report_id
    ) {
        this.widget_id = widget_id;
        this.position_x = position_x;
        this.position_y = position_y;
        this.width = width;
        this.height = height;
        this.widget_type_id = widget_type_id;
        this.label = label;
        this.image_id = image_id;
        this.data_source_id = data_source_id;
        this.canvas_id = canvas_id;
        this.report_id = report_id;
    }
    // temp: tile adaptors
    get tile() {
        const [widget_type, id] = this.widget_id.toString().split('_');
        const { tiles } = store.getState();

        return this.widget_type_id === 1
            ? tiles.tiles[id]
            : tiles.summary_tiles[id];
    }

    get data() {
        if (this.widget_type_id === 1) return this.tile?.asset;
        return this.tile?.data;
    }
}
