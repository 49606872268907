/* eslint-disable react/prop-types */
import React from 'react';

import { Collapse } from 'reactstrap';

import './Collapsible.scss';

export default class Collapsible extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: props.isOpen };
    }

    handleClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    get label() {
        const { alwaysShowLabel, label } = this.props;

        const labelComponent = <span className="collapsible--label">{label}</span>;

        if (alwaysShowLabel) return labelComponent;
        return !this.state.isOpen ? labelComponent : null;
    }

    get header() {
        return (
            <div
                className={`collapsible--header ${
                    this.state.isOpen ? '' : 'collapsed'
                }`}
            >
                <span
                    className="collapsible--caret"
                    onClick={this.handleClick.bind(this)}
                >
                    {!this.state.isOpen ? (
                        <span key="caret-down">
                            <i className="fas fa-angle-down" style={{ fontSize: '1.25em' }} />
                        </span>
                    ) : (
                        <span key="caret-up">
                            <i className="fas fa-angle-up" style={{ fontSize: '1.25em' }} />
                        </span>
                    )}
                </span>
                {this.label}
            </div>
        );
    }

    get collapse() {
        return (
            <Collapse isOpen={this.state.isOpen} key={this.state.isOpen}>
                {this.props.children}
            </Collapse>
        );
    }

    render() {
        return (
            <div
                className={`collapsible ${this.props.className}`}
                style={this.props.style}
            >
                {this.header}
                {this.collapse}
            </div>
        );
    }
}

Collapsible.defaultProps = { isOpen: false };
