export const SkuConstants = {
    ACTIONS: {
        RESET_SKUS_STATE: 'RESET_SKUS_STATE',
        SET_SKUS_STATE: 'SET_SKUS_STATE',
        ADD_SKUS_RESOURCE: 'ADD_SKUS_RESOURCE',
        REMOVE_SKUS_RESOURCE: 'REMOVE_SKUS_RESOURCE',
        GET_SKUS_REQUEST: 'GET_SKUS_REQUEST',
        CREATE_SKU_REQUEST: 'CREATE_SKU_REQUEST',
        DELETE_SKU_REQUEST: 'DELETE_SKU_REQUEST',
        UPDATE_SKU_REQUEST: 'UPDATE_SKU_REQUEST',
        BULK_CREATE_SKU_REQUEST: 'BULK_CREATE_SKU_REQUEST',
    },
};
