/* eslint-disable react/prop-types */
import React from 'react';
import { regexMatch } from '../utils/helpers';
import { flash } from './Flash';

const isValidImage = (str) =>
    regexMatch(str, 'data:image/png;base64') ||
  regexMatch(str, 'data:image/jpeg;base64');

export default class ImageInput extends React.Component {
    handleChange(e) {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) reader.readAsDataURL(file);

        reader.onloadend = () => {
            const { result } = reader;
            if (!isValidImage(result)) {
                return flash({
                    message: 'Invalid file type',
                    details: 'Please upload an image (png/jpeg only)',
                    status: 'error',
                });
            }
            this.props.onChange(result);
        };
    }

    click() {
        this.imageLoad.click();
    }

    render() {
        const { style, icon, disabled } = this.props;

        return (
            <div onClick={() => this.click()} style={style}>
                {icon && !disabled && (
                    <i
                        className={icon}
                        disabled={disabled}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.handleChange.bind(this)}
                    ref={(imageLoad) => (this.imageLoad = imageLoad)}
                    disabled={disabled}
                />
            </div>
        );
    }
}

ImageInput.defaultProps = {
    disabled: false,
};
