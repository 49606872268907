import { SharedConstants as K } from './Shared.constants';
import { store } from '../..';

export const fetchCoreModels = (entityId, callback) => {
    return {
        type: K.ACTIONS.FETCH_CORE_MODELS,
        payload: { entityId },
        callback,
    };
};

export const appInitFlow = (entityId, initControlPanel = true, callback) => {
    return {
        type: K.ACTIONS.APP_INIT_FLOW,
        payload: { entityId, initControlPanel },
        callback,
    };
};

const _makeRequest = ({
    path = '',
    method = 'GET',
    params = JSON.stringify({}),
    contentType = 'application/json',
    monitor = false,
    success,
    error,
}) => {
    return {
        type: K.ACTIONS.HTTP_REQUEST,
        payload: { path, method, params, contentType, monitor, success, error },
    };
};

export const makeRequest = (obj) => store.dispatch(_makeRequest(obj));
