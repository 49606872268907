import "./style.scss"
import React, { useEffect } from "react"
import Page from "../../components/Page/Page"
import { LoadingBackdrop } from "../../components/Template/LoadingTemplate/LoadingTemplate"
import { entityReportsGetAction } from "../../store/actions/reports.action"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import ImpactResponsiveScreen from "./components/ImpactResponsiveScreen"
import useCurrentEntity from "../../hooks/useCurrentEntity"

const ImpactPage = () => {
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector(appState => appState.reports)
    const { entityId } = useCurrentEntity()

    useEffect(() => {
        void dispatch(entityReportsGetAction({ entityId }))
    }, [])

    return <Page className="impact-page" id="impactPage">
        <LoadingBackdrop open={loading}/>
        <ImpactResponsiveScreen />
    </Page>
}

export default ImpactPage
