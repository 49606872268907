import { SkuConstants as K } from './Sku.constant';

// MODEL
import { Sku } from '../../../legacy/models';

export const fetchSkus = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_SKUS_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const resetSkusState = () => {
    return {
        type: K.ACTIONS.RESET_SKUS_STATE,
    };
};

export const setSkusState = (skus, initialized = true) => {
    return {
        type: K.ACTIONS.SET_SKUS_STATE,
        payload: { skus, initialized },
    };
};

export const addSkusResource = (skus) => {
    return {
        type: K.ACTIONS.ADD_SKUS_RESOURCE,
        payload: { skus },
    };
};

export const removeSkusResource = (sku_ids) => {
    return {
        type: K.ACTIONS.REMOVE_SKUS_RESOURCE,
        payload: { sku_ids },
    };
};

export const createSku = (sku, callback) => {
    return {
        type: K.ACTIONS.CREATE_SKU_REQUEST,
        payload: { sku },
        callback,
    };
};

export const updateSku = (sku, callback) => {
    return {
        type: K.ACTIONS.UPDATE_SKU_REQUEST,
        payload: { sku },
        callback,
    };
};

export const deleteSku = (sku, callback) => {
    return {
        type: K.ACTIONS.DELETE_SKU_REQUEST,
        payload: { sku },
        callback,
    };
};

export const bulkCreateSku = (skus, callback) => {
    return {
        type: K.ACTIONS.BULK_CREATE_SKU_REQUEST,
        payload: { skus },
        callback,
    };
};
