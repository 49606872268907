import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { store } from '../..';

export const api_getIssues = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/issue`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateIssues = (payload) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/multi_issue`,
                method: 'PUT',
                params: JSON.stringify(payload),
                success: resolve,
                error: reject,
            });
        }
    );
};
