/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { SortableComponent } from '../../../components/SortableComponent';

const getItemLabel = (item, index) => {
    const original_title = item.title.replace('<PRIMARY> ', '');
    item.title = index ? original_title : `<PRIMARY> ${original_title}`;
    return item;
};

const SortableCharts = forwardRef((props, ref) => {
    const [charts, setCharts] = useState(
        props.charts.map((c, i) => ({
            chart_id: c.chart_id,
            chart_order: c.chart_order,
            title: i ? c.title : `<PRIMARY> ${c.title}`,
        }))
    );

    useImperativeHandle(ref, () => ({
        getData() {
            return charts.map((c) => {
                const clone = { ...c };
                delete clone.title;
                delete clone.asset_id;
                return clone;
            });
        },
    }));

    return (
        <SortableComponent
            items={charts}
            valueAccessor={(item) => item.title}
            onSortEnd={({ result }) => {
                setCharts(
                    result.map((c, index) => {
                        return getItemLabel({ ...c, chart_order: index + 1 }, index);
                    })
                );
            }}
        />
    );
});

export default SortableCharts;
