import { createSelector } from 'reselect';
import { values } from 'lodash';

export const entityState = (appState) =>
    appState.entity;

export const entitiesSelector = createSelector(entityState, (v) => v.entities);

export const organisationSelector = createSelector(entityState, (v) => {
    const { entities } = v;

    return values(entities).find((e) => !e.parent_entity_id);
});

export const currentEntitySelector = (appState) => {
    const { active, entities } = entityState(appState);
    return entities[active];
};
