/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import BlocksTreeSelect from '../../../components/BlocksTreeSelect';
import './BlockTitle.scss';
import { blocksState } from '../../../../store/old/Blocks/Blocks.selector';
import { defaultFilterOption } from '../../../utils/helpers';
import CONSTANTS from '../../../Constants';

export const BlockTitle = (props) => {
    const navigate = useNavigate();
    const { id, getTreeData, style } = props;

    const { blocks } = useSelector(blocksState);
    const block = blocks[id];

    const img =
    block && block.asset ? (
        <img alt={block.block_name} src={block.asset.image} />
    ) : (
        <Placeholder label={block.operation}/>
    );

    const handleChange = (block_id) => {
        const clicked = blocks[block_id];

        const { BLOCK, ASSET, PARETO } = CONSTANTS.URLS;
        const { pathname, search } = window.location;

        const OPERATOR = 'operator';

        const paretoRegex = new RegExp(PARETO);
        const operatorRegex = new RegExp(OPERATOR);

        if (pathname.match(operatorRegex)) {
            return navigate(`${ASSET}/${block_id}/${OPERATOR}${search}`);
        }

        if (pathname.match(paretoRegex)) {
            return navigate(`${PARETO}/${block_id}${search}`);
        }

        if (!clicked.asset) {
            return navigate(`${BLOCK}/${block_id}${search}`);
        }

        return navigate(`${ASSET}/${block_id}${search}`);
    };

    return (
        <div className="block-title" style={style}>
            <div className="block-title__image">{img}</div>
            <BlocksTreeSelect
                value={id}
                onChange={handleChange}
                treeDefaultExpandAll
                showSearch
                filterTreeNode={defaultFilterOption}
                getTreeData={getTreeData}
                placeholder="Select a process/asset"
            />
            {props.children}
        </div>
    );
};

export default BlockTitle;

const Placeholder = ({label}) => {
    return <div className='block-title__image-placeholder'>{label}</div>
}
