// https://github.com/react-dnd/react-dnd/issues/592#issuecomment-825458440

import React from 'react';
import { isEqual } from 'lodash';
import { useCallback, useRef } from 'react';
import { useDragLayer } from 'react-dnd';

export const useEfficientDragLayer = (collect) => {
    const requestID = useRef();

    const collectCallback = useCallback(
        (monitor) =>
            requestID.current === undefined ? { data: collect(monitor) } : undefined,
        [collect]
    );

    const collected = useDragLayer(collectCallback);

    const result = useRef(collected ? collected.data : null);

    if (collected && !isEqual(result.current, collected.data)) {
        result.current = collected.data;
        requestID.current = requestAnimationFrame(
            () => (requestID.current = undefined)
        );
    }

    return result.current;
};
