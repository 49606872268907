import { updateStoreRedux } from '../../../legacy/utils/redux';
import { TrackedIssuesConstants as K } from './TrackedIssues.constants';

const initialReducer = {
    trackedIssues: {},
    error: {},
};

export const trackedIssuesReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.GET_TRACKED_ISSUES_REQUEST_SUCCESS) {
        return updateStoreRedux(state, {
            trackedIssues: action.payload.trackedIssues,
        });
    }

    if (
        action.type === K.ACTIONS.CREATE_TRACKED_ISSUE_REQUEST_SUCCESS ||
    action.type === K.ACTIONS.UPDATE_TRACKED_ISSUE_REQUEST_SUCCESS
    ) {
        const { trackedIssue } = action.payload;
        return updateStoreRedux(state, {
            trackedIssues: {
                ...state.trackedIssues,
                [trackedIssue.tracked_issue_id]: trackedIssue,
            },
        });
    }

    if (action.type === K.ACTIONS.DELETE_TRACKED_ISSUE_REQUEST_SUCCESS) {
        const { tracked_issue_id } = action.payload;
        const clonedState = { ...state.trackedIssues };
        delete clonedState[tracked_issue_id];
        return updateStoreRedux(state, {
            trackedIssues: clonedState,
        });
    }

    if (action.type === K.ACTIONS.MAKE_TRACKED_ISSUES_REQUEST_FAILURE) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    return updateStoreRedux(state, {});
};
