export const ru = {
    notes: 'заметки',
    load: 'доставать',
    export: 'экспорт',
    unutilised: 'Un-используется',
    breakdowns: 'Поломки',
    threshold: 'порог',
    oeepreview: 'БРЭО предварительный просмотр',
    heatmappreview: 'Heatmap Просмотр',
    oeecolors: 'OEE цвета',
    color: 'цвет',
    reset: 'Сброс',
    theme: 'тема',
    defaultview: 'Вид по умолчанию',
    defaulttimeselection: 'По умолчанию выбор времени',
    zoomlevel: 'Увеличение уровня',
    entity: 'сущность',
    preferences: 'предпочтения',
    custom: 'изготовленный на заказ',
    enteryourmailtoreset: 'Введите свою почту, чтобы сбросить',
    goback: 'Вернитесь назад',
    resetpassword: 'Сброс пароля',
    logout: 'Выйти',
    ownersemail: 'Владельцы Email',
    optional: 'По желанию',
    industry: 'Промышленность',
    timezone: 'Часовой пояс',
    nocoderequesttojoin: 'Нет Коды квитирования Регистрации',
    enterreferralcodeinstead: 'Введите код рефералов вместо',
    setup: 'Настроить',
    company: 'Компания',
    referralcode: 'Промо-код',
    submit: 'Отправить',
    summary: 'Резюме',
    language: 'язык',
    login: 'Авторизоваться',
    signin: 'войти в систему',
    signup: 'зарегистрироваться',
    username: 'имя пользователя',
    password: 'пароль',
    forgotpassword: 'Забыли пароль?',
    rememberme: 'Запомни меня',
    register: 'регистр',
    registration: 'Постановка на учет',
    firstname: 'Имя',
    lastname: 'Фамилия',
    email: 'Эл. адрес',
    mobile: 'мобильный',
    country: 'Страна',
    contactnumber: 'Контактный телефон',
    appointment: 'Деловое свидание, встреча',
    role: 'Роль',
    admin: 'админ',
    owner: 'владелец',
    editor: 'редактор',
    viewer: 'зритель',
    confirmpassword: 'Подтвердите Пароль',
    iacceptthe: 'я принимаю',
    useragreement: 'Пользовательское Соглашение',
    alreadyregisteredlogin: 'Уже зарегистрирован? Авторизоваться',
    softwareasaservicetermsofuse:
    'Программное обеспечение как услуга Условия использования',
    dashboard: 'Панель приборов',
    streaming: 'Потоковый',
    resolution: 'разрешение',
    seconds: 'секунд',
    second: 'второй',
    minutes: 'минут',
    minute: 'минут',
    hours: 'часов',
    hour: 'Час',
    weeks: 'недель',
    week: 'Неделя',
    months: 'месяцы',
    month: 'месяц',
    quarters: 'помещение',
    quarter: 'четверть',
    years: 'лет',
    year: 'год',
    from: 'Из',
    to: 'к',
    start: 'Начало',
    end: 'Конец',
    display: 'дисплей',
    oee1: 'БРЭО-1',
    oee2: 'OEE2',
    timeseries: 'Временные ряды',
    gateway: 'шлюз',
    statusasof: 'Статус по состоянию на',
    active: 'активный',
    offline: 'не в сети',
    mergeasonemachine: 'Объединить в одной машине',
    drawarrowstoformprocessmap: 'Рисовать стрелки на карте формы процесса',
    openblockeditor: 'редактор Open блок',
    createnewtile: 'Создать новую плитку',
    summarytile: 'Основная плитка',
    selectablockthennameyoursummarytile:
    'Выберите блок, затем название вашей сводную плитки',
    name: 'название',
    blockselector: 'Блок выбора',
    asset: 'Актив',
    therearenolabelstaggedinthisperiod: 'Там нет этикеток с тегом в этот период',
    standardtimes: 'Стандартное Время (с)',
    total: 'Общее количество',
    last: 'Последний',
    oeeinput: 'вход OEE',
    chartcolor: 'Диаграмма цвета',
    issueeditor: 'Редактор Issue',
    issuemangement: 'Выпуск Mangement',
    cumulativeoutput: 'Накопительное выход',
    tag: 'Тег',
    label: 'метка',
    valueval: 'Значение (Val)',
    time: 'Время',
    januaryjan: 'Январь (январь)',
    februaryfeb: 'Февраль (февраль)',
    marchmar: 'Март (март)',
    aprilapr: 'Апрель (апрель)',
    may: 'май',
    junejun: 'Июнь (июнь)',
    julyjul: 'Июль (июль)',
    augustaug: 'Август (август)',
    septembersep: 'Сентябрь (сентябрь)',
    octoberoct: 'Октябрь (октябрь)',
    novembernov: 'Ноябрь (ноябрь)',
    decemberdec: 'Декабрь (декабрь)',
    day: 'День',
    days: 'дней',
    mondaymonm: 'Понедельник (пн) M',
    tuesdaytuet: 'Вторник (Вт) T',
    wednesdaywedw: 'Среда (ср) W',
    thursdaythut: 'Четверг (Ср) Т',
    fridayfrif: 'Пятница (пт) F',
    saturdaysats: 'Суббота (сб) S',
    sundaysuns: 'Воскресенье (ВС) S',
    edit: 'редактировать',
    add: 'Добавить',
    new: 'новый',
    create: 'Создайте',
    search: 'Поиск',
    close: 'близко',
    cancel: 'Отмена',
    save: 'Сохранить',
    saved: 'Сохраненный',
    clear: 'Очистить',
    enter: 'Войти',
    navigator: 'навигатор',
    mandatoryfields: 'Обязательные поля',
    select: 'Выбрать',
    merge: 'Объединить',
    delete: 'Удалить',
    done: 'Готово',
    filter: 'Фильтр',
    table: 'Стол',
    columns: 'столбцы',
    rows: 'строки',
    cell: 'клетка',
    page: 'страница',
    previous: 'предыдущий',
    next: 'следующий',
    serialnumber: 'Серийный номер',
    manuallyinputserialnumber: 'ввода вручную Серийный номер',
    updateasset: 'Обновление активов',
    machine: 'Машина',
    brand: 'марка',
    model: 'Модель',
    nodes: 'Вершины',
    fusions: 'Гибриды',
    chartorder: 'Диаграмма заказа',
    primary: 'первичный',
    adddevicechannel: 'Добавить устройство - канал',
    pushratesec: 'Нажмите Rate (сек)',
    voltagev: 'Напряжение (V)',
    sensor: 'датчик',
    mode: 'Режим',
    digitalcount: 'цифровой граф',
    digitalstate: 'цифровой государственный',
    analogvoltage: 'Аналоговое напряжение',
    charttitle: 'Название диаграммы',
    unitmeasure: 'Единица измерения',
    attribute: 'Атрибут',
    autocycletime: 'Время Автоматический цикл',
    unittransform: 'блок преобразования',
    leaveblankifnotapplicable: 'Оставьте поле пустым, если не применимо',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Обязательные поля (оставьте пустым, если канал не используется)',
    fusedchart: 'плавленый Chart',
    fusionname: 'Fusion Имя',
    charttype: 'Тип диаграммы',
    column: 'колонка',
    line: 'Линия',
    state: 'государство',
    expression: 'выражение',
    combination: 'комбинирование',
    currentcharts: 'Текущие Графики',
    expressionconstructor: 'Выражение Constructor',
    validoperators: 'Допустимые операторы',
    leaveblanktokeepexistingexpression:
    'Оставьте пустым, чтобы сохранить существующее выражение',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Общая эффективность оборудования',
    legend: 'легенда',
    loading: 'загрузка',
    availability: 'Доступность',
    performance: 'Производительность',
    quality: 'Качественный',
    unscheduled: 'незапланированный',
    planneddowntime: 'Запланированные простои',
    changeoversetup: 'Переход / Настройка',
    breakdown: 'Сломать',
    unutilized: 'Un-используется',
    speedloss: 'Потеря скорости',
    minorstops: 'Незначительные Остановки',
    rework: 'Rework',
    rejectscrap: 'Отклонить / Scrap',
    nodata: 'Нет данных',
    blockeditor: 'Редактор блоков',
    sort: 'Сортировать',
    logicblocks: 'Логические блоки',
    sequence: 'Последовательность',
    and: 'И',
    or: 'Или',
    utilizationheatmap: 'Использование Heatmap',
    startdate: 'Дата начала',
    enddate: 'Дата окончания',
    daily: 'Ежедневно',
    weekly: 'еженедельно',
    monthly: 'ежемесячно',
    quarterly: 'Ежеквартальный',
    yearly: 'каждый год',
    trendanalysis: 'Анализ тенденций',
    metricselection: 'Выбор метрической',
    metric: 'метрический',
    data: 'Данные',
    target: 'цель',
    periodselection: 'Выбор периода',
    span: 'пядь',
    every: 'каждый',
    repeat: 'Повторение',
    repeats: 'Повторы',
    addsingle: 'Однодневное',
    paretoanalysis: 'Анализ Парето',
    calendartime: 'Календарное время',
    scheduledtime: 'Назначенное время',
    operatingtime: 'Время работы',
    netruntime: 'Чистое время работы',
    effective: 'эффективный',
    loadingloss: 'Загрузка Loss',
    availabilityloss: 'Наличие Loss',
    performanceloss: 'Потеря производительности',
    qualityloss: 'Потеря качества',
    notagsinthiscategory: 'Нет тегов в этой категории',
    overall: 'В общем и целом',
    tagged: 'Tagged',
    occurrence: 'Вхождение',
    skumanagement: 'Управление SKU',
    category: 'категория',
    skuname: 'SKU Имя',
    skucode: 'SKU Код',
    unitofmeasurementuom: 'Единица измерения (единица измерения)',
    description: 'Описание',
    csvupload: 'CSV Загрузить',
    template: 'шаблон',
    notifications: 'Уведомления',
    newnotification: 'Новые уведомления',
    monitoring: 'Мониторинг',
    topic: 'тема',
    frequency: 'частота',
    recipients: 'Получатели',
    notifyby: 'Уведомлять по',
    sms: 'смс',
    alert: 'бдительный',
    report: 'отчет',
    greaterthan: 'Лучше чем',
    lessthan: 'Меньше, чем',
    greaterthanorequal: 'Больше или равно',
    lessthanorequal: 'Меньше или равно',
    equal: 'равных',
    range: 'Ассортимент',
    continuousduration: 'Непрерывная продолжительность',
    usermanagement: 'Управление пользователями',
    user: 'пользователь',
    users: 'пользователей',
    invitenew: 'Пригласить новое',
    status: 'положение дел',
    resend: 'Отправить',
    revoke: 'Отозвать',
    invite: 'приглашение',
    invitees: 'Приглашенные',
    addinviteesbypressingenterorclickingonthesign:
    'Добавить приглашенные нажав Enter или нажав на знак «+»',
    schedules: 'Расписание',
    shift: 'сдвиг',
    profile: 'Профиль',
    changepassword: 'Сменить пароль',
    deleteaccount: 'Удалить аккаунт',
    userguide: 'Гид пользователя',
    overviewandbasiccontrol: 'Обзор и базовый контроль',
    rangeandresolution: 'Диапазон и разрешение',
    editingoptions: 'Редактирование параметров',
    mergetiles: 'Объединить плитки',
    detailedassetview: 'детальный вид активов',
    assetoverview: 'Обзор активов',
    summarystatistics: 'Сводные статистические данные',
    dataanalysis: 'Анализ данных',
    oeeanoverview: 'БРЭО обзор',
    oeetoggle: 'БРЭО тумблер',
    addingamachine: 'Добавление машины',
    nodeconfiguration: 'конфигурация узла',
    deleteupdateassets: 'Удаление активы обновления',
    oeesummarytile: 'БРЭО резюме плитка',
    oeetags: 'БРЭО Теги',
    issuemanagement: 'проблемное управление',
    labelsandfiltering: 'Этикетки и фильтрации',
    useraccess: 'Доступ пользователя',
    group: 'группа',
    groups: 'группы',
    joincompany: 'Регистрация компании',
    notification: 'уведомление',
    createnotification: 'Создать уведомление',
    node: 'Узел',
    downtime: 'время простоя',
    downtimes: 'Простои',
    repeatstartdate: 'Повторите Дата начала',
    repeatenddate: 'Повторите Дата окончания',
    optional: 'По желанию',
    createplan: 'Создание плана',
    today: 'Cегодня',
    starttime: 'Начальное время',
    endtime: 'Время окончания',
    chart: 'Диаграмма',
    reject: 'отклонять',
    accept: 'принимать',
    filterbydaterange: 'Фильтр по диапазону дат',
    filterbymostrecent: 'фильтр последний',
    result: 'результат',
    type: 'Тип',
    duration: 'продолжительность',
    by: 'по',
    title: 'заглавие',
    assigned: 'назначенный',
    action: 'действие',
    track: 'отслеживать',
    issue: 'вопрос',
    priority: 'приоритет',
    requestor: 'запрашивающий',
    opened: 'открытый',
    assignee: 'правопреемник',
    root: 'корень',
    cause: 'причина',
    identified: 'идентифицированный',
    defined: 'определенный',
    date: 'Дата',
    resolve: 'разрешить',
    resolved: 'решены',
    targetresolvedate: 'Отчетная дата Решимость',
    procurement: 'Закупка',
    choose: 'выберите',
    analyses: 'анализы',
    source: 'Источник',
    selection: 'выбор',
    historical: 'исторический',
    mostrecent: 'Самые последние',
    regression: 'регрессия',
    trendline: 'линия тренда',
    upperbound: 'верхняя граница',
    lowerbound: 'нижняя граница',
    preview: 'предварительный просмотр',
    period: 'период',
    series: 'серии',
    axis: 'ось',
    youhaveno: 'у тебя нет',
    code: 'Код',
    all: 'Все',
    upload: 'загрузка',
    regressionanalysis: 'Регрессионный анализ',
    issuetracker: 'отслеживания проблем',
    labelsearch: 'Этикетка Поиск',
    gatewaysetup: 'Установка шлюза',
    setupgateway: 'шлюз Настройка',
    radiomesh: 'радио сетки',
    targetsetting: 'целевая установка',
    updatetrackedissue: 'обновление отслеживаются вопрос',
    shownewtrackedissue: 'показать новый Гусеничный вопрос',
    low: 'низкий',
    mid: 'середина',
    high: 'высокая',
    linear: 'линейный',
    exponential: 'экспоненциальный',
    logarithmic: 'логарифмический',
    quadratic: 'квадратный',
    polynomial: 'многочлен',
    general: 'Общее',
    datataggingenrichment: 'Данные Tagging Обогащение',
    hardware: 'аппаратные средства',
    nodemanagement: 'Узел управления',
    createeditnotification: 'Создание уведомления о редактировании',
    conditions: 'условия',
    addsku: 'добавить SKU',
    editsku: 'редактировать SKU',
    setupagateway: 'Настройка шлюза',
    deviceserialnumber: 'серийный номер устройства',
    repeatsweekly: 'повторяется еженедельно',
    repeatsdaily: 'повторяется ежедневно',
    repeatsmonthly: 'повторяется ежемесячно',
    repeatsannually: 'повторяется ежегодно',
    repeatsevery: 'повторяет каждый',
    hourly: 'почасовой',
    biweekly: 'два раза в неделю',
};
