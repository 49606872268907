import "./style.scss"
import React, { ChangeEvent } from "react"
import NumberInput from "../../../../../../components/Input/NumberInput/NumberInput"
import { goalsUpdateGoalAction, TimeKey } from "../../../../../../store/actions/goals.action"
import { useAppDispatch } from "../../../../../../store/hooks"

const EndGoalInput = (props: Props) => {
    const dispatch = useAppDispatch()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        void dispatch(
            goalsUpdateGoalAction({
                blockId: props.blockId,
                timeKey: props.timeKey,
                value: +e.target.value,
            })
        )
    }

    return <NumberInput
        className="end-goal-input"
        align="end"
        min={0}
        max={100}
        decimalScale={2}
        endAdornment={<span className="end-adornment">%</span>}
        onChange={handleChange}
        value={props.value}
        disabled={props.disabled}
    />
}

interface Props {
    blockId: Domain.BlockId
    timeKey: TimeKey
    value: number
    disabled?: boolean
}

export default EndGoalInput
