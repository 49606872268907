import { BlockConstants as K } from './block.constants';

export const getBlockOee = (blockId, query, options, callback) => {
    return {
        type: K.ACTIONS.BLOCK_FETCH_OEE_DATA,
        payload: { blockId, query, options },
        callback
    }
}

export const getBlockOutput = (blockId, query, options, callback) => {
    return {
        type: K.ACTIONS.BLOCK_FETCH_OUTPUT_DATA,
        payload: { blockId, query, options },
        callback
    }
}

export const setMode = (mode) => {
    return {
        type: K.ACTIONS.BLOCK_SET_MODE,
        payload: { mode }
    };
};

export const setBlockOeeData = (oee) => {
    return {
        type: K.ACTIONS.BLOCK_SET_OEE_DATA,
        payload: { oee }
    }
}

export const setBlockOutputData = (output) => {
    return {
        type: K.ACTIONS.BLOCK_SET_OUTPUT_DATA,
        payload: { output }
    }
}

export const setBlockIssueLabels = (labels) => {
    return {
        type: K.ACTIONS.BLOCK_SET_ISSUE_LABELS,
        payload: { labels }
    }
}

export const addBlockIssueLabels = () => {}

export const setBlockSkuLabels = (labels) => {
    return {
        type: K.ACTIONS.BLOCK_SET_SKU_LABELS,
        payload: { labels }
    }
}

export const addBlockSkuLabels = () => {}

export const resetBlockStore = () => {
    return {
        type: K.ACTIONS.BLOCK_RESET_STORE
    }
}

export const createBlockLabels = (assets, data, callback) => {
    return {
        type: K.ACTIONS.BLOCK_CREATE_LABELS,
        payload: { assets, data },
        callback
    }
}
