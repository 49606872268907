import { TargetConstants as K } from './Target.constant';
import { Target } from '../../../legacy/models';

export const setTargetsResource = (targets) => {
    return {
        type: K.ACTIONS.SET_TARGETS_RESOURCE,
        payload: { targets },
    };
};

export const addTargetsResource = (targets) => {
    return {
        type: K.ACTIONS.ADD_TARGETS_RESOURCE,
        payload: { targets },
    };
};

export const removeTargetsResource = (target_ids) => {
    return {
        type: K.ACTIONS.REMOVE_TARGETS_RESOURCE,
        payload: { target_ids },
    };
};

export const fetchEntityTargets = (query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ENTITY_TARGETS_REQUEST,
        payload: query,
        callback,
    };
};

export const fetchAssetTargets = (asset_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_TARGETS_REQUEST,
        payload: { asset_id, query },
        callback,
    };
};

export const createTarget = (target, callback) => {
    return {
        type: K.ACTIONS.CREATE_TARGET_REQUEST,
        payload: { target },
        callback,
    };
};

export const bulkCreateTargets = (targets, callback) => {
    return {
        type: K.ACTIONS.BULK_CREATE_TARGETS_REQUEST,
        payload: { targets },
        callback,
    };
};

export const updateTarget = (target, callback) => {
    return {
        type: K.ACTIONS.UPDATE_TARGET_REQUEST,
        payload: { target },
        callback,
    };
};

export const deleteTarget = (target_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_TARGET_REQUEST,
        payload: { target_id },
        callback,
    };
};

export const createAssetShiftTarget = (entity_id, asset_id, shift_id, target, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET_SHIFT_TARGET_REQUEST,
        payload: { entity_id, asset_id, shift_id, target },
        callback,
    };
};

export const updateAssetShiftTarget = (entity_id, asset_id, shift_id, target_id, target, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_SHIFT_TARGET_REQUEST,
        payload: { entity_id, asset_id, shift_id, target_id, target },
        callback,
    };
};

export const deleteAssetShiftTarget = (entity_id, asset_id, shift_id, target_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_ASSET_SHIFT_TARGET_REQUEST,
        payload: { entity_id, asset_id, shift_id, target_id },
        callback,
    };
};
