import React, { useState } from "react"
import BlockTitle from "../../../legacy/routes/Assets/shared/BlockTitle"
import ViewTabs from "./ViewTabs"
import { useNavigate, useParams } from "react-router-dom"
import AukTooltip from "../../../legacy/components/AukTooltip"
import AukButton from "../../../legacy/components/AukButton"
import CONSTANTS from "../../../legacy/Constants"
import { RolePermission } from "../../../legacy/components/Permission"
import { Menu } from "antd"
import translate from "../../../legacy/utils/translate"
import BlockModel from "../../../api/models/block.model"
import { useAppSelector } from "../../../store/hooks"
import { flash } from "../../../legacy/components/Flash"
import { saveCsv } from "../../../legacy/utils/dataExports"
import { blocksCSV } from "../../../legacy/routes/Blocks/Blocks.csv_exporter"
import BlockCSVExportModal from "../../../legacy/routes/Blocks/BlockCSVExportModal"
import useLegacyBlock from "../../../hooks/useLegacyBlock"
import { isEmpty } from "lodash"


const EXPORT_KEY = "export"
const CLASSIC_EXPORT_KEY = "classic_export"

const BlockHeader = (props: Props) => {
    const navigate = useNavigate()
    const params = useParams()
    const { count : loading } = useAppSelector(appState => appState.ui.loader)
    const masks = useAppSelector(appState => appState.ui.oee.masks)
    const blockId = +(params.blockId as unknown as string)
    const block = useLegacyBlock(blockId)

    const [showExport, setShowExport] = useState<boolean>(false)

    const downloadClassic = () => {
        if (loading)
            return flash({ message: "Loading... Please wait.", status: "warning" })

        if (isEmpty(block.oee))
            return flash({ message: "No data", status: "warning" })

        saveCsv(blocksCSV(block, masks), `${block.label.split(" ").join("_")}.csv`)
    }

    const handleClickMenu = ({ key }: { key: string }) => {
        switch (key) {
            case EXPORT_KEY:
                setShowExport(true)
                return
            case CLASSIC_EXPORT_KEY:
                downloadClassic()
                return
            default:
                return
        }
    }


    return <BlockTitle id={props.block.blockId}>
        <>
            <BlockCSVExportModal
                visible={showExport}
                onCancel={() => { setShowExport(false) }}
                block={block}
            />
            <div className="d-flex">
                <div className="d-flex">
                    <AukTooltip.Help title={translate("paretoanalysis")}>
                        <AukButton.Outlined
                            className="auk-button--round mr-2"
                            style={{ transform: "rotate(270deg) scaleY(-1)" }}
                            onClick={() =>
                                navigate(`${CONSTANTS.URLS.PARETO}/${props.block.blockId}`)
                            }
                        >
                            <i className="fas fa-signal i-i"/>
                        </AukButton.Outlined>
                    </AukTooltip.Help>
                    <RolePermission accessLevel="editor">
                        <AukTooltip.Help title={translate("export")}>
                            <AukButton.Dropdown
                                className="auk-button--round mr-2"
                                icon={<i className="fas fa-download"/>}
                                placement="bottomLeft"
                                trigger={["click"]}
                                overlay={
                                    <Menu onClick={handleClickMenu}>
                                        <Menu.Item key={EXPORT_KEY}>Export</Menu.Item>
                                        <Menu.Item key={CLASSIC_EXPORT_KEY}>
                            Classic Export
                                        </Menu.Item>
                                    </Menu>
                                }
                            />
                        </AukTooltip.Help>
                    </RolePermission>
                </div>
                <ViewTabs/>
            </div>
        </>
    </BlockTitle>
}

export default BlockHeader

interface Props {
    block: BlockModel
}