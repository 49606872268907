import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import "./InputDialog.scss"
import React, { useState } from "react"
import ActionButton from "../Button/ActionButton/ActionButton"
import TextInput from "../Input/TextInput/TextInput"

const InputDialog = (props: Props) => {
    const [value, setValue] = useState<string>(props.defaultValue ?? "")
    return <Dialog onClose={props.handleClose} open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <TextInput maxLength={props.maxLength ?? 80} placeholder={props.placeholder} value={value} onChange={e => { setValue(e.target.value) }}/>
        </DialogContent>
        <DialogActions>
            <ActionButton onPress={() => {
                props.handleOk && props.handleOk(value)
            }}>Done</ActionButton>
        </DialogActions>
    </Dialog>
}

interface Props {
    open: boolean
    handleClose?: () => void
    title: string
    placeholder?: string
    defaultValue?: string
    handleOk: (value: string) => void
    maxLength?: number
}

export default InputDialog
