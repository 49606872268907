/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { isEqual } from 'lodash';

export function shouldUpdate(WrapperComponent) {
    return class extends Component {
        shouldComponentUpdate(nextProps) {
            return !isEqual(this.props, nextProps);
        }

        render() {
            return <WrapperComponent {...this.props} />;
        }
    };
}
