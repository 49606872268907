/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// COMPONENTS
import AukButton from '../../components/AukButton';
import BlocksNavigator from '../../components/Blocks/BlocksNavigator';
import CSVDownloadPrefixer from '../../components/PromptCSVDownloadPrefixer';
import DateTimeSelector from '../../components/DateTimeSelector';
import LabelWrapper from '../../components/LabelWrapper';
import OEEScale from './components/OEEScale';
import SelectWithDisabledOptions from '../../components/SelectWithDisabledOptions';
import { Heatmap } from '../../Charts/v1';
import { HeatmapTooltip } from '../../Charts/v1/tooltips/templates';
import { SPAWrapper } from '../../components/SPAWrapper';
import OEECategorySelect, {
    OEECategorySelectOptions,
} from './components/OEECategorySelect';

// CSV
import utilizationCSV from './Utilization.csv_exporter';
import { saveCsv } from '../../utils/dataExports';

// CONSTANTS
import CONSTANTS from '../../Constants';

// HELPERS
import { getDefaultResolution } from '../../utils/controls';
import { depthFirstTraversal, fileDatetimeFormat, uiDateFormat } from '../../utils/helpers';

// SELECTOR
import { utilizationResolutions } from '../../../store/old/UI/Utilization/Utilization.selector';

import './Utilization.scss';
import { isEmpty } from 'lodash';
import { flash } from '../../components/Flash';
import AukTooltip from '../../components/AukTooltip';
import { RolePermission } from '../../components/Permission';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { generateUrlQuery } from '../../utils/url';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';
import translate from '../../utils/translate';

const gridSize = 40;

const utilizationHeatmapDataParser = (data) => {
    return data
        .map((d) => {
            return d.data
                ? d.data.map((datum) => ({ ...datum, block_id: d.block_id }))
                : [];
        })
        .reduce((acc, curr) => {
            return acc.concat(...curr);
        }, []);
};

const xTickFormat = (d, res_period, force) => {
    const m = moment(d);
    let format;
    if (res_period === 'hours') {
        if (m.format('HH:mm:ss') === '00:00:00') {
            format = 'DD/MM';
        } else {
            format = 'HHmm';
        }
    } else if (res_period === 'days') {
        if (force || m.format('DD') === '01') {
            format = 'MMM';
        } else {
            format = 'DD';
        }
    } else if (res_period === 'weeks') {
        format = 'MMM DD';
    } else {
    // monthly
        format = 'MMM';
    }

    return m.format(format);
};

const xTickFontWeight = (d, force) => {
    const m = moment(d);
    if (force || m.format('DD') === '01') return 600;
    return 300;
};

const getXDomain = (data) => {
    if (!data.length) return [];
    const maxDomain = data
        .map((b) => b.data)
        .sort((a, b) => b.length - a.length)[0];
    if (!maxDomain) return [];

    return maxDomain.map((d) => moment(d.time).toDate());
};

const getYDomain = (data) => {
    return data.map(({ block_id }) => block_id).reverse();
};

class UtilizationPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oeeFilter: this.oeeFilterOptions.filter((opt) => opt.value === 'ef'),
            blocksFilter: this.orderedBlocksList.map((b) => b.block_id),
            prefixer: false,
        };

        this.togglePrefixer = this.togglePrefixer.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.blocks, this.props.blocks)) {
            this.setState({
                blocksFilter: this.orderedBlocksList.map((b) => b.block_id),
            });
        }
    }

    togglePrefixer() {
        this.setState({ prefixer: !this.state.prefixer });
    }

    handleChangeBlockNavigator(e) {
        this.setState({ blocksFilter: e.expanded });
    }

    handleChangeDate({ upper, lower }) {
        const {
            utilizationStore: {
                date_range: [from, to],
            },
            updateRange,
        } = this.props;

        if (upper) return updateRange([from, upper]);
        if (lower) return updateRange([lower, to]);
    }

    handleChangeFilter(e) {
        this.setState({ oeeFilter: e.concat() });
    }

    handleClickGridCell(d) {
        const {
            blocks: { blocks },
        } = this.props;
        const { block_id, time, int } = d;

        const startDate = moment(time);
        const endDate = moment(time).add(int, 'milliseconds');
        const durationSeconds = endDate.diff(startDate, 'seconds');
        const resolution = getDefaultResolution(durationSeconds);
        const block = blocks[block_id];
        const pathname = block.asset
            ? `${CONSTANTS.URLS.ASSET}/${block_id}`
            : `${CONSTANTS.URLS.BLOCK}/${block_id}`;

        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        this.props.router.navigate(`${pathname}${query}`)
    }

    get oeeFilterOptions() {
        const { oeeCategories } = this.props;
        return OEECategorySelectOptions(oeeCategories);
    }

    download(prefix) {
        if (isEmpty(this.props.data))
            return flash({ message: 'No data', status: 'warning' });

        const {
            csvData,
            resolution,
            state: { oeeFilter },
            props: {
                oee2,
                utilizationStore: { date_range },
            },
        } = this;

        const filter = oeeFilter.map(({ value }) => value);
        saveCsv(
            utilizationCSV({
                data: csvData,
                resolution,
                oee2,
                date_range,
                filter,
                oee2,
            }),
            `${prefix}_${this.range}.csv`
        );
    }

    get resOptions() {
        const {
            utilizationStore: { date_range },
        } = this.props;

        return utilizationResolutions(date_range);
    }

    get orderedBlocksList() {
        const {
            // rootBlock: root,
            props: {
                utilizationStore: { data },
                entityBlock,
            },
        } = this;

        return depthFirstTraversal(entityBlock);
    }

    get expanded() {
        return this.state.blocksFilter.reduce(
            (acc, curr) => ({ ...acc, [curr]: true }),
            {}
        );
    }

    get data() {
        const {
            orderedBlocksList,
            expanded,
            props: {
                blocks: { blocks },
                data,
            },
        } = this;

        return orderedBlocksList
            .filter((b) => expanded[b.block_id])
            .map(({ block_id }) => {
                return {
                    block_id,
                    data: data[block_id]
                        ? data[block_id].oee.map((d) => {
                            return {
                                int: d.int,
                                value: blocks[block_id].isRestricted
                                    ? '-'
                                    : Math.round(this.getDataValue(d)),
                                time: d.time,
                            };
                        })
                        : [],
                };
            });
    }

    get csvData() {
        const {
            props: {
                blocks: { blocks },
                data,
            },
        } = this;
        return this.data.map((d) => {
            return {
                ...d,
                label: blocks[d.block_id].label,
                overall: data[d.block_id]? data[d.block_id].overall : {},
                asset_id: blocks[d.block_id].asset_id,
            };
        });
    }

    get color() {
        const { oeeColors } = this.props;
        const domain = oeeColors.map((c) => c.color);
        const range = oeeColors.map((c) => c.th);
        return { domain, range };
    }

    get colorGradient() {
        const { oeeColors } = this.props;
        const gradient = oeeColors
            .sort((a, b) => +a.th - +b.th)
            .map((breakpoint) => `${breakpoint.color} ${breakpoint.th}%`)
            .join(',');

        return `linear-gradient(to right, ${gradient})`;
    }

    get resolution() {
        const {
            props: {
                utilizationStore: { resolution },
            },
        } = this;

        return resolution
            ? this.resOptions.find((r) => r.res_period === resolution.res_period)
            : null;
    }

    get range() {
        const {
            utilizationStore: { date_range },
        } = this.props;

        return date_range
            .map((d) => moment(d).format(fileDatetimeFormat))
            .join('_');
    }

    getDataValue(d) {
        const {
            state: { oeeFilter },
            props: { oee2 },
        } = this;

        const filter = oeeFilter
            .map(({ value }) => value)
            .reduce((acc, curr) => {
                return acc.add(curr);
            }, new Set());

        const valueTotalTime = oeeFilter
            .map(({ value }) => value)
            .reduce((acc, curr) => {
                return (acc += d.oee[curr]);
            }, 0);

        if (!filter.has('pd') && !filter.has('us')) {
            const { pd, us } = d.oee;
            const loadingLoss = pd + us;
            if (loadingLoss >= 100) return 0;
            const valueScheduledTime = (valueTotalTime / (100 - loadingLoss)) * 100;
            return oee2 ? valueScheduledTime : valueTotalTime;
        }

        return valueTotalTime;
    }

    getTooltip(d) {
        const block = this.props.blocks.blocks[d.block_id];
        const label = block.asset
            ? block.asset.asset_name
            : block.block_name || '-';
        const value = d.value;
        return HeatmapTooltip({ label, value });
    }

    render() {
        const format = uiDateFormat;
        const [lower, upper] = this.props.utilizationStore.date_range;
        const { res_period } = this.props.utilizationStore.resolution;
        const { data, color } = this;

        return (
            <SPAWrapper className="utilization--component">
                <div className="utilization--controls p-3 mb-2 align-items-center">
                    <div className="col d-flex p-0 pr-2">
                        <LabelWrapper col={2} label={translate('date')}>
                            <div className="d-flex">
                                <DateTimeSelector
                                    format={format}
                                    timePicker={false}
                                    showToday={true}
                                    defaultValue={lower}
                                    handleChange={(e) => this.handleChangeDate({ lower: e })}
                                />
                                <span className="d-flex mx-2 align-items-center">—</span>
                                <DateTimeSelector
                                    format={format}
                                    timePicker={false}
                                    showToday={true}
                                    defaultValue={upper}
                                    handleChange={(e) => this.handleChangeDate({ upper: e })}
                                />
                            </div>
                        </LabelWrapper>
                    </div>
                    <div className="col d-flex p-0 pr-2">
                        <LabelWrapper
                            className="d-flex"
                            col={2}
                            label={translate('resolution')}
                        >
                            <SelectWithDisabledOptions
                                options={this.resOptions}
                                value={this.resolution}
                                onChange={(e) => this.props.updateResolution(e)}
                            />
                        </LabelWrapper>
                    </div>
                    <AukTooltip.Help title={translate('load')}>
                        <AukButton.Blue
                            className="auk-button--round mr-2"
                            onClick={this.props.refresh}
                        >
                            <i className="fas fa-play-circle" />
                        </AukButton.Blue>
                    </AukTooltip.Help>
                    <RolePermission accessLevel="editor">
                        <AukTooltip.Help title={translate('export')}>
                            <AukButton.Outlined
                                className="auk-button--round mr-2"
                                onClick={this.togglePrefixer}
                            >
                                <i className="fas fa-download" />
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                    </RolePermission>
                </div>
                <OEECategorySelect
                    onChange={this.handleChangeFilter}
                    defaultValue={this.state.oeeFilter}
                    options={this.oeeFilterOptions}
                />
                <div className="utilization--body">
                    <div
                        className="blank d-flex align-items-center"
                        ref={(node) => (this.blank = node)}
                    >
                        <OEEScale gradient={this.colorGradient} />
                    </div>
                    <div className="chart-wrapper">
                        <div
                            className="heatmap--y-axis"
                            ref={(node) => (this.yAxis = node)}
                        >
                            <BlocksNavigator
                                data={this.props.entityBlock}
                                blocks={this.props.blocks.blocks}
                                onChange={this.handleChangeBlockNavigator.bind(this)}
                                style={{
                                    header: { height: gridSize },
                                }}
                            />
                        </div>
                        <div className="heatmap--diagram">
                            {!isEmpty(this.props.data) && (
                                <Heatmap
                                    gridSize={gridSize}
                                    data={utilizationHeatmapDataParser(data)}
                                    xDomain={getXDomain(data)}
                                    yDomain={getYDomain(data)}
                                    colorRange={color.domain}
                                    colorDomain={color.range}
                                    colorAccessor={(scale, d) =>
                                        d.value === '-' ? '#ddd' : scale(d.value)
                                    }
                                    dataAccessor={(d) => d.value}
                                    xAccessor={(d) => d.time}
                                    yAccessor={(d) => d.block_id}
                                    xTickAccessor={(d, i) => xTickFormat(d, res_period, i === 0)}
                                    xTickFontWeight={(d, i) => xTickFontWeight(d, i === 0)}
                                    handleClick={(d) => this.handleClickGridCell(d)}
                                    htmlTooltip={this.getTooltip.bind(this)}
                                    useTooltip
                                />
                            )}
                        </div>
                    </div>
                </div>
                <CSVDownloadPrefixer
                    show={this.state.prefixer}
                    toggle={this.togglePrefixer}
                    defaultPrefix={this.props.currentEntity.entity_name.replace(
                        / /g,
                        '_'
                    )}
                    save={this.download.bind(this)}
                    date={this.range}
                />
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        currentEntity: currentEntitySelector(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UtilizationPresentation);
