/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'reactstrap';

const CustomModal = (props) => (
    <Modal isOpen={props.show} toggle={props.toggle} backdrop="static">
        <div className={`${props.className || ''} panel`}>
            <div className="panel-header">
                <p className="panel-title">
                    {
                        <i
                            className="fas fa-exclamation fa-2x"
                            style={{
                                color: 'rgb(204, 51, 51)',
                                display: `${props.isConfirmation ? 'inline' : 'none'}`,
                            }}
                        />
                    }
                    <span style={{ marginLeft: 15 }}>{props.title}</span>
                </p>
            </div>
            <div className="panel-body">
                <div className="container">{props.body}</div>
            </div>
            {props.footer || (
                <div className="panel-footer">
                    <button onClick={props.toggle} className="btn b-cancel">
            CANCEL
                    </button>
                    <button
                        onClick={props.onProceed}
                        className={`btn ${props.isConfirmation ? 'b-delete' : 'b-save'}`}
                    >
                        {props.proceedText || 'PROCEED'}
                    </button>
                </div>
            )}
        </div>
    </Modal>
);

export default CustomModal;
