import { updateStoreRedux } from '../../../legacy/utils/redux';

// CONSTANT
import { SkuConstants as K } from './Sku.constant';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    skus: {},
    initialized: false,
});

export const skusReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_SKUS_STATE) {
        return updateStoreRedux(state, {
            skus: action.payload.skus,
            initialized: action.payload.initialized,
        });
    }

    if (action.type === K.ACTIONS.ADD_SKUS_RESOURCE) {
        return updateStoreRedux(state, {
            skus: { ...state.skus, ...action.payload.skus },
        });
    }

    if (action.type === K.ACTIONS.RESET_SKUS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    if (action.type === K.ACTIONS.REMOVE_SKUS_RESOURCE) {
        const deleteSet = new Set(action.payload.sku_ids);
        const deletedState = pickBy(
            { ...state.skus },
            ({ sku_id }) => !deleteSet.has(sku_id)
        );

        return updateStoreRedux(state, {
            skus: deletedState,
        });
    }

    return updateStoreRedux(state, {});
};
