// import { Policy } from '../../../legacy/models';
import { updateStoreRedux } from '../../../legacy/utils/redux';

// CONSTANTS
import { PermissionConstants as K } from './Permission.constants';

// const initialPolicy = new Policy(' ', -1, [], [], [], []);
const initialPolicy= null;

const getInitialReducer = () => ({
    policy: initialPolicy,
    policies: [],
    resources: {},
    error: {},
});

export const permissionsReducer = (
    state = getInitialReducer(),
    action
) => {
    if (
        action.type === K.ACTIONS.GET_POLICIES_SUCCESS ||
    action.type === K.ACTIONS.CREATE_POLICY_SUCCESS ||
    action.type === K.ACTIONS.UPDATE_POLICY_SUCCESS ||
    action.type === K.ACTIONS.DELETE_POLICY_SUCCESS
    ) {
        return updateStoreRedux(state, {
            policies: action.payload.policies,
        });
    }

    if (action.type === K.ACTIONS.GET_POLICY_DETAIL_SUCCESS) {
        return updateStoreRedux(state, {
            policy: action.payload.policy,
        });
    }

    if (action.type === K.ACTIONS.GET_POLICY_RESOURCES_SUCCESS) {
        return updateStoreRedux(state, {
            resources: action.payload.resources,
        });
    }

    if (
        action.type === K.ACTIONS.GET_POLICIES_FAILURE ||
    action.type === K.ACTIONS.GET_POLICY_DETAIL_FAILURE ||
    action.type === K.ACTIONS.CREATE_POLICY_FAILURE ||
    action.type === K.ACTIONS.UPDATE_POLICY_FAILURE ||
    action.type === K.ACTIONS.APPLY_POLICY_FAILURE ||
    action.type === K.ACTIONS.GET_POLICY_RESOURCES_FAILURE ||
    action.type === K.ACTIONS.DELETE_POLICY_FAILURE
    ) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    if (action.type === K.ACTIONS.RESET_PERMISSIONS_STORE) {
        return updateStoreRedux(state, getInitialReducer());
    }

    return updateStoreRedux(state, {});
};
