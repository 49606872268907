import { makeRequest } from '../Shared/Shared.action';
import { queryString } from '../../../legacy/utils/service';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { store } from '../..';

export const api_getSchedules = (entity_id) => {
    return Promise.all([api_getShifts(entity_id), api_getDowntimes(entity_id)]);
};

export const api_getShifts = (entity_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/shift`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};

export const api_createShift = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/shift`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_updateShift = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());
    const { shift_id } = data;

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/shift/${shift_id}`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_stopRecurringShift = (shift_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/shift/${shift_id}`,
            method: 'DELETE',
            params: null,
            success: resolve,
            error: reject,
        });
    });
};

export const api_deleteShifts = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/shift`,
            method: 'DELETE',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_getDowntimes = (entity_id) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/downtime`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};

export const api_createDowntime = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/downtime`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_updateDowntime = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());
    const { downtime_id } = data;

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/downtime/${downtime_id}`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_stopRecurringDowntime = (downtime_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/downtime/${downtime_id}`,
            method: 'DELETE',
            params: null,
            success: resolve,
            error: reject,
        });
    });
};

export const api_deleteDowntimes = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/downtime`,
            method: 'DELETE',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_getAssetShifts = (entity_id, asset_id, queryParams) => {
    const query = queryString(queryParams);

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/shift${query}`,
            method: 'GET',
            success: resolve,
            error: reject,
        });
    });
};
