/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResetPasswordWithCode } from "../../../store/old/Authentication/Authentication.action"
import { getQueryParams } from '../../utils/service';
import { flash } from '../../components/Flash';
import { pwRegex } from '../../utils/helpers';
import withRouter from '../../Wrappers/HOCs/withRouter';
import translate from '../../utils/translate';

class ChangePassword extends Component {
    state = {
        email: '',
        resetCode: '',
        password: '',
        confirm: '',
        emailValid: false,
        resetCodeValid: false,
        passwordValid: false,
        confirmValid: false,
        formErrors: { email: '', resetCode: '', password: '', confirm: '' },
        formValid: false,
    };

    UNSAFE_componentWillMount() {
        const params = getQueryParams(window.location.search);

        this.setState({
            email: params.email,
            resetCode: params.token,
            emailValid: !!params.email.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i),
            resetCodeValid: params.token.length >= 2,
        });
    }

    handleSubmit = () => {
        if (!this.state.formValid) {
            const { formErrors, password, confirm } = this.state;
            if (!password || !confirm) {
                flash({
                    message: 'Please fill in missing fields',
                    status: 'error',
                });
                return;
            }

            flash({
                message: 'Error updating password',
                details: (
                    <ol>
                        {Object.keys(formErrors).map((err, i) =>
                            formErrors[err] ? <li key={i}>{formErrors[err]}</li> : null
                        )}
                    </ol>
                ),
                status: 'error',
                duration: 100,
            });
            return;
        }

        this.props.resetPasswordWithCode({
            email: this.state.email,
            reset_code: this.state.resetCode,
            password: this.state.password,
            confirm_password: this.state.confirm,
        }, () => {
            this.props.router.navigate('/login')
        });
    };

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value);
        });
    };

    validateField(fieldName, value) {
        const fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let resetCodeValid = this.state.resetCodeValid;
        let passwordValid = this.state.passwordValid;
        let confirmValid = this.state.confirmValid;

        switch (fieldName) {
        case 'email':
            emailValid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
            fieldValidationErrors.email = emailValid
                ? ''
                : 'Please provide a valid email address';
            break;
        case 'resetCode':
            resetCodeValid = value.length >= 2;
            fieldValidationErrors.resetCode = resetCodeValid
                ? ''
                : 'Reset code is invalid';
            break;
        case 'password':
            passwordValid = !!(
                value.length >= 8 &&
          value.length <= 20 &&
          value.match(pwRegex)
            );
            fieldValidationErrors.password = passwordValid
                ? ''
                : 'Password should be 8-20 characters long, and contain at least 1 uppercase letter, 1 lowercase letter and 1 numeral';

            // check confirmed password also
            confirmValid = value === this.state.confirm;
            fieldValidationErrors.confirm = confirmValid
                ? ''
                : 'Passwords do not match';
            break;
        case 'confirm':
            confirmValid = value === this.state.password;
            fieldValidationErrors.confirm = confirmValid
                ? ''
                : 'Passwords do not match';
            break;
        default:
            break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                emailValid: emailValid,
                resetCodeValid: resetCodeValid,
                passwordValid: passwordValid,
                confirmValid: confirmValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({
            formValid:
        this.state.emailValid &&
        this.state.resetCodeValid &&
        this.state.passwordValid &&
        this.state.confirmValid,
        });
    }
    render() {
        let validPassword =
      this.state.password === '' ? (
          <input
              type="password"
              className="form-control form-control-up"
              placeholder="New Password"
              name="password"
              value={this.state.password}
              onChange={(event) => this.handleChange(event)}
          />
      ) : this.state.passwordValid === false ? (
          <input
              type="password"
              className="form-control form-control-up is-invalid"
              placeholder="New Password"
              name="password"
              value={this.state.password}
              onChange={(event) => this.handleChange(event)}
          />
      ) : (
          <input
              type="password"
              className="form-control form-control-up is-valid"
              placeholder="New Password"
              name="password"
              value={this.state.password}
              onChange={(event) => this.handleChange(event)}
          />
      );

        let validConfirm =
      this.state.confirm === '' ? (
          <input
              type="password"
              className="form-control form-control-up"
              placeholder="Confirm Password"
              name="confirm"
              value={this.state.confirm}
              onChange={(event) => this.handleChange(event)}
          />
      ) : this.state.confirmValid === false ? (
          <input
              type="password"
              className="form-control form-control-up is-invalid"
              placeholder="Confirm Password"
              name="confirm"
              value={this.state.confirm}
              onChange={(event) => this.handleChange(event)}
          />
      ) : (
          <input
              type="password"
              className="form-control form-control-up is-valid"
              placeholder="Confirm Password"
              name="confirm"
              value={this.state.confirm}
              onChange={(event) => this.handleChange(event)}
          />
      );

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-5 col-md-offset-5 align-self-end">
                            <div className="panel">
                                <div className="panel-header">
                                    <p className="panel-title">
                                        {translate('changepassword')}
                                    </p>
                                </div>
                                <div className="panel-body">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputFirstName"
                                            className="col-sm-4 col-form-label d-flex justify-content-end"
                                        >
                      New Password
                                        </label>
                                        <div className="col-sm-8">{validPassword}</div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputFirstName"
                                            className="col-sm-4 col-form-label d-flex justify-content-end"
                                        >
                      Confirm Password
                                        </label>
                                        <div className="col-sm-8">{validConfirm}</div>
                                    </div>
                                </div>
                                <div className="panel-footer justify-content-end">
                                    <button className="btn b-save" onClick={this.handleSubmit}>
                    SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetPasswordWithCode: (data, callback) =>
            dispatch(ResetPasswordWithCode(data, callback)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(ChangePassword));
