/* eslint-disable */
import { HolderOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import BlockOperation from './components/BlockOperation'

const BlocksTreeNode = (props) => {
    const {
        node,
        handleClick,
        handleDrop,
        children,
        draggable,
        selected,
        selectable,
    } = props;

    const domRef = useRef(null);

    const [clientOffset, setClientOffset] = useState({ x: 0, y: 0 });

    const onClick = (e) => {
        e.stopPropagation();
        handleClick && handleClick(node);
    };

    const onHover = (e) => {
        const { y: clientOffsetY } = clientOffset;
        const rect = domRef.current.getBoundingClientRect();
        const hoverClientY = clientOffsetY - rect.top;
        const hoverMiddleY = (rect.bottom - rect.top) / 2;
        const newHoverClientY = e.y - rect.top;
        const signChanged =
      (hoverClientY - hoverMiddleY) * (newHoverClientY - hoverMiddleY) > 0;

        if (signChanged) return;

        setClientOffset(e);
    };

    const onDrop = useCallback(
        (item, monitor) => {
            if (!node.parent_block_id) return;

            if (monitor.isOver({ shallow: true })) {
                const { y: clientOffsetY } = monitor.getClientOffset();
                const rect = domRef.current.getBoundingClientRect();
                const hoverClientY = clientOffsetY - rect.top;

                const targetIsAssetBlock = node.asset_id;
                const targetIsEmptyBlock = !targetIsAssetBlock && !node.children.length;

                let insert;
                if (targetIsEmptyBlock) {
                    insert = 0;
                } else {
                    const hoverMiddleY = (rect.bottom - rect.top) / 2;
                    insert = hoverMiddleY < hoverClientY ? 1 : -1;
                }

                handleDrop && handleDrop(item, node, insert);
            }
        },
        [handleDrop]
    );

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'card',
            item: node,
            canDrag: () => draggable && node.parent_block_id !== null,
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        }),
        [draggable]
    );

    const [{ isOverCurrent }, drop] = useDrop(
        () => ({
            accept: 'card',
            drop: (item, monitor) => onDrop(item, monitor),
            hover: (props, monitor) => {
                if (!props.parent_block_id) return;
                if (monitor.isOver({ shallow: true }))
                    onHover(monitor.getClientOffset());
            },
            collect: (monitor) => ({
                isOverCurrent: monitor.isOver({ shallow: true }),
            }),
        }),
        [clientOffset, handleDrop]
    );

    const borderStyle = useMemo(() => {
        const hoveredLine = '1px solid #40a9ff';
        const selectedLine = selected ? '2px solid #40a9ff' : 'none';

        if (!domRef || !domRef.current) return { border: selectedLine };
        if (!isOverCurrent) return { border: selectedLine };

        const { y: clientOffsetY } = clientOffset;
        const rect = domRef.current.getBoundingClientRect();
        const hoverClientY = clientOffsetY - rect.top;

        const isAssetBlock = node.asset_id;
        const isEmptyBlock = !isAssetBlock && !node.children.length;

        if (isEmptyBlock) {
            return { border: hoveredLine };
        } else {
            const hoverMiddleY = (rect.bottom - rect.top) / 2;

            return hoverMiddleY < hoverClientY
                ? { borderBottom: hoveredLine }
                : { borderTop: hoveredLine };
        }
    }, [selected, isOverCurrent, draggable, clientOffset]);

    const opacity = useMemo(() => (isDragging ? 0 : 1), [isDragging]);
    const cursor = useMemo(
        () =>
            draggable
                ? node.parent_block_id
                    ? 'pointer'
                    : 'default'
                : selectable
                    ? 'pointer'
                    : 'default',
        [draggable, selectable]
    );

    const getCompositeBlock = () => {
        const { block_id, weightage, block_name } = node;

        const header = (
            <div className="blocks-tree__node__composite__header blocks-tree__node__header d-flex justify-content-between w-100">
                <BlockWeightage value={weightage} />
                <BlockName value={block_name} />
                <BlockOperation block={node}/>
            </div>
        );

        return (
            <Collapse ghost defaultActiveKey={[block_id]}>
                <Collapse.Panel
                    className="blocks-tree__node__composite"
                    key={block_id}
                    header={
                        <div
                            className="w-100"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onClick(e);
                            }}
                        >
                            {draggable ? WithHolder(header) : header}
                        </div>
                    }
                >
                    <div style={{ marginLeft: 46 }}>{children}</div>
                </Collapse.Panel>
            </Collapse>
        );
    };

    const getAssetBlock = () => {
        const { weightage, asset } = node;

        const header = (
            <div onClick={onClick} className="blocks-tree__node__header">
                <BlockWeightage value={weightage} />
                <BlockName value={asset && asset.asset_name} />
            </div>
        );

        return draggable ? WithHolder(header) : header;
    };

    return drag(
        drop(
            <div
                ref={domRef}
                className="blocks-tree__node"
                style={{ ...borderStyle, opacity, cursor }}
            >
                {node.asset ? getAssetBlock() : getCompositeBlock()}
            </div>
        )
    );
};

const WithHolder = (component) => {
    return (
        <div className="d-flex" style={{ flexGrow: 1 }}>
            {component}
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: 40, flexShrink: 0 }}
            >
                <HolderOutlined />
            </div>
        </div>
    );
};

const BlockWeightage = ({ value }) => (
    <div className="blocks-tree__node__weightage">{value}</div>
);

const BlockName = ({ value }) => (
    <div className="blocks-tree__node__label ml-2" title={value}>
        <div className="blocks-tree__node__label__value text-overflow-ellipsis">
            {value}
        </div>
    </div>
);

BlocksTreeNode.defaultProps = {
    selectable: false,
    draggable: false,
};

export default BlocksTreeNode;
