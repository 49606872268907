

export const WidgetConstants = {
    ACTIONS: {
        RESET_WIDGETS_STATE: 'RESET_WIDGETS_STATE',
        SET_WIDGETS_RESOURCE: 'SET_WIDGETS_RESOURCE',
        ADD_WIDGETS_RESOURCE: 'ADD_WIDGETS_RESOURCE',
        REMOVE_WIDGETS_RESOURCE: 'REMOVE_WIDGETS_RESOURCE',
        CREATE_WIDGET_REQUEST: 'CREATE_WIDGET_REQUEST',
        UPDATE_WIDGET_REQUEST: 'UPDATE_WIDGET_REQUEST',
        DELETE_WIDGET_REQUEST: 'DELETE_WIDGET_REQUEST',
    },
    TYPES: {
        DASHBOARD: 'DASHBOARD',
        ASSET: 'ASSET',
        REPORT: 'REPORT',
    },
};
