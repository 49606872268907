/* eslint-disable react/prop-types */
import React from 'react';

// STYLE
import './Checkbox.scss';

// controlled component
export const Checkbox = ({
    onChange,
    checked,
    indeterminate,
    disabled,
}) => {
    const classDisabled = disabled ? 'auk-checkbox--disabled' : '';

    const classState = indeterminate
        ? 'auk-checkbox--indeterminate'
        : checked
            ? 'auk-checkbox--checked'
            : '';

    return (
        <div
            className={`auk-checkbox ${classDisabled} ${classState}`}
            onClick={onChange}
        />
    );
};

export const CheckboxLegend = ({ className, style }) => {
    const list = [
        { icon: <Checkbox checked={true} />, label: 'Selected' },
        {
            icon: <Checkbox indeterminate />,
            label: 'Partially (children) selected',
        },
        { icon: <Checkbox />, label: 'Not selected' },
    ];

    return (
        <div className={`d-flex flex-column ${className || ''}`} style={style}>
            {list.map((item, i) => {
                return (
                    <div className="d-flex align-items-center" key={i}>
                        <div
                            className="mr-2"
                            style={{
                                height: 24,
                                width: 24,
                                flexShrink: 0,
                                border: `1px solid #ddd`,
                                padding: 2,
                            }}
                        >
                            {item.icon}
                        </div>
                        {item.label}
                    </div>
                );
            })}
        </div>
    );
};

export const CheckboxState = {
    CHECKED: 1,
    UNCHECKED: 0,
    INDETERMINATE: -1,
};
