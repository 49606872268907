import React, { useEffect, useState } from "react"
import UnauthLayout from "../../../legacy/components/Layout/UnauthLayout"
import { Logo } from "../../components/Logo"
import mainLogoSrc from "../../../assets/images/auk-logo-full.png"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useNavigate, useSearchParams } from "react-router-dom"
import { decode } from "../../utils/helpers"
import { errorFlash, flash } from "../../components/Flash"
import { api_userAcceptInvite } from "../../../store/old/User/User.services"
import { session } from "../../auxStore"

const InvitePage = () => {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [loading, setLoading] = useState(true)

    const ref = params.get("ref")
  
    const accept = async (eId: string, uId: string, uEmail: string) => {
        try {
            await api_userAcceptInvite(eId, uId, { email: uEmail })
            setTimeout(() => {
                flash({ message: "Invite accepted, redirecting.." })
                setLoading(false)
                session.logout()
                navigate("/login")
            }, 3000)
        } catch(e) {
            setTimeout(() => {
                errorFlash(e)
                setLoading(false)
                session.logout()
                navigate("/login")
            }, 3000)
        }
    }

    useEffect(() => {
        const [entityId, userId, email] = decode(ref).split("-")
        accept(entityId, userId, email)
    }, [])

    const icon = <LoadingOutlined style={{ fontSize: 80 }} spin />

    return <UnauthLayout>
        <Logo className="main-logo" src={mainLogoSrc} />
        {
            loading ? 
                <>
                    <Spin indicator={icon} />
                    <p>Accepting your invitation, please wait...</p>
                </>
                : "Redirecting..."
        }
    </UnauthLayout>
}

export default InvitePage