class EventEmitter {
    constructor() {
        this._events = {};
    }

    /**
   * Subscribe to event
   * @param {String} event
   * @param {Function} handler
   *
   * @return {Function} dispose function to remove current event handler
   */
    on(event, handler) {
        if (!this._events[event]) this._events[event] = []; // new event

        this._events[event].push(handler);

        return () => this.dispose(event, handler);
    }

    /**
   * Trigger event and pass data to event handler
   * @param {String} event
   * @param {Any} data
   *
   * @return {Object} current instance of EventEmitter
   */
    trigger(event, data) {
        if (!this._events[event]) return this; // no one is listening to this event

        this._events[event].forEach((handler) => handler(data));
        return this;
    }

    /**
   * Dispose event handler
   * @param {String} event
   * @param {Function} handler
   *
   * @return {Object} current instance of EventEmitter
   */
    dispose(event, handler) {
        if (!this._events[event]) return this; // there is no event handlers

        const idx = this._events[event].findIndex(
            (_handler) => _handler === handler
        );
        this._events[event].splice(idx, 1);
        return this;
    }

    /**
   * Dispose event
   * @param {String} event
   *
   * @return {Object} current instance of EventEmitter
   */
    disposeEvent(event) {
        this._events[event] = null;
        delete this.events[event];
        return this;
    }

    /**
   * Dispose all events
   *
   * @return {Object} current instance of EventEmitter
   */
    disposeAll() {
        Object.keys(this._events).forEach(this.dispose);
        return this;
    }
}

// Singleton instance of EventEmitter is used globally in application
export const eventEmitter = new EventEmitter();
