/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

// MODEL
import { Policy } from '../../../models';

// ACTION
import { deletePolicyRequest } from '../../../../store/old/Permission/Permission.action';

class DeletePolicyModal extends Component {
    state = {
        policy: new Policy('', -1, [], [], [], []),
        showModalDeletePolicy: false,
    };

    handleCloseModal = () => {
        this.setState({ showModalDeletePolicy: false });
    };

    handleSubmitModal = () => {
        const { deletePolicy } = this.props;
        const { policy } = this.state;

        deletePolicy(policy, () => {
            this.setState({ showModalDeletePolicy: false });
        });
    };

    changeStateDeletePolicyModal(policy) {
        this.setState({ showModalDeletePolicy: true, policy });
    }

    render() {
        const { showModalDeletePolicy, policy } = this.state;
        return (
            <Modal
                className="auk-modal--small"
                style={{ width: '60vw' }}
                title={`Delete ${policy.policy_name}`}
                visible={showModalDeletePolicy}
                onOk={this.handleSubmitModal}
                onCancel={this.handleCloseModal}
            >
                <div>
                    <p>Warning! This action is not reversible.</p>
                    <p>Would you like to continue?</p>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deletePolicy: (policy, callback) =>
            dispatch(deletePolicyRequest(policy, callback)),
    };
};

export default connect(
    null,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(DeletePolicyModal);
