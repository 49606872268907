import { IssueConstants as K } from './Issues.constant';

export const resetIssuesState = () => {
    return {
        type: K.ACTIONS.RESET_ISSUES_STATE,
    };
};

export const setIssuesState = (issues, initialized = true) => {
    return {
        type: K.ACTIONS.SET_ISSUES_STATE,
        payload: { issues, initialized },
    };
};

export const fetchIssues = (entity_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ISSUES_REQUEST,
        payload: entity_id,
        callback,
    };
};

export const updateIssues = (payload, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ISSUES_REQUEST,
        payload,
        callback,
    };
};
