import { isNumber, round } from 'lodash';
import CONSTANTS from '../Constants';

export const parseTsData = (arr) => {
    return arr
        .map((d) => {
            d.time = new Date(d.time);
            return d;
        })
        .filter((d) => d.val !== null)
        .sort((a, b) => a.time - b.time);
};

export const TimeSeriesFactory = (d, statusSeries = null) => {
    const data = parseTsData(d);
    data.total = data.reduce((acc, curr) => (acc += curr.val), 0);
    data.last = data.length ? round(data[data.length - 1].val, 2) : '-';
    data.status = statusSeries ? getMachineStatus(statusSeries) : ''
    data.aggregate = function (mode, units) {
        const { total, last } = this;
        switch (mode) {
        case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
            return `Total: ${total.toLocaleString()} ${units}`;
        case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
            return `Last: ${last} ${units}`;
        default:
            return '';
        }
    };

    data.__defineGetter__('cumulative', function () {
        delete this.cumulative;
        let temp = 0;
        return (this.cumulative = data.map((d) => ({
            time: d.time,
            int: d.int,
            val: (temp += d.val),
        })));
    });

    return data;
};

const getMachineStatus = ({ data, mode, samplingRate }) => {
    if (!data.length) return CONSTANTS.STATUS.UNAVAILABLE;

    if (samplingRate < 600) {
        let lastOutputCursor;
        const expectedDataLength = CONSTANTS.MAJOR_STOP_THRESHOLD / Math.max(samplingRate, 60);
        for (let i = 0; i < data.length; i++) {
            if (isValidSignal(data[i], mode)) {
                (lastOutputCursor = i)
            };
        }

        if (!lastOutputCursor) return CONSTANTS.STATUS.RED;
        if (lastOutputCursor === (expectedDataLength - 1)) return CONSTANTS.STATUS.GREEN;
        return CONSTANTS.STATUS.YELLOW;
    }

    if (isValidSignal(data[data.length - 1], mode)) return CONSTANTS.STATUS.GREEN;
    return CONSTANTS.STATUS.RED
};

const isValidSignal = (d, mode) => {
    if (mode === CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE) {
        return isNumber(d.val);
    }

    return d.val && d.val > 0;
};