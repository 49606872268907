import React from "react"
import { type ImpactComparisonChartSeriesPoint } from "./ImpactChart"
import { ImpactEnum, ImpactTypeEnum } from "../../../constants/impact.constants"

const ImpactTooltip = (props: Props) => {
    const Content = props.data ? contentComponents[props.type][props.data.x] : null
    return props.data
        ? 
        <div className="impact-chart-tooltip">
            <div className="impact-chart-tooltip__title">{props.data.x}* (ARR)</div>
            {Content ? <Content/> : null}
        </div>
        
        : null
}

interface Props {
    data: ImpactComparisonChartSeriesPoint | null
    type: ImpactTypeEnum
}

const CapturedSavingsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Cost Saving Annualized =
                <br/>
                <br/>
                    Annual Operating Hours
                <br/>
                    x Operating cost per hour
                <br/>
                    x (Current OEE - Baseline OEE)
            </div>

        </div>
    )
}

const PotentialSavingsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Cost Saving Annualized =
                <br/>
                <br/>
                    Annual Operating Hours
                <br/>
                    x Operating cost per hour
                <br/>
                    x (Best OEE - Current OEE)
            </div>

        </div>
    )
}

const BenchmarkSavingsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Cost Saving Annualized =
                <br/>
                <br/>
                    Annual Operating Hours
                <br/>
                    x Operating cost per hour
                <br/>
                    x (Benchmark OEE - Best OEE)
            </div>

        </div>
    )
}

const CapturedGainsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Revenue Gain Annualized =
                <br/>
                <br/>
                    Base output per year
                <br/>
                    x Margin per piece
                <br/>
                    x (Current OEE - Baseline OEE)
            </div>
        </div>
    )
}

const PotentialGainsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Revenue Gain Annualized =
                <br/>
                <br/>
                    Base output per year
                <br/>
                    x Margin per piece
                <br/>
                    x (Best OEE - Current OEE)
            </div>
        </div>
    )
}

const BenchmarkGainsTooltip = () => {
    return (
        <div className="impact-chart-tooltip__content">
            <div>Revenue Gain Annualized =
                <br/>
                <br/>
                    Base output per year
                <br/>
                    x Margin per piece
                <br/>
                    x (Benchmark OEE - Best OEE)
            </div>
        </div>
    )
}

const contentComponents = {
    [ImpactTypeEnum.COST_SAVINGS]: {
        [ImpactEnum.CAPTURED_DELTA as string]: CapturedSavingsTooltip,
        [ImpactEnum.POTENTIAL_DELTA as string]: PotentialSavingsTooltip,
        [ImpactEnum.BENCHMARK_DELTA as string]: BenchmarkSavingsTooltip,
    },
    [ImpactTypeEnum.REVENUE_GAIN]: {
        [ImpactEnum.CAPTURED_DELTA as string]: CapturedGainsTooltip,
        [ImpactEnum.POTENTIAL_DELTA as string]: PotentialGainsTooltip,
        [ImpactEnum.BENCHMARK_DELTA as string]: BenchmarkGainsTooltip,
    },
}

export default ImpactTooltip
