export const de = {
    notes: 'Anmerkungen',
    load: 'Laden',
    export: 'Herunterladen',
    unutilised: 'Un genutzter',
    breakdowns: 'Aufschlüsselungen',
    threshold: 'Schwelle',
    oeepreview: 'OEE Vorschau',
    heatmappreview: 'Heatmap Vorschau',
    oeecolors: 'OEE Farben',
    color: 'Farbe',
    reset: 'Reset',
    theme: 'Thema',
    defaultview: 'Standardansicht',
    defaulttimeselection: 'Standardzeitauswahl',
    zoomlevel: 'Zoom-Ebene',
    entity: 'Entität',
    preferences: 'Voreinstellungen',
    custom: 'Benutzerdefiniert',
    enteryourmailtoreset: 'Geben Sie Ihre E-Mail zurücksetzen',
    goback: 'Geh zurück',
    resetpassword: 'Passwort zurücksetzen',
    logout: 'Ausloggen',
    ownersemail: 'Besitzer E-Mail',
    optional: 'Optional',
    industry: 'Industrie',
    timezone: 'Zeitzone',
    nocoderequesttojoin: 'No-Code-Abfrage Join',
    enterreferralcodeinstead: 'Geben Sie stattdessen Empfehlungscode',
    setup: 'Konfiguration',
    company: 'Unternehmen',
    referralcode: 'Referenzcode',
    submit: 'einreichen',
    summary: 'Zusammenfassung',
    language: 'Sprache',
    login: 'Anmeldung',
    signin: 'Einloggen',
    signup: 'Anmelden',
    username: 'Nutzername',
    password: 'Passwort',
    forgotpassword: 'Passwort vergessen?',
    rememberme: 'Behalte mich in Erinnerung',
    register: 'Registrieren',
    registration: 'Anmeldung',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    mobile: 'Handy, Mobiltelefon',
    country: 'Land',
    contactnumber: 'Kontakt Nummer',
    appointment: 'Geplanter Termin',
    role: 'Rolle',
    admin: 'Administrator',
    owner: 'Inhaber',
    editor: 'Editor',
    viewer: 'Zuschauer',
    confirmpassword: 'Bestätige das Passwort',
    iacceptthe: 'Ich akzeptiere das',
    useragreement: 'Nutzungsbedingungen',
    alreadyregisteredlogin: 'Bereits registriert? Anmeldung',
    softwareasaservicetermsofuse: 'Software-as-a-Service-Nutzungsbedingungen',
    dashboard: 'Instrumententafel',
    streaming: 'Streaming',
    resolution: 'Auflösung',
    seconds: 'Sekunden',
    second: 'zweite',
    minutes: 'Protokoll',
    minute: 'Minute',
    hours: 'Std',
    hour: 'Stunde',
    weeks: 'Wochen',
    week: 'Woche',
    months: 'Monate',
    month: 'Monat',
    quarters: 'Quartier',
    quarter: 'Quartal',
    years: 'Jahre',
    year: 'Jahr',
    from: 'Von',
    to: 'Zu',
    start: 'Anfang',
    end: 'Ende',
    display: 'Anzeige',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Zeitfolgen',
    gateway: 'Tor',
    statusasof: 'Der Status ab',
    active: 'aktiv',
    offline: 'offline',
    mergeasonemachine: 'Fusionieren, wie eine Maschine',
    drawarrowstoformprocessmap: 'Zeichnen Sie Pfeile Form Prozesslandkarte',
    openblockeditor: 'Offener Bausteineditor',
    createnewtile: 'Create New Tile',
    summarytile: 'Zusammenfassung Kachel',
    selectablockthennameyoursummarytile:
    'Wählen Sie einen Block, nennen Sie Ihre Zusammenfassung Kachel',
    name: 'Name',
    blockselector: 'Blockselektor',
    asset: 'Anlagegut',
    therearenolabelstaggedinthisperiod:
    'Es gibt keine in dieser Zeit markiert Etiketten',
    standardtimes: 'Standardzeit (s)',
    total: 'Gesamt',
    last: 'Zuletzt',
    oeeinput: 'OEE-Eingang',
    chartcolor: 'Diagramm Farbe',
    issueeditor: 'Ausgabe-Editor',
    issuemangement: 'Ausgabe Mangement',
    cumulativeoutput: 'Kumulierte Ausgabe',
    tag: 'Etikett',
    label: 'Etikette',
    valueval: 'Wert (Val)',
    time: 'Zeit',
    januaryjan: 'Januar (Jan)',
    februaryfeb: 'Februar (Feb)',
    marchmar: 'März (Mar)',
    aprilapr: 'April (Apr)',
    may: 'Kann',
    junejun: 'Juni (Jun)',
    julyjul: 'Juli (Jul)',
    augustaug: 'August (Aug)',
    septembersep: 'September (Sep)',
    octoberoct: 'Oktober (Oktober)',
    novembernov: 'November (Nov)',
    decemberdec: 'Dezember (Dec)',
    day: 'Tag',
    days: 'Tage',
    mondaymonm: 'Montag (MO) M',
    tuesdaytuet: 'Dienstag (Di) T',
    wednesdaywedw: 'Mittwoch (Mi) W',
    thursdaythut: 'Donnerstag (Do) T',
    fridayfrif: 'Freitag (Fr) F',
    saturdaysats: 'Samstag (Sa) S',
    sundaysuns: 'Sonntag (So) S',
    edit: 'Bearbeiten',
    add: 'Hinzufügen',
    new: 'Neu',
    create: 'Erstellen',
    search: 'Suche',
    close: 'Schließen',
    cancel: 'Stornieren',
    save: 'sparen',
    saved: 'Gerettet',
    clear: 'klar',
    enter: 'Eingeben',
    navigator: 'Navigator',
    mandatoryfields: 'Pflichtfelder',
    select: 'Wählen',
    merge: 'Verschmelzen',
    delete: 'Löschen',
    done: 'Getan',
    filter: 'Filter',
    table: 'Tabelle',
    columns: 'Säulen',
    rows: 'Reihen',
    cell: 'Zelle',
    page: 'Seite',
    previous: 'Bisherige',
    next: 'Nächster',
    serialnumber: 'Ordnungsnummer',
    manuallyinputserialnumber: 'Manuelle Eingabe der Seriennummer',
    updateasset: 'Update Vermögen',
    machine: 'Maschine',
    brand: 'Marke',
    model: 'Modell',
    nodes: 'Knoten',
    fusions: 'Fusions',
    chartorder: 'Chart Bestellen',
    primary: 'primär',
    adddevicechannel: 'Gerät hinzufügen - Kanal',
    pushratesec: 'Push-Rate (s)',
    voltagev: 'Spannung (V)',
    sensor: 'Sensor',
    mode: 'Modus',
    digitalcount: 'Digital-Count',
    digitalstate: 'Digital-Staat',
    analogvoltage: 'Analog Spannungs',
    charttitle: 'Diagrammtitel',
    unitmeasure: 'Maßeinheit',
    attribute: 'Attribut',
    autocycletime: 'Auto Zykluszeit',
    unittransform: 'Transformations-Einheit',
    leaveblankifnotapplicable: 'Freilassen, wenn nicht anwendbar',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Pflichtfelder (leer lassen, wenn Kanal nicht in Betrieb)',
    fusedchart: 'Fused-Diagramm',
    fusionname: 'Fusion-Name',
    charttype: 'Diagramm Typ',
    column: 'Säule',
    line: 'Linie',
    state: 'Zustand',
    expression: 'Ausdruck',
    combination: 'Kombination',
    currentcharts: 'aktuelle Charts',
    expressionconstructor: 'Expression Constructor',
    validoperators: 'Gültige Operatoren',
    leaveblanktokeepexistingexpression: 'Leer lassen vorhandenen Ausdruck halten',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Gesamteffektivität der Ausrüstung',
    legend: 'Legende',
    loading: 'Wird geladen',
    availability: 'Verfügbarkeit',
    performance: 'Performance',
    quality: 'Qualität',
    unscheduled: 'außerplanmäßig',
    planneddowntime: 'geplante Ausfallzeiten',
    changeoversetup: 'Umstellung / Setup',
    breakdown: 'Nervenzusammenbruch',
    unutilized: 'Un genutzter',
    speedloss: 'Geschwindigkeitsverlust',
    minorstops: 'Minor Stops',
    rework: 'nachbearbeiten',
    rejectscrap: 'Ablehnen / Scrap',
    nodata: 'Keine Daten',
    blockeditor: 'Block-Editor',
    sort: 'Sortieren',
    logicblocks: 'Logik-Blöcke',
    sequence: 'Reihenfolge',
    and: 'Und',
    or: 'Oder',
    utilizationheatmap: 'Nutzung Heatmap',
    startdate: 'Anfangsdatum',
    enddate: 'Endtermin',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    quarterly: 'Vierteljährlich',
    yearly: 'Jährlich',
    trendanalysis: 'Trend analysen',
    metricselection: 'Metric Auswahl',
    metric: 'Metrisch',
    data: 'Daten',
    target: 'Ziel',
    periodselection: 'Zeitraum Auswahl',
    span: 'Spanne',
    every: 'Jeden',
    repeat: 'Wiederholen',
    repeats: 'wiederholt',
    addsingle: 'In Einzel',
    paretoanalysis: 'Pareto-Analyse',
    calendartime: 'Kalenderzeit',
    scheduledtime: 'Geplante Zeit',
    operatingtime: 'Betriebszeit',
    netruntime: 'Net Laufzeit',
    effective: 'Wirksam',
    loadingloss: 'Loading-Verlust',
    availabilityloss: 'Verfügbarkeit Verlust',
    performanceloss: 'Performance-Verlust',
    qualityloss: 'Qualitätsverlust',
    notagsinthiscategory: 'Keine Tags in dieser Kategorie',
    overall: 'Insgesamt',
    tagged: 'Tagged',
    occurrence: 'Auftreten',
    skumanagement: 'SKU-Management',
    category: 'Kategorie',
    skuname: 'SKU-Name',
    skucode: 'SKU-Code',
    unitofmeasurementuom: 'Maßeinheit (ME)',
    description: 'Beschreibung',
    csvupload: 'CSV hochladen',
    template: 'Vorlage',
    notifications: 'Benachrichtigungen',
    newnotification: 'neue Benachrichtigung',
    monitoring: 'Überwachung',
    topic: 'Thema',
    frequency: 'Frequenz',
    recipients: 'Empfänger',
    notifyby: 'Benachr',
    sms: 'SMS',
    alert: 'Aufmerksam',
    report: 'Bericht',
    greaterthan: 'Größer als',
    lessthan: 'Weniger als',
    greaterthanorequal: 'Größer als oder gleich',
    lessthanorequal: 'Weniger als oder gleich',
    equal: 'Gleich',
    range: 'Angebot',
    continuousduration: 'kontinuierliche Dauer',
    usermanagement: 'Benutzerverwaltung',
    user: 'Benutzer',
    users: 'Benutzer',
    invitenew: 'einladen New',
    status: 'Status',
    resend: 'Erneut senden',
    revoke: 'Widerrufen',
    invite: 'Einladen',
    invitees: 'Eingeladene',
    addinviteesbypressingenterorclickingonthesign:
    'In Eingeladenen der Enter-Taste oder einem Klick auf das ‚+‘ Zeichen',
    schedules: 'Termine',
    shift: 'Verschiebung',
    profile: 'Profil',
    changepassword: 'Ändere das Passwort',
    deleteaccount: 'Konto löschen',
    userguide: 'Benutzerhandbuch',
    overviewandbasiccontrol: 'Übersicht und Basic Control',
    rangeandresolution: 'Bereich und Auflösung',
    editingoptions: 'Bearbeitungsoptionen',
    mergetiles: 'Merge Fliesen',
    detailedassetview: 'detaillierte Anlagensicht',
    assetoverview: 'Vermögensübersicht',
    summarystatistics: 'Zusammengefasste Statistiken',
    dataanalysis: 'Datenanalyse',
    oeeanoverview: 'OEE eine Übersicht',
    oeetoggle: 'OEE Toggle',
    addingamachine: 'Hinzufügen einer Maschine',
    nodeconfiguration: 'Knotenkonfiguration',
    deleteupdateassets: 'Löschen Update Vermögen',
    oeesummarytile: 'OEE Zusammenfassung Fliese',
    oeetags: 'OEE Stichworte',
    issuemanagement: 'Issue Management',
    labelsandfiltering: 'Etiketten und Filter',
    useraccess: 'Benutzerzugriff',
    group: 'Gruppe',
    groups: 'Gruppen',
    joincompany: 'Registriert Unternehmen',
    notification: 'Benachrichtigung',
    createnotification: 'erstellen Benachrichtigung',
    node: 'Knoten',
    downtime: 'Ausfallzeit',
    downtimes: 'Ausfallzeiten',
    repeatstartdate: 'Wiederholen Startdatum',
    repeatenddate: 'Wiederholen Sie Enddatum',
    optional: 'Optional',
    createplan: 'erstellen Sie planen',
    today: 'Heute',
    starttime: 'Startzeit',
    endtime: 'Endzeit',
    chart: 'Diagramm',
    reject: 'Ablehnen',
    accept: 'Akzeptieren',
    filterbydaterange: 'Filter nach Datumsbereich',
    filterbymostrecent: 'Filter nach jüngsten',
    result: 'Ergebnis',
    type: 'Art',
    duration: 'Dauer',
    by: 'durch',
    title: 'Titel',
    assigned: 'Assigned',
    action: 'Aktion',
    track: 'Spur',
    issue: 'Problem',
    priority: 'Priorität',
    requestor: 'Anforderer',
    opened: 'geöffnet',
    assignee: 'Zessionar',
    root: 'Wurzel',
    cause: 'Ursache',
    identified: 'identifizierte',
    defined: 'definierte',
    date: 'Datum',
    resolve: 'Entschlossenheit',
    resolved: 'aufgelöst',
    targetresolvedate: 'Ziel Entschlossenheit Datum',
    procurement: 'Beschaffung',
    choose: 'Wählen',
    analyses: 'Analysen',
    source: 'Quelle',
    selection: 'Auswahl',
    historical: 'historisch',
    mostrecent: 'Neueste',
    regression: 'Regression',
    trendline: 'Trendlinie',
    upperbound: 'obere Grenze',
    lowerbound: 'untere Grenze',
    preview: 'Vorschau',
    period: 'Zeitraum',
    series: 'Serie',
    axis: 'Achse',
    youhaveno: 'du hast kein',
    code: 'Code',
    all: 'Alles',
    upload: 'hochladen',
    regressionanalysis: 'Regressionsanalyse',
    issuetracker: 'issue tracker',
    labelsearch: 'Label Suche',
    gatewaysetup: 'Gateway-Setup',
    setupgateway: 'Setup-Gateway',
    radiomesh: 'Radio-Mesh',
    targetsetting: 'Zielsetzungs',
    updatetrackedissue: 'Update verfolgt Ausgabe',
    shownewtrackedissue: 'zeigen neue getrackten Ausgabe',
    low: 'niedrig',
    mid: 'Mitte',
    high: 'hoch',
    linear: 'linear',
    exponential: 'exponentiell',
    logarithmic: 'logarithmisch',
    quadratic: 'quadratisch',
    polynomial: 'Polynom',
    general: 'Allgemeines',
    datataggingenrichment: 'Data-Kennzeichnung Enrichment',
    hardware: 'Hardware',
    nodemanagement: 'Node-Management',
    createeditnotification: 'Erstellen Bearbeitungsmeldebit',
    conditions: 'Bedingungen',
    addsku: 'hinzufügen sku',
    editsku: 'bearbeiten SKU',
    setupagateway: 'ein Gateway-Setup',
    deviceserialnumber: 'Geräte-Seriennummer',
    repeatsweekly: 'wiederholt wöchentlich',
    repeatsdaily: 'wiederholt täglich',
    repeatsmonthly: 'wiederholt monatlich',
    repeatsannually: 'wiederholt jährlich',
    repeatsevery: 'wiederholt sich alle',
    hourly: 'stündlich',
    biweekly: 'zweiwöchentlich',
};
