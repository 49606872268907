import moment from "moment/moment"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import ActionButton from "../../../../../components/Button/ActionButton/ActionButton"
import TextButton from "../../../../../components/Button/TextButton/TextButton"
import DateRangePicker, { DateRangePickerType } from "../../../../../components/DateRangePicker/DateRangePicker"
import Dialog from "../../../../../components/Dialog/Dialog"
import FormField from "../../../../../components/Form/FormField"
import Grid from "../../../../../components/Grid/Grid"
import ProcessTreeSelect from "../../../../../components/ProcessSelector/ProcessTreeSelector"
import Select from "../../../../../components/Select/Select"
import { GoalsUnitOfTimeEnum } from "../../../../../constants/goals.constants"
import useBlocks from "../../../../../hooks/useBlocks"
import { objectiveCreateAction } from "../../../../../store/actions/goals.action"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { toggleDialog } from "../../../../../store/reducers/goals.reducer"
import { useNavigate } from "react-router-dom"
import { snacksErrorMessage } from "../../../../../store/actions/snacks.action"
import TextInput from "../../../../../components/Input/TextInput/TextInput"
import BlockModel from "../../../../../api/models/block.model"

const ObjectivesAddDialog = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { root: plant } = useBlocks()
    const dialog = useAppSelector(state => state.goals.dialog)
    const { register, reset, setValue, watch, formState, handleSubmit, control, trigger } = useForm<Inputs>({
        defaultValues: {
            name: "",
            startsAt: null,
            endsAt: null,
            blockId: (plant as BlockModel).blockId,
            unitOfTime: GoalsUnitOfTimeEnum.MONTHLY
        },
        mode: "onChange"
    })

    useEffect(() => {
        register("startsAt", { required: true })
        register("endsAt", { required: true })
    }, [])

    useEffect(() => {
        setValue("startsAt", null)
        setValue("endsAt", null)
    }, [watch("unitOfTime")])

    useEffect(() => {
        reset()
        setValue("blockId", (plant as BlockModel).blockId)
    }, [dialog.add])

    const onSubmit = async (data: Inputs) => {
        if (!data.blockId || !data.startsAt || !data.endsAt) {
            dispatch(snacksErrorMessage("Please fill in all fields"))
            return
        }

        try {
            const result: any = await dispatch(
                objectiveCreateAction({
                    blockId: data.blockId,
                    name: data.name,
                    startsAt: data.startsAt,
                    endsAt: data.endsAt,
                    unitOfTime: data.unitOfTime,
                })
            )
    
            navigate(`/goals/${result.payload.id}`)
        } catch (e: any) {
            dispatch(snacksErrorMessage(e?.message))
        }
    }

    const handleDateChange = (start: Time.DateValue, end: Time.DateValue) => {
        setValue("startsAt", start)
        setValue("endsAt", end)
        trigger()
    }

    const handleUnitOfTimeChange = (value: GoalsUnitOfTimeEnum) => {
        setValue("unitOfTime", value)
    }

    const handleProcessChange = (value: number) => {
        setValue("blockId", value)
    }

    const handleAddToggle = (value: boolean) => {
        void dispatch(toggleDialog({ type: "add", value }))
    }

    const handleCancel = () => {
        void dispatch(toggleDialog({ type: "add", value: false }))
    }

    const handleAddClose = () => {
        reset()
    }

    const unitOfTime = watch("unitOfTime")

    return <Dialog
        title="New Goal"
        maxWidth="sm"
        actions={<>
            <TextButton onPress={handleCancel}>Cancel</TextButton>
            <ActionButton onPress={handleSubmit(onSubmit)}>Create</ActionButton>
        </>}
        onClose={handleAddClose}
        setOpen={handleAddToggle}
        open={dialog.add}
    >
        <Grid container direction="column" rowSpacing="16px">
            <Grid item>
                <TextInput
                    label="Plan name"
                    maxLength={40}
                    fullWidth
                    {...register("name", { required: true })}
                    errorText={!formState.isSubmitted ? undefined : formState.errors?.name ? "Plan name is required" : undefined}
                />
            </Grid>
            <Grid item>
                <Select<GoalsUnitOfTimeEnum>
                    fullWidth
                    options={[
                        { label: "Weekly", value: GoalsUnitOfTimeEnum.WEEKLY },
                        { label: "Monthly", value: GoalsUnitOfTimeEnum.MONTHLY },
                    ]}
                    onValueChange={handleUnitOfTimeChange}
                    value={unitOfTime}
                    required
                />
            </Grid>
            <Grid item>
                <Controller
                    key={"startsAt"}
                    rules={{ deps: ["endsAt"] }}
                    name={"startsAt"}
                    control={control}
                    render={({ formState }) => {
                        const isValid = !formState.errors?.startsAt && !formState.errors?.endsAt
                        return <DateRangePicker
                            placeholder="Goal Time Period"
                            type={getCalendarType(unitOfTime)}
                            onChange={handleDateChange}
                            min={moment()}
                            start={watch("startsAt")}
                            end={watch("endsAt")}
                            excludeDaysStart={[2, 3, 4, 5, 6, 7]}
                            excludeDaysEnd={[1, 2, 3, 4, 5, 6]}
                            error={formState.isSubmitted ? !isValid : false}
                            helperText={formState.isSubmitted && !isValid ? "Range is required" : undefined}
                        />
                    }}
                />
            </Grid>
            <Grid item>
                <FormField
                    render={props => <ProcessTreeSelect
                        {...props}
                        required
                        onChange={handleProcessChange}
                        value={watch("blockId")}
                    />}
                />
            </Grid>
        </Grid>
    </Dialog>
}

const getCalendarType = (unitOfTime: GoalsUnitOfTimeEnum): DateRangePickerType => {
    if (unitOfTime === GoalsUnitOfTimeEnum.MONTHLY) {
        return DateRangePickerType.MONTH
    }

    if (unitOfTime === GoalsUnitOfTimeEnum.WEEKLY) {
        return DateRangePickerType.DAY
    }

    throw new Error("Date range type is not supported")
}

type Inputs = {
    name: string
    blockId: number | null
    startsAt: Time.DateValue
    endsAt: Time.DateValue
    unitOfTime: GoalsUnitOfTimeEnum,
}

export default ObjectivesAddDialog
