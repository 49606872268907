/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import './DaysOfWeekTabs.scss';

const DaysOfWeekTabs = (props) => {
    const days = [
        { value: 1, label: 'Mon' },
        { value: 2, label: 'Tue' },
        { value: 3, label: 'Wed' },
        { value: 4, label: 'Thu' },
        { value: 5, label: 'Fri' },
        { value: 6, label: 'Sat' },
        { value: 0, label: 'Sun' },
    ];

    const [selected, setSelected] = useState(props.value || []);

    const handleChange = (clickedValue) => {
        const result = selected.includes(clickedValue)
            ? selected.filter((v) => v !== clickedValue)
            : selected.concat(clickedValue);
        setSelected(result);

        props.handleChange(result);
    };

    return (
        <div className={`p-2 ${props.className}`}>
            {days.map((day, i) => {
                return (
                    <span
                        key={i}
                        className={`day ${selected.includes(day.value) ? 'active' : ''}`}
                        onClick={() => !props.readOnly && handleChange(day.value)}
                        style={{ cursor: props.readOnly ? 'default' : 'pointer' }}
                    >
                        {day.label[0]}
                    </span>
                );
            })}
        </div>
    );
};

export default DaysOfWeekTabs;
