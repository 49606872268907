/* eslint-disable react/prop-types */
import React from 'react';

import ModalPanel from '../../components/ModalPanel';
import WithLoaders from '../../Widgets/components/WithLoaders';
import { AssetPrimaryDumb } from '../../Widgets';
import { OEESummary, WidgetWrapper } from '../../Widgets';
import { PanelHeader, PanelBody, PanelFooter } from '../../components/Panel';
import { Permission } from '../../components/Permission';

import './DashboardWidgetCreate.scss';

const DashboardWidgetCreate = (props) => {
    const { onCancel } = props;
    const AssetThumbnail = (
        <WidgetWrapper
            className="widget--no-touch"
            style={{ cursor: 'pointer', width: 170, height: 250 }}
        >
            <WithLoaders isLoading={false}>
                <AssetPrimaryDumb />
            </WithLoaders>
        </WidgetWrapper>
    );

    const SummaryThumbnail = (
        <WidgetWrapper
            className="widget--no-touch"
            style={{ cursor: 'pointer', width: 170, height: 250 }}
        >
            <WithLoaders isLoading={false}>
                <OEESummary />
            </WithLoaders>
        </WidgetWrapper>
    );

    return (
        <ModalPanel className="tile-create">
            <PanelHeader>Create New Tile</PanelHeader>
            <PanelBody>
                <div className="row justify-content-around">
                    <Permission resource="devices" forResource canDo="edit">
                        <DummyTileWrapper click={props.showDeviceInit}>
                            {AssetThumbnail}
                        </DummyTileWrapper>
                    </Permission>

                    <Permission resource="blocks" forResource canDo="edit">
                        <DummyTileWrapper click={props.showSummarySetup}>
                            {SummaryThumbnail}
                        </DummyTileWrapper>
                    </Permission>
                </div>
            </PanelBody>
            <PanelFooter>
                <button className="btn b-cancel" onClick={onCancel}>
          CANCEL
                </button>
            </PanelFooter>
        </ModalPanel>
    );
};

const DummyTileWrapper = ({ click, children }) => {
    return (
        <div className={'box tile-pseudo'} onClick={click}>
            {children}
        </div>
    );
};

export default DashboardWidgetCreate;
