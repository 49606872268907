
import moment from 'moment';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { UtilizationConstants as K } from './Utilization.constants';

const getInitialState = () => {
    return {
        date_range: [
            moment().subtract(30, 'day').startOf('day'),
            moment().startOf('day'),
        ],
        resolution: K.RESOLUTIONS[1],
    };
};

export const UtilizationReducer = (
    state = { ...getInitialState() },
    action
) => {
    switch (action.type) {
    case K.ACTIONS.UTILIZATION_UPDATE_RANGE:
        return updateStoreRedux(state, {
            date_range: action.payload.date_range,
        });
    case K.ACTIONS.UTILIZATION_UPDATE_RESOLUTION:
        return updateStoreRedux(state, {
            resolution: action.payload.resolution,
        });
    case K.ACTIONS.RESET_UTILIZATION_STORE:
        return updateStoreRedux(state, {
            ...getInitialState(),
        });
    default:
        return updateStoreRedux(state, {});
    }
};
