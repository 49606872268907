/* eslint-disable react/prop-types */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import { isFinite } from 'lodash';
import React, { useEffect, useState } from 'react';

export const validateControlLimits = (value) => {
    if (value === null) return Promise.resolve();

    for (const val of value) {
        if (!(isFinite(+val) || val === '')) {
            return Promise.reject(
                new Error('Control limit must be blank or a number')
            );
        }
    }

    return Promise.resolve();
};

const ControlLimitsInputs = ({ value, onChange }) => {
    value = value || [];
    const [uppValue, setUppValue] = useState(value[1] || '');
    const [lowValue, setLowValue] = useState(value[0] || '');

    useEffect(() => {
        if (!uppValue && !lowValue) {
            return onChange(null);
        }

        return onChange([lowValue, uppValue]);
    }, [uppValue, lowValue]);

    return (
        <div className="d-flex flex-column">
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>High</span>}
                className="w-100"
                value={uppValue}
                onChange={(e) => setUppValue(e.target.value)}
                maxLength={8}
            />
            <Input
                addonBefore={<span style={{ minWidth: 80 }}>Low</span>}
                className="w-100"
                value={lowValue}
                onChange={(e) => setLowValue(e.target.value)}
                maxLength={8}
            />
        </div>
    );
};

export default ControlLimitsInputs;

export const ControlLimitsFormLabel = () => {
    return (
        <span>
      Control Limits
            <Popover
                placement="right"
                content={
                    <div style={{ width: 250, textAlign: 'left' }}>
            High and low control limit values define the control range for the
            chart. Red marking lines indicating these values will be displayed
            only on push rate resolution.
                    </div>
                }
            >
                <InfoCircleOutlined className="ml-2" />
            </Popover>
        </span>
    );
};
