import { getMapFromArr } from '../../../../legacy/utils/helpers';

export const TARGETS_UI_PERIOD_OPTION_TYPES = {
    SIX_HOURS: 'SIX_HOURS',
    TWELVE_HOURS: 'TWELVE_HOURS',
    ONE_DAY: 'ONE_DAY',
    THREE_DAYS: 'THREE_DAYS',
    SEVEN_DAYS: 'SEVEN_DAYS',
    FOURTEEN_DAYS: 'FOURTEEN_DAYS',
    ONE_MONTH: 'ONE_MONTH',
    THREE_MONTHS: 'ONE_MONTHS',
    SIX_MONTHS: 'SIX_MONTHS',
};

const PERIOD_OPTIONS = [
    {
        value: TARGETS_UI_PERIOD_OPTION_TYPES.ONE_DAY,
        id: TARGETS_UI_PERIOD_OPTION_TYPES.ONE_DAY,
        size: 1,
        unit: 'day',
        number: 24,
        step: 180,
        resolution: 30,
        colWidth: 163,
        visibleItems: 7,
        visibleItemsResolution: 47,
    },
    {
        value: TARGETS_UI_PERIOD_OPTION_TYPES.THREE_DAYS,
        id: TARGETS_UI_PERIOD_OPTION_TYPES.THREE_DAYS,
        size: 3,
        unit: 'days',
        number: 72,
        step: 360,
        resolution: 60,
        colWidth: 108.9,
        visibleItems: 11,
        visibleItemsResolution: 71,
    },
    {
        value: TARGETS_UI_PERIOD_OPTION_TYPES.SEVEN_DAYS,
        id: TARGETS_UI_PERIOD_OPTION_TYPES.SEVEN_DAYS,
        size: 7,
        unit: 'days',
        number: 168,
        step: 720,
        resolution: 180,
        colWidth: 93,
        visibleItems: 13,
        visibleItemsResolution: 55,
    },
    {
        value: TARGETS_UI_PERIOD_OPTION_TYPES.FOURTEEN_DAYS,
        id: TARGETS_UI_PERIOD_OPTION_TYPES.FOURTEEN_DAYS,
        size: 14,
        unit: 'days',
        number: 336,
        step: 720,
        resolution: 360,
        colWidth: 93,
        visibleItems: 27,
        visibleItemsResolution: 55,
    },
];

const CAT_SKU = 'Sku';
const CAT_ASSET = 'Asset';

export const TargetsUIConstants = {
    ACTIONS: {
        TARGETS_SET_UI_STORE: 'TARGETS_SET_UI_STORE',
    },
    DEFAULT_PERIOD_OPTION: PERIOD_OPTIONS[0],
    PERIOD_OPTIONS,
    PERIOD_OPTIONS_MAP: getMapFromArr(PERIOD_OPTIONS, 'id'),
    CAT_SKU,
    CAT_ASSET,
    FILTER_CATEGORIES: [
        { value: CAT_ASSET, label: CAT_ASSET },
        { value: CAT_SKU, label: CAT_SKU },
    ],
};
