import { values } from 'lodash';
import { all, put, call, takeLatest } from 'redux-saga/effects';

// ACTION
import {
    addBlocksIssuesResource,
    addBlocksResource,
    setBlocksState,
} from './Blocks.action';

// MODEL
import { Block, parseBlockArguments } from '../../../legacy/models';

// BLOCK SERVICES
import {
    api_getBlocksIssues,
    api_createBlock,
    api_deleteBlock,
    api_getBlocks,
    api_updateBlocks,
    api_updateBlocksIssues,
    api_createBlockUsers,
    api_deleteBlockUsers,
    api_getBlockIssues,
} from './Blocks.services';

// HELPERS
import { getMapFromArr, flattenTree } from '../../../legacy/utils/helpers';
import { getBlocksIssuesExclusionsState } from '../../../legacy/utils/blocks';

// CONSTANTS
import { BlocksConstants as K } from './Blocks.constants';

// REDUX STORE
import { store } from '../..';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { deleteSummaryTileSuccess } from '../Tiles/Tiles.action';
import { RemoveWidgetsResource } from '../Widgets/Widget.action';
import { entityBlock } from './Blocks.selector';
import { currentEntitySelector } from '../Entity/Entity.selector';

// GET BLOCKS SERVICE
function* handleGetBlocks(action) {
    try {
        const response = yield call(
            api_getBlocks,
            action.payload.entity_id
        );

        const blocksArray = flattenTree(response[0], b => b.children)
            .map((b) => new Block(...parseBlockArguments(b)));
        
        const blocks = getMapFromArr(blocksArray, 'block_id');
        yield put(setBlocksState({ blocks }))

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getBlocksSaga() {
    yield takeLatest(
        K.ACTIONS.GET_BLOCKS_REQUEST,
        handleGetBlocks
    );
}

// CREATE BLOCK SERVICE
function* handleCreateBlock(action) {
    try {
        const appState = store.getState();
        const rootBlock = entityBlock(appState);
        const parent_block_id = rootBlock.block_id;
        const order = rootBlock.children.length + 1;

        const blockParams = {
            ...action.payload.block,
            order,
            parent_block_id,
        };

        const res = yield call(api_createBlock, action.payload.entity_id, blockParams);

        const block = new Block(...parseBlockArguments(res));

        yield put(addBlocksResource({ [block.block_id]: block }));

        flash({ message: 'Block created.' });

        if (action.callback) {
            yield action.callback(block);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createBlockSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_BLOCK_REQUEST,
        handleCreateBlock
    );
}

// UPDATE BLOCKS
function* handleUpdateBlocks(action) {
    try {
        const { payload, callback } = action;

        const res = yield call(api_updateBlocks, payload.entity_id, payload.blocks);

        const blocks = res
            .reduce((acc, curr) => acc.concat(flattenTree(curr)), [])
            .map((b) => new Block(...parseBlockArguments(b)));

        yield put(addBlocksResource({ ...getMapFromArr(blocks, 'block_id') }));

        flash({ message: 'Blocks updated.' });

        if (callback) callback(blocks);
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateBlocksSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_BLOCKS_REQUEST,
        handleUpdateBlocks
    );
}

// UPDATE SINGLE BLOCK
function* handleUpdateBlock(action) {
    try {
        const res = yield call(api_updateBlocks, action.payload.block);

        const updated = new Block(...parseBlockArguments(res));

        yield put(addBlocksResource({ [updated.block_id]: updated }));

        flash({ message: 'Block updated.' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateSingleBlockSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_BLOCK_REQUEST,
        handleUpdateBlock
    );
}

function* handleDeleteBlock(action) {
    try {
        const appState = store.getState();
        const {
            tiles: { summary_tiles },
        } = appState;

        const { block_id } = action.payload.block;

        const res = yield call(api_deleteBlock, action.payload.entity_id, block_id);
        const updateSet = getMapFromArr(
            flattenTree(res, (node) => node.children).map(
                (b) => new Block(...parseBlockArguments(b))
            ),
            'block_id'
        );

        const { blocks: blocksState } = appState.blocks;
        const newState = { ...blocksState, ...updateSet };
        delete newState[block_id];

        yield put(setBlocksState({ blocks: newState }));

        const deleteSummaryTiles = values(summary_tiles)
            .filter((st) => block_id === st.block_id)
            .map(({ summary_tile_id }) => summary_tile_id);

        const deleteWidgetIds = deleteSummaryTiles.map((id) => `st_${id}`);

        yield all([
            put(RemoveWidgetsResource(deleteWidgetIds)),
            put(deleteSummaryTileSuccess(deleteSummaryTiles)),
        ]);

        flash({ message: 'Block deleted.' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteBlockSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_BLOCK_REQUEST,
        handleDeleteBlock
    );
}

function* handleGetBlockIssues(action) {
    try {
        const { entity_id, block_id } = action.payload;

        const {
            data: { blkIssues },
        } = yield call(api_getBlockIssues, entity_id, block_id);

        let blocks_issues_exclusions = {};

        if (blkIssues) {
            blocks_issues_exclusions = getBlocksIssuesExclusionsState(blkIssues);
        }

        yield put(addBlocksIssuesResource(blocks_issues_exclusions));

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* getBlockIssuesSaga() {
    yield takeLatest(
        K.ACTIONS.GET_BLOCK_ISSUES_REQUEST,
        handleGetBlockIssues
    );
}

let fetchedBlocksIssues = false;
function* handleGetBlocksIssues(action) {
    try {
        if (fetchedBlocksIssues) return;

        const {
            data: { blkIssues, blocks: _blocks },
        } = yield call(api_getBlocksIssues, action.payload.entity_id);

        let blocks_issues_exclusions = {};

        if (blkIssues) {
            blocks_issues_exclusions = getBlocksIssuesExclusionsState(blkIssues);
        }

        yield put(setBlocksState({ blocks_issues_exclusions }));
        fetchedBlocksIssues = true;

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* getBlocksIssuesSaga() {
    yield takeLatest(
        K.ACTIONS.GET_BLOCKS_ISSUES_REQUEST,
        handleGetBlocksIssues
    );
}

function* handleUpdateBlocksIssues(action) {
    try {
        const {
            data: { blkIssues },
        } = yield api_updateBlocksIssues(action.payload.entity_id, action.payload.blocks_issues_exclusions);

        let blocks_issues_exclusions = {};

        if (blkIssues) {
            blocks_issues_exclusions = getBlocksIssuesExclusionsState(blkIssues);
        }

        yield put(setBlocksState({ blocks_issues_exclusions }));

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateBlocksIssuesSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_BLOCKS_ISSUES_REQUEST,
        handleUpdateBlocksIssues
    );
}

function* handleUpdateBlockUsers(action) {
    try {
        const { create, remove, block_id, entity_id } = action.payload;

        let response;

        if (create.length) {
            response = yield call(api_createBlockUsers, entity_id, block_id, {
                users: create,
            });
        }

        if (remove.length) {
            response = yield call(api_deleteBlockUsers, entity_id, block_id, {
                user_block_ids: remove,
            });
        }

        const updated = new Block(...parseBlockArguments(response));
        yield put(addBlocksResource({ [updated.block_id]: updated }));

        flash({ message: 'Person(s) in charge updated!', status: 'success' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateBlockUsersSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_BLOCK_USERS_REQUEST,
        handleUpdateBlockUsers
    );
}
