import VerticalVirtualizeComponent from './VerticalVirtualize';
import HorizontalVirtulizeComponent from './HorizontalVirtualize';
import MultiVirtulizeComponent from './MultiVirtualize';
import HorizontalVirtulizeNoScrollComponent from './HorizontalVirtualize.noscroll';
import MultiVirtulizeComponentNoScrollComponent from './MultiVirtualize.noscroll';
import VerticalVirtualizeNoScrollComponent from './VerticalVirtualize.noscroll';

export const VerticalVirtualize = VerticalVirtualizeComponent;
export const HorizontalVirtualize = HorizontalVirtulizeComponent;
export const MultiVirtualize = MultiVirtulizeComponent;
export const HorizontalVirtualizeNoScroll = HorizontalVirtulizeNoScrollComponent;
export const MultiVirtualizeNoScroll = MultiVirtulizeComponentNoScrollComponent;
export const VerticalVirtualizeNoScroll = VerticalVirtualizeNoScrollComponent;
