/* eslint-disable react/prop-types */
import React from 'react';
import AukTooltip from '../../components/AukTooltip';
import Navigator from './components/Navigator';
import TrendRow from './components/TrendRow';
import { saveCsv } from '../../utils/dataExports';
import { OEELegend } from '../../Charts';
import { SPAWrapper } from '../../components/SPAWrapper';
import { TrendConstants as K } from '../../../store/old/UI/Trend/TrendUI.constants';
import { RolePermission } from '../../components/Permission';

import trendCSV from './TrendAnalysis.csv_exporter';

import './Trend.scss';

import VList from '../../components/VList';
import AukButton from '../../components/AukButton';
import translate from '../../utils/translate';

export default class TrendAnalysis extends React.Component {
    constructor(props) {
        super(props);
    }

    get tabs() {
        const { trendStore } = this.props;

        const isActive = (m) => trendStore.metric.id === m.id;

        return (
            <>
                {K.METRICS.map((m, i) => (
                    <AukButton.Outlined
                        key={m.id}
                        className="text-uppercase"
                        type={isActive(m) ? 'primary' : 'default'}
                        ghost={isActive(m)}
                        onClick={() => this.props.handleClickMetricDisplay(m)}
                        style={{ fontSize: 12, width: 120 }}
                    >
                        {m.value}
                    </AukButton.Outlined>
                ))}
            </>
        );
    }

    download() {
        const {
            trendStore: { rows, data },
            assets,
            oeeMasks,
        } = this.props;

        saveCsv(trendCSV(assets, rows, data, oeeMasks), 'trend_export.csv');
    }

    render() {
        const { rows, data } = this.props.trendStore;

        return (
            <SPAWrapper className="trend-analysis">
                <div className="trend-header">
                    {this.tabs}
                    <RolePermission accessLevel="editor">
                        <AukTooltip.Help title={translate('export')}>
                            <AukButton.Outlined
                                className="auk-button--round ml-2"
                                onClick={() => this.download()}
                            >
                                <i className="fas fa-download" />
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                    </RolePermission>
                </div>
                <div className="trend-body d-flex flex-row">
                    <div className="trend-rows--wrapper">
                        <div className="trend-rows d-flex flex-column">
                            <VList
                                rowHeight={140}
                                rowCount={rows.length}
                                data={rows}
                                rowRenderer={({ key, index, style }) => {
                                    const row = rows[index];
                                    const d = data[row.uuid];
                                    return (
                                        <div className="py-1" key={key} style={style}>
                                            {d ? (
                                                <TrendRow
                                                    key={row.uuid}
                                                    row={row}
                                                    data={d}
                                                    arrayAsset={this.props.arrayAssets}
                                                    assets={this.props.assets}
                                                    oee2={this.props.oee2}
                                                    handleUpdate={this.props.handleUpdateRow}
                                                    handleRemove={this.props.handleRemoveRow}
                                                />
                                            ) : null}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                        <div className="legend-wrapper mt-3">
                            <OEELegend />
                        </div>
                    </div>
                    <Navigator {...this.props} />
                </div>
            </SPAWrapper>
        );
    }
}
