import "./style.scss"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"
import { FormControl, FormHelperText, IconButton, useMediaQuery, useTheme } from "@mui/material"
import Popover from "@mui/material/Popover"
import moment from "moment"
import React, { forwardRef, JSXElementConstructor, useEffect, useImperativeHandle, useState } from "react"
import DateRangeInput, { DisplayType } from "../DateRangeInput/DateRangeInput"
import Grid from "../Grid/Grid"
import DayCalendar from "./DayCalendar/DayCalendar"
import MonthCalendar from "./MonthCalendar/MonthCalendar"
import { CalendarProps, DateRangePickerType, SelectingState } from "./types"

const DateRangePicker = forwardRef((props: Props, ref) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null)
    const [referenceDate, setReferenceDate] = useState(props.start?.clone() ?? moment().startOf("month"))
    const [selecting, setSelecting] = useState<SelectingState>("start")

    const open = Boolean(popoverRef)
    const [Calendar, displayType, addAmount] = getCalendarByType(props.type)

    useImperativeHandle(ref, () => ({ handleClose }), [])

    useEffect(() => {
        if (open) {
            setSelecting("start")
        }
    }, [open])

    useEffect(() => {
        if (props.onChange) {
            props.onChange(null, null)
        }

        if (props.onStartChange) {
            props.onStartChange(null)
        }

        if (props.onEndChange) {
            props.onEndChange(null)
        }
    }, [props.type])

    const handlePress = (e: React.MouseEvent<HTMLDivElement>) => {
        setPopoverRef(e.currentTarget)
    }

    const handleClose = () => {
        setPopoverRef(null)
    }

    const handlePrevious = () => {
        setReferenceDate(referenceDate.clone().subtract(1, addAmount))
    }

    const handleNext = () => {
        setReferenceDate(referenceDate.clone().add(1, addAmount))
    }

    const handleSelect = (value: moment.Moment) => {
        if (selecting === "start") {
            if (props.onChange) {
                props.onChange(value, null)
            }

            if (props.onStartChange) {
                props.onStartChange(value)
            }

            setSelecting("end")
            return
        }

        if (selecting === "end") {
            if (value.isBefore(props.start)) {
                if (props.onChange) {
                    props.onChange(value, props.start ?? null)
                }

                if (props.onStartChange) {
                    props.onStartChange(value)
                }

                setSelecting("start")
                handleClose()
                return
            }

            if (props.onChange) {
                props.onChange(props.start ?? null, value)
            }

            if (props.onEndChange) {
                props.onEndChange(value)
            }

            setSelecting("start")
            handleClose()
        }
    }

    const excludeDays = selecting === "start" ? props.excludeDaysStart : props.excludeDaysEnd
    return <FormControl className="date-range-picker" fullWidth>
        <DateRangeInput
            type={displayType}
            onPress={handlePress}
            start={props.start}
            end={props.end}
            placeholder={props.placeholder}
        />
        {props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
        <Popover
            open={open}
            anchorEl={popoverRef}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Grid container direction="row">
                <Grid item>
                    <Calendar
                        actions={{
                            left: <IconButton onClick={handlePrevious}><ArrowLeft /></IconButton>,
                            right: fullScreen && <IconButton onClick={handleNext}><ArrowRight /></IconButton>,
                        }}
                        onSelect={handleSelect}
                        referenceDate={referenceDate}
                        start={props.start}
                        end={props.end}
                        disablePast={props.disablePast}
                        disableFuture={props.disableFuture}
                        excludeDays={excludeDays ?? props.excludeDays}
                        selecting={selecting}
                    />
                </Grid>
                {!fullScreen && <Grid item className="date-range-calendar-right">
                    <Calendar
                        actions={{ right: <IconButton onClick={handleNext}><ArrowRight /></IconButton> }}
                        onSelect={handleSelect}
                        referenceDate={referenceDate.clone().add(1, addAmount)}
                        start={props.start}
                        end={props.end}
                        disablePast={props.disablePast}
                        disableFuture={props.disableFuture}
                        excludeDays={excludeDays ?? props.excludeDays}
                        selecting={selecting}
                    />
                </Grid>}
            </Grid>
        </Popover>
    </FormControl>
})

interface Props {
    placeholder?: string
    type: DateRangePickerType
    start?: Time.DateValue
    end?: Time.DateValue
    min?: moment.Moment
    max?: moment.Moment
    onChange?: (start: Time.DateValue, end: Time.DateValue) => void
    onStartChange?: (value: Time.DateValue) => void
    onEndChange?: (value: Time.DateValue) => void
    disablePast?: boolean
    disableFuture?: boolean
    excludeDays?: number[]
    excludeDaysStart?: number[]
    excludeDaysEnd?: number[],
    ref?: React.Ref<any>
    error?: boolean
    helperText?: string
    onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined
}

const getCalendarByType = (type: DateRangePickerType): [JSXElementConstructor<CalendarProps>, DisplayType, "year" | "month"] => {
    if (type === DateRangePickerType.MONTH) {
        return [MonthCalendar, DisplayType.MONTH, "year"]
    }

    if (type === DateRangePickerType.DAY) {
        return [DayCalendar, DisplayType.DAY, "month"]
    }

    throw new Error("Calendar type is not supported")
}

export { DateRangePickerType }

DateRangePicker.displayName = "DateRangePicker"

export default DateRangePicker
