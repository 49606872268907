import "./style.scss"
import React from "react"
import BaseLink from "@mui/material/Link"

const Link = (props: Props) => {
    return <BaseLink href={props.to} onClick={props.onPress}>{props.children}</BaseLink>
}

interface Props {
    to?: string
    onPress?: () => void
    children: string
}

export default Link
