import { makeRequest } from '../Shared/Shared.action';

export const api_getCorrelations = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/correlation`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createCorrelation = (entity_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/correlation`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// export const api_updateCorrelation = (entity_id, correlation_id, data) => {
//     return new Promise(
//         (resolve, reject) => {
//             makeRequest({
//                 path: `v1/entity/${entity_id}/correlation/${correlation_id}`,
//                 method: 'PUT',
//                 params: JSON.stringify(data),
//                 success: resolve,
//                 error: reject,
//             });
//         }
//     );
// };

export const api_deleteCorrelation = (entity_id, correlation_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/correlation/${correlation_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};
