/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';

const PortalWrapper = (props) => {
    const domNode = document.querySelector('body') || props.domNode;
    return ReactDOM.createPortal(props.children, domNode);
};

export default PortalWrapper;
