import { all, call, put, takeLatest, take } from 'redux-saga/effects';
import { has, isEmpty } from 'lodash';

// SERVICES
import {
    api_updateAssetStandardTime,
    api_updateAsset,
    api_updateAssetChartOrder,
    api_getAssets,
    api_deleteAsset,
    api_createAsset,
    api_getAssetCharts,
    api_updateAssetChart,
    api_getAssetSkus,
    api_createAssetSku,
    api_deleteAssetSkus,
} from './Assets.services';
import {
    api_createEntityPreference,
    api_getAssetOperatorPreferences,
    api_updateEntityPreference,
} from '../Preference/Preference.services';
import {
    parseAssetArguments,
    Asset,
    Fusion,
    parseFusionArguments,
    Metadatum,
    parseMetadatumArguments,
    parseBlockArguments,
    Block,
    Chart,
    parseChartArguments,
} from '../../../legacy/models';

// HELPERS
import { AssetsConstants as K } from './Assets.constants';
import { errorFlash, flash } from '../../../legacy/components/Flash';

// REDUX STORE
import { store } from '../..';
import {
    addAssetsResource,
    cascadeDeleteAsset,
    removeAssetsResource,
    addAssetsChartsResource,
    fetchAssetCharts,
    fetchAssetSkus,
    addAssetSkuResource,
    removeAssetChartsResource,
} from './Assets.action';
import { DeleteTilesState } from '../Tiles/Tiles.action';
import {
    AddMetadataResource,
    removeMetadataResource,
    setMetadataResource,
} from '../Metadata/Metadata.action';
import {
    addFusionsResource,
    removeFusionsResource,
    setFusionsResource,
} from '../Fusions/Fusions.action';
import { setAssetOperatorPreferences } from '../Preference/Preference.action';
import { removeDevicesResource } from '../Devices/Devices.action';
import {
    addBlocksResource,
    fetchBlocks,
    removeBlocksResource,
} from '../Blocks/Blocks.action';
import { RemoveWidgetsResource } from '../Widgets/Widget.action';
import { BlocksConstants } from '../Blocks/Blocks.constants';

function* handleUpdateAssetStandardTime(action) {
    try {
        const { payload } = action;

        // const data = Object.assign(
        //     {},
        //     has(payload.data, 'stdTime') ? { stdTime: payload.data.stdTime } : {},
        //     has(payload.data, 'autoUpdate') ? { autoUpdate: payload.data.autoUpdate } : {},
        //     has(payload.data, 'speed') ? { speed: payload.data.speed } : {}
        // );

        if (has(payload.data, 'stdTime') && payload.data.stdTime <= 0) {
            throw {
                message: 'The standard time should be greater than 0',
            };
        }

        const response = yield call(
            api_updateAssetStandardTime,
            action.payload.entity_id,
            action.payload.asset_id,
            action.payload.data
        );

        const { assets } = store.getState().assets;
        const _asset = assets[payload.asset_id];

        const updated = new Asset(
            ...parseAssetArguments({
                ..._asset,
                ...payload.data,
            })
        );

        const { _primaryChart } = updated;
        if (_primaryChart.fusion_id) {
            const fusion = new Fusion(
                ...parseFusionArguments({ ..._primaryChart.dataSource.serialize(), ...payload.data })
            );

            yield put(addFusionsResource({ [fusion.fusion_id]: fusion }));
        } else if (_primaryChart.metadata_id) {
            const metadatum = new Metadatum(
                ...parseMetadatumArguments({ ..._primaryChart.dataSource.serialize(), ...payload.data })
            );
            yield put(AddMetadataResource({ [metadatum.metadata_id]: metadatum }));
        }

        flash({
            message: `Update success`,
            status: 'success',
        });

        yield put(addAssetsResource({ [payload.asset_id]: updated }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetStandardTimeSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_STANDARD_TIME,
        handleUpdateAssetStandardTime
    );
}

function* handleCreateAsset(action) {
    try {

        const response = yield call(api_createAsset, action.payload.entity_id, action.payload.asset);
        const asset = new Asset(...parseAssetArguments(response.asset));
        const block = new Block(...parseBlockArguments(response.block));

        yield all([
            put(addAssetsResource({ [asset.asset_id]: asset })),
            put(addBlocksResource({ [block.block_id]: block })),
        ]);

        flash({ message: 'Asset created.' });
        if (action.callback) {
            return action.callback({ asset, block });
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createAssetSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET_REQUEST,
        handleCreateAsset
    );
}

function* handleUpdateAsset(action) {
    try {
        const { asset, entity_id } = action.payload;

        const response = yield call(api_updateAsset, entity_id, asset.asset_id, asset);
        const updatedAsset = new Asset(...parseAssetArguments(response));

        yield put(addAssetsResource({ [asset.asset_id]: updatedAsset }));

        flash({ message: 'Asset updated.' });

        if (action.callback) {
            return action.callback(updatedAsset);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET,
        handleUpdateAsset
    );
}

function* handleFetchAssetCharts(action) {
    try {
        const { asset_id, entity_id } = action.payload;

        const response = yield call(
            api_getAssetCharts,
            entity_id,
            asset_id
        );

        const charts = response.map(c => (new Chart(...parseChartArguments({...c, asset_id}))))


        yield put(addAssetsChartsResource({[asset_id]: charts}));

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* fetchAssetChartsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_CHARTS,
        handleFetchAssetCharts
    );
}

function* handleUpdateAssetChart(action) {
    try {
        yield call(
            api_updateAssetChart,
            action.payload.entity_id,
            action.payload.asset_id,
            action.payload.chart_id,
            action.payload.data
        );

        yield put(fetchAssetCharts(action.payload.entity_id, action.payload.asset_id));

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* updateAssetChartSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_CHART,
        handleUpdateAssetChart
    );
}

function* handleFetchAssetSkus(action) {
    try {
        const response = yield call(
            api_getAssetSkus,
            action.payload.entity_id,
            action.payload.asset_id,
        );

        const skus = response.map((s) => s.sku_id);

        yield put(addAssetSkuResource({ [action.payload.asset_id]: skus }))

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* fetchAssetSkusSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_SKUS,
        handleFetchAssetSkus
    );
}

function* handleCreateAssetSku(action) {
    try {
        yield call(
            api_createAssetSku,
            action.payload.entity_id,
            action.payload.asset_id,
            action.payload.sku_id,
        );

        yield put(fetchAssetSkus(action.payload.entity_id, action.payload.asset_id));

        flash({ message: 'Update success' });
        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* createAssetSkuSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET_SKU,
        handleCreateAssetSku
    );
}

function* handleDeleteAssetSku(action) {
    try {
        yield call(
            api_deleteAssetSkus,
            action.payload.entity_id,
            action.payload.asset_id,
            { skus: action.payload.sku_ids },
        );

        yield put(fetchAssetSkus(action.payload.entity_id, action.payload.asset_id));

        flash({ message: 'Update success' });

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* deleteAssetSkuSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_ASSET_SKU,
        handleDeleteAssetSku
    );
}

// function* handleDeleteAssetPure(action) {
//     try {
//         const { assets } = action.payload;
//         const deleteIds = assets.map((a) => a.asset_id);

//         const response = yield call(api_deleteAssetsPure, { assets: deleteIds });
//         const blocks = getMapFromArr(
//             response.blocks.map((b) => new Block(...parseBlockArguments(b))),
//             'block_id'
//         );

//         yield all([
//             put(removeAssetsResource(deleteIds)),
//             put(setBlocksState({ blocks })),
//         ]);

//         flash({ message: 'Asset deleted.' });

//         if (action.callback) {
//             return action.callback();
//         }
//         return;
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* handleDeleteAssetPureSaga() {
//     yield takeLatest(
//         K.ACTIONS.DELETE_ASSET_PURE,
//         handleDeleteAssetPure
//     );
// }

function* handleUpdateAssetChartOrders(action) {
    try {
        const { entity_id, asset_id, chart_orders } = action.payload;

        const response = yield call(api_updateAssetChartOrder, entity_id, asset_id, {
            chart_order: chart_orders,
        });

        const charts = response.map(c => (new Chart(...parseChartArguments({...c, asset_id}))))

        yield put(addAssetsChartsResource({[asset_id]: charts}));

        if (action.callback) {
            return action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetChartOrdersSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_CHART_ORDERS,
        handleUpdateAssetChartOrders
    );
}

function* handleFetchAssetOperatorSettings(action) {
    try {
        const { asset_id, entity_id } = action.payload;

        const preferences = yield call(
            api_getAssetOperatorPreferences,
            entity_id,
            asset_id
        );

        preferences.assets = preferences.assets.map((a) => a.asset_id);

        yield put(
            setAssetOperatorPreferences(!isEmpty(preferences) ? preferences : null)
        );

        if (action.callback) {
            action.callback({ preferences, skus });
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* fetchAssetOperatorSettingsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OPERATOR_SETTINGS,
        handleFetchAssetOperatorSettings
    );
}

function* handleUpdateAssetOperatorSettings(action) {
    try {
        const { asset_id, panels, alerts, preference_id, apply_to } = action.payload;

        const preferences = {
            panels,
            assets: [asset_id],
            alerts,
            apply_to,
        };

        const addOrEditPreference = preference_id
            ? api_updateEntityPreference
            : api_createEntityPreference;

        yield call(addOrEditPreference, preferences, preference_id)

        yield put(setAssetOperatorPreferences({ ...preferences, preference_id }))

        if (action.callback) {
            action.callback({ preferences });
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetOperatorSettingsSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_OPERATOR_SETTINGS,
        handleUpdateAssetOperatorSettings
    );
}

function* handleFetchAssetsProduction(action) {
    try {
        const { entity_id, query } = action.payload;
        const response = yield call(api_getAssets, entity_id, query);

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchAssetsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSETS_PRODUCTION_REQUEST,
        handleFetchAssetsProduction
    );
}

// DELETE ASSETS
function* handleDeleteAsset(action) {
    try {
        const { asset_id, entity_id } = action.payload;

        const {
            assets: { assets },
        } = store.getState();

        const asset = assets[asset_id];
        const response = yield api_deleteAsset(entity_id, asset_id);
        yield put(cascadeDeleteAsset(asset));

        yield put(fetchBlocks(entity_id))
        yield take(BlocksConstants.ACTIONS.SET_BLOCKS_STATE)

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
    }
}

export function* deleteAssetSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_ASSET,
        handleDeleteAsset
    );
}

function* handleCascadeDeleteAsset(action) {
    try {
        const { asset } = action.payload;
        const { tile, block, metadata, fusions, devices, asset_id } = asset;
        
        if (tile) {
            yield all([
                put(RemoveWidgetsResource([`t_${tile.tile_id}`])),
                put(DeleteTilesState([tile.tile_id]))
            ]);
        }

        yield all([
            put(removeAssetsResource([asset_id])),
            put(removeBlocksResource([block.block_id])),
            put(removeDevicesResource(devices.map(({ device_id }) => device_id))),
            put(removeMetadataResource(metadata.map(({ metadata_id }) => metadata_id))),
            put(removeFusionsResource(fusions.map(({ fusion_id }) => fusion_id))),
            put(removeAssetChartsResource(asset_id))
        ]);
    } catch (e) {
        errorFlash({
            details: e.message,
            message: e.error,
        });
    }
}

export function* watchCascadeDeleteAssetSaga() {
    yield takeLatest(
        K.ACTIONS.CASCADE_DELETE_ASSET,
        handleCascadeDeleteAsset
    );
}
