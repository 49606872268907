import classNames from "classnames"
import React from "react"

const PageTitle = (props: Props) => {
    return <div className={classNames("page-title", props.className)}>{props.children}</div>
}

interface Props {
    children: React.ReactNode
    className?: string
}

export default PageTitle
