/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import { Treebeard, decorators } from 'react-treebeard';

import './BlocksNavigator.scss';

const CustomHeader = (props) => {
    const { style, node, isTerminal } = props;

    return (
        <div className="blocks-navigator__treebeard-header" style={style}>
            <div
                className="blocks-navigator__treebeard-toggle__icon"
                key={isTerminal}
            >
                <span
                    className="d-flex justify-content-center align-items-center mx-2"
                    style={{ fontSize: 10, color: '#222' }}
                >
                    {isTerminal ? (
                        <i className="fas fa-square" />
                    ) : (
                        <i className="fas fa-th-large" />
                    )}
                </span>
            </div>
            <div>{`${node.name}`}</div>
        </div>
    );
};
const CustomToggle = (props) => {
    const { style, node, onToggle, isTerminal } = props;

    const isExpanded = node.children && node.children.length && node.toggled;

    return (
        <div
            onClick={() => !isTerminal && onToggle(node)}
            className="blocks-navigator__treebeard-toggle"
            style={style}
        >
            {isTerminal ? null : (
                <div
                    className="blocks-navigator__treebeard-toggle__icon"
                    key={isExpanded}
                >
                    {isExpanded ? (
                        <i className="fas fa-angle-down" />
                    ) : (
                        <i className="fas fa-angle-right" />
                    )}
                </div>
            )}
        </div>
    );
};
const CustomContainer = (props) => {
    const { style, decorators, terminal, onToggle, node } = props;

    const isTerminal = terminal(node);

    return (
        <div
            className="blocks-navigator__treebeard-container d-flex flex-row"
            style={{ width: 'max-content' }}
        >
            <decorators.Toggle
                node={node}
                onToggle={onToggle}
                isTerminal={isTerminal}
                style={Object.assign({ width: 19 }, style && style.toggle)}
            />
            <decorators.Header
                node={node}
                style={style && style.header}
                isTerminal={isTerminal}
            />
        </div>
    );
};

const treestate = (node) => {
    if (!node.children || (node.children && !node.children.length)) {
        return {
            id: node.block_id,
            name: node.label,
            toggled: true,
            children: [],
            active: false,
            disable: false,
        };
    }

    const children = node.children.map((c) => treestate(c));

    return {
        id: node.block_id,
        name: node.label,
        toggled: true,
        children: children,
        active: false,
        disable: false,
    };
};

export default class BlocksNavigator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data ? treestate(this.props.data) : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: treestate(this.props.data) });
        }
    }

    toggle(node) {
        const { onChange } = this.props;
        node.toggled = !node.toggled;
        this.setState({ data: this.state.data }, () => {
            onChange && onChange({ expanded: this.expanded, node });
        });
    }

    checkTerminal(node) {
        const { id: block_id } = node;
        const { blocks } = this.props;

        return blocks[block_id] && blocks[block_id].asset;
    }

    get expanded() {
        const { data } = this.state;

        const traverseExpanded = (node) => {
            let result = [node.id];
            if (!node.toggled) return result;

            if (node.children) {
                result = node.children.reduce((acc, curr) => {
                    return acc.concat(...traverseExpanded(curr));
                }, result);
            }

            return result;
        };

        return traverseExpanded(data);
    }

    render() {
        const CustomDecorators = {
            Loading: decorators.Loading,
            Header: CustomHeader,
            Toggle: CustomToggle,
            Container: (props) => (
                <CustomContainer
                    {...props}
                    style={this.props.style}
                    onToggle={this.toggle.bind(this)}
                    terminal={this.checkTerminal.bind(this)}
                />
            ),
        };

        return (
            <div className="blocks-navigator--container">
                {this.state.data && (
                    <Treebeard data={this.state.data} decorators={CustomDecorators} />
                )}
            </div>
        );
    }
}
