/* eslint-disable react/prop-types */
import React, { useContext, useMemo } from 'react';
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Popconfirm, Popover, Tag } from 'antd';
import { capitalize } from 'lodash';
import { Gateway } from '../../models';
import Circle from '../../components/Circle';
import { Permission } from '../../components/Permission';
import { HardwareManagementContext } from './HardwareManagementContext';

const HardwareItem = (props) => {
    const { NODE_COLORS, NODE_TYPES, refreshNode, deleteRelay } = useContext(HardwareManagementContext);
    const {
        item,
        onClick,
        selected,
        health: { health, summary, warnings, icon },
    } = props;

    const { mac_address, device_id } = item;

    const type = useMemo(() => {
        if (item instanceof Gateway) return NODE_TYPES.GATEWAY;
        if (item.relay) return NODE_TYPES.RELAY;
        return NODE_TYPES.NODE;
    }, [item]);

    const isRelay = useMemo(() => type === NODE_TYPES.RELAY, [type]);

    const typeColor = useMemo(() => NODE_COLORS[type], [type]);

    const deviceType = useMemo(() => {
        if (type === NODE_TYPES.NODE)
            return item.asset ? capitalize(item.asset.asset_name) : '-- Unlinked --';
        return capitalize(type);
    }, [item, type]);

    const TypeCircle = useMemo(() => <Circle color={typeColor} />, [type]);

    const PopoverTitle = (
        <div
            className="d-flex align-items-center"
            style={{ fontSize: 12, fontWeight: 400 }}
        >
            {icon}
            <div className="ml-2">
                <Tag color={typeColor}>{mac_address}</Tag>
            </div>
        </div>
    );

    const PopoverContent = warnings.length ? (
        <div>
            <p style={{ color: health }}>{summary}</p>
            <ul className="m-0" style={{ fontSize: 11, paddingInlineStart: 16 }}>
                {warnings.map((warning, i) => (
                    <li key={i}>{warning}</li>
                ))}
            </ul>
        </div>
    ) : null;

    return (
        <div
            className={`list-item px-3 ${selected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <div className="h-100 d-flex align-items-center justify-content-center">
                {TypeCircle}
            </div>
            <div className="list-item__content">
                <pre className="list-item__content__type m-0 text-overflow-ellipsis">
                    {deviceType}
                </pre>
                <code className="list_item__content__label">{mac_address}</code>
            </div>
            <div className="d-flex align-items-center" style={{ color: '#616161' }}>
                <Popover
                    overlayClassName={`device-popover ${
                        warnings.length ? '' : 'no-warning'
                    }`}
                    content={PopoverContent}
                    title={PopoverTitle}
                    placement="right"
                    overlayStyle={{ width: 300 }}
                >
                    <div className="d-flex align-items-center" style={{ cursor: 'help' }}>
                        {icon}
                    </div>
                </Popover>
                <Permission forResource resource="radio_mesh" canDo="edit">
                    <SyncOutlined
                        className="mx-1"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            refreshNode(mac_address);
                        }}
                    />
                </Permission>

                <Permission forResource resource="radio_mesh" canDo="full">
                    <Popconfirm
                        title="Are you sure? This may affect mesh strength."
                        onConfirm={(e) => {
                            e.stopPropagation();
                            deleteRelay(device_id);
                        }}
                        okText="Yes"
                        cancelText="No"
                        disabled={!isRelay}
                    >
                        <DeleteOutlined
                            className="mx-1"
                            style={{
                                cursor: isRelay ? 'pointer' : 'default',
                                opacity: isRelay ? 1 : 0.5,
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                </Permission>
            </div>
        </div>
    );
};

export default HardwareItem;
