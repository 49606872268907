import UserModel from "./user.model"

interface ObjectiveModel {
    id: number
    blockId: number
    blockName: string
    userId: number
    user: Partial<UserModel>
    name: string
    startsAt: string
    endsAt: string
    unitOfTime: string
    createdAt: string
    updatedAt: string
    deletedAt: string
}

export const displayUnitOfTime = (type: string): string => {
    if (type === "months" || type === "month") {
        return "Monthly"
    }

    if (type === "weeks" || type === "week") {
        return "Weekly"
    }

    return "Unknown"
}

export default ObjectiveModel
