import "./style.scss"
import { Button } from "@mui/material"
import classNames from "classnames"
import React, { forwardRef, type MouseEventHandler } from "react"

const ActionButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (props.onPress) {
            e.preventDefault()
            void props.onPress()
        }
    }

    return <Button
        ref={ref}
        type={props.type ?? "button"}
        className={classNames("btn-action", props.className)}
        variant="contained"
        color="primary"
        size={props.size}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        onClick={handleClick}
    >
        {props.children}
    </Button>
})

interface Props {
    className?: string
    onPress?: () => (void | Promise<void>)
    children: React.ReactNode
    disabled?: boolean
    size?: "small" | "medium" | "large"
    type?: "submit" | "reset" | "button" | undefined
    autoFocus?: boolean
}

ActionButton.displayName = "ActionButton"

export default ActionButton
