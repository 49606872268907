/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TreeSelect } from 'antd';

import { assetsSelector } from '../../store/old/Assets/Assets.selector';
import { entityBlock } from '../../store/old/Blocks/Blocks.selector';
import { getBlocksTreeData } from '../utils/blocks';

import './BlocksTreeSelect.scss';

const BlocksTreeSelect = (props) => {
    const rootBlock = useSelector(entityBlock);
    const assets = useSelector(assetsSelector);

    const treeData = useMemo(
        () =>
            props.getTreeData
                ? props.getTreeData(rootBlock)
                : getBlocksTreeData(rootBlock),
        [rootBlock, assets]
    );

    const _props = { ...props };
    delete _props.getTreeData;

    return (
        <TreeSelect
            {..._props}
            className={`auk-blocks-select ${props.className || ''}`}
            treeData={[treeData]}
        />
    );
};

export default BlocksTreeSelect;
