import { updateStoreRedux } from '../../../legacy/utils/redux';
import { GatewayConstants as K } from './Gateway.constants';

const initialReducer = {
    gateways: {},
    statuses: {},
};

export const gatewaysReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.SET_GATEWAYS_RESOURCE) {
        return updateStoreRedux(state, {
            gateways: action.payload.gateways,
        });
    }

    if (action.type === K.ACTIONS.ADD_GATEWAYS_RESOURCE) {
        return updateStoreRedux(state, {
            gateways: { ...state.gateways, ...action.payload.gateways },
        });
    }

    if (action.type === K.ACTIONS.SET_GATEWAYS_STATUS_RESOURCE) {
        return updateStoreRedux(state, {
            statuses: action.payload.statuses,
        });
    }

    if (action.type === K.ACTIONS.SET_GATEWAYS_STATUS_RESOURCE) {
        return updateStoreRedux(state, {
            statuses: { ...state.statuses, ...action.payload.statuses },
        });
    }

    return updateStoreRedux(state, {});
};
