import { updateStoreRedux } from '../../../legacy/utils/redux';
import { CorrelationConstants as K } from './Correlations.constants';

const initialReducer = {
    correlations: {},
    error: {},
};

export const correlationsReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.FETCH_CORRELATIONS_REQUEST_SUCCESS) {
        return updateStoreRedux(state, {
            correlations: { ...action.payload },
        });
    }

    if (
        action.type === K.ACTIONS.CREATE_CORRELATION_REQUEST_SUCCESS ||
    action.type === K.ACTIONS.UPDATE_CORRELATION_REQUEST_SUCCESS
    ) {
        const { correlation } = action.payload;
        return updateStoreRedux(state, {
            correlations: {
                ...state.correlations,
                [correlation.correlation_id]: correlation,
            },
        });
    }

    if (action.type === K.ACTIONS.DELETE_CORRELATION_REQUEST_SUCCESS) {
        const clonedState = { ...state.correlations };
        delete clonedState[action.payload.correlation_id];
        return updateStoreRedux(state, {
            correlations: clonedState,
        });
    }

    return updateStoreRedux(state, {});
};
