/* eslint-disable react/prop-types */
// LIBRARIES
import * as d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { Button,  Input, Modal } from 'antd';
import { connect } from 'react-redux';

// COMPONENTS
import Avatar from '../../../components/Avatar';
import AukTooltip from '../../../components/AukTooltip';
import ImageInput from '../../../components/ImageInput';
import MultiTextInputGroup from '../../../components/MultiTextInputGroup';
import PriorityIcon from './PriorityIcon';
import { ColumnChart } from '../../../Charts/v1';
import { Permission } from '../../../components/Permission';

// HELPERS
import { uiDateFormat, uiDatetimeFormat } from '../../../utils/helpers';
import { generateContinuousDomain } from '../../../utils/charts';

// SERVICES
import { api_uploadTrackedIssueMedia } from '../../../../store/old/Images/Images.services';

// ACTIONS
import { updateTrackedIssueRequestSuccess } from '../../../../store/old/TrackedIssues/TrackedIssues.action';

// MODEL
import {
    DELETED_USER,
    parseTrackedIssueArguments,
    TrackedIssue,
} from '../../../models';

import './Slider.scss';
import { errorFlash, flash } from '../../../components/Flash';
import { DangerZoneItem } from '../../../components/DangerZone';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AukButton from '../../../components/AukButton';

const { confirm } = Modal;

class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionEditorActive: false,
            rootCauseEditorActive: false,
            actions: [],
            rootCause: '',
            message: '',
        };

        this.handleClickEditAction = this.handleClickEditAction.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleCreateComment = this.handleCreateComment.bind(this);
        this.updateActions = this.updateActions.bind(this);
        this.imageUpload = this.imageUpload.bind(this);

        this.handleClickEditRootCause = this.handleClickEditRootCause.bind(this);
        this.handleCancelRootCauseEditor =
      this.handleCancelRootCauseEditor.bind(this);
        this.updateRootCause = this.updateRootCause.bind(this);

        this.handleCancelActionEditor = this.handleCancelActionEditor.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({ actionEditorActive: false });
        }
    }

    get actions() {
        return this.state.actions.filter((s) => s.trim().length > 0);
    }

    handleClickEditRootCause() {
        const { selection } = this.props.data;

        if (this.state.rootCauseEditorActive) return;

        this.setState({
            rootCauseEditorActive: true,
            rootCause: selection.root_cause || '',
        });
    }

    handleCancelRootCauseEditor() {
        this.setState({
            rootCauseEditorActive: false,
        });
    }

    handleClickEditAction() {
        const { selection } = this.props.data;

        if (this.state.actionEditorActive) return;

        this.setState({
            actionEditorActive: true,
            actions:
        selection.actions && selection.actions.length
            ? selection.actions
            : [''],
        });
    }

    handleCancelActionEditor() {
        const { selection } = this.props.data;

        this.setState({
            actionEditorActive: false,
            actions:
        selection.actions && selection.actions.length
            ? selection.actions.concat()
            : [''],
        });
    }

    handleChangeMessage(e) {
        const {
            target: { value },
        } = e;

        if (value.length > 500) {
            return flash({
                message: 'Messages have a 500 character limit',
                status: 'error',
            });
        }

        this.setState({ message: value });
    }

    handleCreateComment() {
        const { message } = this.state;
        const { selection } = this.props.data;

        this.props.addComment(selection, message, () =>
            this.setState({ message: '' })
        );
    }

    updateActions() {
        const {
            props: {
                data: {
                    selection: { tracked_issue_id },
                },
            },
            actions,
        } = this;

        const obj = {
            tracked_issue_id,
            actions,
        };

        this.props.update(obj, this.handleCancelActionEditor);
    }

    updateRootCause() {
        const {
            props: {
                data: {
                    selection: { tracked_issue_id },
                },
            },
            state: { rootCause },
        } = this;

        const obj = {
            tracked_issue_id,
            root_cause: rootCause,
        };

        this.props.update(obj, this.handleCancelRootCauseEditor);
    }

    imageUpload(result) {
        const {
            props: {
                data: { selection },
            },
        } = this;

        api_uploadTrackedIssueMedia(selection.tracked_issue_id, result)
            .then((res) => {
                const temp = selection.clone();
                temp.tracked_media = temp.tracked_media.concat(res);
                this.props.updateTrackedIssuesState(
                    new TrackedIssue(...parseTrackedIssueArguments(temp))
                );
            })
            .catch((e) => errorFlash(e));
    }

    get firstComponent() {
        return (
            <div className="w-100 p-2">
                {this.reporter}
                {this.rootCause}
                {this.actionEditor}
                {this.occurrenceChart}
            </div>
        );
    }

    get secondComponent() {
        return (
            <div
                className="w-100 p-2"
                style={{
                    height: '40%',
                    overflow: 'auto',
                }}
            >
                {this.commentsSummary}
                {this.comments}
                <Permission resource="tracked_issues" forResource canDo="edit">
                    {this.commentsEditor}
                </Permission>
            </div>
        );
    }

    get thirdComponent() {
        return (
            <div className="p-2" style={{ position: 'relative' }}>
                {this.mediaSummary}
                {this.mediaGrid}
            </div>
        );
    }

    get title() {
        if (!this.props.data.selection) return '';
        const { title, priority } = this.props.data.selection;

        return (
            <div className="">
                <div className="d-flex">
                    <AukTooltip.Help title="Priority">
                        <span className="mr-3 py-3" style={{ flexShrink: 0 }}>
                            <PriorityIcon priority={priority} />
                        </span>
                    </AukTooltip.Help>
                    <span
                        className="d-flex align-items-center"
                        style={{ flexGrow: 1, fontWeight: 500 }}
                    >
                        {title}
                    </span>
                </div>
                <div>{this.updateButtons}</div>
            </div>
        );
    }

    render() {
        const { selection } = this.props.data;
        return (
            <>
                <div className="tracked-issue-drawer__header p-3">{this.title}</div>
                <div className="d-flex flex-column justify-content-between slide-content-container">
                    {selection && (
                        <>
                            {this.firstComponent}
                            <hr />
                            {this.secondComponent}
                            <hr />
                            {this.thirdComponent}
                            <hr />
                            <div className="p-2 m-3">
                                <DangerZoneItem
                                    title="Delete"
                                    description="Stop tracking this issue"
                                    button={
                                        <AukButton.Red
                                            size="small"
                                            onClick={() => {
                                                confirm({
                                                    title: `Delete tracked issue`,
                                                    icon: <ExclamationCircleOutlined />,
                                                    content: 'This action is not reversible, continue?',
                                                    onOk: () => {
                                                        this.props.remove(selection);
                                                    },
                                                });
                                            }}
                                        >
                                            Delete
                                        </AukButton.Red>
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }

    get updateButtons() {
        const {
            data: {
                selection,
                selection: { close_date },
            },
            handleClickEdit,
            handleClickResolve,
            handleClickReopen,
        } = this.props;
        const _buttons = !close_date
            ? [
                {
                    label: 'edit',
                    onClick: () => handleClickEdit(selection),
                },
                {
                    props: { type: 'primary' },
                    label: 'resolve',
                    onClick: () => handleClickResolve(selection),
                },
            ]
            : [
                {
                    label: 'edit',
                    onClick: () => handleClickEdit(selection),
                },
                {
                    props: { danger: true },
                    label: 'reopen',
                    onClick: () => handleClickReopen(selection),
                },
            ];

        return (
            <div className="d-flex">
                {_buttons.map((btn, idx) => {
                    return (
                        <Permission
                            key={idx}
                            resource="tracked_issues"
                            forResource
                            canDo="edit"
                        >
                            <Button
                                {...btn.props}
                                key={btn.label}
                                onClick={btn.onClick}
                                className="mr-2"
                            >
                                <span className="text-uppercase">{btn.label}</span>
                            </Button>
                        </Permission>
                    );
                })}
            </div>
        );
    }

    get rootCause() {
        return (
            <div
                className="d-flex flex-column m-3"
                style={{ maxHeight: 200, overflow: 'auto' }}
            >
                <div className="mb-1" style={{ fontWeight: 600 }}>
          Root Cause
                    <Permission resource="tracked_issues" forResource canDo="edit">
                        <span className="ml-2" onClick={this.handleClickEditRootCause}>
                            <i className="fas fa-edit" />
                        </span>
                    </Permission>
                </div>
                {this.state.rootCauseEditorActive ? (
                    <div className="d-flex flex-column">
                        <Input.TextArea
                            className="mb-2"
                            style={{ maxHeight: 200, overflow: 'auto' }}
                            value={this.state.rootCause}
                            onChange={(e) => this.setState({ rootCause: e.target.value })}
                        />
                        <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                            <Button onClick={this.handleCancelRootCauseEditor}>Cancel</Button>
                            <Button type="primary" onClick={this.updateRootCause}>
                Update
                            </Button>
                        </div>
                    </div>
                ) : (
                    this.props.data.selection.root_cause || 'No root cause identified'
                )}
            </div>
        );
    }

    get actionEditor() {
        const { selection } = this.props.data;

        const form = (
            <>
                <MultiTextInputGroup
                    inputProps={{ autoSize: true }}
                    data={this.state.actions}
                    handleChange={(val) => this.setState({ actions: val })}
                />
                <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={this.handleCancelActionEditor}>Cancel</Button>
                    <Button type="primary" onClick={() => this.updateActions()}>
            Update
                    </Button>
                </div>
            </>
        );

        const actions = (
            <div>
                {selection.actions && selection.actions.length ? (
                    <ol>
                        {selection.actions.map((action, idx) => (
                            <li key={idx}>{action}</li>
                        ))}
                    </ol>
                ) : (
                    'No actions defined'
                )}
            </div>
        );

        return (
            <div className="row flex-column m-3">
                <div className="mb-1" style={{ fontWeight: 600 }}>
          Actions Taken
                    <Permission resource="tracked_issues" forResource canDo="edit">
                        <span className="ml-2" onClick={this.handleClickEditAction}>
                            <i className="fas fa-edit" />
                        </span>
                    </Permission>
                </div>
                {this.state.actionEditorActive ? form : actions}
            </div>
        );
    }

    get occurrenceChart() {
        const {
            data: { occurrences },
        } = this.props;

        const count = occurrences.reduce((acc, curr) => (acc += curr.val), 0);

        return (
            <div className="d-flex flex-column m-3">
                <div style={{ fontWeight: 600 }}>
          Occurred {` ${count} `} times in the last 30 days
                </div>
                <OccurrenceChart data={occurrences} />
            </div>
        );
    }

    get reporter() {
        const {
            selection: { reporter, created_at, description },
        } = this.props.data;

        if (!reporter) return;

        return reporter ? (
            <div>
                <CommentRow
                    user={reporter}
                    timestamp={created_at}
                    activity={<i className="ml-1">started tracking</i>}
                    content={
                        <div
                            className="d-flex flex-column w-100"
                            style={{ maxHeight: 200, overflow: 'auto' }}
                        >
                            <div>{description}</div>
                        </div>
                    }
                />
                <hr />
            </div>
        ) : null;
    }

    get commentsSummary() {
        const {
            data: { comments },
        } = this.props;
        return (
            <div className="row mt-3 mr-3 ml-3 mb-0" style={{ fontWeight: 600 }}>
        Comments ({comments ? comments.length : 0})
            </div>
        );
    }

    get comments() {
        const {
            data: { comments },
        } = this.props;
        return (
            <div
                className="mt-3 mr-3 ml-3 mb-0"
                style={{
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#fff',
                    maxHeight: 300,
                    overflow: 'auto',
                }}
            >
                {comments &&
          comments.map((d, idx) => (
              <CommentRow
                  key={idx}
                  user={d.user}
                  timestamp={d.timestamp}
                  content={d.message}
              />
          ))}
            </div>
        );
    }

    get commentsEditor() {
        return (
            <div className="row m-3">
                <Input
                    value={this.state.message}
                    addonAfter={
                        <div onClick={this.handleCreateComment}>
                            <i className="fas fa-caret-right fa-2x" />
                        </div>
                    }
                    onPressEnter={this.handleCreateComment}
                    onChange={this.handleChangeMessage}
                />
            </div>
        );
    }

    get mediaSummary() {
        return (
            <>
                <Permission resource="tracked_issues" forResource canDo="edit">
                    <Button
                        className="auk-button auk-button--round m-3"
                        style={{ position: 'absolute', right: 0 }}
                    >
                        <ImageInput icon="fas fa-plus" onChange={this.imageUpload} />
                    </Button>
                </Permission>

                <div className="row m-3" style={{ fontWeight: 600 }}>
          Images ({this.props.data.selection.tracked_media.length})
                </div>
            </>
        );
    }

    get mediaGrid() {
        return (
            <div
                className="row m-3 flex-row"
                style={{ flexWrap: 'wrap', overflow: 'auto' }}
            >
                {this.props.data.selection.tracked_media.map((m, idx) => {
                    return (
                        <div
                            key={idx}
                            className="m-2"
                            onClick={() => this.props.handleClickImage(m.signedUrl)}
                            style={{ cursor: 'pointer', borderRadius: 2 }}
                        >
                            <img
                                alt={''}
                                src={m.signedUrl}
                                style={{
                                    width: 50,
                                    height: 50,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

const CommentRow = (props) => {
    const { user = DELETED_USER, activity, timestamp, content } = props;
    return (
        <div className="d-flex m-3 comment-row">
            <Avatar key={user.user_id} user={user} withTooltip={false} />
            <div className="d-flex flex-column comment pl-1">
                <div className="row m-0 justify-content-between mb-2">
                    <div>
                        <span style={{ fontWeight: 600 }}>{user && user.nameFull}</span>{' '}
                        <span>{activity}</span>
                    </div>
                    <div>{moment(timestamp).format(uiDatetimeFormat)}</div>
                </div>
                <div
                    className="row w-100 m-0"
                    style={{ flexWrap: 'wrap', whiteSpace: 'break-spaces' }}
                >
                    {content}
                </div>
            </div>
        </div>
    );
};

const OccurrenceChart = ({ data }) => {
    const xAccessor = (d) => d.time;
    const yAccessor = (d) => d.val;
    const xDomain = [d3.min(data, xAccessor), d3.max(data, xAccessor)];

    return (
        <div className="w-100" style={{ height: 100 }}>
            <ColumnChart
                data={data}
                xScale={d3.scaleTime()}
                yScale={d3.scaleLinear()}
                xDomain={xDomain}
                yDomain={generateContinuousDomain(data, yAccessor)}
                xAccessor={xAccessor}
                yAccessor={yAccessor}
                useBrush={false}
                xTicks={4}
                yTicks={4}
                htmlTooltip={(d) => `${moment(d.time).format(uiDateFormat)}: ${d.val}`}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTrackedIssuesState: (trackedIssue) =>
            dispatch(updateTrackedIssueRequestSuccess(trackedIssue)),
    };
};

export default connect(null, mapDispatchToProps)(Slider);
