/* eslint-disable react/prop-types */
// LIBRARIES
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import Scheduler from './Scheduler';
import ModalPanel from '../../../components/ModalPanel';
import { PanelHeader, PanelBody, PanelFooter } from '../../../components/Panel';

// ACTIONS
import {
    createDowntime,
    createShift,
    updateDowntime,
    updateShift,
} from '../../../../store/old/Schedules/Schedules.action';

// CONSTANTS
import { SchedulesConstants as K } from '../../../../store/old/Schedules/Schedules.constants';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import translate from '../../../utils/translate';
import AukButton from '../../../components/AukButton';

const TYPES = [
    { id: 1, value: K.SHIFT, icon: 'fas fa-retweet' },
    { id: 2, value: K.DOWNTIME, icon: 'fas fa-stopwatch' },
];

class SchedulerPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = { type: '' };
    }

    componentDidMount() {
        const { data } = this.props;
        if (!data) return;

        this.setState({
            type: data.shift_id ? K.SHIFT : K.DOWNTIME,
        });
    }

    handleClick(item) {
        this.setState({ type: item.value });
    }

    get selectContent() {
        return (
            <ModalPanel className="schedule-panel">
                <PanelHeader>Create New Schedule</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    {TYPES.map((t, i) => {
                        return (
                            <div
                                key={i}
                                className="d-flex flex-column align-items-center justify-content-center mx-4"
                                onClick={() => this.handleClick(t)}
                                style={{
                                    width: 120,
                                    height: 100,
                                    cursor: 'pointer',
                                    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.75)',
                                }}
                            >
                                <div>
                                    <i style={{ fontSize: '2em' }} className={t.icon} />
                                </div>
                                <div style={{ textTransform: 'uppercase' }}>{t.value}</div>
                            </div>
                        );
                    })}
                </PanelBody>
                <PanelFooter className="p-3">
                    <AukButton.Cancel onClick={this.props.cancel}/>
                </PanelFooter>
            </ModalPanel>
        );
    }

    get form() {
        return (
            <Scheduler
                ref={(node) => (this.ref = node)}
                type={this.state.type}
                submit={this.submit}
                {...this.props}
            />
        );
    }

    get label() {
        const { data } = this.props;
        const { type } = this.state;

        const model = _.capitalize(type);
        if (!data && type) return `Create ${model}`;
        return `Update ${model}`;
    }

    get step() {
        if (!this.state.type) return 1;
        return 2;
    }

    get submit() {
        const { data } = this.props;
        const { type } = this.state;

        const isUpdate = !!data;

        let fn;
        if (!isUpdate && type === K.SHIFT) fn = this.props.createShift;
        if (isUpdate && type === K.SHIFT) fn = this.props.updateShift;
        if (!isUpdate && type === K.DOWNTIME) fn = this.props.createDowntime;
        if (isUpdate && type === K.DOWNTIME) fn = this.props.updateDowntime;

        return (formData) => fn(formData, () => this.props.cancel());
    }

    render() {
        return this.step === 1 ? this.selectContent : this.form;
    }
}

const mapStateToProps = (rootState) => {
    return {
        entity: currentEntitySelector(rootState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createShift: (data, callback) => dispatch(createShift(data, callback)),
        updateShift: (data, callback) => dispatch(updateShift(data, callback)),
        createDowntime: (data, callback) =>
            dispatch(createDowntime(data, callback)),
        updateDowntime: (data, callback) =>
            dispatch(updateDowntime(data, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerPanel);
