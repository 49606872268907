export const PermissionConstants = {
    ACTIONS: {
        GET_POLICIES_REQUEST: 'GET_POLICIES_REQUEST',
        GET_POLICIES_SUCCESS: 'GET_POLICIES_SUCCESS',
        GET_POLICIES_FAILURE: 'GET_POLICIES_FAILURE',
        GET_POLICY_DETAIL_REQUEST: 'GET_POLICY_DETAIL_REQUEST',
        GET_POLICY_DETAIL_SUCCESS: 'GET_POLICY_DETAIL_SUCCESS',
        GET_POLICY_DETAIL_FAILURE: 'GET_POLICY_DETAIL_FAILURE',
        CREATE_POLICY_REQUEST: 'CREATE_POLICY_REQUEST',
        CREATE_POLICY_SUCCESS: 'CREATE_POLICY_SUCCESS',
        CREATE_POLICY_FAILURE: 'CREATE_POLICY_FAILURE',
        UPDATE_POLICY_REQUEST: 'UPDATE_POLICY_REQUEST',
        UPDATE_POLICY_SUCCESS: 'UPDATE_POLICY_SUCCESS',
        UPDATE_POLICY_FAILURE: 'UPDATE_POLICY_FAILURE',
        DELETE_POLICY_REQUEST: 'DELETE_POLICY_REQUEST',
        DELETE_POLICY_SUCCESS: 'DELETE_POLICY_SUCCESS',
        DELETE_POLICY_FAILURE: 'DELETE_POLICY_FAILURE',
        APPLY_POLICY_REQUEST: 'APPLY_POLICY_REQUEST',
        APPLY_POLICY_SUCCESS: 'APPLY_POLICY_SUCCESS',
        APPLY_POLICY_FAILURE: 'APPLY_POLICY_FAILURE',
        GET_POLICY_RESOURCES_REQUEST: 'GET_POLICY_RESOURCES_REQUEST',
        GET_POLICY_RESOURCES_SUCCESS: 'GET_POLICY_RESOURCES_SUCCESS',
        GET_POLICY_RESOURCES_FAILURE: 'GET_POLICY_RESOURCES_FAILURE',
        RESET_PERMISSIONS_STORE: 'RESET_PERMISSIONS_STORE',
    },
};
