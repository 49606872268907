import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUserPreferencesRequest,
    updateUserPreferencesRequest,
} from '../../../store/old/Preference/Preference.action';
import { useLocation } from 'react-router';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import AukTooltip from '../../components/AukTooltip';
import { StarFilled, StarOutlined } from '@ant-design/icons';

const style = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    padding: '0px 6px'
};

const withHomeviewIcon = (Component) => {
    return function WrapperComponent(props) {
        const location = useLocation();
        const user = useSelector(userSelector);
        const { entity_id: currentEntityId } = useSelector(currentEntitySelector);
        const preference = useSelector((appState) => appState.preference.user);
        const dispatch = useDispatch();
        const { pathname } = location;

        const setFavourite = () => {
            const handler = preference && preference.preference_id
                ? updateUserPreferencesRequest
                : createUserPreferencesRequest;

            dispatch(handler({ default_view: pathname }));
        };

        const canSetHomeview = user.entity_id === currentEntityId;
        const isFavourited = pathname === (preference && preference.default_view);

        return (
            <div className="d-flex">
                <Component {...props} />{' '}
                {canSetHomeview ? (
                    <AukTooltip.Help
                        title="Set as home view"
                        placement="bottom"
                        mouseEnterDelay={0.5}
                    >
                        <div className="home-icon" style={style} onClick={setFavourite}>
                            {isFavourited ? (
                                <StarFilled style={{ color: '#ffc107' }} />
                            ) : (
                                <StarOutlined />
                            )}
                        </div>
                    </AukTooltip.Help>
                ) : null}
            </div>
        );
    };
};

export default withHomeviewIcon;
