import { CorrelationConstants as K } from './Correlations.constants';

export const fetchCorrelations = (entity_id) => {
    return {
        type: K.ACTIONS.FETCH_CORRELATIONS_REQUEST,
        payload: { entity_id }
    };
};

export const fetchCorrelationsRequestSuccess = (payload) => {
    return {
        type: K.ACTIONS.FETCH_CORRELATIONS_REQUEST_SUCCESS,
        payload,
    };
};

export const createCorrelationRequest = (entity_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_CORRELATION_REQUEST,
        payload: { data, entity_id },
        callback,
    };
};

export const createCorrelationRequestSuccess = (correlation) => {
    return {
        type: K.ACTIONS.CREATE_CORRELATION_REQUEST_SUCCESS,
        payload: { correlation },
    };
};

// export const updateCorrelationRequest = (entity_id, correlation_id, data, callback) => {
//     return {
//         type: K.ACTIONS.UPDATE_CORRELATION_REQUEST,
//         payload: { data, entity_id, correlation_id },
//         callback,
//     };
// };

export const updateCorrelationRequestSuccess = (correlation) => {
    return {
        type: K.ACTIONS.UPDATE_CORRELATION_REQUEST_SUCCESS,
        payload: { correlation },
    };
};

export const deleteCorrelationRequest = (entity_id, correlation_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_CORRELATION_REQUEST,
        payload: { entity_id, correlation_id },
        callback,
    };
};

export const deleteCorrelationRequestSuccess = (correlation_id) => {
    return {
        type: K.ACTIONS.DELETE_CORRELATION_REQUEST_SUCCESS,
        payload: { correlation_id },
    };
};
