/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import { Button, Input } from 'antd';

const { TextArea } = Input;

export default class MultiTextInputGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : [''],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.props.handleChange(this.state.data);
        }
    }

    handleAdd() {
        this.setState({ data: this.state.data.concat('') });
    }

    handleRemove(index) {
        const data = this.state.data.concat();
        data.splice(index, 1);

        if (!data.length) return this.setState({ data: [''] });
        this.setState({ data });
    }

    handleChange(index, e) {
        const data = this.state.data.concat();
        data[index] = e;

        this.setState({ data });
    }

    render() {
        const { inputProps } = this.props;
        return (
            <div className="multi-text-input-group d-flex flex-column">
                <ol style={{ marginBottom: 0 }}>
                    {this.state.data.map((d, i) => {
                        return (
                            <li key={i}>
                                <AddRemoveTextInput
                                    inputProps={{
                                        ...inputProps,
                                        value: d,
                                    }}
                                    handleChange={(e) => this.handleChange(i, e.target.value)}
                                    handleAdd={this.handleAdd.bind(this)}
                                    handleRemove={() => this.handleRemove(i)}
                                />
                            </li>
                        );
                    })}
                </ol>
            </div>
        );
    }
}

const AddRemoveTextInput = (props) => {
    return (
        <div className="add-remove-text-input d-flex mb-2 flex-column">
            <TextArea {...props.inputProps} onChange={props.handleChange} />
            <Button danger onClick={props.handleRemove}>
                <span>
                    <i className="fas fa-minus" />
                </span>
            </Button>
            <Button onClick={props.handleAdd}>
                <span>
                    <i className="fas fa-plus" />
                </span>
            </Button>
        </div>
    );
};

MultiTextInputGroup.defaultProps = {
    data: [''],
};

