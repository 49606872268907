import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { store } from '../..';

export const api_getGatewaysStatus = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/gateway/status`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getGatewayStatus = (entity_id, device_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/gateway/${device_id}/status`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getGatewayMeshData = (gateway_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/gateway/${gateway_id}/link`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateGatewayEntity = (entity_id, serial_number) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/gateway`,
                method: 'PUT',
                params: JSON.stringify({ serial_number }),
                success: resolve,
                error: reject,
            });
        }
    );
};
