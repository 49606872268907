import moment from 'moment';
import { toUnix } from '../../../../legacy/utils/date';

export const RegressionConstants = {
    ACTIONS: {
        REGRESSION_SET_HISTORICAL: 'REGRESSION_SET_HISTORICAL',
        REGRESSION_SET_PERIOD: 'REGRESSION_SET_PERIOD',
        REGRESSION_SET_RANGE_TYPE: 'REGRESSION_SET_RANGE_TYPE',
        REGRESSION_SET_ASSET: 'REGRESSION_SET_ASSET',
        REGRESSION_SET_SOURCE: 'REGRESSION_SET_SOURCE',
        REGRESSION_SET_RESOLUTION: 'REGRESSION_SET_RESOLUTION',
        REGRESSION_SET_REGRESSION: 'REGRESSION_SET_REGRESSION',
        REGRESSION_SET_SELECTION: 'REGRESSION_SET_SELECTION',
        REGRESSION_FETCH_DATA_REQUEST: 'REGRESSION_FETCH_DATA_REQUEST',
        REGRESSION_FETCH_DATA_REQUEST_SUCCESS:
      'REGRESSION_FETCH_DATA_REQUEST_SUCCESS',
        REGRESSION_CHANGE_BOUND: 'REGRESSION_CHANGE_BOUND',
        REGRESSION_CHANGE_FORM: 'REGRESSION_CHANGE_FORM',
        REGRESSION_RESET_STORE: 'REGRESSION_RESET_STORE',
    },
    RANGE_TYPES: {
        HISTORICAL: '0',
        RECENT: '1',
    },
    ACCESSORS: {
        x: (d) => d.x,
        y: (d) => d.y,
        color: (d) => toUnix(moment(d.time)),
    },
};
