/* eslint-disable react/prop-types */
import React from 'react';

import { AutoSizer, List } from 'react-virtualized';

import './VList.scss';

export default class VList extends React.PureComponent {
    constructor(props) {
        super(props);

        this._rowRenderer = this._rowRenderer.bind(this);
    }

    _rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it)
    }) {
        const { data = [] } = this.props;
        return (
            <div key={key} style={style}>
                {data[index]}
            </div>
        );
    }

    render() {
        const { rowHeight, rowRenderer, rowCount } = this.props;
        return (
            <AutoSizer>
                {({ width, height }) => {
                    return (
                        <List
                            width={width}
                            height={height}
                            rowCount={rowCount}
                            rowHeight={rowHeight}
                            rowRenderer={rowRenderer || this._rowRenderer}
                        />
                    );
                }}
            </AutoSizer>
        );
    }
}
