import { put, takeLatest } from 'redux-saga/effects';
import { List } from 'immutable';

// ACTION
import {
    // GET POLICY
    getPoliciesSuccess,
    getPoliciesFailure,

    // GET POLICY DETAL
    getPolicyDetailSuccess,
    getPolicyDetailFailure,

    // CREATE POLICY
    createPolicySuccess,
    createPolicyFailure,

    // UPDATE POLICY
    updatePolicySuccess,
    updatePolicyFailure,

    // DELETE POLICY
    deletePolicySuccess,
    deletePolicyFailure,

    // APPLY POLICY
    applyPolicyFailure,

    // RESOURCE
    getResourcesSuccess,
    getResourcesFailure,
} from './Permission.action';

// ENTITY SERVICES
import {
    api_getPolicies,
    api_getPolicy,
    api_createPolicy,
    api_updatePolicy,
    api_deletePolicy,
    api_applyPolicy,
    api_getResources,
} from './Permission.services';

// MODEL
import { Policy } from '../../../legacy/models';
import { store } from '../..';

import { errorFlash, flash } from '../../../legacy/components/Flash';

// GET POLICIES
function* handleGetPolicies(data) {
    try {
        const response = yield api_getPolicies();
        const policies = response.policies;
        const newPolicies = policies.map((v) => {
            return {
                ...v,
                users: v.users.map((user) => Number(user.user_id)),
                groups: v.groups.map((group) => Number(group.group_id)),
                blocks: v.blocks.map((block) => Number(block.block_id)),
            };
        });

        yield put(getPoliciesSuccess(newPolicies));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(getPoliciesFailure(error));
    }
}

export function* getPolicies() {
    yield takeLatest(
        'GET_POLICIES_REQUEST',
        handleGetPolicies
    );
}

// GET POLICY DETAIL
function* handleGetPolicyDetail(data) {
    try {
        const response = yield api_getPolicy(data.payload.policy);

        yield put(getPolicyDetailSuccess(response));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(getPolicyDetailFailure(error));
    }
}

export function* getPolicyDetail() {
    yield takeLatest('GET_POLICY_DETAIL_REQUEST', handleGetPolicyDetail);
}

// CREATE POLICY
function* handleCreatePolicy(data) {
    try {
        const policies = List(store.getState().permission.policies);

        if (data.payload.policy.policy_name.trim() === '') {
            throw { msg: 'Policy name can be empty' };
        }

        if (data.payload.policy.groups.length === 0) {
            throw { msg: 'At least one group must be selected' };
        }

        const response = yield api_createPolicy(data.payload.policy);
        const blocks = response.blocks.map((v) => Number(v.block_id));

        const policyFromDB = new Policy(
            response.policy_name,
            response.policy_id,
            blocks,
            data.payload.policy.users,
            data.payload.policy.groups,
            response.resource_policies
        );

        const newPolicies = policies.push(policyFromDB);

        yield put(createPolicySuccess(newPolicies.toJS()));

        flash({
            message: 'Policy created',
            status: 'success',
        });

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(createPolicyFailure(error));
    }
}

export function* createPolicy() {
    yield takeLatest(
        'CREATE_POLICY_REQUEST',
        handleCreatePolicy
    );
}

// UPDATE POLICY
function* handleUpdatePolicy(data) {
    try {
        const policies = List(store.getState().permission.policies);

        const indexPolicy = policies.findIndex(
            (v) => v.policy_id === data.payload.policy.policy_id
        );

        if (indexPolicy === -1) {
            throw { msg: 'This policy does not exist' };
        }

        if (data.payload.policy.policy_name.trim() === '') {
            throw { msg: 'Policy name can be empty' };
        }

        if (data.payload.policy.groups.length === 0) {
            throw { msg: 'At least one group must be selected' };
        }

        const response = yield api_updatePolicy(data.payload.policy);

        const newPolicies = policies.update(indexPolicy, () => data.payload.policy);

        yield put(updatePolicySuccess(newPolicies.toJS()));

        flash({
            message: 'Policy updated',
            status: 'success',
        });

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(updatePolicyFailure(error));
    }
}

export function* updatePolicy() {
    yield takeLatest(
        'UPDATE_POLICY_REQUEST',
        handleUpdatePolicy
    );
}

// DELETE POLICY
function* handleDeletePolicy(data) {
    try {
        const policies = List(store.getState().permission.policies);

        const indexPolicy = policies.findIndex(
            (v) => v.policy_id === data.payload.policy.policy_id
        );

        if (indexPolicy === -1) {
            throw { msg: 'This policy does not exist' };
        }

        const response = yield api_deletePolicy(data.payload.policy);

        const newPolicies = policies.delete(indexPolicy);

        yield put(deletePolicySuccess(newPolicies.toJS()));

        flash({
            message: 'Policy deleted',
            status: 'success',
        });

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
        yield put(deletePolicyFailure(error));
    }
}

export function* deletePolicy() {
    yield takeLatest(
        'DELETE_POLICY_REQUEST',
        handleDeletePolicy
    );
}

// APPLY POLICY
function* handleApplyPolicy(data) {
    try {
        const response = yield api_applyPolicy(data.payload.policy);

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(applyPolicyFailure(error));
    }
}

export function* applyPolicy() {
    yield takeLatest(
        'APPLY_POLICY_REQUEST',
        handleApplyPolicy
    );
}

// GET RESOURCES
function* handleGetResources(data) {
    try {
        const response = yield api_getResources();
        const responseLength = response.length;
        const responeKeyValue = {};

        for (let i = 0; i < responseLength; i++) {
            const resource_id = response[i].resource_id;
            responeKeyValue[Number(resource_id)] = response[i];
        }

        yield put(getResourcesSuccess(responeKeyValue));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(getResourcesFailure(error));
    }
}

export function* getResources() {
    yield takeLatest('GET_POLICY_RESOURCES_REQUEST', handleGetResources);
}
