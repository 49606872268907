/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import { last } from 'lodash';

export default class MultiplyVirtualizeNoScroll extends Component {
    constructor(props) {
        super(props);
        this.viewPort = createRef();
        this.state = {
            startX: 0,
            endX: this.props.numsOfVisibleColItems,
            startY: 0,
            endY: this.props.numsOfVisibleRowItems,
            width: 100,
            height: 100,
            numbersOfCol: 0,
            numbersOfRow: 0,
            arrayWidth: [],
            arrayHeight: [],
            arrayLeft: [0],
            arrayTop: [0],
            numsOfVisibleColItems: this.props.numsOfVisibleColItems,
            numsOfVisibleRowItems: this.props.numsOfVisibleRowItems
        };
        this._timeout = null;
        this._timeoutLoading = null;
    }

    componentDidMount() {
        const { numbersOfCol, numbersOfRow, colWidth, colHeight } = this.props;
        const {
            startX,
            startY,
            arrayWidth,
            arrayHeight,
            arrayLeft,
            arrayTop
        } = this.state;
        for (let i = startX; i <= numbersOfCol - 1; i++) {
            arrayWidth.push(colWidth({ index: i }));
        }

        arrayWidth.reduce((total, num) => {
            arrayLeft.push(total);
            return total + num;
        });

        for (let i = startY; i <= numbersOfRow - 1; i++) {
            arrayHeight.push(colHeight({ index: i }));
        }

        arrayHeight.reduce((total, num) => {
            arrayTop.push(total);
            return total + num;
        });

        let width = last(arrayLeft) + colWidth({ index: numbersOfCol - 1 });
        let height = last(arrayTop) + colHeight({ index: numbersOfRow - 1 });

        this.setState({
            width: width,
            height: height,
            numbersOfCol: numbersOfCol,
            numbersOfRow: numbersOfRow,
            arrayLeft: arrayLeft,
            arrayTop: arrayTop,
            arrayHeight: arrayHeight,
            arrayWidth: arrayWidth
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            numbersOfCol,
            numbersOfRow,
            colWidth,
            colHeight,
            numsOfVisibleColItems,
            numsOfVisibleRowItems
        } = nextProps;
        const { startX, startY } = this.state;
        let newArrayWidth = [];
        let newArrayHeight = [];
        let newArrayLeft = [0];
        let newArrayTop = [0];

        for (let i = startX; i <= numbersOfCol - 1; i++) {
            newArrayWidth.push(colWidth({ index: i }));
        }

        newArrayWidth.reduce((total, num) => {
            newArrayLeft.push(total);
            return total + num;
        });

        for (let i = startY; i <= numbersOfRow - 1; i++) {
            newArrayHeight.push(colHeight({ index: i }));
        }

        newArrayHeight.reduce((total, num) => {
            newArrayTop.push(total);
            return total + num;
        });

        let width = last(newArrayLeft) + colWidth({ index: numbersOfCol - 1 });
        let height = last(newArrayTop) + colHeight({ index: numbersOfRow - 1 });

        this.setState({
            startX: 0,
            endX: numsOfVisibleColItems,
            startY: 0,
            endY: numsOfVisibleRowItems,
            width: width,
            height: height,
            numbersOfCol: numbersOfCol,
            numbersOfRow: numbersOfRow,
            arrayLeft: newArrayLeft,
            arrayTop: newArrayTop,
            arrayHeight: newArrayHeight,
            arrayWidth: newArrayWidth,
            numsOfVisibleColItems,
            numsOfVisibleRowItems
        });
    }

    componentWillUnmount() {
        clearTimeout(this._timeoutLoading);
    }

    renderRows() {
        let result = [];
        const { startX, endX, startY, endY, arrayTop, arrayLeft } = this.state;
        const { colWidth, colHeight, renderRow, showLoading } = this.props;

        if (!showLoading) {
            for (let i = startY; i < endY; i++) {
                for (let j = startX; j <= endX; j++) {
                    result.push(
                        <div
                            key={`${i}${j}-${Math.floor(Math.random() * Math.floor(100000))}`}
                            style={{
                                width: colWidth({ index: j }),
                                position: 'absolute',
                                left: arrayLeft[j],
                                top: arrayTop[i],
                                borderTop: '0.1px solid #e6e6e6',
                                borderRight: 'none',
                                height: colHeight({ index: i })
                            }}
                        >
                            {renderRow({ indexX: j, indexY: i })}
                        </div>
                    );
                }
            }

            return result;
        }

        return null;
    }

    render() {
        let {
            viewPortHeight,
            viewPortWidth,
            style,
            showLoading,
            customLoading,
            additionalComponent
        } = this.props;
        const { width, height } = this.state;

        return (
            <div
                style={{
                    height: viewPortHeight,
                    width: viewPortWidth ? viewPortWidth : '100%',
                    position: 'relative'
                }}
            >
                <div
                    ref={this.viewPort}
                    style={{
                        height: viewPortHeight,
                        overflow: 'hidden',
                        position: 'relative',
                        width: viewPortWidth ? viewPortWidth : '100%',
                        ...style
                    }}
                >
                    <div
                        style={{
                            width: width,
                            position: 'relative',
                            height: height
                        }}
                    >
                        {this.renderRows()}
                        {additionalComponent}
                    </div>
                </div>

                {showLoading ? (
                    <div className="animate_loading">
                        {customLoading ? (
                            customLoading()
                        ) : (
                            <div className="lds-roller">
                                {new Array(8).fill(null).map((v, i) => (
                                    <div key={i} />
                                ))}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}
