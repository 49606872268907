import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { DashboardConstants as K } from './Dashboard.constants';

const initialState = {
    viewMode: K.VIEW.TIME_SERIES,
    mode: K.MODES.VIEW,
    activeCanvasId: -1,
};

export const DashboardReducer = (
    state = initialState,
    action
) => {
    if (action.type === K.ACTIONS.DASHBOARD_CHANGE_VIEW_MODE) {
        return updateStoreRedux(state, {
            viewMode: action.payload.viewMode,
        });
    }

    if (action.type === K.ACTIONS.DASHBOARD_CHANGE_MODE) {
        return updateStoreRedux(state, {
            mode: action.payload.mode,
        });
    }

    return updateStoreRedux(state, {});
};
