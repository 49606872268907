/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Arrow } from '../Arrow';
import { arrayBlocks } from '../../../../store/old/Blocks/Blocks.selector';
import CONSTANTS from '../../../Constants';
import { flash } from '../../../components/Flash';

class DashboardArrow extends Component {
    constructor(props) {
        super(props);

        this.state = { blockBoundingRects: {} };
    }

    componentDidUpdate(prevProps, prevState, ss) {
        if (
            prevProps.blocks !== this.props.blocks ||
      prevProps.tiles !== this.props.tiles
        ) {
            this.setState({ blockBoundingRects: this.getBlockBoundingRects() });
        }
    }

    getBlockBoundingRects() {
        const {
            blocksWithAsset,
            blocksWithoutAsset,
            props: { tiles },
        } = this;

        const cellWidth = CONSTANTS.DASHBOARD.CELL_WIDTH;
        const cellHeight = CONSTANTS.DASHBOARD.CELL_HEIGHT;

        const boundingRects = {};

        if (Object.keys(tiles).length === 0) return {};

        // get bounding rects for all asset blocks first
        blocksWithAsset
            .filter((b) => b.asset.tile) // ensure each block is represented by a tile
            .reduce((acc, curr) => {
                const tileRect = tiles[curr.asset.tile.tile_id];
                acc[curr.block_id] = {
                    x: tileRect.arrowX,
                    y: tileRect.arrowY,
                    width: cellWidth,
                    height: cellHeight,
                };
                return acc;
            }, boundingRects);

        return blocksWithoutAsset
            .sort((a, b) => b.hierarchy_level - a.hierarchy_level)
            .reduce((acc, curr) => {
                const childRects = curr.children
                    .map((child) => acc[child.block_id])
                    .filter((rect) => rect);

                const minX = Math.min(...childRects.map((r) => r.x));
                const minY = Math.min(...childRects.map((r) => r.y));

                acc[curr.block_id] = {
                    x: minX,
                    y: minY,
                    width: Math.max(...childRects.map((r) => r.x + r.width)) - minX,
                    height: Math.max(...childRects.map((r) => r.y + cellHeight)) - minY,
                };

                return acc;
            }, boundingRects);
    }

    get blocksWithAsset() {
        return this.props.blocks.filter((b) => b.asset);
    }

    get blocksWithoutAsset() {
        return this.props.blocks.filter((b) => b.children && b.children.length);
    }

    generatePrependConnectors(block) {
        const { blockBoundingRects } = this.state;

        const connectors = [];
        const rect = blockBoundingRects[block.block_id];

        if (!rect) return [];

        try {
            block.children.forEach((child) => {
                const childRect = blockBoundingRects[child.block_id];
                connectors.push({
                    x1: rect.x + CONSTANTS.DASHBOARD.CELL_PADDING * 0.5,
                    y1: childRect.y,
                    x2: childRect.x,
                    y2: childRect.y,
                    offsetType: CONSTANTS.ARROWS.OFFSET.HORIZONTAL_LINE,
                    arrowhead: true,
                });
            });

            !block.asset_id &&
        connectors.push({
            x1: rect.x + CONSTANTS.DASHBOARD.CELL_PADDING * 0.5,
            y1: rect.y,
            x2: rect.x + CONSTANTS.DASHBOARD.CELL_PADDING * 0.5,
            y2: rect.y + rect.height,
            offsetType: CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE,
            pointIndicator: true,
        });

            return connectors;
        } catch (e) {
            throw new Error(e);
        }
    }

    generatePostConnectors(block) {
        const { blockBoundingRects } = this.state;

        const connectors = [];
        const rect = blockBoundingRects[block.block_id];

        if (!rect) return [];

        try {
            connectors.push({
                x1: rect.x + rect.width + CONSTANTS.DASHBOARD.CELL_PADDING * 1.5,
                y1: rect.y,
                x2: rect.x + rect.width + CONSTANTS.DASHBOARD.CELL_PADDING * 1.5,
                y2: rect.y + rect.height,
                offsetType: CONSTANTS.ARROWS.OFFSET.VERTICAL_LINE,
            });

            block.children.forEach((child) => {
                const childRect = blockBoundingRects[child.block_id];
                connectors.push({
                    x1: childRect.x + childRect.width,
                    y1: childRect.y,
                    x2: rect.x + rect.width,
                    y2: childRect.y,
                    offsetType: CONSTANTS.ARROWS.OFFSET.HORIZONTAL_LINE,
                    arrowhead: false,
                });
            });

            return connectors;
        } catch (e) {
            throw new Error(e);
        }
    }

    generateSequenceConnectors(block) {
        const { blockBoundingRects } = this.state;

        const connectors = [];
        const { nextSibling } = block;
        const rect = blockBoundingRects[block.block_id];
        const nextRect =
      nextSibling.isSequence && nextSibling.children.length
          ? blockBoundingRects[nextSibling.children[0].block_id]
          : blockBoundingRects[nextSibling.block_id]; // append to tile of first child if sequence block, else point to bounding rect of sibling

        if (!nextRect || !rect) return [];

        try {
            connectors.push({
                x1: rect.x + rect.width,
                y1: rect.y,
                x2: nextRect.x,
                y2: nextRect.y,
                offsetType: CONSTANTS.ARROWS.OFFSET.HORIZONTAL_LINE,
                arrowhead: true,
            });

            return connectors;
        } catch (e) {
            throw new Error(e);
        }
    }

    get connections() {
        const { blocks } = this.props;
        const connections = [];

        try {
            blocks.forEach((block) => {
                const { drawPrepend, drawSequence, drawPost } = block.draw;

                drawPrepend &&
          connections.push(...this.generatePrependConnectors(block));

                drawSequence &&
          connections.push(...this.generateSequenceConnectors(block));

                drawPost && connections.push(...this.generatePostConnectors(block));
            });

            return connections;
        } catch (e) {
            flash({
                message: 'Arrow generation failed. Please review process editor',
                status: 'warning',
                key: 'arrow-generate-fail',
            });
            return [];
        }
    }

    render() {
        return (
            <>
                {this.connections.map((c, i) => (
                    <Arrow
                        key={i}
                        disabled={false}
                        isHighlighted={false}
                        dragging={false}
                        data={c}
                        scale={1}
                        cellWidth={CONSTANTS.DASHBOARD.CELL_WIDTH}
                        cellHeight={CONSTANTS.DASHBOARD.CELL_HEIGHT}
                        cellPadding={CONSTANTS.DASHBOARD.CELL_PADDING}
                    />
                ))}
            </>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        tiles: rootState.tiles.tiles,
        blocks: arrayBlocks(rootState),
    };
};

export default connect(mapStateToProps, null)(DashboardArrow);
