import "./style.scss"
import { Typography } from "@mui/material"
import React from "react"

const DayCalendarHeader = (props: Props) => {
    return <Typography className="day-calendar-header" role="columnheader" variant="caption" aria-label={props.value}>
        {props.value[0]}
    </Typography>
}

interface Props {
    value: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
}

export default DayCalendarHeader
