import { keys, uniq } from 'lodash';

export const permittedIssuesByBlock = (issues, blockId, blocksIssuesExclusions) => {
    const excludedIssues = new Set(
        keys(blocksIssuesExclusions[blockId]).map((id) => +id)
    );
    return keys(issues)
        .filter((issue_id) => !excludedIssues.has(+issue_id))
        .map((issue_id) => +issue_id);
};

export const permittedIssuesByBlocks = (issues, blockIds, blocksIssuesExclusions) => {
    return uniq(
        blockIds
            .map((id) => permittedIssuesByBlock(issues, id, blocksIssuesExclusions))
            .reduce((acc, curr) => acc.concat(...curr), [])
    );
};

export const isQualityIssue = (issue) => {
    if (!issue) return false;
    return issue.oee === 'rj' || issue.oee === 'rw';
};
