import { store } from '../../store';

export const parseCommentArguments = (c) => {
    return [c.comment_id, c.user_id, c.message, c.timestamp];
};

export class Comment {
    constructor(
        comment_id,
        user_id,
        message,
        timestamp
    ) {
        this.comment_id = comment_id;
        this.user_id = user_id;
        this.message = message;
        this.timestamp = timestamp;
    }

    get user() {
        const {
            user: { list: usersState },
        } = store.getState();

        return usersState[this.user_id];
    }
}
