import "./style.scss"
import { Button } from "@mui/material"
import cx from "classnames"
import moment from "moment"
import React, { ReactEventHandler } from "react"
import Grid from "../../../Grid/Grid"

const MonthCalendarButton = (props: Props) => {
    const handleClick: ReactEventHandler = () => {
        props.onPress(props.value)
    }

    const isStart = props.start?.isSame(props.value)
    const isEnd = props.end?.isSame(props.value)
    return <Grid item flex={1} className="month-button">
        <span className={cx({
            "month-button-container": true,
            "highlight": props.start && props.end && !props.value.isBefore(props.start) && !props.value.isAfter(props.end),
            "start": isStart,
            "end": isEnd,
        })}>
            <Button
                variant="text"
                className={cx({ "month-button-btn": true, "active": isStart || isEnd })}
                onClick={handleClick}
            >
                {props.value.format("MMM")}
            </Button>
        </span>
    </Grid>
}

interface Props {
    value: moment.Moment
    onPress: (value: moment.Moment) => void
    start?: Time.DateValue
    end?: Time.DateValue
}

export default MonthCalendarButton
