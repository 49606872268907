import moment from 'moment';
import { csvDatetimeFormat } from '../../utils/helpers';
import { round } from 'lodash';
import { getMaskedOEE } from '../../utils/oee';

const oeeColumns = (masks) =>
    getMaskedOEE(masks).sort((a, b) => a.csvOrder - b.csvOrder);

const getData = (row, data) => {
    const { uuid, metric, data_type } = row;

    switch (metric.value) {
    case 'data':
        return {
            timeSeries: data[uuid].data,
            labels: data[uuid].labels,
        };
    default:
        return {
            oee: data[uuid].oee,
            timeSeries: data[uuid].oee.oee,
            labels: data[uuid].labels,
        };
    }
};

const getAsset = (assets, row) => {
    return assets[row.asset_id];
};

const getChart = (asset, row) => {
    const data_type = row.data_type === 'fusion' ? 'fusion_id' : 'metadata_id';
    return asset.charts.find((c) => c[data_type] === row.chart_id);
};

const findLabelsInInterval = (datum, labels) => {
    const intervalStart = new moment(datum.time);
    const intervalEnd = intervalStart.clone().add(datum.int, 'milliseconds');
    return labels.filter((l) => {
        let { from, to } = l;
        from = new moment(from);
        to = to ? new moment(to) : new moment();
        return (
            from.isBetween(intervalStart, intervalEnd, null, '[)') ||
      to.isBetween(intervalStart, intervalEnd, null, '(]') ||
      intervalStart.isBetween(from, to, null, '[]')
        );
    });
};

const generateLabelRow = (asset, labels) => {
    const composite = labels.reduce((acc, curr) => {
        Object.keys(curr.attributes).forEach((key) => {
            const value = curr.attributes[key];
            acc[key] = acc[key] ? acc[key].concat(value) : [value];
        });
        return acc;
    }, {});

    return asset.labelFields.map(({ field }) => {
        const key = field.toLowerCase();
        return composite[key] ? `"${composite[key].join(' | ')}"` : '';
    });
};

const generateDataRowOEE = (datum, masks) => {
    const { time, oee } = datum;

    return [
        moment(time).format(csvDatetimeFormat),
        ...oeeColumns(masks).map(({ key }) => round(oee[key], 2)),
    ];
};

const generateDataRowProduction = (datum) => {
    const { time, val } = datum;
    return [moment(time).format(csvDatetimeFormat), val];
};

const getHeaderColumns = (row, asset, chart, masks) => {
    const labelHeader = asset.labelFields.map(({ field }) => field);
    const oeeHeader = oeeColumns(masks).map((cat) => cat.altLabel);
    const productionHeader = [chart.chart_title];

    const header = (row.metric.value === 'data' ? productionHeader : oeeHeader)
        .concat(...labelHeader)
        .join(',');

    return 'Time,' + header;
};

const getAggregateBlock = (data, masks) => {
    const cols = oeeColumns(masks);
    return [
        'Total (hours),' +
      cols
          .map(({ key }) =>
              round((data[key] * data.totalSeconds) / 100 / 3600, 2)
          )
          .join(','),
        'Percent,' + cols.map(({ key }) => round(data[key], 2)).join(','),
    ].join('\n');
};

const trendCSV = (assets, rows, data, masks) => {
    const chunks = rows.map((row) => {
        const asset = getAsset(assets, row);
        const chart = getChart(asset, row);
        const { timeSeries, labels, oee } = getData(row, data);

        const labelsBlock = timeSeries.map((datum) => {
            const result = findLabelsInInterval(datum, labels);
            return generateLabelRow(asset, result);
        });

        const dataBlock = timeSeries.map((datum) =>
            row.metric.value === 'data'
                ? generateDataRowProduction(datum)
                : generateDataRowOEE(datum, masks)
        );

        const csvRows = timeSeries
            .map((x, i) => dataBlock[i].concat(...labelsBlock[i]))
            .join('\n');

        const headers = getHeaderColumns(row, asset, chart, masks);

        let aggregateBlock = '';
        if (row.metric.value !== 'data') {
            aggregateBlock = getAggregateBlock(oee.aggregate_oee, masks);
        }

        return (
            `${asset.asset_name} (${chart.chart_title})` +
      '\n' +
      headers +
      '\n' +
      csvRows +
      '\n' +
      aggregateBlock +
      '\n'
        );
    });

    return chunks.join('\n');
};

export default trendCSV;
