/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { values } from 'lodash';

// CONSTANTS
import CONSTANTS from '../../Constants';

// COMPONENTS
import { errorFlash, flash } from '../../components/Flash';

// SELECTORS
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

// ACTIONS
import { fetchOrganizationRequest } from '../../../store/old/Entity/Entity.action';

// SERVICES
import { api_updateGatewayEntity } from '../../../store/old/Gateway/Gateway.services';
import withRouter from '../../Wrappers/HOCs/withRouter';
import { ZXScanner } from '../../components/BarcodeScanner';
import translate from '../../utils/translate';
import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../components/Panel';
import { SPAWrapper } from '../../components/SPAWrapper';

export class SetupGatewaySimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceSerialNumber: '',
            manually: false,
            buttonNextInSetupLabel: false,
        };

        this.handleChangeDeviceSerialNumber =
      this.handleChangeDeviceSerialNumber.bind(this);
        this.handleClickByManually = this.handleClickByManually.bind(this);
        this.handleClickNextInSetupLabel =
      this.handleClickNextInSetupLabel.bind(this);
        this.handleGetDataSerialNumber = this.handleGetDataSerialNumber.bind(this);
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    handleSubmit() {
        const { deviceSerialNumber } = this.state;
        const { entity_id } = this.props.entity;

        const gatewayExists = this.checkGatewayExists(deviceSerialNumber);

        if (gatewayExists) {
            flash({
                message: 'Gateway already exists',
                status: 'warning',
            });
            return;
        }

        api_updateGatewayEntity(entity_id, deviceSerialNumber)
            .then(() =>
                this.props.fetchOrg(entity_id, () => {
                    this.props.router.navigate(CONSTANTS.URLS.DASHBOARD);
                })
            )
            .catch((e) => errorFlash(e));
    }

    checkGatewayExists(sn) {
        const { gateways } = this.props;

        return values(gateways).find(({ serial_number }) => serial_number === sn);
    }

    handleChangeDeviceSerialNumber(e) {
        this.setState({ deviceSerialNumber: e.target.value });
    }

    handleClickByManually() {
        this.setState({ manually: true });
    }

    handleClickNextInSetupLabel() {
        this.setState({ buttonNextInSetupLabel: true });
    }

    handleGetDataSerialNumber(str) {
        this.setState(
            {
                deviceSerialNumber: str,
            },
            () => this.handleSubmit()
        );
    }

    handleClickBack() {
        this.setState({
            manually: false,
            deviceSerialNumber: '',
        });
    }

    navigate() {
        this.props.router.navigate(CONSTANTS.URLS.GATEWAYS);
    }

    render() {
        const { deviceSerialNumber, manually, buttonNextInSetupLabel } = this.state;

        const { handleGetDataSerialNumber } = this;

        const setupLabel = (
            <tr>
                <td className="td all-input-paddings-right" colSpan="2">
                    <label className="check">
                        {translate('setupgateway')}
                    </label>
                </td>
            </tr>
        );

        const manuallySerialNumber = (
            <tr>
                <td className="right td all-input-paddings-right">
                    <label className="check" />
                </td>
                <td className="td all-input-paddings-left">
                    <div className="right forgot" onClick={this.handleClickByManually}>
                        {translate('manuallyinputserialnumber')}
                    </div>
                </td>
            </tr>
        );

        const afterClickManuallySerialNumber = (
            <tr>
                <td className="right td all-input-paddings-right">
                    <label className="check">
                        {translate('deviceserialnumber')}
                    </label>
                </td>
                <td className="td all-input-paddings-left">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Device Serial Number"
                        value={deviceSerialNumber}
                        onChange={this.handleChangeDeviceSerialNumber}
                    />
                </td>
            </tr>
        );

        let setupPrompt;
        if (!buttonNextInSetupLabel) {
            setupPrompt = setupLabel;
        } else {
            setupPrompt = !manually
                ? manuallySerialNumber
                : afterClickManuallySerialNumber;
        }

        const Table = (
            <table className="table t-current">
                <tbody>{setupPrompt}</tbody>
            </table>
        );

        const footerSetupLabel = (
            <>
                <button
                    className="btn b-save"
                    onClick={this.handleClickNextInSetupLabel}
                >
                    {translate('next')}
                </button>
            </>
        );

        const footerManually = <div className="panel-footer" />;

        const footerAfterClickManually = (
            <>
                <button className="btn b-cancel" onClick={this.handleClickBack}>
                    {translate('cancel')}
                </button>
                <button
                    className="btn b-save"
                    onClick={this.handleSubmit}
                    disabled={deviceSerialNumber === ''}
                >
                    {translate('submit')}
                </button>
            </>
        );

        let footer;
        if (!buttonNextInSetupLabel) {
            footer = footerSetupLabel;
        } else {
            footer = !manually ? footerManually : footerAfterClickManually;
        }

        const qrCode = (
            <div className="d-flex justify-content-center">
                <ZXScanner onScan={handleGetDataSerialNumber} />
            </div>
        );

        return (
            <SPAWrapper className="d-flex justify-content-center align-items-center">
                <Panel style={{ width: '60vw' }}>
                    <PanelHeader>
                        {translate('setupgateway')}
                    </PanelHeader>
                    <PanelBody>
                        {buttonNextInSetupLabel && !manually && qrCode}
                        {manually}
                        {Table}
                    </PanelBody>
                    <PanelFooter>
                        {footer}
                    </PanelFooter>
                </Panel>
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        entity: currentEntitySelector(appState),
        gateways: appState.gateway.gateways,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrg: (entity_id, callback) =>
            dispatch(fetchOrganizationRequest(entity_id, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SetupGatewaySimple));
