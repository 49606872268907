import { AssetsConstants as K } from './Assets.constants';

export const resetAssetsState = () => {
    return {
        type: K.ACTIONS.RESET_ASSETS_STATE,
    };
};

export const setAssetsState = (
    payload
) => {
    return {
        type: K.ACTIONS.SET_ASSETS_STATE,
        payload,
    };
};

export const addAssetsResource = (assets) => {
    return {
        type: K.ACTIONS.ADD_ASSETS_RESOURCE,
        payload: { assets },
    };
};

export const fetchAssetsProduction = (entity_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSETS_PRODUCTION_REQUEST,
        payload: { entity_id, query },
        callback,
    };
};

export const updateAssetStdTime = (entity_id, asset_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_STANDARD_TIME,
        payload: { entity_id, asset_id, data },
        callback,
    };
};

export const createAsset = (entity_id, asset, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET_REQUEST,
        payload: { entity_id, asset },
        callback,
    };
};

export const updateAsset = (entity_id, asset, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET,
        payload: { entity_id, asset },
        callback,
    };
};

export const fetchAssetCharts = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_CHARTS,
        payload: { entity_id, asset_id },
        callback,
    };
}

export const updateAssetChart = (entity_id, asset_id, chart_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_CHART,
        payload: { entity_id, asset_id, chart_id, data },
        callback,
    };
}

export const fetchAssetSkus = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_SKUS,
        payload: { entity_id, asset_id },
        callback,
    };
}

export const createAssetSku = (entity_id, asset_id, sku_id, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET_SKU,
        payload: { entity_id, asset_id, sku_id },
        callback,
    };
}

export const deleteAssetSkus = (entity_id, asset_id, sku_ids, callback) => {
    return {
        type: K.ACTIONS.DELETE_ASSET_SKU,
        payload: { entity_id, asset_id, sku_ids },
        callback,
    };
}

export const setAssetSkuResource = (skus) => {
    return {
        type: K.ACTIONS.SET_ASSET_SKU_RESOURCE,
        payload: { skus },
        callback,
    };
}

export const addAssetSkuResource = (skus) => {
    return {
        type: K.ACTIONS.ADD_ASSET_SKU_RESOURCE,
        payload: { skus },
    };
}

export const removeAssetSkuResource = (asset_ids) => {
    return {
        type: K.ACTIONS.REMOVE_ASSET_SKU_RESOURCE,
        payload: { asset_ids },
    };
}

// export const deleteAssetPure = (
//     assets,
//     callback
// ) => {
//     return {
//         type: K.ACTIONS.DELETE_ASSET_PURE,
//         payload: { assets },
//         callback,
//     };
// };

export const fetchAssetOperatorSettings = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_OPERATOR_SETTINGS,
        payload: { entity_id, asset_id },
        callback,
    };
};

export const setAssetOperatorSettings = (payload, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_OPERATOR_SETTINGS,
        payload,
        callback,
    };
};

export const deleteAsset = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_ASSET,
        payload: { entity_id, asset_id },
        callback,
    };
};

export const removeAssetsResource = (asset_ids) => {
    return {
        type: K.ACTIONS.REMOVE_ASSETS_RESOURCE,
        payload: { asset_ids },
    };
};

export const cascadeDeleteAsset = (asset) => {
    return {
        type: K.ACTIONS.CASCADE_DELETE_ASSET,
        payload: { asset },
    };
};

export const updateAssetChartOrders = (entity_id, asset_id, chart_orders, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_CHART_ORDERS,
        payload: { entity_id, asset_id, chart_orders },
        callback,
    };
};

export const setAssetsChartsResource = (charts) => {
    return {
        type: K.ACTIONS.SET_CHARTS_RESOURCE,
        payload: { charts },
    };
}

export const addAssetsChartsResource = (charts) => {
    return {
        type: K.ACTIONS.ADD_CHARTS_RESOURCE,
        payload: { charts },
    };
}


export const removeAssetChartsResource = (asset_id, chart_ids) => {
    return {
        type: K.ACTIONS.REMOVE_CHARTS_RESOURCE,
        payload: { asset_id, chart_ids },
    };
}

