import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type UserModel from "../../api/models/user.model"

interface AuthState {
    isInitialising: boolean
    isAuthenticating: boolean
    isLoggedIn: boolean
    user: UserModel | null
}

const initialState: AuthState = {
    isInitialising: true,
    isAuthenticating: false,
    isLoggedIn: false,
    user: null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setInitialised: (state, action: PayloadAction<UserModel | undefined>) => {
            state.isInitialising = false
            if (action.payload !== undefined) {
                state.isLoggedIn = true
                state.user = action.payload
                return
            }

            state.isLoggedIn = false
            state.user = null
        },
        setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticating = action.payload
        },
        setLoggedIn: (state, action: PayloadAction<UserModel>) => ({
            ...state,
            isAuthenticating: false,
            isLoggedIn: true,
            user: { ...action.payload },
        }),
        setLoggedOut: (state) => ({
            ...state,
            isAuthenticating: false,
            isLoggedIn: false,
            user: null,
        }),
    },
})

export const {
    setInitialised,
    setIsAuthenticating,
    setLoggedIn,
    setLoggedOut,
} = authSlice.actions

export default authSlice.reducer
