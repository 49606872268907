/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvitedUsers, removeInviteUser, resendInviteUser } from '../../../store/old/Invitation/Invitation.action';
import './UsersManagement.scss'
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import { Popconfirm, Table } from 'antd';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import InviteForm from './InviteForm'
import { values } from 'lodash';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';

const InvitesTab = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(userSelector)
    const { entity_id } = useSelector(currentEntitySelector)
    const invitationResource = useSelector(appState => appState.invitation.invitations);
    const invitations = useMemo(() => values(invitationResource), [invitationResource]);

    const [showInviteDialog, setShowInviteDialog] = useState(false)

    useEffect(() => {
        dispatch(fetchInvitedUsers(entity_id))
    }, [])

    const resend = (row) => {
        dispatch(resendInviteUser(entity_id, row.user_id, authUser.user_id))
    }

    const revoke = (row) => {
        dispatch(removeInviteUser(entity_id, row.user_id))
    }

    const columns = [
        {
            title: translate('email'),
            dataIndex: 'email'
        },
        {
            title: translate('status'),
            render: (text, record, index) => { 
                return record.invite_code ? 'Registration required' : 'Awaiting response'
            },
        },
        {
            render: (text, record, index) => { 
                return (
                <Permission forResource resource="users" canDo="full">
                    <div className='d-flex'>
                        <AukButton.Outlined
                            style={{ width: 80 }}
                            onClick={() => resend(record)}
                        >
                            {translate('resend')}
                        </AukButton.Outlined>
                        <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => revoke(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <AukButton.Red style={{ width: 80 }}>
                            {translate('revoke')}
                        </AukButton.Red>

                    </Popconfirm>
                    </div>
                </Permission>
                )
            },
        },
    ];

    return (
        <div className="invites-tab-content">
            <Permission forResource resource="users" canDo="edit">
                <div className="d-flex w-100 justify-content-end">
                    <AukTooltip.Help title={"Invite user(s)"}>
                        <AukButton.Outlined
                            onClick={() => setShowInviteDialog(true)}
                            className="auk-button auk-button--round"
                        >
                            <i className="fas fa-plus" />
                        </AukButton.Outlined>
                    </AukTooltip.Help>
                </div>
            </Permission>
            <Table
                style={{ marginTop: '0.5em' }}
                pagination={10}
                columns={columns}
                dataSource={invitations}
                rowKey="invitation_id"
            />
            <InviteForm open={showInviteDialog} cancel={() => setShowInviteDialog(false)}/>
        </div>
    );
};

export default InvitesTab;
