/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import PortalWrapper from '../../../components/PortalWrapper';

import './Tooltips_V1.scss';

const BaseChartTooltipSimple = (props, ref) => {
    return (
        <PortalWrapper>
            <div className="chart-v1__tooltip" ref={ref}>
                {props.children}
            </div>
        </PortalWrapper>
    );
};

export const BaseChartTooltip = forwardRef(BaseChartTooltipSimple);
