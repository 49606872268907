/* eslint-disable */
import { round } from 'lodash';
import { csvDatetimeFormat } from '../../utils/helpers';
import { oeeHeadersCSV } from '../../utils/oee';
import moment from 'moment';

const getOEEFactors = (factors) => {
    return (
        'OEE Summary, Percent\n' +
    factors
        .map((f) => {
            return `${f.label}, ${round(f.value, 2)}`;
        })
        .join('\n') +
    '\n'
    );
};

const getDataRows = (data) => {
    return data
        .map((datum) => {
            return (
                moment(datum.time).format(csvDatetimeFormat) +
        ',' +
        oeeHeadersCSV.map(({ key }) => round(datum.oee[key], 2)).join(',')
            );
        })
        .join('\n');
};

const getAggregateRows = (data) => {
    return [
        'Total (hours),' +
      oeeHeadersCSV
          .map(({ key }) =>
              round((data[key] * data.totalSeconds) / 100 / 3600, 2)
          )
          .join(','),
        'Percent,' + oeeHeadersCSV.map(({ key }) => round(data[key], 2)).join(','),
    ].join('\n');
};

const getChunk = (block, oeeMasks) => {
    const label = `"${block.label}"`;
    const factorRows = getOEEFactors(block.oee.factors);
    const columns =
    'Time,' +
    oeeHeadersCSV
        .map((cat) => (oeeMasks[cat.key] ? oeeMasks[cat.key].mask : cat.altLabel))
        .join(',');
    const rows = getDataRows(block.oee.oee);
    const aggregateRows = getAggregateRows(block.oee.aggregate_oee);

    return [label, factorRows, columns, rows, aggregateRows].join('\n');
};

export const blocksCSV = (block, masks) => {
    const blocks = [block].concat(...block.children);
    return blocks.map((b) => getChunk(b, masks)).join('\n\n');
};
