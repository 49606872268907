import { ControlsConstants as K } from './Controls.constants';

export const ToggleOEE2 = () => {
    return {
        type: K.ACTIONS.TOGGLE_OEE2,
    };
};

export const ToggleOEESKU = () => {
    return {
        type: K.ACTIONS.TOGGLE_OEE_SKU,
    };
};

export const SetControls = (payload) => {
    return {
        type: K.ACTIONS.SET_CONTROL_PANEL_STATE,
        payload,
    };
};

export const initControlPanel = (params, callback) => {
    return {
        type: K.ACTIONS.INIT_CONTROL_PANEL,
        payload: params,
        callback
    };
};
