/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { values } from 'lodash';
import { Form, Input, Modal, Radio, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CONSTANTS from '../../Constants';
import { containsSuspiciousCharacters } from '../../utils/validate';
import AukButton from '../../components/AukButton';
import BlocksTree from './BlocksTree';
import { DangerZoneItem } from '../../components/DangerZone';
import './BlockForm.scss';

const { confirm } = Modal;
const { OR, SPEED, FIXED } = CONSTANTS.BLOCKS;

const BLOCK_OPERATORS = [
    { label: 'Sequence', value: 'seq' },
    { label: 'And', value: 'and' },
    { label: 'Or', value: OR },
];

const BLOCK_VALIDATE = {
    BLOCK_NAME: [
        {
            required: true,
            message: 'Block Name is required',
        },
        {
            whitespace: true,
            message: 'Block name is required.',
        },
        () => ({
            validator: (_, value) => {
                if (containsSuspiciousCharacters(value))
                    return Promise.reject(
                        new Error('Block name cannot contain #%&*?|<>\\.')
                    );
                return Promise.resolve();
            },
        }),
    ],
    WEIGHTAGE: [
        {
            required: true,
            message: 'Weightage is required (default 100)',
        },
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Weightage must be greater than or equal to 0',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 99999,
            message: 'Weightage must be less than or equal to 99999',
        },
    ],
    BN_BLOCK_ID: [
        ({ getFieldValue }) => ({
            validator: (_, value) => {
                if (getFieldValue('bn_mode') === FIXED) {
                    return !!value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Bottleneck selection is required.'));
                }
                return Promise.resolve();
            },
        }),
    ],
};

const BlockForm = (props) => {
    const {
        block = {},
        submit,
        onDelete,
        isInit = false,
        cancel,
        showCancel = false,
    } = props;

    const [form] = Form.useForm();
    const [operator, setOperator] = useState(block.operation || OR);
    const [bnMode, setBnMode] = useState(block.bn_mode || SPEED);

    const isOrBlock = useMemo(() => operator === OR, [operator]);
    const isFixedBn = useMemo(() => bnMode === FIXED, [bnMode]);

    const getFormData = (d) => {
        const { block_id } = block;
        const { children, weightage, block_name, operation, bn_mode, bn_block_id } =
      d;

        const result = {
            weightage: +weightage,
            block_name,
            operation,
        };

        if (!block_id) return result;

        result.block_id = block_id;
        result.child_weightages = children.map((c) => ({
            block_id: c.block_id,
            weightage: +c.weightage,
        }));

        if (operation !== OR) {
            result.bn_mode = bn_mode;
            result.bn_block_id = bn_mode === FIXED ? bn_block_id : null;
        }

        return result;
    };

    return (
        <Form
            name="blockForm"
            id="blockForm"
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            className="block-form"
            onFinish={() => submit(getFormData(form.getFieldsValue(true)))}
            onValuesChange={(changedValues) => {
                if (changedValues.operation) setOperator(changedValues.operation);
                if (changedValues.bn_mode) setBnMode(changedValues.bn_mode);
            }}
        >
            <div className="block-form__fields">
                <Form.Item
                    style={{ marginTop: 24 }}
                    name="block_name"
                    initialValue={block.block_name || ''}
                    label="Name"
                    rules={BLOCK_VALIDATE.BLOCK_NAME}
                >
                    <Input maxLength={50} />
                </Form.Item>
                <Form.Item
                    style={{ marginTop: 24 }}
                    name="operation"
                    initialValue={block.operation || OR}
                    label="Operation"
                >
                    <Select options={BLOCK_OPERATORS} />
                </Form.Item>
                <Form.Item
                    style={{ marginTop: 24 }}
                    name="weightage"
                    initialValue={isFinite(block.weightage) ? block.weightage : 100}
                    label="Weightage"
                    rules={BLOCK_VALIDATE.WEIGHTAGE}
                >
                    <Input type="number" maxLength={5} />
                </Form.Item>
                {!isInit && (
                    <>
                        <Form.Item label="Child Processes">
                            <Form.List name="children" initialValue={block.children || []}>
                                {(fields, { add, remove }, { errors }) => {
                                    return (
                                        <div
                                            className="p-3"
                                            style={{ border: '1px solid #ddd', borderRadius: 3 }}
                                        >
                                            {fields.map((field, i) => {
                                                const child = block.children[i];
                                                return (
                                                    <Form.Item
                                                        labelAlign="left"
                                                        colon={false}
                                                        labelCol={{ span: 6 }}
                                                        wrapperCol={{ span: 18 }}
                                                        key={i}
                                                        name={[field.name, 'weightage']}
                                                        label={
                                                            child.asset
                                                                ? child.asset.asset_name
                                                                : child.block_name
                                                        }
                                                        rules={BLOCK_VALIDATE.WEIGHTAGE}
                                                    >
                                                        <Input maxLength={5} />
                                                    </Form.Item>
                                                );
                                            })}

                                            <Form.ErrorList errors={errors} />
                                        </div>
                                    );
                                }}
                            </Form.List>
                        </Form.Item>
                        <Form.Item noStyle hidden={isOrBlock}>
                            <Form.Item
                                name="bn_mode"
                                label="Bottleneck Mode"
                                initialValue={block.bn_mode || SPEED}
                            >
                                <Radio.Group
                                    className="d-flex flex-column p-3"
                                    style={{ border: '1px solid #ddd', borderRadius: 3 }}
                                >
                                    {values(CONSTANTS.BLOCKS.BN_MODE)
                                        .filter((option) => option.show)
                                        .map((option, i) => (
                                            <Radio value={option.value} key={i}>
                                                {option.name}
                                                <p>
                                                    <i>{option.description}</i>
                                                </p>
                                            </Radio>
                                        ))}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="bn_block_id"
                                label="Bottleneck"
                                initialValue={block.bn_block_id}
                                hidden={!isFixedBn}
                                rules={BLOCK_VALIDATE.BN_BLOCK_ID}
                            >
                                <BlocksTreeFormItem
                                    data={block}
                                    canSelect={(b) => b.block_id !== block.block_id}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            label=" "
                            className="mb-3"
                            colon={false}
                            hidden={!block.parent_block_id}
                        >
                            <DangerZoneItem
                                title="Remove block"
                                description="Reset child processes, may affect OEE of parent processes."
                                button={
                                    <AukButton.Red
                                        size="small"
                                        onClick={() => {
                                            confirm({
                                                title: `Remove block ${block.block_name || ''}`,
                                                icon: <ExclamationCircleOutlined />,
                                                content: 'This action is not reversible, continue?',
                                                onOk: () => onDelete && onDelete(block),
                                            });
                                        }}
                                    >
                    Delete
                                    </AukButton.Red>
                                }
                            />
                        </Form.Item>
                    </>
                )}
            </div>
            <div className="block-form__submit">
                <AukButton.Save htmlType="submit" />
                {showCancel && <AukButton.Cancel onClick={cancel} />}
            </div>
        </Form>
    );
};

BlockForm.defaultProps = {};

const BlocksTreeFormItem = (props) => {
    const { onChange, value } = props;

    return (
        <div
            className="p-3 w-100 h-100"
            style={{ border: '1px solid #ddd', borderRadius: 3 }}
        >
            <BlocksTree
                {...props}
                selectable
                selected={value}
                onSelect={(b) => onChange(b ? b.block_id : null)}
            />
        </div>
    );
};

export default BlockForm;
