import "./style.scss"
import { TextField, type TextFieldProps } from "@mui/material"
import React, { type ChangeEvent, type ChangeEventHandler, type ForwardedRef, forwardRef, useEffect } from "react"

const TextInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { errorText, onChange, endAdornment, ...inputProps } = props

    useEffect(() => {
        if (props.required === false || !props.onError) {
            return
        }

        if (!props.value) {
            const field = props.label ? props.label[0].toUpperCase() + props.label.slice(1).toLowerCase() : "This field"
            props.onError(`${field} is required`)
        }
    }, [props.required, props.value])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.maxLength && e.target.value.length > props.maxLength) {
            return
        }

        if (onChange) {
            onChange(e)
        }

        if (props.onTextChange) {
            props.onTextChange(e.target.value)
        }
    }

    return <TextField
        variant="filled"
        {...inputProps}
        ref={ref}
        required={props.required ?? false}
        hiddenLabel={!props.label}
        onChange={handleChange}
        inputProps={{ maxLength: props.maxLength, style: { textAlign: props.align } }}
        InputProps={{
            autoComplete: props.autoComplete,
            endAdornment: endAdornment,
            readOnly: props.readOnly,
        }}
        error={errorText !== undefined}
        helperText={errorText ?? props.helperText}
    />
})

export type Props = TextFieldProps & {
    ref: ForwardedRef<HTMLDivElement>
    align?: "start" | "end" | "center"
    maxLength?: number
    label?: string
    helperText?: string
    autoComplete?: string
    errorText?: string
    disabled?: boolean
    required?: boolean
    readOnly?: boolean
    onTextChange?: (val: string) => void
    onError?: (val: string) => void
    endAdornment?: React.ReactElement
    onChange?: ChangeEventHandler<HTMLInputElement>
}

TextInput.displayName = "TextInput"

export default TextInput
