import { pickBy } from 'lodash';
import { updateStoreRedux } from '../../../legacy/utils/redux';

// CONSTANT
import { InvitationConstants as K } from './Invitation.constant';

const initialReducer = {
    invitations: {},
    // error: {},
};

export const invitationsReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.SET_INVITATIONS_RESOURCE) {
        return updateStoreRedux(state, {
            invitations: action.payload.invitations,
        });
    }

    if (action.type === K.ACTIONS.ADD_INVITATIONS_RESOURCE) {
        return updateStoreRedux(state, {
            invitations: { ...state.invitations, ...action.payload.invitations },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_INVITATIONS_RESOURCE) {
        const deleteSet = new Set(action.payload.user_ids);
        const deletedState = pickBy(
            { ...state.invitations },
            ({ user_id }) => !deleteSet.has(user_id)
        );

        return updateStoreRedux(state, {
            invitations: deletedState,
        });
    }

    // if (
    //     action.type === K.ACTIONS.FETCH_INVITATIONS_FAILURE ||
    // action.type === K.ACTIONS.CREATE_INVITATION_FAILURE ||
    // action.type === K.ACTIONS.UPDATE_INVITATION_FAILURE ||
    // action.type === K.ACTIONS.REVOKE_INVITATION_FAILURE
    // ) {
    //     return updateStoreRedux(state, {
    //         error: action.payload.error,
    //     });
    // }

    return updateStoreRedux(state, {});
};
