import React, { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { issuesState } from "../../../../store/old/Issues/Issues.selector"
import { blocksState } from "../../../../store/old/Blocks/Blocks.selector"
import { keys, pickBy } from "lodash"
import { generatePermittedIssuesTreeData } from "../../IssueManagement/IssuesTreeData"
import { isQualityIssue } from "../../../utils/issues"
import SearchableTree from "../../../components/SearchableTree"
import { Input } from "antd"

const PermittedIssuesTreeSelect = (props: any) => {
    const { withDefects, data, onChange, forBlocks } = props

    const issuesStore = useAppSelector(issuesState)
    const blocksStore = useAppSelector(blocksState)

    const blocksIssuesExclusions = useMemo(() => {
        const { blocks, blocks_issues_exclusions } = blocksStore
        return forBlocks.reduce((result: any, blockId: number) => {
            const block = blocks[blockId]
            if (block && block.asset_id) {
                return { ...result, [blockId]: blocks_issues_exclusions[blockId] }
            }

            const childAssets = block.children.filter((c: any) => c.asset_id)

            const allChildExclusions = childAssets
                .map((child: any) => keys(blocks_issues_exclusions[child.block_id]))
                .reduce((acc: any, childExclusions: any) => [...acc, ...childExclusions], [])
                .reduce((acc: any, curr: any) => {
                    return {
                        ...acc,
                        [curr]: acc[curr] ? acc[curr] + 1 : 1
                    }
                }, {})
            
            return {
                ...result,
                [blockId]: pickBy(allChildExclusions, v => v === childAssets.length)
            }
        }, {})
    }, [forBlocks, blocksStore])

    const treeData = useMemo(
        () =>
            generatePermittedIssuesTreeData(
                issuesStore,
                forBlocks,
                blocksIssuesExclusions
            ),
        [issuesStore, blocksIssuesExclusions]
    )

    const [defects, setDefects] = useState(
        data && data.defects ? data.defects : null
    )
    const [selection, setSelection] = useState(data ? [data.issue] : [])

    const isQualitySelection = useMemo(
        () => selection.length ? isQualityIssue(selection[0]) : false,
        [selection]
    )

    const getFormData = (value: any) => {
        const defectCount = withDefects
            ? value
                ? isQualityIssue(value)
                    ? { defects }
                    : {}
                : {}
            : {}

        return {
            issue_id: value ? value.issue_id : null,
            ...defectCount,
        }
    }

    useEffect(() => {
        onChange && onChange(getFormData(selection.length ? selection[0] : null))
    }, [selection, defects])

    const showDefectsInput = withDefects && isQualitySelection

    const selectedKeys = selection.map(({ issue_id }) => issue_id)
    const defaultSearch = data ? data.issue.name : ""

    return (
        <>
            <div className="w-100" style={{ height: 380 }}>
                <SearchableTree
                    search={{ placeholder: "Type to filter" }}
                    tree={{
                        treeData,
                        onSelect: (d: any) =>
                            setSelection(d[0] ? [issuesStore.issues[d[0]]] : []),
                        selectedKeys: selectedKeys,
                    }}
                    data={issuesStore.issues}
                    parentKeyAccessor={(i: any) => i.parent_issue_id}
                    nodeKeyAccessor={(treeNode: any) => +treeNode.key}
                    defaultExpanded={[1, 2, 3, 4]}
                    defaultSearch={defaultSearch}
                />
            </div>
            {showDefectsInput ? 
                <Input
                    addonBefore="Defects"
                    type="number"
                    placeholder="No. of reject / rework items"
                    value={defects}
                    onChange={(e) => setDefects(e.target.value)}
                />
                : null}
        </>
    )
}

export default PermittedIssuesTreeSelect