import { RegressionConstants as K } from './RegressionUI.constants';

export const changeRegressionSelection = (selection) => {
    return {
        type: K.ACTIONS.REGRESSION_SET_SELECTION,
        payload: { selection },
    };
};

export const fetchRegressionDataRequest = (callback) => {
    return {
        type: K.ACTIONS.REGRESSION_FETCH_DATA_REQUEST,
        callback,
    };
};

export const fetchRegressionDataRequestSuccess = (data) => {
    return {
        type: K.ACTIONS.REGRESSION_FETCH_DATA_REQUEST_SUCCESS,
        payload: { data },
    };
};

export const changeRegressionModel = (regression) => {
    return {
        type: K.ACTIONS.REGRESSION_SET_REGRESSION,
        payload: { regression },
    };
};

export const changeRegressionBound = (bound, value) => {
    return {
        type: K.ACTIONS.REGRESSION_CHANGE_BOUND,
        payload: { bound, value },
    };
};

export const changeRegressionForm = (data, callback) => {
    return {
        type: K.ACTIONS.REGRESSION_CHANGE_FORM,
        payload: { data },
        callback,
    };
};

export const resetRegressionStore = () => {
    return {
        type: K.ACTIONS.REGRESSION_RESET_STORE,
    };
};
