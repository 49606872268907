import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import CONSTANTS from '../../../../../Constants';
import Select from '../../../../../components/Select';
import { Permission } from '../../../../../components/Permission';

const TIME_UNITS = [
    { label: 'minutes', value: 'minutes' },
    { label: 'hours', value: 'hours' },
];

const OEE_CAT = (masks) =>
    Object.keys(CONSTANTS.CAT).map((cat) => {
        return {
            label: masks[cat] ? masks[cat].mask : CONSTANTS.CAT[cat].altLabel,
            value: cat,
            color: CONSTANTS.CAT[cat].color,
        };
    });

class AlertsTab extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }

    get data() {
        return this.props.data.filter(
            (datum) => _.has(datum, 'cat') &&
              _.has(datum, 'dur_x') &&
              _.has(datum, 'dur_period')
        );
    }

    render() {
        const { data } = this;

        return (
            <div className="configuration-alerts-tab">
                <h5 className="text-uppercase">
                    <code>Alert if not labelled:</code>
                </h5>
                <ul style={{ paddingInlineStart: 20 }}>
                    {data.map((a, i) => (
                        <AlertRow
                            key={i}
                            index={i}
                            data={a}
                            handleChange={this.props.handleChange}
                            handleDelete={this.props.handleDelete}
                            masks={this.props.oeeMasks}
                        />
                    ))}
                </ul>
                <button className="add-alert btn b-new" onClick={this.props.handleAdd}>
          Add Alert
                </button>
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        authUser: rootState.auth.user,
        oeeMasks: rootState.ui.oee.masks,
    };
};

export default connect(mapStateToProps, null)(AlertsTab);

const getOptionLabel = (opt) => {
    return (
        <div
            className="d-flex flex-row align-items-center"
            style={{
                fontSize: 12,
            }}
        >
            <div
                className="mr-1"
                style={{
                    height: 10,
                    width: 10,
                    backgroundColor: opt.color,
                }}
            />
            {opt.label}
        </div>
    );
};

const AlertRow = (props: any) => {
    const {
        data: { cat, dur_x, dur_period },
        index,
        handleChange,
        masks,
    } = props;

    const options = OEE_CAT(masks);
    return (
        <li>
            <div className="alert-row mb-2">
                <Select
                    className="oee-select"
                    options={options}
                    value={options.find(({ value }) => value === cat) || null}
                    onChange={({ value }) => handleChange(index, 'cat', value)}
                    getOptionLabel={(opt) => getOptionLabel(opt)}
                    placeholder="OEE Category"
                    hideSelectedOptions
                />
                <span className="mx-2" style={{ flexShrink: 0 }}>
          for last consecutive
                </span>
                <input
                    type="number"
                    className="form-control"
                    style={{ width: 80 }}
                    value={dur_x}
                    placeholder="e.g. 15"
                    onChange={(e) => handleChange(index, 'dur_x', +e.target.value)}
                />
                <Select
                    options={TIME_UNITS}
                    value={TIME_UNITS.find(({ value }) => value === dur_period) || null}
                    onChange={({ value }) => handleChange(index, 'dur_period', value)}
                    placeholder="min / hour"
                    hideSelectedOptions
                />
                <Permission resource="assets" forResource canDo="full">
                    <span
                        style={{
                            width: 20,
                            color: '#d60606',
                            cursor: 'pointer',
                        }}
                        onClick={() => props.handleDelete(index)}
                    >
                        <i className="fas fa-minus-circle" />
                    </span>
                </Permission>
            </div>
        </li>
    );
};
