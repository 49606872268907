import "./style.scss"
import { Box, Grid } from "@mui/material"
import AddButton from "../../../components/Button/AddButton/AddButton"
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog"
import Page, { PageTitle } from "../../../components/Page"
import DeleteButton from "../../../components/Button/DeleteButton/DeleteButton"

import React, { useEffect, useState } from "react"
import { GoalsUnitOfTimeEnum } from "../../../constants/goals.constants"
import { objectivesDestroyAction, objectivesGetAction } from "../../../store/actions/goals.action"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setUnitOfTime, toggleDialog } from "../../../store/reducers/goals.reducer"
import ObjectivesAddDialog from "./components/ObjectivesAddDialog/ObjectivesAddDialog"
import ObjectivesList from "./components/ObjectivesList/ObjectivesList"
import UnitOfTimeSwitch from "./components/UnitOfTimeSwitch/UnitOfTimeSwitch"

const ObjectivesPage = () => {
    const unitOfTime = useAppSelector(({ goals }) => goals.unitOfTime)
    const dispatch = useAppDispatch()

    const [selected, setSelected] = useState<number[]>([])
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

    useEffect(() => {
        void dispatch(objectivesGetAction())
    }, [unitOfTime])

    const handleAddPress = () => {
        void dispatch(toggleDialog({ type: "add", value: true }))
    }

    const handleDeletePress = () => {
        setConfirmOpen(true)
    }

    const handleConfirm = () => {
        void dispatch(objectivesDestroyAction(selected))
    }

    const handleUnitChange = (value: GoalsUnitOfTimeEnum) => {
        void dispatch(setUnitOfTime(value))
    }

    return <Page className="goals-plan-page">
        <Grid container direction="column" columnSpacing="16px" height="100%" wrap="nowrap">
            <Grid item className="page-header" justifyContent="space-between">
                <PageTitle>Goals</PageTitle>
                <UnitOfTimeSwitch value={unitOfTime} onChange={handleUnitChange} />
                <Box>
                    <DeleteButton
                        className="header-btn-delete"
                        onPress={handleDeletePress}
                        size="small"
                        disabled={selected.length === 0}
                    />
                    <AddButton onPress={handleAddPress}>New Goal Plan</AddButton>
                </Box>
            </Grid>
            <Grid item flex={1} className="page-body" justifyContent="space-between" sx={{ overflow: "auto" }}>
                <ObjectivesList onAdd={handleAddPress} onSelect={setSelected} />
            </Grid>
        </Grid>
        <ConfirmationDialog
            title="Delete goals"
            onConfirm={handleConfirm}
            setOpen={setConfirmOpen}
            open={confirmOpen}
        >
            Are you sure you want to delete these goals?
        </ConfirmationDialog>
        <ObjectivesAddDialog />
    </Page>
}

export default ObjectivesPage
