

export const CanvasConstants = {
    ACTIONS: {
        RESET_CANVAS_STATE: 'RESET_CANVAS_STATE',
        SET_CANVAS_RESOURCE: 'SET_CANVAS_RESOURCE',
        ADD_CANVAS_RESOURCE: 'ADD_CANVAS_RESOURCE',
        REMOVE_CANVAS_RESOURCE: 'REMOVE_CANVAS_RESOURCE',
        CREATE_CANVAS_REQUEST: 'CREATE_CANVAS_REQUEST',
        UPDATE_CANVAS_REQUEST: 'UPDATE_CANVAS_REQUEST',
        DELETE_CANVAS_REQUEST: 'DELETE_CANVAS_REQUEST',
    },
    TYPES: {
        DASHBOARD: 'DASHBOARD',
        ASSET: 'ASSET',
        REPORT: 'REPORT',
    },
};
