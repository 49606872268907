export const vi = {
    notes: 'Notes',
    load: 'Lấy',
    export: 'Tải xuống',
    unutilised: 'Un-sử dụng',
    breakdowns: 'sự cố',
    threshold: 'ngưỡng',
    oeepreview: 'OEE preview',
    heatmappreview: 'Heatmap Preview',
    oeecolors: 'OEE màu sắc',
    color: 'màu sắc',
    reset: 'Cài lại',
    theme: 'chủ đề',
    defaultview: 'giao diện mặc định',
    defaulttimeselection: 'lựa chọn thời gian mặc định',
    zoomlevel: 'Mức độ phóng to',
    entity: 'thực thể',
    preferences: 'Sở thích',
    custom: 'Tập quán',
    enteryourmailtoreset: 'Nhập email của bạn để thiết lập lại',
    goback: 'Quay lại',
    resetpassword: 'Đặt lại mật khẩu',
    logout: 'Đăng xuất',
    ownersemail: 'chủ sở hữu Email',
    optional: 'Không bắt buộc',
    industry: 'Công nghiệp',
    timezone: 'Múi giờ',
    nocoderequesttojoin: 'TT Mã yêu cầu tham gia',
    enterreferralcodeinstead: 'Nhập mã giới thiệu thay vì',
    setup: 'Thiết lập',
    company: 'Công ty',
    referralcode: 'mã giới thiệu',
    submit: 'Gửi đi',
    summary: 'Tóm lược',
    language: 'ngôn ngữ',
    login: 'Đăng nhập',
    signin: 'Đăng nhập',
    signup: 'Đăng ký',
    username: 'tên tài khoản',
    password: 'Mật khẩu',
    forgotpassword: 'Quên mật khẩu?',
    rememberme: 'Ghi nhớ tôi',
    register: 'Đăng ký',
    registration: 'Đăng ký',
    firstname: 'Tên đầu tiên',
    lastname: 'tên họ',
    email: 'E-mail',
    mobile: 'di động',
    country: 'Quốc gia',
    contactnumber: 'Số liên lạc',
    appointment: 'Cuộc hẹn',
    role: 'Vai trò',
    admin: 'quản trị viên',
    owner: 'chủ nhân',
    editor: 'biên tập viên',
    viewer: 'người xem',
    confirmpassword: 'Xác nhận mật khẩu',
    iacceptthe: 'Tôi chấp nhận các',
    useragreement: 'Sự thỏa thuận của người dùng',
    alreadyregisteredlogin: 'Đã đăng ký? Đăng nhập',
    softwareasaservicetermsofuse: 'Software-as-a-Service Quy định sử dụng',
    dashboard: 'bảng điều khiển',
    streaming: 'trực tuyến',
    resolution: 'Nghị quyết',
    seconds: 'giây',
    second: 'thứ hai',
    minutes: 'từ phút',
    minute: 'phút',
    hours: 'giờ',
    hour: 'giờ',
    weeks: 'tuần',
    week: 'Tuần',
    months: 'tháng',
    month: 'tháng',
    quarters: 'quý',
    quarter: 'phần tư',
    years: 'năm',
    year: 'năm',
    from: 'Từ',
    to: 'Đến',
    start: 'khởi đầu',
    end: 'Kết thúc',
    display: 'Trưng bày',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Chuỗi thời gian',
    gateway: 'cổng vào',
    statusasof: 'Tình trạng như của',
    active: 'tích cực',
    offline: 'ẩn',
    mergeasonemachine: 'Merge như một máy',
    drawarrowstoformprocessmap: 'Vẽ mũi tên vào bản đồ quá trình hình',
    openblockeditor: 'biên tập viên khối Mở',
    createnewtile: 'Tạo mới Ngói',
    summarytile: 'ngói Tóm tắt thông tin',
    selectablockthennameyoursummarytile:
    'Chọn một khối, sau đó đặt tên ngói tóm tắt của bạn',
    name: 'Tên',
    blockselector: 'khối selector',
    asset: 'tài sản',
    therearenolabelstaggedinthisperiod:
    'Không có nhãn được gắn thẻ trong giai đoạn này',
    standardtimes: 'Giờ chuẩn (s)',
    total: 'Toàn bộ',
    last: 'Cuối cùng',
    oeeinput: 'OEE đầu vào',
    chartcolor: 'màu biểu đồ',
    issueeditor: 'vấn đề biên tập viên',
    issuemangement: 'Mangement vấn đề',
    cumulativeoutput: 'sản lượng tích lũy',
    tag: 'Nhãn',
    label: 'Nhãn',
    valueval: 'Giá trị (Val)',
    time: 'Thời gian',
    januaryjan: 'Tháng mười hai (Jan)',
    februaryfeb: 'Tháng hai (tháng)',
    marchmar: 'Tháng (Mar)',
    aprilapr: 'Tháng tư (tháng tư)',
    may: 'có thể',
    junejun: 'June (Jun)',
    julyjul: 'Tháng bảy (tháng bảy)',
    augustaug: 'August (tháng tám)',
    septembersep: 'Tháng Chín (Sep)',
    octoberoct: 'Tháng mười hai (tháng mười)',
    novembernov: 'Tháng mười một (Nov)',
    decemberdec: 'Tháng mười hai (Đéc)',
    day: 'ngày',
    days: 'ngày',
    mondaymonm: 'Thứ hai (Thứ Hai) M',
    tuesdaytuet: 'Thứ Ba (Tue) T',
    wednesdaywedw: 'Thứ Tư (Wed) W',
    thursdaythut: 'Thứ Năm (Thu) T',
    fridayfrif: 'Thứ Sáu (Thứ Sáu) F',
    saturdaysats: 'Thứ Bảy (Bảy) S',
    sundaysuns: 'Chủ Nhật (Sun) S',
    edit: 'Biên tập',
    add: 'Thêm vào',
    new: 'Mới',
    create: 'Tạo nên',
    search: 'Tìm kiếm',
    close: 'Đóng',
    cancel: 'hủy bỏ',
    save: 'Tiết kiệm',
    saved: 'Saved',
    clear: 'Thông thoáng',
    enter: 'Đi vào',
    navigator: 'Hoa tiêu',
    mandatoryfields: 'Các trường bắt buộc',
    select: 'Lựa chọn',
    merge: 'Merge',
    delete: 'Xóa bỏ',
    done: 'Làm xong',
    filter: 'Lọc',
    table: 'Bàn',
    columns: 'cột',
    rows: 'hàng',
    cell: 'tế bào',
    page: 'Trang',
    previous: 'Trước',
    next: 'Kế tiếp',
    serialnumber: 'Số Sê-ri',
    manuallyinputserialnumber: 'Bằng tay đầu vào Serial Number',
    updateasset: 'cập nhật nội dung',
    machine: 'Máy móc',
    brand: 'Nhãn hiệu',
    model: 'Mô hình',
    nodes: 'Điểm giao',
    fusions: 'Fusions',
    chartorder: 'Bảng xếp hạng theo thứ tự',
    primary: 'Sơ cấp',
    adddevicechannel: 'Thêm thiết bị - Kênh',
    pushratesec: 'Đẩy Rate (giây)',
    voltagev: 'Điện áp (V)',
    sensor: 'cảm biến',
    mode: 'Chế độ',
    digitalcount: 'Đếm kỹ thuật số',
    digitalstate: 'Nhà nước kỹ thuật số',
    analogvoltage: 'analog Voltage',
    charttitle: 'Tiêu đề biểu đồ',
    unitmeasure: 'Biện pháp đơn vị',
    attribute: 'đặc tính',
    autocycletime: 'Hiện Auto Cycle',
    unittransform: 'đơn vị chuyển đổi',
    leaveblankifnotapplicable: 'Để trống nếu không áp dụng',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'lĩnh vực bắt buộc (để trống nếu kênh không sử dụng)',
    fusedchart: 'Fused Chart',
    fusionname: 'Fusion Tên',
    charttype: 'Loại biểu đồ',
    column: 'Cột',
    line: 'Hàng',
    state: 'Tiểu bang',
    expression: 'Biểu hiện',
    combination: 'Sự phối hợp',
    currentcharts: 'Charts hiện tại',
    expressionconstructor: 'biểu thức Khối',
    validoperators: 'khai thác hợp lệ',
    leaveblanktokeepexistingexpression: 'Để trống để giữ biểu hiện',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Hiệu quả thiết bị tổng thể',
    legend: 'Huyền thoại',
    loading: 'Đang tải',
    availability: 'khả dụng',
    performance: 'Hiệu suất',
    quality: 'Chất lượng',
    unscheduled: 'đột xuất',
    planneddowntime: 'Planned Downtime',
    changeoversetup: 'Chuyển đổi / Cài đặt',
    breakdown: 'Phá vỡ',
    unutilized: 'Un-sử dụng',
    speedloss: 'tốc độ mất',
    minorstops: 'Tiểu Dừng',
    rework: 'Làm lại',
    rejectscrap: 'Từ chối / phế liệu',
    nodata: 'Không có dữ liệu',
    blockeditor: 'block Editor',
    sort: 'loại',
    logicblocks: 'Logic Blocks',
    sequence: 'Sự nối tiếp',
    and: 'và',
    or: 'Hoặc là',
    utilizationheatmap: 'sử dụng Heatmap',
    startdate: 'ngày bắt đầu',
    enddate: 'Ngày cuối',
    daily: 'hằng ngày',
    weekly: 'hàng tuần',
    monthly: 'hằng tháng',
    quarterly: 'hàng quý',
    yearly: 'hàng năm',
    trendanalysis: 'Phân tích xu hướng',
    metricselection: 'metric Selection',
    metric: 'Hệ mét',
    data: 'Dữ liệu',
    target: 'Mục tiêu',
    periodselection: 'Thời gian lựa chọn',
    span: 'nhịp cầu',
    every: 'Mỗi',
    repeat: 'Nói lại',
    repeats: 'lặp lại',
    addsingle: 'Thêm Độc thân',
    paretoanalysis: 'Phân tích Pareto',
    calendartime: 'Hiện Lịch',
    scheduledtime: 'thời gian dự kiến',
    operatingtime: 'thời gian hoạt động',
    netruntime: 'thời gian chạy Net',
    effective: 'Có hiệu lực',
    loadingloss: 'Đang tải Mất',
    availabilityloss: 'Availability Mất',
    performanceloss: 'hiệu suất Mất',
    qualityloss: 'chất lượng Mất',
    notagsinthiscategory: 'Không có thẻ nào trong thể loại này',
    overall: 'Nhìn chung',
    tagged: 'Tagged',
    occurrence: 'Tần suất xảy ra',
    skumanagement: 'Quản lý SKU',
    category: 'thể loại',
    skuname: 'SKU Tên',
    skucode: 'SKU Code',
    unitofmeasurementuom: 'Đơn vị đo lường (Đơn vị đo lường)',
    description: 'Sự miêu tả',
    csvupload: 'CSV Tải lên',
    template: 'Bản mẫu',
    notifications: 'thông báo',
    newnotification: 'Thông báo mới',
    monitoring: 'Giám sát',
    topic: 'Đề tài',
    frequency: 'Tần số',
    recipients: 'Người nhận',
    notifyby: 'Thông báo qua',
    sms: 'tin nhắn',
    alert: 'báo động',
    report: 'Báo cáo',
    greaterthan: 'Lớn hơn',
    lessthan: 'Ít hơn',
    greaterthanorequal: 'Lớn hơn hoặc bằng',
    lessthanorequal: 'Nhỏ hơn hoặc bằng',
    equal: 'Công bằng',
    range: 'Phạm vi',
    continuousduration: 'Thời gian liên tục',
    usermanagement: 'Quản lý người dùng',
    user: 'Người sử dụng',
    users: 'người dùng',
    invitenew: 'mời mới',
    status: 'trạng thái',
    resend: 'Resend',
    revoke: 'Thu hồi',
    invite: 'Mời gọi',
    invitees: 'Khách mời',
    addinviteesbypressingenterorclickingonthesign:
    "Thêm khách mời bằng cách nhấn Enter hoặc nhấp vào dấu '+'",
    schedules: 'lịch',
    shift: 'sự thay đổi',
    profile: 'Hồ sơ',
    changepassword: 'Đổi mật khẩu',
    deleteaccount: 'Xóa tài khoản',
    userguide: 'Hướng dẫn sử dụng',
    overviewandbasiccontrol: 'Tổng quan và kiểm soát cơ bản',
    rangeandresolution: 'Phạm vi và Nghị quyết',
    editingoptions: 'tùy chọn chỉnh sửa',
    mergetiles: 'gạch Merge',
    detailedassetview: 'xem chi tiết tài sản',
    assetoverview: 'tổng quan về tài sản',
    summarystatistics: 'Tóm tắt thống kê',
    dataanalysis: 'Phân tích dữ liệu',
    oeeanoverview: 'OEE một cái nhìn tổng quan',
    oeetoggle: 'OEE Toggle',
    addingamachine: 'Thêm một máy',
    nodeconfiguration: 'cấu hình nút',
    deleteupdateassets: 'tài sản Cập nhật Xóa',
    oeesummarytile: 'ngói tóm tắt OEE',
    oeetags: 'OEE Thẻ',
    issuemanagement: 'vấn đề quản lý',
    labelsandfiltering: 'Nhãn và lọc',
    useraccess: 'Người dùng truy cập',
    group: 'Nhóm',
    groups: 'Các nhóm',
    joincompany: 'Tham gia công ty',
    notification: 'thông báo',
    createnotification: 'tạo thông báo',
    node: 'Nút',
    downtime: 'thời gian chết',
    downtimes: 'downtime',
    repeatstartdate: 'Lặp lại Ngày bắt đầu',
    repeatenddate: 'Lặp lại ngày kết thúc',
    optional: 'Không bắt buộc',
    createplan: 'Xây dựng kế hoạch',
    today: 'Hôm nay',
    starttime: 'Thời gian bắt đầu',
    endtime: 'Thời gian kết thúc',
    chart: 'Đồ thị',
    reject: 'Từ chối',
    accept: 'Chấp nhận',
    filterbydaterange: 'lọc theo phạm vi ngày',
    filterbymostrecent: 'lọc bởi hầu hết gần đây',
    result: 'Kết quả',
    type: 'Kiểu',
    duration: 'thời gian',
    by: 'qua',
    title: 'Tiêu đề',
    assigned: 'Giao',
    action: 'Hoạt động',
    track: 'theo dõi',
    issue: 'Vấn đề',
    priority: 'Sự ưu tiên',
    requestor: 'người yêu cầu',
    opened: 'mở',
    assignee: 'nhận chuyển nhượng',
    root: 'nguồn gốc',
    cause: 'Nguyên nhân',
    identified: 'Xác định',
    defined: 'Defined',
    date: 'ngày',
    resolve: 'giải quyết',
    resolved: 'quyết định',
    targetresolvedate: 'mục tiêu ngày quyết tâm',
    procurement: 'Tạp vụ',
    choose: 'Chọn',
    analyses: 'phân tích',
    source: 'nguồn',
    selection: 'sự lựa chọn',
    historical: 'Lịch sử',
    mostrecent: 'Gần đây nhất',
    regression: 'hồi quy',
    trendline: 'đường xu hướng',
    upperbound: 'giới hạn trên',
    lowerbound: 'chặn dưới',
    preview: 'xem trước',
    period: 'giai đoạn = Stage',
    series: 'loạt',
    axis: 'trục',
    youhaveno: 'bạn không có',
    code: 'Mã',
    all: 'Tất cả',
    upload: 'tải lên',
    regressionanalysis: 'Phân tích hồi quy',
    issuetracker: 'người tìm bệnh',
    labelsearch: 'nhãn Tìm kiếm',
    gatewaysetup: 'cài đặt cổng',
    setupgateway: 'cổng thiết lập',
    radiomesh: 'lưới phát thanh',
    targetsetting: 'thiết lập mục tiêu',
    updatetrackedissue: 'cập nhật vấn đề theo dõi',
    shownewtrackedissue: 'hiển thị vấn đề được theo dõi mới',
    low: 'Thấp',
    mid: 'khoảng giữa',
    high: 'cao',
    linear: 'tuyến tính',
    exponential: 'số mũ',
    logarithmic: 'logarit',
    quadratic: 'phương trình bậc hai',
    polynomial: 'đa thức',
    general: 'Chung',
    datataggingenrichment: 'Dữ liệu Tagging Làm Giàu',
    hardware: 'Phần cứng',
    nodemanagement: 'quản lý Node',
    createeditnotification: 'Tạo chỉnh sửa thông báo',
    conditions: 'Điều kiện',
    addsku: 'thêm sku',
    editsku: 'chỉnh sửa SKU',
    setupagateway: 'thiết lập một cổng',
    deviceserialnumber: 'thiết bị số serial',
    repeatsweekly: 'lặp đi lặp lại hàng tuần',
    repeatsdaily: 'lặp đi lặp lại hàng ngày',
    repeatsmonthly: 'lặp đi lặp lại hàng tháng',
    repeatsannually: 'lặp đi lặp lại hàng năm',
    repeatsevery: 'lặp đi lặp lại mỗi',
    hourly: 'từng giờ',
    biweekly: 'hai tuần',
};
