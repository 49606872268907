import { makeRequest } from '../Shared/Shared.action';
import { store } from '../..';

export const api_updateMetadatum = (asset_id, data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();
    const { device_id, metadata_id } = data;

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/device/${device_id}/metadata/${metadata_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
