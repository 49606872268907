export const InvitationConstants = {
    ACTIONS: {
        FETCH_INVITATIONS_REQUEST: 'FETCH_INVITATIONS_REQUEST',
        CREATE_INVITATION_REQUEST: 'CREATE_INVITATION_REQUEST',
        RESEND_INVITATION_REQUEST: 'RESEND_INVITATION_REQUEST',
        REVOKE_INVITATION_REQUEST: 'REVOKE_INVITATION_REQUEST', // deletes shadow user
        SET_INVITATIONS_RESOURCE: 'SET_INVITATIONS_RESOURCE',
        ADD_INVITATIONS_RESOURCE: 'ADD_INVITATIONS_RESOURCE',
        REMOVE_INVITATIONS_RESOURCE: 'REMOVE_INVITATIONS_RESOURCE',

    },
};
