/* eslint-disable react/prop-types */
import React from 'react';
import { useEfficientDragLayer } from '../../../Hooks';
import WithLoaders from '../../../Widgets/components/WithLoaders';
import { AssetPrimary, OEESummary, WidgetWrapper } from '../../../Widgets';
import { isEmpty } from 'lodash';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 999999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const getItemStyles = (previewProps) => {
    const { initialOffset, currentOffset } = previewProps;

    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
        transform,
        WebkitTransform: transform,
    };
};

const getDragLayerWidgetBody = (w, options) => {
    const { data, widget_type_id } = w;

    switch (widget_type_id) {
    case 1:
        return (
            <AssetPrimary
                data={data}
                window={options.window}
                canDoubleClick={false}
            />
        );
    case 2:
        return <OEESummary data={data} canDoubleClick={false} />;
    default:
        return null;
    }
};

const WidgetDragLayer = (props) => {
    const previewProps = useEfficientDragLayer((monitor) => ({
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        isDragging: monitor.isDragging(),
    }));

    const { item, isDragging } = previewProps;

    if (!isDragging || isEmpty(item)) return null;

    const renderItem = () => {
        const { data } = item;
        const { window } = props;

        if (!data) return null;
        const preview = getDragLayerWidgetBody(data, { window });

        return (
            <WidgetWrapper
                className="widget--no-touch"
                data={data}
                style={{ cursor: 'move', width: 170, height: 250 }}
            >
                <WithLoaders isLoading={false}>{preview}</WithLoaders>
            </WidgetWrapper>
        );
    };

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(previewProps)}>{renderItem()}</div>
        </div>
    );
};

export default WidgetDragLayer;
