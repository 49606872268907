export const ja = {
    notes: 'ノート',
    load: 'フェッチ',
    export: 'エクスポート',
    unutilised: '未利用',
    breakdowns: '故障',
    threshold: 'しきい値',
    oeepreview: 'OEEプレビュー',
    heatmappreview: 'ヒートマップのプレビュー',
    oeecolors: 'OEE色',
    color: '色',
    reset: 'リセット',
    theme: 'テーマ',
    defaultview: 'デフォルトビュー',
    defaulttimeselection: 'デフォルトの時間選択',
    zoomlevel: 'ズームレベル',
    entity: 'エンティティ',
    preferences: '環境設定',
    custom: 'カスタム',
    enteryourmailtoreset: 'リセットするために、あなたのメールを入力してください',
    goback: '戻る',
    resetpassword: 'パスワードを再設定する',
    logout: 'ログアウト',
    ownersemail: '所有者のメール',
    optional: 'オプショナル',
    industry: '業界',
    timezone: 'タイムゾーン',
    nocoderequesttojoin: 'ノーコードに参加するための要求',
    enterreferralcodeinstead: '代わりに紹介コードを入力します。',
    setup: 'セットアップ',
    company: '会社',
    referralcode: '紹介コード',
    submit: '参加する',
    summary: '概要',
    language: '言語',
    login: 'ログインする',
    signin: 'サインイン',
    signup: 'サインアップ',
    username: 'ユーザー名',
    password: 'パスワード',
    forgotpassword: 'パスワードをお忘れですか？',
    rememberme: '私を覚えてますか',
    register: '登録',
    registration: '登録',
    firstname: 'ファーストネーム',
    lastname: '苗字',
    email: 'Eメール',
    mobile: 'モバイル',
    country: '国',
    contactnumber: '連絡先番号',
    appointment: '任命',
    role: '役割',
    admin: '管理者',
    owner: 'オーナー',
    editor: '編集者',
    viewer: 'ビューア',
    confirmpassword: 'パスワードを認証する',
    iacceptthe: '私は受け入れます',
    useragreement: 'ユーザー規約',
    alreadyregisteredlogin: 'すでに登録？ログインする',
    softwareasaservicetermsofuse: '利用サービスとしてのソフトウェア利用規約',
    dashboard: '計器盤',
    streaming: 'ストリーミング',
    resolution: '間隔',
    seconds: '秒',
    second: '第2',
    minutes: '分',
    minute: '分',
    hours: '時間',
    hour: '時間',
    weeks: '週間',
    week: '週間',
    months: 'ヶ月',
    month: '月',
    quarters: '四半期',
    quarter: '四半期',
    years: '年',
    year: '年',
    from: 'から',
    to: 'に',
    start: '開始',
    end: '終わり',
    display: '表示',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: '時系列',
    gateway: 'ゲートウェイ',
    statusasof: 'のとしての地位',
    active: 'アクティブ',
    offline: 'オフライン',
    mergeasonemachine: '一台のマシンとしてマージ',
    drawarrowstoformprocessmap: 'フォーム・プロセス・マップに矢印を描きます',
    openblockeditor: 'オープンブロックエディタ',
    createnewtile: '新しいタイルを作成します。',
    summarytile: '概要タイル',
    selectablockthennameyoursummarytile:
    'ブロックを選択して、あなたの概要タイルに名前を付けます',
    name: '名前',
    blockselector: 'ブロックセレクタ',
    asset: '資産',
    therearenolabelstaggedinthisperiod:
    'この期間内にタグ付けされたラベルなしはありません',
    standardtimes: '標準時間（s）',
    total: 'トータル',
    last: '最終',
    oeeinput: 'OEE入力',
    chartcolor: 'チャートの色',
    issueeditor: '問題エディタ',
    issuemangement: '発行マングメント',
    cumulativeoutput: '累積出力',
    tag: '鬼ごっこ',
    label: 'ラベル',
    valueval: '値（ヴァル）',
    time: '時間',
    januaryjan: '1月（1月）',
    februaryfeb: '2月（2月）',
    marchmar: '月（3月）',
    aprilapr: '4月（4月）',
    may: '五月',
    junejun: '6月（6月）',
    julyjul: '7月（7月）',
    augustaug: '8月（8月）',
    septembersep: '9月（9月）',
    octoberoct: '10月（10月）',
    novembernov: '11月（​​11月）',
    decemberdec: '12月（12月）',
    day: '日',
    days: '日々',
    mondaymonm: '月曜日（月曜）M',
    tuesdaytuet: '火曜日（火）T',
    wednesdaywedw: '水曜日（水）W',
    thursdaythut: '木曜日（木）T',
    fridayfrif: '金曜日（金）F',
    saturdaysats: '土曜日（土）S',
    sundaysuns: '日曜日（日）S',
    edit: '編集します',
    add: '追加',
    new: '新着',
    create: '作ります',
    search: '探す',
    close: '閉じる',
    cancel: 'キャンセル',
    save: '保存する',
    saved: '保存されました',
    clear: '晴れ',
    enter: '入る',
    navigator: 'ナビゲーター',
    mandatoryfields: '必須フィールド',
    select: '選択する',
    merge: 'マージ',
    delete: '[削除]',
    done: '完了',
    filter: 'フィルタ',
    table: 'テーブル',
    columns: 'コラム',
    rows: '行',
    cell: '細胞',
    page: 'ページ',
    previous: '前',
    next: '次',
    serialnumber: 'シリアルナンバー',
    manuallyinputserialnumber: '手動で入力シリアル番号',
    updateasset: 'アップデート資産',
    machine: '機械',
    brand: 'ブランド',
    model: 'モデル',
    nodes: 'ノード',
    fusions: '融合',
    chartorder: 'チャート注文',
    primary: '主要',
    adddevicechannel: 'デバイスの追加 - チャンネル',
    pushratesec: 'プッシュレート（秒）',
    voltagev: '電圧（V）',
    sensor: 'センサー',
    mode: 'モード',
    digitalcount: 'デジタルカウント',
    digitalstate: 'デジタル州',
    analogvoltage: 'アナログ電圧',
    charttitle: 'グラフタイトル',
    unitmeasure: 'ユニットの測定',
    attribute: '属性',
    autocycletime: 'オートサイクルタイム',
    unittransform: 'ユニットは、トランスフォーム',
    leaveblankifnotapplicable: '該当空白のままにしていない場合',
    mandatoryfieldsleaveblankifchannelnotinuse:
    '必須フィールド（休暇空白チャネル使用しない場合）',
    fusedchart: '融合チャート',
    fusionname: 'Fusionの名前',
    charttype: 'チャートの種類',
    column: 'カラム',
    line: 'ライン',
    state: '状態',
    expression: '表現',
    combination: '組み合わせ',
    currentcharts: '現在のチャート',
    expressionconstructor: '式のコンストラクタ',
    validoperators: '有効な演算子',
    leaveblanktokeepexistingexpression: '既存の式を保つために空白のままにします',
    oee: 'OEE',
    overallequipmenteffectiveness: '全体の設備効率',
    legend: '伝説',
    loading: '積載',
    availability: '可用性',
    performance: '演奏',
    quality: '品質',
    unscheduled: 'スケジュールされていません',
    planneddowntime: '計画停止',
    changeoversetup: '切替/設定',
    breakdown: '壊す',
    unutilized: '未利用',
    speedloss: 'スピード損失',
    minorstops: 'マイナー停止',
    rework: 'リワーク',
    rejectscrap: '拒否/スクラップ',
    nodata: 'データなし',
    blockeditor: 'ブロックエディタ',
    sort: 'ソート',
    logicblocks: 'ロジックブロック',
    sequence: 'シーケンス',
    and: 'そして',
    or: '若しくは',
    utilizationheatmap: '利用ヒートマップ',
    startdate: '開始日',
    enddate: '終了日',
    daily: '日々',
    weekly: 'ウィークリー',
    monthly: '毎月',
    quarterly: '季刊',
    yearly: '毎年',
    trendanalysis: 'トレンド分析',
    metricselection: 'メトリックの選択',
    metric: 'メトリック',
    data: 'データ',
    target: '目標',
    periodselection: '期間の選択',
    span: 'スパン',
    every: 'すべての',
    repeat: '繰り返す',
    repeats: '繰り返し',
    addsingle: 'シングルを追加',
    paretoanalysis: 'パレート分析',
    calendartime: 'カレンダーの時間',
    scheduledtime: '予定時間',
    operatingtime: '稼働時間',
    netruntime: 'ネットの実行時間',
    effective: '効果的な',
    loadingloss: 'ロード・ロス',
    availabilityloss: '可用性の損失',
    performanceloss: 'パフォーマンスの損失',
    qualityloss: '品質を損ないます',
    notagsinthiscategory: 'このカテゴリにタグはありません',
    overall: '全体',
    tagged: 'タグ付き',
    occurrence: '発生',
    skumanagement: 'SKU管理',
    category: 'カテゴリー',
    skuname: 'SKU名',
    skucode: 'SKUコード',
    unitofmeasurementuom: '測定単位（UOM）',
    description: '説明',
    csvupload: 'CSVアップロード',
    template: 'テンプレート',
    notifications: '通知',
    newnotification: '新しい通知',
    monitoring: 'モニタリング',
    topic: 'トピック',
    frequency: '周波数',
    recipients: '受信者',
    notifyby: 'で通知',
    sms: 'SMS',
    alert: '警報',
    report: '報告する',
    greaterthan: 'より大きい',
    lessthan: '未満',
    greaterthanorequal: '以上で',
    lessthanorequal: '以下で',
    equal: '等しい',
    range: '範囲',
    continuousduration: '連続期間',
    usermanagement: 'ユーザー管理',
    user: 'ユーザー',
    users: 'ユーザー',
    invitenew: '新を招待',
    status: '状態',
    resend: '再送信',
    revoke: '取り消す',
    invite: '招待する',
    invitees: '招待',
    addinviteesbypressingenterorclickingonthesign:
    'Enterキーを押すか、「+」記号をクリックして招待を追加',
    schedules: 'スケジュール',
    shift: 'シフト',
    profile: 'プロフィール',
    changepassword: 'パスワードを変更する',
    deleteaccount: 'アカウントを削除する',
    userguide: 'ユーザーガイド',
    overviewandbasiccontrol: '概要と基本コントロール',
    rangeandresolution: 'レンジと分解能',
    editingoptions: 'オプションの編集',
    mergetiles: 'マージタイル',
    detailedassetview: '詳細資産ビュー',
    assetoverview: '資産の概要',
    summarystatistics: '要約統計量',
    dataanalysis: 'データ分析',
    oeeanoverview: 'OEEの概要',
    oeetoggle: 'OEEトグル',
    addingamachine: 'マシンを追加します',
    nodeconfiguration: 'ノード構成',
    deleteupdateassets: '削除、更新資産',
    oeesummarytile: 'OEEの概要タイル',
    oeetags: 'OEEタグ',
    issuemanagement: '問題管理',
    labelsandfiltering: 'ラベルとフィルタ',
    useraccess: 'ユーザーアクセス',
    group: 'グループ',
    groups: 'グループ',
    joincompany: '会社に参加',
    notification: '通知',
    createnotification: '通知を作成します。',
    node: 'ノード',
    downtime: 'ダウンタイム',
    downtimes: 'ダウンタイム',
    repeatstartdate: '開始日を繰り返し',
    repeatenddate: '繰り返し終了日',
    optional: 'オプショナル',
    createplan: 'プランの作成',
    today: '今日',
    starttime: '始まる時間',
    endtime: '終了時間',
    chart: 'チャート',
    reject: '拒絶します',
    accept: '受け入れます',
    filterbydaterange: '日付範囲によってフィルタ',
    filterbymostrecent: '最も最近のことでフィルタ',
    result: '結果',
    type: 'タイプ',
    duration: '期間',
    by: '沿って',
    title: '題名',
    assigned: '割り当てられました',
    action: 'アクション',
    track: '追跡',
    issue: '問題',
    priority: '優先',
    requestor: 'リクエスタ',
    opened: '開かれました',
    assignee: '譲受人',
    root: 'ルート',
    cause: '原因',
    identified: '識別',
    defined: '定義されました',
    date: '日付',
    resolve: '決意',
    resolved: '解決',
    targetresolvedate: 'ターゲット解決日時',
    procurement: '調達',
    choose: '選択してください',
    analyses: '分析',
    source: 'ソース',
    selection: '選択',
    historical: '歴史的',
    mostrecent: '最も最近の',
    regression: '回帰',
    trendline: 'トレンドライン',
    upperbound: '上界',
    lowerbound: '下界',
    preview: '試写',
    period: '限目',
    series: 'シリーズ',
    axis: '軸',
    youhaveno: 'あなたは何も持っていません',
    code: 'コード',
    all: 'すべて',
    upload: 'アップロード',
    regressionanalysis: '回帰分析',
    issuetracker: '問題追跡',
    labelsearch: 'ラベル検索',
    gatewaysetup: 'ゲートウェイ設定',
    setupgateway: 'セットアップゲートウェイ',
    radiomesh: 'ラジオメッシュ',
    targetsetting: '対象の設定',
    updatetrackedissue: '更新追跡問題',
    shownewtrackedissue: '新しい追跡問題を示し、',
    low: '低いです',
    mid: 'ミッド',
    high: '高い',
    linear: '線形',
    exponential: '指数',
    logarithmic: '対数',
    quadratic: '二次の',
    polynomial: '多項式',
    general: '一般的な',
    datataggingenrichment: 'データタギング充実',
    hardware: 'ハードウェア',
    nodemanagement: 'ノードの管理',
    createeditnotification: '編集通知を作成します。',
    conditions: '条件',
    addsku: 'SKUを追加',
    editsku: '編集SKU',
    setupagateway: 'セットアップゲートウェイ',
    deviceserialnumber: 'デバイスのシリアル番号',
    repeatsweekly: '毎週繰り返されます',
    repeatsdaily: '毎日繰り返されます',
    repeatsmonthly: '毎月の繰り返し',
    repeatsannually: '毎年繰り返されます',
    repeatsevery: 'すべての繰り返し',
    hourly: '毎時',
    biweekly: '隔週',
};
