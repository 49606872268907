import { put, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

// MODEL
import { Gateway, parseGatewayArguments } from '../../../legacy/models';

// HELPERS
import { GatewayConstants as K } from './Gateway.constants';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { errorFlash, flash } from '../../../legacy/components/Flash';

// REDUX
import { AddGatewaysResource, SetGatewaysStatusResource } from './Gateway.action';

// GATEWAY SERVICES
import { api_getGatewaysStatus, api_updateGatewayEntity } from './Gateway.services';

// GET GATEWAY SERVICE
function* handleAddGateway(action) {
    try {
        const { serial_number, entity_id } = action.payload;

        const response = yield call(
            api_updateGatewayEntity,
            entity_id,
            serial_number
        );

        const gateway = new Gateway(...parseGatewayArguments(response));

        yield put(AddGatewaysResource({ [gateway.device_id]: gateway }));

        flash({ message: 'Gateway added', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* addGatewaySaga() {
    yield takeLatest(
        K.ACTIONS.ADD_GATEWAY_REQUEST,
        handleAddGateway
    );
}

// GET GATEWAY SERVICE
function* handleGetEntityGatewaysStatus(action) {
    try {
        const { entity_id } = action.payload;
        if (entity_id === -1) return;

        const last_fetched = new moment();
        const response = yield call(api_getGatewaysStatus, entity_id);

        const statuses = getMapFromArr(
            response.map(({ status, device_id }) => ({
                ...status,
                last_fetched,
                device_id,
            })),
            'device_id'
        );
        yield put(SetGatewaysStatusResource(statuses));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getEntityGatewaysStatusSaga() {
    yield takeLatest(
        K.ACTIONS.GET_ENTITY_GATEWAY_STATUS_REQUEST,
        handleGetEntityGatewaysStatus
    );
}
