export const UtilizationConstants = {
    ACTIONS: {
        UTILIZATION_UPDATE_RANGE: 'UTILIZATION_UPDATE_RANGE',
        UTILIZATION_UPDATE_RESOLUTION: 'UTILIZATION_UPDATE_RESOLUTION',
        UTILIZATION_FETCH_DATA_REQUEST: 'UTILIZATION_FETCH_DATA_REQUEST',
        RESET_UTILIZATION_STORE: 'UTILIZATION_RESET_STORE',
    },
    RESOLUTIONS: [
        { label: 'Hourly', res_x: 1, res_period: 'hours', min: 1, max: 96 }, // min 1 h, max 4 days, 96 datapoints
        { label: 'Daily', res_x: 1, res_period: 'days', min: 24, max: 2400 }, // min 1 day, max 3 months (90 days) + 10 days, 100 datapoints
        { label: 'Weekly', res_x: 1, res_period: 'weeks', min: 168, max: 8760 },
        { label: 'Monthly', res_x: 1, res_period: 'months', min: 720, max: 8760 }, // min 1 month, max 12 months (limit)
    ],
};
