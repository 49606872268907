import _ from 'lodash';
import moment from 'moment';

import CONSTANTS from '../../Constants';
import { csvDatetimeFormat } from '../../utils/helpers';
import { generateUrlQuery } from '../../utils/url';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { store } from '../../../store';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';

const getTimes = (data) => {
    return data
        .sort((a, b) => b.data.length - a.data.length)[0]
        .data.map((d) => d.time);
};

const getDataRow = ({ d, times, resolution, date_range, oee2 }) => {
    const data = d.data.reduce((acc, curr) => {
        return { ...acc, [curr.time]: curr.value };
    }, {});

    const { final_effective = 0, loading } = d.overall;
    const orderedValues = times.map((t) => (_.isNumber(data[t]) ? data[t] : ''));
    const link = getUrl({ d, resolution, date_range });
    const ef = oee2 ? final_effective : (final_effective * loading) / 100;
    return `"${d.label}", ${link}, ${ef}, , ${orderedValues.join(',')}`;
};

const getUrl = ({ d, resolution, date_range }) => {
    const [from] = date_range;
    const { res_x, res_period } = resolution;
    const startDate = moment(from);
    const endDate = moment(from).add(res_x, res_period);
    const query = generateUrlQuery({ resolution, startDate, endDate, span: CUSTOM_SPAN });
    const base = d.asset_id ? CONSTANTS.URLS.ASSET : CONSTANTS.URLS.BLOCK;
    const id = d.asset_id || d.block_id;

    return window.location.origin + base + '/' + id + query;
};

const utilizationCSV = ({ data, resolution, date_range, filter, oee2 }) => {
    const { timezone } = currentEntitySelector(store.getState());
    const { label } = resolution;
    const [from, to] = date_range;
    const title = `${label} ${oee2 ? 'OEE2' : 'OEE1'}`;
    const summary = [
        'Time Range',
        `Timezone, ${timezone}`,
        `Start, ${moment(from).format(csvDatetimeFormat)}`,
        `End, ${moment(to).format(csvDatetimeFormat)}`,
        `Active Categories, "${filter.join(',')}"`,
    ].join('\n');

    const times = getTimes(data);
    const header = [
        'Line/Asset',
        'Link',
        'Final Effective',
        'Date >',
        times
            .map((t) => {
                const time = moment(t).utcOffset(timezone);
                return moment(time).format(csvDatetimeFormat);
            })
            .join(','),
    ].join(',');

    const dataChunk = data
        .map((d) => getDataRow({ d, times, resolution, date_range, oee2 }))
        .join('\n');

    return [`${title}\n`, `${summary}\n`, `${header}`, `${dataChunk}`].join('\n');
};

export default utilizationCSV;
