import { CloseOutlined } from '@mui/icons-material';
import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import AukButton from '../../components/AukButton'
import { PlusOutlined } from '@ant-design/icons';
import { emailRegex } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { createInviteUser } from '../../../store/old/Invitation/Invitation.action';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';

const VALIDATE = {
    EMAIL: [
        (_form) => ({
            validator: (_, value = '') => {
                if (!value.match(emailRegex))
                    return Promise.reject(new Error('Must be a valid email address'));
                return Promise.resolve();
            },
        }),
    ]
}

const getFormItemPath = (fieldName, name) => ([fieldName, name])

const InviteForm = (props) => {
    const dispatch = useDispatch();
    const { user_id } = useSelector(userSelector)
    const { entity_id } = useSelector(currentEntitySelector)
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {  
            form.resetFields() 
        }
    }, [props.open])

    const getFormData = (formData) => {
        return formData.invitees
    }

    const submit = async () => {
        try {
            await form.validateFields()
            const errors = form.getFieldsError().filter(item => item.errors.length)
            if (!errors.length) {
                const data = getFormData(form.getFieldsValue(true));
                data.length && dispatch(createInviteUser(entity_id, user_id, data, props.cancel))
                return;
            }
        } catch(e) {
        }
    }


    return <Form
        name="inviteForm"
        form={form}
        className="invite-form"
    >
        <Modal
            className="auk-modal--small"
            style={{ width: '60vw' }}
            title="Invite user(s)"
            open={props.open}
            onCancel={props.cancel}
            footer={
                <div className="d-flex justify-content-between">
                    <AukButton.Cancel onClick={props.cancel}/>
                    <AukButton.Save onClick={submit}/>
                </div>
            }
        >
            <Form.List 
                name="invitees"
                initialValue={['']}
            >
                {(fields, { add, remove }) => {
                    return (
                        <>
                                {fields.map((field, i) => {
                                    return (
                                        <Form.Item
                                            className='invite-form-item'
                                            key={i}
                                            name={getFormItemPath(field.name, 'email')}
                                            rules={VALIDATE.EMAIL}
                                            {...field}
                                        >
                                            <Input addonAfter={<CloseOutlined onClick={() => remove(field.name)}/>}/>
                                        </Form.Item>
                                    );
                                })}

                                <Form.Item className='mb-0 mt-3'>
                                    <AukButton.Blue
                                        ghost
                                        onClick={() => { add('') }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add Invitee
                                    </AukButton.Blue>
                                </Form.Item>
                        </>
                    );
                }}
            </Form.List>
        </Modal>
    </Form>
}

export default InviteForm;