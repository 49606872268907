import "./Checklist.scss"

import React, { useMemo } from "react"
import { AnyFunction } from "../../types/any"
import { Checkbox } from "antd"

// controlled
const Checklist = (props: ChecklistProps) => {
    const validKeys = useMemo(() => props.items
        .filter(({ disabled }) => !disabled)
        .map(({ value }) => value), [props.items])

    const checkedKeys = useMemo(() => new Set(props.checkedKeys), [props.checkedKeys])
    const checkAll = useMemo(() => props.checkedKeys.length === validKeys.length, [props.checkedKeys, validKeys])

    const handleCheckAll = () => {
        if (checkAll) {
            props.onChange([])
            return
        }

        props.onChange(validKeys)
    }

    const handleCheckItem = (key: string | number) => {
        const cloned = new Set([...checkedKeys])
        if (cloned.has(key)) {
            cloned.delete(key)
        } else {
            cloned.add(key)
        }

        props.onChange(Array.from(cloned))
    }

    return (
        <div className="auk-checklist">
            <Checkbox checked={checkAll} onChange={handleCheckAll}>
                Select / Unselect All
            </Checkbox>
            {props.items.map((item) => 
                <ChecklistItem
                    {...item}
                    key={item.value}
                    checked={checkedKeys.has(item.value)}
                    onCheck={() => handleCheckItem(item.value)}
                />
            )}
        </div>
    )
}

interface ChecklistProps {
    items: ChecklistItem[];
    onChange: (checkedKeys: (string | number)[]) => any;
    checkedKeys: (string | number)[];
    defaultCheckAll?: boolean;
}

export interface ChecklistItem {
    label: string;
    value: string | number;
    disabled: boolean;
    info?: string;
}

export default Checklist

const ChecklistItem = (props: ChecklistItemProps) => {
    return (
        <Checkbox
            disabled={props.disabled}
            checked={props.disabled ? false : props.checked}
            onChange={() => {
                props.onCheck()
            }}
        >
            {props.label}
        </Checkbox>
    )
}

interface ChecklistItemProps extends ChecklistItem {
    checked: boolean;
    onCheck: AnyFunction;
}
