/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Modal, Select, Tabs } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

// COMPONENTS
import AukButton from '../../components/AukButton';
import AukInputPhone from '../../components/AukInputPhone';
import ImageInput from '../../components/ImageInput';
import { Panel, PanelBody, PanelFooter } from '../../components/Panel';
import { SPAWrapper } from '../../components/SPAWrapper';

import { emailRegex, pwRegex } from '../../utils/helpers';
import { errorFlash, flash } from '../../components/Flash';
import { LANGUAGE_OPTIONS } from '../../../store/old/UI/Controls/Controls.constants';

// API
import {
    api_updateUserEmail,
    api_updateUserImage,
} from '../../../store/old/User/User.services';

// REDUX
import {
    ChangePassword,
    UpdateAuthenticatedUser,
    UpdateUserInformation,
} from '../../../store/old/Authentication/Authentication.action';
import {
    createUserPreferencesRequest,
    updateUserPreferencesRequest,
} from '../../../store/old/Preference/Preference.action';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';

import placeholder from '../../../assets/images/placeholder.jpg';

import './UserConfig.scss';
import translate from '../../utils/translate';
import UserAppointmentSelect from './components/UserAppointmentSelect';

const { TabPane } = Tabs;

const FIELDS = {
    NAME_FIRST: 'name_first',
    NAME_LAST: 'name_last',
    EMAIL: 'email',
    APPOINTMENT: 'appointment',
    MOBILE: 'mobile',
    COUNTRY_CODE: 'country_code',
    PHONE: 'phone',
};

const EMAIL_MAXLENGTH = 80;
const NAME_MAXLENGTH = 50;
const APPT_MAXLENGTH = 50;

const VALIDATE = {
    NAME_FIRST: [
        {
            required: true,
            message: 'First name is required.',
        },
        {
            whitespace: true,
            message: 'First name is required.',
        },
    ],
    NAME_LAST: [
        {
            required: true,
            message: 'Last name is required.',
        },
        {
            whitespace: true,
            message: 'Last name is required.',
        },
    ],
    EMAIL: [
        {
            required: true,
            message: 'Email is required.',
        },
        {
            whitespace: true,
            message: 'Email is required.',
        },
        (_form) => ({
            validator: (_, value) => {
                if (!value.match(emailRegex))
                    return Promise.reject(new Error('Must be a valid email address'));
                return Promise.resolve();
            },
        }),
    ],
    PHONE: [
        (_form) => ({
            validator: (_, value) => {
                if (!value.country_code && !value.mobile) return Promise.resolve();
                if (!value.country_code || !value.mobile) {
                    return Promise.reject(
                        new Error('Missing country code / Invalid phone number')
                    );
                }
                return Promise.resolve();
            },
        }),
    ],
};

const UserConfig = (props) => {
    const user = useSelector(userSelector);
    return (
        <SPAWrapper className="user-config">
            <Tabs destroyInactiveTabPane className="profile-tabs" type="card">
                <TabPane tab="Profile" key="1">
                    <ProfileForm user={user} />
                </TabPane>
                <TabPane tab="Account" key="2">
                    <AccountTabContent user={user} />
                </TabPane>
                <TabPane tab="Preferences" key="3">
                    <UserPreferenceForm data={user.preference} />
                </TabPane>
            </Tabs>
        </SPAWrapper>
    );
};

export default UserConfig;


const ProfileAvatar = (props) => {
    const { value, user } = props;
    const [img, setImg] = useState(value);
    const dispatch = useDispatch();
    const handleChange = async (d) => {
        try {
            const res = await api_updateUserImage(user.user_id, {
                base64Img: d,
                image_type: 'user',
            });
            const user_img = { image_id: res.image_id, signedUrl: res.signedUrl };
            setImg(user_img);
            dispatch(UpdateUserInformation({ user_img }));
            flash({ message: 'User photograph updated', status: 'success' });
        } catch (e) {
            errorFlash(e);
        }
    };

    const src = img && img.signedUrl ? img.signedUrl : placeholder;

    return (
        <div className="profile-avatar">
            <ImageInput
                onChange={handleChange}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: 2,
                }}
            />
            <img className="profile-avatar-image" src={src} alt="user image" />
        </div>
    );
};

const ProfileForm = (props) => {
    const { user } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const {
        appointment,
        name_first,
        name_last,
        user_img,
        mobile,
        country_code,
        user_id,
    } = user;

    const getFormData = (formData) => {
        const result = { ...formData, ...formData.phone };
        delete result.phone;

        return result;
    };

    const submit = (formData) => {
        dispatch(UpdateAuthenticatedUser(user_id, formData));
    };

    return (
        <Panel>
            <Form
                name="profileForm"
                id="profileForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="profile-form"
                onFinish={() => submit(getFormData(form.getFieldsValue(true)))}
            >
                <PanelBody>
                    <div className="profile-form__image">
                        <ProfileAvatar value={user_img} user={user} />
                    </div>
                    <div className="profile-form__fields">
                        <Form.Item
                            colon={false}
                            name={FIELDS.NAME_FIRST}
                            label={translate('firstname')}
                            initialValue={name_first}
                            rules={VALIDATE.NAME_FIRST}
                        >
                            <Input maxLength={NAME_MAXLENGTH} />
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            name={FIELDS.NAME_LAST}
                            label={translate('lastname')}
                            initialValue={name_last}
                            rules={VALIDATE.NAME_LAST}
                        >
                            <Input maxLength={NAME_MAXLENGTH} />
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            name={FIELDS.APPOINTMENT}
                            label={translate('appointment')}
                            initialValue={appointment}
                        >
                            <UserAppointmentSelect/>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            name={'phone'}
                            label={translate('mobile')}
                            initialValue={{ mobile, country_code }}
                            rules={VALIDATE.PHONE}
                        >
                            <AukInputPhone />
                        </Form.Item>
                    </div>
                </PanelBody>
                <PanelFooter className="p-2">
                    <AukButton.Reset
                        className="mr-2"
                        onClick={() => form.resetFields()}
                    />
                    <AukButton.Save htmlType="submit" />
                </PanelFooter>
            </Form>
        </Panel>
    );
};

const AccountTabContent = (props) => {
    const { user } = props;
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    return (
        <Panel>
            <PanelBody>
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    <Form.Item label={translate(FIELDS.EMAIL)}>
                        <Input
                            disabled
                            addonAfter={
                                <ArrowRightOutlined onClick={() => setShowChangeEmail(true)} />
                            }
                            value={props.user.email}
                        />
                    </Form.Item>
                    <Form.Item label={translate('password')}>
                        <Input.Password
                            disabled
                            addonAfter={
                                <ArrowRightOutlined
                                    onClick={() => setShowChangePassword(true)}
                                />
                            }
                            value={props.user.email}
                        />
                    </Form.Item>
                </Form>
            </PanelBody>
            <PasswordChangeModal
                user={user}
                visible={showChangePassword}
                close={() => setShowChangePassword(false)}
            />
            <EmailChangeModal
                user={user}
                visible={showChangeEmail}
                close={() => setShowChangeEmail(false)}
            />
        </Panel>
    );
};

const UserPreferenceForm = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const save = (formData) => {
        const params = {
            language: formData.language,
            oee: formData.oee,
        };

        return props.data.preference_id
            ? updateUserPreferencesRequest(params)
            : createUserPreferencesRequest(params);
    };

    const submit = (formData) => {
        dispatch(save(formData));
    };

    return (
        <Panel>
            <Form
                name="userPreferenceForm"
                id="userPreferenceForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="user-preference-form"
                onFinish={() => submit(form.getFieldsValue(true))}
            >
                <PanelBody>
                    <Form.Item
                        colon={false}
                        name={'language'}
                        label={translate('language')}
                        initialValue={props.data.language}
                    >
                        <Select options={LANGUAGE_OPTIONS} />
                    </Form.Item>
                </PanelBody>
                <PanelFooter className="p-2">
                    <AukButton.Reset
                        className="mr-2"
                        onClick={() => form.resetFields()}
                    />
                    <AukButton.Save htmlType="submit" />
                </PanelFooter>
            </Form>
        </Panel>
    );
};

const PasswordChangeModal = (props) => {
    const { visible, close, user } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { user_id } = user;
    const submitPasswordChange = useCallback(async () => {
        const { new: password, current: original_password } =
      form.getFieldsValue(true);

        dispatch(
            ChangePassword({ user_id, data: { original_password, password } }, () => {
                flash({ message: 'Password updated', status: 'success' });
                close();
            })
        );
    }, [form]);

    return (
        <Modal
            title="Change Password"
            visible={visible}
            width={600}
            onOk={submitPasswordChange}
            onCancel={close}
            okText="Proceed"
            getContainer={false}
            forceRender
        >
            <Form
                name="passwordChangeForm"
                id="passwordChangeForm"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className="password-change-form"
            >
                <Form.Item
                    colon={false}
                    name={'current'}
                    label="Current Password"
                    rules={[
                        {
                            required: true,
                            message: 'Current password is required',
                        },
                    ]}
                >
                    <Input.Password autoComplete="off" />
                </Form.Item>
                <Form.Item
                    colon={false}
                    name={'new'}
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Password is required',
                        },
                        {
                            type: 'string',
                            min: 8,
                            message: 'Cannot be less than 8 characters',
                        },
                        {
                            type: 'string',
                            max: 20,
                            message: 'Cannot be more than 20 characters',
                        },
                        (_form) => ({
                            validator: (_, value) => {
                                if (!value.match(pwRegex))
                                    return Promise.reject(
                                        new Error(
                                            'Must contain at least 1 uppercase letter, 1 lowercase letter and 1 numeral'
                                        )
                                    );
                                return Promise.resolve();
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    colon={false}
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['new']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        (_form) => ({
                            validator(_, value) {
                                if (!value || _form.getFieldValue('new') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const EmailChangeModal = (props) => {
    const { visible, close, user } = props;
    const [form] = Form.useForm();
    const { user_id, email } = user;
    const submitEmailChange = useCallback(async () => {
        try {
            const formData = form.getFieldsValue(true);

            await api_updateUserEmail(user_id, formData);
            flash({
                message: 'Email change request submitted',
                details: `Please check your inbox at ${email} to verify.`,
                status: 'success',
            });
        } catch (e) {
            errorFlash(e);
        }
    }, [form]);

    return (
        <Modal
            title="Change Email"
            visible={visible}
            width={600}
            onOk={submitEmailChange}
            onCancel={close}
            okText="Proceed"
            getContainer={false}
            forceRender
        >
            <Form
                name="emailChangeForm"
                id="emailChangeForm"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className="email-change-form"
            >
                <Form.Item
                    colon={false}
                    name={FIELDS.EMAIL}
                    label={translate('email')}
                    rules={VALIDATE.EMAIL}
                    initialValue={email}
                >
                    <Input maxLength={EMAIL_MAXLENGTH} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
