import languageFiles from "../../legacy/Localization"

export const translator = (lang: string, fileJson: Record<string, Record<string, string>> = languageFiles) => {
    return function translate (content: string) {
        const key = content
            .replace(/\s/g, "")
            .replace(/[^\w\s]/gi, "")
            .replace(/\//gi, "")
            .toLowerCase()

        return fileJson[lang][key] || fileJson.en[key] || content
    }
}
