/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { CompactPicker } from 'react-color';

import './ColorPicker.scss';

export const SWATCHES = [
    '#4D4D4D',
    '#cccccc',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#67B4F5',
    '#73D8FF',
    '#D3C6EC',
    '#FDA1FF',
    '#333333',
    '#999999',
    '#D33115',
    '#E27300',
    '#FCC400',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#4682B4',
    '#009CE0',
    '#9879D2',
    '#FA28FF',
    '#000000',
    '#666666',
    '#9F0500',
    '#C45100',
    '#FB9E00',
    '#808900',
    '#194D33',
    '#0C797D',
    '#285769',
    '#0062B1',
    '#653294',
    '#AB149E',
];

const ColorPicker = ({ value, onChange, className, disabled }) => {
    const [showPicker, setShowPicker] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(value);

    const styles = {
        swatch: {
            backgroundColor,
            width: '80%',
            borderRadius: 3,
            height: 15,
            margin: '2px auto',
            cursor: 'pointer',
            opacity: disabled ? 0.5 : 1,
        },
        popover: {
            position: 'absolute',
            zIndex: 2,
            right: 0,
            top: 10,
            marginTop: 6,
        },
        cover: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    };

    return (
        <div className={`color-picker ${className}`}>
            <div
                className="color-picker--swatch"
                style={styles.swatch}
                onClick={(e) => {
                    e.stopPropagation();
                    setShowPicker(!showPicker);
                }}
            />
            {showPicker && !disabled ? (
                <div style={styles.popover}>
                    <CompactPicker
                        colors={SWATCHES}
                        style={{ padding: 10 }}
                        color={value}
                        onChange={(v) => {
                            setBackgroundColor(v.hex);
                            onChange(v.hex);
                            setShowPicker(false);
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
