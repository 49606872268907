import { CanvasConstants as K } from './Canvas.constants';

export const ResetCanvasState = () => {
    return {
        type: K.ACTIONS.RESET_CANVAS_STATE,
    };
};

export const setCanvasResource = (
    canvasses
) => {
    return {
        type: K.ACTIONS.SET_CANVAS_RESOURCE,
        payload: { canvasses },
    };
};
