/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import './index.scss';
import { last } from 'lodash';

export default class HorizontalVirtualizeNoScroll extends Component {
    constructor(props) {
        super(props);
        this.viewPort = createRef();
        this.state = {
            start: 0,
            end: this.props.numsOfVisibleItems,
            width: 100,
            dataLength: 0,
            arrayWidth: [],
            arrayLeft: [0],
            numsOfVisibleItems: this.props.numsOfVisibleItems
        };
        this._timeout = null;
        this.renderRows = this.renderRows.bind(this);
    }

    componentDidMount() {
        const { dataLength, colWidth } = this.props;
        const { start, arrayWidth, arrayLeft } = this.state;
        for (let i = start; i <= dataLength - 1; i++) {
            arrayWidth.push(colWidth({ index: i }));
        }

        arrayWidth.reduce((total, num) => {
            arrayLeft.push(total);
            return total + num;
        });

        let lastTotalWidth = last(arrayLeft) + colWidth({ index: dataLength - 1 });

        this.setState({
            width: lastTotalWidth,
            dataLength: dataLength,
            arrayLeft: arrayLeft,
            arrayWidth: arrayWidth
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dataLength, colWidth, numsOfVisibleItems } = nextProps;
        const newArrayWidth = [];
        const newArrayLeft = [0];

        for (let i = 0; i <= dataLength - 1; i++) {
            newArrayWidth.push(colWidth({ index: i }));
        }

        newArrayWidth.reduce((total, num) => {
            newArrayLeft.push(total);
            return total + num;
        });

        let lastTotalWidth =
      last(newArrayLeft) + colWidth({ index: dataLength - 1 });

        this.setState({
            start: 0,
            end: numsOfVisibleItems,
            width: lastTotalWidth,
            numsOfVisibleItems: numsOfVisibleItems,
            dataLength: dataLength,
            arrayLeft: newArrayLeft,
            arrayWidth: newArrayWidth
        });
    }

    renderRows() {
        let result = [];
        const { start, end, arrayLeft } = this.state;
        const { colWidth, renderRow, showLoading } = this.props;

        if (!showLoading) {
            for (let i = start; i <= end; i++) {
                result.push(
                    <div
                        key={i}
                        style={{
                            width: colWidth({ index: i }),
                            position: 'absolute',
                            left: arrayLeft[i],
                            height: '100%'
                        }}
                    >
                        {renderRow({ index: i })}
                    </div>
                );
            }

            return result;
        }

        return null;
    }

    render() {
        const {
            viewPortHeight,
            viewPortWidth,
            style,
            showLoading,
            customLoading
        } = this.props;
        const { width } = this.state;
        return (
            <div
                style={{
                    height: viewPortHeight,
                    width: viewPortWidth,
                    position: 'relative'
                }}
            >
                <div
                    ref={this.viewPort}
                    style={{
                        height: viewPortHeight ? viewPortHeight : '100%',
                        overflowX: 'hidden',
                        position: 'relative',
                        width: viewPortWidth ? viewPortWidth : '100%',
                        ...style
                    }}
                >
                    <div
                        style={{
                            width: width,
                            position: 'relative',
                            height: '100%'
                        }}
                    >
                        {this.renderRows()}
                    </div>
                </div>
                {showLoading ? (
                    <div className="animate_loading">
                        {customLoading ? (
                            customLoading()
                        ) : (
                            <div className="lds-roller">
                                {new Array(8).fill(null).map((v, i) => (
                                    <div key={i} />
                                ))}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}
