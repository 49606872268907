import { AddCircle } from "@mui/icons-material"
import React from "react"

const IconAdd = (props: Props) => {
    return <AddCircle className={props.className} />
}

interface Props {
    className?: string
}

export default IconAdd
