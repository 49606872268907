/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Label, parseLabelArguments } from '../../models';
import { api_getEntityLabels } from '../../../store/old/Labels/Labels.services';
import LabelSearchComponent from './Search.component';
import { errorFlash } from '../../components/Flash';


class LabelSearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { labels: [] };

        this.getLabels = this.getLabels.bind(this);
    }

    getLabels = (from, to) => {
        const query = {
            from: from.toISOString(),
            to: to.toISOString(),
        };

        api_getEntityLabels(query)
            .then((res) => {
                this.setState({
                    labels: res.map((l) => new Label(...parseLabelArguments(l))),
                });
            })
            .catch((e) => errorFlash(e));
    };

    render = () => {
        return (
            <LabelSearchComponent {...(this.state)} getLabels={this.getLabels} />
        );
    };
}

const mapStateToProps = (appState) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabelSearchContainer);
