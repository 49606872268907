const CurrencyConstants = [
    {
        code: "CNY",
        label: "CNY (CN¥)"
    },
    {
        code: "EUR",
        label: "EUR (€)"
    },
    {
        code: "GBP",
        label: "GBP (£)"
    },
    {
        code: "IDR",
        label: "IDR (IDR)"
    },
    {
        code: "JPY",
        label: "JPY (JP¥)"
    },
    {
        code: "MYR",
        label: "MYR (MYR)"
    },
    {
        code: "SGD",
        label: "SGD (SGD)"
    },
    {
        code: "USD",
        label: "USD (US$)"
    },
    {
        code: "THB",
        label: "THB (THB)"
    },
]

export default CurrencyConstants
