/* eslint-disable react/prop-types */
import React from 'react';

import FormInput from '../../../components/FormInput';

import {
    PanelBody,
    PanelFooter,
    PanelHeader,
    Panel,
} from '../../../components/Panel';

import './CorrelationFormModal.scss';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { createCorrelationRequest } from '../../../../store/old/Correlations/Correlations.action';
import { changeRegressionSelection } from '../../../../store/old/UI/Regression/RegressionUI.action';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';

class CorrelationFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { title: '', category: '' };

        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // const {
    //   props: { selection },
    // } = this;
    // if (prevProps.selection !== this.props.selection) {
    //   this.setState({
    //     title: selection ? selection.title : '',
    //     category: selection ? selection.category : '',
    //   });
    // }
    // }

    handleChangeInput(field, e) {
        this.setState({ [field]: e.target.value });
    }

    // get isUpdate() {
    //   return this.props.selection;
    // }

    get formData() {
        const { title, category: _category } = this.state;

        const category = _category
            .split(',')
            .map((word) => word.trim())
            .join(', ');

        return {
            title,
            category,
        };
    }

    get footerButtons() {
        const { entity_id } = this.props.entity;
        const buttons = [
            {
                label: (
                    <span>
                        <i className="fas fa-times mr-1" />
            Cancel
                    </span>
                ),
                className: 'btn b-cancel',
                handleClick: this.props.toggle,
            },
            {
                label: (
                    <span>
                        <i className="fas fa-save mr-1" />
            Save
                    </span>
                ),
                className: 'btn b-save',
                handleClick: () => {
                    const data = this.props.getCorrelationFormData(this.formData);
                    this.props.createCorrelation(entity_id, data, () => {
                        this.props.changeSelection(-1);
                        this.props.toggle();
                    });
                },
            },
        ];

        return (
            <>
                {buttons.map((btn, i) => (
                    <button
                        className={`text-uppercase ${btn.className}`}
                        onClick={btn.handleClick}
                        key={i}
                    >
                        {btn.label}
                    </button>
                ))}
            </>
        );
    }

    render() {
        const {
            state: { title, category },
            // isUpdate,
            handleChangeInput,
            footerButtons,
        } = this;

        // const action = isUpdate ? 'Update' : 'Create';
        const action = 'Create';

        return (
            <Modal
                className="correlation-form"
                isOpen={this.props.show}
                toggle={this.props.onClose}
            >
                <Panel>
                    <PanelHeader>{action} Analysis</PanelHeader>
                    <PanelBody>
                        <FormInput
                            label="Title"
                            value={title}
                            handleChange={(e) => handleChangeInput('title', e)}
                            required
                        />
                        <FormInput
                            label="Category"
                            value={category}
                            handleChange={(e) => handleChangeInput('category', e)}
                        />
                    </PanelBody>
                    <PanelFooter>{footerButtons}</PanelFooter>
                </Panel>
            </Modal>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        regressionStore: appState.ui.regression,
        entity: currentEntitySelector(appState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCorrelation: (entityId, data, cb) =>
            dispatch(createCorrelationRequest(entityId, data, cb)),
        changeSelection: (id) => dispatch(changeRegressionSelection(id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CorrelationFormModal);
