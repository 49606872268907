import type UserModel from "../models/user.model"

const UserTransformer = (res: Record<any, any>): UserModel => ({
    userId: res.user_id,
    roleName: res.role_name,
    email: res.email,
    nameFirst: res.name_first,
    nameLast: res.name_last,
    mobile: res.mobile,
    countryCode: res.country_code,
    appointment: res.appointment,
    userImg: res.user_img,
    userImgId: res.user_img_id,
    entityId: res.entity_id,
    confirmed: res.confirmed,
    policies: res.policies,
})

export default UserTransformer
