import { Box, Button, Popover } from "@mui/material"
import React from "react"
import theme from "../../styles/theme"
import DeleteIcon from "@mui/icons-material/Delete"

const ConfirmPopoverButton = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget) }
    const handleClosePopover = () => { setAnchorEl(null) }

    const open = Boolean(anchorEl)
    const id = open ? props.id : undefined

    return <>
        <Button variant="text" onClick={handleOpenPopover} disabled={props.disabled}>
            <DeleteIcon sx={{ fontSize: 24 }} />
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            {props.children}
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleClosePopover}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        props.onConfirm()
                        handleClosePopover()
                    }}
                    sx={{ background: theme.palette.error.main }}
                >
                    Delete
                </Button>
            </Box>
        </Popover>
    </>
}

interface Props {
    id: string
    onConfirm: () => void
    children: React.ReactNode
    disabled?: boolean
}

export default ConfirmPopoverButton
