import "./style.scss"
import { Typography } from "@mui/material"
import classNames from "classnames"
import moment from "moment"
import React from "react"
import Grid from "../../Grid/Grid"
import { CalendarProps } from "../types"
import MonthCalendarButton from "./MonthCalendarButton/MonthCalendarButton"

const MonthCalendar = (props: CalendarProps) => {
    const handlePress = (value: moment.Moment) => {
        props.onSelect(value)
    }

    const getMonthValue = (month: number): moment.Moment => {
        return props.referenceDate.clone().month(month-1)
    }

    return <Grid className={classNames(props.className, "month-calendar")}>
        <Grid container className="month-calendar-actions">
            <Grid item className="calendar-actions-item">{props.actions?.left}</Grid>
            <Grid item flex={1} className="calendar-actions-year">
                <Typography variant="subtitle1">{props.referenceDate.year()}</Typography>
            </Grid>
            <Grid item className="calendar-actions-item">{props.actions?.right}</Grid>
        </Grid>
        <Grid container direction="column" className="month-calendar-buttons">
            <Grid container item direction="row" className="calendar-buttons-row">
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(1)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(2)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(3)}
                />
            </Grid>
            <Grid container item direction="row" className="calendar-buttons-row">
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(4)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(5)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(6)}
                />
            </Grid>
            <Grid container item direction="row" className="calendar-buttons-row">
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(7)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(8)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(9)}
                />
            </Grid>
            <Grid container item direction="row" className="calendar-buttons-row">
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(10)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(11)}
                />
                <MonthCalendarButton
                    onPress={handlePress}
                    start={props.start}
                    end={props.end}
                    value={getMonthValue(12)}
                />
            </Grid>
        </Grid>
    </Grid>
}

export default MonthCalendar
