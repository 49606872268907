/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LabelConstants as K } from '../../../../store/old/Labels/Labels.constants';
import './LabelCounters.scss';
import Counter from '../../../../components/Counter/Counter';

let timerId = null;
export const OEETimer = (props) => {
    const { tagging } = props;

    if (!tagging) {
        clearInterval(timerId);
        return (
            <Counter 
                label={<><span className="text-uppercase">Issue</span>: Elapsed</>}
                withLoader
                loading={false}
                placeholder="--:--:--"
            />
        //     <div className="label-counter">
        //         <div className="start-stop-circle" />
        //         <p className="label">
        //             <span className="text-uppercase">Issue</span>: Elapsed
        //         </p>
        //         <p className="value" style={{ opacity: 0.8 }}>
        //   --:--:--
        //         </p>
        //     </div>
        );
    }

    const [oeeTotalTime, setOeeTotalTime] = useState(moment().startOf('day'));

    useEffect(() => {
        startTimer();

        return () => clearInterval(timerId);
    }, []);

    const startTimer = () => {
        timerId = setInterval(() => {
            const currentTime = moment();
            const oeeFrom = moment(tagging.from);
            const diff = currentTime.diff(oeeFrom, 'second');
            const newOeeTotalTime = moment(oeeTotalTime).add(diff, 'second');
            setOeeTotalTime(newOeeTotalTime);
        }, 1000);
    };

    return (
        <Counter 
            label={<><span className="text-uppercase">Issue</span>: Elapsed</>}
            withLoader
            loading={true}
            value={moment(oeeTotalTime).format('HH:mm:ss')}
        />
    );
};

export const SKUTotal = (props) => {
    const { tagging } = props;

    const defaultComponent = (
        <Counter 
            label={<><span className="text-uppercase">SKU</span>: Output</>}
            withLoader
            loading={false}
            placeholder="--"
        />
    );

    if (!tagging) return defaultComponent;

    const isTaggingSKU = tagging.label_values.find(
        ({ type }) => type === K.TYPES.SKU
    );

    if (!isTaggingSKU) return defaultComponent;
    return (
        <Counter 
            label={<><span className="text-uppercase">SKU</span>: Output</>}
            withLoader
            loading={true}
            value={isTaggingSKU.total ? isTaggingSKU.total.toFixed(0) : 0}
        />
    );
};

const LabelCounters = (props) => {
    return (
        <div className="label-counters">
            <OEETimer tagging={props.asset.liveIssueLabel} />
            <SKUTotal tagging={props.asset.liveProductLabel} />
        </div>
    );
};

export default LabelCounters;
