import React, { useState } from 'react';
import { regexMatch } from '../../../../utils/helpers';
import './OperatorLabelPanel.scss';

const PanelItem = (props: any) => {
    const { data, collate, label, handleClick, isDisabled, style } = props;
    return (
        <div
            className={`panel-item ${isDisabled ? 'is-disabled' : ''}`}
            style={style}
            onClick={() => !isDisabled && handleClick(collate(data))}
        >
            {label}
        </div>
    );
};

export const PanelWO = ({
    data = [],
    title,
    className = '',
    handleClickAdd,
    handleClickItem,
    handleClickQR,
    style = {},
}: any) => {
    const [inputValue, handleChangeInput] = useState('');

    const handleAdd = () => handleClickAdd(inputValue);

    return (
        <div className={`basic-view-panel ${className}`} style={style}>
            <div className="d-flex flex-column basic-view-panel-header">
                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                    <div className="d-flex flex-row" style={{ flexGrow: 1 }}>
                        <p className="header-tag">{title}</p>
                        <div className="input-group-append">
                            <input
                                className="form-control"
                                onChange={(e) => handleChangeInput(e.target.value)}
                            />
                            <div
                                className="input-group-append__icon"
                                onClick={() => !data.length && handleAdd()}
                            >
                                <i
                                    className="fas fa-plus"
                                    style={{
                                        width: 18,
                                        height: 18,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <QrIconWithBorder onClick={handleClickQR} />
                </div>
            </div>
            <div className="basic-view-panel-content">
                <div
                    className="d-flex flex-row justify-content-between"
                    style={{ flexWrap: 'wrap' }}
                >
                    {data.map((d, i) => (
                        <PanelItem key={i} {...d} handleClick={handleClickItem} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const Panel = ({
    data = [],
    title,
    className = '',
    handleClickItem,
    handleClickQR,
    style = {},
}: any) => {
    const [inputValue, handleChangeInput] = useState('');

    const filteredItems = data.filter((d) =>
        regexMatch(d.label || '', inputValue, { escape: true })
    );

    return (
        <div className={`basic-view-panel ${className}`} style={style}>
            <div className="d-flex flex-column basic-view-panel-header">
                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                    <div className="d-flex flex-row" style={{ flexGrow: 1 }}>
                        <p className="header-tag">{title}</p>
                        <div className="input-group-append">
                            <input
                                className="form-control"
                                onChange={(e) => handleChangeInput(e.target.value)}
                            />
                            <div className="input-group-append__icon">
                                <i
                                    className="fas fa-search"
                                    style={{
                                        width: 18,
                                        height: 18,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <QrIconWithBorder onClick={handleClickQR} />
                </div>
            </div>
            <div className="basic-view-panel-content">
                <div
                    className="d-flex flex-row justify-content-between"
                    style={{ flexWrap: 'wrap' }}
                >
                    {filteredItems.map((d, i) => (
                        <PanelItem key={i} {...d} handleClick={handleClickItem} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const QrIconWithBorder = (props: any) => {
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            onClick={props.onClick}
            style={{
                flexShrink: 0,
                border: '2px solid #616161',
                height: 32,
                width: 32,
                marginLeft: 10,
                cursor: 'pointer',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    height: '20px',
                    width: '32px',
                    backgroundColor: '#ececec',
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    height: '32px',
                    width: '20px',
                    backgroundColor: '#ececec',
                }}
            />
            <i
                className="fas fa-qrcode"
                style={{
                    position: 'absolute',
                    width: 24,
                    height: 24,
                }}
            />
        </div>
    );
};
