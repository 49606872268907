export const LabelConstants = {
    ACTIONS: {
        SET_LABELS_STATE: 'SET_LABELS_STATE',
        FETCH_ASSET_LABELS_REQUEST: 'FETCH_ASSET_LABELS_REQUEST',
        SET_LABELS_RESOURCE: 'SET_LABELS_RESOURCE',
        ADD_LABELS_RESOURCE: 'ADD_LABELS_RESOURCE',
        REPLACE_LABEL_RESOURCE: 'REPLACE_LABEL_RESOURCE',
        CREATE_LABEL_REQUEST: 'CREATE_LABEL_REQUEST',
        STOP_LABEL_REQUEST: 'STOP_LABEL_REQUEST',
        UPDATE_LABEL_REQUEST: 'UPDATE_LABEL_REQUEST',
        DELETE_LABEL_REQUEST: 'REMOVE_LABEL_REQUEST',
        REMOVE_LABEL_RESOURCE: 'REMOVE_LABEL_REQUEST_SUCCESS',
        RESET_LABELS_STATE: 'RESET_LABELS_STATE',
    },
    TYPES: {
        SKU: 'sku',
        ISSUE: 'issue',
        'W/O': 'w/o',
        'S/N': 's/n',
    },
};
