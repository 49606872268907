/* eslint-disable react/prop-types */
import React from 'react';

import './OEEScale.scss';

const OEEScale = (props) => {
    return (
        <div className="oee-scale--container">
            <div
                className="oee-scale"
                style={{
                    backgroundImage: props.gradient,
                }}
            >
                <label>0</label>
                <label>OEE</label>
                <label>100</label>
            </div>
        </div>
    );
};

export default OEEScale;
