import { Grid as BaseGrid } from "@mui/material"
import { GridProps } from "@mui/material/Grid/Grid"
import React, { forwardRef } from "react"

const Grid = forwardRef<any, GridProps>((props, ref) => {
    return <BaseGrid {...props} ref={ref}>
        {props.children}
    </BaseGrid>
})

Grid.displayName = "Grid"

export default Grid
