import MOMENT from 'moment';
import { BinarySearchForIndex } from '../../utils/binarySearch';

import { extendMoment } from 'moment-range';

const moment = extendMoment(MOMENT);

export const createIdDate = (date) => {
    const dateMoment = moment(date);
    return dateMoment.valueOf();
};

export const getArrayFromTwoDates = (startDate, endDate, step) => {
    let showDate = false;
    let dateRange = moment.range(startDate, endDate);
    let arrayDates = Array.from(
        dateRange.by('minutes', { step: step, excludeEnd: true })
    );

    arrayDates = arrayDates.map((i) => {
        const startDateInArray = moment(i).startOf('date');

        if (
            (step === 30 || step === 60) &&
      moment(startDate).isSame(moment(i), 'minute')
        ) {
            showDate = true;
        } else {
            showDate = false;
        }

        if (startDateInArray.isSame(moment(i)) || showDate) {
            return {
                id: createIdDate(i),
                day: moment(i).format('ddd DD'),
                time: moment(i).format('HH:mm'),
                showDate: true,
            };
        }

        return {
            id: createIdDate(i),
            day: moment(i).format('ddd DD'),
            time: moment(i).format('HH:mm'),
            showDate: false,
        };
    });

    return arrayDates;
};

export const generateLeftForAbsItem = (
    arr,
    x,
    start,
    end,
    colWidth,
    period
) => {
    const objectFound = BinarySearchForIndex(arr, x, start, end);

    if (objectFound.AfterMid) {
        const left =
      ((x - arr[objectFound.mid]) * colWidth) /
      (arr[objectFound.AfterMid] - arr[objectFound.mid]);

        return {
            showBorderLeft: true,
            showBorderRight: true,
            left: objectFound.mid * colWidth + left,
        };
    }

    if (objectFound.mid === 0 && !objectFound.AfterMid) {
        return {
            showBorderRight: true,
            showBorderLeft: false,
            left: 0,
        };
    }

    if (objectFound.mid === end && !objectFound.AfterMid) {
        const diffMins = moment(x).diff(moment(arr[end]), 'minute');
        const viewOptionsStep = period.step;
        const viewOptionsColWidth = period.colWidth;

        if (diffMins >= viewOptionsStep) {
            return {
                showBorderRight: false,
                showBorderLeft: true,
                left: (objectFound.mid + 1) * colWidth,
            };
        }

        if (diffMins < viewOptionsStep) {
            const left = (diffMins / viewOptionsStep) * viewOptionsColWidth;

            return {
                showBorderRight: true,
                showBorderLeft: false,
                left: objectFound.mid * colWidth + left,
            };
        }
    }
};
