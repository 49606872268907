import { callPreferenceDestroy, callEntityUserPreferencesIndex, callEntityUserPreferencesStore } from "../../api"
import { UserPreferenceTransformer } from "../../api/transformers/preference.transformer"
import { session } from "../../legacy/auxStore"
import { flash } from "../../legacy/components/Flash"
import { createAppAsyncThunk } from "../index"
import { FetchUser } from "../old/Authentication/Authentication.action"
import { clear, setLoading, set, add, remove } from "../reducers/preferences.reducer"
import { snacksErrorMessage } from "./snacks.action"

export const entityUserPreferencesGetAction = createAppAsyncThunk<Promise<void>, GetEntityUserPreferencesPayload>(
    "userPreferences/get",
    async ({ entityId }, { dispatch }) => {
        void dispatch(clear())
        void dispatch(setLoading(true))

        try {
            const { data } = await callEntityUserPreferencesIndex(entityId)

            void dispatch(set(data.map(UserPreferenceTransformer)))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface GetEntityUserPreferencesPayload {
    entityId: number
}

export const entityUserPreferencesStoreAction = createAppAsyncThunk<Promise<void>, SaveEntityUserPreferencesPayload>(
    "userPreferences/save",
    async ({ entityId, payload }, { dispatch }) => {
        void dispatch(setLoading(true))

        try {
            const { data } = await callEntityUserPreferencesStore(entityId, payload)
            void dispatch(add(data.map(UserPreferenceTransformer)))

            const affectsCurrentUser = data.find((p: any) => p.user_id === +(session.user_id as string))
            if (affectsCurrentUser) { void dispatch(FetchUser()) }
            flash({ message: "Preferences updated" })
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface SaveEntityUserPreferencesPayload {
    entityId: number
    payload: any[]
}

export const entityUserPreferencesDestroyAction = createAppAsyncThunk<Promise<void>, DestroyEntityUserPreferencesPayload>(
    "userPreferences/destroy",
    async ({ entityId, preferenceId }, { dispatch }) => {
        void dispatch(setLoading(true))

        try {
            await callPreferenceDestroy(entityId, preferenceId)
            void dispatch(remove(preferenceId))
            flash({ message: "Preference removed" })
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface DestroyEntityUserPreferencesPayload {
    entityId: number
    preferenceId: number
}
