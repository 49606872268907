import React, { forwardRef, type KeyboardEventHandler } from "react"
import { type NumberFormatValues, NumericFormat } from "react-number-format"
import TextInput, { type Props as TextInputProps } from "../TextInput/TextInput"

const NumberInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const handleIsAllowed = (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true
        }

        if (props.min && values.floatValue < props.min) {
            return false
        }

        if (props.max && values.floatValue > props.max) {
            return false
        }

        return true
    }

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === "-") {
            e.preventDefault()
        }
    }

    const { endAdornment, ...TextInputProps } = props
    return <NumericFormat
        variant="filled"
        {...TextInputProps}
        type="text"
        getInputRef={ref}
        className={props.className}
        decimalScale={props.decimalScale ?? 0}
        customInput={TextInput}
        onKeyDown={handleKeyDown}
        isAllowed={handleIsAllowed}
        inputProps={{ textAlign: props.align }}
        endAdornment={endAdornment}
        style={{ width: props.width }}
        sx={{ ...props.sx, width: props.width }}
    />
})

type Props = TextInputProps & {
    className?: string
    maxLength?: number
    endAdornment?: React.ReactNode
    width?: number
    value?: string | number | null | undefined
    defaultValue?: string | number | null | undefined
    min?: number
    max?: number
    align?: "start" | "end" | "center"
    decimalScale?: number
}

NumberInput.displayName = "NumberInput"

export default NumberInput
