import { updateStoreRedux } from '../../../legacy/utils/redux';
import { FusionConstants as K } from './Fusions.constants';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    fusions: {},
    error: {},
});

export const fusionsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_FUSIONS_RESOURCE) {
        return updateStoreRedux(state, {
            fusions: action.payload,
        });
    }

    if (action.type === K.ACTIONS.ADD_FUSIONS_RESOURCE) {
        return updateStoreRedux(state, {
            fusions: { ...state.fusions, ...action.payload },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_FUSIONS_RESOURCE) {
        const deleteSet = new Set(action.payload.fusion_ids);
        const deletedState = pickBy(
            { ...state.fusions },
            ({ fusion_id }) => !deleteSet.has(fusion_id)
        );

        return updateStoreRedux(state, {
            fusions: deletedState,
        });
    }

    if (action.type === K.ACTIONS.UPDATE_FUSION_SUCCESS) {
        return updateStoreRedux(state, {
            fusions: {
                ...state.fusions,
                [action.payload.fusion_id]: action.payload,
            },
        });
    }

    if (action.type === K.ACTIONS.RESET_FUSIONS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
