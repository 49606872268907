/* eslint-disable react/prop-types */
import moment from 'moment';
import { findIndex } from 'lodash';
import React, { useContext, Fragment } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

// import { MultiVirtualizeNoScroll } from '../../components/virtualized';
import { MultiVirtualizeNoScroll } from '../../components/virtualized';
import { TargetSettingContext } from '.';
import { Resolution } from './Resolution';
import { UPDATE } from './Action';

import { generateLeftForAbsItem } from './targets.utils';

export const AssetPlanTable = (props) => {
    const {
        arrayDates,
        plans,
        period,
        activeAssetId,
        rightGroupSize,
        leftGroupSize,
        assetsDisplayed,
    } = useContext(TargetSettingContext);

    const arrayDatesLength = arrayDates.length;
    const dateRangeIds = arrayDates.map((v) => v.id);
    const assetLength = assetsDisplayed.length;
    const colHeight = 60;
    const colWidth = rightGroupSize / (period.visibleItems + 1);
    const numberColVisibleItem = period.visibleItems;
    const viewPortHeight = assetLength * colHeight;

    function renderRowAsset(a) {
        return (
            <div
                className={`ts-asset-column-name ${
                    activeAssetId === a.asset_id ? 'ts-selected-asset' : ''
                }`}
            >
                {a.asset_name}
            </div>
        );
    }

    if (assetLength === 0) {
        return null;
    }

    return (
        <div className="row assets-plan-table">
            <div className="asset-column" style={{ width: leftGroupSize }}>
                <div
                    style={{
                        height: viewPortHeight,
                        position: 'relative',
                        width: '100%',
                    }}
                >
                    {assetsDisplayed.map((v, i) => {
                        return (
                            <div
                                id={v.asset_id}
                                key={v.asset_id}
                                style={{
                                    height: colHeight,
                                    position: 'relative',
                                }}
                            >
                                {renderRowAsset(v)}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="plan-table" style={{ width: rightGroupSize }}>
                <MultiVirtualizeNoScroll
                    colHeight={() => colHeight}
                    colWidth={() => colWidth}
                    numbersOfCol={arrayDatesLength}
                    numbersOfRow={assetLength}
                    viewPortHeight={viewPortHeight}
                    renderRow={() => <div />}
                    numsOfVisibleColItems={numberColVisibleItem}
                    numsOfVisibleRowItems={assetLength}
                    showLoading={false}
                    additionalComponent={
                        <Fragment>
                            <Resolution viewPortHeight={viewPortHeight} />
                            <Plans
                                assets={assetsDisplayed}
                                colHeight={colHeight}
                                data={plans}
                                dateRangeIds={dateRangeIds}
                                colWidth={colWidth}
                                period={period}
                            />
                        </Fragment>
                    }
                />
            </div>
        </div>
    );
};

const Plans = ({ colHeight, assets, data, dateRangeIds, colWidth, period }) => {
    let planArrayComp = [];

    for (let item of data) {
        planArrayComp.push(
            <Plan
                colHeight={colHeight}
                assets={assets}
                key={item.target_id}
                item={item}
                dateRangeIds={dateRangeIds}
                colWidth={colWidth}
                period={period}
            />
        );
    }

    return planArrayComp;
};

const Plan = ({ colHeight, assets, item, dateRangeIds, colWidth, period }) => {
    const planColor = item.sku ? item.sku.color : '#000000';

    const { setCurrentPlan, changeModalStatus, setAction, filteredSKU } =
    useContext(TargetSettingContext);

    const hasActiveSKUFilter = !filteredSKU ? true : item.sku_id === filteredSKU;

    let topAssetElement;
    const indexAssetName = findIndex(assets, (v) => v.asset_id === item.asset_id);

    if (indexAssetName > -1) {
        topAssetElement = indexAssetName * colHeight;
    }

    const itemFrom = moment(item.from).valueOf();
    const itemTo = moment(item.to).valueOf();

    if (itemFrom > itemTo) {
        return null;
    }

    const indexObjectFrom = generateLeftForAbsItem(
        dateRangeIds,
        itemFrom,
        0,
        dateRangeIds.length - 1,
        colWidth,
        period
    );

    const indexObjectTo = generateLeftForAbsItem(
        dateRangeIds,
        itemTo,
        0,
        dateRangeIds.length - 1,
        colWidth,
        period
    );

    const width = indexObjectTo.left - indexObjectFrom.left;
    const top = topAssetElement + 8;

    const handleSetCurrentPlan = () => {
        unstable_batchedUpdates(() => {
            setCurrentPlan(item);
            setAction(UPDATE);
            changeModalStatus();
        });
    };

    let style;

    let mainStyle = {
        top: top,
        left: indexObjectFrom.left,
        width: width,
        borderWidth: '1px 1px 1px 10px',
        borderStyle: 'solid',
        borderColor: planColor,
    };

    let styleWithBorderRight = {
        top: top,
        left: indexObjectFrom.left,
        width: width,
        borderWidth: '1px 10px 1px 1px',
        borderStyle: 'solid',
        borderColor: planColor,
        borderLeft: 'none',
    };

    let styleWithBorderLeft = {
        top: top,
        left: indexObjectFrom.left,
        width: width,
        borderWidth: '1px 1px 1px 10px',
        borderStyle: 'solid',
        borderColor: planColor,
        borderRight: 'none',
    };

    let styleWithoutBorder = {
        top: top,
        left: indexObjectFrom.left,
        width: width,
        borderWidth: '1px 1px 1px 10px',
        borderStyle: 'solid',
        borderColor: planColor,
        borderLeft: 'none',
        borderRight: 'none',
    };

    if (indexObjectTo.left === 0 || width === 0) {
        return null;
    }

    if (!indexObjectFrom.showBorderLeft && indexObjectTo.showBorderRight) {
        style = styleWithBorderRight;
    }

    if (indexObjectFrom.showBorderLeft && !indexObjectTo.showBorderRight) {
        style = styleWithBorderLeft;
    }

    if (!indexObjectFrom.showBorderLeft && !indexObjectTo.showBorderRight) {
        style = styleWithoutBorder;
    }

    return (
        <div
            className={`plan-label ${
                hasActiveSKUFilter ? '' : 'plan-label--disabled'
            }`}
            style={style ? style : mainStyle}
            onClick={() => hasActiveSKUFilter && handleSetCurrentPlan()}
        >
            <div className="plan-lable-wrapper">
                <div className="label-target">{item.value}</div>
                <div className="label-calendar">
                    <div className="sku">SKU: {item.sku ? item.sku.name : 'None'}</div>
                    <div className="date">
            From: {moment(item.from).format('MMM DD / HH:mm')} - To:{' '}
                        {moment(item.to).format('MMM DD / HH:mm')}
                    </div>
                </div>
            </div>
        </div>
    );
};
