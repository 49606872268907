export const parseGroupArguments = (g) => {
    return [g.entity_id, g.group_id, g.group_name, g.user_groups];
};

export class Group {
    constructor(
        entity_id,
        group_id,
        group_name,
        user_groups
    ) {
        this.entity_id = entity_id;
        this.group_id = group_id;
        this.group_name = group_name;
        this.user_groups = user_groups;
    }
}
