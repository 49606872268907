import { type SxProps, TextField, Button } from "@mui/material"
import classNames from "classnames"
import React from "react"

import SaveIcon from "@mui/icons-material/Save"

const PlainTextInputWithSubmit = (props: Props) => {
    return (
        <TextField
            onBlur={props.onBlur}
            hiddenLabel
            id={props.id}
            className={classNames("plain-text-input-with-submit", props.className)}
            value={props.value}
            sx={{
                height: 32,
                ".MuiInputBase-root.MuiOutlinedInput-root": {
                    paddingRight: 0,
                },
                " .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 1,
                    borderColor: "#71787E"
                },
                ".MuiOutlinedInput-input": {
                    padding: "6px 12px",
                },
                ...props.sx
            }}
            onChange={e => { props.onChange(e.target.value) }}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    props.onEscape && props.onEscape()
                }
                if (e.key === "Enter") {
                    props.onSubmit && props.onSubmit()
                }
            }}
            inputProps={{ maxLength: props.maxLength ?? 80 }}
            InputProps={{
                endAdornment: <Button
                    variant="text"
                    onClick={() => { 
                        props.onSubmit && props.onSubmit() 
                    }}
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <SaveIcon sx={{ fontSize: 24 }}/>
                </Button>
            }}
        />
    )
}

interface Props {
    className?: string
    id?: string
    value: string
    onChange: (value: string) => void
    onEscape?: () => void
    onSubmit?: (val?: string) => void
    sx?: SxProps
    maxLength?: number
    onBlur?: () => void

}

export default PlainTextInputWithSubmit
