import _ from 'lodash';
import { createSelector } from 'reselect';

export const schedulesState = (appState) =>
    appState.schedules;

export const arrayShifts = (appState) => {
    const { shifts } = schedulesState(appState);
    return _.values(shifts).sort((a, b) => {
        {
            if (a.shift_name < b.shift_name) return -1;
            if (a.shift_name > b.shift_name) return 1;
            return 0;
        }
    });
};

export const arrayDowntimes = (appState) => {
    const { downtimes } = schedulesState(appState);
    return _.values(downtimes).sort((a, b) => {
        {
            if (a.downtime_name < b.downtime_name) return -1;
            if (a.downtime_name > b.downtime_name) return 1;
            return 0;
        }
    });
};

export const shiftsSelector = createSelector(schedulesState, (v) => v.shifts);
