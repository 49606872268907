import "./style.scss"
import {
    Dialog as BaseDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { Breakpoint } from "@mui/system"
import React, { useEffect } from "react"

const Dialog = (props: Props) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    useEffect(() => {
        if (props.onClose && !props.open) {
            props.onClose()
        }
    }, [props.open])

    const handleClose = () => {
        props.setOpen(false)
    }

    return <BaseDialog
        fullWidth
        aria-labelledby="responsive-dialog-title"
        maxWidth={props.maxWidth ?? "sm"}
        open={props.open}
        fullScreen={fullScreen}
        onClose={handleClose}
    >
        <DialogTitle id="responsive-dialog-title" className="dialog-title">{props.title}</DialogTitle>
        <DialogContent className="dialog-content">{props.children}</DialogContent>
        <DialogActions className="dialog-actions">{props.actions}</DialogActions>
    </BaseDialog>
}

interface Props {
    title: string
    actions: React.ReactNode
    children: React.ReactNode
    setOpen: (value: boolean) => void
    open: boolean
    onClose?: () => void
    maxWidth?: Breakpoint | false
}

export default Dialog
