import { AxiosResponse, CanceledError } from "axios"
import { cloneDeep } from "lodash"
import moment from "moment"
import {
    callEntityBlockOee,
    callEntityBlockObjectivesStore,
    callEntityBlockObjectivesUpdate,
    callEntityObjectiveGoalsIndex,
    callEntityObjectiveGoalsStore,
    callEntityObjectivesDestroy,
    callEntityObjectivesIndex,
    callEntityObjectivesShow,
    callEntityObjectiveGoalUpdate,
} from "../../api"
import type ObjectiveModel from "../../api/models/objective.model"
import ObjectiveTransformer from "../../api/transformers/objective.transformer"
import { GoalsUnitOfTimeEnum } from "../../constants/goals.constants"
import timeSetWorkStartsAtAction from "../../lib/time/timeSetWorkStartsAt.action"
import { createAppAsyncThunk } from "../index"
import {
    clear,
    GoalMap,
    goalsUpdate,
    OeeMap,
    oeeUpdate,
    originalGoalsUpdate,
    set,
    setLoading,
    setViewMode,
    toggleDialog,
    view
} from "../reducers/goals.reducer"
import { snacksErrorMessage, snacksSuccessMessage } from "./snacks.action"
import { flattenTree } from "../../legacy/utils/helpers"

export const objectivesGetAction = createAppAsyncThunk<Promise<void>>(
    "objectives/get",
    async (_, { dispatch, getState }) => {
        const state = getState()
        const unitOfTime = state.goals.unitOfTime
        const entityId = state.entity.active
        void dispatch(clear())
        void dispatch(setLoading(true))

        try {
            const { data }: { data: ObjectiveModel[] } = await callEntityObjectivesIndex(entityId, {
                unit_of_time: unitOfTime,
            })

            void dispatch(set(data.map(ObjectiveTransformer)))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const objectivesViewAction = createAppAsyncThunk<Promise<void>, number>(
    "objectives/view",
    async (objectiveId, { dispatch, getState }) => {
        const state = getState()
        const entityId = state.entity.active
        void dispatch(setLoading(true))

        const goalMap: GoalMap = {}
        try {
            const { data: objective }: { data: ObjectiveModel } = await callEntityObjectivesShow(entityId, objectiveId)
            const { data: goals } = await callEntityObjectiveGoalsIndex(entityId, objectiveId)
            for (const goal of goals) {
                const blockId = +goal.block_id
                if (!goalMap[blockId]) {
                    goalMap[blockId] = {}
                }

                const timeKey: TimeKey = rangeToTimeKey({
                    startsAt: moment(goal.starts_at),
                    endsAt: moment(goal.ends_at),
                })

                if (!goalMap[blockId][timeKey]) {
                    goalMap[blockId][timeKey] = {
                        value: goal.value,
                        goalId: goal.id
                    }
                }
            }

            const viewing = ObjectiveTransformer(objective)
            void dispatch(goalsUpdate(goalMap))
            void dispatch(originalGoalsUpdate(goalMap))
            void dispatch(goalsFetchOeeAction(viewing))
            void dispatch(view(viewing))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const objectiveCreateAction = createAppAsyncThunk<Promise<any>, ObjectivesCreatePayload>(
    "objectives/create",
    async (payload, {
        dispatch,
        getState,
    }) => {
        const state = getState()
        const entityId = state.entity.active
        void dispatch(setLoading(true))

        try {
            const response = await callEntityBlockObjectivesStore(entityId, payload.blockId, {
                name: payload.name,
                starts_at: payload.startsAt.toISOString(),
                ends_at: payload.endsAt.toISOString(),
                unit_of_time: payload.unitOfTime,
            })

            void dispatch(objectivesGetAction())
            void dispatch(
                toggleDialog({
                    type: "add",
                    value: false,
                }),
            )

            return response.data
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface ObjectivesCreatePayload {
    blockId: number
    name: string
    startsAt: moment.Moment
    endsAt: moment.Moment
    unitOfTime: GoalsUnitOfTimeEnum
}

export const objectiveUpdateAction = createAppAsyncThunk<Promise<any>, ObjectivesUpdate>(
    "objectives/update",
    async (payload, {
        dispatch,
        getState,
    }) => {
        const state = getState()
        const entityId = state.entity.active
        const objective = state.goals.viewing
        if (!objective?.id) {
            throw new Error("An objective must be selected before saving the goal")
        }

        void dispatch(setLoading(true))

        try {
            const response = await callEntityBlockObjectivesUpdate(entityId, payload.blockId, payload.objectiveId, {
                name: payload.name,
                starts_at: objective.startsAt,
                ends_at: objective.endsAt,
                unit_of_time: objective.unitOfTime,
            })

            void dispatch(objectivesViewAction(payload.objectiveId))
            void dispatch(snacksSuccessMessage("Goal successfully updated"))
            return response.data
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

interface ObjectivesUpdate {
    blockId: number,
    name: string,
    objectiveId: number
}


export const objectivesDestroyAction = createAppAsyncThunk<Promise<void>, number[]>(
    "objectives/destroy",
    async (objectiveIds, {
        dispatch,
        getState,
    }) => {
        const state = getState()
        const entityId = state.entity.active
        void dispatch(setLoading(true))

        try {
            const promises = objectiveIds.map(objectiveId => callEntityObjectivesDestroy(entityId, objectiveId))
            await Promise.all(promises)
            void dispatch(objectivesGetAction())
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
            void dispatch(objectivesGetAction())
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const goalsUpdateGoalAction = createAppAsyncThunk<Promise<void>, GoalsUpdateGoalPayload>(
    "goals/updateGoal",
    async (payload, { dispatch, getState }) => {
        const state = getState()
        const goals: GoalMap = cloneDeep(state.goals.goals)
        if (!goals[payload.blockId]) {
            goals[payload.blockId] = {}
        }

        const goal = goals[payload.blockId][payload.timeKey]
        if (!!goal) {
            goals[payload.blockId][payload.timeKey] = { ...goal, value: payload.value }
        } else {
            goals[payload.blockId][payload.timeKey] = { value: payload.value }
        }
        void dispatch(goalsUpdate(goals))
    })

interface GoalsUpdateGoalPayload {
    blockId: Domain.BlockId
    timeKey: TimeKey
    value: number
}

export const goalsSaveEndGoalsAction = createAppAsyncThunk<Promise<void>>(
    "goals/save",
    async (_, { dispatch, getState }) => {
        const state = getState()
        const entityId = state.entity.active
        const objective = state.goals.viewing
        const objectiveId = objective?.id
        if (!objectiveId) {
            throw new Error("An objective must be selected before saving the goal")
        }

        const entity = state.entity.entities[entityId]
        const goals: GoalMap = cloneDeep(state.goals.goals)
        const originalGoals = state.goals.goalsOriginal
        void dispatch(setLoading(true))
        try {
            const endGoalRangeKey = rangeToTimeKey(objectiveEndRange(objective, entity))

            const promises: Promise<AxiosResponse>[] = []
            for (const blockId in goals) {
                const goal = goals[blockId][endGoalRangeKey]
                if (!goal) continue

                const { startsAt, endsAt } = timeKeyToTimeRange(endGoalRangeKey)
                if (!goal.goalId) {
                    promises.push(
                        callEntityObjectiveGoalsStore(entityId, objectiveId, {
                            block_id: blockId,
                            value: goal.value,
                            starts_at: startsAt.toISOString(),
                            ends_at: endsAt.toISOString(),
                        })
                    )
                    continue
                }

                const originalGoal = originalGoals[blockId][endGoalRangeKey]
                if (goal.value ===  originalGoal.value) continue
                promises.push(
                    callEntityObjectiveGoalUpdate(entityId, objectiveId, goal.goalId, {
                        block_id: blockId,
                        value: goal.value,
                        starts_at: startsAt.toISOString(),
                        ends_at: endsAt.toISOString(),
                    })
                )
            }

            await Promise.all(promises)
            void dispatch(setViewMode("default"))
            void dispatch(objectivesViewAction(objectiveId))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const goalsSaveAction = createAppAsyncThunk<Promise<void>>(
    "goals/save",
    async (_, { dispatch, getState }) => {
        const state = getState()
        const entityId = state.entity.active
        const objective = state.goals.viewing
        const objectiveId = objective?.id
        if (!objectiveId) {
            throw new Error("An objective must be selected before saving the goal")
        }

        const entity = state.entity.entities[entityId]
        const goals: GoalMap = cloneDeep(state.goals.goals)
        const originalGoals = state.goals.goalsOriginal
        void dispatch(setLoading(true))
        try {
            const endGoalRangeKey = rangeToTimeKey(objectiveEndRange(objective, entity))

            const promises: Promise<AxiosResponse>[] = []
            for (const blockId in goals) {
                for (const timeKey in goals[blockId]) {
                    if (timeKey === endGoalRangeKey) continue

                    const goal = goals[blockId][timeKey]

                    const { startsAt, endsAt } = timeKeyToTimeRange(timeKey)
                    if (!goal.goalId) {
                        promises.push(
                            callEntityObjectiveGoalsStore(entityId, objectiveId, {
                                block_id: blockId,
                                value: goal.value,
                                starts_at: startsAt.toISOString(),
                                ends_at: endsAt.toISOString(),
                            })
                        )
                        continue
                    }

                    const originalGoal = originalGoals[blockId][timeKey]
                    if (goal.value ===  originalGoal?.value) continue
                    promises.push(
                        callEntityObjectiveGoalUpdate(entityId, objectiveId, goal.goalId, {
                            block_id: blockId,
                            value: goal.value,
                            starts_at: startsAt.toISOString(),
                            ends_at: endsAt.toISOString(),
                        })
                    )
                }
            }

            await Promise.all(promises)
            void dispatch(setViewMode("default"))
            void dispatch(objectivesViewAction(objectiveId))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

// export const goalsGetOeeAction = createAppAsyncThunk<Promise<void>, GoalsGetOeePayload>(
//     "goals/getOee",
//     async (payload, {
//         dispatch,
//         getState,
//     }) => {
//         const state = getState()
//         const entityId = state.entity.active
//         const objective = state.goals.viewing
//         if (!objective) {
//             throw new Error("An objective must be selected before retrieving the OEE")
//         }

//         const oee: OeeMap = cloneDeep(state.goals.oee)
//         try {
//             const { data } = await callEntityBlockOee(entityId, payload.blockId, {
//                 res_x: 1,
//                 res_period: objective.unitOfTime,
//                 date_range: JSON.stringify({
//                     lower: payload.startsAt.toISOString(),
//                     upper: payload.endsAt.toISOString(),
//                 }),
//                 sku_oee: true,
//             })

//             if (!oee[payload.blockId]) {
//                 oee[payload.blockId] = {}
//             }

//             oee[payload.blockId][rangeToTimeKey(payload)] = data.overall.final_effective
//             void dispatch(oeeUpdate(oee))
//         } catch (e: any) {
//             void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
//         }
//     })

// interface GoalsGetOeePayload {
//     blockId: number
//     startsAt: moment.Moment
//     endsAt: moment.Moment
// }

export const goalsFetchEndGoalDataAction = createAppAsyncThunk<Promise<moment.Moment[] | void>>(
    "goals/fetchEndGoalData",
    async (_, { dispatch, getState, signal }) => {
        const state = getState()
        const entityId = state.entity.active
        const objective = state.goals.viewing
        const { blocks: blocksResource } = state.blocks
        if (!objective) {
            throw new Error("An objective must be selected before retrieving the OEE")
        }

        const entity = state.entity.entities[entityId]
        const ranges: TimeRange[] = objectiveEndTimeFrame(objective, entity)

        const now = moment()
        const blocks = flattenTree(blocksResource[objective.blockId])
        const oee: OeeMap = cloneDeep(state.goals.oee)
        void dispatch(setLoading(true))
        try {
            for (const block of blocks) {
                for (const range of ranges) {
                    if (range.startsAt.isAfter(now)) {
                        continue
                    }

                    const timeKey = rangeToTimeKey(range)
                    const endsAt = range.endsAt.isAfter(now) ? now.toISOString() : range.endsAt.toISOString()
                    callEntityBlockOee(entityId, block.block_id, {
                        resolution: 1,
                        period: objective.unitOfTime,
                        starts_at: range.startsAt.toISOString(),
                        ends_at: endsAt,
                        is_using_sku_oee: true,
                    }, { signal }).then(({ data }) => {
                        if (!oee[block.block_id]) {
                            oee[block.block_id] = {}
                        }

                        oee[block.block_id][timeKey] = data[0].value.overall.final_effective
                        void dispatch(oeeUpdate(cloneDeep(oee)))
                    }).catch(e => {
                        if (e instanceof CanceledError) {
                            return
                        }

                        void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
                    })
                }
            }
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

export const goalsFetchOeeAction = createAppAsyncThunk<Promise<moment.Moment[] | void>, ObjectiveModel>(
    "goals/fetchProgressiveGoalData",
    async (objective, { dispatch, getState, signal }) => {
        const state = getState()
        const entityId = state.entity.active
        const { blocks: blocksResource } = state.blocks
        if (!objective) {
            throw new Error("An objective must be selected before retrieving the OEE")
        }

        const entity = state.entity.entities[entityId]
        const ranges: TimeRange[] = objectiveDuration(objective, entity)

        const blocks = flattenTree(blocksResource[objective.blockId])
        const oee: OeeMap = cloneDeep(state.goals.oee)

        const now = moment()
        void dispatch(setLoading(true))
        try {
            for (const block of blocks) {
                for (const range of ranges) {
                    if (range.startsAt.isAfter(now)) {
                        continue
                    }

                    const endsAt = range.endsAt.isAfter(now) ? now.toISOString() : range.endsAt.toISOString()
                    const timeKey = rangeToTimeKey(range)
                    callEntityBlockOee(entityId, block.block_id, {
                        resolution: 1,
                        period: objective.unitOfTime,
                        starts_at: range.startsAt.toISOString(),
                        ends_at: endsAt,
                        is_using_sku_oee: true,
                    }, { signal }).then(({ data }) => {
                        if (!oee[block.block_id]) {
                            oee[block.block_id] = {}
                        }

                        oee[block.block_id][timeKey] = data[0].value.overall.final_effective
                        void dispatch(oeeUpdate(cloneDeep(oee)))
                    }).catch(e => {
                        if (e instanceof CanceledError) {
                            return
                        }

                        void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
                    })
                }
            }
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            void dispatch(setLoading(false))
        }
    })

// const flattenBlocks = (block: any, blocks: any[] = []): any[] => {
//     blocks.push(block)
//     if (!block.children || block.children.length === 0) {
//         return blocks
//     }

//     for (const child of block.children) {
//         flattenBlocks(child, blocks)
//     }

//     return blocks
// }

export const objectiveEndRange = (objective: ObjectiveModel, entity: any) => {
    const ranges: TimeRange[] = objectiveDuration(objective, entity)
    return ranges[ranges.length - 1]
}

export const objectiveEndTimeFrame = (objective: ObjectiveModel, entity: any): TimeRange[] => {
    const unitOfTime = objective.unitOfTime as moment.unitOfTime.Base
    const now = moment()
    const ranges: TimeRange[] = []
    const jumpOffPoint = toTimeRange(moment(objective.startsAt).subtract(1, unitOfTime), unitOfTime, entity)
    ranges.push(jumpOffPoint)

    const reference = now.clone().subtract(3, unitOfTime)
    for (let i = 0; i < 3; i++) {
        if (!reference.isAfter(jumpOffPoint.endsAt)) {
            reference.add(1, unitOfTime)
            continue
        }

        ranges.push(toTimeRange(reference, unitOfTime, entity))
        reference.add(1, unitOfTime)
    }

    ranges.push(toTimeRange(now, unitOfTime, entity))
    return ranges
}

export const objectiveDuration = (objective: ObjectiveModel, entity: any): TimeRange[] => {
    const unitOfTime = objective.unitOfTime as moment.unitOfTime.Base
    const ranges: TimeRange[] = []
    const reference: moment.Moment = moment(objective.startsAt).clone()
    const endsAt: moment.Moment = moment(objective.endsAt)
    while (!reference.isAfter(endsAt)) {
        ranges.push(toTimeRange(reference, unitOfTime, entity))
        reference.add(1, unitOfTime)
    }

    return ranges
}

export const toTimeRange = (date: moment.Moment, unitOfTime: moment.unitOfTime.Base, entity: any): TimeRange => {
    return {
        startsAt: timeSetWorkStartsAtAction(date.clone().startOf(unitOfTime), entity),
        endsAt: timeSetWorkStartsAtAction(date.clone().endOf(unitOfTime), entity),
    }
}

export const rangeToTimeKey = (range: TimeRange): TimeKey => {
    return `${range.startsAt.valueOf()}|${range.endsAt.valueOf()}`
}

export const toTimeKey = (date: moment.Moment, unitOfTime: moment.unitOfTime.Base, entity: any): TimeKey => {
    return rangeToTimeKey(toTimeRange(date, unitOfTime, entity))
}

export const timeKeyToTimeRange = (key: TimeKey): TimeRange => {
    const [startsAt, endsAt] = key.split("|")
    return {
        startsAt: moment(+startsAt),
        endsAt: moment(+endsAt),
    }
}

/**
 * @author      linde.lee@auk.industries
 * @date        02 Feb 2024
 * startsAt = starting time in milliseconds since epoch
 * endsAt = ending time in milliseconds since epoch
 * DateRange = `${startsAt.valueOf()}|{$endsAt.valueOf()}`
 */
export type TimeKey = string
export type TimeRange = { startsAt: moment.Moment, endsAt: moment.Moment }
