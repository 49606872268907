export const ko = {
    notes: '메모',
    load: '가져옴',
    export: '수출',
    unutilised: '유엔 이용',
    breakdowns: '고장',
    threshold: '문지방',
    oeepreview: 'OEE 미리보기',
    heatmappreview: '히트 맵 미리보기',
    oeecolors: 'OEE 색상',
    color: '색깔',
    reset: '초기화',
    theme: '테마',
    defaultview: '기본보기',
    defaulttimeselection: '기본 시간 선택',
    zoomlevel: '줌 레벨',
    entity: '실재',
    preferences: '환경 설정',
    custom: '커스텀',
    enteryourmailtoreset: '다시 메일을 입력',
    goback: '이동 다시',
    resetpassword: '암호를 재설정',
    logout: '로그 아웃',
    ownersemail: '소유자 이메일',
    optional: '선택 과목',
    industry: '산업',
    timezone: '시간대',
    nocoderequesttojoin: '아니 코드 가입을 요청',
    enterreferralcodeinstead: '대신 추천 코드를 입력',
    setup: '설정',
    company: '회사',
    referralcode: '추천 코드',
    submit: '제출',
    summary: '요약',
    language: '언어',
    login: '로그인',
    signin: '로그인',
    signup: '가입하기',
    username: '사용자 이름',
    password: '암호',
    forgotpassword: '비밀번호를 잊으 셨나요?',
    rememberme: '날 기억해',
    register: '레지스터',
    registration: '기재',
    firstname: '이름',
    lastname: '성',
    email: '이메일',
    mobile: '변하기 쉬운',
    country: '국가',
    contactnumber: '연락 번호',
    appointment: '약속',
    role: '역할',
    admin: '관리자',
    owner: '소유자',
    editor: '편집자',
    viewer: '뷰어',
    confirmpassword: '비밀번호 확인',
    iacceptthe: '나는을 받아',
    useragreement: '사용자 계약',
    alreadyregisteredlogin: '이미 등록? 로그인',
    softwareasaservicetermsofuse: '사용의 소프트웨어 것과 같이 서비스 이용 약관',
    dashboard: '계기반',
    streaming: '스트리밍',
    resolution: '해결',
    seconds: '초',
    second: '둘째',
    minutes: '의사록',
    minute: '분',
    hours: '시간',
    hour: '시',
    weeks: '주',
    week: '주',
    months: '달',
    month: '달',
    quarters: '병사',
    quarter: '쿼터',
    years: '연령',
    year: '년',
    from: '에서',
    to: '에',
    start: '스타트',
    end: '종료',
    display: '디스플레이',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: '시계열',
    gateway: '게이트웨이',
    statusasof: '현재 상태',
    active: '유효한',
    offline: '오프라인',
    mergeasonemachine: '하나 개의 시스템으로 병합',
    drawarrowstoformprocessmap: '양식 과정지도에 화살표를 그립니다',
    openblockeditor: '열기 블록 편집기',
    createnewtile: '새 타일 만들기',
    summarytile: '요약 타일',
    selectablockthennameyoursummarytile: '블록을 선택한 다음 요약 타일의 이름을',
    name: '이름',
    blockselector: '블록 선택',
    asset: '유산',
    therearenolabelstaggedinthisperiod: '이 기간에 태그에는 라벨이 없습니다',
    standardtimes: '표준 시간 (들)',
    total: '합계',
    last: '마지막',
    oeeinput: 'OEE 입력',
    chartcolor: '차트 색상',
    issueeditor: '문제 편집기',
    issuemangement: '문제의 Mangement',
    cumulativeoutput: '누적 출력',
    tag: '꼬리표',
    label: '상표',
    valueval: '값 (발)',
    time: '시각',
    januaryjan: '1월 (월)',
    februaryfeb: '2월 (2월)',
    marchmar: '월 (월)',
    aprilapr: '4월 APR ()',
    may: '할 수있다',
    junejun: '6월 (6월)',
    julyjul: '7월 (7월)',
    augustaug: '8월 (8월)',
    septembersep: '9월 SEP ()',
    octoberoct: '10월 OCT ()',
    novembernov: '11월 (11월)',
    decemberdec: '12월 (10 진수)',
    day: '일',
    days: '일',
    mondaymonm: '월요일 (월) M',
    tuesdaytuet: '화요일 (화) T',
    wednesdaywedw: '수요일 (수) W',
    thursdaythut: '목요일 (목) T',
    fridayfrif: '금요일 (금) F',
    saturdaysats: '토요일 (토) S',
    sundaysuns: '일요일 (일) S',
    edit: '편집하다',
    add: '더하다',
    new: '새로운',
    create: '창조하다',
    search: '검색',
    close: '닫기',
    cancel: '취소',
    save: '저장',
    saved: '저장',
    clear: '명확한',
    enter: '시작하다',
    navigator: '항해자',
    mandatoryfields: '필수 필드',
    select: '고르다',
    merge: '병합',
    delete: '지우다',
    done: '끝난',
    filter: '필터',
    table: '표',
    columns: '열',
    rows: '행',
    cell: '세포',
    page: '페이지',
    previous: '이전',
    next: '다음',
    serialnumber: '일련 번호',
    manuallyinputserialnumber: '수동으로 입력 일련 번호',
    updateasset: '업데이트 자산',
    machine: '기계',
    brand: '상표',
    model: '모델',
    nodes: '노드',
    fusions: '융합',
    chartorder: '차트 주문',
    primary: '일 순위',
    adddevicechannel: '장치 추가 - 채널을',
    pushratesec: '속도 (초)를 밀어',
    voltagev: '전압 (V)',
    sensor: '감지기',
    mode: '양식',
    digitalcount: '디지털 카운트',
    digitalstate: '디지털 주',
    analogvoltage: '아날로그 전압',
    charttitle: '차트 제목',
    unitmeasure: '단위 측정',
    attribute: '속성',
    autocycletime: '자동 사이클 타임',
    unittransform: '단위 변환',
    leaveblankifnotapplicable: '휴가 공백으로 적용 할 수없는 경우',
    mandatoryfieldsleaveblankifchannelnotinuse:
    '필수 필드 (휴가의 빈 채널을 사용하지 않을 경우)',
    fusedchart: '융합 차트',
    fusionname: '퓨전 이름',
    charttype: '차트 종류',
    column: '기둥',
    line: '선',
    state: '상태',
    expression: '표현',
    combination: '콤비네이션',
    currentcharts: '현재 차트',
    expressionconstructor: '표현의 생성자',
    validoperators: '유효한 사업자',
    leaveblanktokeepexistingexpression: '기존의 표현을 유지하기 위해 비워 둡니다',
    oee: 'OEE',
    overallequipmenteffectiveness: '전체 장비 효율성',
    legend: '전설',
    loading: '로드',
    availability: '유효성',
    performance: '공연',
    quality: '품질',
    unscheduled: '예약되지 않은',
    planneddowntime: '계획된 다운 타임',
    changeoversetup: '전환 / 설정',
    breakdown: '고장',
    unutilized: '유엔 이용',
    speedloss: '속도 감소',
    minorstops: '마이너 중지',
    rework: '재 작업',
    rejectscrap: '거부 / 스크랩',
    nodata: '데이터가 없습니다',
    blockeditor: '블록 편집기',
    sort: '종류',
    logicblocks: '로직 블록',
    sequence: '순서',
    and: '과',
    or: '또는',
    utilizationheatmap: '이용의 히트 맵',
    startdate: '시작 날짜',
    enddate: '종료 날짜',
    daily: '매일',
    weekly: '주간',
    monthly: '월간 간행물',
    quarterly: '계간지',
    yearly: '매년',
    trendanalysis: '유행 분석',
    metricselection: '메트릭 선택',
    metric: '미터법',
    data: '데이터',
    target: '표적',
    periodselection: '기간 선택',
    span: '스팬',
    every: '마다',
    repeat: '반복',
    repeats: '반복',
    addsingle: '싱글 추가',
    paretoanalysis: '파레토 분석',
    calendartime: '일정 시간',
    scheduledtime: '예정된 시간',
    operatingtime: '운영 시간',
    netruntime: '닷넷 런타임',
    effective: '유효한',
    loadingloss: '로드 손실',
    availabilityloss: '가용성 손실',
    performanceloss: '성능 손실',
    qualityloss: '품질 손실',
    notagsinthiscategory: '이 카테고리의 태그가 없습니다',
    overall: '사무용 겉옷',
    tagged: '태그',
    occurrence: '발생',
    skumanagement: 'SKU 관리',
    category: '범주',
    skuname: 'SKU 이름',
    skucode: 'SKU 코드',
    unitofmeasurementuom: '측정 단위 (UOM)',
    description: '기술',
    csvupload: 'CSV 업로드',
    template: '주형',
    notifications: '알림',
    newnotification: '새로운 알림',
    monitoring: '모니터링',
    topic: '이야기',
    frequency: '회수',
    recipients: '받는 사람',
    notifyby: '에 의한 통지',
    sms: 'SMS',
    alert: '경보',
    report: '보고서',
    greaterthan: '보다 큰',
    lessthan: '이하',
    greaterthanorequal: '보다 크거나 같은',
    lessthanorequal: '보다 작거나 같음',
    equal: '같은',
    range: '범위',
    continuousduration: '연속 재생 시간',
    usermanagement: '사용자 관리',
    user: '사용자',
    users: '사용자',
    invitenew: '새로운 초대',
    status: '상태',
    resend: '다시 보내기',
    revoke: '취소',
    invite: '초대',
    invitees: '초대',
    addinviteesbypressingenterorclickingonthesign:
    "Enter 키를 눌러 또는 '+'기호를 클릭하여 초대를 추가",
    schedules: '일정',
    shift: '시프트',
    profile: '프로필',
    changepassword: '암호 변경',
    deleteaccount: '계정 삭제',
    userguide: '사용자 설명서',
    overviewandbasiccontrol: '개요 및 기본 제어',
    rangeandresolution: '범위 및 해상도',
    editingoptions: '옵션을 편집',
    mergetiles: '병합 타일',
    detailedassetview: '자세한 자산보기',
    assetoverview: '자산 개요',
    summarystatistics: '요약 통계',
    dataanalysis: '데이터 분석',
    oeeanoverview: 'OEE 개요',
    oeetoggle: 'OEE 토글',
    addingamachine: '기계를 추가',
    nodeconfiguration: '노드 구성',
    deleteupdateassets: '삭제 업데이트 자산',
    oeesummarytile: 'OEE 요약 타일',
    oeetags: 'OEE 태그',
    issuemanagement: '문제 관리',
    labelsandfiltering: '라벨 및 필터링',
    useraccess: '사용자 액세스',
    group: '그룹',
    groups: '여러 떼',
    joincompany: '회사를 가입',
    notification: '공고',
    createnotification: '알림 만들기',
    node: '마디',
    downtime: '중단 시간',
    downtimes: '다운 타임',
    repeatstartdate: '시작 날짜를 반복',
    repeatenddate: '반복 종료 날짜',
    optional: '선택 과목',
    createplan: '계획 만들기',
    today: '오늘',
    starttime: '시작 시간',
    endtime: '종료 시간',
    chart: '차트',
    reject: '받지 않다',
    accept: '동의하기',
    filterbydaterange: '기간별 필터',
    filterbymostrecent: '가장 최근에 의해 필터',
    result: '결과',
    type: '유형',
    duration: '지속',
    by: '으로',
    title: '표제',
    assigned: '할당',
    action: '동작',
    track: '과정',
    issue: '발행물',
    priority: '우선 순위',
    requestor: '요청',
    opened: '오픈',
    assignee: '양수인',
    root: '뿌리',
    cause: '원인',
    identified: '확인 된',
    defined: '한정된',
    date: '데이트',
    resolve: '결의',
    resolved: '해결',
    targetresolvedate: '목표 해결 날짜',
    procurement: '획득',
    choose: '고르다',
    analyses: '복수',
    source: '출처',
    selection: '선택',
    historical: '역사적인',
    mostrecent: '가장 최근',
    regression: '회귀',
    trendline: '추세선',
    upperbound: '상한',
    lowerbound: '하한',
    preview: '시사',
    period: '기간',
    series: '시리즈',
    axis: '중심선',
    youhaveno: '넌 ~가 없다',
    code: '암호',
    all: '모두',
    upload: '업로드',
    regressionanalysis: '회귀 분석',
    issuetracker: '이슈 트래커',
    labelsearch: '라벨 검색',
    gatewaysetup: '게이트웨이 설정',
    setupgateway: '설정 게이트웨이',
    radiomesh: '무선 메쉬',
    targetsetting: '목표 설정',
    updatetrackedissue: '갱신 추적 문제',
    shownewtrackedissue: '새 추적 된 문제를 보여',
    low: '낮은',
    mid: '중앙의',
    high: '높은',
    linear: '선의',
    exponential: '지수의',
    logarithmic: '로그',
    quadratic: '차',
    polynomial: '다항식',
    general: '일반',
    datataggingenrichment: '데이터 태그 농축',
    hardware: '하드웨어',
    nodemanagement: '노드 관리',
    createeditnotification: '편집 알림 만들기',
    conditions: '정황',
    addsku: 'SKU를 추가',
    editsku: '편집 SKU',
    setupagateway: '설치 a를 게이트웨이',
    deviceserialnumber: '장치 시리얼 번호',
    repeatsweekly: '매주 반복',
    repeatsdaily: '매일 반복',
    repeatsmonthly: '매월 반복',
    repeatsannually: '매년 반복',
    repeatsevery: '반복 모든',
    hourly: '시간당',
    biweekly: '격주 간행물',
};
