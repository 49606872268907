import { updateStoreRedux } from '../../../legacy/utils/redux';
import { MetadataConstants as K } from './Metadata.constants';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    metadata: {},
});

export const metadataReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_METADATA_RESOURCE) {
        return updateStoreRedux(state, {
            metadata: action.payload.metadata,
        });
    }

    if (action.type === K.ACTIONS.ADD_METADATA_RESOURCE) {
        return updateStoreRedux(state, {
            metadata: { ...state.metadata, ...action.payload.metadata },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_METADATA_RESOURCE) {
        const deleteSet = new Set(action.payload.metadata_ids);
        const deletedState = pickBy(
            { ...state.metadata },
            ({ metadata_id }) => !deleteSet.has(metadata_id)
        );

        return updateStoreRedux(state, {
            metadata: deletedState,
        });
    }

    if (action.type === K.ACTIONS.UPDATE_METADATUM_REQUEST_SUCCESS) {
        return updateStoreRedux(state, {
            metadata: {
                ...state.metadata,
                [action.payload.metadata_id]: action.payload,
            },
        });
    }

    if (action.type === K.ACTIONS.RESET_METADATA_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
