/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';
import { isEmpty } from 'lodash';

// COMPONENTS
import FormModal from '../../components/FormModal';
import LabelWrapper from '../../components/LabelWrapper';
import { flash, generateValidationErrorDetails } from '../../components/Flash';

// HELPERS
import {
    getFormErrors,
    isRequiredField,
    validateSingleField,
} from '../../utils/validate';
import translate from '../../utils/translate';

const SKU_FIELDS = {
    NAME: 'name',
    CATEGORY: 'category',
    CODE: 'code',
    DESCRIPTION: 'description',
    UOM: 'uom',
};

const SKU_VALIDATE = {
    [SKU_FIELDS.NAME]: [
        {
            err: 'Name is required.',
            assessor: (v) => isRequiredField(v.name),
        },
    ],
    [SKU_FIELDS.CODE]: [
        {
            err: 'Code is required.',
            assessor: (v) => isRequiredField(v.code),
        },
    ],
    [SKU_FIELDS.UOM]: [
        {
            err: 'UOM is required.',
            assessor: (v) => isRequiredField(v.uom),
        },
    ],
    [SKU_FIELDS.CATEGORY]: [],
    [SKU_FIELDS.DESCRIPTION]: [],
};

const getStateFromProps = ({
    name = '',
    code = '',
    uom = '',
    category = '',
    description = '',
}) => {
    return {
        name,
        code,
        uom,
        category,
        description,
    };
};

export default class SKUFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.isEdit = props.data && props.data.sku_id;

        this.state = this.isEdit
            ? getStateFromProps(props.data)
            : {
                name: '',
                category: '',
                code: '',
                description: '',
                uom: '',
            };
    }

    handleChange(field, value) {
        this.setState({ [field]: value });
    }

    handleSubmit(formData) {
        const errors = this.formErrors(formData);

        if (!isEmpty(errors)) return this.invalidErrorFlash(errors);

        this.props.handleSubmit(formData);
    }

    get formData() {
        const { name, category, description, uom, code } = this.state;
        return { name, category, code, description, uom };
    }

    formErrors(formData) {
        return getFormErrors(formData, SKU_VALIDATE);
    }

    invalidErrorFlash(errors) {
        flash({
            message: 'Please check invalid / incomplete fields',
            details: generateValidationErrorDetails(errors),
            status: 'warning',
        });
    }

    get nameValid() {
        const { formData } = this;
        return !validateSingleField(SKU_VALIDATE, SKU_FIELDS.NAME, formData).length;
    }

    get codeValid() {
        const { formData } = this;
        return !validateSingleField(SKU_VALIDATE, SKU_FIELDS.CODE, formData).length;
    }

    get uomValid() {
        const { formData } = this;
        return !validateSingleField(SKU_VALIDATE, SKU_FIELDS.UOM, formData).length;
    }

    get categoryValid() {
        const { formData } = this;
        return !validateSingleField(SKU_VALIDATE, SKU_FIELDS.CATEGORY, formData)
            .length;
    }

    get descriptionValid() {
        const { formData } = this;
        return !validateSingleField(SKU_VALIDATE, SKU_FIELDS.DESCRIPTION, formData)
            .length;
    }

    render() {
        const { name, category, description, uom, code } = this.state;
        const { nameValid, codeValid, uomValid, categoryValid, descriptionValid } =
      this;

        const formProps = {
            name,
            category,
            description,
            uom,
            code,
            nameValid,
            codeValid,
            uomValid,
            categoryValid,
            descriptionValid,
            handleChange: this.handleChange.bind(this),
        };

        return (
            <FormModal
                show={true}
                title={this.isEdit ? 'Edit SKU' : 'Create SKU'}
                toggle={this.props.toggle}
                submit={() => this.handleSubmit(this.formData)}
            >
                <SKUForm {...formProps} />
            </FormModal>
        );
    }
}

const SKUForm = ({
    name,
    category,
    code,
    description,
    uom,
    nameValid,
    categoryValid,
    codeValid,
    descriptionValid,
    uomValid,
    submitted,
    handleChange,
}) => {
    const inputFields = [
        {
            key: 'skuname',
            label: 'SKU Name',
            value: name,
            isInvalid: !nameValid,
            onChange: (e) => handleChange(SKU_FIELDS.NAME, e.target.value),
            required: true,
            maxLength: 255,
        },
        {
            key: 'skucode',
            label: 'SKU Code',
            value: code,
            isInvalid: !codeValid,
            onChange: (e) => handleChange(SKU_FIELDS.CODE, e.target.value),
            required: true,
            maxLength: 20,
        },
        {
            key: 'category',
            label: 'Category',
            value: category,
            isInvalid: !categoryValid,
            onChange: (e) => handleChange(SKU_FIELDS.CATEGORY, e.target.value),
            maxLength: 255,
        },
        {
            key: 'unitofmeasurementuom',
            label: 'Unit of Measurement',
            value: uom,
            isInvalid: !uomValid,
            onChange: (e) => handleChange(SKU_FIELDS.UOM, e.target.value),
            required: true,
            maxLength: 20,
        },
        {
            key: 'description',
            label: 'Description',
            value: description,
            isInvalid: !descriptionValid,
            onChange: (e) => handleChange(SKU_FIELDS.DESCRIPTION, e.target.value),
            maxLength: 255,
        },
    ];

    return (
        <form>
            {inputFields.map((f, i) => {
                const label = (
                    <>
                        {f.required ? '*' : ''} {translate(f.key)}
                    </>
                );
                return (
                    <LabelWrapper key={i} label={label}>
                        <Input
                            className={`auk-input ${
                                !submitted ? '' : f.isInvalid ? 'is-invalid' : ''
                            }`}
                            maxLength={f.maxLength}
                            value={f.value}
                            onChange={f.onChange}
                        />
                    </LabelWrapper>
                );
            })}
        </form>
    );
};
