import { SkuChartsConstants as K } from './SkuCharts.constant';

export const fetchSkuCharts = (entity_id, query = {}, callback) => {
    return {
        type: K.ACTIONS.FETCH_SKU_CHARTS,
        payload: { entity_id, query },
        callback,
    };
};

export const fetchAssetSkuCharts = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_SKU_CHARTS,
        payload: { entity_id, asset_id },
        callback,
    };
}

export const createSkuChart = (entity_id, asset_id, chart_id, sku_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_SKU_CHART,
        payload: { entity_id, asset_id, chart_id, sku_id, data },
        callback,
    };
}

export const updateSkuChart = (entity_id, asset_id, chart_id, sku_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_SKU_CHART,
        payload: { entity_id, asset_id, chart_id, sku_id, data },
        callback,
    };
}

export const bulkCreateUpdateSkuCharts = (entity_id, data, callback) => {
    return {
        type: K.ACTIONS.BULK_CREATE_UPDATE_SKU_CHARTS,
        payload: { entity_id, data },
        callback,
    };
}

export const resetSkuChartsState = () => {
    return {
        type: K.ACTIONS.RESET_SKU_CHARTS_STATE,
    };
};

export const setSkuChartsState = (skuCharts) => {
    return {
        type: K.ACTIONS.SET_SKU_CHARTS_STATE,
        payload: { skuCharts },
    };
};

export const setSkuChartsResource = (skuCharts) => {
    return {
        type: K.ACTIONS.SET_SKU_CHARTS_RESOURCE,
        payload: { skuCharts },
    };
};

export const addSkuChartsResource = (skuCharts) => {
    return {
        type: K.ACTIONS.ADD_SKU_CHARTS_RESOURCE,
        payload: { skuCharts },
    };
};
