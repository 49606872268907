import "./style.scss"
import { GridColDef, GridTreeNode, useGridApiContext } from "@mui/x-data-grid"
import { GridAlignment } from "@mui/x-data-grid/models/colDef/gridColDef"
import React, { CSSProperties } from "react"
import Grid from "../../../Grid/Grid"
import TreeTableCell from "../TreeTableCell/TreeTableCell"

const TreeTableRow = <T extends Row, >(props: Props<T>) => {
    const ref = useGridApiContext()
    const row = props.rows[props.index]
    if (!row) {
        return null
    }

    return <Grid container item style={props.style} className="tree-table-row">
        {props.columns.map(column => {
            const value = row[column.field]
            const formattedValue = column.valueFormatter
                ? column.valueFormatter({
                    api: ref.current,
                    id: row.id,
                    field: column.field,
                    value: row[column.field],
                })
                : undefined

            if (column.renderCell) {
                return column.renderCell({
                    api: ref.current,
                    cellMode: "view",
                    colDef: { ...column, computedWidth: 0 },
                    field: column.field,
                    formattedValue,
                    hasFocus: false,
                    id: row.id,
                    row: undefined,
                    rowNode: {
                        ...row,
                        isAutoGenerated: false,
                        type: "pinnedRow",
                        parent: row.id,
                    },
                    tabIndex: -1,
                    value,

                })
            }

            return <TreeTableCell
                key={`${column.field}${row.id}`}
                height={props.style?.height}
                width={column.width}
                style={{ justifyContent: getCellAlignment(column.align) }}
            >
                {formattedValue ?? value}
            </TreeTableCell>
        })}
    </Grid>
}

const getCellAlignment = (align?: GridAlignment): string | undefined => {
    if (align === "left") {
        return "flex-start"
    }

    if (align === "right") {
        return "flex-end"
    }

    return align
}

interface Props<T> {
    style?: CSSProperties
    columns: GridColDef[]
    index: number
    rows: T[]
    display?: [number, number?]
    isScrolling: boolean
}

export type Row = GridTreeNode & { id: string, [key: string]: any }

export default TreeTableRow
