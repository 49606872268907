import { makeRequest } from '../Shared/Shared.action';

// MODEL
import { Label } from '../../../legacy/models';

// REDUX STORE
import { store } from '../..';


import { currentEntitySelector } from '../Entity/Entity.selector';
import { queryString, queryStringData } from '../../../legacy/utils/service';

export const api_deleteLabel = (asset_id, label_id) => {
    const {
        entity: { active: entity_id },
    } = store.getState();

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/label/${label_id}`,
            method: 'DELETE',
            params: JSON.stringify({}),
            success: resolve,
            error: reject,
        });
    });
};

export const api_createLabel = (asset_id, data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/new_label`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_updateLabel = (asset_id, data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();
    const { label_id } = data;

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/new_label/${label_id}/`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_stopLabel = (asset_id, data) => {
    const {
        entity: { active: entity_id },
    } = store.getState();

    const { label_id } = data;

    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/entity/${entity_id}/asset/${asset_id}/new_label/${label_id}/stop`,
            method: 'PUT',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

export const api_getAssetLabels = (asset_id, queryParams) => {
    const { entity_id } = currentEntitySelector(store.getState());
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/label${queryStringData(queryParams)}`,
                method: 'GET',
                monitor: true,
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getAssetsLabels = (entity_id, assets, queryParams) => {
    const query = queryStringData({ ...queryParams, assets });
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/combined_label${query}`,
                method: 'GET',
                monitor: true,
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getEntityLabels = (query) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/new_label${queryString(query)}`,
                method: 'GET',
                monitor: true,
                success: resolve,
                error: reject,
            });
        }
    );
};
