/* eslint-disable react/prop-types */
import React from 'react';
class GridCell extends React.Component {
    render() {
        const { handleClick, editing, hovered } = this.props;

        const hoveredStyle = {
            opacity: 0.6,
            background: 'steelblue',
        };

        return (
            <div
                className="dashboard__grid-cell"
                onClick={() => editing && handleClick()}
                style={hovered ? hoveredStyle : {}}
            >
                {editing && (
                    <div className="dashboard-cell-wrapper">
                        <i
                            className="fas fa-plus fa-7x"
                            style={{ color: 'green', opacity: 0.3 }}
                        />
                        <p>ADD DEVICE</p>
                        <p>OR</p>
                        <p>SUMMARY TILE</p>
                    </div>
                )}
            </div>
        );
    }
}

export default GridCell;
