/* eslint-disable react/prop-types */
import React from 'react';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { HolderOutlined } from '@ant-design/icons';

import './SortableComponent.scss';

const SortableItem = SortableElement(({ value, order }) => {
    return (
        <li className="order-select-single">
            <HolderOutlined style={{ position: 'absolute', left: 8 }} />
            <span>{value}</span>
        </li>
    );
});

const SortableList = SortableContainer(({ items, valueAccessor }) => {
    return (
        <ul className="order-select-all list-unstyled">
            {items.map((item, index) => (
                <SortableItem
                    key={index}
                    index={index}
                    value={valueAccessor(item)}
                    order={index + 1}
                />
            ))}
        </ul>
    );
});

export const SortableComponent = (props) => {
    return (
        <SortableList
            items={props.items}
            valueAccessor={props.valueAccessor}
            onSortEnd={({ oldIndex, newIndex }) => {
                const result = arrayMove(props.items, oldIndex, newIndex);
                props.onSortEnd({ result, oldIndex, newIndex });
            }}
        />
    );
};
