import { SxProps, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import PlainTextInputWithSubmit from "../PlainTextInputWithSubmit/PlainTextInputWithSubmit"
import { Box } from "@mui/system"

const EditableInput = (props: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>("")

    useEffect(() => {
        setInputValue(props.value)
    }, [props.value])
    
    return <Box id={props.id} className={props.className}>
        {
            isEditing ?
                <PlainTextInputWithSubmit
                    sx={{ width: "100%" }}
                    value={inputValue}
                    onChange={(value) => {
                        props.onChange && props.onChange(value)
                        setInputValue(value)
                    }}
                    onEscape={() => { 
                        props.onEscape && props.onEscape()
                        setIsEditing(false) 
                    }}
                    onSubmit={() => {
                        props.onSubmit && props.onSubmit(inputValue)
                        setIsEditing(false) 
                    }}
                /> : 
                <Box onClick={() => { setIsEditing(true) }} sx={{ width: "100%", cursor: "text" }}>
                    {props.placeholder ? props.placeholder(inputValue):  <Typography variant="h6">{inputValue}</Typography>}
                </Box>

        }
    </Box>
}

interface Props {
    placeholder?: (value: string) => React.ReactNode
    className?: string
    id?: string
    value: string
    onChange?: (value: string) => void
    onEscape?: () => void
    onSubmit?: (val?: string) => void
    sx?: SxProps
}

export default EditableInput
