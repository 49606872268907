import moment from "moment"
import { ReactNode } from "react"

export interface CalendarProps {
    className?: string
    actions?: {
        left?: ReactNode
        right?: ReactNode
    }
    start?: Time.DateValue
    end?: Time.DateValue
    referenceDate: moment.Moment
    onSelect: (value: moment.Moment) => void
    excludeDays?: number[]
    disablePast?: boolean
    disableFuture?: boolean
    selecting?: SelectingState
}

export type SelectingState = "start" | "end"

export enum DateRangePickerType {DAY, MONTH}
