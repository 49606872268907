/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React from 'react';
import * as d3 from 'd3';
import { AutoSizer } from 'react-virtualized';

import { findDOMNode } from 'react-dom';
import { isEqual } from 'lodash';

import { Colors } from '../Colors';

import './Charts_V1.scss';

const strokeWidth = '2px';
const format = (d = 0) => d3.format('.3')(d) + '%';

const maxValue = 100;

class DonutChartSimple extends React.Component {
    constructor(props) {
        super(props);

        const { duration } = this.props;
        this.t = d3.transition().ease(d3.easeLinear).duration(duration);
    }

    // calculate domains, ...
    recalculate() {
        const {
            radius,
            thickness,
            width,
            height,
            fontSize1,
            fontSize2,
            margin,
        } = this.props;

        this.w = width - margin.left - margin.right;
        this.h = height - margin.top - margin.bottom;

        this.dim = Math.min(this.w, this.h);

        this.radius = radius || Math.min(this.w, this.h) / 2;
        this.thickness = thickness || this.radius / 4;

        this.translate = {
            x: margin.left + this.dim / 2 + (width - this.dim) / 2,
            y: margin.top + this.dim / 2 + (height - this.dim) / 2,
        };

        const r = this.radius - this.thickness;

        this.arc = d3.arc().innerRadius(r).outerRadius(this.radius);

        this.pie = d3
            .pie()
            .value((d) => d.value)
            .sort(null);

        this.labels = {
            dy1: 0,
            fontSize1: fontSize1 || r / 2,
            dy2: (r * 3.5) / 8,
            fontSize2: fontSize2 || r / 3,
        };
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.svg = this.el.select('svg');
        this.g = this.svg.select('g');
        this.series = this.g.select('.pie');

        this.recalculate();
        this.prepareData();
        this.createChart();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps, this.props)) {
            this.recalculate();
            this.prepareData();
            this.redraw();
        }
    }

    prepareData() {
        const { data: _data } = this.props;
        this.data = [
            ..._data,
            {
                name: 'last',
                value:
          maxValue -
          d3.sum(_data, function (d) {
              return d.value;
          }),
            },
        ];

        const initValue = (this.datum && this.datum.initValue) || 0;
        this.datum = this.data[0];
        this.datum.initValue = initValue;
    }

    createChart() {
        const { data, datum, radius, thickness, pie, arc, w, h, labels } = this;
        const { duration } = this.props;

        this.series
            .append('path')
            .attr('class', 'placeholder')
            .attr('d', arc(pie([{ value: maxValue }])[0]))
            .style('fill', Colors.gray[3])
            .style('stroke', '#fff')
            .style('stroke-width', strokeWidth);

        this.series
            .selectAll('.slice')
            .data(pie(data).filter((d) => d.data.name !== 'last'))
            .enter()
            .append('path')
            .attr('class', 'slice')
            .each(
                (d) =>
                    (this._current = Object.assign({}, d, { endAngle: d.startAngle }))
            )
            .style('fill', (d) => d.data.color)
            .style('stroke', '#fff')
            .style('stroke-width', strokeWidth)
            .transition()
            .duration(duration)
            .attrTween('d', (d) => this.updateTween(d, arc));

        if (this.props.showIndicators) {
            this.series
                .append('text')
                .datum(datum)
                .attr('class', 'pie-label1')
                .text(format(datum.initValue))
                .attr('text-anchor', 'middle')
                .attr('dy', labels.dy1 + 'px')
                .style('font-size', labels.fontSize1 + 'px')
                .style('fill', datum.color)
                .style('font-weight', 'bold')
                .transition()
                .duration(duration)
                .on('start', this.repeat);

            this.series
                .append('text')
                .attr('class', 'pie-label2')
                .text(datum.name)
                .attr('text-anchor', 'middle')
                .attr('dy', labels.dy2 + 'px')
                .style('font-size', labels.fontSize2 + 'px')
                .style('fill', datum.color)
                .style('font-weight', 'bold');
        }
    }

    redraw() {
        const { data, datum, pie, arc, labels, dim, translate } = this;
        const { duration, width, height } = this.props;

        this.svg.attr('width', width).attr('height', height);

        this.g.attr('transform', `translate(${translate.x},${translate.y})`);

        this.series
            .select('.placeholder')
            .attr('d', arc(pie([{ value: maxValue }])[0]))
            .style('fill', Colors.gray[3])
            .style('stroke', '#fff')
            .style('stroke-width', strokeWidth);

        this.series
            .selectAll('.slice')
            .data(pie(data).filter((d) => d.data.name !== 'last'))
            .transition()
            .duration(duration)
            .style('fill', (d) => d.data.color)
            .attrTween('d', (d) => this.updateTween(d, arc));

        if (this.props.showIndicators) {
            this.series
                .select('.pie-label1')
                .datum(datum)
                .transition()
                .duration(duration)
                .on('start', this.repeat)
                .style('fill', datum.color)
                .attr('dy', labels.dy1 + 'px')
                .style('font-size', labels.fontSize1 + 'px');

            this.series
                .select('.pie-label2')
                .text(datum.name)
                .attr('dy', labels.dy2 + 'px')
                .style('font-size', labels.fontSize2 + 'px')
                .style('fill', datum.color);
        }
    }

    updateTween(d) {
        const { arc } = this;
        const i = d3.interpolate(this._current, d);
        this._current = i(0);
        return function (t) {
            return arc(i(t));
        };
    }

    repeat(d) {
        d3.active(this).tween('text', function () {
            const that = d3.select(this),
                i = d3.interpolate(d.initValue, d.value);
            return function (t) {
                d.initValue = i(t);
                that.text(format(d.initValue));
            };
        });
    }

    render() {
        const { className, style } = this.props;

        return (
            <div className={className} style={style}>
                <svg xmlns="http://www.w3.org/2000/svg" className="pie-chart">
                    <g>
                        <g className="pie" />
                    </g>
                </svg>
            </div>
        );
    }
}

DonutChartSimple.defaultProps = {
    width: 300,
    height: 300,
    maxValue: 100,
    duration: 0,
    showIndicators: true,
    margin: { left: 0, top: 0, right: 0, bottom: 0 },
};

export const DonutChart = (props) => {
    return (
        <AutoSizer>
            {({ width, height }) => (
                <DonutChartSimple {...props} width={width} height={height} />
            )}
        </AutoSizer>
    );
};
