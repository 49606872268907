/* eslint-disable react/prop-types */
import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';

const FusionExpressionEditor = (props) => {
    const { value, onChange, data } = props;

    return (
        <MentionsInput
            style={defaultStyle}
            value={value}
            onChange={(event, newValue, newPlainTextValue, mentions) => {
                onChange && onChange(newValue);
            }}
            placeholder="e.g. @Source1 + @Source2"
            maxLength={1000}
        >
            <Mention
                appendSpaceOnAdd
                data={data}
                style={defaultMentionStyle}
                markup="@{__id__>__display__}"
                trigger={'@'}
                renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                ) => (
                    <div className={`argument ${focused ? 'focused' : ''}`}>
                        {highlightedDisplay}
                    </div>
                )}
            />
        </MentionsInput>
    );
};

export default FusionExpressionEditor;

const defaultMentionStyle = { backgroundColor: '#cee4e5' };

const defaultStyle = {
    control: {
        backgroundColor: '#fff',
        border: 'red',

        fontSize: 12,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    operator: {
        border: '1px solid',
    },

    input: {
        margin: 0,
    },

    '&singleLine': {
        control: {
            display: 'inline-block',

            width: 130,
        },

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },

        input: {
            padding: 1,
            border: '2px inset',
        },
    },

    '&multiLine': {
        control: {
            borderRadius: 2,
            border: '1px solid #ccc',
        },

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0,
            color: '#616161',
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 10,
            maxHeight: 100,
            overflow: 'auto',
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
};
