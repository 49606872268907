import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';

// CONFIG
import { queryStringData } from '../../../legacy/utils/service';

// REDUX STORE
import { store } from '../..';

export const api_getEntityTargets = (query) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/target${queryStringData(query)}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_getAssetTargets = (asset_id, query) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/target${queryStringData(
                    query
                )}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createTarget = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/target`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_bulkCreateTargets = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/targets`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateTarget = (data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/target/${data.target_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteTarget = (target_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/target/${target_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createAssetShiftTarget = (entity_id, asset_id, shift_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/shift/${shift_id}/target`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateAssetShiftTarget = (entity_id, asset_id, shift_id, target_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/shift/${shift_id}/target/${target_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteAssetShiftTarget = (entity_id, asset_id, shift_id, target_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/shift/${shift_id}/target/${target_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};
