
import moment from 'moment';
import { assetsState } from '../../store/old/Assets/Assets.selector';
import { skuState } from '../../store/old/Sku/Sku.selector';
import { store } from '../../store';

export class Target {
    constructor(props) {
        const value = props.value || props.target;
        this.target_id = props.target_id;
        this.asset_id = props.asset_id;
        this.from = props.from ? new Date(props.from) : null;
        this.to = props.to ? new Date(props.to) : null;
        this.notes = props.notes;
        this.value = value;
        this.sku_id = props.sku_id;
    }

    get _to() {
    // expose similar getter as Label model (for charts)
        return this.to;
    }

    get asset() {
        const appState = store.getState();
        const assetsResource = assetsState(appState).assets;
        return assetsResource[this.asset_id];
    }

    get asset_name() {
        return this.asset ? this.asset.asset_name : '';
    }

    get label() {
        if (this.sku) {
            // return `[${this.sku.sku_code}] ${this.sku.sku_name}`;wap when new target response is done
            return `[${this.sku.code}] ${this.sku.name}`;
        }

        return 'Planned Production';
    }

    // remove when new target response is done
    get sku() {
        const appState = store.getState();
        const { skus: skusResource } = skuState(appState);
        return skusResource[this.sku_id];
    }

    duration(units = 'seconds', floating = true) {
        return moment(this.to).diff(moment(this.from), units, floating);
    }
}
