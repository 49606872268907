import { updateStoreRedux } from '../../../../legacy/utils/redux';

import { LoaderConstants as K } from './Loader.constants';

const initialState = {
    count: 0,
};

export const LoaderReducer = (
    state = initialState,
    action
) => {
    if (action.type === K.ACTIONS.START_LOADING) {
        return updateStoreRedux(state, {
            count: state.count + 1,
        });
    }

    if (action.type === K.ACTIONS.STOP_LOADING) {
        return updateStoreRedux(state, { count: state.count - 1 });
    }

    return updateStoreRedux(state, {});
};
