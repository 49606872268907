import React from 'react';
import * as d3 from 'd3-regression';

export const toExponential = (n, sf = 5) => {
    const str = n.toExponential(sf);
    const pow = +str.split('e')[1];
    return pow < -3 || pow > 3 ? `(${str})` : n.toFixed(sf);
};

const op = (nextTerm) => (nextTerm < 0 ? '-' : '+');
const mod = (n) => Math.abs(n);

export const regressionType = ({ model_parameters, model_name }) => {
    if (model_name === REGRESSION_TYPES.POLYNOMIAL.toLowerCase()) {
        switch (model_parameters.length) {
        case 2:
            return REGRESSIONS.find(
                (r) => r.label.toLowerCase() === REGRESSION_TYPES.LINEAR.toLowerCase()
            );
        case 3:
            return REGRESSIONS.find(
                (r) => r.label.toLowerCase() === REGRESSION_TYPES.QUAD.toLowerCase()
            );
        default:
            return REGRESSIONS.find(
                (r) =>
                    r.label.toLowerCase() === REGRESSION_TYPES.POLYNOMIAL.toLowerCase()
            );
        }
    }

    return REGRESSIONS.find((r) => r.label.toLowerCase() === model_name);
};

export const collateRegressionMetaFromD3 = (d) => {
    return {
        model_parameters: model_parameters(d),
        r2: d.rSquared,
    };
};

export const model_parameters = (meta) => {
    if (meta.coefficients) return meta.coefficients;

    return Object.keys(meta)
        .filter((k) => k.length === 1 && k.match(/[a-z]/))
        .sort((a, b) => (a < b ? -1 : 1))
        .map((k) => meta[k]);
};

export const model_name = (name) => {
    switch (name) {
    case REGRESSION_TYPES.LOGARITHMIC:
        return REGRESSION_TYPES.LOGARITHMIC;
    case REGRESSION_TYPES.EXPONENTIAL:
        return REGRESSION_TYPES.EXPONENTIAL;
    default:
        return REGRESSION_TYPES.POLYNOMIAL;
    }
};

export const trendlineEquation = (rType, model_parameters) => {
    switch (rType) {
    case REGRESSION_TYPES.EXPONENTIAL:
        let [a, b] = model_parameters;
        return (
            <span className="pl-1">
                <i>
            y = {toExponential(a)}e<sup>{toExponential(b)}x</sup>
                </i>
            </span>
        );
    case REGRESSION_TYPES.LOGARITHMIC:
        let [c, d] = model_parameters;
        return (
            <span className="pl-1">
                <i>y = {toExponential(c)}</i>ln(<i>x</i>) {op(d)}{' '}
                <i>{toExponential(mod(d))}</i>
            </span>
        );
    default:
        return getPolylineEqn(model_parameters);
    }
};

export const getFittedYFromDataset = (regression, data, x) => {
    // predictor fn
    const { model_parameters } = data;

    switch (regression.value) {
    case REGRESSION_TYPES.EXPONENTIAL:
        let [a, b] = model_parameters;
        return a * Math.pow(Math.E, b * x);
    case REGRESSION_TYPES.LOGARITHMIC:
        let [c, d] = model_parameters;
        return c * Math.log(x) + d;
    default:
        return getPolylineFittedYFromDataset(model_parameters, x);
    }
};

function getPolylineEqn(data) {
    const order = data.length - 1;
    const eqn = data.map((a, index) => {
        if (a === 0) return '';

        const exponent = order - index;
        if (index === order)
            return (
                <span className="pl-1">
                    {op(a)} {toExponential(mod(a))}
                </span>
            );

        return (
            <span key={index} className="pl-1">
                {index === 0 ? (
                    toExponential(a)
                ) : (
                    <>
                        {op(a)} {toExponential(mod(a))}
                    </>
                )}
                {exponent === 1 ? (
                    'x'
                ) : (
                    <>
            x<sup>{exponent}</sup>{' '}
                    </>
                )}
            </span>
        );
    });

    return (
        <span className="d-flex flex-row pl-1">
            <i>
        y ={' '}
                {eqn.map((term, idx) => (
                    <span key={idx}>{term}</span>
                ))}
            </i>
        </span>
    );
}

function getPolylineFittedYFromDataset(data, x) {
    const order = data.length - 1;

    return data
        .map((a, index) => {
            const exponent = order - index;
            return a * Math.pow(x, exponent);
        })
        .reduce((acc, curr) => {
            return (acc += curr);
        }, 0);
}

export const REGRESSION_TYPES = {
    LINEAR: 'LINEAR',
    EXPONENTIAL: 'EXPONENTIAL',
    LOGARITHMIC: 'LOGARITHMIC',
    QUADRATIC: 'QUADRATIC',
    POLYNOMIAL: 'POLYNOMIAL',
};

export const R_MODELS = {
    LINEAR: {
        value: REGRESSION_TYPES.LINEAR,
        fn: d3.regressionLinear,
    },
    EXPONENTIAL: {
        value: REGRESSION_TYPES.EXPONENTIAL,
        fn: d3.regressionExp,
    },
    LOGARITHMIC: {
        value: REGRESSION_TYPES.LOGARITHMIC,
        fn: d3.regressionLog,
    },
    QUADRATIC: {
        value: REGRESSION_TYPES.QUADRATIC,
        fn: d3.regressionQuad,
    },
    POLYNOMIAL: {
        value: REGRESSION_TYPES.POLYNOMIAL,
        fn: d3.regressionPoly,
        order: 3,
    },
};

export const REGRESSIONS = [
    {
        value: REGRESSION_TYPES.LINEAR,
        label: REGRESSION_TYPES.LINEAR,
        title: REGRESSION_TYPES.LINEAR,
        d: R_MODELS.LINEAR,
    },
    {
        value: REGRESSION_TYPES.EXPONENTIAL,
        label: REGRESSION_TYPES.EXPONENTIAL,
        title: REGRESSION_TYPES.EXPONENTIAL,
        d: R_MODELS.EXPONENTIAL,
    },
    {
        value: REGRESSION_TYPES.LOGARITHMIC,
        label: REGRESSION_TYPES.LOGARITHMIC,
        title: REGRESSION_TYPES.LOGARITHMIC,
        d: R_MODELS.LOGARITHMIC,
    },
    {
        value: REGRESSION_TYPES.QUADRATIC,
        label: REGRESSION_TYPES.QUADRATIC,
        title: REGRESSION_TYPES.QUADRATIC,
        d: R_MODELS.QUADRATIC,
    },
    {
        value: REGRESSION_TYPES.POLYNOMIAL,
        label: REGRESSION_TYPES.POLYNOMIAL,
        title: REGRESSION_TYPES.POLYNOMIAL,
        d: R_MODELS.POLYNOMIAL,
    },
];

export const CONFIDENCE_BANDS = [
    { label: '95%', value: 0.05 },
    { label: '96%', value: 0.04 },
    { label: '98%', value: 0.02 },
    { label: '99%', value: 0.01 },
    { label: '99.99%', value: 0.0001 },
];
