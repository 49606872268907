/* eslint-disable react/prop-types */
import { InfoCircleOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import React from 'react'

const OutputDescriptionPopover = (props) => {
    if (props.hide) {
        return null;
    }

    const operation = props.operation.toUpperCase();
    const content = <span className="legend-popover-container d-flex" style={{ width: '60vw', flexDirection: 'column' }}>
        {operation === "OR" && <>
            <u>For [OR] operation:</u>
            <span>This group (OR) output is calculated from the SUM of outputs from all assets under this group.</span>
        </>}

        {(operation === "SEQ" || operation === "SEQUENCE" || operation === "SPEED") &&<>
            <u>For [SEQ-Fixed/Longest STD time] operation:</u>
            <span>This sequential line output is calculated from the last asset of the line.</span>
        </>}

        {operation === "AND" &&<>
            <u>For [AND] operation:</u>
            <span>This group (AND) output is calculated from the lowest output asset under this group.</span>
        </>}
    </span>

    return <Popover placement="rightBottom" content={content}>
        <InfoCircleOutlined className="ml-2"/>
    </Popover>
}

export default OutputDescriptionPopover
