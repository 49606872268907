/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResetPassword } from '../../../store/old/Authentication/Authentication.action';
import InputGroupPrepend from '../../components/InputGroupPrepend';
import CONSTANTS from "../../Constants"
import { Logo } from '../../components/Logo';
import mainLogoSrc from '../../../assets/images/auk-logo-full.png';
import { flash } from '../../components/Flash';
import './Account.scss';
import withRouter from '../../Wrappers/HOCs/withRouter';
import translate from '../../utils/translate';

class PasswordResetPanel extends Component {
    state = {
        email: '',
    };

    fieldValidation = () => {
        const validationResult = {
            email: '',
        };

        const currentData = {
            email: this.state.email,
        };

        const isEmailValid = currentData.email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );

        if (!isEmailValid) {
            validationResult.email = 'Please enter a valid email address';
        }

        return validationResult;
    };

    handleChangeInput = (key, e) => {
        const value = e.target.value;

        return this.setState({
            [key]: value,
        });
    };

    resetPassword = () => {
        const fieldValidation = this.fieldValidation();

        if (fieldValidation.email.length > 0) {
            flash({
                message: 'Please enter a valid email address',
                status: 'error',
            });
            return;
        }

        this.props.resetPassword(
            {
                email: this.state.email,
            },
            () => {
                this.props.router.navigate(CONSTANTS.URLS.LOGIN);
                flash({
                    message: `Instructions for password reset sent to ${this.state.email}`,
                    status: 'success',
                });
            }
        );
    };

    render() {
        const validEmail = (
            <input
                type="text"
                className="form-control place"
                placeholder={"user@example.com"}
                value={this.state.email}
                onChange={(event) => this.handleChangeInput('email', event)}
            />
        );

        return (
            <div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col col-md-4 col-md-offset-4">
                                <Logo className="main-logo" src={mainLogoSrc} />
                                <div className="panel">
                                    <div className="panel-header">
                                        <p className="panel-title">
                                            {translate('resetpassword')}
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        <form noValidate="novalidate">
                                            <fieldset>
                                                <label className="check check-marg">
                                                    {translate('enteryourmailtoreset')}
                                                </label>
                                                <InputGroupPrepend
                                                    icon={<i className="far fa-user" />}
                                                    input={validEmail}
                                                />
                                            </fieldset>
                                        </form>
                                    </div>
                                    <div className="panel-footer">
                                        <button
                                            onClick={() => this.props.router.navigate(CONSTANTS.URLS.LOGIN)}
                                            className="btn b-cancel"
                                        >
                                            {translate('goback')}
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn b-save"
                                            onClick={this.resetPassword}
                                        >
                                            {translate('submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

const mapDispachToProps = (dispatch) => {
    return {
        resetPassword: (data, callback) =>
            dispatch(ResetPassword(data, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(withRouter(PasswordResetPanel));
