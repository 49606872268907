import "./style.scss"
import { GridColDef } from "@mui/x-data-grid"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { displayUnitOfTime } from "../../../../../api/models/objective.model"
import SimpleTable from "../../../../../components/Table/SimpleTable/SimpleTable"
import { useAppSelector } from "../../../../../store/hooks"
import ObjectivesEmptyPlaceholder from "../ObjectivesEmptyPlaceholder/ObjectivesEmptyPlaceholder"
import { uiReadableDateFormat } from "../../../../../legacy/utils/helpers"

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Plan/Goal Name",
        type: "string",
        width: 320,
    },
    {
        field: "startsAt",
        headerName: "Start",
        type: "date",
        width: 120,
        valueFormatter: (params) => moment(params.value).format(uiReadableDateFormat)
    },
    {
        field: "endsAt",
        headerName: "End",
        type: "date",
        width: 120,
        valueFormatter: (params) => moment(params.value).format(uiReadableDateFormat)
    },
    {
        field: "lastChanged",
        headerName: "Last Changed",
        type: "date",
        width: 240,
        valueFormatter: (params) => {
            return moment(params.value).fromNow()
        }
    },
    {
        field: "createdBy",
        headerName: "Created By",
        type: "string",
        width: 240,
    },
    {
        field: "type",
        headerName: "Type",
        type: "string",
        width: 240,
        sortable: false,
    },
    {
        field: "parentAsset",
        headerName: "Block / Asset",
        type: "string",
        width: 240,
    },
]

const ObjectivesList = (props: Props) => {
    const navigate = useNavigate()
    const { loading, list } = useAppSelector(state => state.goals)
    const [rows, setRows] = useState<Row[]>([])

    useEffect(() => {
        setRows(
            Object.values(list).map(
                plan => ({
                    id: plan.id,
                    name: plan.name,
                    lastChanged: moment(plan.updatedAt).toDate(),
                    createdBy: `${plan.user.nameFirst} ${plan.user.nameLast}`,
                    type: displayUnitOfTime(plan.unitOfTime),
                    parentAsset: plan.blockName,
                    startsAt: plan.startsAt,
                    endsAt: plan.endsAt
                })
            )
        )
    }, [JSON.stringify(Object.values(list).map(({ id }) => id))])

    const handleSelect = (values: (string | number)[]) => {
        props.onSelect(values.map(value => value as number))
    }

    const handleRowClick = (value: Row) => {
        navigate(`/goals/${value.id}`)
    }

    return <SimpleTable<Row>
        loading={loading}
        rows={rows}
        columns={columns}
        onSelect={handleSelect}
        onRowClick={handleRowClick}
        placeholder={() => <ObjectivesEmptyPlaceholder onAdd={props.onAdd} />}
    />
}

type Row = {
    id: number
    name: string
    lastChanged: Date
    createdBy: string
    type: string
    parentAsset: string
}

interface Props {
    onSelect: (ids: number[]) => void
    onAdd: () => void
}

export default ObjectivesList
