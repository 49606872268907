export const ms = {
    notes: 'Nota',
    load: 'Ambil',
    export: 'Eksport',
    unutilised: 'Un-digunakan',
    breakdowns: 'kerosakan',
    threshold: 'ambang',
    oeepreview: 'OEE pratonton',
    heatmappreview: 'peta haba Preview',
    oeecolors: 'warna OEE',
    color: 'warna',
    reset: 'Reset',
    theme: 'tema',
    defaultview: 'paparan biasa',
    defaulttimeselection: 'pilihan masa lalai',
    zoomlevel: 'Zoom Level',
    entity: 'Entiti',
    preferences: 'keutamaan',
    custom: 'Custom',
    enteryourmailtoreset: 'Masukkan mel anda untuk menetapkan semula',
    goback: 'Pergi balik',
    resetpassword: 'Menetapkan semula kata laluan',
    logout: 'Log keluar',
    ownersemail: 'pemilik Email',
    optional: 'pilihan',
    industry: 'industri',
    timezone: 'Zon masa',
    nocoderequesttojoin: 'Tiada kod? Memohon untuk menyertainya',
    enterreferralcodeinstead: 'Masukkan kod rujukan dan bukannya',
    setup: 'persediaan',
    company: 'syarikat',
    referralcode: 'Kod rujukan',
    submit: 'hantar',
    summary: 'Ringkasan',
    language: 'bahasa',
    login: 'Log masuk',
    signin: 'Daftar masuk',
    signup: 'Daftar',
    username: 'Nama pengguna',
    password: 'kata laluan',
    forgotpassword: 'lupa kata laluan?',
    rememberme: 'Ingat saya',
    register: 'Daftar',
    registration: 'pendaftaran',
    firstname: 'Nama pertama',
    lastname: 'Nama terakhir',
    email: 'e-mel',
    mobile: 'mudah alih',
    country: 'negara',
    contactnumber: 'Nombor telefon',
    appointment: 'pelantikan',
    role: 'peranan',
    admin: 'admin',
    owner: 'pemilik',
    editor: 'editor',
    viewer: 'penonton',
    confirmpassword: 'Sahkan Kata laluan',
    iacceptthe: 'Saya menerima',
    useragreement: 'perjanjian pengguna',
    alreadyregisteredlogin: 'Sudah mendaftar? Log masuk',
    softwareasaservicetermsofuse:
    'Perisian-sebagai-a-Perkhidmatan Terma Penggunaan',
    dashboard: 'Dashboard',
    streaming: 'streaming',
    resolution: 'Resolusi',
    seconds: 'saat',
    second: 'kedua',
    minutes: 'minit',
    minute: 'minit',
    hours: 'Jam',
    hour: 'Jam',
    weeks: 'minggu',
    week: 'minggu',
    months: 'bulan',
    month: 'bulan',
    quarters: 'pihak',
    quarter: 'suku',
    years: 'tahun',
    year: 'tahun',
    from: 'daripada',
    to: 'kepada',
    start: 'permulaan',
    end: 'akhir',
    display: 'paparan',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: 'Siri masa',
    gateway: 'pintu masuk',
    statusasof: 'Status pada',
    active: 'aktif',
    offline: 'Tutup Talian',
    mergeasonemachine: 'Bergabung sebagai satu mesin',
    drawarrowstoformprocessmap: 'Menarik anak panah untuk peta proses bentuk',
    openblockeditor: 'editor blok terbuka',
    createnewtile: 'Buat Tile New',
    summarytile: 'jubin Ringkasan',
    selectablockthennameyoursummarytile:
    'Pilih blok, kemudian menamakan jubin ringkasan anda',
    name: 'nama',
    blockselector: 'blok pemilih',
    asset: 'aset',
    therearenolabelstaggedinthisperiod:
    'Tiada label yang ditandai dalam tempoh ini',
    standardtimes: 'Masa Standard (s)',
    total: 'Jumlah',
    last: 'lalu',
    oeeinput: 'input OEE',
    chartcolor: 'Carta warna',
    issueeditor: 'isu Editor',
    issuemangement: 'isu Mangement',
    cumulativeoutput: 'output terkumpul',
    tag: 'tag',
    label: 'label',
    valueval: 'Nilai (Val)',
    time: 'Masa',
    januaryjan: 'Januari (Jan)',
    februaryfeb: 'February (Feb)',
    marchmar: 'Mac (Mar)',
    aprilapr: 'April (Apr)',
    may: 'Mungkin',
    junejun: 'Jun (Jun)',
    julyjul: 'Julai (Jul)',
    augustaug: 'Ogos (Ogos)',
    septembersep: 'September (September)',
    octoberoct: 'Oktober (Oktober)',
    novembernov: 'November (Nov)',
    decemberdec: 'Disember (Dis)',
    day: 'hari',
    days: 'hari',
    mondaymonm: 'Isnin (Isnin) M',
    tuesdaytuet: 'Selasa (Selasa) T',
    wednesdaywedw: 'Rabu (Rabu) W',
    thursdaythut: 'Khamis (Thu) T',
    fridayfrif: 'Jumaat (Fri) F',
    saturdaysats: 'Sabtu (Sabtu) S',
    sundaysuns: 'Ahad (Sun) S',
    edit: 'Edit',
    add: 'Tambah',
    new: 'Baru',
    create: 'Buat',
    search: 'cari',
    close: 'Tutup',
    cancel: 'Batal',
    save: 'jimat',
    saved: 'tersimpan',
    clear: 'jelas',
    enter: 'Masukkan',
    navigator: 'Navigator',
    mandatoryfields: 'wajib Fields',
    select: 'pilih',
    merge: 'Merge',
    delete: 'Padam',
    done: 'Selesai',
    filter: 'penapis',
    table: 'Jadual',
    columns: 'ruangan',
    rows: 'baris',
    cell: 'sel',
    page: 'Page',
    previous: 'sebelum',
    next: 'Seterusnya',
    serialnumber: 'Nombor siri',
    manuallyinputserialnumber: 'Nombor Siri secara manual input',
    updateasset: 'Update Asset',
    machine: 'mesin',
    brand: 'jenama',
    model: 'model',
    nodes: 'nod',
    fusions: 'paduan',
    chartorder: 'Carta Order',
    primary: 'utama',
    adddevicechannel: 'Tambah Peranti - Channel',
    pushratesec: 'Tolak Kadar (sec)',
    voltagev: 'Voltan (V)',
    sensor: 'sensor',
    mode: 'mod',
    digitalcount: 'Pengiraan digital',
    digitalstate: 'Digital Negeri',
    analogvoltage: 'Analog Voltan',
    charttitle: 'Carta Title',
    unitmeasure: 'Unit Measure',
    attribute: 'atribut',
    autocycletime: 'Masa kitaran Auto',
    unittransform: 'Unit Mengubah',
    leaveblankifnotapplicable: 'Cuti kosong jika tidak berkenaan',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'medan mandatori (cuti kosong jika saluran tidak digunakan)',
    fusedchart: 'Carta Fused',
    fusionname: 'Nama Fusion',
    charttype: 'Jenis Carta',
    column: 'Ruangan',
    line: 'Line',
    state: 'Negeri',
    expression: 'ungkapan',
    combination: 'gabungan',
    currentcharts: 'Carta Semasa',
    expressionconstructor: 'ungkapan Constructor',
    validoperators: 'pengendali sah',
    leaveblanktokeepexistingexpression:
    'Biarkan kosong untuk menyimpan ungkapan yang sedia ada',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Secara keseluruhan Keberkesanan Equipment',
    legend: 'Legend',
    loading: 'Loading',
    availability: 'ketersediaan',
    performance: 'prestasi',
    quality: 'kualiti',
    unscheduled: 'tidak berjadual',
    planneddowntime: 'dirancang Downtime',
    changeoversetup: 'Pertukaran / Setup',
    breakdown: 'Rosak',
    unutilized: 'Un-digunakan',
    speedloss: 'Kehilangan kelajuan',
    minorstops: 'Stops kecil',
    rework: 'Kerja semula',
    rejectscrap: 'Menolak / Scrap',
    nodata: 'Tiada data',
    blockeditor: 'blok Editor',
    sort: 'Jenis',
    logicblocks: 'Logik Blok',
    sequence: 'urutan',
    and: 'dan',
    or: 'atau',
    utilizationheatmap: 'penggunaan Heatmap',
    startdate: 'Tarikh mula',
    enddate: 'Tarikh tamat',
    daily: 'setiap hari',
    weekly: 'Setiap minggu',
    monthly: 'bulanan',
    quarterly: 'suku tahunan',
    yearly: 'tahunan',
    trendanalysis: 'Analisis trend',
    metricselection: 'metrik Pemilihan',
    metric: 'metrik',
    data: 'Data',
    target: 'sasaran',
    periodselection: 'tempoh Pemilihan',
    span: 'span',
    every: 'setiap',
    repeat: 'ulangan',
    repeats: 'ulangan',
    addsingle: 'menambah Single',
    paretoanalysis: 'Analisis Pareto',
    calendartime: 'masa kalendar',
    scheduledtime: 'Masa yang telah dijadualkan',
    operatingtime: 'Masa operasi',
    netruntime: 'masa jangka bersih',
    effective: 'berkesan',
    loadingloss: 'Loading Kerugian',
    availabilityloss: 'ketersediaan Kerugian',
    performanceloss: 'Kerugian prestasi',
    qualityloss: 'Kehilangan kualiti',
    notagsinthiscategory: 'Tiada tag dalam kategori ini',
    overall: 'keseluruhan',
    tagged: 'Tagged',
    occurrence: 'kejadian',
    skumanagement: 'Pengurusan SKU',
    category: 'kategori',
    skuname: 'Nama SKU',
    skucode: 'Kod SKU',
    unitofmeasurementuom: 'Unit Pengukuran (UOM)',
    description: 'Penerangan',
    csvupload: 'CSV naik',
    template: 'template',
    notifications: 'pemberitahuan',
    newnotification: 'Pemberitahuan baru',
    monitoring: 'pemantauan',
    topic: 'Topic',
    frequency: 'kekerapan',
    recipients: 'penerima',
    notifyby: 'memberitahu melalui',
    sms: 'SMS',
    alert: 'Amaran',
    report: 'laporan',
    greaterthan: 'Lebih besar daripada',
    lessthan: 'Kurang daripada',
    greaterthanorequal: 'Lebih besar daripada atau sama',
    lessthanorequal: 'Kurang daripada atau sama',
    equal: 'sama',
    range: 'pelbagai',
    continuousduration: 'Tempoh berterusan',
    usermanagement: 'Pengurusan pengguna',
    user: 'pengguna',
    users: 'pengguna',
    invitenew: 'menjemput New',
    status: 'status',
    resend: 'Hantar semula',
    revoke: 'Membatalkan',
    invite: 'menjemput',
    invitees: 'jemputan',
    addinviteesbypressingenterorclickingonthesign:
    "Menambah jemputan dengan menekan Enter atau klik pada tanda '+'",
    schedules: 'Jadual',
    shift: 'Shift',
    profile: 'Profil',
    changepassword: 'Tukar kata laluan',
    deleteaccount: 'Akaun padam',
    userguide: 'Panduan pengguna',
    overviewandbasiccontrol: 'Gambaran keseluruhan dan kawalan asas',
    rangeandresolution: 'Pelbagai dan Resolusi',
    editingoptions: 'pilihan mengedit',
    mergetiles: 'jubin Merge',
    detailedassetview: 'pandangan aset terperinci',
    assetoverview: 'gambaran keseluruhan aset',
    summarystatistics: 'Ringkasan Perangkaan',
    dataanalysis: 'Analisis data',
    oeeanoverview: 'OEE gambaran keseluruhan',
    oeetoggle: 'OEE togol',
    addingamachine: 'Menambah mesin',
    nodeconfiguration: 'konfigurasi nod',
    deleteupdateassets: 'aset maklumat padam',
    oeesummarytile: 'OEE jubin ringkasan',
    oeetags: 'OEE Tags',
    issuemanagement: 'isu pengurusan',
    labelsandfiltering: 'Label dan penapisan',
    useraccess: 'Akses pengguna',
    group: 'Kumpulan',
    groups: 'kumpulan',
    joincompany: 'menyertai syarikat',
    notification: 'pemberitahuan',
    createnotification: 'Buat pemberitahuan',
    node: 'nod',
    downtime: 'downtime',
    downtimes: 'downtimes',
    repeatstartdate: 'Mengulangi Tarikh Mula',
    repeatenddate: 'Ulang Tarikh Akhir',
    optional: 'pilihan',
    createplan: 'Buat Rancangan',
    today: 'hari ini',
    starttime: 'Masa mula',
    endtime: 'Masa tamat',
    chart: 'carta',
    reject: 'Tolak',
    accept: 'terima',
    filterbydaterange: 'penapis mengikut julat tarikh',
    filterbymostrecent: 'penapis dengan terbaru',
    result: 'keputusan',
    type: 'Jenis',
    duration: 'Tempoh',
    by: 'oleh',
    title: 'tajuk',
    assigned: 'Ditugaskan',
    action: 'Tindakan',
    track: 'mengesan',
    issue: 'Isu',
    priority: 'keutamaan',
    requestor: 'peminta',
    opened: 'dibuka',
    assignee: 'Pemegang Harta',
    root: 'akar',
    cause: 'menyebabkan',
    identified: 'dikenalpasti',
    defined: 'ditakrifkan',
    date: 'Tarikh',
    resolve: 'keazaman',
    resolved: 'diselesaikan',
    targetresolvedate: 'Sasaran tarikh keazaman',
    procurement: 'Perolehan',
    choose: 'pilih',
    analyses: 'analisis',
    source: 'sumber',
    selection: 'pemilihan',
    historical: 'sejarah',
    mostrecent: 'Yang terbaru',
    regression: 'regresi',
    trendline: 'garis arah aliran',
    upperbound: 'atas terikat',
    lowerbound: 'lebih rendah terikat',
    preview: 'preview',
    period: 'tempoh',
    series: 'siri',
    axis: 'paksi',
    youhaveno: 'anda tidak mempunyai',
    code: 'Kod',
    all: 'Semua',
    upload: 'muat naik',
    regressionanalysis: 'Analisis regresi',
    issuetracker: 'isu tracker',
    labelsearch: 'label Cari',
    gatewaysetup: 'Gateway Persediaan',
    setupgateway: 'pintu masuk persediaan',
    radiomesh: 'radio mesh',
    targetsetting: 'penetapan sasaran',
    updatetrackedissue: 'update isu dikesan',
    shownewtrackedissue: 'menunjukkan isu berlandasan baru',
    low: 'rendah',
    mid: 'pertengahan',
    high: 'tinggi',
    linear: 'linear',
    exponential: 'eksponen',
    logarithmic: 'logaritma',
    quadratic: 'kuadratik',
    polynomial: 'polinomial',
    general: 'umum',
    datataggingenrichment: 'Data Tagging Enrichment',
    hardware: 'perkakasan',
    nodemanagement: 'pengurusan nod',
    createeditnotification: 'Buat edit pemberitahuan',
    conditions: 'Syarat',
    addsku: 'menambah sku',
    editsku: 'edit SKU',
    setupagateway: 'pintu masuk setup',
    deviceserialnumber: 'nombor siri peranti',
    repeatsweekly: 'berulang setiap minggu',
    repeatsdaily: 'mengulangi setiap hari',
    repeatsmonthly: 'mengulangi bulanan',
    repeatsannually: 'mengulangi setiap tahun',
    repeatsevery: 'berulang setiap',
    hourly: 'setiap jam',
    biweekly: 'dua kali seminggu',
    standardtimemanagement: 'Pengurusan Waktu Piawai',
    autoupdate: 'Kemas kini automatik',
    cycletime: 'Kitaran masa',
    notset: 'Tidak Ditetapkan',
    hourly: 'Setiap jam',
    oeesku: 'OEE-SKU',
    skuassetstandardtime: 'SKU-Aset Masa Standard',
    permission: 'Pengurusan kebenaran',
};
