import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction, Draft } from "@reduxjs/toolkit"
import type { OptionsWithExtraProps, SnackbarKey, SnackbarMessage, VariantType } from "notistack"

export interface Message {
    key: SnackbarKey
    message?: SnackbarMessage
    options?: OptionsWithExtraProps<VariantType>
    dismissed?: boolean
}

type SnacksState = Message[]

const initialState: SnacksState = []

export const snacksSlice = createSlice({
    name: "snacks",
    initialState,
    reducers: {
        snacksAdd: (state, action: PayloadAction<Message>) => {
            state.push(action.payload as Draft<Message>)
        },
        snacksRemove: (state, action: PayloadAction<SnackbarKey>) => {
            return state.filter(snack => snack.key !== action.payload)
        },
    },
})

export const { snacksAdd, snacksRemove } = snacksSlice.actions

export default snacksSlice.reducer
