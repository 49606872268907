import { round } from "lodash"

const findMeanTimeBetweenFailure = (waterfall: any[], breakdowns: any[]) => {
    if (!waterfall.length) return undefined
    const productionTime = round(waterfall.find(({ key }) => key === "pt").duration, 0)
    const totalBreakdownDuration = round(waterfall.find(({ key }) => key === "bd").duration, 0)
    const totalUnutilizedDuration = round(waterfall.find(({ key }) => key === "uu").duration, 0)
    const totalUptime = productionTime - totalBreakdownDuration - totalUnutilizedDuration

    return breakdowns.length ? round(totalUptime / breakdowns.length, 0) : undefined
}

export default findMeanTimeBetweenFailure
