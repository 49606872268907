export const GroupConstants = {
    ACTIONS: {
        SET_GROUPS_RESOURCE: 'SET_GROUPS_RESOURCE',
        ADD_GROUPS_RESOURCE: 'ADD_GROUPS_RESOURCE',
        REMOVE_GROUPS_RESOURCE: 'REMOVE_GROUPS_RESOURCE',
        FETCH_GROUPS_REQUEST: 'FETCH_GROUPS_REQUEST',
        CREATE_GROUP_REQUEST: 'CREATE_GROUP_REQUEST',
        UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
        DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
    },
};
