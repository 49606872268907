export const LanguageConstants = [
    {
        value: "en",
        name: "English",
        nativeName: "English",
    },
    {
        value: "it",
        name: "Italian",
        nativeName: "Italiano",
    },
    {
        value: "id",
        name: "Indonesian",
        nativeName: "Bahasa Indonesia",
    },
    {
        value: "ms",
        name: "Malay",
        nativeName: "Bahasa Melayu",
    },
    {
        value: "th",
        name: "Thai",
        nativeName: "ภาษาไทย",
    },
    {
        value: "zh",
        name: "Chinese",
        nativeName: "中文",
    },
    {
        value: "es",
        name: "Spanish",
        nativeName: "Español",
    },
    {
        value: "ja",
        name: "Japanese",
        nativeName: "日本語",
    },
    {
        value: "pt",
        name: "Portuguese",
        nativeName: "Português",
    },
    {
        value: "de",
        name: "German",
        nativeName: "Deutsche",
    },
    {
        value: "ar",
        name: "Arabic",
        nativeName: "عربى",
    },
    {
        value: "fr",
        name: "French",
        nativeName: "Français",
    },
    {
        value: "ru",
        name: "Russian",
        nativeName: "русский",
    },
    {
        value: "ko",
        name: "Korean",
        nativeName: "한국어",
    },
    {
        value: "vi",
        name: "Vietnamese",
        nativeName: "Tiếng Việt",
    },
    {
        value: "tr",
        name: "Turkish",
        nativeName: "Türk",
    },
    {
        value: "el",
        name: "Greek",
        nativeName: "Yunan",
    },
    {
        value: "sv",
        name: "Swedish",
        nativeName: "Svenska",
    },
]
