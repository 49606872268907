import { all, call, put, takeLatest } from 'redux-saga/effects';


// SERVICES
import {
    api_createDowntime,
    api_createShift,
    api_stopRecurringDowntime,
    api_stopRecurringShift,
    api_deleteShifts,
    api_getAssetShifts,
    api_getDowntimes,
    api_getShifts,
    api_updateDowntime,
    api_updateShift,
    api_deleteDowntimes,
} from './Schedules.services';

import {
    setSchedulesState,
    addDowntimesResource,
    addShiftsResource,
    addAssetShiftsResource,
    removeShiftsResource,
    removeDowntimesResource,
} from './Schedules.action';

// REDUX STORE
import { store } from '../..';
import { SchedulesConstants as K } from './Schedules.constants';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import {
    Downtime,
    parseDowntimeArguments,
    parseShiftArguments,
    Shift,
    AssetWorkSchedule,
} from '../../../legacy/models';

// HELPERS
import { errorFlash } from '../../../legacy/components/Flash';

function* handleGetSchedulesRequest(action) {
    try {
        const { initialized } = store.getState().schedules;
        if (initialized) return;

        const { entity_id } = action.payload;
        const res = yield all([
            call(api_getShifts, entity_id),
            call(api_getDowntimes, entity_id),
        ]);
        const shifts = getMapFromArr(
            res[0].map((s) => new Shift(...parseShiftArguments(s))),
            'shift_id'
        );
        const downtimes = getMapFromArr(
            res[1].map((d) => new Downtime(...parseDowntimeArguments(d))),
            'downtime_id'
        );

        yield put(setSchedulesState(shifts, downtimes));

        action.callback && action.callback({ shifts, downtimes });
    } catch (e) {
        errorFlash(e);
    }
}

export function* getSchedulesRequestSaga() {
    yield takeLatest(K.ACTIONS.GET_SCHEDULES_REQUEST, handleGetSchedulesRequest);
}

function* handleCreateShiftRequest(action) {
    try {
        const { shift: data } = action.payload;
        const res = yield call(api_createShift, data);
        const shift = new Shift(...parseShiftArguments(res));

        yield put(addShiftsResource({ [shift.shift_id]: shift }));

        action.callback && action.callback(shift);
    } catch (e) {
        errorFlash(e);
    }
}

export function* createShiftRequestSaga() {
    yield takeLatest(K.ACTIONS.CREATE_SHIFT_REQUEST, handleCreateShiftRequest);
}

function* handleUpdateShiftRequest(action) {
    try {
        const { shift: data } = action.payload;
        const res = yield call(api_updateShift, data);
        const shift = new Shift(...parseShiftArguments(res));

        yield put(addShiftsResource({ [shift.shift_id]: shift }));

        action.callback && action.callback(shift);
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateShiftRequestSaga() {
    yield takeLatest(K.ACTIONS.UPDATE_SHIFT_REQUEST, handleUpdateShiftRequest);
}

function* handleStopShiftRequest(action) {
    try {
        const { shift_id } = action.payload;
        const res = yield call(api_stopRecurringShift, shift_id);
        const shift = new Shift(...parseShiftArguments(res));

        yield put(addShiftsResource({ [shift.shift_id]: shift }));

        action.callback && action.callback();
    } catch (e) {
        errorFlash(e);
    }
}

export function* stopShiftRequestSaga() {
    yield takeLatest(K.ACTIONS.STOP_SHIFT_REQUEST, handleStopShiftRequest);
}

function* handleDeleteShiftsRequest(action) {
    try {
        const { shifts } = action.payload;

        yield call(api_deleteShifts, { shifts });
        yield put(removeShiftsResource(shifts));

        action.callback && action.callback();
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteShiftsRequestSaga() {
    yield takeLatest(K.ACTIONS.DELETE_SHIFTS_REQUEST, handleDeleteShiftsRequest);
}

function* handleCreateDowntimeRequest(action) {
    try {
        const { downtime: data } = action.payload;
        const res = yield call(api_createDowntime, data);
        const downtime = new Downtime(...parseDowntimeArguments(res));

        yield put(addDowntimesResource({ [downtime.downtime_id]: downtime }));

        action.callback && action.callback(downtime);
    } catch (e) {
        errorFlash(e);
    }
}

export function* createDowntimeRequestSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_DOWNTIME_REQUEST,
        handleCreateDowntimeRequest
    );
}

function* handleUpdateDowntimeRequest(action) {
    try {
        const { downtime: data } = action.payload;
        const res = yield call(api_updateDowntime, data);
        const downtime = new Downtime(...parseDowntimeArguments(res));

        yield put(addDowntimesResource({ [downtime.downtime_id]: downtime }));

        action.callback && action.callback(downtime);
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateDowntimeRequestSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_DOWNTIME_REQUEST,
        handleUpdateDowntimeRequest
    );
}

function* handleStopDowntimeRequest(action) {
    try {
        const { downtime_id } = action.payload;
        const res = yield call(api_stopRecurringDowntime, downtime_id);
        const downtime = new Downtime(...parseDowntimeArguments(res));

        yield put(addDowntimesResource({ [downtime.downtime_id]: downtime }));

        action.callback && action.callback();
    } catch (e) {
        errorFlash(e);
    }
}

export function* stopDowntimeRequestSaga() {
    yield takeLatest(K.ACTIONS.STOP_DOWNTIME_REQUEST, handleStopDowntimeRequest);
}

function* handleDeleteDowntimesRequest(action) {
    try {
        const { downtimes } = action.payload;

        yield call(api_deleteDowntimes, { downtimes });
        yield put(removeDowntimesResource(downtimes));

        action.callback && action.callback();
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteDowntimesRequestSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_DOWNTIMES_REQUEST,
        handleDeleteDowntimesRequest
    );
}

function* handleGetAssetShiftsRequest(action) {
    try {
        const { entity_id, asset_id, query } = action.payload;

        const response = yield call(api_getAssetShifts, entity_id, asset_id, query);

        yield put(
            addAssetShiftsResource({
                [asset_id]: response.map((item) => new AssetWorkSchedule(item)),
            })
        );
        action.callback && action.callback();
    } catch (e) {
        errorFlash(e);
    }
}

export function* getAssetShiftsRequestSaga() {
    yield takeLatest(
        K.ACTIONS.GET_ASSET_SHIFTS_REQUEST,
        handleGetAssetShiftsRequest
    );
}
