import { makeRequest } from '../Shared/Shared.action';
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

export const api_createFusion = (asset_id, data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/fusion`,
                method: 'POST',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateFusion = (asset_id, data) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/fusion/${data.fusion_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteFusion = (entity_id, asset_id, fusion_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/fusion/${fusion_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};