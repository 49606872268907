/* eslint-disable react/prop-types */

import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {  Menu } from 'antd';
import { MoreOutlined, ZoomInOutlined } from '@ant-design/icons';

// HELPERS
import { uiDatetimeFormatWithSeconds } from '../../../utils/helpers';
import { getAppropriateResolution } from '../../../utils/controls';
import { CUSTOM_SPAN } from '../../../../store/old/UI/Controls/Controls.constants';

// REDUX
import { controlsState } from '../../../../store/old/UI/Controls/Controls.selector';

// COMPONENTS
import AukButton from '../../../components/AukButton';
import AukTooltip from '../../../components/AukTooltip';

// TYPES / INTERFACE
import { useLocation, useNavigate } from 'react-router-dom';
import { generateUrlQuery } from '../../../utils/url';

export const LABEL_DRAWER = {
    TYPE: {
        ISSUE_LABELLER: 'ISSUE_LABELLER',
        PRODUCTION_LABELLER: 'PRODUCTION_LABELLER',
        FORWARD_LABELLER: 'FORWARD_LABELLER',
        TARGET_PRODUCTION_LABELLER: 'TARGET_PRODUCTION_LABELLER',
        BLOCK_OUTPUT_LABELLER: 'BLOCK_OUTPUT_LABELLER'
    },
};

export const LabelRangeZoom = (props) => {
    const navigate = useNavigate();
    const location = useLocation()
    const { range, disableZoom = false } = props;
    const controls = useSelector(controlsState);
    const [start, end] = range;

    const zoom = useCallback(() => {
        const startDate = moment(start);
        const endDate = moment(end);
        const durationSeconds = endDate.diff(startDate, 'seconds');
        const resolution = getAppropriateResolution(
            durationSeconds,
            controls.resolution
        );

        const query = generateUrlQuery({
            startDate,
            endDate,
            resolution,
            span: CUSTOM_SPAN,
        });

        navigate(`${location.pathname}${query}`)
    }, [controls, range]);

    return (
        <div style={{ flexGrow: 1 }}>
            <div className="d-flex align-items-center">
                {moment(start).format(uiDatetimeFormatWithSeconds)}
                <i className="fas fa-long-arrow-alt-right ml-2" />
            </div>
            <div className="d-flex align-items-center">
                {disableZoom ? 'Now' : moment(end).format(uiDatetimeFormatWithSeconds)}{' '}
                {!disableZoom && (
                    <AukTooltip.Help title="Zoom into range">
                        <ZoomInOutlined
                            className="ml-2"
                            style={{ color: '#fff' }}
                            onClick={zoom}
                        />
                    </AukTooltip.Help>
                )}
            </div>
        </div>
    );
};

export const StopLabelIcon = (props) => {
    return (
        <AukTooltip.Help title="Stop Tagging">
            <div className="label-vlist-item__stop-icon" onClick={props.onClick}>
                <i className="far fa-stop-circle" />
            </div>
        </AukTooltip.Help>
    );
};

export const Labeller = (props) => {
    return (
        <div
            className={`labeller h-100 w-100 d-flex flex-column ${
                props.className || ''
            }`}
        >
            {props.children}
        </div>
    );
};

export const LabellerHeader = (props) => {
    return (
        <div
            className={`labeller-header w-100 p-3 d-flex ${props.className || ''}`}
        >
            {props.children}
        </div>
    );
};

export const LabellerHeaderItem = (props) => {
    return (
        <div className="d-flex">
            <span className="label">{props.label}</span>
            {props.children}
        </div>
    );
};

export const LabellerBody = (props) => {
    return (
        <div className={`labeller-body w-100 d-flex ${props.className || ''}`}>
            {props.children}
        </div>
    );
};

export const LabellerBodyItem = (props) => {
    const { header, children, addonHeader } = props;
    return (
        <div className="labeller-body-item">
            <LabellerBodyItemHeader addonAfter={addonHeader}>
                {header}
            </LabellerBodyItemHeader>
            <>{children}</>
        </div>
    );
};

const LabellerBodyItemHeader = (props) => {
    const { className, children, addonAfter } = props;
    return (
        <div className={`labeller-body-item-header ${className || ''}`}>
            <div className="d-flex justify-content-between w-100">
                <div style={{ flexGrow: 1 }}>{children}</div>
                {addonAfter ? (
                    <div style={{ flexShrink: 0 }}>
                        <AukButton.Dropdown
                            trigger={['click']}
                            size="small"
                            icon={<MoreOutlined />}
                            overlay={
                                <Menu>
                                    {addonAfter.map((item, index) => (
                                        <Menu.Item style={{ width: 280 }} key={index}>
                                            {item}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
