/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal } from 'antd';
import  translate  from '../utils/translate';


const CsvFileUpload = (props) => {
    const { visible, onCancel, rowTransform, withHeaders } = props;
    const [file, setFile] = useState();
    const fileReader = new FileReader();

    const csvStringToArray = (str) =>
        str
            .split('\n')
            .map((row) => rowTransform(row))
            .filter((row) => row.length > 1);

    const onOk = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                const data = csvStringToArray(text);
                props.onSubmit && props.onSubmit(!withHeaders ? data.slice(1) : data);
            };
            fileReader.readAsText(file);
        }
    };

    return (
        <Modal
            className="auk-modal--small"
            title={translate('csvupload')}
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
        >
            <form id="csvFileUpload">
                <input
                    required
                    type="file"
                    className="mx-3 mb-2"
                    label="* CSV file"
                    onChange={(e) => {
                        setFile(e.target.files[0])
                    }}
                    accept=".csv"
                />
            </form>
        </Modal>
    );
};

CsvFileUpload.defaultProps = {
    withHeaders: false,
    rowTransform: (str) => str.split(','),
};

export default CsvFileUpload;
