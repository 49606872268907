import { call, put, takeLatest } from 'redux-saga/effects';

// CONSTANTS
import { TrackedIssuesConstants as K } from './TrackedIssues.constants';

// REDUX STORE
import { store } from '../..';

// API
import { api_createTrackedIssue, api_deleteTrackedIssue, api_getTrackedIssues, api_updateTrackedIssue } from './TrackedIssues.services';

// ACTIONS
import { getTrackedIssuesRequestSuccess, createTrackedIssueRequestSuccess, makeTrackedIssueRequestFailure, updateTrackedIssueRequestSuccess, deleteTrackedIssueRequestSuccess } from './TrackedIssues.action';

// MODEL
import { parseTrackedIssueArguments, TrackedIssue } from '../../../legacy/models';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { errorFlash } from '../../../legacy/components/Flash';

function* handleGetTrackedIssuesRequest(action) {
    try {
        const res = yield call(api_getTrackedIssues);
        const trackedIssues = getMapFromArr(
            res.map((t) => new TrackedIssue(...parseTrackedIssueArguments(t))),
            'tracked_issue_id'
        );
        yield put(getTrackedIssuesRequestSuccess(trackedIssues));

        if (action.callback) action.callback(trackedIssues);
    } catch (e) {
        errorFlash(e);
        yield put(makeTrackedIssueRequestFailure(e));
    }
}

export function* getTrackedIssuesRequestSaga() {
    yield takeLatest(K.ACTIONS.GET_TRACKED_ISSUES_REQUEST, handleGetTrackedIssuesRequest);
}

function* handleCreateTrackedIssueRequest(action) {
    try {
        const res = yield call(api_createTrackedIssue, action.payload.data);
        const trackedIssue = new TrackedIssue(...parseTrackedIssueArguments(res));
        yield put(createTrackedIssueRequestSuccess(trackedIssue));

        if (action.callback) action.callback(trackedIssue);
    } catch (e) {
        errorFlash(e);
        yield put(makeTrackedIssueRequestFailure(e));
    }
}

export function* createTrackedIssueRequestSaga() {
    yield takeLatest(K.ACTIONS.CREATE_TRACKED_ISSUE_REQUEST, handleCreateTrackedIssueRequest);
}

function* handleUpdateTrackedIssueRequest(action) {
    try {
        const trackdIssuesState = store.getState().trackedIssues.trackedIssues;
        const res = yield call(api_updateTrackedIssue, action.payload.data);
        const current = trackdIssuesState[res.tracked_issue_id];
        const trackedIssue = new TrackedIssue(
            ...parseTrackedIssueArguments({ ...current.clone(), ...res })
        );
        yield put(updateTrackedIssueRequestSuccess(trackedIssue));

        if (action.callback) action.callback(trackedIssue);
    } catch (e) {
        errorFlash(e);
        yield put(makeTrackedIssueRequestFailure(e));
    }
}

export function* updateTrackedIssueRequestSaga() {
    yield takeLatest(K.ACTIONS.UPDATE_TRACKED_ISSUE_REQUEST, handleUpdateTrackedIssueRequest);
}

function* handleDeleteTrackedIssueRequest(action) {
    try {
        const { tracked_issue_id } = action.payload.trackedIssue;

        yield call(api_deleteTrackedIssue, tracked_issue_id);
        yield put(deleteTrackedIssueRequestSuccess(tracked_issue_id));

        if (action.callback) action.callback();
    } catch (e) {
        errorFlash(e);
        yield put(makeTrackedIssueRequestFailure(e));
    }
}

export function* deleteTrackedIssueRequestSaga() {
    yield takeLatest(K.ACTIONS.DELETE_TRACKED_ISSUE_REQUEST, handleDeleteTrackedIssueRequest);
}
