// CONSTANTS
import { PermissionConstants as K } from './Permission.constants';

// GET POLICIES
export const getPoliciesRequest = (callback) => {
    return {
        type: K.ACTIONS.GET_POLICIES_REQUEST,
        callback,
    };
};

export const getPoliciesSuccess = (policies) => {
    return {
        type: K.ACTIONS.GET_POLICIES_SUCCESS,
        payload: {
            policies,
        },
    };
};

export const getPoliciesFailure = (error) => {
    return {
        type: K.ACTIONS.GET_POLICIES_FAILURE,
        payload: {
            error,
        },
    };
};

// GET POLICY DETAIL
export const getPolicyDetailRequest = (policy, callback) => {
    return {
        type: K.ACTIONS.GET_POLICY_DETAIL_REQUEST,
        payload: {
            policy,
        },
        callback,
    };
};

export const getPolicyDetailSuccess = (policy) => {
    return {
        type: K.ACTIONS.GET_POLICY_DETAIL_SUCCESS,
        payload: {
            policy,
        },
    };
};

export const getPolicyDetailFailure = (error) => {
    return {
        type: K.ACTIONS.GET_POLICY_DETAIL_FAILURE,
        payload: {
            error,
        },
    };
};

// CREATE POLICY
export const createPolicyRequest = (policy, callback) => {
    return {
        type: K.ACTIONS.CREATE_POLICY_REQUEST,
        payload: {
            policy,
        },
        callback,
    };
};

export const createPolicySuccess = (policies) => {
    return {
        type: K.ACTIONS.CREATE_POLICY_SUCCESS,
        payload: {
            policies,
        },
    };
};

export const createPolicyFailure = (error) => {
    return {
        type: K.ACTIONS.CREATE_POLICY_FAILURE,
        payload: {
            error,
        },
    };
};

// UPDATE POLICY
export const updatePolicyRequest = (policy, callback) => {
    return {
        type: K.ACTIONS.UPDATE_POLICY_REQUEST,
        payload: {
            policy,
        },
        callback,
    };
};

export const updatePolicySuccess = (policies) => {
    return {
        type: K.ACTIONS.UPDATE_POLICY_SUCCESS,
        payload: {
            policies,
        },
    };
};

export const updatePolicyFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_POLICY_FAILURE,
        payload: {
            error,
        },
    };
};

// DELETE POLICY
export const deletePolicyRequest = (policy, callback) => {
    return {
        type: K.ACTIONS.DELETE_POLICY_REQUEST,
        payload: {
            policy,
        },
        callback,
    };
};

export const deletePolicySuccess = (policies) => {
    return {
        type: K.ACTIONS.DELETE_POLICY_SUCCESS,
        payload: {
            policies,
        },
    };
};

export const deletePolicyFailure = (error) => {
    return {
        type: K.ACTIONS.DELETE_POLICY_FAILURE,
        payload: {
            error,
        },
    };
};

// APPLY POLICY
export const applyPolicyRequest = (policy, callback) => {
    return {
        type: K.ACTIONS.APPLY_POLICY_REQUEST,
        payload: {
            policy,
        },
        callback,
    };
};

export const applyPolicySuccess = (policies) => {
    return {
        type: K.ACTIONS.APPLY_POLICY_SUCCESS,
        payload: {
            policies,
        },
    };
};

export const applyPolicyFailure = (error) => {
    return {
        type: K.ACTIONS.APPLY_POLICY_FAILURE,
        payload: {
            error,
        },
    };
};

// GET RESOURCES
export const getResourcesRequest = (callback) => {
    return {
        type: K.ACTIONS.GET_POLICY_RESOURCES_REQUEST,
        callback,
    };
};

export const getResourcesSuccess = (resources) => {
    return {
        type: K.ACTIONS.GET_POLICY_RESOURCES_SUCCESS,
        payload: {
            resources,
        },
    };
};

export const getResourcesFailure = (error) => {
    return {
        type: K.ACTIONS.GET_POLICY_RESOURCES_FAILURE,
        payload: {
            error,
        },
    };
};

export const resetPermissionsStore = () => {
    return {
        type: K.ACTIONS.RESET_PERMISSIONS_STORE,
    };
};
